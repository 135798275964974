import React, { useContext } from 'react';
import LinkButton from '../../../../../components/Buttons/LinkButton';
import NestedMenuList from '../../../../../components/NestedMenuList';
import { NavigationContext } from '../../../../../utilities/services/contexts';
import iconManager from '../../../../../utilities/services/IconManager';
import RouterCollapsible from '../../../RouterCollapsible';
import './styles.scss';

/**
 * Responsive list of existing pages displayed on screens below 1024px.
 */

const ResponsiveNavigationList = () => {
  const { hideModal, kycList, administrationList, accountSettingsList, businessList } = useContext(NavigationContext);

  return (
    <div className="ickyc-side-navigation-list">
      <RouterCollapsible title="iComplyKYC">
        <NestedMenuList items={kycList} />
      </RouterCollapsible>
      <RouterCollapsible title="Administration">
        <NestedMenuList items={administrationList} />
      </RouterCollapsible>
      <RouterCollapsible title="Account Settings">
        <NestedMenuList items={accountSettingsList} />
      </RouterCollapsible>
      {businessList.length > 0 && (
        <RouterCollapsible title="Internal Dashboard">
          <ul>
            {businessList.map(element => (
              <li key={element.title}>
                <LinkButton path={element.path} hideModal={hideModal}>
                  <span className="ickyc-side-navigation-list__svg">{iconManager.get(iconManager.names.PHONE)}</span>
                  {element.title}
                </LinkButton>
              </li>
            ))}
          </ul>
        </RouterCollapsible>
      )}
    </div>
  );
};

export default ResponsiveNavigationList;
