import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import setClassSuffix from '../../utilities/services/ClassManager';
import './styles.scss';

const Card = ({ text, children, row, column, className }) => {
  const baseClass = 'ickyc-card';
  const setSuffix = setClassSuffix(baseClass);
  const classes = classNames([baseClass, `${baseClass}--${row}x${column}`, className]);
  return (
    <div className={classes}>
      <h3 className={setSuffix('__header')}>{text}</h3>
      <div className={setSuffix('__content')}>{children}</div>
    </div>
  );
};
Card.propTypes = {
  text: PropTypes.string,
  row: PropTypes.number,
  column: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
Card.defaultProps = {
  text: '',
  row: 3,
  column: 3,
  className: '',
};
export default Card;
