import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'flag-icon-css/css/flag-icon.min.css';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import useHubspotChat from './hooks/useHubspotChat';
import './index.scss';
import history from './modules/history';
import Routes from './router/components/Routes';
import routes from './router/routes';
import store from './store';

const App = ({ errorCallback }) => {
  const { REACT_APP_STRIPE_KEY } = process.env;
  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY.toString());

  useHubspotChat();

  useEffect(() => {
    // When some unhandled error ocurs app will be unattached
    return () => errorCallback();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <Router history={history}>
          <Routes routes={routes} />
        </Router>
      </Provider>
    </Elements>
  );
};

export default App;
