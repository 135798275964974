import AddLegalEntityModule from '../../../NaturalPersonPortalModal/components/Modules/components/AddLegalEntityModule';
import ConsentGatewayLeModule from '../../../NaturalPersonPortalModal/components/Modules/components/ConsentGatewayLeModule';
import FinalStepModuleLE from '../../../NaturalPersonPortalModal/components/Modules/components/FinalStepModuleLE';
import LandingScreenModule from '../../../NaturalPersonPortalModal/components/Modules/components/LandingScreenModule';
import NaturalPersonFlowModule from '../../../NaturalPersonPortalModal/components/Modules/components/NaturalPersonFlowModule';
import SummaryScreenModule from '../../../NaturalPersonPortalModal/components/Modules/components/SummarySreenModule';
import SupportingDocumentsModule from '../../../NaturalPersonPortalModal/components/Modules/components/SupportingDocumentsModule';
import ThirdPartyConfirmationModule from '../../../NaturalPersonPortalModal/components/Modules/components/ThirdPartyConfirmationModule';

const modulesList = [
  { component: LandingScreenModule, name: 'LandingScreenModule', index: 0 },
  { component: SummaryScreenModule, name: 'SummaryScreenModule', index: 1 },
  { component: ConsentGatewayLeModule, name: 'ConsentGatewayLeModule', index: 8 },
  { component: AddLegalEntityModule, name: 'AddLegalEntityModule', index: 2 },
  { component: SupportingDocumentsModule, name: 'SupportingDocumentsModule', index: 7, isLEModules: true },
  { component: ThirdPartyConfirmationModule, name: 'ThirdPartyConfirmationModule', index: 5 },
  { component: FinalStepModuleLE, name: 'FinalStepModuleLE', index: 9 },
  {
    component: NaturalPersonFlowModule,
    name: 'NaturalPersonFlowModule',
    index: 6,
    draggable: true,
    isNaturalPersonFlowModule: true,
  },
];

const getNPFModuleIndex = modules => {
  return modules.findIndex(module => module.index === 6);
};

const getNPFModule = modules => {
  return modules.find(module => module.index === 6);
};

const putNPFModuleOnBegining = modules => {
  const tempModules = modules;
  const [npfModule] = tempModules.splice(getNPFModuleIndex(modules), 1);
  tempModules.unshift(npfModule);

  return tempModules;
};

export default {
  modulesList,
  getNPFModule,
  putNPFModuleOnBegining,
};
