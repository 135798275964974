import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Section from '../../../../../components/Section';
import { AML_FILTER_TYPES } from '../../enums';
import AMLFilter from './components/AMLFilter';
import './styles.scss';

const AMLFiltering = ({
  consolidatedMatchTypes,
  onFilterSelect,
  requestedAMLFilters,
  selectedAMLFilters,
  title,
  disabled,
}) => {
  const classBase = 'ickyc-aml-filtering';
  const classes = classNames(classBase, { [`${classBase}--disabled`]: disabled });

  const handleFilterClick = useCallback(
    (e, code) => {
      if (disabled) return;
      e.stopPropagation();
      onFilterSelect(code);
    },
    [onFilterSelect, disabled],
  );

  const getAMLFilterCode = useCallback(
    filterCode => {
      // wont work for pep
      let code = AML_FILTER_TYPES.DISABLED;
      const isInRequested = requestedAMLFilters.includes(filterCode);
      const hasMatches = consolidatedMatchTypes.includes(filterCode);
      if (isInRequested) {
        code = !hasMatches ? AML_FILTER_TYPES.REQUESTED : AML_FILTER_TYPES.MATCHED;
      }
      if (selectedAMLFilters.includes(filterCode)) {
        code = AML_FILTER_TYPES.SELECTED;
      }
      return code;
    },
    [consolidatedMatchTypes, requestedAMLFilters, selectedAMLFilters],
  );

  return (
    <Section title={title} className={classes}>
      <div className={`${classBase}__filters`}>
        <div className={`${classBase}__filters__column`}>
          <AMLFilter type={16} code={getAMLFilterCode(16)} onClick={e => handleFilterClick(e, 16)} />
          <AMLFilter type={0} code={getAMLFilterCode(0)} onClick={e => handleFilterClick(e, 0)} />
          <AMLFilter type={1} code={getAMLFilterCode(1)} onClick={e => handleFilterClick(e, 1)} />
          <AMLFilter type={2} code={getAMLFilterCode(2)} onClick={e => handleFilterClick(e, 2)} />
        </div>

        <div className={`${classBase}__filters__column`}>
          <AMLFilter type={3} code={getAMLFilterCode(3)} onClick={e => handleFilterClick(e, 3)} />
          <AMLFilter type={4} code={getAMLFilterCode(4)} onClick={e => handleFilterClick(e, 4)} />
          <AMLFilter type={5} code={getAMLFilterCode(5)} onClick={e => handleFilterClick(e, 5)} />
          <AMLFilter type={6} code={getAMLFilterCode(6)} onClick={e => handleFilterClick(e, 6)} />
          <AMLFilter type={7} code={getAMLFilterCode(7)} onClick={e => handleFilterClick(e, 7)} />
        </div>
        <div className={`${classBase}__filters__column`}>
          <AMLFilter type={8} code={getAMLFilterCode(8)} onClick={e => handleFilterClick(e, 8)} />
          <AMLFilter type={9} code={getAMLFilterCode(9)} onClick={e => handleFilterClick(e, 9)} />
          <AMLFilter type={13} code={getAMLFilterCode(13)} onClick={e => handleFilterClick(e, 13)} />
          <AMLFilter type={10} code={getAMLFilterCode(10)} onClick={e => handleFilterClick(e, 10)} />
          <AMLFilter type={14} code={getAMLFilterCode(14)} onClick={e => handleFilterClick(e, 14)} />
          <AMLFilter type={11} code={getAMLFilterCode(11)} onClick={e => handleFilterClick(e, 11)} />
          <AMLFilter type={15} code={getAMLFilterCode(15)} onClick={e => handleFilterClick(e, 15)} />
          <AMLFilter type={12} code={getAMLFilterCode(12)} onClick={e => handleFilterClick(e, 12)} />
        </div>
      </div>
    </Section>
  );
};

AMLFiltering.propTypes = {
  consolidatedMatchTypes: PropTypes.arrayOf(PropTypes.number),
  selectedAMLFilters: PropTypes.arrayOf(PropTypes.number),
  onFilterSelect: PropTypes.func,
  requestedAMLFilters: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
AMLFiltering.defaultProps = {
  consolidatedMatchTypes: [],
  selectedAMLFilters: [],
  onFilterSelect: () => {},
  requestedAMLFilters: [],
  disabled: false,
};
export default AMLFiltering;
