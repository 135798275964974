import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Camera from '../../../../../../assets/CameraIcon';

const DocumentMiniUploader = ({ handlers }) => {
  return (
    <div className="ickyc-document-detection-dropzone--mini" {...handlers()}>
      <IconButton transparent>
        <Camera />
      </IconButton>
      Upload New Photo
    </div>
  );
};
DocumentMiniUploader.propTypes = {
  handlers: PropTypes.func,
};
DocumentMiniUploader.defaultProps = {
  handlers: () => {},
};
export default DocumentMiniUploader;
