import PropTypes from 'prop-types';
import React from 'react';
import AutomaticOnDemandSettings from '../../../../../components/AutomaticOnDemandSettings';

const IdentityVerification = ({ disabled }) => {
  return <AutomaticOnDemandSettings divider disabled={disabled} tableName="verificationRestrictions" />;
};

IdentityVerification.propTypes = {
  disabled: PropTypes.bool,
};
IdentityVerification.defaultProps = {
  disabled: false,
};
export default IdentityVerification;
