import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import toastr from 'toastr';
import api from '../../../../../api';
import Toggle from '../../../../../components/Toggle';
import { BatchContext } from '../../../../../utilities/services/contexts';

const BatchStatusToggle = ({ toggleState, setUpdating, id, onBatchAction }) => {
  const { listOfSelectedIDs } = useContext(BatchContext) || [];
  const caseIds = useMemo(() => {
    if (!listOfSelectedIDs.length) {
      return [id];
    }
    if (!listOfSelectedIDs.some(eId => eId === id)) {
      return [...listOfSelectedIDs, id];
    }
    return listOfSelectedIDs;
  }, [listOfSelectedIDs, id]);

  const handleCaseStatusChange = () => {
    setUpdating(caseIds);
    api.kyc.caseManagement
      .batchUpdateCaseStatus({ caseStatus: !toggleState ? 'Open' : 'Closed', complianceLogsIds: caseIds })
      .then(({ data }) => {
        onBatchAction(data);
        toastr.success(`Status was changed to ${!toggleState ? 'Open' : 'Closed'} `);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Error occurred during update');
      })
      .finally(() => {
        setUpdating([]);
      });
  };

  return (
    <Toggle
      onChange={handleCaseStatusChange}
      value={toggleState}
      showCustomText
      toggleOnText="Open"
      toggleOffText="Closed"
    />
  );
};

BatchStatusToggle.propTypes = {
  toggleState: PropTypes.bool,
  setUpdating: PropTypes.func,
  id: PropTypes.string,
  onBatchAction: PropTypes.func,
};

BatchStatusToggle.defaultProps = {
  toggleState: false,
  setUpdating: () => {},
  id: null,
  onBatchAction: () => {},
};
export default BatchStatusToggle;
