/* eslint-disable no-empty */
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import toastr from 'toastr';
import api from '../../../../../api';
import OutlineButton from '../../../../../components/Buttons/OutlineButton';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import Loader from '../../../../../components/Loader';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../components/SelectField';
import bus from '../../../../../modules/bus';
import enums from '../../../../../utilities/enums';
import capitalSplit from '../../../../../utilities/services/CapitalSplitter';
import IconManager from '../../../../../utilities/services/IconManager';
import viewRelatedEvents from '../../../../../utilities/services/RelatedEventsService';
import {
  EntityContext,
  HistoryRecordContext,
  PermissionGroupContext,
} from '../../../../../utilities/services/contexts';
import './index.scss';

const Controls = ({ info, onStatusChange, apiRequest }) => {
  const { portalConfigurationId, portalDomain, portalName } = info;
  const { entityId, isNaturalPerson } = useContext(EntityContext);
  const { eventHistoryId: historyId, issues, status: initialStatus, eventId } = useContext(HistoryRecordContext);

  const eventHistoryId = historyId || eventId;

  const portalConfigIdLabel = useMemo(() => {
    return isNaturalPerson ? `NPP${portalConfigurationId}` : `LEP${portalConfigurationId}`;
  }, [isNaturalPerson, portalConfigurationId]);

  const [initialValue, setInitialValue] = useState({
    historyRecordStatus: initialStatus,
  });

  const getViewRelatedEvents = useCallback(() => {
    viewRelatedEvents(entityId, isNaturalPerson, eventHistoryId, true);
  }, [entityId, isNaturalPerson, eventHistoryId]);

  const mappedStatuses = useMemo(
    () =>
      Object.entries(enums.HISTORY_EVENT_STATUSES_REDUCED).map(entry => ({
        id: entry[0],
        label: entry[1],
        value: entry[1],
      })),
    [],
  );

  const onSubmit = values => {
    return apiRequest(eventHistoryId, entityId, values.historyRecordStatus)
      .then(({ data: { comment, issuesCount = 0, status, issuesList = [], allRejected } }) => {
        const fromAcceptToAny =
          initialValue.historyRecordStatus === enums.HISTORY_EVENT_STATUSES.ACCEPTED &&
          status !== enums.HISTORY_EVENT_STATUSES.ACCEPTED;
        const fromAnyToAccept =
          initialValue.historyRecordStatus !== enums.HISTORY_EVENT_STATUSES.ACCEPTED &&
          status === enums.HISTORY_EVENT_STATUSES.ACCEPTED;

        if (fromAnyToAccept) {
          onStatusChange(-issues, [], status, allRejected);
          bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, {
            eventHistoryId,
            data: {
              status,
              issues: 0,
            },
          });
        } else if (fromAcceptToAny) {
          onStatusChange(issues + issuesCount, issuesList, status, allRejected);
          bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, {
            eventHistoryId,
            data: {
              status,
              issues: issues + issuesCount,
            },
          });
        } else {
          onStatusChange(issuesCount - issues, issuesList, status, allRejected);
          bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, {
            eventHistoryId,
            data: {
              status,
              issues: issuesCount,
            },
          });
        }
        if (comment) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, comment);
        }
        toastr.success(`Status has been set to ${status}.`);
        setInitialValue({ historyRecordStatus: status });
      })
      .catch(err => {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  const baseClass = 'ickyc-history-record-controls';
  const { edit: canEdit } = useContext(PermissionGroupContext);
  return canEdit ? (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValue}
      render={({ handleSubmit, submitting, submitError, form, modifiedSinceLastSubmit, pristine }) => (
        <form className={baseClass} onSubmit={handleSubmit}>
          {submitting && <Loader />}
          <div className="ickyc-history-record-controls__item">
            <b>Source Type:</b>

            {capitalSplit(info.sourceType || 'N/A')}

            <OutlineButton onClick={getViewRelatedEvents}> View Related Events </OutlineButton>
          </div>

          <div className="ickyc-history-record-controls__item">
            <b>Source Details :</b>
            {portalConfigurationId && portalDomain !== '' && portalName !== '' && (
              <div>
                <OutlineButton>
                  <b>Portal Configuration ID :&nbsp;</b>
                  {portalConfigIdLabel}
                </OutlineButton>
                <OutlineButton>
                  <b>Portal Name :&nbsp;</b>
                  {portalName}
                </OutlineButton>
                <OutlineButton>
                  <b>Portal Domain :&nbsp;</b>
                  {portalDomain}
                </OutlineButton>
              </div>
            )}
          </div>
          <div className="ickyc-history-record-controls__item">
            <b>Status :</b>

            <div className="ickyc-history-record-controls__item__block">
              <Field
                component={SelectField}
                name="historyRecordStatus"
                // renderAsPortal
                Trigger={
                  <SelectTrigger
                    placeholder={info?.status === 'Expired' || info?.status === 'Canceled' ? 'Canceled' : 'Status'}
                  />
                }
                Content={<SelectFilterContent />}
                options={mappedStatuses}
              />
              <div className="ickyc-history-record-controls__item__buttons">
                <PrimaryButton
                  onClick={() => {
                    form.reset({
                      historyRecordStatus: info.status,
                    });
                  }}
                  variant="red"
                  disabled={pristine || submitting}
                >
                  {IconManager.get(IconManager.names.CLOSE)} Reject Changes
                </PrimaryButton>

                <PrimaryButton type="submit" disabled={pristine || submitting}>
                  {IconManager.get(IconManager.names.CHECK_FILLED)}Accept Changes
                </PrimaryButton>
              </div>
            </div>
          </div>
          {submitError && !modifiedSinceLastSubmit && (
            <div className="ickyc-history-record-controls__item">
              <div className="ickyc-form__submissionError">{submitError}</div>
            </div>
          )}
        </form>
      )}
    />
  ) : (
    <div className={baseClass}>
      <div className="ickyc-history-record-controls__item">
        <b>Source Type:</b>

        {capitalSplit(info.sourceType || 'N/A')}

        <OutlineButton onClick={getViewRelatedEvents}> View Related Events </OutlineButton>
      </div>

      <div className="ickyc-history-record-controls__item">
        <b>Source Details :</b>
        {portalConfigurationId && portalDomain !== '' && portalName !== '' && (
          <div>
            <OutlineButton>
              <b>Portal Configuration ID :&nbsp;</b>
              {portalConfigIdLabel}
            </OutlineButton>
            <OutlineButton preview>
              <b>Portal Name :&nbsp;</b>
              {portalName}
            </OutlineButton>
            <OutlineButton disabled>
              <b>Portal Domain :&nbsp;</b>
              {portalDomain}
            </OutlineButton>
          </div>
        )}
      </div>
      <div className="ickyc-history-record-controls__item">
        <b>Status :</b>

        <div className="ickyc-history-record-controls__item__block">{initialValue.historyRecordStatus}</div>
      </div>
    </div>
  );
};
Controls.propTypes = {
  info: PropTypes.shape({
    sourceType: PropTypes.string,
    sourceDetail: PropTypes.string,
    status: PropTypes.string,
    portalConfigurationId: PropTypes.number,
    portalDomain: PropTypes.string,
    portalName: PropTypes.string,
  }),
  onStatusChange: PropTypes.func,
  apiRequest: PropTypes.func,
};

Controls.defaultProps = {
  info: {},
  onStatusChange: undefined,
  apiRequest: api.kyc.entityManagement.changeHistoryEventStatus,
};

export default Controls;
