import api from 'api';
import Spinner from 'components/Spinner';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { RCUContext } from 'utilities/services/contexts';
import GeneralLegalEntitySettings from '../../../components/RequestClientUpdateLegalEntityModal/components/GeneralLegalEntitySettings';

const BatchLegalEntityRCU = ({ setInitialValues, handleSelectedDomainGroup }) => {
  const [selectedDomainGroup, setSelectedDomainGroup] = useState();
  const [domainsConfigs, setDomainsConfigs] = useState([]);
  const [isLoadingDomains, setIsLoadingDomains] = useState(true);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [initialConfiguration, setInitialConfiguration] = useState({});
  const [loadingSettings, setIsLoadingSettings] = useState(true);
  const [domain, setDomain] = useState(undefined);

  const { batch, change } = useForm();

  useEffect(() => {
    const reloadData = () => {
      setIsLoadingSettings(true);
      api.kyc.entityManagement
        .getLESettingsForRCU(selectedDomainGroup.id, 'batch', domain)
        .then(({ data: responseData }) => {
          setInitialConfiguration(responseData);
        })
        .catch(err => {
          console.log(err);
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              setErrMessage(Array.isArray(errData.message) ? errData.message.join('') : errData.message);
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoadingSettings(false);
        });
    };
    if (selectedDomainGroup?.id && domain) reloadData();
  }, [selectedDomainGroup, domain]);

  useEffect(() => {
    if (selectedDomainGroup) {
      const defaultDomain = selectedDomainGroup.domains.find(dom => dom.isDefault);
      setDomain(defaultDomain ? defaultDomain.url : selectedDomainGroup[0]?.url);
      batch(() => {
        change(
          'legalEntityBatchClientUpdateDto.domain',
          defaultDomain ? defaultDomain.url : selectedDomainGroup[0]?.url,
        );
      }, []);
    }
  }, [selectedDomainGroup]);

  useEffect(() => {
    if (selectedDomain && selectedDomainGroup?.id) {
      const newDomainGroup = domainsConfigs.find(domainConfig =>
        domainConfig.domains.some(d => d.url === selectedDomain),
      );
      if (selectedDomainGroup.id !== newDomainGroup?.id) {
        setSelectedDomainGroup(newDomainGroup);
        handleSelectedDomainGroup(newDomainGroup);
      }
    }
  }, [selectedDomain, domainsConfigs, selectedDomainGroup]);

  useEffect(() => {
    const loadDomains = () => {
      setIsLoadingDomains(true);
      api.kyc.entityManagement
        .getLegalEntityDomains()
        .then(({ data: responseData }) => {
          setSelectedDomainGroup(responseData.find(config => config.isDefault) || responseData[0]);
          handleSelectedDomainGroup(responseData.find(config => config.isDefault) || responseData[0]);
          setDomainsConfigs(responseData);
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoadingDomains(false);
        });
    };
    loadDomains();
  }, []);

  const mappedDomainsList = useMemo(() => {
    return domainsConfigs
      .reduce((acc, v) => [...acc, ...v.domains], [])
      .map(domainEl => ({
        id: domainEl.url,
        value: domainEl.url,
        label: domainEl.url,
      }));
  }, [domainsConfigs]);
  return (
    <>
      {isLoadingDomains || loadingSettings ? (
        <Spinner />
      ) : (
        <div>
          <RCUContext.Provider value={{ initialConfiguration }}>
            <GeneralLegalEntitySettings
              namePrefix="legalEntityBatchClientUpdateDto"
              domains={mappedDomainsList}
              errMessage={errMessage}
              setSelectedDomain={setSelectedDomain}
            />
          </RCUContext.Provider>
        </div>
      )}
    </>
  );
};
BatchLegalEntityRCU.propTypes = {
  setInitialValues: PropTypes.func,
  handleSelectedDomainGroup: PropTypes.func,
};
BatchLegalEntityRCU.defaultProps = {
  setInitialValues: () => {},
  handleSelectedDomainGroup: () => {},
};

export default BatchLegalEntityRCU;
