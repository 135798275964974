/* eslint-disable no-shadow */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import uniqid from 'uniqid';
import './styles.scss';

const NestedListHeader = ({ title, onClick, items, right }) => {
  const isNestedList = useCallback(item => !!item.items?.length, []);
  const renderItems = useCallback(
    (items = [], nested = false, className) => {
      const nestedListId = uniqid('nested-list-');
      const getLineHeight = () => {
        if (items.filter(item => !isNestedList(item)).length === 1 && !nested) return 0;
        const lastDirectChildReverseIndex = [...items].reverse().findIndex(item => !isNestedList(item));
        const getTotalItemsLength = items =>
          items.reduce((acc, item) => {
            return isNestedList(item) ? acc + getTotalItemsLength(item.items) : acc + 1;
          }, 0);
        const totalItems = getTotalItemsLength(items);
        const directIndex = items.length - 1 - lastDirectChildReverseIndex;
        const indirectIndex = items
          .slice(0, directIndex + 1)
          .reduce((acc, item) => (isNestedList(item) ? getTotalItemsLength(item.items) + acc : acc + 1), 0);
        return (indirectIndex / totalItems) * 100;
      };
      const lineHeight = `${getLineHeight()}%`;
      return (
        <ul className={classNames(className, nestedListId)} key={nestedListId}>
          <style>
            {`
           .${nestedListId} {
             --line-height: ${lineHeight}; 
          }
          `}
          </style>
          {items.map(item => (isNestedList(item) ? renderItems(item.items, true) : <li key={nestedListId}>{item}</li>))}
        </ul>
      );
    },
    [isNestedList],
  );
  if (!items.length) return <>No items to render</>;

  return (
    <div className="ickyc-page__header">
      <div className="ickyc-page__header__left">
        <h1 className="ickyc-page__header__link" onClick={onClick}>
          {title}
        </h1>
        {renderItems(items, false, 'nested-list')}
      </div>
      {right && <div className="ickyc-page__header__right">{right}</div>}
    </div>
  );
};

NestedListHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  right: PropTypes.shape({}),
};
NestedListHeader.defaultProps = {
  onClick: () => {},
  items: [],
  right: undefined,
};
export default NestedListHeader;
