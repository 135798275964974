import React from 'react';
import AmexIcon from '../../../../../../../../assets/CardBrands/AmexIcon';
import DinersIcon from '../../../../../../../../assets/CardBrands/DinersIcon';
import DiscoverIcon from '../../../../../../../../assets/CardBrands/DiscoverIcon';
import JcbIcon from '../../../../../../../../assets/CardBrands/JcbIcon';
import MastercardIcon from '../../../../../../../../assets/CardBrands/MastercardIcon';
import UnionpayIcon from '../../../../../../../../assets/CardBrands/UnionpayIcon';
import VisaIcon from '../../../../../../../../assets/CardBrands/VisaIcon';

// keys correspond to brand names received from the API
const cardBrandToIcon = {
  MasterCard: <MastercardIcon />,
  'American Express': <AmexIcon />,
  Diners: <DinersIcon />,
  Discover: <DiscoverIcon />,
  Jcb: <JcbIcon />,
  Visa: <VisaIcon />,
  UnionPay: <UnionpayIcon />,
};

export default {
  cardBrandToIcon,
};
