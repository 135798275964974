import api from 'api';
import useSelect from 'hooks/useSelect';
import useTable from 'hooks/useTable';
import { useMemo } from 'react';
import { selectPersonalInfo } from 'store/selectors/auth.selector';

const defaultParams = {
  skip: 0,
  limit: 10,
  sortOrder: 1,
  searchString: null,
  filterOn: true,
};

export default function useMyWorkParams() {
  const { id } = useSelect(selectPersonalInfo);

  const initialCaseParams = useMemo(() => {
    return {
      ...defaultParams,
      sortCriteria: 'created',
      filters: {
        assignsToFlag: true,
        assignsTo: [id],
      },
    };
  }, [id]);

  const {
    pagination: casePagination,
    params: caseParams,
    records: cases,
    isLoading: isCaseFetching,
    changeParams: changeCaseParams,
  } = useTable(api.kyc.caseManagement.getCaseManagementTable, initialCaseParams, 'data');

  const initialEntityParams = useMemo(() => {
    return {
      ...defaultParams,
      sortCriteria: 'lastUpdated',
      filters: {
        assignedToFlag: true,
        assignedTo: [id],
      },
    };
  }, [id]);

  const {
    pagination: entityPagination,
    records: entities,
    params: entityParams,
    isLoading: isEntityFetching,
    changeParams: changeEntityParams,
  } = useTable(api.kyc.entityManagement.getEntitiesTable, initialEntityParams);

  return {
    entityPagination,
    entities,
    entityParams,
    isEntityFetching,
    changeEntityParams,
    casePagination,
    caseParams,
    cases,
    isCaseFetching,
    changeCaseParams,
  };
}
