import { CircularProgress } from '@material-ui/core';
import api from 'api';
import classNames from 'classnames';
import Accordion from 'components/Accordion';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import useModalHandler from 'hooks/useModalHandler';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ThirdPartyConfirmation from 'scenes/Kyc/EntityManagement/LegalEntityProfile/components/ThirdPartyConfirmation';
import IssueList from 'scenes/Kyc/EntityManagement/components/IssueList';
import toastr from 'toastr';
import IconManager from 'utilities/services/IconManager';
import { DocumentDataContext, EntityContext, HistoryRecordContext } from 'utilities/services/contexts';
import DocumentsPackageTable from '../DocumentsPackageTable';
import AdditionalItemModal from './components/AdditionalItemModal';
import './styles.scss';

function DocumentPackage() {
  const { documentRecord, setDocumentRecord, onRemove } = useContext(DocumentDataContext);
  const { eventHistoryId } = useContext(HistoryRecordContext);
  const { entityId } = useContext(EntityContext);
  const [additionalItems, setAdditionalItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const {
    close: closeAdditionalItemModal,
    isOpen: isOpenAdditionalItemModal,
    open: openAdditionalItemModal,
  } = useModalHandler();

  const baseClass = 'ickyc-document-package';

  const classes = classNames(baseClass);

  const onlyNotPopulatedItems = useCallback(
    data => data?.filter(item => !documentRecord?.items.some(existingItem => existingItem.itemId === item.id)),
    [documentRecord?.items],
  );

  const downloadAsPDF = useCallback(async () => {
    try {
      setIsDownloading(true);
      const { data } = await api.kyc.entityManagement.downloadSupportingDocumentPackageAsPDF(entityId, eventHistoryId);
      const blob = new Blob([data], { type: 'application/pdf' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'documentPackage.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toastr.success('Supporting package downloaded successfully');
    } catch (err) {
      console.error(err);
      toastr.error('Error occured while downloading supporting package');
    } finally {
      setIsDownloading(false);
    }
  }, [eventHistoryId]);

  useEffect(() => {
    const fetchadditionalItems = async () => {
      try {
        setIsLoading(true);

        const {
          data: { data },
        } = await api.administration.supportingDocumentPackages.getSupportingDocumentPackagesItems({
          packageId: documentRecord?.supportingDocumentPackageId,
        });

        const finalData = onlyNotPopulatedItems(data);

        setAdditionalItems(finalData);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchadditionalItems();
  }, [documentRecord?.supportingDocumentPackageId, setAdditionalItems, onlyNotPopulatedItems]);

  return (
    <Accordion title="Document Package" accordionOpen className={classes} onClick={e => e.stopPropagation()}>
      <IssueList list={documentRecord?.issues} />

      <DocumentsPackageTable items={documentRecord?.items} />

      <div className={`${baseClass}__buttons-box`}>
        <PrimaryButton disabled={additionalItems.length === 0} onClick={openAdditionalItemModal}>
          + Add Additional Item
        </PrimaryButton>

        <OutlineButton
          onClick={downloadAsPDF}
          left={
            isDownloading ? <CircularProgress color="inherit" size={18} /> : IconManager.get(IconManager.names.DOWNLOAD)
          }
          disabled={isDownloading}
        >
          Download as PDF
        </OutlineButton>
      </div>

      {documentRecord?.thirdPartySuggestion && (
        <ThirdPartyConfirmation
          data={documentRecord}
          onDataFetch={setDocumentRecord}
          onRemove={onRemove}
          isSupportingDocument
        />
      )}

      {isOpenAdditionalItemModal && (
        <AdditionalItemModal additionalItems={additionalItems} hideModal={closeAdditionalItemModal} />
      )}
    </Accordion>
  );
}
DocumentPackage.propTypes = {
  documentPackage: PropTypes.shape({}),
};
DocumentPackage.defaultProps = {
  documentPackage: {},
};

export default DocumentPackage;
