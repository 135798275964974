/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';
import api from '../../../api';
import CloseButton from '../../../components/Buttons/CloseButton';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import IComplyForm from '../../../components/Form/IComplyForm';
import BareHeading from '../../../components/Headings/BareHeading';
import InputField from '../../../components/InputField';
import Modal from '../../../components/Modal';
import Section from '../../../components/Section';
import Spinner from '../../../components/Spinner';
import useModalHandler from '../../../hooks/useModalHandler';
import { saveRiskLevels } from '../../../store/actions/global.action';
import { PermissionGroupContext } from '../../../utilities/services/contexts';
import './styles.scss';

/**
 * Risk levels page
 */

const RiskLevelsPage = ({ className }) => {
  const [initialValues, setInitialValues] = useState({});
  const [shouldForce, setShoudForce] = useState(false);
  const { close: closeModal, isOpen: isOpenModal, open: openModal } = useModalHandler();
  const [errorRiskLevelMsg, setErrorRiskLevelMsg] = useState('');
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(true);

  const riskLevelEnum = {
    ONETOFIVE: 'OneToFive',
    ONETOFOUR: 'OneToFour',
    ONETOTHREE: 'OneToThree',
  };

  const riskLevelMap = new Map([
    [riskLevelEnum.ONETOFIVE, 5],
    [riskLevelEnum.ONETOFOUR, 4],
    [riskLevelEnum.ONETOTHREE, 3],
  ]);

  useEffect(() => {
    setIsLoading(true);
    api.administration
      .getRiskLevels()
      .then(({ data }) => {
        setInitialValues({ ...data, riskLevelAscOrder: data.riskLevelAscOrder.toString() });
      })
      .finally(() => setIsLoading(false));
  }, []);

  const classes = classNames('ickyc-page', 'ickyc-risk-levels', className);
  const dispatch = useDispatch();

  const handleSubmit = async vals => {
    const { riskLevelLabels, riskLevelScale } = vals;
    let selectedRiskLevelLabales = [...riskLevelLabels];
    if (riskLevelScale === riskLevelEnum.ONETOFOUR)
      selectedRiskLevelLabales = selectedRiskLevelLabales.slice(0, riskLevelMap.get(riskLevelEnum.ONETOFOUR));
    else if (riskLevelScale === riskLevelEnum.ONETOTHREE)
      selectedRiskLevelLabales = selectedRiskLevelLabales.slice(0, riskLevelMap.get(riskLevelEnum.ONETOTHREE));

    closeModal();

    return api.administration
      .updateRiskLevels({ ...vals, riskLevelLabels: selectedRiskLevelLabales }, shouldForce)
      .then(response => {
        toastr.success('Successfully updated Risk Levels');
        dispatch(saveRiskLevels(response.data));
        setInitialValues(vals);
      })
      .catch(err => {
        console.log(err);
        if (err?.response) {
          console.log(err?.response);
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            if (Array.isArray(data?.message)) {
              if (data.message[0]?.includes('scale will reset current levels')) {
                setErrorRiskLevelMsg(data.message[0]);
                setShoudForce(true);
                openModal();
                return;
              }
              setShoudForce(false);
              return { [FORM_ERROR]: data.message[0] };
            }
            return {
              [FORM_ERROR]:
                (Array.isArray(data?.message) && data.message.join(' ')) ||
                data?.message ||
                data?.errors?.RecepientId ||
                'Error Occurred',
            };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        }
        return { [FORM_ERROR]: 'Error trying to Request Client Update' };
      });
  };

  const { edit: canEdit } = useContext(PermissionGroupContext);
  return (
    <div className={classes}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <BareHeading title="Risk Levels" />
          <div className="ickyc-page__content">
            <IComplyForm initialValues={initialValues} ref={formRef} onSubmit={handleSubmit} showControls={canEdit}>
              <Section
                title="Risk Levels"
                text={() => (
                  <p>
                    You are able to assign your users a rank based on how you perceive their level of risk.
                    <br />
                    <br />
                    <b>Choose the number of risk levels</b> you would like to use.
                  </p>
                )}
              >
                <div className="ickyc-risk-levels__radio-levels">
                  <label>
                    <Field
                      disabled={!canEdit}
                      component="input"
                      type="radio"
                      name="riskLevelScale"
                      value={riskLevelEnum.ONETOFIVE}
                    />
                    <div>
                      <div>1-5</div> <div>Risk Levels</div>
                    </div>
                  </label>

                  <label>
                    <Field
                      disabled={!canEdit}
                      component="input"
                      type="radio"
                      name="riskLevelScale"
                      value={riskLevelEnum.ONETOFOUR}
                    />
                    <div>
                      <div>1-4</div> <div>Risk Levels</div>
                    </div>
                  </label>

                  <label>
                    <Field
                      disabled={!canEdit}
                      component="input"
                      type="radio"
                      name="riskLevelScale"
                      value={riskLevelEnum.ONETOTHREE}
                    />
                    <div>
                      <div>1-3</div> <div>Risk Levels</div>
                    </div>
                  </label>
                </div>
              </Section>

              <Section
                title="Ascending / Descending Risk Order"
                text={() => (
                  <p>
                    Your risk ranking system can be set to either ascending or descending order of risk.
                    <br />
                    <br />
                    Choose whether you would like <b>higher numbers to indicate higher risk</b> or{' '}
                    <b>lower numbers to indicate higher risk.</b>
                  </p>
                )}
              >
                <div className="ickyc-risk-levels__radio-order">
                  <div>
                    <label>
                      <Field disabled={!canEdit} component="input" type="radio" name="riskLevelAscOrder" value="true" />
                      <h3>Ascending Order of Risk</h3>
                    </label>

                    <p>
                      Higher numbers indicate higher risk. On a 1-5 scale, a 1 is the lowest risk possible, while 5 is
                      the highest risk.
                    </p>
                  </div>

                  <div>
                    <label>
                      <Field
                        disabled={!canEdit}
                        component="input"
                        type="radio"
                        name="riskLevelAscOrder"
                        value="false"
                      />

                      <h3>Descending Order of Risk</h3>
                    </label>

                    <p>
                      Lower numbers indicate higher risk. On a 1-5 scale, a 1 is the highest risk possible, while 5 is
                      the lowest risk.
                    </p>
                  </div>
                </div>
              </Section>

              <Section
                title="Risk Level Labels"
                text={() => (
                  <p>
                    In addition to the numbering on each risk level, each one of them has a label.
                    <br />
                    <br />
                    <b>Choose each label</b> that you would like to appear beside each risk level.
                  </p>
                )}
              >
                <div className="ickyc-risk-levels__label-inputs">
                  <Field
                    disabled={!canEdit}
                    component={InputField}
                    key="risk-level-1"
                    label="Risk Level 1"
                    name="riskLevelLabels[0]"
                  />
                  <Field
                    disabled={!canEdit}
                    component={InputField}
                    key="risk-level-2"
                    label="Risk Level 2"
                    name="riskLevelLabels[1]"
                  />
                  <Field
                    disabled={!canEdit}
                    component={InputField}
                    key="risk-level-3"
                    label="Risk Level 3"
                    name="riskLevelLabels[2]"
                  />
                  <FormSpy subscription={{ values: true }}>
                    {({ values: { riskLevelScale } }) => (
                      <>
                        {riskLevelMap.get(riskLevelScale) === 4 && (
                          <Field
                            disabled={!canEdit}
                            component={InputField}
                            key="risk-level-4"
                            label="Risk Level 4"
                            initialValue="Medium-High Risk "
                            name="riskLevelLabels[3]"
                          />
                        )}
                        {riskLevelMap.get(riskLevelScale) === 5 && (
                          <>
                            <Field
                              disabled={!canEdit}
                              component={InputField}
                              key="risk-level-4"
                              label="Risk Level 4"
                              initialValue="Medium-High Risk "
                              name="riskLevelLabels[3]"
                            />
                            <Field
                              disabled={!canEdit}
                              component={InputField}
                              key="risk-level-5"
                              label="Risk Level 5"
                              initialValue="High Risk "
                              name="riskLevelLabels[4]"
                            />
                          </>
                        )}
                      </>
                    )}
                  </FormSpy>
                </div>
              </Section>

              {isOpenModal && (
                <Modal
                  open={isOpenModal}
                  className="ickyc-confirmation-modal"
                  relativeToParent={formRef.current}
                  hideModal={() => {
                    closeModal();
                    setShoudForce(false);
                  }}
                >
                  <div className="ickyc-form-modal ickyc-portal-management-modal ickyc-confirmation-risk-level-modal">
                    <h2 className="ickyc-form-modal__header">
                      Risk Levels Reset Confirmation
                      <CloseButton
                        transparent
                        onClick={() => {
                          closeModal();
                          setShoudForce(false);
                        }}
                      />
                    </h2>
                    <div className="ickyc-form-modal__content">{errorRiskLevelMsg}</div>
                    <div className="ickyc-form-modal__footer">
                      <PrimaryButton
                        variant="link"
                        type="button"
                        onClick={() => {
                          closeModal();
                          setShoudForce(false);
                        }}
                      >
                        Decline
                      </PrimaryButton>
                      <PrimaryButton type="submit">Confirm</PrimaryButton>
                    </div>
                  </div>
                </Modal>
              )}
            </IComplyForm>
          </div>
        </>
      )}
    </div>
  );
};

RiskLevelsPage.propTypes = {
  className: PropTypes.string,
};

RiskLevelsPage.defaultProps = {
  className: undefined,
};
export default RiskLevelsPage;
