import Toggle from 'components/Toggle';
import React from 'react';

import './styles.scss';

const SelectAllToggle = ({ value, onChange }) => (
  <Toggle preview value={value} onChange={onChange} label="Enable All" className="kyc-select-all-toggle" />
);

export default SelectAllToggle;
