/**
 * Responsive redux form input field with visible bold label.
 * @param {string} [props.label] - label text
 * @param {string} [props.type = 'text'] - input type
 * @param {string} [props.placeholder] - input placeholder
 * @param {boolean} [props.editable = true] - flag which notes if input is editable
 * @param {object} input - DO NOT pass prop manually, this is generated by Final-Form's Field component
 * @param {object} meta - metadata for input ({invalid, touched, error})
 */
import HelpIcon from '@material-ui/icons/Help';
import classNames from 'classnames';
import FieldDescription from 'components/FieldDescription';
import useMatchMedia from 'hooks/useMatchMedia';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import IconManager from '../../utilities/services/IconManager';
import Tooltip from '../Tooltip';
import './styles.scss';

const Input = ({
  label,
  type,
  onChange,
  placeholder,
  preview,
  disabled,
  className,
  required,
  errorMsg,
  hasError,
  hint,
  hintMessage,
  suffix,
  preffix,
  accentText,
  name,
  positiveNumbersOnly,
  topLabeled,
  description,
  max,
  min,
  readOnly,
  converterFunction,
  ...rest
}) => {
  const [inputType, setInputType] = useState(type);

  const isBelowTablet = useMatchMedia('isBelowTablet');

  const handleOnChange = useCallback(
    event => {
      const tempValue = event.target.value.trim();

      if (positiveNumbersOnly) {
        event.target.value = tempValue == 0 ? 1 : Math.abs(tempValue);
      }
      if (max !== undefined && tempValue > max) {
        event.target.value = max;
      }
      if (min !== undefined && (tempValue < min || tempValue === '')) {
        event.target.value = min;
      }

      if (converterFunction) {
        event.target.value = converterFunction(event.target.value);
      }

      onChange && onChange(event);
    },
    [onChange, max, min, positiveNumbersOnly],
  );

  const handleWheel = event => {
    const { activeElement } = document;

    if (activeElement.type === 'number' && activeElement === event.target) {
      activeElement.blur();
    }
  };

  const inputProps = useMemo(() => {
    return {
      onChange: handleOnChange,
      type: inputType,
      placeholder,
      disabled,
      name,
      ...rest,
    };
  }, [inputType, placeholder, disabled, onChange, rest]);

  const classBase = 'ickyc-input';

  const classes = classNames({
    [`${classBase}`]: true,
    [`${classBase}--disabled`]: disabled,
    [`${classBase}--preview`]: preview,
    [`${classBase}--error`]: hasError,
    [`${classBase}--required`]: !preview && required,
    [`${classBase}--red-colored-text`]: accentText,
    [`${classBase}--top-labeled`]: topLabeled || isBelowTablet,
    [className]: className,
  });

  return (
    <div className={classes}>
      {label && (
        <label htmlFor={name}>
          {label}
          {hint && <Tooltip trigger={<HelpIcon />} content={<span>{hint}</span>} />}
        </label>
      )}
      <div className={`${classBase}__content`}>
        <div className={`${classBase}__content__input-container`}>
          {preffix && <b className={`${classBase}__content--preffix`}>{preffix}</b>}

          <input
            id={name}
            autocomplete="off"
            {...inputProps}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
          />
          {suffix && <b className={`${classBase}__content--suffix`}>{suffix}</b>}
        </div>
        {hintMessage && <div className={`${classBase}__content--hint-message`}>{hintMessage}</div>}
        {type === 'password' && (
          <span
            className={`${classBase}__content__unmask-icon`}
            onClick={() => {
              setInputType(inputType === 'password' ? 'text' : 'password');
            }}
          >
            {inputType === 'password'
              ? IconManager.get(IconManager.names.EYE)
              : IconManager.get(IconManager.names.EYE_CROSSED)}
          </span>
        )}
        {hasError && <span htmlFor={name}>{errorMsg}</span>}
        {description && <FieldDescription text={description} />}
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  type: PropTypes.string,
  preview: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  // Generated by final-form's Field component

  rest: PropTypes.shape({}),
  className: PropTypes.string,
  errorMsg: PropTypes.string,
  hint: PropTypes.string,
  hasError: PropTypes.bool,
  hintMessage: PropTypes.string,
  suffix: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  preffix: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  accentText: PropTypes.bool,
  topLabeled: PropTypes.bool,
  description: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  readOnly: PropTypes.bool,
};

Input.defaultProps = {
  label: '',
  value: '',
  hintMessage: undefined,
  preview: false,
  placeholder: '',
  onChange: undefined,
  disabled: false,
  type: 'text',
  required: false,
  rest: {},
  hasError: false,
  errorMsg: undefined,
  className: undefined,
  hint: undefined,
  suffix: undefined,
  preffix: undefined,
  accentText: false,
  topLabeled: false,
  description: undefined,
  max: undefined,
  min: undefined,
  readOnly: undefined,
};

export default Input;
