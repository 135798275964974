import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';
import api from '../../../../../../../api';
import useGroupPermissions from '../../../../../../../hooks/useGroupPermissions';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import { PortalManagementContext } from '../../../../../../../utilities/services/contexts';
import PortalManagement from '../../../../../../Administration/PortalManagementPage';

const PortalsSection = ({ domain }) => {
  const { id } = useParams();
  const groupPermissions = useGroupPermissions(authEnums.PERMISSION_GROUP.BUSINESS_MANAGER);

  return (
    <>
      <PortalManagementContext.Provider
        value={{
          getAllNaturalPortalsRoute: api.businessManager.getPortalsConfigurationsNaturalPerson,
          getAllLegalEntityPortalsRoute: api.businessManager.getPortalsConfigurationsLegalEntity,

          // updateConfigurationLogoImage: api.businessManager.updateConfigurationLogoImage,
          createPortalsConfiguration: api.businessManager.createPortalsConfiguration,
          // updateConfigurationNP: api.businessManager.updatePortalsConfigurationNaturalPerson,
          // updateConfigurationLE: api.businessManager.updatePortalsConfigurationLegalEntity,
          // fetchExistingConfiguration: api.businessManager.getPortalsConfigurationById,
          deleteConfiguration: api.businessManager.deleteConfiguration,
          updateDefault: api.businessManager.updateDefault,
          // getRiskScreeningProfiles: api.businessManager.getRiskScreeningProfilesBusinessManager,
          withTitle: true,
          titleText: 'Portals',
          groupPermissions,
          clientId: id,
          domain,
        }}
      >
        <PortalManagement />
      </PortalManagementContext.Provider>
    </>
  );
};
PortalsSection.propTypes = {
  domain: PropTypes.string,
};
PortalsSection.defaultProps = {
  domain: '',
};
export default PortalsSection;
