import { stringify } from 'querystring';
import TokenStorage from '../../utilities/services/local-storage/TokenStorage';
import axiosInstance from '../axiosInstance';

const login = credentials => axiosInstance.post('/login', stringify(credentials));
const register = credentials => axiosInstance.post('/register', credentials);
const forgotPassword = credentials => axiosInstance.post('/forgot-password', credentials);
const confirmEmail = credentials => axiosInstance.post('/confirm-email', credentials);
const createNewPassword = credentials => axiosInstance.post('/reset-password', credentials);

// CREATE SEPARATE MODULE
const getTwoFactorAuthInfo = () => axiosInstance.get('/client-users/two-factor-authentication', {});
const enableTwoFactorAuthentication = token =>
  axiosInstance.get('/client-users/two-factor-authentication/enable', { token });

const verifyTwoFactorAuthenticator = data => axiosInstance.post('/client-users/two-factor-authentication/enable', data);

const confirmCodeTwoFactor = data => axiosInstance.post('/login/2fa', data);
const confirmRecoveryCodeTwoFactor = data => axiosInstance.post('/login/recovery-code', data);
const resetRecoveryCodes = () => axiosInstance.post('/client-users/two-factor-authentication/recovery-codes', {});
const resetAuthenticator = () => axiosInstance.post('/client-users/two-factor-authentication/reset', {});

const getEnableTwoFactorAuthenticationAnonymus = () => {
  return axiosInstance.get('/client-users/two-factor-authentication/configure', {
    params: { token: TokenStorage.token },
  });
};
const getBasicConfiguration = () => {
  return axiosInstance.get('/lalallal-basic', {
    params: { token: TokenStorage.token },
  });
};
const getUploadIdConfiguration = () => {
  return axiosInstance.get('/laalllalall-ahahsasa', {
    params: { token: TokenStorage.token },
  });
};

const enableTwoFactorAuthenticationAnonymus = data => {
  const { token, ...rest } = data;
  return axiosInstance.post(
    '/client-users/two-factor-authentication/configure',
    { ...rest },
    {
      params: {
        token,
      },
    },
  );
};
const logout = () => {
  const { refreshToken } = TokenStorage;
  return axiosInstance.post(
    '/logout',
    { refreshToken },
    {
      skipRefresh: true,
    },
  );
};

function refresh() {
  const { refreshToken } = TokenStorage;
  return axiosInstance
    .post(
      'refresh-token',
      {
        refreshToken,
      },
      {
        isRefresh: true,
      },
    )
    .then(res => res.data);
}

export default {
  createNewPassword,
  confirmEmail,
  forgotPassword,
  refresh,
  logout,
  login,
  register,
  getTwoFactorAuthInfo,
  enableTwoFactorAuthentication,
  verifyTwoFactorAuthenticator,
  confirmCodeTwoFactor,
  confirmRecoveryCodeTwoFactor,
  resetRecoveryCodes,
  resetAuthenticator,
  getEnableTwoFactorAuthenticationAnonymus,
  enableTwoFactorAuthenticationAnonymus,
  getBasicConfiguration,
  getUploadIdConfiguration,
};
