import React from 'react';

export default () => (
  <svg viewBox="0 0 89.7 90.7" fill="currentColor">
    <g transform="matrix(1.41318 0 0 1.41318 2124.917 28183.277)">
      <path d="M-1444.2-19915.9l-5.2-.7c-.6-2.1-1.4-4.1-2.5-6l3.2-4.2c.8-.9.8-2.2 0-3l-1-1-3.2-3.2-1-1c-.9-.8-2.2-.8-3 0l-4.2 3.2c-1.9-1.1-3.9-1.9-6-2.5l-.7-5.2c-.1-1.2-1-2.1-2.1-2.1h-7.3-.1-.2-.1v.5l-.7 5.2c-2.1.6-4.1 1.4-6 2.5l-4.2-3.2c-.9-.8-2.2-.8-3 0l-1 1-3.2 3.2-1 1c-.8.9-.8 2.2 0 3l3.2 4.2c-1.1 1.9-1.9 3.9-2.5 6l-5.2.7c-1.2.1-2.1 1-2.1 2.1v7.2c.1 1.2 1 2.1 2.1 2.1l5.2.7c.6 2.1 1.4 4.1 2.5 6l-3.2 4.2c-.8.9-.8 2.2 0 3l1 1 3.2 3.2 1 1c.9.8 2.2.8 3 0l4.2-3.2c1.9 1.1 3.9 1.9 6 2.5l.7 5.2c.1 1.2 1 2.1 2.1 2.1h7.2c1.2-.1 2.1-1 2.1-2.1l.7-5.2c2.1-.6 4.1-1.4 6-2.5l4.2 3.2c.9.8 2.2.8 3 0l1-1 3.2-3.2 1-1c.8-.9.8-2.2 0-3l-3.2-4.2c1.1-1.9 1.9-3.9 2.5-6l5.2-.7c1.2-.1 2.1-1 2.1-2.1v-7.2c-1.6.5-2.5-.4-3.7-.5zm-29.2 24.2c-10.2 0-18.4-8.3-18.4-18.4 0-10.2 8.2-18.4 18.4-18.4s18.4 8.2 18.4 18.4c0 10.1-8.2 18.4-18.4 18.4z" />
      <g transform="translate(853.358 562.336)">
        <path d="M-2319.7-20480h-.6l-8.7 8.7-4.1-4.1h-.6l-3.2 3.2v.6l7.6 7.6h.6l12.2-12.2v-.6z" />
      </g>
    </g>
  </svg>
);
