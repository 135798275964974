import PropTypes from 'prop-types';
import React from 'react';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import IconManager from '../../../../utilities/services/IconManager';
import './styles.scss';

const AcceptRejectCaptureControls = ({ onClick, disabled }) => {
  const globalValues = {
    ACCEPT: 'Accepted',
    REJECT: 'Rejected',
    CAPTURE: 'Captured',
  };

  return (
    <span className="ickyc-accept-reject-capture-control-buttons">
      <PrimaryButton onClick={() => onClick({ minimumAcceptableAgeStatus: globalValues.ACCEPT })} disabled={disabled}>
        {IconManager.get(IconManager.names.CHECK_FILLED)}
        Accept Selected
      </PrimaryButton>

      <PrimaryButton
        onClick={() => onClick({ minimumAcceptableAgeStatus: globalValues.REJECT })}
        variant="red"
        disabled={disabled}
      >
        {IconManager.get(IconManager.names.CLOSE)}
        Reject Selected
      </PrimaryButton>

      <PrimaryButton
        onClick={() => onClick({ minimumAcceptableAgeStatus: globalValues.CAPTURE })}
        variant="orange"
        disabled={disabled}
      >
        {IconManager.get(IconManager.names.ARCHIVE)}

        <span>Capture Selected</span>
      </PrimaryButton>
    </span>
  );
};

AcceptRejectCaptureControls.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

AcceptRejectCaptureControls.defaultProps = {
  onClick: () => {},
  disabled: true,
};
export default React.memo(AcceptRejectCaptureControls);
