import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import FormModal from '../../../../../components/Modal/FormModal';
import DateManager from '../../../../../utilities/services/DateManager';
import LicencesFilterForm from './components/LicencesFilterForm';

const LicencesFilterModal = ({ hideModal, onFilterSave, initialFilters }) => {
  const applyFilters = values => {
    const arraysValidation = [values.tags, values.updatedBy].some(arr => arr?.length > 0);
    const { dateFrom, dateUntil, tags, updatedBy } = values;
    const otherValidation = dateFrom || dateUntil || updatedBy || tags;
    if (!arraysValidation && !otherValidation) {
      return {
        [FORM_ERROR]: 'No Filters Selected ',
      };
    }
    if (DateManager.compareDates(dateFrom, dateUntil)) {
      return {
        [FORM_ERROR]: 'Date From Must Be Before Date Until ',
      };
    }
    onFilterSave(values);
    hideModal();
  };
  return (
    <FormModal
      onSubmit={applyFilters}
      title="Filter"
      initialValues={initialFilters}
      hideModal={hideModal}
      className="ickyc-filter-modal"
      renderAsPortal={false}
      showErrorsInConfirmation
    >
      <LicencesFilterForm />
    </FormModal>
  );
};
LicencesFilterModal.propTypes = {
  hideModal: PropTypes.func,
  onFilterSave: PropTypes.func,
  initialFilters: PropTypes.shape({}),
};
LicencesFilterModal.defaultProps = {
  initialFilters: {},
  hideModal: () => {},
  onFilterSave: () => {},
};
export default LicencesFilterModal;
