import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import RangeSlider from '../RangeSlider';
import './styles.scss';

/**
 * Range slider with (labeled) border on its sides.
 * @param {number} min - min range value
 * @param {number} max - max range value
 * @param {string} leftLabel - label upon left border
 * @param {string} rightLabel - label with right border
 * @param {boolean} withPercentage - will percentage be placed upon borders
 * @param {number} value - range slider value
 * @param {func} callback - callback function
 */

const FramedRangeSlider = ({ min, max, step, leftLabel, rightLabel, withPercentage, value, callback, disabled }) => {
  const leftLabelRef = useRef(null);
  const rightLabelRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const setMargin = (ref, isLeft = false) => {
      const { current } = ref;
      const { width } = current.getBoundingClientRect();

      const { current: content } = contentRef;

      const widhtInPixels = `${width / 3}px`;

      isLeft ? (content.style.marginLeft = widhtInPixels) : (content.style.marginRight = widhtInPixels);
    };

    if (leftLabelRef && rightLabelRef) {
      setMargin(leftLabelRef, true);
      setMargin(rightLabelRef);
    }
  }, []);

  return (
    <div className="ickyc-framed-slider">
      <div>
        <div ref={leftLabelRef}>
          {withPercentage && <span>0%</span>}
          <span>{leftLabel}</span>
        </div>

        <div ref={rightLabelRef}>
          {withPercentage && <span>100%</span>}
          <span>{rightLabel}</span>
        </div>
      </div>

      <div className="ickyc-framed-slider__content" ref={contentRef}>
        <RangeSlider
          min={min}
          max={max}
          step={step}
          initialValue={value}
          callback={callback}
          withFrame
          disabled={disabled}
        />
      </div>
    </div>
  );
};

FramedRangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  withPercentage: PropTypes.bool,
  value: PropTypes.number,
  callback: PropTypes.func,
  disabled: PropTypes.bool,
};

FramedRangeSlider.defaultProps = {
  min: 0,
  max: 0,
  step: 1,
  leftLabel: '',
  rightLabel: '',
  withPercentage: false,
  value: 0,
  disabled: false,
  callback: () => {},
};

export default FramedRangeSlider;
