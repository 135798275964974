import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import bus from '../../../../../../../../modules/bus';
import enums from '../../../../../../../../utilities/enums';
import { HistoryRecordContext } from '../../../../../../../../utilities/services/contexts';
import CollapsibleTableRow from '../../../../../../components/CollapsibleTableRow';
import CachedCollapsible from '../../../../../components/CachedCollapsible';
import HistoryRecordTableRow from '../../../../../components/HistoryRecordTableRow';
import IdentityData from '../IdentityData';

const IdentityTableRow = ({ rowInfo, className, onRemove }) => {
  const { eventHistoryId, issues, status } = rowInfo;
  /**
   * When record is being removed, KYC Issues have to be updated
   */
  const handleRemove = useCallback(
    eventId => {
      bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
        type: 'kyc',
        section: enums.ACCORDION_INDEXES.IDENTITY_DOCUMENTS,
        issuesChange: -issues,
      });
      onRemove(eventId);
    },
    [onRemove, issues],
  );

  return (
    <HistoryRecordContext.Provider value={rowInfo}>
      <CachedCollapsible
        collapsible={
          <CollapsibleTableRow
            className={className}
            row={<HistoryRecordTableRow className={className} onRemove={handleRemove} />}
          />
        }
        key={eventHistoryId}
        initialData={
          status === enums.HISTORY_EVENT_STATUSES.PENDING
            ? { message: `This request is still in progress, please come back later.` }
            : {}
        }
      >
        <IdentityData />
      </CachedCollapsible>
    </HistoryRecordContext.Provider>
  );
};

IdentityTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    assignedUserId: PropTypes.string,
    date: PropTypes.string.isRequired,
    eventHistoryId: PropTypes.string.isRequired,
    issues: PropTypes.number,
    status: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  onRemove: PropTypes.func,
  className: PropTypes.string.isRequired,
};
IdentityTableRow.defaultProps = {
  onRemove: () => {},
  rowInfo: {
    issues: 0,
    assignedUserId: null,
    assignedUserName: null,
  },
};

export default IdentityTableRow;
