import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import PrimaryButton from '../../../../../../../../../../components/Buttons/PrimaryButton';
import setClassSuffix from '../../../../../../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../../../../../../utilities/services/IconManager';
import './styles.scss';

const IdentityCheckList = ({ checklist, generatePdf, isGenerating, isFaceMatchAvailable }) => {
  const classBase = 'identity-checklist';
  const setSuffix = setClassSuffix(classBase);

  const pickStatus = isPassing => {
    const passIcon = IconManager.get(IconManager.names.CHECK_FILLED);
    const closeIcon = IconManager.get(IconManager.names.CLOSE);

    if (isPassing === null || isPassing === undefined) {
      return <span className={setSuffix('__content__item--pending')} />;
    }

    return isPassing ? (
      <span className={setSuffix('__content__item--success')}>{passIcon} Pass</span>
    ) : (
      <span className={setSuffix('__content__item--error')}>{closeIcon} Fail</span>
    );
  };

  return (
    <div className={classBase}>
      <h2>
        <span>Check List</span>
        <PrimaryButton onClick={generatePdf} disabled={isGenerating}>
          PDF Summary{isGenerating && <CircularProgress />}
        </PrimaryButton>
      </h2>
      <div className={setSuffix('__content')}>
        <div className={setSuffix('__content__item')}>
          Template Match
          {pickStatus(checklist.templateMatch)}
        </div>

        <div className={setSuffix('__content__item')}>
          Face Match
          {isFaceMatchAvailable ? (
            pickStatus(checklist.faceMatch)
          ) : (
            <span className={setSuffix('__content__item--unavailable')}>
              {IconManager.get(IconManager.names.PROHIBITED)} Unavailable
            </span>
          )}
        </div>

        <div className={setSuffix('__content__item')}>
          Authentication
          {pickStatus(checklist.authentication)}
        </div>

        <div className={setSuffix('__content__item')}>
          Data Match
          {pickStatus(checklist.dataMatch)}
        </div>
      </div>
    </div>
  );
};

IdentityCheckList.propTypes = {
  checklist: PropTypes.shape({
    templateMatch: PropTypes.bool,
    faceMatch: PropTypes.bool,
    authentication: PropTypes.bool,
    dataMatch: PropTypes.bool,
  }),
  generatePdf: PropTypes.func,
  isGenerating: PropTypes.bool,
};

IdentityCheckList.defaultProps = {
  checklist: {
    templateMatch: true,
    faceMatch: true,
    authentication: true,
    dataMatch: true,
  },
  generatePdf: () => {},
  isGenerating: false,
};

export default IdentityCheckList;
