import api from 'api';
import classNames from 'classnames';
import ModalTabHeader from 'components/Headers/ModalTabHeader';
import NestedListHeader from 'components/Headings/NestedListHeading';
import FormModal from 'components/Modal/FormModal';
import Spinner from 'components/Spinner';
import { FORM_ERROR } from 'final-form';
import useModalHandler from 'hooks/useModalHandler';
import history from 'modules/history';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import ProtectedComponent from 'router/components/ProtectedComponent';
import ApiKeysManagment from 'scenes/Administration/ApiKeysManagmentPage';
import CommentSection from 'scenes/Kyc/components/CommentSection';
import toastr from 'toastr';
import authEnums from 'utilities/enums/authEnums';
import commentTypes from 'utilities/enums/commentTypes';
import routesEnum from 'utilities/enums/routesEnum';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import BillingSection from './components/BillingSection';
import BusinessAccountSection from './components/BusinessAccountSection';
import BusinessLicenseSection from './components/BusinessLicenseSection';
import PortalsSection from './components/PortalsSection';
import UsageSection from './components/UsageSection';
import UsersInformationSection from './components/UsersInformationSection';
import './styles.scss';
import utils from './utils';

const BusinessProfile = ({ className }) => {
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState(0);
  const [initialValues, setInitialValues] = useState({ companyName: '' });
  const [isLoading, setIsLoading] = useState(true);
  const screens = useMemo(
    () => [
      <BusinessAccountSection />,
      <BusinessLicenseSection />,
      <UsersInformationSection />,
      <PortalsSection domain={initialValues?.domain} />,
      <UsageSection />,
      <BillingSection />,
      <CommentSection
        newCommentButton
        apiGetComments={api.businessManager.getComments(id)}
        apiGetReplies={api.businessManager.getReplies(id)}
        apiDeleteComment={api.businessManager.deleteComment(id)}
        apiEditComment={api.businessManager.updateComment(id)}
        apiCreateComment={api.businessManager.createNewComment(id)}
        apiCreateReply={api.businessManager.createNewReply(id)}
        type={commentTypes.ENTITY}
      />,
      <ApiKeysManagment clientAccountData={initialValues} />,
    ],
    [initialValues, id],
  );

  const goHistoryBack = useCallback(() => history.goBack(), []);

  const containerRef = useRef(null);
  const classes = classNames('ickyc-page', 'ickyc-business-profile', className);
  const { isOpen: isConfirmModalOpen, close: closeConfirmModal, open: openConfirmModal } = useModalHandler();

  useEffect(() => {
    const fetchBusinessInfo = () => {
      setIsLoading(true);
      api.businessManager
        .getClientAccount(id)
        .then(({ data: responseData }) => {
          const { domain = '' } = responseData;
          setInitialValues({ ...responseData, domain: domain || '' });
        })
        .catch(err => {
          if (err?.response) {
            const { status, data } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(data.message)
                  ? data.message.join('')
                  : data.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchBusinessInfo();
  }, []);

  const handleOrganizationDelete = () => {
    return api.businessManager
      .deleteOrganization(id)
      .then(() => {
        toastr.success(`${initialValues.companyName} has been deleted`);

        closeConfirmModal();
        history.push(routesEnum.internal.BUSINESS_MANAGER);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
          }
          if (resStatus === 500) {
            return {
              [FORM_ERROR]: Array.isArray(data.message)
                ? data.message.join('')
                : data.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  const optionClick = useCallback(value => {
    setActiveTab(value);
  }, []);

  if (isLoading) {
    return (
      <div className={classes} ref={containerRef}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={classes} ref={containerRef}>
      <NestedListHeader
        title="Business Details"
        onClick={goHistoryBack}
        right={
          <ProtectedComponent
            requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.delete]}
            permissionGroup={authEnums.PERMISSION_GROUP.BUSINESS_MANAGER}
          >
            <DeleteButton onClick={openConfirmModal} />
          </ProtectedComponent>
        }
        items={[
          <>
            <b>{initialValues.companyName}</b>
          </>,
        ]}
      />
      {isConfirmModalOpen && (
        <FormModal
          hideModal={closeConfirmModal}
          title="Delete Organization"
          className="ickyc-confirmation-modal"
          onSubmit={handleOrganizationDelete}
        >
          <span className="ickyc-confirmation-message">
            Are you sure you want to delete &nbsp;
            <b>{initialValues.companyName}</b>?
          </span>
        </FormModal>
      )}
      <ModalTabHeader onClick={optionClick} options={utils.businessDetailsTabs} />
      {screens[activeTab]}
    </div>
  );
};
BusinessProfile.propTypes = {
  className: PropTypes.string,
};

BusinessProfile.defaultProps = {
  className: undefined,
};
export default BusinessProfile;
