import licenceEnums from 'utilities/enums/licenceEnums';
import DateManager from 'utilities/services/DateManager';

const convertNumberObjectPropToString = object =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: typeof value === 'number' ? `${value}` : value,
    }),
    {},
  );

const convertStringObjectPropToNumber = object =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: typeof value === 'string' ? +value : value,
    }),
    {},
  );

const prepareValuesForModal = vals => {
  const {
    tags,
    defaultValues: {
      kycBillingCycle,
      overageBillingCycle,
      saasBillingCycle,
      npPortals,
      lePortals,
      saasFee,
      kycServicePlan,
      overageRate,
      leCustomUrls,
      npCustomUrls,
      numOfAdminUsersDefault,
      numOfUsersDefault,
      riskScreeningLimit,
      supportingDocumentsLimit,
      paymentDueType,
      ...restDefaultValues
    },
    ...rest
  } = vals;

  return {
    tags: tags?.split('|'),
    defaultValues: {
      kycBillingCycle: String(kycBillingCycle),
      overageBillingCycle: String(overageBillingCycle),
      saasBillingCycle: String(saasBillingCycle),
      npPortals: String(npPortals),
      lePortals: String(lePortals),
      saasFee: String(saasFee),
      kycServicePlan: String(kycServicePlan),
      overageRate: String(overageRate),
      leCustomUrls: String(leCustomUrls),
      npCustomUrls: String(npCustomUrls),
      numOfAdminUsersDefault: String(numOfAdminUsersDefault),
      numOfUsersDefault: String(numOfUsersDefault),
      riskScreeningLimit: String(riskScreeningLimit),
      supportingDocumentsLimit: String(supportingDocumentsLimit),
      paymentDueType: String(paymentDueType),
      ...restDefaultValues,
    },
    ...rest,
  };
};

const normalizeModalValues = vals => {
  const {
    tags,
    defaultValues: {
      kycBillingCycle,
      overageBillingCycle,
      saasBillingCycle,
      npPortals,
      lePortals,
      saasFee,
      kycServicePlan,
      overageRate,
      leCustomUrls,
      npCustomUrls,
      numOfAdminUsersDefault,
      numOfUsersDefault,
      riskScreeningLimit,
      supportingDocumentsLimit,
      paymentDueType,
      ...restDefaultValues
    },
    ...rest
  } = vals;

  return {
    tags: tags?.join('|'),
    defaultValues: {
      kycBillingCycle: Number(kycBillingCycle) || 0,
      overageBillingCycle: Number(overageBillingCycle) || 0,
      saasBillingCycle: Number(saasBillingCycle) || 0,
      npPortals: Number(npPortals) || 0,
      lePortals: Number(lePortals) || 0,
      saasFee: Number(saasFee) || 0,
      kycServicePlan: Number(kycServicePlan) || 0,
      overageRate: Number(overageRate) || 0,
      leCustomUrls: Number(leCustomUrls) || 0,
      npCustomUrls: Number(npCustomUrls) || 0,
      numOfAdminUsersDefault: Number(numOfAdminUsersDefault) || 0,
      numOfUsersDefault: Number(numOfUsersDefault) || 0,
      riskScreeningLimit: Number(riskScreeningLimit) || 0,
      supportingDocumentsLimit: Number(supportingDocumentsLimit) || 0,
      paymentDueType: Number(paymentDueType) || 0,
      ...restDefaultValues,
    },
    ...rest,
  };
};

const normalizeLicenceData = vals => {
  const { licences } = vals;
  const normalizedLicences = licences.map(licc => {
    const { defaultValues } = normalizeModalValues(licc);
    return {
      clientLicence: {
        ...licc,
        startDate: DateManager.toBekend(licc?.startDate),
        endDate: DateManager.toBekend(licc?.endDate),
        defaultValues: {
          ...defaultValues,
        },
        kycServices:
          licc?.kycServices
            ?.filter(service => !!service?.type && !!service?.price)
            .map(srvc => ({ type: Number(srvc?.type), price: Number(srvc?.price) })) || [],
        licenceStatus: Number(licc?.licenceStatus),
      },
      status: licc?.status,
    };
  });

  return normalizedLicences;
};

const parseLicenceData = licences => {
  const parsedLicences = licences.map(licc =>
    licc.type === 1
      ? {
          ...prepareWebsiteLicence(licc),
          kycServices: licc.kycServices.map(service => ({ type: service?.type?.toString(), price: service?.price })),
          licenceStatus: licc?.licenceStatus?.toString(),
          status: licenceEnums.LICENCE_STATUS_OPTIONS.EXISTING,
        }
      : {
          ...licc,
          defaultValues: {
            ...licc?.defaultValues,
            paymentDueType: licc?.defaultValues?.paymentDueType?.toString(),
            kycBillingCycle: licc?.defaultValues?.kycBillingCycle?.toString(),
            saasBillingCycle: licc?.defaultValues?.saasBillingCycle?.toString(),
            overageBillingCycle: licc?.defaultValues?.overageBillingCycle?.toString(),
          },
          kycServices: licc.kycServices.map(service => ({ type: service?.type?.toString(), price: service?.price })),
          licenceStatus: licc?.licenceStatus?.toString(),
          status: licenceEnums.LICENCE_STATUS_OPTIONS.EXISTING,
        },
  );

  return parsedLicences;
};

function normalizeWebsiteLicence(inputObject) {
  const { platformAccess, administrationAccess, defaultValues, tags, ...rest } = inputObject;

  const setupAndMaintenance = {
    ...rest.setupAndMaintenance,
    oneTimeSetupFee: Number(rest.setupAndMaintenance.oneTimeSetupFee) || 0,
    recurringMaintenance: Number(rest.setupAndMaintenance.recurringMaintenance) || 0,
    recurringMaintenancePeriod: Number(rest.setupAndMaintenance.recurringMaintenancePeriod) || 0,
    saasFee: Number(rest.setupAndMaintenance.saasFee) || 0,
    saasFeePeriod: Number(rest.setupAndMaintenance.saasFeePeriod) || 0,
  };

  const modules = Object.entries(rest.modules).reduce(
    (acc, [moduleName, moduleValue]) => ({
      ...acc,
      [moduleName]:
        moduleValue && typeof moduleValue === 'object' && !Array.isArray(moduleValue)
          ? convertStringObjectPropToNumber(moduleValue)
          : moduleValue,
    }),
    {},
  );

  return {
    ...rest,
    tags: tags?.join('|'),
    setupAndMaintenance,
    modules,
  };
}

function prepareWebsiteLicence(inputObject) {
  const { setupAndMaintenance, modules, tags, addOns } = inputObject;

  const setupAndMaintenancePrepared = {
    ...setupAndMaintenance,
    oneTimeSetupFee: String(setupAndMaintenance.oneTimeSetupFee),
    recurringMaintenance: String(setupAndMaintenance.recurringMaintenance),
    recurringMaintenancePeriod: String(setupAndMaintenance.recurringMaintenancePeriod),
    saasFee: String(setupAndMaintenance.saasFee),
    saasFeePeriod: String(setupAndMaintenance.saasFeePeriod),
    ...(setupAndMaintenance.addonsFee && { addonsFee: String(setupAndMaintenance.addonsFee) }),
  };

  const preparedModules = Object.entries(modules).reduce(
    (acc, [moduleName, moduleValue]) => ({
      ...acc,
      [moduleName]:
        moduleValue && typeof moduleValue === 'object' && !Array.isArray(moduleValue)
          ? convertNumberObjectPropToString(moduleValue)
          : moduleValue,
    }),
    {},
  );

  let preparedAddOns = {};

  if (addOns) {
    preparedAddOns = Object.entries(addOns).reduce(
      (acc, [moduleName, moduleValue]) => ({
        ...acc,
        [moduleName]:
          moduleValue && typeof moduleValue === 'object' && !Array.isArray(moduleValue)
            ? convertNumberObjectPropToString(moduleValue)
            : moduleValue,
      }),
      {},
    );
  }

  return {
    ...inputObject,
    tags: tags?.split('|'),
    setupAndMaintenance: setupAndMaintenancePrepared,
    modules: preparedModules,
    ...(addOns && { addOns: preparedAddOns }),
  };
}

export default {
  normalizeLicenceData,
  prepareValuesForModal,
  normalizeModalValues,
  parseLicenceData,
  normalizeWebsiteLicence,
  prepareWebsiteLicence,
};
