import React from 'react';

export default () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 53 53.5" style={{ enableBackground: 'new 0 0 53 53.5' }}>
      <g>
        <path
          style={{
            opacity: 0.25,
            enableBackground: 'new',
          }}
          d="M51.2,27.6c0,13.1-10.6,23.7-23.7,23.7S3.7,40.7,3.7,27.6S14.3,3.9,27.5,3.9C40.5,3.9,51.2,14.5,51.2,27.6z"
        />
        <path
          style={{
            fill: '#009245',
          }}
          d="M49.2,25.2c0,13.1-10.6,23.7-23.7,23.7S1.8,38.3,1.8,25.2S12.4,1.5,25.5,1.5C38.6,1.5,49.2,12.1,49.2,25.2z"
        />
        <path
          style={{
            fill: '#37b34a',
          }}
          d="M46.5,25.2c0,11.6-9.4,21-21,21c-11.6,0-21-9.4-21-21c0-11.6,9.4-21,21-21C37.1,4.2,46.5,13.6,46.5,25.2z"
        />
        <g>
          <radialGradient
            id="SVGID_1_"
            cx="17.364"
            cy="34.5919"
            r="25.3835"
            gradientTransform="matrix(1 0 0 -1 0 54)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" style={{ stopColor: '#FFFFFF' }} />
            <stop offset="1" style={{ stopColor: '#FFFDF3' }} />
          </radialGradient>
          <polygon
            style={{
              fill: '#ffffff',
            }}
            points="16.8,32.1 11.7,27.1 16,22.8 21.5,27.8 35,15.1 39.4,19.5 21.6,36.6 		"
          />
        </g>
      </g>
    </svg>
  );
};
