import RiskLevelsPage from '../../../../../../../../scenes/Administration/RiskLevelsPage';
import RiskScoringPage from '../../../../../../../../scenes/Administration/RiskScoringPage';
import RiskScreeningPage from '../../../../../../../../scenes/Administration/RiskScreeningPage';
import SupportingDocumentsPage from '../../../../../../../../scenes/Administration/SupportingDocumentsPage';
import SupportingPackageDetailsPage from '../../../../../../../../scenes/Administration/SupportingDocumentsPage/components/SupportingPackageDetailsPage';
import authEnums from '../../../../../../../../utilities/enums/authEnums';
import redirect from '../../../../../../../modules/redirect';
import QuickSetupSubrouter from '../components/QuickSetupSettingsSubrouter';

export default [
  {
    path: '/risk-screening',
    component: RiskScreeningPage,
  },
  {
    path: '/supporting-documents',
    component: SupportingDocumentsPage,
  },
  {
    path: '/supporting-documents/:id',
    component: SupportingPackageDetailsPage,
  },
  {
    path: '/risk-levels',
    component: RiskLevelsPage,
  },
  {
    path: '/quick-setup-subrouter',
    component: QuickSetupSubrouter,
    withPermissionsCheck: true,
    exact: false,
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    permissionGroup: authEnums.PERMISSION_GROUP.COMPLIANCE_SETTINGS,
  },
  {
    path: '/risk-scoring',
    component: RiskScoringPage,
  },
  {
    path: '*',
    component: redirect('/administration/compliance-settings/supporting-documents', true),
  },
];
