import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { SelectContext } from '../..';
import IconManager from '../../../../utilities/services/IconManager';
import './styles.scss';

const SelectContent = ({ ContentComponent }) => {
  const {
    hoverIndex,
    onChange,
    filterProps: { suggestions },
  } = useContext(SelectContext);

  return (
    <div className="ickyc-select-content">
      <ul>
        {suggestions.map((suggestion, index) => {
          const optionClasses = classNames({
            hovered: hoverIndex === index,
            selected: false,
            [suggestion.className]: suggestion.className,
          });

          return (
            <li
              className={optionClasses}
              key={suggestion.id}
              onClick={e => {
                e.stopPropagation();
                onChange(suggestion);
              }}
            >
              {ContentComponent ? <ContentComponent {...suggestion} /> : suggestion.label}
              {false && <span>{IconManager.get(IconManager.names.CHECK_FILLED)}</span>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SelectContent.propTypes = {
  ContentComponent: PropTypes.node,
};
SelectContent.defaultProps = {
  ContentComponent: undefined,
};
export default SelectContent;
