import React from 'react';

export default () => (
  <svg viewBox="0 0 38 38" style={{ enableBackground: 'new 0 0 38 38' }}>
    <g>
      <path
        fill="currentColor"
        d="M19,0C8.5,0,0,8.5,0,19s8.5,19,19,19s19-8.5,19-19S29.5,0,19,0z M22.5,35.6V27c-1.1,0.5-2.3,0.7-3.5,0.7
       c-1.2,0-2.4-0.3-3.5-0.7v8.7C7.8,34,2,27.2,2,19C2,9.6,9.6,2,19,2s17,7.6,17,17C36,27.2,30.2,34,22.5,35.6z"
      />
      <circle cx="19" cy="19" r="6.7" fill="currentColor" />
    </g>
  </svg>
);
