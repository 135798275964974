import { IconButton } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import api from 'api';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toastr from 'toastr';
import PrimaryButton from '../../../../../../../../../components/Buttons/PrimaryButton';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import './styles.scss';

const LogoUploadInput = ({ input: { onChange, value }, withActions }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const baseClass = 'ickyc-logo-upload';
  const setSuffix = setClassSuffix(baseClass);

  const onDrop = useCallback(
    async acceptedFile => {
      const imageBlobUrl = URL.createObjectURL(acceptedFile[0]);
      onChange({
        file: acceptedFile[0],
        preview: imageBlobUrl,
      });
    },
    [onChange],
  );

  const handleDelete = useCallback(async () => {
    try {
      setIsDeleting(true);
      await api.administration.account.deleteCompanyLogo(value);
      toastr.success('Successfully removed Company Logo');
      onChange();
    } catch (err) {
      console.error(err);
      toastr.error('Error occured while removing Company Logo');
    } finally {
      setIsDeleting(false);
    }
  }, [value]);

  const { getRootProps, getInputProps } = useDropzone({ accept: 'image/*', onDrop, multiple: false });

  const classes = classNames(baseClass, { [`${baseClass}--with-actions`]: withActions });
  return (
    <div className={classes}>
      <input {...getInputProps()} />
      <div className={setSuffix('__logo-cube')}>{value?.preview && <img src={value?.preview} alt="Preview" />}</div>
      {value?.preview && withActions ? (
        <div className={setSuffix('__actions')}>
          <PrimaryButton variant="red" disabled={isDeleting} onClick={handleDelete}>
            Remove
          </PrimaryButton>
          <PrimaryButton {...getRootProps()}>Replace</PrimaryButton>
        </div>
      ) : (
        <div className={setSuffix('__logo-uploader')} {...getRootProps()}>
          <p>
            <b>Upload company logo</b>
            <IconButton>
              <CloudUpload />
            </IconButton>
          </p>
          <PrimaryButton variant="link">
            <b>Upload image file &nbsp;</b> or drag it here
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

LogoUploadInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.shape({
      file: PropTypes.instanceOf(File),
      preview: PropTypes.string,
    }),
    onChange: PropTypes.func,
  }),
  withActions: PropTypes.bool,
};

LogoUploadInput.defaultProps = {
  input: undefined,
  withActions: false,
};

export default LogoUploadInput;
