const SUPPORT_PLAN_OPTIONS = ['Basic', 'Priority', 'Premium'];

const mapValuesArrayToOptions = valuesArray => valuesArray.map(value => ({ id: value, label: value, value }));

const mapIComplyTeamToOptions = usersArray =>
  usersArray.map(({ userId, name }) => ({ id: userId, label: name, value: userId }));

export default {
  SUPPORT_PLAN_OPTIONS,
  mapValuesArrayToOptions,
  mapIComplyTeamToOptions,
};
