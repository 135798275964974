import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import utilities from '../../../../utilities';
import routesEnum from '../../../../utilities/enums/routesEnum';
import setClassSuffix from '../../../../utilities/services/ClassManager';
import DateManager from '../../../../utilities/services/DateManager';

const TilesTableRow = ({ rowInfo: { date, id, name, type }, className }) => {
  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const history = useHistory();

  const navigateToItem = () => {
    switch (type) {
      case 0: {
        history.push(
          utilities.routeParams(routesEnum.kyc.LEGAL_ENTITY, {
            id,
          }),
        );
        break;
      }

      case 1: {
        history.push(
          utilities.routeParams(routesEnum.kyc.NATURAL_PERSON, {
            id,
          }),
        );
        break;
      }
      case 2: {
        history.push(utilities.routeParams(routesEnum.kyc.CASE_DETAIL, { id }));
        break;
      }
      default:
        break;
    }
  };
  return (
    <tr key={id} className={`${className}__row`} onClick={navigateToItem}>
      <td className={setColumnSuffix('__name')}>
        {utilities.getIconBasedOnType(type)}
        <span>{name}</span>
      </td>
      <td className={setColumnSuffix('__dateTime')}>
        <span>{date ? DateManager.monDayYearLocal(date) : ''}</span>
      </td>
    </tr>
  );
};

TilesTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.number,
  }),
  className: PropTypes.string,
};

TilesTableRow.defaultProps = {
  className: '',
  rowInfo: {},
};
export default TilesTableRow;
