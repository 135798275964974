import classNames from 'classnames';
import PropTypes, { string } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';
import OutlineButton from '../../../../../components/Buttons/OutlineButton';
import routesEnum from '../../../../../utilities/enums/routesEnum';
import './styles.scss';

/**
 * Issue List display list of detected issues.
 * @param {array} list - list of issues
 */

const IssueList = ({ list = [] }) => {
  const classes = classNames({
    'ickyc-issues-list': true,
    'ickyc-issues-list--empty': list.length === 0,
  });

  const history = useHistory();

  const navigateToEntity = ({ searchParam }) => {
    const encodedSearchParam = encodeURIComponent(searchParam);
    history.push(`${routesEnum.kyc.ENTITY_MANAGEMENT}?fullySearchParam=${encodedSearchParam}`);
  };

  return (
    <div className={classes}>
      <h3>Issues</h3>

      {list.length !== 0 ? (
        <ul>
          {list.map(item => {
            const items = item.split('#');

            if (items.length > 1) {
              return (
                <li key={uuid()} className="ickyc-history-record-controls__item">
                  <b>{items[0]}</b>
                  <OutlineButton onClick={() => navigateToEntity({ searchParam: items[1] })}>{items[1]}</OutlineButton>
                </li>
              );
            }

            return (
              <li key={uuid()}>
                <b>{item}</b>
              </li>
            );
          })}
        </ul>
      ) : (
        <span>No Issues Found</span>
      )}
    </div>
  );
};

IssueList.propTypes = {
  list: PropTypes.arrayOf(string),
};

IssueList.defaultProps = {
  list: [],
};
export default IssueList;
