import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import validators from '../../../../../../../../../utilities/services/validators';
import './styles.scss';

const ApiKeyForm = () => {
  const classBase = 'ickyc-create-new-api-key-form';
  return (
    <div className={classBase}>
      <Field component={InputField} required label="Name" name="ApiKey.name" validate={validators.required()} />
    </div>
  );
};
export default ApiKeyForm;
