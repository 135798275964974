const placeholderDescriptions = {
  '{{ENTITY NAME}}': "{{ENTITY NAME}}: Recipient's name in KYC module",
  '{{LIST OF REQUESTS}}': '{{LIST OF REQUESTS}}: Enabled requests list',
  '{{LINK}}': '{{LINK}}: Clickable link',
  '{{PLAIN LINK}}': '{{PLAIN LINK}}: Full URL link',
  '{{EMAIL}}': '{{EMAIL}}: Support email',
  '{{COMPANY NAME}}': '{{COMPANY NAME}}: Company name',
  '{{CUSTOM EMAIL MESSAGE}}': '{{CUSTOM EMAIL MESSAGE}}: Email message content',
  '{{CLIENT NAME}}': '{{CLIENT NAME}}: Client user name',
  '{{COUNT}}': '{{COUNT}}: General numerical count',
};

export default {
  placeholderDescriptions,
};
