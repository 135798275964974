import { Box, CircularProgress, IconButton, Typography } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { HeaderRow, ListCell, ListWrapper, Row } from './OrganizationTable.components';
import './styles.scss';

const OrganizationsTable = ({
  isLoading,
  records,
  params: { source },
  selectedEntitiesBusinessNumber,
  onAddOrganization,
  onLoadMore,
}) => {
  const countries = useSelector(state => state.global.countries || []);

  const handleScroll = useCallback(
    event => {
      if (isLoading) return;
      const { scrollTop, scrollHeight, offsetHeight } = event.currentTarget;
      const isScrollToBottom = scrollTop + offsetHeight >= scrollHeight - 40;
      if (isScrollToBottom) onLoadMore();
    },
    [isLoading, onLoadMore],
  );

  const getFullAddressInfo = useCallback(
    info => {
      const { unit, streetAddress, city, region, postalZip } = info;
      const firstRow = [unit, streetAddress].filter(item => !!item).join(' ');
      const secondRow = [city, region, postalZip]
        .filter(item => !!item)
        .join(', ')
        .replace(/,([^,]*)$/, '$1');

      return (
        <>
          {firstRow && <div>{firstRow}</div>}
          {secondRow && <div>{secondRow}</div>}
        </>
      );
    },
    [countries],
  );

  const getCountryName = useCallback(
    info => {
      const { country: countryAlphaCode } = info;
      return countries.find(countryData => countryData.alpha2Code === countryAlphaCode)?.name;
    },
    [countries],
  );

  return (
    <ListWrapper onScroll={handleScroll}>
      <HeaderRow>
        <ListCell alias="name">
          <Typography className="organizations-table__theme-font-family" variant="inherit">
            Name
          </Typography>
        </ListCell>
        <ListCell alias="lei-duns">
          <Typography className="organizations-table__theme-font-family" variant="inherit">
            {`${source?.toUpperCase()} Number`}
          </Typography>
        </ListCell>
        <ListCell alias="address">
          <Typography className="organizations-table__theme-font-family" variant="inherit">
            Address
          </Typography>
        </ListCell>
        <ListCell alias="country">
          <Typography className="organizations-table__theme-font-family" variant="inherit">
            Country
          </Typography>
        </ListCell>
        <ListCell alias="checkbox" />
      </HeaderRow>
      {records?.length === 0 ? (
        <Typography variant="inherit">No results found. Check spelling or try different source.</Typography>
      ) : (
        <>
          <Box className="organizations-table__radio-group">
            {records?.map(record => (
              <Row key={record.number}>
                <Box className="organizations-table__info-wrapper">
                  <Box className="organizations-table__organization-info">
                    <ListCell alias="name">
                      <Typography variant="inherit">{record.name}</Typography>
                    </ListCell>
                    <ListCell alias="lei-duns">
                      <Typography variant="inherit">{record.number}</Typography>
                    </ListCell>
                    <ListCell alias="address">
                      <Typography variant="inherit">{getFullAddressInfo(record.additionalInfo)}</Typography>
                    </ListCell>
                    <ListCell alias="country">
                      <Typography variant="inherit">{getCountryName(record.additionalInfo)}</Typography>
                    </ListCell>
                    <ListCell alias="checkbox">
                      <IconButton
                        className="organizations-table__plus-btn"
                        disabled={selectedEntitiesBusinessNumber.includes(record.number)}
                        size="small"
                        onClick={e => {
                          e.stopPropagation();
                          onAddOrganization(record);
                        }}
                      >
                        <AddCircleOutline />
                      </IconButton>
                    </ListCell>
                  </Box>
                </Box>
              </Row>
            ))}
            {isLoading && (
              <Box className="organizations-table__loading-more">
                <CircularProgress size="28px" />
              </Box>
            )}
          </Box>
        </>
      )}
    </ListWrapper>
  );
};

OrganizationsTable.propTypes = {
  records: PropTypes.any,
  params: PropTypes.any,
  onAddOrganization: PropTypes.func,
  isLoading: PropTypes.bool,
  onLoadMore: PropTypes.func,
};
OrganizationsTable.defaultProps = {
  records: {},
  params: {},
  isLoading: false,
  onAddOrganization: () => {},
  onLoadMore: () => {},
};

export default OrganizationsTable;
