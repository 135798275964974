import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const ModuleSettings = ({ children, className }) => {
  const baseClass = 'ickyc-module-settings';
  return <div className={classNames(baseClass, className)}>{children}</div>;
};
ModuleSettings.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};
ModuleSettings.defaultProps = {
  className: null,
};

export default ModuleSettings;
