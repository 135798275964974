import React from 'react';

export default () => (
  <svg version="1.2" baseProfile="tiny" id="Layer_1" x="0px" y="0px" viewBox="0 0 256 265" overflow="visible">
    <g>
      <g>
        <path
          fill="#FFFFFF"
          d="M121.11,187.83l-15.6-15.58c-2.32-2.33-6.09-2.33-8.42-0.01c-2.32,2.32-2.33,6.09-0.01,8.41l25.81,25.73
			c2.32,2.31,6.07,2.31,8.39,0l25.82-25.72c2.32-2.32,2.32-6.09-0.01-8.41c-2.33-2.32-6.1-2.32-8.41,0.01l-15.62,15.57l0.01-42.06
			c0-3.29-2.67-5.96-5.96-5.96c-3.29,0-5.96,2.67-5.96,5.96l-0.01,42.06L121.11,187.83z"
        />
        <path
          fill="#FFFFFF"
          d="M164.8,127.01l-75.43-0.02c-3.28,0-5.93-2.65-5.93-5.92c-0.01-3.28,2.65-5.93,5.92-5.95h0.01l75.43,0.02
			c3.28,0,5.93,2.66,5.93,5.94c0.01,3.28-2.65,5.93-5.92,5.93H164.8z"
        />
      </g>
      <path
        fill-rule="evenodd"
        fill="#FFFFFF"
        d="M210.86,84.06l-53.84-0.01c-1.65,0-3.15-0.67-4.24-1.75
		c-1.08-1.09-1.75-2.58-1.75-4.24l0.01-53.84L61.31,24.2c-9.91,0-17.95,8.04-17.95,17.94l-0.05,179.48
		c0,9.91,8.04,17.95,17.94,17.95l131.61,0.04c9.91,0,17.95-8.04,17.95-17.94L210.86,84.06z M202.4,72.09l-39.39-39.41L163,72.08
		L202.4,72.09z M31.39,42.13c0-16.52,13.4-29.91,29.92-29.9l95.72,0.03c1.67,0,3.18,0.68,4.27,1.8l59.73,59.76
		c1.11,1.09,1.79,2.6,1.79,4.27l-0.04,143.58c0,16.52-13.4,29.91-29.92,29.9l-131.61-0.04c-16.52,0-29.91-13.4-29.9-29.92
		L31.39,42.13z"
      />
    </g>
  </svg>
);
