import { ArrowDropDown, ArrowRight } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import bus from '../../modules/bus';
import enums from '../../utilities/enums';
import setClassSuffix from '../../utilities/services/ClassManager';
import Badge from '../Badge';
import Tooltip from '../Tooltip';
import './styles.scss';

/**
 * Accordion component.
 * @param {string} className - className of root div
 * @param {component} title - title of accordion
 * @param {string|number} accordionindex - accordionindex of the Accordion, returned by 'onClick'
 * @param {fn} onClick - fn called when Accordion clicked
 * @param {boolean} accordionOpen - is Accordion open
 * @param {boolean} accented - does Accordion have accented styles
 * @param {*} children - accordion content
 * @param {boolean} withBadge - width badge in header
 * @param {number} badgeCount - value of badge
 * @param {booleal} controledOpen - indicates if parent controls accordion state (e.g.
 *                                - if radio is in accordion header,
 *                                - it should be opened if radio is checked) (disabled on accordion click)
 */

const Accordion = ({
  className,
  children,
  title,
  onClick,
  accordionOpen,
  accordionindex,
  accented,
  withBadge,
  badge,
  controledOpen,
  hint,
  withComponentUnmount,
}) => {
  const [isOpen, setIsOpen] = useState(accordionOpen);
  const { count: badgeCount, isAllRejected } = badge;

  const accordionRef = useRef();
  const baseClass = 'ickyc-accordion';
  const setSuffix = setClassSuffix(baseClass);

  const classes = classNames(baseClass, { [setSuffix('--accented')]: accented }, className);

  useEffect(() => {
    setIsOpen(accordionOpen);
  }, [accordionOpen]);

  const isAccordionOpen = useMemo(() => (onClick ? accordionOpen : isOpen), [onClick, accordionOpen, isOpen]);

  useEffect(() => {
    const handler = info => {
      const expandInfo = info.find(ind => ind.index === accordionindex);
      if (expandInfo) {
        setIsOpen(true);
        if (expandInfo.shouldFocus) {
          setTimeout(() => {
            accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }, 600);
        }
      }
    };
    bus.addEventListener(enums.BUS_EVENTS.EXPAND_SECTIONS, handler);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.EXPAND_SECTIONS, handler);
    };
  }, [badgeCount, setIsOpen, accordionRef, accordionindex]);

  const handleAccordionClick = useCallback(() => {
    if (controledOpen) return;
    if (onClick) onClick(accordionindex);
    else setIsOpen(prev => !prev);
  }, [onClick, accordionindex, controledOpen]);

  return (
    <div className={classes} ref={accordionRef}>
      <div
        className={classNames(
          setSuffix('__title'),
          { [setSuffix('__title--with-badge')]: withBadge },
          { [setSuffix('__title--accented')]: accented },
          { [setSuffix('__title--accented--open')]: accented && isAccordionOpen },
        )}
        onClick={handleAccordionClick}
      >
        <div className={setSuffix('__title__arrow')}>
          {isAccordionOpen ? (
            <ArrowDropDown className={setSuffix('__title__arrow--down')} />
          ) : (
            <ArrowRight className={setSuffix('__title__arrow--right')} />
          )}
        </div>
        {title}
        {withBadge && (
          <Tooltip
            trigger={
              <>
                <span className="ickyc-spacer" />
                <Badge content={badgeCount} red={!isAllRejected} gray={isAllRejected} />
              </>
            }
            content={<span>{hint}</span>}
          />
        )}
      </div>
      {withComponentUnmount ? (
        <div className={classNames(setSuffix('__content'), { [setSuffix('__content--open')]: isAccordionOpen })}>
          {isAccordionOpen && children}
        </div>
      ) : (
        <div className={classNames(setSuffix('__content'), { [setSuffix('__content--open')]: isAccordionOpen })}>
          {children}
        </div>
      )}
    </div>
  );
};

Accordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  accordionindex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  accordionOpen: PropTypes.bool,
  accented: PropTypes.bool,
  withBadge: PropTypes.bool,
  controledOpen: PropTypes.bool,
  withComponentUnmount: PropTypes.bool,
  hint: PropTypes.string,
  badge: PropTypes.shape({ count: PropTypes.number, isAllRejected: PropTypes.bool }),
};

Accordion.defaultProps = {
  className: '',
  accordionOpen: false,
  onClick: null,
  accented: false,
  withBadge: false,
  withComponentUnmount: false,
  controledOpen: false,
  hint: undefined,
  badge: { count: 0, isAllRejected: false },
};

export default Accordion;
