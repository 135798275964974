import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../../api';
import Accordion from '../../../../../../../components/Accordion';
import CheckboxTable from '../../../../../../../components/Tables/CheckboxTable';
import TableHeader from '../../../../../../../components/Tables/Table/components/TableHeader';
import useSelect from '../../../../../../../hooks/useSelect';
import bus from '../../../../../../../modules/bus';
import { selectUserAccessByLicense } from '../../../../../../../store/selectors/auth.selector';
import enums from '../../../../../../../utilities/enums';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import { CaseContext } from '../../../../../../../utilities/services/contexts';
import TableHeaders from '../../../../../../../utilities/services/TableHeaders';
import MatchesControls from '../../../Matches/components/MatchesControls';
import ListingsTableRow from './components/ListingsTableRow';
import './styles.scss';

const FullListings = ({
  complianceLogId,
  hitId,
  selectedAMLFilters,
  selectedStatus,
  onHitStatusUpdate,
  shouldOpen,
  count,
}) => {
  const [params, setParams] = useState({ sortCriteria: 'name', sortOrder: 0 });
  const [pagination, setPagination] = useState({});

  const [listings, setListings] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const { isCaseClosed } = useContext(CaseContext);

  useEffect(() => {
    const fetchHitListings = async () => {
      setIsLoading(true);
      try {
        const { data: response } = await api.kyc.caseManagement.getHitListing({
          hitId,
          complianceLogId,
          params,
          filters: selectedAMLFilters,
          status: selectedStatus,
        });
        const { listings: l, counts } = response;
        onHitStatusUpdate(prev => ({
          ...prev,
          listingsCounter: counts?.fullListingsCount,
        }));
        setListings(l.data);
        setPagination(l.paginationData);
      } finally {
        setIsLoading(false);
      }
    };
    fetchHitListings();
  }, [complianceLogId, hitId, params, selectedAMLFilters, selectedStatus]);

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  const updateSelectedHitsStatus = async (newStatus, selectedListings) => {
    setIsLoading(true);
    try {
      const response = await api.kyc.caseManagement.updateHitStatus({
        complianceLogId,
        hitId,
        status: newStatus,
        ids: selectedListings,
      });
      setListings(prev => prev.filter(match => !selectedListings.some(m => m === match.id)));
      onHitStatusUpdate(prev => ({
        ...prev,
        [`${selectedStatus}Counter`]: prev[`${selectedStatus}Counter`] - selectedListings.length,
        [`${newStatus}Counter`]: prev[`${newStatus}Counter`] + selectedListings.length,
      }));

      bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, response.data);
      setParams(prev => {
        const limitDiff = prev.limit - selectedListings.length;
        return {
          total: prev.total - selectedListings.length,
          limit: !limitDiff ? prev.limit : limitDiff,
        };
      });
    } finally {
      setIsLoading(false);
    }
  };

  const platformAccessByLicence = useSelect(selectUserAccessByLicense);

  const tableHeaderItems = useMemo(() => {
    let items = TableHeaders.ListingsTableHeader;
    if (!platformAccessByLicence.some(el => el === authEnums.ACCESS_BY_LICENCE.ENTITIES)) {
      items = items.filter(item => item.type !== 'assignedTo');
    }
    return items;
  }, [platformAccessByLicence]);

  return (
    <Accordion
      title={<h3>Full Listings</h3>}
      accented
      badgeCount={count}
      className="ickyc-full-listings-table"
      accordionOpen={shouldOpen}
      withBadge
      accordionindex={enums.ACCORDION_INDEXES.FULL_LISTINGS}
    >
      <CheckboxTable
        disabled={isCaseClosed}
        values={listings}
        pagination={pagination}
        tableRow={ListingsTableRow}
        headerRow={TableHeader}
        withPagination
        withLimitChange
        TableControlsComponent={MatchesControls}
        handleParamsChange={changeParams}
        onControls={updateSelectedHitsStatus}
        updating={isLoading}
        headerData={{
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          onClick: changeParams,
          items: !isCaseClosed ? tableHeaderItems : TableHeaders.ListingsTableHeaderWithHiddenControlls,
        }}
      />
    </Accordion>
  );
};

FullListings.propTypes = {
  complianceLogId: PropTypes.string.isRequired,
  hitId: PropTypes.string.isRequired,
  selectedAMLFilters: PropTypes.arrayOf(PropTypes.number),
  selectedStatus: PropTypes.string.isRequired,
  onHitStatusUpdate: PropTypes.func,
  shouldOpen: PropTypes.bool,
  count: PropTypes.number,
};
FullListings.defaultProps = {
  selectedAMLFilters: [],
  onHitStatusUpdate: () => {},
  shouldOpen: false,
  count: 0,
};
export default FullListings;
