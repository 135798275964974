import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import IconManager from 'utilities/services/IconManager';
import EditDocumentDetails from './components/EditDocumentDetails';
import PreviewDocumentDetails from './components/PreviewDocumentDetails';
import './index.scss';

function DocumentDetails({ details }) {
  const [editMode, setEditMode] = useState(false);
  const classes = classNames({
    'ickyc-document-details': true,
    'ickyc-document-details--preview': true,
  });

  const toggleEdit = useCallback(() => {
    setEditMode(old => !old);
  }, []);

  return (
    <div className={classes}>
      <div>
        <h2>Details</h2>
        <Tooltip
          placement="top"
          trigger={<span onClick={toggleEdit}>{IconManager.get(IconManager.names.PENCIL)}</span>}
          content={<span>Click to edit details.</span>}
        />
      </div>
      {editMode ? (
        <EditDocumentDetails details={details} setEditMode={setEditMode} />
      ) : (
        <PreviewDocumentDetails details={details} />
      )}
    </div>
  );
}
DocumentDetails.propTypes = {
  details: PropTypes.shape({
    fileName: PropTypes.string,
    type: PropTypes.string,
    editableText: PropTypes.string,
  }),
};
DocumentDetails.defaultProps = {
  details: {
    fileName: '',
    type: '',
    editableText: '',
  },
};

export default DocumentDetails;
