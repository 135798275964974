import PrimaryButton from 'components/Buttons/PrimaryButton';
import PropTypes from 'prop-types';
import React from 'react';
import IconManager from '../../../../utilities/services/IconManager';
import './styles.scss';

const DocumentTypesControls = ({ onClick, disabled, initValue: buttonValues, setState }) => {
  return (
    <span className="ickyc-document-type-buttons">
      {buttonValues.map(({ label, name, value }) => (
        <PrimaryButton
          key={name}
          disabled={disabled}
          onClick={() => {
            onClick({ [name]: !value });
            setState(prev => prev.map(el => (el.name === name ? { ...el, value: !value } : el)));
          }}
        >
          {IconManager.get(IconManager.names.ID_CARD)}
          <span>{label}</span>
        </PrimaryButton>
      ))}
    </span>
  );
};

DocumentTypesControls.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  initValue: PropTypes.arrayOf(PropTypes.shape()),
  setState: PropTypes.func,
};

DocumentTypesControls.defaultProps = {
  onClick: () => {},
  initValue: {},
  setState: () => {},
  disabled: true,
};

export default DocumentTypesControls;
