import api from 'api';
import FormModal from 'components/Modal/FormModal';
import { FORM_ERROR } from 'final-form';
import useGroupPermissions from 'hooks/useGroupPermissions';
import useModalHandler from 'hooks/useModalHandler';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import toastr from 'toastr';
import authEnums from 'utilities/enums/authEnums';
import licenceEnums from 'utilities/enums/licenceEnums';
import setClassSuffix from 'utilities/services/ClassManager';
import DateManager from 'utilities/services/DateManager';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';

const LicenceTableRow = ({
  rowInfo: { name, updatedByName, tags, id, lastModified, type },
  className,
  onClick,
  onRemove,
  onControlsClick,
}) => {
  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const groupPermissions = useGroupPermissions(authEnums.PERMISSION_GROUP.LICENCE_MANAGER);

  const { close: closeConfirmModal, open: openConfirmModal, isOpen: isConfirmModal } = useModalHandler();
  const handleEditLicence = useCallback(
    event => {
      event.stopPropagation();
      onClick(id);
    },
    [id, onClick],
  );

  const handleLicenceDelete = () => {
    return api.businessManager.licences
      .deleteLicence(id)
      .then(() => {
        closeConfirmModal();
        onRemove(id);
        toastr.success(`${name} has been deleted`);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
          }
          if (resStatus === 500) {
            return {
              [FORM_ERROR]: Array.isArray(data.message)
                ? data.message.join('')
                : data.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  const TagsControlls = () => {
    if (tags) {
      const tagsArray = tags.split('|');
      return tagsArray?.map((tag, index) => (
        <span className={`${className}__tag-element`} onClick={() => onControlsClick(tag)}>
          {index !== tagsArray.length - 1 ? `${tag}, ` : `${tag}`}
        </span>
      ));
    }
  };

  return (
    <tr key={id} className={`${className}__row`}>
      <td className={setColumnSuffix('__name')} onClick={handleEditLicence}>
        <span>{name}</span>
      </td>
      <td className={setColumnSuffix('__lastModified')} onClick={handleEditLicence}>
        <span>{lastModified ? DateManager.getFullDateLocal(lastModified) : ''}</span>
      </td>
      <td className={setColumnSuffix('__updatedBy')} onClick={handleEditLicence}>
        <span>{updatedByName}</span>
      </td>
      <td className={setColumnSuffix('__tags')}>
        <span>
          <TagsControlls />
        </span>
      </td>
      <td className={setColumnSuffix('__type')} onClick={handleEditLicence}>
        <span>{type === licenceEnums.LICENCE_TYPES.WEBSITE ? 'Website' : 'Custom'}</span>
      </td>
      {groupPermissions.delete && (
        <td className={setColumnSuffix('__remove')}>
          <DeleteButton onClick={openConfirmModal} />
          <div>
            {isConfirmModal && (
              <FormModal
                hideModal={closeConfirmModal}
                title="Remove Licence"
                className="ickyc-confirmation-modal"
                onSubmit={handleLicenceDelete}
                onRejectClick={closeConfirmModal}
                showErrorsInConfirmation
              >
                <span className="ickyc-confirmation-message">
                  Are you sure you want to delete &nbsp; <h3>{name}</h3>
                </span>
              </FormModal>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

LicenceTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    updatedByName: PropTypes.string,
    tags: PropTypes.array,
    lastModified: PropTypes.string,
  }),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  onControlsClick: PropTypes.func,
};

LicenceTableRow.defaultProps = {
  className: '',
  rowInfo: {},
  onRemove: () => {},
  onClick: () => {},
  onControlsClick: () => {},
};
export default LicenceTableRow;
