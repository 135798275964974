import CountrySelectField from 'components/CountrySelectField';
import DatepickerField from 'components/DatepickerField';
import InputField from 'components/InputField';
import StateProvinceSelectField from 'components/StateProvinceSelectField';
import TextareaField from 'components/TextareaField';
import ToggleField from 'components/ToggleField';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field, useField, useFormState } from 'react-final-form';
import utilities from 'utilities';
import Constants from 'utilities/constants';
import setClassSuffix from 'utilities/services/ClassManager';
import validators from 'utilities/services/validators';
import Association from './components/Association';

import './styles.scss';
/**
 * Displays the data about the natural info profile
 * @param {object} data - displayed information
 * @param {boolean} preview - should fields be preview
 * @param {function} onDiscard - `Discard Changes` button handler
 * @param {function} onHistoryUpdate - pushes the row into the history table
 */

const NaturalPersonInformationForm = ({ namePreffix, preview, responsive, checkList }) => {
  const classBase = !responsive ? 'ickyc-personal-information' : 'ickyc-personal-information--responsive';
  const setSuffix = setClassSuffix(classBase);
  const { values } = useFormState();
  const { residence } = values[namePreffix] || {};
  const {
    input: { value: politicallyExposed },
  } = useField(`${namePreffix}.additionalDetails.pep`);

  const isPollyticallyExposed = useMemo(() => {
    if (politicallyExposed === '') return '';

    return politicallyExposed ? 'Yes' : 'No';
  });

  return (
    <div className={classBase}>
      <div className={setSuffix('__block')}>
        <Field
          component={InputField}
          name={`${namePreffix}.firstName`}
          label="First Name"
          preview={preview}
          accentText={!checkList?.firstNameStatus}
          required
          parse={utilities.parseName}
          validate={
            !preview &&
            validators.composeValidators(
              validators.required('Required'),
              validators.stringMaxLength(Constants.PERSONAL_NAME_MAX_LENGTH),
            )
          }
        />

        <Field
          component={InputField}
          name={`${namePreffix}.middleName`}
          accentText={!checkList?.middleNameStatus}
          label="Middle Name"
          preview={preview}
          parse={utilities.parseName}
          validate={validators.stringMaxLength(Constants.PERSONAL_NAME_MAX_LENGTH)}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.lastName`}
          accentText={!checkList?.lastNameStatus}
          required
          label="Last Name"
          preview={preview}
          parse={utilities.parseName}
          validate={
            !preview &&
            validators.composeValidators(
              validators.required('Required'),
              validators.stringMaxLength(Constants.PERSONAL_NAME_MAX_LENGTH),
            )
          }
        />
        <Field
          component={InputField}
          name={`${namePreffix}.phone`}
          accentText={!checkList?.phoneStatus}
          label="Phone"
          preview={preview}
          parse={value => value.replace(/[^\d]/g, '')}
        />

        <Field
          component={InputField}
          name={`${namePreffix}.email`}
          accentText={!checkList?.emailStatus}
          required
          label="Email"
          preview={preview}
          validate={!preview && validators.email('Invalid email address')}
        />
        <Field
          component={DatepickerField}
          label="Date of Birth"
          name={`${namePreffix}.birthDate`}
          accentText={!checkList?.dateOfBirthStatus}
          preview={preview}
          maxDate={new Date()}
        />
        <Field
          component={TextareaField}
          name={`${namePreffix}.additionalDetails.employerName`}
          label="Employer Name "
          preview={preview}
        />
        <Field component={InputField} name={`${namePreffix}.additionalDetails.title`} label="Title" preview={preview} />
        <div className={setSuffix(`__block__pollitically${preview ? '' : '--edit'}`)}>
          {preview ? (
            <span>
              <b>Are you a Politically Exposed Person? </b> &nbsp;
              {isPollyticallyExposed}
            </span>
          ) : (
            <Field
              component={ToggleField}
              name={`${namePreffix}.additionalDetails.pep`}
              label="Are you a Politically Exposed Person ?"
            />
          )}
        </div>
      </div>

      <div className={setSuffix('__block')}>
        <Field
          component={CountrySelectField}
          label="Nationality"
          name={`${namePreffix}.nationality`}
          accentText={!checkList?.nationalityStatus}
          preview={preview}
          required
          validate={!preview && validators.required('No Country Selected')}
        />
        <Field
          component={CountrySelectField}
          name={`${namePreffix}.residence`}
          accentText={!checkList?.residenceStatus}
          label="Residence"
          preview={preview}
          validate={!preview && validators.customResidenceValidator('Residence must be selected when creating address')}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.unit`}
          label="Unit"
          preview={preview}
          accentText={!checkList?.unitStatus}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.streetAddress`}
          label="Street Address"
          preview={preview}
          accentText={!checkList?.streetAddressStatus}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.city`}
          accentText={!checkList?.cityStatus}
          label="City"
          preview={preview}
        />
        <Field
          component={StateProvinceSelectField}
          name={`${namePreffix}.stateProvince`}
          label="Province"
          preview={preview}
          accentText={!checkList?.provinceStatus}
          countryId={residence}
          autoClose
        />
        <Field
          component={InputField}
          name={`${namePreffix}.postalZip`}
          accentText={!checkList?.postalZipStatus}
          label="Postal/Zip"
          preview={preview}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.additionalDetails.industry`}
          label="Industry"
          preview={preview}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.additionalDetails.roleOccupation`}
          label="Role/Occupation"
          preview={preview}
        />
        <Association namePreffix={namePreffix} />
      </div>
    </div>
  );
};

NaturalPersonInformationForm.propTypes = {
  preview: PropTypes.bool,
  responsive: PropTypes.bool,
  namePreffix: PropTypes.string,
  checkList: PropTypes.shape({
    cityStatus: PropTypes.bool,
    dateOfBirthStatus: PropTypes.bool,
    emailStatus: PropTypes.bool,
    firstNameStatus: PropTypes.bool,
    lastNameStatus: PropTypes.bool,
    middleNameStatus: PropTypes.bool,
    nationalityStatus: PropTypes.bool,
    phoneStatus: PropTypes.bool,
    postalZipStatus: PropTypes.bool,
    provinceStatus: PropTypes.bool,
    residenceStatus: PropTypes.bool,
    streetAddressStatus: PropTypes.bool,
    unitStatus: PropTypes.bool,
  }),
};

NaturalPersonInformationForm.defaultProps = {
  preview: false,
  responsive: false,
  namePreffix: 'personalInformation',
  checkList: {
    cityStatus: true,
    dateOfBirthStatus: true,
    emailStatus: true,
    firstNameStatus: true,
    lastNameStatus: true,
    middleNameStatus: true,
    nationalityStatus: true,
    phoneStatus: true,
    postalZipStatus: true,
    provinceStatus: true,
    residenceStatus: true,
    streetAddressStatus: true,
    unitStatus: true,
  },
};

export default NaturalPersonInformationForm;
