import React from 'react';

export default () => (
  <svg viewBox="0 0 89.7 90.7">
    <path
      d="M86.552 17.5953L72.6362 3.6797h-2.7525L44.805 28.6054 19.7265 3.6797h-2.7526L3.0584 17.5953v2.7525L27.984 45.4265 3.0584 70.505v2.7525l13.9155 13.9156h2.7526L44.805 62.2475l25.0787 25.0787h2.7525L86.552 73.4106V70.658L61.6262 45.4265l25.0786-25.0787c-.1529-.9175-.1529-1.835-.1529-2.7525z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);
