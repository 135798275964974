/**
 * Service used to store functions used to transform filter forms data into object that fits requests
 *
 */

import DateManager from './DateManager';

/**
 * User Management Filter Form
 */
const normalizeEntityManagementFormData = (filters, applyFilters) => {
  if (!filters) return {};

  const data = {};
  data.applyFilters = applyFilters;

  const {
    countryFlag,
    countries,
    riskLevelFlag,
    riskLevels,
    statusFlag,
    statuses,
    assignedToFlag,
    assignedTo,
    dateFlag,
    dateFrom,
    dateUntil,
    entityTypeFlag,
    naturalPersonFlag,
    organizationFlag,
    tagsFlag,
    tags,
    nextKYCReviewFlag,
    nextKYCReviewFrom,
    nextKYCReviewUntil,
  } = filters;

  if (countryFlag && countries.length) data.countries = countries;
  if (riskLevelFlag && riskLevels.length) data.riskLevels = riskLevels;
  if (statusFlag && statuses.length) data.statuses = statuses;
  if (assignedToFlag && assignedTo.length) data.assignedTo = assignedTo;

  if (dateFlag) {
    data.dateFrom = dateFrom && DateManager.toBekend(dateFrom);
    data.dateUntil = dateUntil && DateManager.toBekend(dateUntil);
  }

  if (nextKYCReviewFlag) {
    data.nextKYCReviewFrom = nextKYCReviewFrom && DateManager.toBekend(nextKYCReviewFrom);
    data.nextKYCReviewUntil = nextKYCReviewUntil && DateManager.toBekend(nextKYCReviewUntil);
  }

  if (entityTypeFlag) {
    const entityTypes = [];

    if (naturalPersonFlag) entityTypes.push('Natural Person');
    if (organizationFlag) entityTypes.push('Legal Entity');

    if (entityTypes.length) data.entityTypes = entityTypes;
  }
  if (tagsFlag) {
    data.tags = tags;
  }

  return data;
};

/**
 * Risk Screening -> Matches Form
 */
const normalizeMatchesFormData = (filters, applyFilters) => {
  const data = {};
  data.applyFilters = applyFilters;

  const { countriesFlag, countries, relevanceFlag, relevanceTerms, entityTypesFlag, entityTypes } = filters;

  if (countriesFlag && countries.length) data.countries = countries;

  if (relevanceFlag) {
    data.relevanceTerms = relevanceTerms;
  }
  if (entityTypesFlag) {
    data.entityTypes = entityTypes;
  }

  return data;
};

/**
 * User Management Filter Form
 */
const normalizeUserManagementFormData = (filters, applyFilters) => {
  if (!filters || !applyFilters) return {};

  const data = {};
  const {
    userStatusesFlag,
    userStatuses,
    dateFlag,
    dateFrom,
    dateUntil,
    tagsOfUser,
    tags,
    userTypes,
    userTypesFlag,
  } = filters;

  if (userStatusesFlag && userStatuses.length) data.userStatuses = userStatuses;

  if (dateFlag) {
    data.dateFrom = dateFrom && DateManager.toBekend(dateFrom);
    data.dateUntil = dateUntil && DateManager.toBekend(dateUntil);
  }
  if (tagsOfUser && tags.length) data.tags = tags;

  if (userTypesFlag && userTypes.length) data.userTypes = userTypes;

  return data;
};

/**
 * Business Management Filter Form
 */
const normalizeBusinessManageementFormData = values => {
  const data = {};

  const { countriesFlag, countryIds, fromDate, toDate, userStatusesFlag, dateFlag, statuses } = values;

  if (countriesFlag && countryIds.length) data.countryIds = countryIds;
  if (userStatusesFlag && statuses.length) data.statuses = statuses;

  if (dateFlag) {
    data.fromDate = fromDate && DateManager.toBekend(fromDate);
    data.toDate = toDate && DateManager.toBekend(toDate);
  }

  return data;
};

/**
 * RCU MODAL
 */
const filterRcuModalData = values => {
  const data = {};
  const { generalSettings, amlCase, automation, comment, kycRequests, dataCollection } = values;
  const {
    biometricAuthentication,
    documentAuthentication: { includeFaceMatch, faceMatchStrategy, ...restOfDocumentAuthentication },
    documentAuthentication,
    identityVerification,
  } = kycRequests;
  const {
    packagesTypes,
    requestSupportingDocuments,
    requestPersonalInformation,
    personalInformationInputOption,
    requestAddressInformation,
    addressInformationInputOption,
    requestAdditionalDetails,
    additionalDetailsInputOption,
    requestProofOfAddress,
    ...restOfDataCollection
  } = dataCollection;

  data.generalSettings = generalSettings;

  data.amlCase = amlCase.isEnabled ? amlCase : { isEnabled: false };

  data.automation = automation.isEnabled ? automation : { isEnabled: false };

  data.comment = comment.isEnabled ? comment : { isEnabled: false };

  data.kycRequests = {};

  data.kycRequests.biometricAuthentication = biometricAuthentication.isEnabled
    ? biometricAuthentication
    : { isEnabled: false };
  let tempDcumentAuthentication = {};

  if (documentAuthentication.isEnabled) {
    if (includeFaceMatch) {
      tempDcumentAuthentication = {
        ...tempDcumentAuthentication,
        includeFaceMatch,
        faceMatchStrategy,
        ...restOfDocumentAuthentication,
      };
    } else {
      tempDcumentAuthentication = {
        ...tempDcumentAuthentication,
        includeFaceMatch,
        ...restOfDocumentAuthentication,
      };
    }
  } else {
    tempDcumentAuthentication = {
      isEnabled: false,
    };
  }
  data.kycRequests.documentAuthentication = tempDcumentAuthentication;
  data.kycRequests.identityVerification = identityVerification.isEnabled ? identityVerification : { isEnabled: false };

  let tempDataCollection = {};
  if (requestSupportingDocuments) {
    tempDataCollection = { packagesTypes, requestSupportingDocuments, ...tempDataCollection };
  } else {
    tempDataCollection = { requestSupportingDocuments };
  }
  if (requestAddressInformation) {
    tempDataCollection = {
      requestAddressInformation,
      addressInformationInputOption,
      ...tempDataCollection,
    };
  } else {
    tempDataCollection = {
      requestAddressInformation,
      ...tempDataCollection,
    };
  }
  if (requestAdditionalDetails) {
    tempDataCollection = {
      requestAdditionalDetails,
      additionalDetailsInputOption,
      ...tempDataCollection,
    };
  } else {
    tempDataCollection = {
      requestAdditionalDetails,
      ...tempDataCollection,
    };
  }

  if (requestPersonalInformation) {
    tempDataCollection = {
      requestPersonalInformation,
      personalInformationInputOption,
      ...tempDataCollection,
    };
  } else {
    tempDataCollection = {
      requestPersonalInformation,
      ...tempDataCollection,
    };
  }

  data.dataCollection = { ...tempDataCollection, ...restOfDataCollection };
  return data;
};

const normalizeReportsFormData = (filters, applyFilters) => {
  if (!filters || !applyFilters) return {};

  const data = {};
  const { dateFlag, dateFrom, dateUntil, userTypes, userTypesFlag, requestedBy, requestedByFlag } = filters;

  if (requestedByFlag && requestedBy) data.requestedBy = requestedBy;

  if (dateFlag) {
    data.dateFrom = dateFrom && DateManager.toBekend(dateFrom);
    data.dateUntil = dateUntil && DateManager.toBekend(dateUntil);
  }

  if (userTypesFlag && userTypes) data.type = userTypes;

  return data;
};

const normalizeLicenceFormData = (filters, applyFilters) => {
  if (!filters || !applyFilters) return {};

  const data = {};
  const { dateFlag, dateFrom, dateUntil, updatedByFlag, updatedBy, tagsFlag, tags } = filters;

  if (tagsFlag && tags) data.tags = tags;

  if (dateFlag) {
    data.dateFrom = dateFrom && DateManager.toBekend(dateFrom);
    data.dateUntil = dateUntil && DateManager.toBekend(dateUntil);
  }

  if (updatedByFlag && updatedBy) data.updatedBy = updatedBy;

  return data;
};

const normalizeCaseManagementFormData = (filters, applyFilters) => {
  if (!filters) return {};
  const data = {};
  data.applyFilters = applyFilters;

  const {
    dateFlag,
    dateFrom,
    dateTo,
    riskTypesFlag,
    riskTypes,
    statusesFlag,
    statuses,
    assignsToFlag,
    assignsTo,
    ongoingFlag,
    ongoing,
    runBy,
    runByFlag,
    attachmentsFlag,
    attachments,
    potentialIssues,
    potentialIssuesFlag,
  } = filters;

  if (dateFlag) {
    data.dateFrom = dateFrom && DateManager.toBekend(dateFrom);
    data.dateTo = dateTo && DateManager.toBekend(dateTo);
  }
  if (riskTypesFlag && riskTypes?.length) data.riskTypes = riskTypes;
  if (statusesFlag && statuses?.length) data.statuses = statuses;
  if (assignsToFlag && assignsTo?.length) data.assignsTo = assignsTo;
  if (runByFlag && runBy?.length) data.runBy = runBy;
  if (ongoingFlag) data.ongoing = ongoing;
  if (potentialIssuesFlag) data.potentialIssues = potentialIssues;
  if (assignsToFlag && assignsTo?.length) data.assignsTo = assignsTo;
  if (attachmentsFlag) data.attachments = attachments;

  return data;
};

export default {
  normalizeEntityManagementFormData,
  normalizeMatchesFormData,
  normalizeUserManagementFormData,
  normalizeBusinessManageementFormData,
  filterRcuModalData,
  normalizeReportsFormData,
  normalizeLicenceFormData,
  normalizeCaseManagementFormData,
};
