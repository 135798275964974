export const cardItems = [
  {
    header: 'Product Documentation',
    subheader: 'Our product knowledge base',
    redirectToSpecificUrl: () => window.open('https://support.icomplyis.com/product-documentation', '_blank'),
  },
  {
    header: 'Technical & API',
    subheader: 'View API & Webhooks docs',
    redirectToSpecificUrl: () => window.open('https://support.icomplyis.com/icomply-api-documentation', '_blank'),
  },
  {
    header: 'Compliance Dictionary',
    subheader: 'A complete glossary of terms',
    redirectToSpecificUrl: () => window.open('https://icomplyis.com/regtech-glossary/', '_blank'),
  },
  {
    header: 'Regwatch Updates',
    subheader: 'The latest news in compliance',
    redirectToSpecificUrl: () => window.open('https://icomplyis.com/regwatch/', '_blank'),
  },
];

export default { cardItems };
