import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import FormModal from '../../../../../../../components/Modal/FormModal';
import utils from '../../utils';
import MatchesFilterForm from './components/MatchesFilterForm';
const MatchesFilterModal = ({ hideModal, onFilterSave, initialFilters }) => {
  const { areFilterArraysEmpty } = utils;
  const [filters, setFilters] = useState(initialFilters);
  const applyFilters = useCallback(
    async values => {
      if (areFilterArraysEmpty(values)) {
        const filterWithoutFlags = {
          entityTypes: [],
          countries: [],
          relevanceTerms: [],
        };

        onFilterSave(filterWithoutFlags);
        setFilters(filterWithoutFlags);
      } else {
        onFilterSave(values);
      }
      hideModal();
    },
    [hideModal, onFilterSave],
  );

  return (
    <FormModal
      onSubmit={applyFilters}
      title="Filter"
      initialValues={filters}
      hideModal={hideModal}
      renderAsPortal={false}
      className="ickyc-filter-modal"
    >
      <MatchesFilterForm />
    </FormModal>
  );
};

MatchesFilterModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onFilterSave: PropTypes.func.isRequired,
  initialFilters: PropTypes.shape({}),
};
MatchesFilterModal.defaultProps = {
  initialFilters: {},
};
export default MatchesFilterModal;
