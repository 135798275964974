import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { useParams } from 'react-router';
import SelectContentTable from '../../../../../../../../../components/Select/components/SelectContentTable';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import TableHeader from '../../../../../../../../../components/Tables/Table/components/TableHeader';
import ToggleField from '../../../../../../../../../components/ToggleField';
import useTable from '../../../../../../../../../hooks/useTable';
import RiskProfileSelectRow from '../../../../../../../../Kyc/components/SearchModal/components/SearchForm/RiskProfileSelectRow';
import PortalManagementService from '../../../../../../services/PortalManagement.service';
import ModuleOption from '../ModuleOption';
import './styles.scss';

const AMLCaseForm = ({ index, namePreffix }) => {
  const className = 'ickyc-create-aml-case-module';
  const { clientId } = useParams();

  const { getRiskScreeningProfiles } = PortalManagementService.initializeApiEndpoints(clientId);

  const { records, changeParams, params, pagination, isLoading } = useTable(getRiskScreeningProfiles, {
    limit: 10,
    sortCriteria: 'name',
    sortOrder: 0,
    clientId,
  });
  const mappedRiskProfiles = useMemo(
    () =>
      records.map(profile => ({
        ...profile,
        id: profile.id,
        value: profile.id,
        label: profile.name,
        disabled: !profile.isAllowed,
      })),
    [records],
  );

  const handleOnClickEvent = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const fieldNamePrefix = useMemo(() => `modules[${index}]${namePreffix ? `.${namePreffix}` : ''}`, [
    index,
    namePreffix,
  ]);

  return (
    <ModuleOption className="ickyc-create-aml-case-module">
      <div className={`${className}__risk-screening`} onClick={handleOnClickEvent}>
        <span>Risk Screening Profile</span>
        <Field
          name={`${fieldNamePrefix}.screeningProfileId`}
          component={SelectField}
          options={mappedRiskProfiles}
          Trigger={<SelectTrigger />}
          Content={
            <SelectContentTable
              tableProps={{
                tableRow: RiskProfileSelectRow,
                headerRow: TableHeader,
                handleParamsChange: changeParams,
                pagination,
                headerData: {
                  sortCriteria: params.sortCriteria,
                  sortOrder: params.sortOrder,
                  onClick: changeParams,
                  items: [
                    {
                      label: 'Name',
                      type: 'name',
                      clickable: true,
                    },
                  ],
                },
                updating: isLoading,
                withLoadMore: true,
                withPagination: true,
              }}
            />
          }
        />
      </div>

      <Field
        component={ToggleField}
        label="Create AML Case After All KYC Requests are in Accepted Status"
        name={`${fieldNamePrefix}.createCaseAfterAllRequestsAreAccepted`}
      />
    </ModuleOption>
  );
};
AMLCaseForm.propTypes = {
  index: PropTypes.number.isRequired,
  withEnableAllOption: PropTypes.bool,
  namePreffix: PropTypes.string,
};
AMLCaseForm.defaultProps = {
  withEnableAllOption: false,
  namePreffix: undefined,
};
export default AMLCaseForm;
