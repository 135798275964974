import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Select from '../Select';
import SelectContent from '../Select/components/SelectContent';
import SelectTrigger from '../Select/components/SelectTrigger';
import SingleCountry from './components/SingleCountry';
import './styles.scss';

const CountrySelect = ({ ContentComponent, ...rest }) => {
  const options = useSelector(state => state.global.countries || []).map(({ id, ...rest2 }) => ({
    label: rest2.name,
    id,
    value: id,
    ...rest2,
  }));

  return (
    <Select
      {...rest}
      options={options}
      Trigger={<SelectTrigger placeholder="Select Status" TriggerComponent={SingleCountry} />}
      Content={<SelectContent withFilter ContentComponent={SingleCountry} />}
      // triggerComponent={SingleCountry}
      // contentComponent={ContentComponent || CountryList}
    />
  );
};

CountrySelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.number]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  multipleSelection: PropTypes.bool,
  withFilter: PropTypes.bool,
  useFullValue: PropTypes.bool,
  ContentComponent: PropTypes.node,
  // Generated by final-form's Field component
};

CountrySelect.defaultProps = {
  value: undefined,
  disabled: false,
  label: '',
  onChange: () => {},
  useFullValue: false,
  multipleSelection: false,
  withFilter: true,
  ContentComponent: undefined,
};

export default CountrySelect;
