import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import setClassSuffix from '../../utilities/services/ClassManager';
import './styles.scss';

/**
 * Info represented in one line with bolded label and arbitrarly content
 * @param {string} label - content label
 * @param {*} children - content
 */

const Info = ({ label, children, className }) => {
  const classBase = classNames('ickyc-info', { [className]: className });
  const setSuffix = setClassSuffix(classBase);

  return (
    <div className={classBase}>
      <span className={setSuffix('__label')}>{label}:</span>
      <span className={setSuffix('__content')}>{children}</span>
    </div>
  );
};

Info.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.bool,
};
Info.defaultProps = {
  children: undefined,
  className: null,
};

export default Info;
