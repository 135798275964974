import React from 'react';

export default () => (
  <svg fill="none" height="48" viewBox="0 0 70 48" width="70">
    <rect fill="#fff" height="47" rx="5.5" stroke="#d9d9d9" width="69" x=".5" y=".5" />
    <path
      clipRule="evenodd"
      d="m21.2505 32.5165h-4.2406l-3.18-12.1318c-.1509-.558-.4714-1.0514-.9428-1.2839-1.1765-.5843-2.4729-1.0494-3.8871-1.2839v-.4671h6.8313c.9429 0 1.65.7016 1.7678 1.5165l1.65 8.751 4.2386-10.2675h4.1227zm8.717 0h-4.0049l3.2978-15.1667h4.0049zm8.4792-10.9651c.1179-.8168.825-1.2839 1.65-1.2839 1.2964-.1173 2.7085.1173 3.8871.6996l.7071-3.2655c-1.1786-.4671-2.4749-.7016-3.6514-.7016-3.8871 0-6.7156 2.1008-6.7156 5.0165 0 2.2181 2.0035 3.3827 3.4178 4.0843 1.53.6996 2.1193 1.1667 2.0014 1.8663 0 1.0494-1.1785 1.5165-2.355 1.5165-1.4142 0-2.8285-.3498-4.1228-.9342l-.7071 3.2675c1.4142.5823 2.9443.8169 4.3585.8169 4.3585.1152 7.0671-1.9836 7.0671-5.1338 0-3.9671-5.5371-4.1996-5.5371-5.9486zm19.5533 10.9651-3.18-15.1667h-3.4156c-.7072 0-1.4143.4671-1.65 1.1667l-5.8885 14h4.1228l.8229-2.2161h5.0656l.4714 2.2161zm-6.0064-11.0823 1.1765 5.716h-3.2978z"
      fill="#172b85"
      fillRule="evenodd"
    />
  </svg>
);
