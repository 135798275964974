import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import Section from '../../../../../../../../../components/Section';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import TextareaField from '../../../../../../../../../components/TextareaField';
import licenceEnums from '../../../../../../../../../utilities/enums/licenceEnums';
import validators from '../../../../../../../../../utilities/services/validators';
import './styles.scss';

const SaasFee = ({ namePreffix, disabled }) => {
  return (
    <Section className="ickyc-bl-saas-fee-section" divider>
      <h3>SaaS Fee</h3>
      <Field
        name={`${namePreffix}.defaultValues.saasFee`}
        placeholder=". . ."
        preffix="$"
        disabled={disabled}
        type="number"
        label="Fee Amount"
        // price format
        component={InputField}
        validate={validators.required()}
      />
      <Field
        name={`${namePreffix}.defaultValues.saasBillingCycle`}
        id={`${namePreffix}.defaultValues.saasBillingCycle`}
        options={licenceEnums.BILLING_CYCLE_OPTIONS}
        label="SaaS Billing Cycle"
        component={SelectField}
        disabled={disabled}
        Trigger={<SelectTrigger placeholder="Choose" />}
        Content={<SelectFilterContent />}
        renderAsPortal
      />
      <Field
        label="Note"
        component={TextareaField}
        name={`${namePreffix}.saasNote`}
        disabled={disabled}
        placeholder="Add Note"
        autoExpand
      />
    </Section>
  );
};

SaasFee.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};
SaasFee.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default SaasFee;
