const businessDetailsTabs = [
  {
    label: 'Account',
    key: 'account',
  },
  {
    label: 'Licenses ',
    key: 'licences',
  },
  {
    label: 'Users ',
    key: 'users',
  },
  {
    label: 'Portals ',
    key: 'portals',
  },
  {
    label: 'Usage',
    key: 'usage',
  },
  {
    label: 'Billing ',
    key: 'billing',
  },
  {
    label: 'Comments ',
    key: 'comments',
  },
  {
    label: 'Settings ',
    key: 'settings',
  },
];

export default { businessDetailsTabs };
