/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import classNames from 'classnames';
import FieldDescription from 'components/FieldDescription';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import iconManager from '../../utilities/services/IconManager';
import './styles.scss';

/**
 * @name Toggle
 *
 * @param {string} label
 * @param {string} icon
 * @param {string} className
 * @param {boolean} disabled
 * @param {boolean} value - initial value
 * @param {func} onChange - handles click on toggle change
 *
 */

const Toggle = ({
  label,
  onChange,
  icon,
  value,
  onOff,
  disabled,
  showCustomText,
  toggleOnText,
  toggleOffText,
  callback,
  className,
  description,
}) => {
  const inputProps = useMemo(() => {
    // If Final-Form's Field wrapper present
    return {
      onClick: e => {
        e.stopPropagation();
        if (callback) {
          callback(value);
        }
        onChange(!value);
      },
      onChange: () => {},
      checked: !!value,
      disabled,
    };
  }, [onChange, value, disabled, callback]);

  const renderOnOff = useCallback(() => {
    const { checked } = inputProps;
    if (onOff) {
      return checked ? <span className="ickyc-toggle__on"> On</span> : <span className="ickyc-toggle__off"> Off</span>;
    }
    if (showCustomText) {
      return checked ? (
        <span className="ickyc-toggle__on"> {toggleOnText}</span>
      ) : (
        <span className="ickyc-toggle__off"> {toggleOffText}</span>
      );
    }
  }, [inputProps, onOff, showCustomText, toggleOffText, toggleOnText]);

  const handleOnKeyDown = useCallback(
    e => {
      const { keyCode } = e;
      const { checked, onClick } = inputProps;
      if (checked && keyCode === 37 && !disabled) {
        onClick(!checked);
      }
      if (!checked && keyCode === 39 && !disabled) {
        onClick(!checked);
      }
    },
    [inputProps, disabled],
  );

  const classes = classNames({
    'ickyc-toggle': true,
    'ickyc-toggle--disabled': inputProps.disabled,
    [className]: className,
  });

  return (
    <div className={classes}>
      <div className="ickyc-toggle__container">
        <label className="ickyc-toggle__slide">
          <input hidden {...inputProps} disabled={inputProps.disabled} type="checkbox" />
          <span className="ickyc-toggle__circle" tabIndex={0} onKeyDown={handleOnKeyDown} />
        </label>

        {label && (
          <div>
            {icon && <span>{iconManager.get(icon)}</span>}

            <span>{label}</span>
          </div>
        )}

        {renderOnOff()}
      </div>
      {description && <FieldDescription text={description} />}
    </div>
  );
};

Toggle.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.string,
  onOff: PropTypes.bool,
  disabled: PropTypes.bool,
  showCustomText: PropTypes.bool,
  toggleOnText: PropTypes.string,
  toggleOffText: PropTypes.string,
  callback: PropTypes.func,
};

Toggle.defaultProps = {
  value: false,
  label: '',
  className: undefined,
  icon: '',
  onOff: false,
  onChange: () => {},
  disabled: false,
  showCustomText: false,
  toggleOffText: undefined,
  toggleOnText: undefined,
  callback: undefined,
};

export default Toggle;
