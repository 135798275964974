import { useEffect, useState } from 'react';

const useOutsideClick = (ref, handler, ignoreRef) => {
  const [isTouched, setIsTouched] = useState();
  const [isClickedOutside, setIsClickedOutside] = useState(false);

  //Capture inside click
  useEffect(() => {
    const handleClick = () => {
      setIsTouched(true);
    };

    if (ref.current) {
      ref.current.addEventListener('click', handleClick);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('click', handleClick);
      }
    };
  }, [ref, setIsTouched]);

  //Capture outside click
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && !ignoreRef?.current?.contains(event.target)) {
        handler && handler();
        setIsClickedOutside(true);
      }
    }
    if (isTouched) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, handler, ignoreRef, isTouched]);

  return isClickedOutside;
};
export default useOutsideClick;
