import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import BareHeading from '../../../components/Headings/BareHeading';
import CSVExport from './components/CSVExport';
import CSVImport from './components/CSVImport';

const FilesManagementPage = ({ className }) => {
  const classes = classNames('ickyc-page', 'ickyc-file-management', className);
  return (
    <div className={classes}>
      <BareHeading title="CSV" />
      <div className="ickyc-page__content">
        <CSVImport />
        <CSVExport />
      </div>
    </div>
  );
};

FilesManagementPage.propTypes = {
  className: PropTypes.string,
};

FilesManagementPage.defaultProps = {
  className: undefined,
};

export default FilesManagementPage;
