import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import api from '../../../../../../api';
import Accordion from '../../../../../../components/Accordion';
import OutlineButton from '../../../../../../components/Buttons/OutlineButton';
import PrimaryButton from '../../../../../../components/Buttons/PrimaryButton';
import Table from '../../../../../../components/Tables/Table';
import useModalHandler from '../../../../../../hooks/useModalHandler';
import useTable from '../../../../../../hooks/useTable';
import useViewRelatedEventsListener from '../../../../../../hooks/useViewRelatedEventsListener';
import bus from '../../../../../../modules/bus';
import enums from '../../../../../../utilities/enums';
import { EntityContext, PermissionGroupContext } from '../../../../../../utilities/services/contexts';
import HistoryRecordTableHeader from '../../../components/HistoryRecordTableHeader';
import RequestClientUpdateModal from '../../../components/RequestClientUpdateNaturalPersonModal';
import BiometricTableRow from './components/BiometricTableRow';
import FaceImageUpload from './components/FaceImageUpload';
import './styles.scss';

function BiometricAuthenticationSection({ badge }) {
  const { entityId } = useContext(EntityContext);

  const { isOpen: rcuOpen, open: openRCU, close: closeRCU } = useModalHandler();
  const { isOpen: isFaceImageUploadOpen, open: openFaceImageUpload, close: closeFaceImageUpload } = useModalHandler();

  const containerRef = useRef(null);

  const {
    isLoading,
    removeRow,
    records,
    changeParams,
    pagination,
    params,
    overrideTable,
    updateRowData,
    addRow,
  } = useTable(api.kyc.entityManagement.naturalPerson.getBiometricTable, { entityId });

  useViewRelatedEventsListener(enums.BUS_EVENTS.OVERRIDE_BIOMETRICS_TABLE, overrideTable, containerRef);

  const handleHistoryRecordDelete = useCallback(
    eventId => {
      removeRow('eventHistoryId', eventId);
    },
    [removeRow],
  );

  useEffect(() => {
    const updateHistoryEventStatus = ({ eventHistoryId, data }) => {
      updateRowData('eventHistoryId', eventHistoryId, data);
    };
    bus.addEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    };
  }, [updateRowData]);

  useEffect(() => {
    const onNewRecordEvent = newRecord => {
      addRow(newRecord);
    };
    bus.addEventListener(enums.BUS_EVENTS.NEW_BIOMETRIC_RECORD, onNewRecordEvent);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.NEW_BIOMETRIC_RECORD, onNewRecordEvent);
    };
  }, [addRow]);

  const { requestClientUpdate: canRCU, delete: canDelete, create: canCreate } = useContext(PermissionGroupContext);

  const TableControls = () => {
    return (
      <>
        <h3>History</h3>
        <div>
          {canCreate && <OutlineButton onClick={openFaceImageUpload}>+ Add Face Image</OutlineButton>}
          {canRCU && <PrimaryButton onClick={openRCU}> Request Client Update</PrimaryButton>}
        </div>
      </>
    );
  };

  return (
    <Accordion
      title="Biometric Authentication"
      withBadge
      badge={badge}
      accordionOpen={false}
      accented
      accordionindex={enums.ACCORDION_INDEXES.BIOMETRIC_AUTHENTICATIONS}
    >
      <div ref={containerRef}>
        {rcuOpen && (
          <RequestClientUpdateModal biometricAuthenticationEnabled hideModal={closeRCU} containerRef={containerRef} />
        )}

        {isFaceImageUploadOpen && <FaceImageUpload hideModal={closeFaceImageUpload} setNewlyAdded={() => {}} />}
        <Table
          tableControls={TableControls}
          headerRow={HistoryRecordTableHeader}
          tableRow={BiometricTableRow}
          values={records}
          handleParamsChange={changeParams}
          pagination={pagination}
          withPagination
          updating={isLoading}
          onRemove={handleHistoryRecordDelete}
          headerData={{
            sortCriteria: params.sortCriteria,
            sortOrder: params.sortOrder,
            withDelete: canDelete,
            hasServicesColumn: true,
          }}
          className="ickyc-history-record-table"
        />
      </div>
    </Accordion>
  );
}

BiometricAuthenticationSection.propTypes = {
  badge: PropTypes.number,
};
BiometricAuthenticationSection.defaultProps = {
  badge: 0,
};

export default BiometricAuthenticationSection;
