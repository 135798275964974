import { Lock } from '@material-ui/icons';
import api from 'api';
import InputField from 'components/InputField';
import RadioButtonField from 'components/RadioButtonField';
import RadioGroup from 'components/RadioGroup';
import Section from 'components/Section';
import ToggleField from 'components/ToggleField';
import React from 'react';
import { Field, useField } from 'react-final-form';
import setClassSuffix from 'utilities/services/ClassManager';
import { SEARCH_FORM_CASE_ENTITY_TYPES } from '../../../../../Kyc/CaseManagement/enums';
import Fuzziness from '../../Fuzziness';
import ScreeningSources from '../../ScreeningSources';
import './index.scss';

const RiskScreeningParameters = () => {
  const classBase = 'ickyc-risk-screening-parameters';
  const setSuffix = setClassSuffix(classBase);

  const {
    meta: { dirty: sourcesDirty, error: sourcesError },
  } = useField('sources');

  const validateProfileName = async (value, meta) => {
    if (!value) {
      return 'Required';
    }

    if (!meta.touched) return;

    try {
      const { data } = await api.administration.checkRiskScreeningProfileName(value);

      if (!data.isNameValid) {
        return data.message;
      }
    } catch {
      return 'Profile Name is already taken';
    }
  };

  return (
    <div className={classBase}>
      <Section title="Profile Name*" divider>
        <Field component={InputField} name="name" required validate={validateProfileName} />
      </Section>
      <Section title="Search Data" divider>
        <div className={setSuffix('__search-data')}>
          <span>
            <Lock />
            Name (Required)
          </span>
          <Field
            component={ToggleField}
            showCustomText
            toggleOnText="Add Date of Birth / Incorporation"
            toggleOffText="Add Date of Birth / Incorporation"
            name="addYear"
          />
          <Field
            component={ToggleField}
            showCustomText
            toggleOnText="Add Country"
            toggleOffText="Add Country"
            name="addCountry"
          />
        </div>
      </Section>
      <Section title="Fuzziness" divider>
        <p>
          Level of flexibility of search. 0% <i>Exact Match</i> will check for exact matches only. 100%{' '}
          <i>Broad Match</i> will look for results with even the slightest match in spelling and phonetics.
        </p>
        <Field name="fuzziness" component={Fuzziness} />
      </Section>
      <Section title="Entity Type" divider>
        <RadioGroup>
          {SEARCH_FORM_CASE_ENTITY_TYPES.map((label, index) => (
            <Field
              name="entityType"
              type="radio"
              value={index}
              label={label}
              component={RadioButtonField}
              parse={val => Number(val)}
            />
          ))}
        </RadioGroup>
      </Section>
      <Section title="Sources*">
        <Field
          name="sources"
          defaultValue={[]}
          render={({ input }) => <ScreeningSources value={input.value} onChange={input.onChange} />}
        />
        {sourcesError && sourcesDirty && <div className={`${classBase}__error`}>{sourcesError}</div>}
      </Section>
    </div>
  );
};

export default RiskScreeningParameters;
