import api from 'api';
import Loader from 'components/Loader';
import useSelect from 'hooks/useSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import LogoUploadInput from 'scenes/Administration/PortalManagementPage/components/NaturalPersonPortalModal/components/Styling/components/LogoUploadInput';
import { selectUserAccessByLicense } from 'store/selectors/auth.selector';
import toastr from 'toastr';
import IComplyForm from '../../../../../components/Form/IComplyForm';
import ToggleField from '../../../../../components/ToggleField';
import './styles.scss';

const CompanySettingsPage = () => {
  const [initialValues, setInitialValues] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const platformAccessByLicence = useSelect(selectUserAccessByLicense);

  const isPortalManagementLicenceExisting = platformAccessByLicence.includes('Portal Management');
  const isFileManagementLicenceExisting = platformAccessByLicence.includes('Files Management');

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const {
          data: { logoUrl, ...restData },
        } = await api.administration.account.companySettings();
        setInitialValues({ logoUrl: { preview: logoUrl }, ...restData });
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleFormSubmit = useCallback(async data => {
    try {
      const { logoUrl, ...restData } = data;
      let newLogoUrl;

      if (logoUrl?.file) {
        const { logoUrl: newLogo } = await uploadLogo(logoUrl.file);
        newLogoUrl = newLogo;
      }
      await api.administration.account.updateCompanySettings(restData);
      setInitialValues({ ...restData, logoUrl: newLogoUrl ? { preview: newLogoUrl } : logoUrl });
      toastr.success('Successfully updated Company Settings');
    } catch (err) {
      console.error(err);
      toastr.error('Error occured while updating Company Settings');
    }
  }, []);

  const uploadLogo = useCallback(async file => {
    try {
      const formData = new FormData();
      formData.set('image', file);
      const {
        data: { logoUrl },
      } = await api.administration.account.updateCompanyLogo(formData);
      toastr.success('Successfully uploaded Company Logo');

      return logoUrl;
    } catch (err) {
      console.error(err);
      toastr.error('Error occured while uploading Company Logo');
    }
  }, []);

  return (
    <IComplyForm
      onSubmit={handleFormSubmit}
      showControls={!isLoading}
      className="ickyc-company-settings"
      initialValues={initialValues}
    >
      {isLoading && <Loader />}
      <Field name="mandatoryTwoFactor" component={ToggleField} label="Mandatory Two Factor Authentication" />
      <Field name="logoUrl" withActions component={LogoUploadInput} />
      {isPortalManagementLicenceExisting && (
        <Field
          name="useLogoInPortalsByDefault"
          component={ToggleField}
          label="Use this logo in all portals by default"
        />
      )}
      {isFileManagementLicenceExisting && (
        <Field
          name="useLogoInReportsByDefault"
          component={ToggleField}
          label="Use this logo in all reports by default"
        />
      )}
    </IComplyForm>
  );
};
export default CompanySettingsPage;
