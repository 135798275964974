import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import toastr from 'toastr';
import api from '../../../../../api';
import DebounceInput from '../../../../../components/DebounceInput';
import TabHeader from '../../../../../components/Headers/TabHeader';
import CheckboxTable from '../../../../../components/Tables/CheckboxTable';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import bus from '../../../../../modules/bus';
import enums from '../../../../../utilities/enums';
import TableHeaders from '../../../../../utilities/services/TableHeaders';
import { CaseContext, MatchesFilterContext } from '../../../../../utilities/services/contexts';
import FilterToggleButton from '../../../components/FilterToggleButton';
import SearchControls from '../../../components/SearchControls';
import { CASE_MATCH_STATUS } from '../../enums';
import MatchesControls from './components/MatchesControls';
import MatchesFilterModal from './components/MatchesFilterModal';
import MatchesTableRow from './components/MatchesTableRow';
import './styles.scss';
import utils from './utils';

const Matches = ({ selectedAMLFilters, onMatchHitStatusChange, setIsFiltersDisabled }) => {
  const { areFilterArraysEmpty } = utils;
  const [selectedStatus, setSelectedStatus] = useState(CASE_MATCH_STATUS.POTENTIAL);
  const [params, setParams] = useState({ sortCriteria: 'name', sortOrder: 0, limit: 10 });
  const [pagination, setPagination] = useState({});
  const { complianceLogId, isCaseClosed } = useContext(CaseContext);
  const [hits, setHits] = useState([]);
  const [hitCounts, setHitCounts] = useState({});

  const [loading, setLoading] = useState(false);

  const [filterOn, setFilterOn] = useState(false);

  const [filters, setFilters] = useState({
    entityTypes: [],
    countries: [],
    relevanceTerms: [],
  });

  const handleFilterToggle = useCallback(() => {
    setFilterOn(prev => !prev);
  }, []);

  const handleStatusChange = useCallback(
    status => {
      setSelectedStatus(status);
      setIsFiltersDisabled(status !== 'potential');
    },
    [setSelectedStatus, setIsFiltersDisabled],
  );

  const buttonProps = [
    {
      text: 'Potential ',
      value: CASE_MATCH_STATUS.POTENTIAL,
      badgeValue: hitCounts.potentialCount || 0,
      onClick: () => handleStatusChange(CASE_MATCH_STATUS.POTENTIAL),
      selected: selectedStatus === CASE_MATCH_STATUS.POTENTIAL,
      isRed: true,
    },
    {
      text: 'Unknown ',
      badgeValue: hitCounts.unknownCount || 0,
      value: CASE_MATCH_STATUS.UNKNOWN,
      onClick: () => handleStatusChange(CASE_MATCH_STATUS.UNKNOWN),
      selected: selectedStatus === CASE_MATCH_STATUS.UNKNOWN,
    },
    {
      text: 'Rejected ',
      badgeValue: hitCounts.rejectedCount || 0,
      value: CASE_MATCH_STATUS.REJECTED,
      onClick: () => handleStatusChange(CASE_MATCH_STATUS.REJECTED),
      selected: selectedStatus === CASE_MATCH_STATUS.REJECTED,
    },
    {
      text: 'Accepted ',
      badgeValue: hitCounts.acceptedCount || 0,
      value: CASE_MATCH_STATUS.ACCEPTED,
      onClick: () => handleStatusChange(CASE_MATCH_STATUS.ACCEPTED),
      selected: selectedStatus === CASE_MATCH_STATUS.ACCEPTED,
    },
  ];

  const updateSelectedMatchesStatus = useCallback(
    (newStatus, selectedHits) => {
      setLoading(true);
      api.kyc.caseManagement
        .updateComplianceHitStatus(complianceLogId, {
          status: newStatus,
          hitIds: selectedHits,
        })
        .then(({ data: { consolidatedMatchTypes, comment } }) => {
          setHits(prev => prev.filter(match => !selectedHits.some(m => m === match.id)));
          setHitCounts(prev => ({
            ...prev,
            [`${selectedStatus}Count`]: prev[`${selectedStatus}Count`] - selectedHits.length,
            [`${newStatus}Count`]: prev[`${newStatus}Count`] + selectedHits.length,
          }));
          setPagination(prev => ({
            ...prev,
            total: prev.total - selectedHits.length,
            tempLimit: (prev.tempLimit || prev.limit * prev.currentPage) - 1,
          }));
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, comment);
          toastr.success(`Successfully Update Hit's status`);
          onMatchHitStatusChange(consolidatedMatchTypes);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [complianceLogId, selectedStatus],
  );

  const classBase = 'ickyc-matches';
  const classess = classNames({
    [classBase]: true,
    [`${classBase}--loading`]: loading,
  });

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  const applySearchTerm = useCallback(searchString => {
    setParams(prev => ({ ...prev, searchString }));
  }, []);

  const handleFilterSave = useCallback(values => {
    setFilters(values);
    setFilterOn(!areFilterArraysEmpty(values));
  }, []);

  const matchesTableHeader = useMemo(() => {
    return isCaseClosed
      ? TableHeaders.CaseMatchesTableHeader.filter(headerItem => headerItem.type !== 'controls')
      : TableHeaders.CaseMatchesTableHeader;
  }, [isCaseClosed]);

  useEffect(() => {
    const getTableMatches = () => {
      setLoading(true);

      api.kyc.caseManagement
        .getCaseManagementMatchesTable({
          filters,
          filterOn,
          complianceLogId,
          selectedAMLFilters,
          status: selectedStatus,
          ...params,
          skip: params.limit !== params.skip / (params.currentPage - 1) ? 0 : params.skip,
        })
        .then(response => {
          const { hits: h, counts: c } = response.data;
          setHits(h.data.map(({ hitId, ...rest }) => ({ id: hitId, ...rest })));
          setHitCounts(c);
          setPagination(h.paginationData);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getTableMatches();
  }, [selectedAMLFilters, params, complianceLogId, selectedStatus, filters, filterOn]);

  return (
    <div className={`${classess}`}>
      <MatchesFilterContext.Provider value={{ selectedAMLFilters }}>
        <TabHeader title="Matches" buttonProps={buttonProps} />

        <SearchControls>
          <div className="ickyc-search-controls__filter-section">
            <DebounceInput
              initialValue={params.searchString}
              placeholder="Search Entities"
              onChange={applySearchTerm}
            />
            <FilterToggleButton filters={filters} filterOn={filterOn} onToggleFilter={handleFilterToggle}>
              <MatchesFilterModal initialFilters={filters} onFilterSave={handleFilterSave} />
            </FilterToggleButton>
          </div>
        </SearchControls>

        <CheckboxTable
          disabled={isCaseClosed}
          values={hits}
          pagination={pagination}
          tableRow={MatchesTableRow}
          headerRow={TableHeader}
          TableControlsComponent={MatchesControls}
          onControls={updateSelectedMatchesStatus}
          updating={loading}
          withPagination
          withLimitChange
          handleParamsChange={changeParams}
          headerData={{
            sortCriteria: params.sortCriteria,
            sortOrder: params.sortOrder,
            onClick: changeParams,
            items: matchesTableHeader,
          }}
        />
      </MatchesFilterContext.Provider>
    </div>
  );
};

Matches.propTypes = {
  selectedAMLFilters: PropTypes.arrayOf(PropTypes.string),
  setIsFiltersDisabled: PropTypes.func,
};
Matches.defaultProps = {
  selectedAMLFilters: [],
  setIsFiltersDisabled: () => {},
};
export default Matches;
