import React, { useContext, useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../../../components/Accordion';
import SelectCustomContent from '../../../../../../../../../components/Select/components/SelectCustomContent';
import SelectInputTrigger from '../../../../../../../../../components/Select/components/SelectInputTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import utilities from '../../../../../../../../../utilities';
import { RCUContext } from '../../../../../../../../../utilities/services/contexts';
import './styles.scss';

const BiometricAuthenticationRCU = ({ namePrefix }) => {
  const baseClass = 'ickyc-biometric-auth-rcu';

  const { values } = useFormState();

  const kycRequests = values[namePrefix] ? values[namePrefix].kycRequests : values.kycRequests;

  const { initialConfiguration } = useContext(RCUContext);

  const { batch, change } = useForm();

  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  const faceMatchTypesOptions = initialConfiguration?.faceMatchTypes.map(elm => ({
    id: elm.id.toString(),
    label: elm?.name,
    value: elm.id.toString(),
  }));

  useEffect(() => {
    if (initialConfiguration?.faceMatchTypes && faceMatchTypesOptions.length) {
      batch(() => {
        change(
          `${namePrefixWithDot}kycRequests.biometricAuthentication.faceMatchType`,
          faceMatchTypesOptions[0]?.value,
        );
      });
    }
  }, [initialConfiguration]);

  return (
    <Accordion
      title={
        <>
          <div onClick={utilities.handleOnClickEvent}>
            <Field
              preview
              name={`${namePrefixWithDot}kycRequests.biometricAuthentication.isEnabled`}
              component={ToggleField}
              label="Biometric Authentication"
            />
          </div>
          <span className="ickyc-spacer" />
        </>
      }
      accordionOpen={kycRequests?.biometricAuthentication?.isEnabled}
    >
      <div className={baseClass}>
        <Field
          name={`${namePrefixWithDot}kycRequests.biometricAuthentication.isEnabled`}
          component={ToggleField}
          label="Run KYC Service: Biometric Authentication"
          className={`${baseClass}__auto-accept-toggle`}
        />
        <Field
          name={`${namePrefixWithDot}kycRequests.biometricAuthentication.faceMatchType`}
          component={SelectField}
          label="Face Match Type"
          className={`${baseClass}__face-match-type-select`}
          options={faceMatchTypesOptions}
          disabled
          Trigger={<SelectInputTrigger placeholder="Live Face Match with fallback to Selfie Upload" />}
          Content={<SelectCustomContent />}
        />
      </div>
    </Accordion>
  );
};
export default BiometricAuthenticationRCU;
