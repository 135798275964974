import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/webhook';

const ENDPOINTS = {
  getWebHooks: () => `${BASE_ROUTE}/subscriptions`,
  createNewWebHook: () => `${BASE_ROUTE}/subscriptions`,
  deleteMyWebHook: webHookId => `${BASE_ROUTE}/subscriptions/${webHookId}`,
  testEndpoint: webHookId => `${BASE_ROUTE}/subscriptions/${webHookId}/test`,
  getWebHookSecret: webHookId => `${BASE_ROUTE}/subscriptions/${webHookId}/secret`,
  updateWebHook: webHookId => `${BASE_ROUTE}/subscriptions/${webHookId}`,
};
const getWebHooks = params => {
  return axiosInstance.get(ENDPOINTS.getWebHooks(), { params });
};
const createNewWebHook = data => {
  data.types = data.types.join('|');
  return axiosInstance.post(ENDPOINTS.createNewWebHook(), data);
};
const deleteMyWebHook = webHookId => axiosInstance.delete(ENDPOINTS.deleteMyWebHook(webHookId));

const testEndpoint = webHookId => axiosInstance.get(ENDPOINTS.testEndpoint(webHookId));

const getWebHookSecret = webHookId => axiosInstance.get(ENDPOINTS.getWebHookSecret(webHookId));

const updateWebHook = data => {
  data.types = data.types.join('|');
  return axiosInstance.put(ENDPOINTS.updateWebHook(data.id), data);
};

export default {
  getWebHooks,
  createNewWebHook,
  deleteMyWebHook,
  testEndpoint,
  getWebHookSecret,
  updateWebHook,
};
