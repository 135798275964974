import React from 'react';

export default () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 53 53.5" style={{ enableBackground: ' 0 0 53 53.5' }}>
      <g>
        <path
          style={{
            opacity: 0.25,
            enableBackground: 'new',
          }}
          d="M52,27.6c0,13.3-10.8,24-24,24S4,40.9,4,27.6s10.7-24,24-24C41.2,3.6,52,14.4,52,27.6z"
        />
        <path
          style={{
            fill: '#E42A25',
          }}
          d="M50,25.2c0,13.3-10.8,24-24,24S2,38.4,2,25.2s10.8-24,24-24C39.3,1.2,50,11.9,50,25.2z"
        />
        <path
          style={{
            fill: '#E42A25',
          }}
          d="M47.2,25.2c0,11.7-9.5,21.3-21.3,21.3C14.2,46.5,4.7,37,4.7,25.2C4.7,13.4,14.2,3.9,26,3.9
      C37.7,3.9,47.2,13.4,47.2,25.2z"
        />
        <polygon
          style={{
            fill: '#FFFFFF',
          }}
          points="37,19.2 32.3,14.5 25.5,21.3 18.7,14.5 14,19.2 20.8,26 14.9,31.8 19.6,36.5 25.5,30.7 31.4,36.5
      36.1,31.8 30.2,26 	"
        />
      </g>
    </svg>
  );
};
