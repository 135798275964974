import classNames from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';
import React from 'react';
import setClassSuffix from 'utilities/services/ClassManager';
import './styles.scss';

const DashboardTile = ({ header, buttonText, children, className, isLoading, buttonAction, hideButton }) => {
  const baseClass = 'ickyc-dashboard-tile';
  const setSuffix = setClassSuffix(baseClass);
  const classes = classNames([baseClass, className]);

  return (
    <div className={classes}>
      <h3 className={setSuffix('__header')}>{header}</h3>
      {isLoading ? <Spinner /> : <div className={setSuffix('__content')}>{children}</div>}
      {!hideButton && <PrimaryButton onClick={buttonAction}>{buttonText}</PrimaryButton>}
    </div>
  );
};
DashboardTile.propTypes = {
  header: '',
  buttonText: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isLoading: PropTypes.bool,
  buttonAction: PropTypes.func,
  hideButton: PropTypes.bool,
};
DashboardTile.defaultProps = {
  header: '',
  buttonText: '',
  className: '',
  isLoading: true,
  buttonAction: () => {},
  hideButton: false,
};
export default DashboardTile;
