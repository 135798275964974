import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
const PreviewDocumentDetails = ({ details }) => {
  const { packageName, uploadedBy, comment, status, sourceType, assignedUserId } = details;

  const usersToAssign = useSelector(state => state.global.usersToAssign);

  const assignedUserName = useMemo(() => usersToAssign.find(el => el.id === assignedUserId)?.name, [usersToAssign]);
  return (
    <>
      <div>
        <b>Document Package</b>
        <span>{packageName}</span>
      </div>

      <div>
        <b>Assigned To</b>
        <span>{assignedUserName}</span>
      </div>

      <div>
        <b>Status</b>
        <span>{status}</span>
      </div>

      <div>
        <b>Comment</b>
        <span>{comment}</span>
      </div>

      <div>
        <b>Source Type</b>
        <span>{sourceType}</span>
      </div>

      <div>
        <b>Uploaded By</b>
        <span>{uploadedBy}</span>
      </div>
    </>
  );
};
export default PreviewDocumentDetails;
