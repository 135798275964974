import UserVerificationTag from 'components/UserVerificationTag';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import toastr from 'toastr';
import api from '../../../../../api';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import FormModal from '../../../../../components/Modal/FormModal';
import useModalHandler from '../../../../../hooks/useModalHandler';
import ProtectedComponent from '../../../../../router/components/ProtectedComponent';
import authEnums from '../../../../../utilities/enums/authEnums';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../utilities/services/DateManager';
const UserManagmentRow = ({
  rowInfo: { name, type, email, tags, userId, status, lastLogin, verificationStatus },
  className,
  onClick,
  onRemove,
}) => {
  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const [forceRequest, setForceRequest] = useState(false);

  const { close: closeConfirmModal, open: openConfirmModal, isOpen: isConfirmModal } = useModalHandler();
  const handleEditUser = useCallback(
    event => {
      event.stopPropagation();
      onClick(userId);
    },
    [userId, onClick],
  );

  const handleUserDelete = () => {
    return api.administration.userManagement
      .deleteUser(userId, forceRequest)
      .then(() => {
        closeConfirmModal();
        onRemove(userId);
        toastr.success(`${name} has been deleted`);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            if (
              data?.message?.length &&
              data.message
                .join('')
                .includes('This user has some assignments that will be removed once the user is deleted')
            ) {
              setForceRequest(true);
            } else {
              setForceRequest(false);
            }
            return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
          }
          if (resStatus === 500) {
            return {
              [FORM_ERROR]: Array.isArray(data.message)
                ? data.message.join('')
                : data.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  return (
    <tr key={userId} className={`${className}__row`}>
      <td className={setColumnSuffix('__verifiedTag')}>
        <UserVerificationTag verificationStatus={verificationStatus} />
      </td>
      <td className={setColumnSuffix('__name')} onClick={handleEditUser}>
        <span>{name}</span>
      </td>
      <td className={setColumnSuffix('__type')} onClick={handleEditUser}>
        <span>{type}</span>
      </td>
      <td className={setColumnSuffix('__email')} onClick={handleEditUser}>
        <span>{email}</span>
      </td>
      <td className={setColumnSuffix('__tags')} onClick={handleEditUser}>
        <span>{tags?.join(', ')}</span>
      </td>
      <td className={setColumnSuffix('__status')} onClick={handleEditUser}>
        <span>{status}</span>
      </td>
      <td className={setColumnSuffix('__lastLogin')} onClick={handleEditUser}>
        <span>{lastLogin ? DateManager.getFullDateLocal(lastLogin) : ''}</span>
      </td>
      <ProtectedComponent
        requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.delete]}
        permissionGroup={authEnums.PERMISSION_GROUP.USER_MANAGEMENT}
      >
        <td className={setColumnSuffix('__remove')}>
          <DeleteButton onClick={openConfirmModal} />
          <div>
            {isConfirmModal && (
              <FormModal
                hideModal={closeConfirmModal}
                title="Remove Entity"
                className="ickyc-confirmation-modal"
                onSubmit={handleUserDelete}
                showConfirmationForm={forceRequest}
                onRejectClick={closeConfirmModal}
                showErrorsInConfirmation
                confirmButtonText="Yes"
                confirmationText="Would you like to proceed?"
                rejectButtonText="No"
              >
                <span className="ickyc-confirmation-message">
                  Are you sure you want to delete &nbsp; <h3>{name}</h3>
                </span>
              </FormModal>
            )}
          </div>
        </td>
      </ProtectedComponent>
    </tr>
  );
};

UserManagmentRow.propTypes = {
  rowInfo: PropTypes.shape({
    userId: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    email: PropTypes.string,
    tags: PropTypes.array,
    status: PropTypes.string,
    lastLogin: PropTypes.string,
    verificationStatus: PropTypes.number,
  }),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

UserManagmentRow.defaultProps = {
  className: '',
  rowInfo: {},
  onRemove: () => {},
  onClick: null,
};
export default UserManagmentRow;
