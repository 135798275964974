import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

/**
 * Simple tag element.
 * By default in primary green color.
 * @param {string} name - name displayed inside the tag
 * @param {boolean} red - should tag be red
 */

const Tag = ({ name, red, outline, gray }) => {
  const classes = classNames([
    'ickyc-tag',
    {
      'ickyc-tag--red': red,
    },
    {
      'ickyc-tag--outline': outline,
    },
    {
      'ickyc-tag--gray': gray,
    },
  ]);

  return <div className={classes}>{name}</div>;
};

Tag.propTypes = {
  name: PropTypes.string,
  red: PropTypes.bool,
  gray: PropTypes.bool,
  outline: PropTypes.bool,
};

Tag.defaultProps = {
  name: '',
  red: false,
  gray: false,
  outline: false,
};

export default Tag;
