import CountrySelectField from 'components/CountrySelectField';
import DatePickerField from 'components/DatepickerField';
import InputField from 'components/InputField';
import StateProvinceSelectField from 'components/StateProvinceSelectField';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';
import utilities from '../../../../../../utilities';
import Constants from '../../../../../../utilities/constants';
import validators from '../../../../../../utilities/services/validators';

/**
 * @param {string} namePreffix - preffix attached to all field name (e.g if component is used in FieldArray)
 * @param {*} param0
 */

const NaturalPerson = ({ index, namePreffix }) => {
  const {
    values: { persons },
  } = useFormState();

  const [countryId, isAddressInfoFilledIn] = useMemo(() => {
    const person = persons[index - 1]?.data;
    const countryIdl = person?.residence || 0;

    return [countryIdl, person?.streetAddress || person?.unit || person?.city || person?.postalZip];
  }, [persons, index]);

  return (
    <>
      <Field
        name={`${namePreffix}.data.index`}
        render={({ meta: { invalid, submitting, submitError } }) => {
          if (!submitting && invalid) {
            return <span className={submitError}>{index}</span>;
          }
          return <span>{index}</span>;
        }}
      />
      <span>
        <Field
          component={InputField}
          name={`${namePreffix}.data.firstName`}
          validate={validators.composeValidators(
            validators.required(),
            validators.stringMaxLength(Constants.PERSONAL_NAME_MAX_LENGTH),
          )}
          parse={utilities.parseName}
        />
      </span>
      <span>
        <Field
          component={InputField}
          name={`${namePreffix}.data.middleName`}
          parse={utilities.parseName}
          validate={validators.stringMaxLength(Constants.PERSONAL_NAME_MAX_LENGTH)}
        />
      </span>
      <span>
        <Field
          component={InputField}
          name={`${namePreffix}.data.lastName`}
          validate={validators.composeValidators(
            validators.required(),
            validators.stringMaxLength(Constants.PERSONAL_NAME_MAX_LENGTH),
          )}
          parse={utilities.parseName}
        />
      </span>
      <span>
        <Field component={CountrySelectField} renderAsPortal name={`${namePreffix}.data.nationality`} />
      </span>
      <span>
        <Field
          component={DatePickerField}
          validate={validators.composeValidators(
            validators.dateIsAfter(new Date(1900, 0, 1), 'DOB must be above 01-01-1900'),
            validators.dateIsBefore(new Date(), 'DOB must be in the past'),
            validators.validateDate(),
          )}
          name={`${namePreffix}.data.birthDate`}
        />
      </span>
      <span>
        <Field
          component={InputField}
          name={`${namePreffix}.data.email`}
          validate={validators.composeValidators(validators.required(), validators.email('Invalid email address'))}
        />
      </span>
      <span>
        <Field component={InputField} name={`${namePreffix}.data.phone`} parse={value => value.replace(/[^\d]/g, '')} />
      </span>
      <span>
        <Field
          component={CountrySelectField}
          initialValue={0}
          key={isAddressInfoFilledIn}
          renderAsPortal
          name={`${namePreffix}.data.residence`}
          autoClose
          validate={!!isAddressInfoFilledIn && validators.required()}
        />
      </span>
      <span>
        <Field component={InputField} name={`${namePreffix}.data.streetAddress`} />
      </span>
      <span>
        <Field component={InputField} name={`${namePreffix}.data.unit`} />
      </span>
      <span>
        <Field component={InputField} name={`${namePreffix}.data.city`} />
      </span>

      <span>
        <Field
          component={StateProvinceSelectField}
          initialValue={0}
          countryId={countryId}
          name={`${namePreffix}.data.stateProvince`}
          renderAsPortal
        />
      </span>

      <span>
        <Field component={InputField} name={`${namePreffix}.data.postalZip`} />
      </span>
    </>
  );
};

NaturalPerson.propTypes = {
  namePreffix: PropTypes.string,
  index: PropTypes.number,
};
NaturalPerson.defaultProps = {
  namePreffix: '',
  index: 0,
};
export default NaturalPerson;
