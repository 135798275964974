import CaseManagement from 'scenes/Kyc/CaseManagement';
import CaseDetails from 'scenes/Kyc/CaseManagement/components/CaseDetails';
import MatchDetails from 'scenes/Kyc/CaseManagement/components/MatchDetails';
import EntityManagement from 'scenes/Kyc/EntityManagement';
import LegalEntityProfile from 'scenes/Kyc/EntityManagement/LegalEntityProfile';
import NaturalPersonProfile from 'scenes/Kyc/EntityManagement/NaturalPersonProfile';
import HomeScreen from 'scenes/Kyc/HomeScreen';
import MyWork from 'scenes/Kyc/MyWork';
import authEnums from 'utilities/enums/authEnums';
import redirect from '../../../../modules/redirect';

export default [
  {
    path: '/entities/legal-entity/:id',
    component: LegalEntityProfile,
    allowedUserTypes: [
      authEnums.USER_TYPES.CLIENT_USER,
      authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
      authEnums.USER_TYPES.PLATFORM_USER,
      authEnums.USER_TYPES.FULL_ADMIN,
      authEnums.USER_TYPES.RESTRICTED_ADMIN,
    ],
  },
  {
    path: '/entities/natural-person/:id',
    component: NaturalPersonProfile,
    allowedUserTypes: [
      authEnums.USER_TYPES.CLIENT_USER,
      authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
      authEnums.USER_TYPES.PLATFORM_USER,
      authEnums.USER_TYPES.FULL_ADMIN,
      authEnums.USER_TYPES.RESTRICTED_ADMIN,
    ],
  },
  {
    path: '/entities',
    component: EntityManagement,
    allowedUserTypes: [
      authEnums.USER_TYPES.CLIENT_USER,
      authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
      authEnums.USER_TYPES.PLATFORM_USER,
      authEnums.USER_TYPES.FULL_ADMIN,
      authEnums.USER_TYPES.RESTRICTED_ADMIN,
    ],
  },
  {
    path: '/case-management/:id',
    component: CaseDetails,
    withPermissionsCheck: true,
    allowedUserTypes: [
      authEnums.USER_TYPES.CLIENT_USER,
      authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
      authEnums.USER_TYPES.PLATFORM_USER,
      authEnums.USER_TYPES.FULL_ADMIN,
      authEnums.USER_TYPES.RESTRICTED_ADMIN,
    ],
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.amlSearchDashboard],
    permissionGroup: authEnums.PERMISSION_GROUP.RISK_SCREENING,
  },
  {
    path: '/case-management/:complianceLogId/matches/:hitId',
    component: MatchDetails,
    withPermissionsCheck: true,
    allowedUserTypes: [
      authEnums.USER_TYPES.CLIENT_USER,
      authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
      authEnums.USER_TYPES.PLATFORM_USER,
      authEnums.USER_TYPES.FULL_ADMIN,
      authEnums.USER_TYPES.RESTRICTED_ADMIN,
    ],
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.amlSearchDashboard],
    permissionGroup: authEnums.PERMISSION_GROUP.RISK_SCREENING,
  },
  {
    path: '/case-management',
    component: CaseManagement,
    withPermissionsCheck: true,
    allowedUserTypes: [
      authEnums.USER_TYPES.CLIENT_USER,
      authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
      authEnums.USER_TYPES.PLATFORM_USER,
      authEnums.USER_TYPES.FULL_ADMIN,
      authEnums.USER_TYPES.RESTRICTED_ADMIN,
    ],
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.amlSearchDashboard],
    permissionGroup: authEnums.PERMISSION_GROUP.RISK_SCREENING,
  },
  {
    path: '/my-work',
    component: MyWork,
    allowedUserTypes: [
      authEnums.USER_TYPES.CLIENT_USER,
      authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
      authEnums.USER_TYPES.PLATFORM_USER,
      authEnums.USER_TYPES.FULL_ADMIN,
      authEnums.USER_TYPES.RESTRICTED_ADMIN,
    ],
  },
  {
    path: '/home-screen',
    component: HomeScreen,
    allowedUserTypes: [
      authEnums.USER_TYPES.CLIENT_USER,
      authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
      authEnums.USER_TYPES.PLATFORM_USER,
      authEnums.USER_TYPES.FULL_ADMIN,
      authEnums.USER_TYPES.RESTRICTED_ADMIN,
    ],
  },
  {
    path: '',
    component: redirect('/home-screen'),
  },
  {
    path: '*',
    component: redirect('/kyc/home-screen', true),
  },
];
