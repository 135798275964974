import React from 'react';
import useSelect from '../../../../../../hooks/useSelect';
import { selectDefaultScreeningProfile } from '../../../../../../store/selectors/global.selector';
import KYCServicesV3 from '../../../components/KYCServicesV3';

const BatchNaturalPersonRCU = () => {
  const defaultScreeningProfile = useSelect(selectDefaultScreeningProfile);

  return (
    <div>
      <KYCServicesV3 screeningProfileId={defaultScreeningProfile?.id} entityID="batch" />
    </div>
  );
};
export default BatchNaturalPersonRCU;
