import PropTypes from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import validators from 'utilities/services/validators';
import CountrySelectField from '../../../../../../../../../../../components/CountrySelectField';
import DatepickerField from '../../../../../../../../../../../components/DatepickerField';
import InputField from '../../../../../../../../../../../components/InputField';
import StateProvinceSelectField from '../../../../../../../../../../../components/StateProvinceSelectField';
import utilities from '../../../../../../../../../../../utilities';
import IconManager from '../../../../../../../../../../../utilities/services/IconManager';

const IdentityDetailsFields = ({ documentType }) => {
  const {
    values: { documentCountryId },
  } = useFormState();

  const preventBubbling = event => {
    event.stopPropagation();
  };

  const renderDocumentJurisdiction = () => {
    if (documentType === 'Passport') return undefined;
    if (documentCountryId === 1 || documentCountryId === 2) {
      return (
        <>
          <span className="ickyc-identity-details__divider">Document Juriscdiction</span>
          <Field component={StateProvinceSelectField} name="documentJurisdictionId" countryId={documentCountryId} />
        </>
      );
    }
    return (
      <>
        <span className="ickyc-identity-details__divider">Document Juriscdiction</span>
        <Field component={CountrySelectField} name="documentJurisdictionId" />
      </>
    );
  };

  return (
    <div onClick={preventBubbling}>
      <span className="ickyc-identity-details__divider">Name</span>
      <Field component={InputField} name="firstName" label="First Name" parse={utilities.parseName} />
      <Field component={InputField} name="middleName" label="Middle Name" parse={utilities.parseName} />
      <Field component={InputField} name="lastName" label="Last Name" parse={utilities.parseName} />

      <span className="ickyc-identity-details__divider">Address</span>
      <div className="ickyc-identity-details__composite">
        <Field component={InputField} name="unit" label="Unit" />
        <Field component={InputField} name="zip" label="Zip" />
      </div>

      <span className="ickyc-identity-details__divider">State/Province</span>
      <Field component={StateProvinceSelectField} autoClose countryId={documentCountryId} name="stateProvinceId" />
      <Field component={InputField} name="city" label="City" />
      <Field component={InputField} name="street" label="Street" />

      <span className="ickyc-identity-details__divider">Date of Birth</span>
      <Field component={DatepickerField} name="dateOfBirth" maxDate={new Date()} validate={validators.validateDate()} />

      <span className="ickyc-identity-details__divider">Document Number</span>
      <Field component={InputField} name="documentNumber" />

      <span className="ickyc-identity-details__divider">Expiry Date</span>
      <Field
        component={DatepickerField}
        name="expiryDate"
        clearIcon={IconManager.get(IconManager.names.CLOSE)}
        validate={validators.validateDate()}
      />

      <span className="ickyc-identity-details__divider">Document Country</span>
      <Field component={CountrySelectField} name="documentCountryId" />
    </div>
  );
};
IdentityDetailsFields.propTypes = {
  documentType: PropTypes.string.isRequired,
};
export default IdentityDetailsFields;
