import FilterFormsService from '../../utilities/services/FilterFormsService';
import axiosInstance from '../axiosInstance';
import billing from './billing';
import licences from './licences';
import usage from './usage';

const BASE_ROUTE = '/business-manager';

const ENDPOINTS = {
  getAllBusiness: () => `${BASE_ROUTE}/clients/all`,
  verifyGlei: glei => `${BASE_ROUTE}/glei/${glei}`,
  verifyDuns: duns => `${BASE_ROUTE}/duns/${duns}`,
  getClientAccount: clientId => `${BASE_ROUTE}/clients/${clientId}/account`,
  updateClientAccount: clientId => `${BASE_ROUTE}/clients/${clientId}/account`,
  createClientAccount: () => `${BASE_ROUTE}/clients`,

  getUser: (userId, clientId) => `${BASE_ROUTE}/clients/${clientId}/users/${userId}`,
  getAllUsers: clientId => `${BASE_ROUTE}/clients/${clientId}/users/all`,
  downloadCSV: clientId => `${BASE_ROUTE}/clients/${clientId}/users/csv`,
  createUser: clientId => `${BASE_ROUTE}/clients/${clientId}/users`,
  updateUser: (userId, clientId) => `${BASE_ROUTE}/clients/${clientId}/users/${userId}`,
  inviteUser: (userId, clientId) => `${BASE_ROUTE}/clients/${clientId}/users/${userId}/invite`,

  portalsConfigurationsNaturalPerson: clientId => `${BASE_ROUTE}/clients/${clientId}/portals/natural-person`,
  portalsConfigurationsLegalEntity: clientId => `${BASE_ROUTE}/clients/${clientId}/portals/legal-entity`,
  portalConfigById: (configId, clientId) => `${BASE_ROUTE}/clients/${clientId}/portals/${configId}`,
  createPortalsConfiguration: clientId => `${BASE_ROUTE}/clients/${clientId}/portals`,
  updatePortalsConfigurationNaturalPerson: (id, clientId) =>
    `${BASE_ROUTE}/clients/${clientId}/portals/natural-person/${id}`,
  updatePortalsConfigurationLegalEntity: (id, clientId) =>
    `${BASE_ROUTE}/clients/${clientId}/portals/legal-entity/${id}`,
  deleteConfiguration: (id, clientId, type) => `${BASE_ROUTE}/clients/${clientId}/portals/${id}?portalType=${type}`,
  updateConfigurationLogoImage: (id, clientId) => `${BASE_ROUTE}/clients/${clientId}/portals/${id}/logo-image`,
  updateDefault: clientId => `${BASE_ROUTE}/clients/${clientId}/portals/default-configuration`,
  getSupportingDocumentPackages: clientId => `${BASE_ROUTE}/clients/${clientId}/packages`,
  getRiskScreeningProfilesBusinessManager: clientId => `${BASE_ROUTE}/clients/${clientId}/screening-profiles`,
  deleteUser: (userId, clientId, forceRequest) =>
    `${BASE_ROUTE}/clients/${clientId}/users/${userId}?forceRequest=${forceRequest}`,
  deleteOrganization: clientId => `${BASE_ROUTE}/clients/${clientId}`,
  getDomainsForLegalEntity: clientId => `${BASE_ROUTE}/clients/${clientId}/portals/legal-entity/domains`,
  getDomainsForNaturalPerson: clientId => `${BASE_ROUTE}/clients/${clientId}/portals/natural-person/domains`,
  getComments: clientId => `${BASE_ROUTE}/clients/${clientId}/comments`,
  deleteComment: (clientId, commentId) => `${BASE_ROUTE}/clients/${clientId}/comments/${commentId}`,
  editComment: (clientId, commentId) => `${BASE_ROUTE}/clients/${clientId}/comments/${commentId}`,
  getReplies: (clientId, commentId) => `${BASE_ROUTE}/clients/${clientId}/comments/${commentId}/replies`,
  createNewComment: clientId => `${BASE_ROUTE}/clients/${clientId}/comments`,
  createNewReply: (clientId, commentId) => `${BASE_ROUTE}/clients/${clientId}/comments/${commentId}`,
  updateLegalEntityPortalStatus: (id, clientId) =>
    `${BASE_ROUTE}/clients/${clientId}/portals/legal-entity/${id}/status`,
  updateNaturalPersonPortalStatus: (id, clientId) =>
    `${BASE_ROUTE}/clients/${clientId}/portals/natural-person/${id}/status`,
  getClientGlobalTwoFactorAuthentication: clientId => `${BASE_ROUTE}/clients/${clientId}/account/global-two-factor`,
  getAllConfigurationsForSpecificEntityType: clientId => `${BASE_ROUTE}/clients/${clientId}/portals/basic-info`,
};

const getAllBusiness = data => {
  const { filters, filterOn, ...params } = data;
  const filterProps = filterOn ? FilterFormsService.normalizeBusinessManageementFormData(filters) : {};
  return axiosInstance.post(ENDPOINTS.getAllBusiness(), { ...filterProps }, { params });
};
const verifyGlei = glei => axiosInstance.get(ENDPOINTS.verifyGlei(glei));
const verifyDuns = duns => axiosInstance.get(ENDPOINTS.verifyDuns(duns));
const getClientAccount = clientId => axiosInstance.get(ENDPOINTS.getClientAccount(clientId));
const createClientAccount = data => axiosInstance.post(ENDPOINTS.createClientAccount(), data);
const updateClientAccount = (clientId, data) => axiosInstance.put(ENDPOINTS.updateClientAccount(clientId), data);

const getAllUsers = data => {
  const { filters, filterOn, id, ...params } = data;
  const transformedFilter = FilterFormsService.normalizeUserManagementFormData(filters, filterOn);
  return axiosInstance.post(ENDPOINTS.getAllUsers(id), { ...transformedFilter }, { params });
};

const getUser = (userId, clientId) => {
  return axiosInstance.get(ENDPOINTS.getUser(userId, clientId));
};

const createUser = (clientId, data) => axiosInstance.post(ENDPOINTS.createUser(clientId), data);

const updateUser = (userId, clientId, data) => axiosInstance.put(ENDPOINTS.updateUser(userId, clientId), data);

const downloadCSV = (id, data) => axiosInstance.post(ENDPOINTS.downloadCSV(id), {}, data);
const inviteUser = (userId, clientId) => axiosInstance.post(ENDPOINTS.inviteUser(userId, clientId), {});

const getPortalsConfigurationsNaturalPerson = params => {
  const { id: clientId, ...rest } = params;
  return axiosInstance.get(ENDPOINTS.portalsConfigurationsNaturalPerson(clientId), { params: rest });
};
const getPortalsConfigurationsLegalEntity = params => {
  const { id: clientId, ...rest } = params;
  return axiosInstance.get(ENDPOINTS.portalsConfigurationsLegalEntity(clientId), { params: rest });
};

const getPortalsConfigurationById = (configId, clientId) =>
  axiosInstance.get(ENDPOINTS.portalConfigById(configId, clientId));

const createPortalsConfiguration = (clientId, data) =>
  axiosInstance.post(ENDPOINTS.createPortalsConfiguration(clientId), data);

const updatePortalsConfigurationNaturalPerson = (id, clientId, data, shouldForce) => {
  const params = shouldForce ? { forceRequest: true } : {};
  return axiosInstance.put(ENDPOINTS.updatePortalsConfigurationNaturalPerson(id, clientId), data, { params });
};

const updatePortalsConfigurationLegalEntity = (id, clientId, data) =>
  axiosInstance.put(ENDPOINTS.updatePortalsConfigurationLegalEntity(id, clientId), data);

const deleteConfiguration = (id, clientId, type, forceRequest, newDefaultPortalId) =>
  axiosInstance.delete(ENDPOINTS.deleteConfiguration(id, clientId, type), {
    data: { forceRequest, newDefaultPortalId },
  });
const updateConfigurationLogoImage = (confId, clientId, data) =>
  axiosInstance.put(ENDPOINTS.updateConfigurationLogoImage(confId, clientId), data);
const updateDefault = (clientId, data) => axiosInstance.put(ENDPOINTS.updateDefault(clientId), data);

const getSupportingDocumentPackages = clientId => {
  return axiosInstance.get(ENDPOINTS.getSupportingDocumentPackages(clientId));
};

const getRiskScreeningProfilesBusinessManager = params => {
  const { clientId, ...rest } = params;
  return axiosInstance.get(ENDPOINTS.getRiskScreeningProfilesBusinessManager(clientId), { params: rest });
};

const deleteUser = (id, clientId, forceRequest) =>
  axiosInstance.delete(ENDPOINTS.deleteUser(id, clientId, forceRequest));

const deleteOrganization = clientId => axiosInstance.delete(ENDPOINTS.deleteOrganization(clientId));
const getDomainsForLegalEntity = clientId => axiosInstance.get(ENDPOINTS.getDomainsForLegalEntity(clientId));
const getDomainsForNaturalPerson = clientId => axiosInstance.get(ENDPOINTS.getDomainsForNaturalPerson(clientId));

const getComments = clientId => () => axiosInstance.get(ENDPOINTS.getComments(clientId));
const deleteComment = clientId => commentId => axiosInstance.delete(ENDPOINTS.deleteComment(clientId, commentId));
const updateComment = clientId => (commentId, payload) =>
  axiosInstance.put(ENDPOINTS.editComment(clientId, commentId), payload);
const getReplies = clientId => (commentId, payload) =>
  axiosInstance.get(ENDPOINTS.getReplies(clientId, commentId), payload);
const createNewComment = clientId => payload => axiosInstance.post(ENDPOINTS.createNewComment(clientId), payload);
const createNewReply = clientId => (commentId, payload) =>
  axiosInstance.post(ENDPOINTS.createNewReply(clientId, commentId), payload);

const updatePortalStatus = (id, status, type, clientId, shouldForce) => {
  const params = shouldForce ? { forceRequest: true } : {};
  return axiosInstance.patch(
    type === 'NaturalPerson'
      ? ENDPOINTS.updateNaturalPersonPortalStatus(id, clientId)
      : ENDPOINTS.updateLegalEntityPortalStatus(id, clientId),
    { enabled: status },
    { params },
  );
};
const getClientGlobalTwoFactorAuthentication = clientId =>
  axiosInstance.get(ENDPOINTS.getClientGlobalTwoFactorAuthentication(clientId));

const getAllConfigurationsForSpecificEntityType = (portalType, clientId) =>
  axiosInstance.get(ENDPOINTS.getAllConfigurationsForSpecificEntityType(clientId), { params: { portalType } });

export default {
  getAllBusiness,
  verifyGlei,
  verifyDuns,
  getClientAccount,
  createClientAccount,
  updateClientAccount,
  getAllUsers,
  downloadCSV,
  getUser,
  createUser,
  updateUser,
  inviteUser,
  getPortalsConfigurationsNaturalPerson,
  getPortalsConfigurationsLegalEntity,
  getPortalsConfigurationById,
  createPortalsConfiguration,
  updatePortalsConfigurationNaturalPerson,
  updatePortalsConfigurationLegalEntity,
  deleteConfiguration,
  updateConfigurationLogoImage,
  updateDefault,
  getSupportingDocumentPackages,
  getRiskScreeningProfilesBusinessManager,
  deleteUser,
  usage,
  licences,
  billing,
  deleteOrganization,
  getDomainsForNaturalPerson,
  getDomainsForLegalEntity,
  getComments,
  deleteComment,
  updateComment,
  getReplies,
  createNewComment,
  createNewReply,
  updatePortalStatus,
  getClientGlobalTwoFactorAuthentication,
  getAllConfigurationsForSpecificEntityType,
};
