import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const ModuleOptions = ({ children, className }) => {
  const baseClass = 'ickyc-module-option';
  return <div className={classNames(baseClass, className)}>{children}</div>;
};
ModuleOptions.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};
ModuleOptions.defaultProps = {
  className: null,
};
export default ModuleOptions;
