import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { SelectContext } from '../..';
import IconManager from '../../../../utilities/services/IconManager';
import './styles.scss';

const SelectCustomContent = () => {
  const {
    hoverIndex,
    onChange,
    multipleSelection,
    withCustomOptions,
    addCustomOptions,
    filterProps: {
      suggestions,
      setSearchTerm,
      inputProps: { value: filterPropsValue },
    },
  } = useContext(SelectContext);

  const customOptionsRef = useRef();

  useEffect(() => {
    if (customOptionsRef.current) {
      customOptionsRef.focus();
    }
  }, [customOptionsRef]);

  const handleCustomClick = () => {
    addCustomOptions({
      label: filterPropsValue,
      id: filterPropsValue,
      value: filterPropsValue,
    });
    onChange({
      label: filterPropsValue,
      id: filterPropsValue,
      value: filterPropsValue,
    });
    setSearchTerm('');
  };

  const showCustomOption = useMemo(() => {
    return (filterPropsValue && !suggestions?.some(el => el.label.includes(filterPropsValue))) || !withCustomOptions;
  }, [filterPropsValue, suggestions, withCustomOptions]);

  return (
    <div className="ickyc-select-content">
      <ul>
        <li
          className={`static ${hoverIndex === 0 ? 'hovered' : ''} ${!showCustomOption ? 'disabled hidden' : ''}`}
          onClick={handleCustomClick}
        >
          Add Custom Tag
        </li>
        {suggestions.map((suggestion, index) => {
          const optionClasses = classNames({
            hovered: hoverIndex === index + 1,
            selected: false,
            [suggestion.className]: suggestion.className,
          });

          return (
            <li
              className={optionClasses}
              key={suggestion.id}
              onClick={e => {
                e.stopPropagation();
                if (!multipleSelection) {
                  setSearchTerm(suggestion.label);
                }
                onChange(suggestion);
              }}
            >
              {suggestion.label}
              {false && <span>{IconManager.get(IconManager.names.CHECK_FILLED)}</span>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SelectCustomContent.propTypes = {};
SelectCustomContent.defaultProps = {};
export default SelectCustomContent;
