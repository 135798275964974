import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import CloseButton from '../../../../../../../../../components/Buttons/CloseButton';
import PrimaryButton from '../../../../../../../../../components/Buttons/PrimaryButton';
import InputField from '../../../../../../../../../components/InputField';
import Section from '../../../../../../../../../components/Section';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import licenceEnums from '../../../../../../../../../utilities/enums/licenceEnums';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import './styles.scss';

const KYCServices = ({ namePreffix, disabled }) => {
  const { mutators } = useForm();

  const {
    input: { value: kycServicesArray },
  } = useField(`${namePreffix}.kycServices`);

  const classBase = 'ickyc-bl-kyc-services';
  const setSufix = setClassSuffix(classBase);

  const addKYCServices = useCallback(() => {
    mutators.push(`${namePreffix}.kycServices`);
  }, [mutators]);

  const removeKYCServices = useCallback(
    index => {
      mutators.remove(`${namePreffix}.kycServices`, index);
    },
    [mutators],
  );

  useEffect(() => {
    if (!kycServicesArray.length) mutators.push(`${namePreffix}.kycServices`);
  }, [mutators, namePreffix]);

  return (
    <Section className={classBase} divider>
      <div className={setSufix('__header-wrapper')}>
        <h3>KYC Services</h3>
        <PrimaryButton onClick={addKYCServices} disabled={disabled}>
          +Add KYC Services
        </PrimaryButton>
      </div>

      <FieldArray name={`${namePreffix}.kycServices`}>
        {({ fields }) => {
          return fields.map((name, index) => (
            <div
              key={name}
              className={classNames(setSufix('__tabele-row'), {
                'ickyc-gray-section': index % 2 !== 0,
                'ickyc-white-section': index % 2 === 0,
              })}
            >
              <div className={setSufix('__fields-box')}>
                <Field
                  component={SelectField}
                  name={`${name}.type`}
                  disabled={disabled}
                  options={licenceEnums.KYC_SERVICES_OPTIONS}
                  renderAsPortal
                  Trigger={<SelectTrigger placeholder="Choose" />}
                  Content={<SelectFilterContent />}
                  className={setSufix('__select-field')}
                />

                <div className={setSufix('__price-per-use')}>
                  <span>$</span>
                  <Field
                    disabled={disabled}
                    component={InputField}
                    type="number"
                    name={`${name}.price`}
                    placeholder="Price"
                  />
                  <span>Per Use</span>
                </div>
              </div>
              <CloseButton
                disabled={disabled}
                onClick={e => {
                  e.stopPropagation();
                  removeKYCServices(index);
                }}
              />
            </div>
          ));
        }}
      </FieldArray>
    </Section>
  );
};

KYCServices.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};
KYCServices.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default KYCServices;
