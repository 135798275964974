import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField } from 'react-final-form';
import InputField from '../../../../../../../components/InputField';
import Tooltip from '../../../../../../../components/Tooltip';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';

import './styles.scss';

const Association = ({ namePreffix }) => {
  const {
    input: { value: associationValue },
  } = useField(`${namePreffix}.association`);

  const baseClass = 'association__container';
  const setSufix = setClassSuffix(baseClass);

  return (
    <div className={baseClass}>
      {associationValue?.length > 20 ? (
        <Tooltip
          trigger={
            <div>
              <Field
                component={InputField}
                className={setSufix('__association')}
                name={`${namePreffix}.association`}
                label="Association"
                preview
              />
            </div>
          }
          content={<span>{associationValue}</span>}
        />
      ) : (
        <Field
          component={InputField}
          className={setSufix('__association')}
          name={`${namePreffix}.association`}
          label="Association"
          preview
        />
      )}
      <Field
        component={InputField}
        className={setSufix('__registration-number')}
        name={`${namePreffix}.registrationNumber`}
        label="Registration Number"
        preview
      />
    </div>
  );
};

Association.propTypes = {
  namePreffix: PropTypes.string,
};

Association.defaultProps = {
  namePreffix: 'personalInformation',
};

export default Association;
