import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Table from 'components/Tables/Table';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import useTable from 'hooks/useTable';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import toastr from 'toastr';
import TableHeaders from 'utilities/services/TableHeaders';
import api from '../../../../../api';
import PlusCircular from '../../../../../assets/PlusCircular';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import Section from '../../../../../components/Section';
import BillingHistoryTableRow from './components/BillingHistoryTableRow';
import CardInfo from './components/CardInfo';
import './styles.scss';

const BillingPage = () => {
  const [cards, setCards] = useState([]);
  const [isCardsLoading, setIsCardsLoading] = useState(false);
  const [showNewPaymentForm, setShowNewPaymentForm] = useState(false);
  const [isBillingHistoryLoading, setIsBillingHistoryLoading] = useState(false);
  const [isPaymentMethodCard, setIsPaymentMethodCard] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { records: billingHistory, changeParams, params, pagination, isLoading } = useTable(
    api.administration.account.billingHistory,
    {
      limit: 10,
      sortCriteria: 'name',
      sortOrder: 0,
    },
  );

  const handleCardSubmit = useCallback(async () => {
    if (!elements) return;

    const card = elements.getElement(CardElement);

    if (!card || !stripe) return;

    try {
      setIsCardsLoading(true);
      const result = await stripe.createToken(card);

      if (result.token) {
        const { data } = await api.administration.account.addNewPaymentMethod({
          paymenttoken: result.token.id,
        });
        setCards(old => [...old, data]);
        setShowNewPaymentForm(false);
        toastr.success('Successfully added new Payment Method');
      } else if (result.error) {
        console.error(result.error);
      }
    } catch (er) {
      console.error(er);
      toastr.error('Error occured while adding new Payment Method');
    } finally {
      setIsCardsLoading(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsCardsLoading(true);
        const {
          data: { cardDetails, paymentMethod },
        } = await api.administration.account.getPaymentMethods();
        setCards(cardDetails);
        setIsPaymentMethodCard(paymentMethod === 1);
      } catch (err) {
        console.error(err);
        setIsPaymentMethodCard(err.response.data.data?.paymentMethod === 1);
      } finally {
        setIsCardsLoading(false);
      }
    })();
  }, []);

  return (
    <div className="kyc-billing-page">
      {isPaymentMethodCard && (
        <Section title="Card Details" divider isUpdating={isCardsLoading}>
          <div className="kyc-billing-page__cards-box">
            {cards?.map(card => (
              <CardInfo key={card.id} {...card} setCards={setCards} setIsLoading={setIsCardsLoading} />
            ))}
            {showNewPaymentForm && (
              <Form
                onSubmit={handleCardSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="kyc-billing-page__new-payment-form">
                    <CardElement />
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                  </form>
                )}
              />
            )}
            <PrimaryButton type="button" onClick={() => setShowNewPaymentForm(true)}>
              <PlusCircular />
              Add New Payment Method
            </PrimaryButton>
          </div>
        </Section>
      )}
      <Section title="Billing History">
        <div className="kyc-billing-history-table">
          <Table
            values={billingHistory}
            tableRow={BillingHistoryTableRow}
            headerRow={TableHeader}
            headerData={{
              items: TableHeaders.BillingHistoryTableHeader,
            }}
            updating={isBillingHistoryLoading}
            withLoadMore
            withPagination
            pagination={pagination}
            handleParamsChange={changeParams}
          />
        </div>
      </Section>
    </div>
  );
};
export default BillingPage;
