/* eslint-disable max-len */
import { Lock } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import RadioButtonField from '../../../../../../../../../components/RadioButtonField';
import RadioGroup from '../../../../../../../../../components/RadioGroup';
import ToggleField from '../../../../../../../../../components/ToggleField';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import BiometricAuthenticationSettings from '../../../../../../../BiometricAuthenticationPage/components/FacialMatchingSettings';
import ModuleOption from '../ModuleOption';
import ModuleOptions from '../ModuleOptions';
import ModuleSettings from '../ModuleSettings';
import ModuleTitleSettings from '../ModuleTitleSettings';
import './styles.scss';

const BiometricAuthenticationModule = ({ index, indexToShow, isDropAllowed }) => {
  const className = 'ickyc-table';

  const baseClass = 'ickyc-biometrics';
  const setSuffix = setClassSuffix(baseClass);

  const {
    input: { value: toggleValue },
  } = useField(`modules[${index}].isEnabled`);

  return (
    <CollapsibleTableRow
      isDropAllowed={isDropAllowed}
      className={className}
      withIndexColumn
      isToggled={toggleValue}
      index={indexToShow}
      icon={<Lock />}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Biometric Authentication</td>
          <td className={`${className}__column__enabled`} onClick={e => e.stopPropagation()}>
            <Field
              preview
              name={`modules[${index}].isEnabled`}
              component={ToggleField}
              showCustomText
              toggleOnText="Enabled"
            />
          </td>
        </>
      }
    >
      <ModuleSettings className={baseClass}>
        <ModuleTitleSettings index={index} withDescription />
        <ModuleOptions>
          <ModuleOption>
            <RadioGroup label="When Upload/Verification Fails">
              <Field
                component={RadioButtonField}
                name={`modules[${index}].source`}
                type="radio"
                parse={value => Number(value)}
                value={1}
                label={
                  <>
                    Allow&nbsp;&nbsp;
                    <Field
                      name={`modules[${index}].numberOfRetries`}
                      className={setSuffix('__number-of-retries')}
                      component={InputField}
                      max="3"
                      min="0"
                      type="number"
                    />
                    Retries, Then Capture Data
                  </>
                }
              />
              <Field
                component={RadioButtonField}
                name={`modules[${index}].source`}
                type="radio"
                value={1}
                parse={value => Number(value)}
                label="Capture Data"
              />
            </RadioGroup>
          </ModuleOption>
          <ModuleOption>
            <BiometricAuthenticationSettings
              biomtricConfidenceSlider
              showRejectionMessageField
              prefix={`modules[${index}]`}
              index={index}
              autoAcceptanceFieldName="globalSettings.biometricAuthenticationAutoAceptance"
            />
          </ModuleOption>
        </ModuleOptions>
      </ModuleSettings>
    </CollapsibleTableRow>
  );
};

BiometricAuthenticationModule.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
};

export default BiometricAuthenticationModule;
