import classNames from 'classnames';
import Accordion from 'components/Accordion';
import OutlineButton from 'components/Buttons/OutlineButton';
import CommentList from 'components/CommentList';
import Loader from 'components/Loader';
import NewCommentModal from 'components/Modal/NewCommentModal';
import TableNavigation from 'components/TableNavigation';
import useAdditionalParamsDep from 'hooks/useAdditionalParamsDep';
import useTablePagination from 'hooks/useTablePagination';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import enums from 'utilities/enums';
import { CommentsContext } from 'utilities/services/contexts';
import DateManager from 'utilities/services/DateManager';
import IconManager from 'utilities/services/IconManager';
import './styles.scss';

/**
 * Collapsible section containing comments inside.
 * @param {boolean} openModalOutside - should flag be opened due to outer
 * @param {function} setOpenModalOutside - openModalOutside setter
 * @param {reference} pageRef - reference of the page containing the comments
 */

const CommentsSections = ({
  openModalOutside,
  setOpenModalOutside,
  isCollapsible,
  newCommentButton,
  apiGetComments,
  apiGetReplies,
  apiDeleteComment,
  apiEditComment,
  apiCreateComment,
  apiCreateReply,
  additionalRequestParams,
  type,
}) => {
  const [params, setParams] = useState({
    skip: 0,
    limit: 5,
  });
  const [parentComment, setParentComment] = useState(null);
  const [commentMap, setCommenMap] = useState(new Map());
  const [openModalInside, setOpenModalInside] = useState(false);
  const [newReply, setNewReply] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [commentsNumber, setCommentsNumber] = useState(0);

  const [pagination, setPagination] = useState({});

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  const { goToNextPage, goToPrevPage, totalNumber, pageNumber } = useTablePagination(changeParams, pagination);
  useEffect(() => {
    setCommentsNumber(totalNumber);
  }, [totalNumber]);

  const openCommentModal = useCallback(
    parentId => {
      if (!openModalOutside && !openModalInside) {
        setOpenModalInside(true);
        setParentComment(parentId);
      }
    },
    [openModalInside, openModalOutside],
  );

  const hideCommentModal = useCallback(() => {
    setOpenModalOutside(false);
    setOpenModalInside(false);
    setParentComment(null);
  }, [setOpenModalOutside]);

  useEffect(() => {
    openModalOutside || openModalInside ? openCommentModal() : hideCommentModal();
  }, [openModalInside, openModalOutside, openCommentModal, hideCommentModal]);

  const paramsDep = useAdditionalParamsDep(additionalRequestParams);
  // Hook for fetching comments
  useEffect(() => {
    const groupComments = comments => {
      const newCommentMap = new Map();
      const { length } = comments;

      for (let i = 0; i < length; i += 1) {
        const date = DateManager.monDayYearLocal(comments[i].timeCreated);
        const currentValue = newCommentMap.get(date);
        const value = currentValue || [];
        newCommentMap.set(date, [...value, comments[i]]);
      }

      setCommenMap(newCommentMap);
      // Dummy flag which induces rerender as the commentMap reference
      // does not change.
    };

    const fetchComments = async () => {
      try {
        setIsLoading(true);
        const { data: response } = await apiGetComments({ ...params, ...additionalRequestParams });
        const { data, paginationData } = response;
        groupComments(data);
        setPagination(paginationData);
      } finally {
        setIsLoading(false);
      }
    };

    fetchComments();
  }, [params, paramsDep, apiGetComments]);

  const renderCommentLists = () => {
    const list = [];

    for (const [key, value] of commentMap.entries()) {
      list.push(<CommentList date={key} comments={value} key={`${value}-${key}`} type={type} />);
    }

    return [...list];
  };

  const addCommentLocally = useCallback(
    comment => {
      const date = DateManager.monDayYearLocal(comment.timeCreated);
      const currentValue = commentMap.get(date);
      const value = currentValue || [];
      commentMap.set(date, [comment, ...value]);
      setPagination(prev => ({ ...prev, limit: prev.limit + 1 }));
      const newCommentMap = new Map(
        [...commentMap.entries()].sort((a, b) => {
          return DateManager.compareDates(b[0], a[0]) ? 1 : -1;
        }),
      );
      setCommenMap(newCommentMap);
    },
    [commentMap],
  );

  const updateCommentLocally = useCallback(editedComment => {
    setCommenMap(prevCommentMap => {
      const newCommentMap = new Map(prevCommentMap);

      const commentDateKey = DateManager.monDayYearLocal(editedComment.timeCreated);
      const commentsForDate = newCommentMap.get(commentDateKey);

      if (commentsForDate) {
        const updatedComments = commentsForDate.map(comment =>
          comment.commentId === editedComment.commentId
            ? {
                ...comment,
                body: editedComment.body,
                timeModified: editedComment.timeModified,
              }
            : comment,
        );

        newCommentMap.set(commentDateKey, updatedComments);
      }

      return newCommentMap;
    });
  }, []);

  const deleteCommentLocally = (key, deletionId) => {
    const filteredComments = commentMap.get(key).filter(({ commentId }) => commentId !== deletionId);

    if (!filteredComments.length) {
      commentMap.delete(key);
    } else {
      commentMap.set(key, filteredComments);
    }
    setPagination(prev => ({ ...prev, limit: prev.limit - 1 }));
  };

  const loadingClasses = classNames({
    'ickyc-comments': true,
  });
  /**
   * listen for new localy added Comments
   */
  useEffect(() => {
    const handleNewComment = data => {
      if (Array.isArray(data)) {
        data.forEach(comm => {
          addCommentLocally(comm);
          setCommentsNumber(prev => prev + 1);
        });
      } else {
        addCommentLocally(data);
        setCommentsNumber(prev => prev + 1);
      }
    };
    bus.addEventListener(enums.BUS_EVENTS.NEW_LOG_COMMENT, handleNewComment);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.NEW_LOG_COMMENT, handleNewComment);
    };
  }, [addCommentLocally]);
  const containerRef = useRef(null);

  const renderContent = () => {
    return (
      <div className={loadingClasses} ref={containerRef}>
        {isLoading && <Loader />}
        <CommentsContext.Provider
          value={{
            deleteCommentLocally,
            updateCommentLocally,
            openCommentModal,
            newReply,
            setNewReply,
            setCommentsNumber,
            apiGetComments,
            apiGetReplies,
            apiDeleteComment,
            apiEditComment,
            apiCreateComment,
            apiCreateReply,
          }}
        >
          {newCommentButton && isCollapsible && (
            <div className="button-wrapper">
              <OutlineButton
                left={IconManager.get(IconManager.names.MESSAGE)}
                onClick={() => setOpenModalOutside(true)}
              >
                + Add Comment
              </OutlineButton>
            </div>
          )}
          {renderCommentLists()}

          <TableNavigation
            goToNextPage={goToNextPage}
            goToPrevPage={goToPrevPage}
            pageNumber={pageNumber}
            totalNumber={totalNumber}
            pageSize={5}
          />

          {(openModalOutside || openModalInside) && (
            <NewCommentModal
              hideModal={hideCommentModal}
              containerRef={containerRef}
              isReply={openModalInside}
              addCommentLocally={addCommentLocally}
              parentCommentId={parentComment}
              setNewReply={setNewReply}
              additionalParams={additionalRequestParams}
              setCommentsNumber={setCommentsNumber}
            />
          )}
        </CommentsContext.Provider>
      </div>
    );
  };
  return (
    <>
      {isCollapsible ? (
        <Accordion
          title={`Comments (${commentsNumber || 0})`}
          className="ickyc-collapsible-comments"
          accordionOpen={false}
          accented
          accordionindex={enums.ACCORDION_INDEXES.COMMENTS}
        >
          {renderContent()}
        </Accordion>
      ) : (
        renderContent()
      )}
    </>
  );
};

CommentsSections.propTypes = {
  openModalOutside: PropTypes.bool,
  setOpenModalOutside: PropTypes.func,
  isCollapsible: PropTypes.bool,
  newCommentButton: PropTypes.bool,
  additionalRequestParams: PropTypes.shape({}).isRequired,
  apiGetComments: PropTypes.func.isRequired,
  apiGetReplies: PropTypes.func.isRequired,
  apiDeleteComment: PropTypes.func.isRequired,
  apiEditComment: PropTypes.func.isRequired,
  apiCreateComment: PropTypes.func.isRequired,
  apiCreateReply: PropTypes.func.isRequired,
  type: PropTypes.string,
};

CommentsSections.defaultProps = {
  openModalOutside: false,
  isCollapsible: true,
  newCommentButton: false,
  setOpenModalOutside: () => {},
  type: 'entity',
};

export default CommentsSections;
