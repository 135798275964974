import React from 'react';

export default ({ className }) => (
  <svg className={className} width="24px" height="24px" fill="currentColor" viewBox="0 0 89.7 90.7">
    <path
      fill="currentColor"
      d="M37.7218 81.1929H32.88L-.0713 48.2416v-4.8418l14.7945-14.7944h4.8418l15.7359 15.7359L70.135 9.507h4.8418l14.7945 14.7945v4.8418zm-2.5554-5.3798h.4035l48.956-48.9561v-.4035l-11.5665-11.701h-.4035L35.4354 51.873 17.5475 33.9852h-.4034L5.443 45.5517v.4035z"
    />
  </svg>
);
