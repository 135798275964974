import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import toastr from 'toastr';
import api from '../../../../../api';
import Toggle from '../../../../../components/Toggle';
import { BatchContext } from '../../../../../utilities/services/contexts';

const BatchOngoingStatusToggle = ({ toggleState, setUpdating, id, onBatchAction, disabled }) => {
  const { listOfSelectedIDs } = useContext(BatchContext) || [];

  const entityIds = useMemo(() => {
    if (!listOfSelectedIDs.length) {
      return [id];
    }
    if (!listOfSelectedIDs.some(eId => eId === id)) {
      return [...listOfSelectedIDs, id];
    }
    return listOfSelectedIDs;
  }, [listOfSelectedIDs, id]);

  const handleToggleChange = () => {
    setUpdating(entityIds);
    api.kyc.caseManagement
      .batchUpdateOngoingStatus({ ongoing: !toggleState, complianceLogsIds: entityIds })
      .then(({ data }) => {
        onBatchAction(data);
        toastr.success(`Ongoing monitoring was ${!toggleState ? 'enabled' : 'disabled'} `);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Error occurred during update');
      })
      .finally(() => {
        setUpdating([]);
      });
  };
  return <Toggle onOff onChange={handleToggleChange} value={toggleState} disabled={!toggleState || disabled} />;
};

BatchOngoingStatusToggle.propTypes = {
  toggleState: PropTypes.bool,
  setUpdating: PropTypes.func,
  id: PropTypes.string,
  onBatchAction: PropTypes.func,
};

BatchOngoingStatusToggle.defaultProps = {
  toggleState: false,
  setUpdating: () => {},
  id: null,
  onBatchAction: () => {},
};
export default BatchOngoingStatusToggle;
