import { useEffect } from 'react';
import StackingManager from '../utilities/services/StackingManager';

/**
 * App's key listener used mainly for interaction of closing modals, dropdowns, selects etc.
 * @param {reference} widgetRef - App's reference
 */

const useGlobalKeyListener = widgetRef => {
  useEffect(() => {
    if (widgetRef?.current) {
      const { current: widget } = widgetRef;

      const handleKeyPress = key => {
        if (key.code === 'Escape') {
          const hideModal = StackingManager.escPop();

          if (hideModal) {
            hideModal();
          }
        }
      };

      const addListener = ref => {
        ref.addEventListener('keydown', handleKeyPress);
      };

      const removeListener = ref => {
        ref.removeEventListener('keydown', handleKeyPress);
      };

      const setUp = () => {
        addListener(widget);
      };

      const cleanUp = () => {
        removeListener(widget);
      };

      setUp();

      return cleanUp;
    }
  }, [widgetRef]);
};

export default useGlobalKeyListener;
