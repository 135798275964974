// addCountry: false
// addYear: true
// annualReview: false
// appliedNationalities: (249) ["CA", "US", "AF", "AG", "AI", "AL", "AM", "AO", "AQ", "AR", "AS", "AT", "AU", "AW", "AX", "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ", "BR", "BS", "BT", "BV", "BW", "BY", "BZ", "AD", "CC", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CU", "CV", "CW", "CX", "CY", "CZ", "DE", "DJ", "DK", "DM", "DO", "DZ", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "FI", "FJ", "FK", "FM", "FO", "FR", "GA", "GB", "GD", "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR", "GS", "GT", "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT", "HU", …]
// appliedKYCStatuses: ["Escalated"]
// appliedRiskLevels: ["Medium Test"]
// assignmentType: 0
// assignmentValues: null
// autoAcceptance: false
// autoAcceptanceStatus: null
// entityType: 0
// fuzziness: 0.57
// id: 4
// isDefault: false
// name: "Default Screening Profile"
// ongoingMonitoring: false
// // sources: (14) [0, 1, 2, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15]

import { useCallback, useMemo } from 'react';
import useSelect from '../../../hooks/useSelect';
import { selectCountries, selectRiskLevelLabels } from '../../../store/selectors/global.selector';
import enums from '../../../utilities/enums';

export default () => {
  const countries = useSelect(selectCountries) || [];
  const riskItems = useSelect(selectRiskLevelLabels);

  const prefillForm = useCallback(
    values => {
      const additionalValues = {
        assignmentUserId: null,
        assignedBySource: [],
        assginedToNone: null,
      };
      const {
        assignmentType,
        assignmentValues,
        appliedNationalities,
        appliedJurisdictions,
        fuzziness,
        sources,
        appliedRiskLevels,
        ...rest
      } = values;
      rest.appliedNationalities = countries.reduce(
        (acc, country) => ({
          ...acc,
          [`country${country.id}`]: { enabled: (appliedNationalities || []).includes(country.alpha2Code) ? 1 : 0 },
        }),
        {},
      );
      rest.appliedJurisdictions = countries.reduce(
        (acc, country) => ({
          ...acc,
          [`country${country.id}`]: { enabled: (appliedJurisdictions || []).includes(country.alpha2Code) ? 1 : 0 },
        }),
        {},
      );

      rest.sources = sources || [];

      switch (assignmentType) {
        case 0: {
          additionalValues.assignmentUserId = assignmentValues;
          break;
        }
        case 1: {
          assignmentValues.forEach(element => {
            const { id, name } = element;
            rest.appliedNationalities[`country${id}`] = {
              ...rest.appliedNationalities[`country${id}`],
              assignedByJurisdiction: name,
            };
          });
          break;
        }
        case 2: {
          const jurisdictions = assignmentValues.map(({ id, name }) => ({
            id: id.toString(),
            value: name,
          }));
          additionalValues.assignedBySource = jurisdictions;
          break;
        }
        case 3: {
          additionalValues.assginedToNone = assignmentValues;
          break;
        }
        default:
          break;
      }
      return {
        ...rest,
        assignmentType,
        appliedRiskLevels: appliedRiskLevels.map(riskLevelIndex => `${riskLevelIndex}`),
        fuzziness: fuzziness * 100,
        ...additionalValues,
      };
    },
    [countries],
  );

  const defaultValues = useMemo(() => {
    return {
      name: undefined,
      addYear: false,
      addCountry: false,
      fuzziness: 50,
      entityType: 0,
      sources: [],
      // step 2
      appliedNationalities: countries.reduce(
        (acc, country) => ({
          ...acc,
          [`country${country.id}`]: { enabled: 1 },
        }),
        {},
      ),
      appliedJurisdictions: countries.reduce(
        (acc, country) => ({
          ...acc,
          [`country${country.id}`]: { enabled: 1 },
        }),
        {},
      ),
      appliedRiskLevels: riskItems.map((risk, ind) => `${ind + 1}`),
      appliedKycStatuses: Object.values(enums.ENTITY_STATUSES),
      ongoingMonitoring: false,
      annualReview: false,
      applyByJurisdictionEnabled: false,
      applyByRiskLevelsEnabled: false,
      applyByKycStatusesEnabled: false,
      // step 3
      assignmentType: 0,
      assignmentUserId: null,
      assignedByJurisdiction: [],
      assignedBySource: [],
      assginedToNone: null,
      autoAcceptance: false,
      autoAcceptanceStatus: null,
      isDefault: false,
    };
  }, [countries, riskItems]);

  const parseSubmitObject = useCallback(
    data => {
      const {
        assignmentType,
        assignmentUserId,
        assignedBySource,
        assginedToNone,
        appliedNationalities,
        appliedJurisdictions,
        assignedByJurisdiction: abj,
        ...rest
      } = data;
      let assignmentValues = null;

      const filteredappliedNationalities = Object.entries(appliedNationalities)
        .filter(entry => entry[1].enabled === 1)
        .map(entry => ({ id: entry[0].slice('country'.length, entry[0].length), ...entry[1] }));

      const filteredappliedJurisdictions = Object.entries(appliedJurisdictions)
        .filter(entry => entry[1].enabled === 1)
        .map(entry => ({ id: entry[0].slice('country'.length, entry[0].length), ...entry[1] }));

      switch (assignmentType) {
        case 0: {
          assignmentValues = assignmentUserId;
          break;
        }
        case 1: {
          assignmentValues = filteredappliedNationalities
            .filter(({ assignedByJurisdiction }) => assignedByJurisdiction)
            .map(({ id, assignedByJurisdiction }) => ({
              id,
              name: assignedByJurisdiction,
            }));
          break;
        }
        case 2: {
          assignmentValues = assignedBySource
            .filter(({ value }) => value)
            .map(({ id, value }) => ({
              id,
              name: value,
            }));
          break;
        }
        case 3: {
          assignmentValues = null;

          break;
        }
        default:
          break;
      }
      return {
        ...rest,
        assignmentType,
        assignmentValues,
        appliedNationalities: filteredappliedNationalities.map(({ id }) => {
          const country = countries.find(el => el.id.toString() === id.toString());
          return country.alpha2Code;
        }),
        appliedJurisdictions: filteredappliedJurisdictions.map(({ id }) => {
          const country = countries.find(el => el.id.toString() === id.toString());
          return country.alpha2Code;
        }),
      };
    },
    [countries],
  );

  return {
    defaultValues,
    prefillForm,
    parseSubmitObject,
  };
};
