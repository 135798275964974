import React, { useCallback, useEffect, useState } from 'react';
import api from '../../../../../api';
import Section from '../../../../../components/Section';
import Table from '../../../../../components/Tables/Table';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import TableHeaders from '../../../../../utilities/services/TableHeaders';
import CSVExportsRow from './components/CSVExportsRow';
import './styles.scss';

const DATA_TYPE = {
  export: 'Export',
  import: 'Import',
};

const CSVExport = () => {
  const [exportParams, setExportsParams] = useState({
    sortCriteria: 'dateTime',
    sortOrder: 1,
    limit: 5,
    skip: 0,
    logType: DATA_TYPE.export,
  });
  const [exports, setExports] = useState([]);
  const [exportsPagination, setExportPagination] = useState({});
  const [exportLoading, setExportsLoading] = useState();

  const changeExportsParams = useCallback(additionalParams => {
    setExportsParams(prev => ({ ...prev, ...additionalParams }));
  }, []);

  useEffect(() => {
    setExportsLoading(true);
    api.administration
      .getFilesManagement(exportParams)
      .then(({ data: response }) => {
        const { data, paginationData } = response;
        setExports(data);
        setExportPagination(paginationData);
      })
      .finally(() => setExportsLoading(false));
  }, [exportParams]);
  return (
    <Section title="Exports">
      <Table
        values={exports}
        pagination={exportsPagination}
        tableRow={CSVExportsRow}
        headerRow={TableHeader}
        className="ickyc-csv-exports-table"
        withPagination
        updating={exportLoading}
        handleParamsChange={changeExportsParams}
        headerData={{
          sortCriteria: exportParams.sortCriteria,
          sortOrder: exportParams.sortOrder,
          onClick: changeExportsParams,
          items: TableHeaders.CVSExportsTable,
        }}
      />
    </Section>
  );
};

export default CSVExport;
