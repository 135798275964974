import React from 'react';

export default () => (
  <svg viewBox="0 0 17 13">
    <g fill="none" fillRule="evenodd">
      <path
        d="m3.025 4.437v2.437c0 .198-.032.39-.132.564-.15266673.2695905-.42594151.44904342-.734.482-.438.062-.868-.004-1.285-.135-.258-.082-.507-.194-.761-.29-.048-.019-.047-.052-.047-.091v-4.38-.81c.0076866-.96904237.67746591-1.80698958 1.621-2.028.174-.038.355-.057.532-.058.992-.004 1.984 0 2.975 0h.086v.086c0 3.51.004 7.02-.003 10.53-.00653797.9945485-.71366098 1.8464435-1.69 2.036-.151.03-.308.042-.462.043-.99.004-1.979.003-2.968.004h-.084v-4.451c.194.039.386.087.581.114.359.048.719.098 1.08.12.328.02.66.019.988.002.476-.024.943-.109 1.368-.345.466-.259.723-.658.753-1.19.02-.333.014-.668.015-1.002.002-.516 0-1.032.002-1.548 0-.072-.018-.094-.092-.093-.552.003-1.104.002-1.656.003z"
        fill="#255797"
      />
      <path
        d="m5.924 12.824v-4.868c.015.006.026.009.034.015.385.309.835.466 1.311.556.62.118 1.244.125 1.87.075.45152604-.04087315.8996269-.11338462 1.341-.217.053-.011.075-.029.075-.087-.004-.246-.002-.493-.002-.739v-.084c-.03.012-.05.02-.07.03-.421.205-.86.352-1.327.41-.41.051-.816.052-1.206-.107-.479-.196-.741-.561-.812-1.067-.04273474-.27273275-.0201146-.5517145.066-.814.159-.467.497-.733.962-.855.33-.085.662-.077.996-.035.47672278.06348957.9388697.20896774 1.366.43.004.002.009 0 .02 0 .002-.019.005-.039.005-.059 0-.253-.001-.507.001-.76 0-.045-.011-.068-.058-.08-.776-.18-1.56-.277-2.358-.24-.504.022-1.003.087-1.48.264-.248.092-.48.213-.687.38l-.05.032v-.092-2.674c-.00109137-.56254655.22292533-1.10214791.62211296-1.49851775.39918764-.39636984.94036233-.61655852 1.50288704-.61148225 1.006-.006 2.012-.003 3.018-.003.021 0 .042.002.07.004.002.03.004.058.004.086 0 3.518.006 7.035-.005 10.553-.002.797-.399 1.394-1.084 1.794-.31265104.1790481-.66775332.2705871-1.028.265h-3.025z"
        fill="#ca4638"
      />
      <g fill="#479950">
        <path d="m11.786 12.826v-4.312h.093c1.082 0 2.165.004 3.247 0 .329 0 .639-.083.905-.288.263767-.19180159.4097058-.50627729.3858914-.83153679s-.2140009-.61512528-.5028914-.76646321c-.2194546-.11774634-.4615136-.18729568-.71-.204.119-.036.232-.061.337-.105.317-.131.534-.354.606-.7.0866226-.39067751-.0951084-.79162671-.446-.984-.268-.152-.561-.198-.862-.198-.985-.002-1.97 0-2.954 0h-.103v-.087-2.123c.0038601-1.16288345.9491134-2.10276599 2.112-2.1 1.008 0 2.016.003 3.024.002.08 0 .075.04.075.094v10.43c-.004.778-.323 1.393-.968 1.83-.281.19-.596.301-.936.326-.1881049.0114926-.3765502.0164956-.565.015l-2.66.002z" />
        <path
          d="m12.817 7.84v-1.1c.024-.001.047-.004.07-.004.384 0 .768-.001 1.152.001.274.002.483.155.549.395.1.365-.136.7-.52.713-.41.013-.82.002-1.231.002-.004 0-.008-.003-.02-.008zm.002-1.742v-1.02c.023-.001.043-.004.064-.004.344 0 .688-.003 1.031.003.0903111.00103606.1795872.01936652.263.054.218.095.322.317.28.563-.040678.22867258-.2377653.3964065-.47.4-.386.013-.773.004-1.168.004z"
          fillRule="nonzero"
        />
      </g>
    </g>
  </svg>
);
