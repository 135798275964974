import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import api from '../../../../../api';
import FormModal from '../../../../../components/Modal/FormModal';
import BusinessAccountFormFields from '../BusinessAccountFormFields';

const BusinessManagerModal = ({ hideModal, setNewlyAdded }) => {
  const handleSubmit = values => {
    const { domain, ...restOfValues } = values;
    return api.businessManager
      .createClientAccount({ domain: `${domain}.icomplykyc.com`, ...restOfValues })
      .then(res => {
        setNewlyAdded(res.data);
        hideModal();
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
          }
          if (resStatus === 500) {
            return {
              [FORM_ERROR]: Array.isArray(data.message)
                ? data.message.join('')
                : data.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  return (
    <FormModal
      onSubmit={handleSubmit}
      hideModal={hideModal}
      submitButtonText="Create"
      title={
        <div>
          <h2>Add Business</h2>
        </div>
      }
      className="ickyc-business-manager-modal"
    >
      <BusinessAccountFormFields editMode />
    </FormModal>
  );
};
BusinessManagerModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ id: PropTypes.number, companyName: PropTypes.string }),
  setNewlyAdded: PropTypes.func.isRequired,
};
BusinessManagerModal.defaultProps = {
  initialValues: {},
};
export default BusinessManagerModal;
