import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import './styles.scss';

const ProgressBar = ({ className, creditsLimit, creditsUsed }) => {
  const progressPercentage = useMemo(() => `${(100 / creditsLimit) * creditsUsed}%`, [creditsLimit, creditsUsed]);

  const classes = classNames('ickyc-progress-bar', { [className]: className });
  const fillClasses = classNames('ickyc-progress-bar__fill', {
    'ickyc-progress-bar__fill--full': progressPercentage === '100%',
  });

  return (
    <div className={classes}>
      <div className={fillClasses} style={{ width: progressPercentage }} />
    </div>
  );
};
ProgressBar.propTypes = {
  className: PropTypes.string,
  creditsUsed: PropTypes.string,
  creditsLimit: PropTypes.string,
};

ProgressBar.defaultProps = {
  className: undefined,
  creditsUsed: 0,
  creditsLimit: 0,
};
export default ProgressBar;
