import PrimaryButton from 'components/Buttons/PrimaryButton';
import Spinner from 'components/Spinner';
import React, { useMemo } from 'react';
import './styles.scss';

const LoadMore = ({
  total,
  limit,
  currentPage,
  onLoadMore: handleShowMore,
  realTimeAddition = 0,
  loading,
  disabled,
}) => {
  const viewed = useMemo(() => (currentPage * limit > total ? total : currentPage * limit + realTimeAddition), [
    currentPage,
    limit,
    total,
    realTimeAddition,
  ]);

  return (
    !!total && (
      <div className="kyc-load-more">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <span>{`You've viewed ${viewed} of ${total} results`}</span>
            {viewed < total && (
              <PrimaryButton onClick={handleShowMore} disabled={loading || disabled}>
                SHOW MORE
              </PrimaryButton>
            )}
          </>
        )}
      </div>
    )
  );
};
export default LoadMore;
