import api from 'api';
import FilterFormsService from '../../../../utilities/services/FilterFormsService';
import axiosInstance from '../../../axiosInstance';

const BASE_ROUTE = `/entities/legal-entities`;

const ENDPOINTS = {
  createNew: () => `${BASE_ROUTE}`,
  getInformations: id => `${BASE_ROUTE}/${id}`,
  updateInformation: id => `${BASE_ROUTE}/${id}`,
  updateCorporateEvent: (entityId, eventId) =>
    `${BASE_ROUTE}/${entityId}/corporate-information/${eventId}/third-party-event`,
  updateAddressEvent: (entityId, eventId) => `${BASE_ROUTE}/${entityId}/addresses/${eventId}/third-party-event`,
  updateSupportingDocument: (entityId, eventId) =>
    `${BASE_ROUTE}/${entityId}/supporting-documents/${eventId}/third-party-event`,

  getAffiliatedEntitiesTable: id => `${BASE_ROUTE}/${id}/affiliated-entities`,
  getAffiliatedEntitiesSuggestios: entityId => `${BASE_ROUTE}/${entityId}/affiliated-entities/suggestions`,
  getAllAffiliatedEntitiesSuggestios: () => `${BASE_ROUTE}/affiliated-entities/suggestions`,

  getLegalEntityPrimaryContacts: () => `${BASE_ROUTE}/affiliated-entities/primary-contacts`,
  addNewNPasAffiliatedEntity: entityId => `${BASE_ROUTE}/${entityId}/affiliated-entities/natural-person`,
  addNewLEasAffiliatedEntity: entityId => `${BASE_ROUTE}/${entityId}/affiliated-entities/legal-entity`,
  addExistingEntityAsAffiliatedEntity: entityId => `${BASE_ROUTE}/${entityId}/affiliated-entities/existing-entity`,
  deleteAffiliatedEntity: (entityId, affiliatedEntityId) =>
    `${BASE_ROUTE}/${entityId}/affiliated-entities/${affiliatedEntityId}`,
  deleteAffiliatedEntityRole: (entityId, affiliatedEntityId) =>
    `${BASE_ROUTE}/${entityId}/affiliated-entities/${affiliatedEntityId}/roles`,
  updateAffiliatedEntityRoles: (entityId, affiliatedEntityId) =>
    `${BASE_ROUTE}/${entityId}/affiliated-entities/${affiliatedEntityId}/roles`,
  createAffiliatedEntity: () => `${BASE_ROUTE}/add-important-personnel`,
  getAffiliatedEntitiesTableCSV: () => `/entitites/important-personnel-csv`,
  getAffiliatedEntityRoles: entityId => `${BASE_ROUTE}/${entityId}/affiliated-entities/titles`,
  getAddressesTableRecord: (id, eventId) => `${BASE_ROUTE}/${id}/addresses/${eventId}`,
  getAddressesTable: id => `${BASE_ROUTE}/${id}/addresses`,
  createAddressTableRecord: id => `${BASE_ROUTE}/${id}/addresses`,

  getInformationTable: id => `${BASE_ROUTE}/${id}/corporate-information`,
  getInformationTableRecord: (id, eventId) => `${BASE_ROUTE}/${id}/corporate-information/${eventId}`,

  importLE_CSV: () => `${BASE_ROUTE}/import-csv`,
  searchOrganizations: () => `${BASE_ROUTE}/organizations/search`,
  updateAffiliatedEntityOwnership: (affiliatedEntityId, entityId) =>
    `/entities/legal-entities/${entityId}/affiliated-entities/${affiliatedEntityId}/ownership`,

  checkDuplicates: namesQuery => `${BASE_ROUTE}/check-duplicates?${namesQuery}`,
};

const searchOrganizations = params => axiosInstance.get(ENDPOINTS.searchOrganizations(), { params });

const getInformationTable = data => {
  const { entityId, ...params } = data;
  return axiosInstance.get(ENDPOINTS.getInformationTable(entityId), { params });
};
const getInformationTableRecord = (id, eventId) => {
  return axiosInstance.get(ENDPOINTS.getInformationTableRecord(id, eventId));
};

const getAddressesTable = data => {
  const { entityId, ...params } = data;
  return axiosInstance.get(ENDPOINTS.getAddressesTable(entityId), { params });
};
const getAddressesTableRecord = (id, eventId) => {
  return axiosInstance.get(ENDPOINTS.getAddressesTableRecord(id, eventId));
};

const getInformation = (id, params) => axiosInstance.get(ENDPOINTS.getInformations(id), { params });

const updateInformation = (id, data) => {
  const formdata = new FormData();

  const nestedStingifyAnswers = data?.answers?.map(({ answer, ...rest }) => {
    return { ...rest, answer: JSON.stringify(answer) };
  });

  formdata.append(`corporateInformation`, JSON.stringify(data?.corporateInformation));
  formdata.append(`documentEvent`, JSON.stringify(data?.documentEvent) || null);
  formdata.append(`answers`, JSON.stringify(nestedStingifyAnswers) || null);
  formdata.append('status', data?.status);

  if (data?.items) {
    Object.entries(data.items).forEach(([itemId, files]) => {
      files &&
        files.map(({ file }) => {
          formdata.append('itemIds', itemId);
          formdata.append('files', file);
        });
    });
  }

  return axiosInstance.put(ENDPOINTS.updateInformation(id), formdata);
};

const updateCorporateEvent = (entityId, eventId, data) => {
  return axiosInstance.put(ENDPOINTS.updateCorporateEvent(entityId, eventId), data);
};

const updateAddressEvent = (entityId, eventId, data) => {
  return axiosInstance.put(ENDPOINTS.updateAddressEvent(entityId, eventId), data);
};

const updateSupportingDocument = (entityId, eventId, data) => {
  return axiosInstance.put(ENDPOINTS.updateSupportingDocument(entityId, eventId), data);
};

const createAddressTableRecord = (entityId, data) => {
  const formdata = new FormData();

  const nestedStingifyAnswers = data?.answers?.map(({ answer, ...rest }) => {
    return { ...rest, answer: JSON.stringify(answer) };
  });

  formdata.append(`addressInformation`, JSON.stringify(data?.addressInformation));
  formdata.append(`documentEvent`, JSON.stringify(data?.documentEvent) || null);
  formdata.append(`answers`, JSON.stringify(nestedStingifyAnswers) || null);
  formdata.append(`status`, data?.status);

  if (data.items) {
    Object.entries(data.items).forEach(([itemId, files]) => {
      files &&
        files.map(({ file }) => {
          formdata.append('itemIds', itemId);
          formdata.append('files', file);
        });
    });
  }

  return axiosInstance.post(ENDPOINTS.createAddressTableRecord(entityId), formdata);
};

const createAffiliatedEntity = data => {
  return axiosInstance.post(ENDPOINTS.createAffiliatedEntity(), data);
};
const deleteAffiliatedEntity = (entityId, affiliatedEntityId) => {
  return axiosInstance.delete(ENDPOINTS.deleteAffiliatedEntity(entityId, affiliatedEntityId));
};
const deleteAffiliatedEntityRole = (entityId, affiliatedEntityId, data) => {
  return axiosInstance.delete(ENDPOINTS.deleteAffiliatedEntityRole(entityId, affiliatedEntityId), { data });
};
const updateAffiliatedEntityRoles = (entityId, affiliatedEntityId, data) => {
  return axiosInstance.put(ENDPOINTS.deleteAffiliatedEntityRole(entityId, affiliatedEntityId), { ...data });
};
const getAffiliatedEntitiesSuggestios = (entityId, params) => {
  return axiosInstance.get(ENDPOINTS.getAffiliatedEntitiesSuggestios(entityId), {
    params,
  });
};
const getAffiliatedEntitiesTableCSV = id => {
  return axiosInstance.get(ENDPOINTS.getAffiliatedEntitiesTableCSV(id), {
    params: {
      entityId: id,
    },
  });
};
const getAffiliatedEntitiesTable = data => {
  const { filters, filterOn, entityId, ...params } = data;
  const filterData = FilterFormsService.normalizeEntityManagementFormData(filters, filterOn);
  return axiosInstance.post(ENDPOINTS.getAffiliatedEntitiesTable(entityId), filterData, { params });
};
const getAffiliatedEntityRoles = (entityId, params) => {
  return axiosInstance.get(ENDPOINTS.getAffiliatedEntityRoles(entityId), { params });
};
const addExistingEntityAsAffiliatedEntity = (entityid, data) => {
  return axiosInstance.post(ENDPOINTS.addExistingEntityAsAffiliatedEntity(entityid), data);
};
const addNewLEasAffiliatedEntity = (entityid, data) => {
  return axiosInstance.post(ENDPOINTS.addNewLEasAffiliatedEntity(entityid), data);
};
const addNewNPasAffiliatedEntity = (entityid, data) => {
  return axiosInstance.post(ENDPOINTS.addNewNPasAffiliatedEntity(entityid), data);
};
const createNew = data => axiosInstance.post(ENDPOINTS.createNew(), data);

const getLegalEntityPrimaryContacts = params =>
  axiosInstance.get(ENDPOINTS.getLegalEntityPrimaryContacts(), { params });

const importCSV = file => {
  return axiosInstance.post(ENDPOINTS.importLE_CSV(), file, {
    headers: {
      'Content-Type': 'text/csv',
    },
  });
};
const getALLAffiliatedEntitiesSuggestios = params => {
  return axiosInstance.get(ENDPOINTS.getAllAffiliatedEntitiesSuggestios(), {
    params,
  });
};

const updateAffiliatedEntityOwnership = (affiliatedEntityId, entityId, data) =>
  axiosInstance.patch(ENDPOINTS.updateAffiliatedEntityOwnership(affiliatedEntityId, entityId), data);

const checkDuplicates = namesList =>
  axiosInstance.get(ENDPOINTS.checkDuplicates(api.utilities.transformArrayToQueryString(namesList)));

export default {
  getInformation,
  getALLAffiliatedEntitiesSuggestios,
  updateInformation,
  updateCorporateEvent,
  updateAddressEvent,
  updateSupportingDocument,
  getAddressesTable,
  getAddressesTableRecord,
  getInformationTable,
  getInformationTableRecord,
  createAddressTableRecord,
  getAffiliatedEntityRoles,
  createAffiliatedEntity,
  getAffiliatedEntitiesSuggestios,
  getAffiliatedEntitiesTableCSV,
  addExistingEntityAsAffiliatedEntity,
  getAffiliatedEntitiesTable,
  addNewLEasAffiliatedEntity,
  addNewNPasAffiliatedEntity,
  deleteAffiliatedEntity,
  createNew,
  getLegalEntityPrimaryContacts,
  deleteAffiliatedEntityRole,
  updateAffiliatedEntityRoles,
  importCSV,
  searchOrganizations,
  updateAffiliatedEntityOwnership,
  checkDuplicates,
};
