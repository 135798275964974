import classNames from 'classnames';
import BareHeading from 'components/Headings/BareHeading';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import api from '../../../api';
import DebounceInput from '../../../components/DebounceInput';
import Table from '../../../components/Tables/Table';
import TableHeader from '../../../components/Tables/Table/components/TableHeader';
import useGroupPermissions from '../../../hooks/useGroupPermissions';
import useModalHandler from '../../../hooks/useModalHandler';
import useTable from '../../../hooks/useTable';
import authEnums from '../../../utilities/enums/authEnums';
import TableHeaders from '../../../utilities/services/TableHeaders';
import FilterToggleButton from '../../Kyc/components/FilterToggleButton';
import SearchControls from '../../Kyc/components/SearchControls';
import CardsHeading from './componenets/CardsHeading';
// eslint-disable-next-line max-len
import LicenceManagementService from '../BusinessManager/components/BusinessProfile/components/BusinessLicenseSection/services/LicenceManagement.service';
import AddLicenceModal from './componenets/AddLicenceModal';
import LicencesFilterModal from './componenets/LicencesFilterModal';
import LicenceTableRow from './componenets/LicenceTableRow';
import './styles.scss';

const LicenceManager = ({ className }) => {
  const classes = classNames('ickyc-page', 'ickyc-licence-management', className);
  const [initialLicenceInfo, setInitialLicenceInfo] = useState({});
  const [fetchingLicence, setFetchingLicence] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [filters, setFilters] = useState();
  const groupPermissions = useGroupPermissions(authEnums.PERMISSION_GROUP.LICENCE_MANAGER);

  const { isOpen: isAddLicenceModalOpen, close: closeAddLicenceModal, open: openEditModal } = useModalHandler();

  const { isLoading, records, changeParams, pagination, params, updateRowData, addRow, removeRow } = useTable(
    api.businessManager.licences.getAllLicences,
    { sortCriteria: 'lastModified', limit: 10, filters, filterOn },
  );

  const deleteLicence = id => {
    removeRow('id', id);
  };

  const setNewlyAdded = licence => {
    addRow(licence);
  };

  const handleUpdateLicence = (id, data) => {
    updateRowData('id', id, data);
  };

  const handleFilterToggle = useCallback(() => {
    setFilterOn(prev => !prev);
  }, []);

  const handleFilterSave = useCallback(values => {
    setFilters(values);
    setFilterOn(true);
  }, []);

  const applySearchTerm = useCallback(
    searchString => {
      changeParams({ searchString });
    },
    [changeParams],
  );

  const applyLicenceChanges = changes => {
    updateRowData('id', changes?.id, changes);
  };

  const fetchLicenceInfo = id => {
    if (!groupPermissions.edit) return;
    setFetchingLicence(true);
    api.businessManager.licences
      .getLicence(id)
      .then(res => {
        if (res?.data?.type === 0) {
          setInitialLicenceInfo(LicenceManagementService.prepareValuesForModal(res?.data));
        } else if (res?.data.type === 1) {
          setInitialLicenceInfo(LicenceManagementService.prepareWebsiteLicence(res?.data));
        }
        openEditModal();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errData.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => {
        setFetchingLicence(false);
      });
  };

  const handleAddNewLicence = () => {
    setInitialLicenceInfo({
      platformAccess: {
        entities: true,
        cases: true,
      },
      administrationAccess: {
        complianceSettings: true,
        filesManagement: true,
        portalManagement: true,
        userManagement: true,
        webHooks: true,
        api: true,
        apiManager: true,
        automations: true,
        notifications: true,
        supportingDocuments: true,
        riskLevels: true,
        riskScoring: true,
        riskScreening: true,
        quickSetup: true,
      },
    });
    openEditModal();
  };

  const tagClickHandler = useCallback(
    tag => {
      handleFilterSave({ tags: [tag], tagsFlag: true });
    },
    [handleFilterSave],
  );

  return (
    <div className={classes}>
      {groupPermissions.create ? (
        <CardsHeading onButtonClick={handleAddNewLicence} />
      ) : (
        <BareHeading title="Licenses" />
      )}

      <SearchControls>
        <div className="ickyc-search-controls__info">
          <span>{pagination.total > 1 ? `${pagination.total || 0}  Results` : `${pagination.total || 0} Result`}</span>
        </div>

        <div className="ickyc-search-controls__filter-section">
          <DebounceInput placeholder="Search Licenses" initialValue={params.searchString} onChange={applySearchTerm} />
          <FilterToggleButton filters={filters} filterOn={filterOn} onToggleFilter={handleFilterToggle}>
            <LicencesFilterModal onFilterSave={handleFilterSave} />
          </FilterToggleButton>
        </div>
      </SearchControls>
      <Table
        values={records}
        onClick={fetchLicenceInfo}
        pagination={pagination}
        tableRow={LicenceTableRow}
        headerRow={TableHeader}
        handleParamsChange={changeParams}
        className="ickyc-licence-management-table"
        withPagination
        withLimitChange
        updating={isLoading || fetchingLicence}
        onRemove={deleteLicence}
        onControlsClick={tagClickHandler}
        headerData={{
          withDelete: groupPermissions.delete,
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          onClick: changeParams,
          items: TableHeaders.LicenceManagmentTableHeader,
        }}
      />
      {isAddLicenceModalOpen && (
        <AddLicenceModal
          hideModal={closeAddLicenceModal}
          handleUpdateInfo={applyLicenceChanges}
          disableAttachTo
          initialLicenceValues={initialLicenceInfo}
          setNewlyAdded={setNewlyAdded}
          updateLicence={handleUpdateLicence}
        />
      )}
    </div>
  );
};
LicenceManager.propTypes = {
  className: PropTypes.string,
};

LicenceManager.defaultProps = {
  className: undefined,
};
export default LicenceManager;
