import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import toastr from 'toastr';
import api from '../../../../../api';
import Select from '../../../../../components/Select';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import { BatchContext } from '../../../../../utilities/services/contexts';
import './styles.scss';

const BatchRiskDropdown = ({
  assignedUserRisk,
  assignEvent,
  eventId,
  onBatchUpdate,
  assignCase,
  complianceLogId,
  assignUser,
  disabled,
  renderAsPortal,
  label,
  preview,
  hint,
  listingId,
  assignlisting,
  hitId,
  logId,
  useFullValue,
  className,
  entityId,
  setUpdating,
  ...rest
}) => {
  const { listOfSelectedIDs } = useContext(BatchContext) || [];
  const { riskLevelScale } = useSelector(state => state.global.riskLevels || []);
  const riskLevelMapping = {
    OneToThree: 3,
    OneToFour: 4,
    OneToFive: 5,
  };
  const labels = Array.from({ length: riskLevelMapping[riskLevelScale] }, (_, i) => ({
    id: i + 1,
    value: i + 1,
    label: `${i + 1}`,
  }));

  const entityIds = useMemo(() => {
    if (!listOfSelectedIDs.length) {
      return [entityId];
    }
    if (!listOfSelectedIDs.some(eId => eId === entityId)) {
      return [...listOfSelectedIDs, entityId];
    }
    return listOfSelectedIDs;
  }, [listOfSelectedIDs, entityId]);

  const batchAssignUsers = useCallback(
    newRisk => {
      setUpdating(entityIds);
      const submitObj = {
        entityIds,
        riskLevel: newRisk,
      };
      api.kyc
        .batchAssignRisk(submitObj)
        .then(response => {
          onBatchUpdate(response?.data);
          toastr.success('Entities successfully updated!');
        })
        .catch(err => {
          console.log(err);
          toastr.error('Error occurred during update');
        })
        .finally(() => {
          setUpdating([]);
        });
    },
    [entityIds, onBatchUpdate, setUpdating],
  );

  const handleRiskAssign = newRisk => {
    if (assignedUserRisk === newRisk) return;
    batchAssignUsers(newRisk);
  };
  return (
    <Select
      value={assignedUserRisk}
      onChange={handleRiskAssign}
      options={labels}
      useFullValue={false}
      renderAsPortal={false}
      disabled={disabled}
      label={label}
      preview={preview}
      hint={hint}
      Trigger={<SelectTrigger placeholder="N/A" withoutArrow />}
      Content={<SelectFilterContent />}
      className={className}
      {...rest}
    />
  );
};
BatchRiskDropdown.propTypes = {
  assignedUserRisk: PropTypes.string,
  assignEvent: PropTypes.bool,
  eventId: PropTypes.string,
  onBatchUpdate: PropTypes.func,
  assignCase: PropTypes.bool,
  assignUser: PropTypes.bool,
  complianceLogId: PropTypes.string,
  disabled: PropTypes.bool,
  renderAsPortal: PropTypes.bool,
  label: PropTypes.string,
  preview: PropTypes.bool,
  hint: PropTypes.string,
  listingId: PropTypes.string,
  assignlisting: PropTypes.bool,
  hitId: PropTypes.string,
  logId: PropTypes.string,
  input: PropTypes.shape(PropTypes.object),
  useFullValue: PropTypes.bool,
  className: PropTypes.string,
  entityId: PropTypes.string.isRequired,
  setUpdating: PropTypes.func,
};
BatchRiskDropdown.defaultProps = {
  assignedUserRisk: undefined,
  assignCase: false,
  assignEvent: false,
  eventId: undefined,
  complianceLogId: undefined,
  assignUser: undefined,
  onBatchUpdate: () => {},
  renderAsPortal: false,
  disabled: false,
  label: undefined,
  preview: false,
  hint: undefined,
  listingId: undefined,
  assignlisting: false,
  hitId: undefined,
  logId: undefined,
  input: {},
  useFullValue: true,
  className: undefined,
  setUpdating: () => {},
};

export default BatchRiskDropdown;
