import PrimaryButton from 'components/Buttons/PrimaryButton';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useSelector } from 'react-redux';
import IconManager from 'utilities/services/IconManager';
import api from '../../../../../../api';
import DatePickerField from '../../../../../../components/DatepickerField';
import SelectCustomContent from '../../../../../../components/Select/components/SelectCustomContent';
import SelectFilterContent from '../../../../../../components/Select/components/SelectFilterContent';
import SelectInputTrigger from '../../../../../../components/Select/components/SelectInputTrigger';
import SelectTrigger from '../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../components/SelectField';
import Spinner from '../../../../../../components/Spinner';
import ToggleField from '../../../../../../components/ToggleField';
import useSelect from '../../../../../../hooks/useSelect';
import { selectUsersToAssign } from '../../../../../../store/selectors/global.selector';
import enums from '../../../../../../utilities/enums';
import './styles.scss';

const GeneralInfoEntitiesForm = ({ batchSelectedLegalEntity, onStatusChange, handleRequestEntityReport }) => {
  const { riskLevelScale } = useSelector(state => state.global.riskLevels || []);
  const [isLoading, setIsLoading] = useState(true);
  const riskLevelMapping = {
    OneToThree: 3,
    OneToFour: 4,
    OneToFive: 5,
  };

  const riskLevelOptions = Array.from({ length: riskLevelMapping[riskLevelScale] }, (_, i) => ({
    id: i + 1,
    value: i + 1,
    label: `${i + 1}`,
  }));

  const assignedToItems = useSelect(selectUsersToAssign).map(user => ({
    id: user.id,
    label: user.name,
    value: user.id,
  }));

  const mappedStatuses = useMemo(() => {
    return Object.values(enums.ENTITY_STATUSES).map(status => ({
      id: status,
      label: status,
      value: status,
    }));
  }, []);

  const [tagsOptions, setTagsOptions] = useState([]);
  useEffect(() => {
    const fetchTags = () => {
      setIsLoading(true);
      api.kyc.entityManagement
        .getAllEntityTags()
        .then(res => {
          const { data } = res;
          setTagsOptions(data.map(el => ({ id: el, value: el, label: el })));
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchTags();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="ickyc-batch-general-entity-info-form">
          <span> Set Status To</span>
          <FormSpy subscription={{ values: { entityStatus: true } }}>
            {({ values }) => {
              const { entityStatus } = values;
              onStatusChange(entityStatus === 'Approved');
              return <></>;
            }}
          </FormSpy>
          <Field
            component={SelectField}
            name="entityStatus"
            options={mappedStatuses}
            Content={<SelectFilterContent />}
            Trigger={<SelectTrigger placeholder="- Choose Status -" />}
          />
          <span> Set Risk To</span>
          <Field
            component={SelectField}
            name="riskLevel"
            options={riskLevelOptions}
            Content={<SelectFilterContent />}
            Trigger={<SelectTrigger placeholder="- Choose Risk Level -" />}
          />
          <span> Assign To</span>
          <Field
            component={SelectField}
            name="assignedUserId"
            options={assignedToItems}
            renderAsPortal
            Content={<SelectFilterContent />}
            Trigger={<SelectTrigger placeholder="- Choose -" />}
          />
          <span> Set Next Review Date</span>
          <Field
            component={DatePickerField}
            minDate={new Date()}
            name="nextReviewDate"
            options={assignedToItems}
            renderAsPortal
            Content={<SelectFilterContent />}
            Trigger={<SelectTrigger placeholder="- Choose -" />}
          />
          <span> Create AML Case for Entities</span>
          <Field component={ToggleField} name="createAmlCaseForEntities" />
          {batchSelectedLegalEntity && (
            <>
              <span> Create AML Case for Affiliated Entities</span>
              <Field component={ToggleField} name="createAmlCaseForAffiliatedEntities" />
            </>
          )}
          <span> Add Tag</span>
          <Field
            component={SelectField}
            name="tags"
            Trigger={<SelectInputTrigger placeholder="Tags" />}
            Content={<SelectCustomContent />}
            options={tagsOptions}
            multipleSelection
            withCustomOptions
            renderAsPortal
          />
          <PrimaryButton variant="link" onClick={handleRequestEntityReport}>
            {IconManager.get(IconManager.names.DOWNLOAD)} Request Entity Reports
          </PrimaryButton>
        </div>
      )}
    </>
  );
};
GeneralInfoEntitiesForm.propTypes = {
  batchSelectedLegalEntity: PropTypes.bool.isRequired,
  onStatusChange: PropTypes.bool.isRequired,
};
export default GeneralInfoEntitiesForm;
