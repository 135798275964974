import FilterFormsService from '../../../utilities/services/FilterFormsService';
import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/case-management';

const ENDPOINTS = {
  assignCaseToUser: complianceLogId => `${BASE_ROUTE}/${complianceLogId}/assigned-user`,
  getAdverseMediaHitsTAble: (complianceLogId, hitId) => `${BASE_ROUTE}/${complianceLogId}/hits/${hitId}/adverse-media`,

  getCaseAttachmentSubjectsEndpoint: () => `${BASE_ROUTE}/case-attachments`,
  removeEntityFromCase: (complianceLogId, entityId) => `${BASE_ROUTE}/${complianceLogId}/case-attachments/${entityId}`,
  attachEntityToCase: complianceLogId => `${BASE_ROUTE}/${complianceLogId}/case-attachments`,
  removeCase: complianceLogId => `${BASE_ROUTE}/${complianceLogId}`,
  getComplianceHitDetails: (complianceLogId, hitId) => `${BASE_ROUTE}/${complianceLogId}/hits/${hitId}`,
  getCaseManagementTable: () => `${BASE_ROUTE}`,
  getCaseManagementMatchesTable: complianceLogId => `${BASE_ROUTE}/${complianceLogId}/matches-table`,
  updateComplianceHitStatus: complianceLogId => `${BASE_ROUTE}/${complianceLogId}/hits`,
  getCaseDetails: complianceLogId => `${BASE_ROUTE}/${complianceLogId}`,
  changeCaseOngoingStatus: complianceLogId => `${BASE_ROUTE}/${complianceLogId}/ongoing-status`,
  defaultRiskScreeningProfile: () => `${BASE_ROUTE}/screening-profiles/default`,

  hitListings: (complianceLogId, hitId) => `case-management/${complianceLogId}/hits/${hitId}/listings`,
  hitDocuments: (complianceLogId, hitId) => `case-management/${complianceLogId}/hits/${hitId}/documents`,

  updateCaseStatus: complianceLogId => `case-management/${complianceLogId}`,
  batchDeleteCases: () => `/case-management/batch`,
  batchUpdateOngoingStatus: () => `/case-management/batch/ongoing-status`,
  batchUpdateCaseStatus: () => `/case-management/batch/case-status`,
  batchAttachEntities: () => `/case-management/batch/attach-entities`,
  batchUpdateCasesFromModal: () => `/case-management/batch`,
  createCaseReportConfig: caseId => `cases/${caseId}/personal-report/config`,
  createBatchCaseReport: () => 'cases/batch/personal-report/config',
  downloadCaseReport: id => `/cases/reports/${id}`,
};

const getDefaultScreeningProfile = () => axiosInstance.get(ENDPOINTS.defaultRiskScreeningProfile());

const assignCaseToUser = (complianceLogId, userId) => {
  return axiosInstance.put(ENDPOINTS.assignCaseToUser(complianceLogId), {
    userId,
  });
};
const getCaseDetailsApi = complianceLogId => {
  return axiosInstance.get(ENDPOINTS.getCaseDetails(complianceLogId));
};

const getCaseManagementMatchesTable = data => {
  const { complianceLogId, status, selectedAMLFilters, filters, filterOn, ...rest } = data;
  const { applyFilters, ...tableFilters } = FilterFormsService.normalizeMatchesFormData(filters, filterOn);

  const bodyData = {
    status,
    filters: selectedAMLFilters,
  };
  if (applyFilters) {
    bodyData.applyTableFilters = applyFilters;
    bodyData.tableFilters = tableFilters;
  }
  return axiosInstance.post(ENDPOINTS.getCaseManagementMatchesTable(complianceLogId), bodyData, { params: rest });
};

const getCaseManagementTable = data => {
  const { filters, filterOn, ...params } = data;
  const filterData = FilterFormsService.normalizeCaseManagementFormData(filters, filterOn);
  return axiosInstance.post(ENDPOINTS.getCaseManagementTable(), filterData, {
    params,
  });
};

const updateComplianceHitStatus = (complianceLogId, data) => {
  return axiosInstance.put(ENDPOINTS.updateComplianceHitStatus(complianceLogId), data);
};

const removeCase = id => {
  return axiosInstance.delete(ENDPOINTS.removeCase(id));
};

const changeCaseOngoingStatus = (complianceLogId, status) => {
  return axiosInstance.put(ENDPOINTS.changeCaseOngoingStatus(complianceLogId), { status });
};
// CASE ATTACHED ENTITY
const getCaseAttachmentSubjects = params => {
  return axiosInstance.get(ENDPOINTS.getCaseAttachmentSubjectsEndpoint(), { params });
};
const attachEntitiesToCaseApi = (complianceLogId, data) => {
  return axiosInstance.post(ENDPOINTS.attachEntityToCase(complianceLogId), data);
};
const removeEntityFromCaseApi = (complianceLogId, entityId) => {
  return axiosInstance.delete(ENDPOINTS.removeEntityFromCase(complianceLogId, entityId));
};

const getComplianceHitDetailsApi = (complianceLogId, hitId) => {
  return axiosInstance.get(ENDPOINTS.getComplianceHitDetails(complianceLogId, hitId));
};

const getAdverseMediaHitsTAble = data => {
  const { hitId, complianceLogId, params } = data;

  return axiosInstance.get(ENDPOINTS.getAdverseMediaHitsTAble(complianceLogId, hitId), { params });
};

const getHitListing = data => {
  const { hitId, complianceLogId, params, status, filters } = data;
  return axiosInstance.post(ENDPOINTS.hitListings(complianceLogId, hitId), { status, filters }, { params });
};

const getHitDocuments = data => {
  const { hitId, complianceLogId, params } = data;
  return axiosInstance.get(ENDPOINTS.hitDocuments(complianceLogId, hitId), { params });
};

const updateHitStatus = data => {
  const { complianceLogId, hitId, ...rest } = data;
  return axiosInstance.put(ENDPOINTS.hitListings(complianceLogId, hitId), rest);
};

const updateCaseStatus = data => {
  const { complianceLogId, status } = data;
  return axiosInstance.put(ENDPOINTS.updateCaseStatus(complianceLogId), { status });
};

const batchDeleteCases = data => {
  return axiosInstance.delete(ENDPOINTS.batchDeleteCases(), { data });
};

const batchUpdateOngoingStatus = data => {
  return axiosInstance.put(ENDPOINTS.batchUpdateOngoingStatus(), data);
};

const batchUpdateCaseStatus = data => {
  return axiosInstance.put(ENDPOINTS.batchUpdateCaseStatus(), data);
};

const batchAttachEntities = data => {
  return axiosInstance.post(ENDPOINTS.batchAttachEntities(), data);
};

const batchUpdateCasesFromModal = data => {
  return axiosInstance.put(ENDPOINTS.batchUpdateCasesFromModal(), data);
};
const createCaseReportConfig = (caseId, data) => axiosInstance.post(ENDPOINTS.createCaseReportConfig(caseId), data);

const downloadCaseReport = (id, forceGeneration) => {
  return axiosInstance.get(ENDPOINTS.downloadCaseReport(id), { params: { forceGeneration }, responseType: 'blob' });
};

const createBatchCaseReport = ({ casesIds, ...personalReportConfig }) => {
  return axiosInstance.post(ENDPOINTS.createBatchCaseReport(), { casesIds, personalReportConfig });
};

export default {
  assignCaseToUser,
  updateComplianceHitStatus,
  getCaseManagementMatchesTable,
  getCaseManagementTable,
  getCaseDetailsApi,
  getComplianceHitDetailsApi,
  removeCase,
  removeEntityFromCaseApi,
  attachEntitiesToCaseApi,
  getCaseAttachmentSubjects,
  changeCaseOngoingStatus,
  getAdverseMediaHitsTAble,
  getDefaultScreeningProfile,
  getHitListing,
  getHitDocuments,
  updateHitStatus,
  updateCaseStatus,
  batchDeleteCases,
  batchUpdateOngoingStatus,
  batchUpdateCaseStatus,
  batchAttachEntities,
  batchUpdateCasesFromModal,
  createCaseReportConfig,
  downloadCaseReport,
  createBatchCaseReport,
};
