import React from 'react';
import { Field } from 'react-final-form';
import SelectFilterContentToggleList from '../../../../../../../components/Select/components/SelectFilterContentToggleList';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import DateFromUntilPicker from '../../../../../../Kyc/components/DateFromUntilPicker';
import FilterItem from '../../../../../../Kyc/components/FilterItem';
import './styles.scss';

const LicencesFilterForm = () => {
  return (
    <div className="ickyc-filter-form">
      <Field component={FilterItem} label="Last Modified" name="dateFlag">
        <DateFromUntilPicker dateFromName="dateFrom" dateUntilName="dateUntil" />
      </Field>
      <Field component={FilterItem} label="Tags" name="tagsFlag">
        <Field
          component={SelectField}
          placeholder="- Choose Tags -"
          withFilter
          multipleSelection
          name="tags"
          Trigger={<SelectTrigger />}
          options={authEnums.TAGS}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
    </div>
  );
};
export default LicencesFilterForm;
