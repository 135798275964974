import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import Section from '../../../../../../../../../components/Section';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import TextareaField from '../../../../../../../../../components/TextareaField';
import licenceEnums from '../../../../../../../../../utilities/enums/licenceEnums';
import validators from '../../../../../../../../../utilities/services/validators';
import './styles.scss';

const KYCServicesPlan = ({ namePreffix, disabled }) => {
  return (
    <Section className="ickyc-bl-kyc-services-plan" divider>
      <h3>KYC Services Plan</h3>
      <Field
        name={`${namePreffix}.defaultValues.kycServicePlan`}
        id={`${namePreffix}.defaultValues.kycServicePlan`}
        placeholder=". . ."
        preffix="$"
        disabled={disabled}
        type="number"
        label="KYC Services Plan"
        // number format
        component={InputField}
        validate={validators.required()}
      />
      <Field
        name={`${namePreffix}.defaultValues.kycBillingCycle`}
        id={`${namePreffix}.defaultValues.kycBillingCycle`}
        options={licenceEnums.BILLING_CYCLE_OPTIONS}
        component={SelectField}
        Trigger={<SelectTrigger placeholder="Choose" />}
        Content={<SelectFilterContent />}
        disabled={disabled}
        renderAsPortal
        label="KYC Services Billing Cycle"
      />
      <Field
        component={TextareaField}
        name={`${namePreffix}.kycNote`}
        disabled={disabled}
        placeholder="Add Note"
        label="Note"
        autoExpand
      />
    </Section>
  );
};

KYCServicesPlan.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};
KYCServicesPlan.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default KYCServicesPlan;
