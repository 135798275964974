import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Input from '../Input';

const InputField = ({ input, meta, className, preview, parentSetError, onChange, ...rest }) => {
  const errorMsg = useMemo(() => (meta ? meta.error || meta.submitError : null), [meta]);
  const hasError = useMemo(() => {
    if (meta && !preview) {
      const { invalid, touched: metaTouched, error: metaError, submitError, dirtySinceLastSubmit } = meta;
      if (submitError && !dirtySinceLastSubmit) {
        return submitError;
      }
      return metaTouched && invalid && metaError;
    }
    return false;
  }, [meta, preview]);

  const inputProps = useMemo(
    () => ({
      ...input,
      onChange: event => {
        input.onChange(event);
        onChange && onChange(event);
      },
    }),
    [input, onChange],
  );

  return (
    <Input
      className={className}
      preview={preview}
      errorMsg={errorMsg}
      hasError={parentSetError || hasError}
      {...inputProps}
      {...rest}
    />
  );
};

InputField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
  className: PropTypes.string,
  preview: PropTypes.bool,
  positiveNumbersOnly: PropTypes.bool,
  parentSetError: PropTypes.bool,
  onChange: PropTypes.bool,
};
InputField.defaultProps = {
  className: undefined,
  onChange: undefined,
  meta: { invalid: false, touched: false, error: '', submitError: '', dirtySinceLastSubmit: false },
  preview: false,
  positiveNumbersOnly: false,
  parentSetError: false,
};
export default InputField;
