import { Lock } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import ModuleTitleSettings from '../ModuleTitleSettings';

import './styles.scss';

const LandingScreenModule = ({ index, indexToShow, isDropAllowed }) => {
  const className = 'ickyc-table';
  const {
    input: { value: toggleValue },
  } = useField(`modules[${index}].isEnabled`);

  return (
    <CollapsibleTableRow
      isDropAllowed={isDropAllowed}
      className={className}
      withIndexColumn
      index={indexToShow}
      isToggled={toggleValue}
      icon={<Lock />}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Landing Page</td>
          <td className={`${className}__column__enabled`} onClick={e => e.stopPropagation()}>
            <Field
              preview
              name={`modules[${index}].isEnabled`}
              component={ToggleField}
              showCustomText
              toggleOnText="Enabled"
            />
          </td>
        </>
      }
    >
      <ModuleTitleSettings withActionButton withDescription index={index} />
    </CollapsibleTableRow>
  );
};
LandingScreenModule.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
};
export default LandingScreenModule;
