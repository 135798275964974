import React from 'react';
import { useParams } from 'react-router';
import api from '../../../../../../../api';
import useGroupPermissions from '../../../../../../../hooks/useGroupPermissions';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import TableHeaders from '../../../../../../../utilities/services/TableHeaders';
import { UserManagementContext } from '../../../../../../../utilities/services/contexts';
import UserManagementPage from '../../../../../../Administration/UserManagementPage';
import EditUserModal from '../../../../../../Administration/UserManagementPage/components/EditUserModal';
import UserRow from './components/UsersRow';
import './styles.scss';

const UsersInformationSection = () => {
  const { id } = useParams();
  const groupPermissions = useGroupPermissions(authEnums.PERMISSION_GROUP.BUSINESS_MANAGER);
  return (
    <>
      <UserManagementContext.Provider
        value={{
          updateUserData: api.businessManager.updateUser,
          createUser: api.businessManager.createUser,
          getAllUsers: api.businessManager.getAllUsers,
          downloadReportCSV: api.businessManager.downloadCSV,
          fetchUser: api.businessManager.getUser,
          deleteUser: api.businessManager.deleteUser,
          withTitle: true,
          titleText: 'Users',
          getGlobalTwoFactorAuthentication: api.businessManager.getClientGlobalTwoFactorAuthentication,
          withPassword: true,
          clientId: id,
          UserModal: EditUserModal,
          TableRow: UserRow,
          groupPermissions,
          CustomTableHeader: TableHeaders.UsersTableHeader,
        }}
      >
        <UserManagementPage />
      </UserManagementContext.Provider>
    </>
  );
};
export default UsersInformationSection;
