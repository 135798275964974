import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, useField, useForm, useFormState } from 'react-final-form';
import api from '../../../../../../../../../../api';
import validators from '../../../../../../../../../../utilities/services/validators';
import DocumentDropzone from '../../../../../../../components/DocumentDropzone';
import { DOCUMENT_TYPES, DOCUMENT_UPLOAD_TITLES } from '../../utilities/documentUploadEnums';
import DocumentBoundsDetectionPreview from '../DocumentBoundsDetectionPreview';
import PassportDetectionPreview from '../PassportDetectionPreview';

const FrontImageStep = ({ changeTitle, index, onFaceNotDetected }) => {
  const [uploadStarted, setUploadStarted] = useState(false);
  const [errorOnSubmit, setErrorOnSubmit] = useState(null);

  const {
    values: { scanDocumentType },
  } = useFormState();
  const { change } = useForm();
  const {
    input: { value: docImage },
  } = useField('image');

  useEffect(() => {
    if (scanDocumentType === DOCUMENT_TYPES.PASSPORT) {
      changeTitle(index, DOCUMENT_UPLOAD_TITLES.PASSPORT);
    }
    if (scanDocumentType === DOCUMENT_TYPES.DRIVERS_LICENCE) {
      changeTitle(index, DOCUMENT_UPLOAD_TITLES.FRONT_DRIVERS_LICENCE);
    }
    if (scanDocumentType === DOCUMENT_TYPES.ID) {
      changeTitle(index, DOCUMENT_UPLOAD_TITLES.FRONT_ID);
    }
  }, [scanDocumentType, changeTitle, index]);

  useEffect(() => {
    const uploadImage = img => {
      setUploadStarted(true);
      change('canSubmit', false);
      setErrorOnSubmit(null);
      const formData = new FormData();
      formData.append('file', img.file);
      api.kyc.entityManagement.naturalPerson
        .uploadFile(formData)
        .then(response => {
          console.log(response);
          change('frontImageName', response?.data?.uploadedName);
          change('canSubmit', true);
        })
        .catch(error => {
          change('canSubmit', false);
          setErrorOnSubmit(
            error?.response?.data?.message || 'Uploading image resulted with error, please try reaploading new photo',
          );
        })
        .finally(() => {
          setTimeout(() => {
            setUploadStarted(false);
          }, 2000);
        });
    };

    if (docImage) {
      if (!uploadStarted) uploadImage(docImage);
    }
  }, [docImage]);

  return (
    <Field
      name="image"
      component={DocumentDropzone}
      initialValue={undefined}
      title="Front Of Your Photo ID"
      accept="image/*"
      validate={validators.composeValidators(validators.validateDocumentDetection(), validators.required())}
      exifCorrection
    >
      {scanDocumentType === DOCUMENT_TYPES.PASSPORT ? (
        <PassportDetectionPreview
          faceDetection
          uploadingImage={uploadStarted}
          errorOnSubmit={errorOnSubmit}
          onFaceNotDetected={onFaceNotDetected}
        />
      ) : (
        <DocumentBoundsDetectionPreview
          faceDetection
          orientationCorrection
          errorOnSubmit={errorOnSubmit}
          uploadingImage={uploadStarted}
          onFaceNotDetected={onFaceNotDetected}
        />
      )}
    </Field>
  );
};

FrontImageStep.propTypes = {
  changeTitle: PropTypes.func,
  index: PropTypes.number,
  onFaceNotDetected: PropTypes.func,
};
FrontImageStep.defaultProps = {
  changeTitle: () => {},
  index: 0,
  onFaceNotDetected: () => {},
};
export default FrontImageStep;
