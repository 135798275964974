import api from 'api';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import { FORM_ERROR } from 'final-form';
import useSelect from 'hooks/useSelect';
import { PropTypes } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import ProtectedComponent from 'router/components/ProtectedComponent';
import AddAMLCase from 'scenes/Kyc/EntityManagement/components/KYCServicesV3/components/AddAMLCase';
import CommentRow from 'scenes/Kyc/EntityManagement/components/KYCServicesV3/components/CommentRow';
import DataCollection from 'scenes/Kyc/EntityManagement/components/KYCServicesV3/components/DataCollection';
import KYCRequests from 'scenes/Kyc/EntityManagement/components/KYCServicesV3/components/KYCRequests';
import { selectDefaultScreeningProfile } from 'store/selectors/global.selector';
import authEnums from 'utilities/enums/authEnums';
import { RCUContext } from 'utilities/services/contexts';
import validators from 'utilities/services/validators';
import ToggleField from '../../../../../components/ToggleField';
import Automation from '../../../../Kyc/EntityManagement/components/KYCServicesV3/components/Automation';
import GeneralSettingsAutomation from './components/GeneralSettingsAutomation';
import './styles.scss';

const NextKYCReviewForm = ({ namePreffix }) => {
  const baseClass = 'ickyc-next-kyc-review-form';
  const [npPortals, setNpProtals] = useState([]);
  const [npPortalsLoading, setNpPortalsLoading] = useState(false);
  const [lePortals, setLeProtals] = useState([]);
  const [lePortalsLoading, setLePortalsLoading] = useState(false);
  const [npConfiguration, setNpConfiguration] = useState({});
  const [npConfigurationLoading, setNpConfigurationLoading] = useState(false);
  const [leConfiguration, setLeConfiguration] = useState({});
  const [leConfigurationLoading, setLeConfigurationLoading] = useState(false);
  const [generalSettingsNPError, setGeneralSettingsNPError] = useState();
  const [generalSettingsLEError, setGeneralSettingsLEError] = useState();

  const defaultScreeningProfile = useSelect(selectDefaultScreeningProfile);

  const preffix = useMemo(() => (namePreffix ? `${namePreffix}.` : ''), [namePreffix]);

  const {
    values: { automationNpRcuEnabled, automationLeRcuEnabled, automationNpRcu, automationLeRcu },
  } = useFormState();
  const npDomain = automationNpRcu?.portalConfigurationId;
  const leDomain = automationLeRcu?.portalConfigurationId;

  const mapPortalList = useCallback(portals => {
    const mappedPortals = portals.map(portal => ({
      id: portal.id,
      value: portal.id,
      label: portal.domains[0].url,
    }));

    return mappedPortals;
  }, []);

  const loadNPPortals = useCallback(async () => {
    try {
      const { data: npPortals } = await api.kyc.entityManagement.getDomains();
      setNpProtals(mapPortalList(npPortals));
    } catch (err) {
      console.error(err);
    }
  }, []);

  const loadLEPortals = useCallback(async () => {
    try {
      const { data: lePortals } = await api.kyc.entityManagement.getLegalEntityDomains();
      setLeProtals(mapPortalList(lePortals));
    } catch (err) {
      console.error(err);
    }
  }, []);

  const loadNPConfiguration = useCallback(async () => {
    try {
      setNpConfigurationLoading(true);
      const { data } = await api.kyc.entityManagement.getNpSettingsForRCU(npDomain, 'automation');
      setNpConfiguration(data);
    } catch (err) {
      console.error(err);
      if (err?.response) {
        const { status, data: errData } = err.response;
        if (status >= 400 && status < 500) {
          setGeneralSettingsNPError(Array.isArray(errData.message) ? errData.message.join('') : errData.message);
          return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
        }
        if (status === 500) {
          return {
            [FORM_ERROR]: Array.isArray(errData.message)
              ? errData.message.join('')
              : errData.message || 'Internal Server Error, Try Again Later',
          };
        }
      } else return { [FORM_ERROR]: 'Error occured' };
    } finally {
      setNpConfigurationLoading(false);
    }
  }, [npDomain]);

  const loadLEConfiguration = useCallback(async () => {
    try {
      setLeConfigurationLoading(true);
      const { data } = await api.kyc.entityManagement.getLESettingsForRCU(leDomain, 'automation');
      setLeConfiguration(data);
    } catch (err) {
      console.error(err);
      if (err?.response) {
        const { status, data: errData } = err.response;
        if (status >= 400 && status < 500) {
          setGeneralSettingsNPError(Array.isArray(errData.message) ? errData.message.join('') : errData.message);
          return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
        }
        if (status === 500) {
          return {
            [FORM_ERROR]: Array.isArray(errData.message)
              ? errData.message.join('')
              : errData.message || 'Internal Server Error, Try Again Later',
          };
        }
      } else return { [FORM_ERROR]: 'Error occured' };
    } finally {
      setLeConfigurationLoading(false);
    }
  }, [leDomain]);

  useEffect(() => {
    if (automationNpRcuEnabled && !npPortals.length) loadNPPortals();
    if (automationLeRcuEnabled && !lePortals.length) loadLEPortals();
  }, [
    automationLeRcuEnabled,
    automationNpRcuEnabled,
    lePortals.length,
    loadLEPortals,
    loadNPPortals,
    npPortals.length,
  ]);

  useEffect(() => {
    npDomain && loadNPConfiguration();
    leDomain && loadLEConfiguration();
  }, [npDomain, leDomain, loadNPConfiguration, loadLEConfiguration]);

  return (
    <div className={baseClass}>
      <label>When Next KYC Review date arrives:</label>
      <Field
        name={`${preffix}automationNpRcuEnabled`}
        component={ToggleField}
        label="Request Client Update to Natural Person"
      />
      {automationNpRcuEnabled && (
        <>
          <Field
            component={SelectField}
            name={`${preffix}automationNpRcu.portalConfigurationId`}
            Trigger={
              <SelectTrigger placeholder={`${npPortals ? 'Choose Natural Person Portal' : 'Loading domains...'}`} />
            }
            Content={<SelectContent />}
            options={npPortals}
            validate={validators.required()}
            loading={npPortalsLoading}
          />

          {npDomain && (
            <RCUContext.Provider
              value={{
                initialConfiguration: npConfiguration,
              }}
            >
              <div>
                <GeneralSettingsAutomation namePrefix={'automationNpRcu'} errMessage={generalSettingsNPError} />
                <DataCollection namePrefix={'automationNpRcu'} />
                <KYCRequests namePrefix={'automationNpRcu'} />
                <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}>
                  <AddAMLCase
                    namePrefix={'automationNpRcu'}
                    entityID="automation"
                    screeningProfileId={defaultScreeningProfile.id}
                  />
                </ProtectedComponent>
                <Automation namePrefix={'automationNpRcu.automation'} />
                <CommentRow namePrefix={'automationNpRcu'} />
              </div>
            </RCUContext.Provider>
          )}
        </>
      )}

      <Field
        name={`${preffix}automationLeRcuEnabled`}
        component={ToggleField}
        label="Request Client Update to Legal Entity"
        description="Note that this request will be sent to a primary contact associated with the Legal Entity. If there is no primary contact in the profile then the request will not be triggered."
      />
      {automationLeRcuEnabled && (
        <>
          <Field
            component={SelectField}
            name={`${preffix}automationLeRcu.portalConfigurationId`}
            Trigger={
              <SelectTrigger placeholder={`${lePortals ? 'Choose Natural Person Portal' : 'Loading domains...'}`} />
            }
            Content={<SelectContent />}
            options={lePortals}
            validate={validators.required()}
            loading={lePortalsLoading}
          />

          {leDomain && (
            <RCUContext.Provider value={{ initialConfiguration: leConfiguration }}>
              <div>
                <GeneralSettingsAutomation namePrefix={'automationLeRcu'} errMessage={generalSettingsLEError} />
              </div>
            </RCUContext.Provider>
          )}
        </>
      )}
    </div>
  );
};

NextKYCReviewForm.propTypes = {
  namePreffix: PropTypes.string,
};
NextKYCReviewForm.defaultProps = {
  namePreffix: null,
};
export default NextKYCReviewForm;
