import PropTypes from 'prop-types';
import React from 'react';
import iconManager from '../../../../utilities/services/IconManager';
import './styles.scss';
/**
 * @name Item
 *
 * @param {string} icon
 * @param {string} title
 * @param {string} title
 *
 */

const Item = ({ icon, title, text }) => (
  <li className="ickyc-list-item">
    {iconManager.get(icon)}
    <span className="ickyc-list-item__content">
      <h3 className="ickyc-list-item__content__title">{title}</h3>
      {text && <p>{text}</p>}
    </span>
  </li>
);

Item.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};
Item.defaultProps = {
  icon: undefined,
  text: '',
};

export default Item;
