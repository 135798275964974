const ACCORDION_INDEXES = {
  GENERAL_SETTINGS: 'generalSettings',
  KYC_REQUESTS: 'kycRequests',
  ADD_AML_CASE: 'addAmlCase',
  AUTOMATION: 'automation',
  COMMENT: 'comment',
  IDENTITY_VERIFICATION: 'identityVerification',
  DOCUMENT_AUTHENTICATION: 'documentAuthentication',
  BIOMETRIC_AUTHENTICATION: 'biometricAuthentication',
  SUPPORTING_DOCUMENTS: 'supportingDocuments',
  DATA_COLLECTION: 'dataCollection',
};
export default {
  ACCORDION_INDEXES,
};
