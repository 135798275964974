import ButtonHeading from 'components/Headings/ButtonHeading';
import PropTypes from 'prop-types';
import React from 'react';
import useModalHandler from '../../../hooks/useModalHandler';
import ApiKeys from './components/ApiKeys';

const ApiKeysManagment = ({ clientAccountData }) => {
  const { isOpen: isCaseModalOpen, open: openCaseModal, close: closeCaseModal } = useModalHandler();

  return (
    <div>
      <ButtonHeading
        h1={false}
        title="API Key"
        buttonCopies={['Generate API Key']}
        buttonDisabled={[!clientAccountData.apiKeysEnabled]}
        clickHandlers={[openCaseModal]}
      />

      <ApiKeys
        clientId={clientAccountData.id}
        isCaseModalOpen={isCaseModalOpen}
        openCaseModal={openCaseModal}
        closeCaseModal={closeCaseModal}
      />
    </div>
  );
};

ApiKeysManagment.propTypes = {
  clientAccountData: PropTypes.shape({
    id: PropTypes.string,
    companyName: PropTypes.string,
    active: PropTypes.bool,
    duns: PropTypes.string,
    glei: PropTypes.string,
    dunsVerified: PropTypes.bool,
    gleiVerified: PropTypes.bool,
    street: PropTypes.string,
    city: PropTypes.string,
    stateProvinceId: PropTypes.number,
    stateProvince: PropTypes.string,
    postalZip: PropTypes.string,
    countryId: PropTypes.number,
    country: PropTypes.string,
    aptSuite: PropTypes.string,
    primaryContactName: PropTypes.string,
    primaryContactEmail: PropTypes.string,
    billingContactName: PropTypes.string,
    billingContactEmail: PropTypes.string,
    domain: PropTypes.string,
    supportingPlan: PropTypes.string,
    accountManagerId: PropTypes.string,
    apiKeysEnabled: PropTypes.bool,
  }),
};

export default ApiKeysManagment;
