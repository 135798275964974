/* eslint-disable no-use-before-define */
import axios from 'axios';
import bus from '../../modules/bus';
import globalLogout from '../../modules/globalLogout';
import RefreshToken, { REFRESH_TOKEN_FAIL, REFRESH_TOKEN_SUCCESS } from '../../utilities/services/RefreshToken';
import tokenHandler from '../../utilities/services/local-storage/TokenStorage';

const url = process.env.REACT_APP_BE_URL;

const axiosInstance = axios.create({
  baseURL: `${url}/api`,
  headers: {
    'ngrok-skip-browser-warning': true,
  },
});

const setToken = config => {
  const accessToken = `Bearer ${tokenHandler.get()}`;

  if (accessToken) {
    if (config.method !== 'OPTIONS') {
      config.headers.Authorization = accessToken;
    }
  }
};

const refresh = async config => {
  await new Promise((resolve, reject) => {
    const onRefreshTokenSuccess = () => {
      setToken(config);
      resolve();
      removeHandlers();
    };

    const onRefreshTokenFail = () => {
      reject(Error('Could not refresh token'));
      removeHandlers();
    };

    const removeSuccessListener = bus.addEventListener(REFRESH_TOKEN_SUCCESS, onRefreshTokenSuccess);
    const removeFailListener = bus.addEventListener(REFRESH_TOKEN_FAIL, onRefreshTokenFail);

    function removeHandlers() {
      removeSuccessListener();
      removeFailListener();
    }

    setTimeout(() => {
      removeHandlers();
    }, RefreshToken.checkRefreshinThreshold);
  });
};

axiosInstance.interceptors.request.use(
  async config => {
    setToken(config);
    if (RefreshToken.refreshingAtm && !config.isRefresh) await refresh(config);

    return config;
  },

  error => Promise.reject(error),
);

axiosInstance.interceptors.response.use(null, async error => {
  if (error.response && error.response.status === 401) {
    const { config } = error;

    if (config.skipRefresh || config.isRefresh) return;

    if (RefreshToken.refreshingAtm) {
      await refresh(config);
      return axiosInstance(config);
    }

    try {
      await RefreshToken.refreshToken();
      return axiosInstance(config);
    } catch (err) {
      globalLogout();
    }
  }

  return Promise.reject(error);
});

export default axiosInstance;
