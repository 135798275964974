import { useCallback } from 'react';

export default (callback, pagination) => {
  const { limit, currentPage, total, tempLimit } = pagination;

  const goToNextPage = useCallback(() => {
    callback({
      limit,
      skip: currentPage * limit,
      currentPage: currentPage + 1,
    });
  }, [limit, currentPage, callback]);

  const goToPrevPage = useCallback(() => {
    callback({
      limit,
      skip: (currentPage - 2) * limit,
      currentPage: currentPage - 1,
    });
  }, [limit, currentPage, callback]);

  const changeLimit = useCallback(
    value => {
      callback({ limit: value });
    },
    [callback],
  );

  const onLoadMore = useCallback(() => {
    callback({ ...pagination, limit: limit + limit, skip: 0 });
  }, [pagination, callback, limit]);

  return {
    goToNextPage,
    goToPrevPage,
    changeLimit,
    pageNumber: currentPage,
    pageSize: limit,
    tempPageSize: tempLimit,
    totalNumber: total,
    onLoadMore,
  };
};
