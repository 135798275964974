import api from 'api';
import React, { useCallback, useContext } from 'react';
import { itemTypes } from 'scenes/Kyc/EntityManagement/components/AdditionalDocumentsForm/components/PackageItemsFields/utils';
import toastr from 'toastr';
import { DocumentDataContext, EntityContext, HistoryRecordContext } from 'utilities/services/contexts';
import CustomQuestionTableRow from '../CustomQuestionTableRow';
import DocumentPackageTableRow from '../DocumentPackageTableRow';
import './styles.scss';

const PackageTableRow = props => {
  const { entityId } = useContext(EntityContext);
  const { eventHistoryId } = useContext(HistoryRecordContext);
  const itemId = props?.rowInfo?.itemId;
  const { setDocumentRecord } = useContext(DocumentDataContext);

  const handleItemDelete = useCallback(async () => {
    try {
      await api.kyc.entityManagement.deleteSupportingDocumentPackageItem(entityId, eventHistoryId, itemId);
      setDocumentRecord(old => ({ ...old, items: old.items.filter(item => item.itemId !== itemId) }));
      toastr.success('Item deleted successfully');
    } catch (err) {
      console.error(err);
      toastr.error('Error occured while deleting pakcage item');
    }
  }, [setDocumentRecord, eventHistoryId, itemId, entityId]);

  return (
    <>
      {props?.rowInfo?.itemType === itemTypes.DOCUMENT ? (
        <DocumentPackageTableRow {...props} onPackageItemDelete={handleItemDelete} />
      ) : (
        <CustomQuestionTableRow {...props} onPackageItemDelete={handleItemDelete} />
      )}
    </>
  );
};
export default PackageTableRow;
