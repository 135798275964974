const INFORMATION_PREFIL_OR_ENTER = [
  { id: '0', label: 'Require User to Enter Manually', value: '0' },
  { id: '1', label: 'Pre-Fill Form and Ask Users to Confirm', value: '1' },
];

const FACE_MATCH_TYPE = [
  { id: '0', label: 'Live Face Match', value: '0' },
  { id: '1', label: 'Selfie Upload', value: '1' },
  { id: '2', label: 'Live Face Match with fallback to Selfie Upload', value: '2' },
];

const STATUS_OPTIONS = [
  { id: '0', label: 'Open', value: '0' },
  { id: '1', label: 'Closed', value: '1' },
];
const PERIODIC_REVIEW_OPTIONS = [
  { id: '0', label: 'Days', value: 'Days' },
  {
    id: '1',
    label: 'Months',
    value: 'Months',
  },
  { id: '2', label: 'Years', value: 'Years' },
];

const INCLUDE_FACE_MATCH_OPTIONS = [
  { id: '0', label: 'With Existing Accepted Record', value: '0' },
  { id: '1', label: 'New Face Match', value: '1' },
];

export default {
  INFORMATION_PREFIL_OR_ENTER,
  FACE_MATCH_TYPE,
  STATUS_OPTIONS,
  PERIODIC_REVIEW_OPTIONS,
  INCLUDE_FACE_MATCH_OPTIONS,
};
