import IconButton from 'components/Buttons/IconButton';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import IconManager from 'utilities/services/IconManager';

import './styles.scss';

const LicenceItem = props => {
  const { text, children, noButtons, handleChange, fieldName } = props;

  const [isChecked, setIsChecked] = useState();

  const {
    input: { value: fieldValue },
  } = useField(fieldName);

  const handleClick = useCallback(() => {
    setIsChecked(prev => !prev);
    if (handleChange) {
      handleChange(fieldName, !isChecked);
    }
  }, [handleChange, fieldName, isChecked]);

  useEffect(() => {
    if (fieldValue !== '') {
      setIsChecked(fieldValue);
    }
  }, [fieldValue]);

  return (
    <div className="licence-item">
      {!noButtons && (
        <IconButton onClick={handleClick} transparent>
          {IconManager.get(isChecked ? IconManager.names.CHECK_CIRCULAR : IconManager.names.PLUS_CIRCULAR)}
        </IconButton>
      )}
      {text && <p>{text}</p>}
      {(isChecked || noButtons) && children && (
        <>
          {!noButtons && (
            <IconButton onClick={handleClick} transparent>
              {IconManager.get(IconManager.names.MINUS_CIRCULAR)}
            </IconButton>
          )}
          <div className="licence-item__content">{children}</div>
        </>
      )}
    </div>
  );
};

LicenceItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  noButtons: PropTypes.bool,
  handleChange: PropTypes.func,
  fieldName: PropTypes.string,
};

export default LicenceItem;
