import BareHeading from 'components/Headings/BareHeading';
import { FORM_ERROR } from 'final-form';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../../../../../api';
import IComplyForm from '../../../../../../../components/Form/IComplyForm';
import Spinner from '../../../../../../../components/Spinner';
import UsageFormFields from './components/UsageFormFields';

const UsageSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clientLicences, setClientLicences] = useState([]);
  const date = new Date();
  const [initialValues, setInititalValues] = useState({
    dateFrom: new Date(date.getFullYear(), date.getMonth() - 1, 1),
    dateTo: new Date(date.getFullYear(), date.getMonth(), 0),
    idsOfLicences: [],
  });
  const { id: clientId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    api.businessManager.usage
      .getAllClientLicences(clientId)
      .then(res => {
        const { data } = res;
        setClientLicences(data);
        setInititalValues({
          dateFrom: new Date(date.getFullYear(), date.getMonth() - 1, 1),
          dateTo: new Date(date.getFullYear(), date.getMonth() + 1, 0),
          idsOfLicences: data.map(() => true),
        });
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
          }
          if (resStatus === 500) {
            return {
              [FORM_ERROR]: Array.isArray(data.message)
                ? data.message.join('')
                : data.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <BareHeading title="Usage" />
      {isLoading ? (
        <Spinner />
      ) : (
        <IComplyForm onSubmit={() => {}} initialValues={initialValues}>
          <UsageFormFields clientLicences={clientLicences} />
        </IComplyForm>
      )}
    </>
  );
};
export default UsageSection;
