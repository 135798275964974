import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import api from '../../../../../api';
import Table from '../../../../../components/Tables/Table';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import useTable from '../../../../../hooks/useTable';
import TableHeaders from '../../../../../utilities/services/TableHeaders';
import ApiKeysRow from './components/ApiKeysRow';
import CreateApiKeyModal from './components/CreateApiKeyModal';
import './styles.scss';

const ApiKeys = ({ clientId, isCaseModalOpen, closeCaseModal }) => {
  const getApiKeys = useMemo(() => api.administration.apikeys.getDataApiKeys(clientId), [clientId]);

  const {
    isLoading: apiKeysLoading,
    removeRow: onRemoveHandler,
    records: apiKeys,
    addRow: handleCreateNewApiKey,
    changeParams: changeApiKeysParams,
    pagination: apiKeysPagination,
    params: apiKeysParams,
  } = useTable(getApiKeys, {});

  return (
    <div className="ickyc-api-keys">
      <Table
        values={apiKeys}
        pagination={apiKeysPagination}
        tableRow={ApiKeysRow}
        headerRow={TableHeader}
        onRemove={onRemoveHandler}
        className="ickyc-api-keys-table"
        withPagination
        updating={apiKeysLoading}
        clientId={clientId}
        handleParamsChange={changeApiKeysParams}
        headerData={{
          sortCriteria: apiKeysParams.sortCriteria,
          sortOrder: apiKeysParams.sortOrder,
          onClick: changeApiKeysParams,
          items: TableHeaders.ApiKeysTableHeader,
        }}
      />
      {isCaseModalOpen && (
        <CreateApiKeyModal
          clientId={clientId}
          className="ickyc-api-keys-modal"
          hideModal={closeCaseModal}
          setNewlyAdded={handleCreateNewApiKey}
          disableAttachTo
        />
      )}
    </div>
  );
};
ApiKeys.propTypes = {
  isCaseModalOpen: PropTypes.func,
  closeCaseModal: PropTypes.func,
  clientId: PropTypes.string,
};
ApiKeys.defaultProps = {
  isCaseModalOpen: null,
  closeCaseModal: null,
  clientId: '',
};
export default ApiKeys;
