import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import api from 'api';
import classNames from 'classnames';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import UploadDocumentField from 'components/UploadDocumentField';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import utilities from 'utilities';
import enums from 'utilities/enums';
import IconManager from 'utilities/services/IconManager';
import './styles.scss';

const documentFields = {
  passport: (
    <Field component={UploadDocumentField} name="document.passport[0]" accept="image/*" photoLabel="Passport ID page" />
  ),
  driversLicense: (
    <>
      <Field
        component={UploadDocumentField}
        name="document.driversLicense[0]"
        accept="image/*"
        photoLabel="Front of Photo Driver's License"
      />
      <Field
        component={UploadDocumentField}
        name="document.driversLicense[1]"
        accept="image/*"
        photoLabel="Back of Photo Driver's License"
      />
    </>
  ),
  idCard: (
    <>
      <Field
        component={UploadDocumentField}
        name="document.idCard[0]"
        accept="image/*"
        photoLabel="Front of Photo ID"
      />
      <Field component={UploadDocumentField} name="document.idCard[1]" accept="image/*" photoLabel="Back of Photo ID" />
    </>
  ),
};

const UploadID = ({ hideSection, isLoadingID, setIsLoadingID }) => {
  const { mutators, change } = useForm();
  const {
    values: { document: documentValue, documentType: documentTypeValue },
  } = useFormState();
  const [error, setError] = useState();

  const documentValidation = useMemo(() => {
    const docValue = documentValue?.[documentTypeValue];

    return (
      docValue?.[0]?.status === 'succeeded' &&
      (documentTypeValue === 'passport' || docValue?.[1]?.status === 'succeeded')
    );
  }, [documentTypeValue, documentValue]);

  useEffect(() => {
    if (!documentValidation) {
      setError();
    }
  }, [documentValidation]);

  useEffect(() => {
    setError();
  }, [documentValue]);

  const handleSelectDocument = useCallback(
    documentName => {
      change('documentType', documentName);
      setTimeout(() => {
        bus.broadcastEvent(enums.BUS_EVENTS.SCROLL_BOTTOM_MODAL);
      });
    },
    [change],
  );

  const submitDocument = useCallback(async () => {
    setIsLoadingID(true);
    const submitData = {
      documentType: documentTypeValue,
      documentFrontFileName: documentValue[documentTypeValue][0].other.uploadedName,
      documentBackFileName: documentValue[documentTypeValue][1]?.other.uploadedName ?? null,
    };
    try {
      const { data } = await api.kyc.entityManagement.naturalPerson.getDocumentData(submitData);
      const filteredData = utilities.removeNullProperties(data);
      mutators.push('persons', { ...filteredData });

      hideSection();
      change('document', undefined);
    } catch (err) {
      if (err?.response) {
        const { status, data } = err.response;
        if (status >= 400 && status < 500) {
          return setError(data.error);
        }
        if (status === 500) {
          return setError('Internal Server Error, Try Again Later');
        }
      } else return setError('Error occured');
    } finally {
      setIsLoadingID(false);
    }
  }, [mutators, documentValue, documentTypeValue, change, hideSection, setIsLoadingID]);

  return (
    <Box className="ickyc-kyc-services ickyc-upload-document">
      <h2>Select ID Type:</h2>
      <Grid className="ickyc-upload-document__buttons-container" container justifyContent="center" spacing={2}>
        <Grid item>
          <OutlineButton
            onClick={() => handleSelectDocument('passport')}
            left={IconManager.get(IconManager.names.PASSPORT)}
            className={classNames({ 'ickyc-outline-button--active': documentTypeValue === 'passport' })}
          >
            Passport
          </OutlineButton>
        </Grid>
        <Grid item>
          <OutlineButton
            onClick={() => handleSelectDocument('driversLicense')}
            left={IconManager.get(IconManager.names.DRIVERS_LICENCE)}
            className={classNames('ickyc-upload-document__buttons-container__license', {
              'ickyc-outline-button--active': documentTypeValue === 'driversLicense',
            })}
          >
            Driver's License
          </OutlineButton>
        </Grid>
        <Grid item>
          <OutlineButton
            onClick={() => handleSelectDocument('idCard')}
            left={IconManager.get(IconManager.names.ID_CARD)}
            className={classNames({ 'ickyc-outline-button--active': documentTypeValue === 'idCard' })}
          >
            ID Card
          </OutlineButton>
        </Grid>
      </Grid>

      <Box className="ickyc-upload-document__fields-container" key={documentTypeValue}>
        {documentFields[documentTypeValue]}
        <Typography className="ickyc-upload-document__warning-info">
          <b>Note:</b> <i>Street Address</i> field can contain <i>Unit</i> and/or <i>Zip/Postal Code</i>, while the
          separate fields <i>Unit</i> and/or <i>Zip/Postal Code</i> are empty.
        </Typography>
        <PrimaryButton disabled={!documentValidation || isLoadingID} onClick={submitDocument}>
          {isLoadingID ? <CircularProgress color="white" size="18px" /> : 'Next'}
        </PrimaryButton>
        {error && <Box className="ickyc-upload-document__submissionError">{error}</Box>}
      </Box>
    </Box>
  );
};

UploadID.propTypes = {
  hideSection: PropTypes.func.isRequired,
  isLoadingID: PropTypes.bool.isRequired,
  setIsLoadingID: PropTypes.func.isRequired,
};

export default UploadID;
