import React, { useMemo, useState } from 'react';
import { DragContext } from 'utilities/services/contexts';
import utils from './utils';

import './styles.scss';

const Modules = () => {
  const baseClass = 'ickyc-natural-person-modules';
  const [dragList, setDragList] = useState(utils.modulesList);
  const [dropCallback, setDropCallback] = useState(() => reorderedList => {});
  const [isChildDragged, setIsChildDragged] = useState(false);

  const modules = useMemo(
    () =>
      dragList.map(({ component: Module, index, ...rest }, indexToShow) => {
        return <Module key={index} index={index} indexToShow={indexToShow + 1} isDropAllowed={false} {...rest} />;
      }),
    [dragList],
  );

  return (
    <div className={baseClass}>
      <div className="ickyc-table">
        <table>
          <DragContext.Provider
            value={{ dragList, setDragList, dropCallback, setDropCallback, setIsChildDragged, isChildDragged }}
          >
            <tbody>{modules}</tbody>
          </DragContext.Provider>
        </table>
      </div>
    </div>
  );
};
export default Modules;
