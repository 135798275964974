import { useCallback, useEffect, useState } from 'react';

export default (collapsibleOptionsRef, shouldOpen) => {
  const [isOpen, setIsOpen] = useState(shouldOpen);

  const toggleView = useCallback(val => {
    setIsOpen(prev => (typeof val === 'boolean' ? val : !prev));
  }, []);

  useEffect(() => setIsOpen(shouldOpen), [shouldOpen]);

  useEffect(() => {
    const collaspibleContent = collapsibleOptionsRef?.current;
    if (collaspibleContent) {
      const childs = collaspibleContent.childNodes;
      let contentHeight = 0;
      for (const child of childs) {
        const { height } = child.getBoundingClientRect();
        contentHeight += height;
      }
      collaspibleContent.style.height = isOpen ? `${contentHeight + 30}px` : '0px';
    }
  }, [collapsibleOptionsRef, isOpen]);

  return {
    isOpen,
    toggleView,
  };
};
