import React, { useCallback, useContext, useEffect } from 'react';
import { useField, useFormState } from 'react-final-form';
import { DragContext } from '../../../../../../../utilities/services/contexts';
import utils from './utils';

const LegalEntityModules = () => {
  const { setDragList, dragList, isChildDragged } = useContext(DragContext);
  const { modulesList, getNPFModule, putNPFModuleOnBegining } = utils;
  const baseClass = 'ickyc-natural-person-modules';

  const {
    submitFailed,
    errors: { modules: modulesErrors },
  } = useFormState();

  const {
    input: { value: nestedNpFlowLast },
  } = useField(`modules[${getNPFModule(modulesList).index}].nestedNpFlowLast`);

  useEffect(() => {
    setDragList(nestedNpFlowLast === false ? putNPFModuleOnBegining(modulesList) : modulesList);
  }, []);

  const isFirstOrLastRow = useCallback(index => dragList && (index === 0 || index === dragList.length - 1), [dragList]);

  return (
    <div className={baseClass}>
      <div className="ickyc-table">
        <div className="ickyc-table__controls">
          <h2>Legal Entity Modules</h2>
        </div>
        <table>
          <tbody>
            {dragList.map(({ component: Module, index, ...rest }, indexToShow) => {
              return (
                <Module
                  index={index}
                  indexToShow={indexToShow + 1}
                  isDropAllowed={isFirstOrLastRow(indexToShow) && !isChildDragged}
                  hasModuleError={submitFailed && !!modulesErrors?.[index]}
                  {...rest}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default LegalEntityModules;
