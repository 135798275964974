import { CircularProgress } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import api from '../../../../../api';
import CountrySelectField from '../../../../../components/CountrySelectField';
import InputField from '../../../../../components/InputField';
import Section from '../../../../../components/Section';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../components/SelectField';
import StateProvinceSelectField from '../../../../../components/StateProvinceSelectField';
import ToggleField from '../../../../../components/ToggleField';
import useDebounce from '../../../../../hooks/useDebounce';
import useEffectSkipFirst from '../../../../../hooks/useEffectSkipFirst';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../utilities/services/IconManager';
import validators from '../../../../../utilities/services/validators';
import ConfirmCompanyModal from './components/ConfirmCompanyModal';
import utils from './utils';

import './styles.scss';

const BusinessAccountFormFields = () => {
  const { SUPPORT_PLAN_OPTIONS, mapValuesArrayToOptions, mapIComplyTeamToOptions } = utils;
  const {
    values: { countryId, duns, glei, dunsVerified, gleiVerified, supportingPlan },
  } = useFormState();

  const { change } = useForm();

  const [gleiStatus, setGleiStatusValue] = useState({ testedValue: glei, state: gleiVerified ? 0 : 1 });
  const [dunsStatus, setDunsStatusValue] = useState({ testedValue: duns, state: dunsVerified ? 0 : 1 });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmData, setConfirmData] = useState({ name: null, code: null, address: null, type: null });

  const [icomplyTeamOptions, setIcomplyTeamOptions] = useState([]);
  const [isIcomplyTeamLoading, setIsIcomplyTeamLoading] = useState();

  const debouncedDunsValue = useDebounce(duns);
  const debouncedGleiValue = useDebounce(glei);

  const handleCloseModal = useCallback(
    isConfirm => {
      const isDuns = confirmData.type === 'duns';

      if (isDuns) {
        change('dunsVerified', isConfirm);
        setDunsStatusValue(prev => ({ ...prev, state: isConfirm ? 0 : 1 }));
      } else {
        change('gleiVerified', isConfirm);
        setGleiStatusValue(prev => ({ ...prev, state: isConfirm ? 0 : 1 }));
      }

      setIsModalOpen(false);
    },
    [setIsModalOpen, setDunsStatusValue, confirmData, change],
  );

  useEffectSkipFirst(() => {
    const checkDuns = () => {
      // reset values
      change('dunsVerified', false);
      if (validators.validateDuns('error')(debouncedDunsValue) || dunsStatus.testedValue === debouncedDunsValue) {
        setDunsStatusValue(prev => ({ ...prev, testedValue: duns, state: 1 }));
        return;
      }

      setDunsStatusValue({ testedValue: debouncedDunsValue, state: 2 });
      api.businessManager
        .verifyDuns(debouncedDunsValue)
        .then(({ data: responseData }) => {
          const { name, duns: code, address } = responseData;
          // if there is company with this duns, require confirm
          if (name) {
            setConfirmData({ name, code, address, type: 'duns' });
            setIsModalOpen(true);
            setDunsStatusValue(prev => ({ ...prev, state: 3 }));
          } else setDunsStatusValue(prev => ({ ...prev, state: 1 }));
        })
        .catch(err => {
          if (err?.response) {
            const { status, data } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(data.message)
                  ? data.message.join('')
                  : data.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        });
    };

    if (debouncedDunsValue) checkDuns();
  }, [change, debouncedDunsValue]);

  useEffectSkipFirst(() => {
    const checkGlei = () => {
      // reset values
      change('gleiVerified', false);
      if (validators.validateLei('error')(debouncedGleiValue) || gleiStatus.testedValue === debouncedGleiValue) {
        setGleiStatusValue(prev => ({ ...prev, testedValue: glei, state: 1 }));
        return;
      }

      setGleiStatusValue(prev => ({ ...prev, testedValue: debouncedGleiValue, state: 2 }));
      api.businessManager
        .verifyGlei(debouncedGleiValue)
        .then(({ data: responseData }) => {
          const { name, glei: code, address } = responseData;
          // if there is company with this glei, require confirm
          if (name) {
            setConfirmData({ name, code, address, type: 'glei' });
            setIsModalOpen(true);
            setGleiStatusValue(prev => ({ ...prev, state: 3 }));
          } else setGleiStatusValue(prev => ({ ...prev, state: 1 }));
        })
        .catch(err => {
          if (err?.response) {
            const { status, data } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(data.message)
                  ? data.message.join('')
                  : data.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        });
    };

    if (debouncedGleiValue) checkGlei();
  }, [change, debouncedGleiValue]);

  const classBase = 'ickyc-business-account-form-fields';
  const setSufix = setClassSuffix(classBase);

  const verifiedNotVerifiedPending = [
    <span className={setSufix('__verified')}>{IconManager.get('check')}Verified</span>,
    <span className={setSufix('__not-verified')}> {IconManager.get('close')}Not Verified</span>,
    <span className={setSufix('__loading')}>
      <CircularProgress />
    </span>,
    <span className={setSufix('__pending')}>Pending</span>,
  ];

  useEffect(() => {
    if (supportingPlan === 'Premium') {
      (async () => {
        try {
          setIsIcomplyTeamLoading(true);
          const users = await api.internal.iComplyTeam.getAllUsers();
          setIcomplyTeamOptions(mapIComplyTeamToOptions(users.data.data));
        } catch (err) {
          console.error(err);
        } finally {
          setIsIcomplyTeamLoading(false);
        }
      })();
    }
  }, [supportingPlan]);
  return (
    <div className={classBase}>
      <Section divider>
        <h2>Company</h2>
        <div className={setSufix('__company')}>
          <Field
            component={InputField}
            name="companyName"
            placeholder="Business Name"
            validate={validators.required()}
          />
          <Field component={InputField} name="legalName" placeholder="Legal Name" validate={validators.required()} />
          <Field component={ToggleField} name="active" showCustomText toggleOnText="Active" toggleOffText="Inactive" />

          <Field
            name="duns"
            component={InputField}
            placeholder="DUNS"
            suffix={verifiedNotVerifiedPending[dunsStatus.state]}
            parse={value => value.replace(validators.NUMBER_REGEX, '')}
          />
          <Field
            name="glei"
            component={InputField}
            placeholder="GLEI"
            suffix={verifiedNotVerifiedPending[gleiStatus.state]}
          />
          <Field
            name="domain"
            component={InputField}
            suffix=".icomplykyc.com"
            validate={validators.composeValidators(validators.required(), validators.domain('Invalid domain format'))}
            placeholder="Subdomain"
            className="ickyc-business-account-form-fields__domain-field"
          />
        </div>
      </Section>
      <Section divider>
        <h2>Support</h2>
        <div className={setSufix('__support')}>
          <Field
            component={SelectField}
            options={mapValuesArrayToOptions(SUPPORT_PLAN_OPTIONS)}
            Trigger={<SelectTrigger placeholder="Select Support Plan" />}
            Content={<SelectFilterContent />}
            name="supportingPlan"
          />
          {supportingPlan === 'Premium' && (
            <Field
              component={SelectField}
              options={icomplyTeamOptions}
              Trigger={<SelectTrigger placeholder="Select Account Manager" />}
              Content={<SelectFilterContent />}
              name="accountManagerId"
              loading={isIcomplyTeamLoading}
            />
          )}
        </div>
      </Section>
      <Section divider>
        <h2>Address</h2>
        <div className={setSufix('__address')}>
          <Field component={InputField} name="aptSuite" placeholder="Apt/Suite" validate={validators.required()} />
          <Field component={InputField} name="street" placeholder="Street" validate={validators.required()} />
          <Field component={InputField} name="city" placeholder="City" validate={validators.required()} />
          <Field component={InputField} name="postalZip" placeholder="Zip/Postal" validate={validators.required()} />
          <Field
            component={CountrySelectField}
            defaultValue={0}
            name="countryId"
            placeholder="Country"
            autoClose
            validate={validators.required()}
          />
          <Field
            component={StateProvinceSelectField}
            name="stateProvinceId"
            placeholder="State/Prov"
            countryId={countryId}
            validate={validators.required()}
            autoClose
          />
        </div>
      </Section>
      <Section divider>
        <h2>Primary Contact</h2>
        <div className={setSufix('__contact')}>
          <Field
            component={InputField}
            name="primaryContactName"
            placeholder="Primary Contact Name"
            validate={validators.required()}
          />
          <Field
            component={InputField}
            name="primaryContactEmail"
            placeholder="Primary Contact Email"
            validate={validators.composeValidators(validators.required(), validators.email('Invalid email address'))}
          />
        </div>
      </Section>
      <Section>
        <h2>Billing Contact</h2>
        <div className={setSufix('__contact')}>
          <Field
            component={InputField}
            name="billingContactName"
            placeholder="Billing Contact Name"
            validate={validators.required()}
          />
          <Field
            component={InputField}
            name="billingContactEmail"
            placeholder="Billing Contact Email"
            validate={validators.composeValidators(validators.required(), validators.email('Invalid email address'))}
          />
        </div>
      </Section>

      {isModalOpen && <ConfirmCompanyModal data={confirmData} onCloseModal={handleCloseModal} />}
    </div>
  );
};

export default BusinessAccountFormFields;
