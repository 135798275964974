import classNames from 'classnames';
import Badge from 'components/Badge';
import useSelect from 'hooks/useSelect';
import React from 'react';
import { selectNotifications } from 'store/selectors/global.selector';
import IconManager from 'utilities/services/IconManager';
import './styles.scss';

const NotificationBell = ({ areNotificationsOpen }) => {
  const { unreadNotificationsCount } = useSelect(selectNotifications) || [];
  const classes = classNames('notification-bell');
  return (
    <div className={classes}>
      {IconManager.get(IconManager.names.BELL)}
      {!!unreadNotificationsCount && <Badge content={unreadNotificationsCount} red />}
    </div>
  );
};
export default NotificationBell;
