import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useSelect from '../../../../hooks/useSelect';
import { selectCountries } from '../../../../store/selectors/global.selector';
import DateManager from '../../../../utilities/services/DateManager';
import SingleCountry from '../../../CountrySelect/components/SingleCountry';

/**
 * Used as a prop in Table Component
 *
 * @param {Date} added - creation date
 * @param {string} type - entity type -> Natural Person or Legal Entity
 */
const ExistingEntitiesTableRow = ({ rowInfo, className, onClick }) => {
  const { added, countryId, name, email, id, selected, hovered } = rowInfo;
  const countries = useSelect(selectCountries);
  const countryToShow = countries.find(country => country.id === countryId) || {};
  const classes = classNames({
    [`${className}__row--hovered`]: hovered,
    [`${className}__row--selected`]: selected,
    [`${className}__row`]: true,
  });
  return (
    <tr
      key={id}
      className={classes}
      onClick={e => {
        e.stopPropagation();
        onClick(id);
      }}
    >
      <td className={`${className}__column__added`}>
        <span>{added ? DateManager.monDayYearLocal(added) : ''}</span>
      </td>
      <td className={`${className}__column__name`}>
        <span>{name}</span>
      </td>
      <td className={`${className}__column__countryName`}>
        <span>{countryId && <SingleCountry name={countryToShow.name} alpha2Code={countryToShow.alpha2Code} />}</span>
      </td>
      <td className={`${className}__column__email`}>
        <span>{email}</span>
      </td>
    </tr>
  );
};

ExistingEntitiesTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    added: PropTypes.string,
    countryId: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    hovered: PropTypes.bool,
  }),
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
ExistingEntitiesTableRow.defaultProps = {
  onClick: () => {},
  rowInfo: {
    selected: false,
    name: '',
    email: '',
    added: undefined,
    hovered: false,
    countryId: 0,
  },
};

export default React.memo(ExistingEntitiesTableRow);
