import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import Textarea from '../Textarea';

const TextareaField = React.forwardRef(({ input, meta, className, onChange, ...rest }, ref) => {
  const errorMsg = useMemo(() => (meta ? meta.error || meta.submitError : null), [meta]);

  const hasError = useMemo(() => {
    if (meta) {
      const { invalid, touched: metaTouched, error: metaError, submitError, dirtySinceLastSubmit } = meta;
      if (submitError && !dirtySinceLastSubmit) {
        return submitError;
      }
      return metaTouched && invalid && metaError;
    }
    return false;
  }, [meta]);

  const handleOnChange = useCallback(
    e => {
      input.onChange(e);
      onChange && onChange(e);
    },
    [onChange, input.onChange],
  );

  return (
    <Textarea
      ref={ref}
      className={className}
      {...rest}
      {...input}
      onChange={handleOnChange}
      errorMsg={errorMsg}
      hasError={hasError}
    />
  );
});

TextareaField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
  className: PropTypes.string,
};
TextareaField.defaultProps = {
  className: undefined,
  input: null,
  meta: { invalid: false, touched: false, error: '', submitError: '', dirtySinceLastSubmit: false },
};
export default TextareaField;
