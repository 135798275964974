import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import utilities from 'utilities';
import routesEnum from 'utilities/enums/routesEnum';
import setClassSuffix from 'utilities/services/ClassManager';
import DateManager from 'utilities/services/DateManager';

const EmailTemplateTableRow = ({ rowInfo: { id, type, lastModifiedAt, lastModifiedBy }, className }) => {
  const classes = classNames('email-template-table-row', `${className}__row`);
  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const history = useHistory();

  const openTemplatePage = useCallback(
    () =>
      history.push({
        pathname: utilities.routeParams(routesEnum.internal.EMAIL_TEMPLATE_EDITOR, { id }),
        state: { id, type },
      }),
    [id, history],
  );
  return (
    <tr className={classes} onClick={openTemplatePage}>
      <td className={setColumnSuffix('__type')}>
        <span>{type}</span>
      </td>
      <td className={setColumnSuffix('__lastModifiedAt')}>
        <span>{DateManager.getFullDateLocal(lastModifiedAt)}</span>
      </td>
      <td className={setColumnSuffix('__lastModifiedBy')}>
        <span>{lastModifiedBy}</span>
      </td>
    </tr>
  );
};
EmailTemplateTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    type: PropTypes.string,
    lastModifiedAt: PropTypes.string,
    lastModifiedBy: PropTypes.string,
  }),
  className: PropTypes.string,
};

EmailTemplateTableRow.defaultProps = {
  className: '',
  rowInfo: {},
};
export default EmailTemplateTableRow;
