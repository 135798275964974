import React, { useCallback, useEffect, useState } from 'react';
import api from '../../../../../api';
import Section from '../../../../../components/Section';
import Table from '../../../../../components/Tables/Table';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import TableHeaders from '../../../../../utilities/services/TableHeaders';
import CSVImportsRow from './components/CSVImportsRow';
import './styles.scss';

const DATA_TYPE = {
  export: 'Export',
  import: 'Import',
};

const CSVImport = () => {
  const [importsParams, setImportsParams] = useState({
    sortCriteria: 'dateTime',
    sortOrder: 1,
    limit: 5,
    skip: 0,
    logType: DATA_TYPE.import,
  });
  const [imports, setImports] = useState([]);
  const [importsPagination, setImportsPagination] = useState({});
  const [importsLoading, setImportsLoading] = useState();

  const changeExportsParams = useCallback(additionalParams => {
    setImportsParams(prev => ({ ...prev, ...additionalParams }));
  }, []);

  useEffect(() => {
    setImportsLoading(true);
    api.administration
      .getFilesManagement(importsParams)
      .then(({ data: response }) => {
        const { data, paginationData } = response;
        setImports(data);
        setImportsPagination(paginationData);
      })
      .finally(() => setImportsLoading(false));
  }, [importsParams]);
  return (
    <Section title="Imports" divider>
      <Table
        values={imports}
        pagination={importsPagination}
        tableRow={CSVImportsRow}
        headerRow={TableHeader}
        className="ickyc-csv-imports-table"
        withPagination
        updating={importsLoading}
        handleParamsChange={changeExportsParams}
        headerData={{
          sortCriteria: importsParams.sortCriteria,
          sortOrder: importsParams.sortOrder,
          onClick: changeExportsParams,
          items: TableHeaders.CVSImportsTable,
        }}
      />
    </Section>
  );
};

export default CSVImport;
