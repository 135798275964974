import BareHeading from 'components/Headings/BareHeading';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import api from '../../../../../../../api';
import IComplyForm from '../../../../../../../components/Form/IComplyForm';
import Spinner from '../../../../../../../components/Spinner';
import Table from '../../../../../../../components/Tables/Table';
import TableHeader from '../../../../../../../components/Tables/Table/components/TableHeader';
import utilities from '../../../../../../../utilities';
import BillingTableRow from './components/BillingTableRow';
import './styles.scss';

const BillingSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [updatingInfo, setUpdatingInfo] = useState(false);
  const [params, setParams] = useState({ sortOrder: 1, sortCriteria: 'created', limit: 10, searchString: undefined });
  const { id: clientId } = useParams();
  const [pagination, setPagination] = useState({});
  const [billingInfo, setBillingInfo] = useState([]);
  const [debtData, setDebtData] = useState({ balanceOwing: 0, lifetimeValue: 0 });
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const fetchTableData = async () => {
      setIsLoading(true);
      api.businessManager.billing
        .getBillingInfo({ ...params, clientId })
        .then(({ data: response }) => {
          const {
            debt,
            monthsWithStatistics: { data, paginationData },
          } = response;

          setDebtData(debt);
          setBillingInfo(data);

          const props = {};
          data.forEach((element, index) => {
            // eslint-disable-next-line no-undef
            props[`isPaid${element.id}`] = element?.isPaid.toString();
          });

          setInitialValues(props);
          setPagination(paginationData);
        })
        .finally(() => setIsLoading(false));
    };
    fetchTableData();
  }, [params]);

  const changeParams = useCallback(additionalParams => {
    setParams(prev => ({ ...prev, ...additionalParams }));
  }, []);

  const onSaveChanges = () => {};
  const handleStatusChange = (id, billStatus) => {
    setUpdatingInfo(true);
    api.businessManager.billing
      .updateBillingStatus(clientId, id, billStatus)
      .then(({ data }) => {
        billStatus === 'true'
          ? setDebtData(prev => ({ ...prev, balanceOwing: prev?.balanceOwing - data?.balance }))
          : setDebtData(prev => ({ ...prev, balanceOwing: prev?.balanceOwing + data?.balance }));
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errData.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => {
        setUpdatingInfo(false);
      });
  };

  return (
    <>
      <BareHeading title="Billing" />

      {isLoading ? (
        <Spinner />
      ) : (
        <IComplyForm mutators={{ ...arrayMutators }} onSubmit={onSaveChanges} initialValues={initialValues}>
          <div className="ickyc-billing__debt">
            <div>
              <h3>Total Lifetime Value&nbsp;</h3>
              <span>{utilities.formatDecimalByAmericanLocalization(debtData?.lifetimeValue || 0)}</span>
            </div>
            <div>
              <h3>Balance Owing&nbsp;</h3>
              <span>{utilities.formatDecimalByAmericanLocalization(debtData?.balanceOwing || 0)}</span>
            </div>
          </div>
          <div className="ickyc-billing-table">
            <Table
              values={billingInfo}
              withPagination
              pagination={pagination}
              tableRow={BillingTableRow}
              headerRow={TableHeader}
              updating={isLoading || updatingInfo}
              handleParamsChange={changeParams}
              onClick={handleStatusChange}
              headerData={{
                items: [
                  { type: 'arrow' },
                  { type: 'month' },
                  { type: 'saas', label: 'SaaS' },
                  { type: 'kycPlan', label: 'KYC Plan' },
                  { type: 'consumption', label: 'Consumption' },
                  { type: 'overage', label: 'Overage ($)' },
                  { type: 'overageRate', label: 'Overage Rate' },
                  { type: 'balance', label: 'Balance' },
                  { type: 'status', label: 'Status' },
                  { type: 'downloadInvoice' },
                ],
              }}
              noResultMessage="No Data To Show"
            />
          </div>
        </IComplyForm>
      )}
    </>
  );
};
export default BillingSection;
