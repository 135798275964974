import { Menu } from '@material-ui/icons';
import classNames from 'classnames';
import Badge from 'components/Badge';
import DropdownMenu from 'components/DropdownMenu';
import NotificationsModal from 'components/Modal/NotificationsModal';
import useModalHandler from 'hooks/useModalHandler';
import useSelect from 'hooks/useSelect';
import globalLogout from 'modules/globalLogout';
import PropTypes from 'prop-types';
import React, { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { resetStore } from 'store/actions/global.action';
import { selectPersonalInfo, selectUserRoles } from 'store/selectors/auth.selector';
import authEnums from 'utilities/enums/authEnums';
import IconManager from 'utilities/services/IconManager';
import PrivateComponent from '../PrivateComponent';
import ProtectedComponent from '../ProtectedComponent';
import NotificationBell from './components/NotificationBell';
import './styles.scss';

/**
 * Header displayed at the top of the page containing main navigation.
 * @param {func} burgerClick - burger menu handler
 */

const Header = ({ burgerClick }) => {
  const { firstName = 'IComply', lastName = 'User' } = useSelect(selectPersonalInfo) || {};
  const { close: closeNotifications, isOpen: areNotificationsOpen, open: openNotifications } = useModalHandler();
  const modalRef = useRef();
  const dispatch = useDispatch();

  const notificationsModal = NotificationsModal(modalRef, closeNotifications, areNotificationsOpen);

  const list = useMemo(
    () => [
      {
        icon: IconManager.get(IconManager.names.ICOMPLY_HEADER),
        text: 'iComplyKYC',
        asLink: true,
        path: '/kyc',
      },
      {
        icon: IconManager.get(IconManager.names.GEAR_EMPTY),
        text: 'Administration',
        asLink: true,
        path: '/administration',
        guardElement: (
          <ProtectedComponent
            allowedUserTypes={[authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN]}
          />
        ),
      },
      {
        icon: <NotificationBell areNotificationsOpen={areNotificationsOpen} />,
        onClick: () => {
          if (areNotificationsOpen) {
            closeNotifications();
          } else {
            openNotifications();
          }
        },
        modalRef: modalRef,
        modal: notificationsModal,
        className: classNames('notification-button', { 'notification-button--active': areNotificationsOpen }),
        guardElement: (
          <ProtectedComponent
            allowedUserTypes={[
              authEnums.USER_TYPES.FULL_ADMIN,
              authEnums.USER_TYPES.RESTRICTED_ADMIN,
              authEnums.USER_TYPES.CLIENT_USER,
              authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
              authEnums.USER_TYPES.PLATFORM_USER,
            ]}
          />
        ),
      },
      {
        icon: <Badge content={`${firstName[0]}${lastName[0]}`} />,
        text: `Hi, ${firstName} ${lastName}`,
        asLink: true,
        path: '/account-settings',
        items: [
          {
            text: 'Logout',
            onClick: () => {
              dispatch(resetStore());
              globalLogout();
            },
          },
        ],
      },
    ],
    [firstName, lastName, notificationsModal],
  );

  const iComplyTabslist = useMemo(
    () => [
      {
        icon: IconManager.get(IconManager.names.INTERNAL),
        text: 'Internal',
        guardElement: (
          <PrivateComponent
            allowedUserRoles={[
              authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
              authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
              authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
            ]}
            allowedUserTypes={[
              authEnums.ICOMPLY_USERS_TYPES.ICOMPLY_SUPER_ADMIN,
              authEnums.ICOMPLY_USERS_TYPES.ICOMPLY_USER,
              authEnums.ICOMPLY_USERS_TYPES.ICOMPLY_ADMIN,
            ]}
          />
        ),
        asLink: true,
        path: '/internal',
      },
      {
        icon: <Badge content={`${firstName[0]}${lastName[0]}`} />,
        text: `Hi, ${firstName}${lastName}`,
        asLink: true,
        path: '/account-settings',
        items: [
          {
            text: 'Logout',
            onClick: globalLogout,
          },
        ],
      },
    ],
    [firstName, lastName],
  );
  const userRoles = useSelect(selectUserRoles);

  const isIComplyTeam = useMemo(
    () =>
      userRoles.some(role => {
        return (
          role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN ||
          role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN ||
          role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER
        );
      }),
    [userRoles],
  );

  return (
    <nav className="ickyc-header">
      <button onClick={burgerClick}>
        <Menu />
      </button>
      <DropdownMenu list={isIComplyTeam ? iComplyTabslist : list} />
    </nav>
  );
};

Header.propTypes = {
  burgerClick: PropTypes.func.isRequired,
};

export default Header;
