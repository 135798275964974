import CountrySelectField from 'components/CountrySelectField';
import InputField from 'components/InputField';
import Loader from 'components/Loader';
import Section from 'components/Section';
import StateProvinceSelectField from 'components/StateProvinceSelectField';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import setClassSuffix from 'utilities/services/ClassManager';
import validators from 'utilities/services/validators';
import './styles.scss';

const CompanyDetailsFormFields = ({ isLoading }) => {
  const {
    values: { countryId, supportPlan },
  } = useFormState();

  const classBase = 'ickyc-company-details-form-fields';
  const setSufix = setClassSuffix(classBase);

  return (
    <div className={classBase}>
      {isLoading && <Loader />}
      <Section title="Company Name" divider>
        <Field placeholder="Company Name" disabled component={InputField} name="companyName" />
        <Field placeholder="Legal Name" disabled component={InputField} name="legalName" />
      </Section>
      {supportPlan && (
        <Section title="Support Plan" divider>
          <Field disabled component={InputField} name="supportPlan" />
        </Section>
      )}
      <Section title="Address" divider>
        <div className={setSufix('__address')}>
          <Field component={InputField} name="aptSuite" placeholder="Apt/Suite" validate={validators.required()} />
          <Field component={InputField} name="street" placeholder="Street" validate={validators.required()} />
          <Field component={InputField} name="city" placeholder="City" validate={validators.required()} />
          <Field component={InputField} name="postalZip" placeholder="Zip/Postal" validate={validators.required()} />
          <Field
            component={CountrySelectField}
            defaultValue={0}
            name="countryId"
            placeholder="Country"
            autoClose
            validate={validators.required()}
          />
          <Field
            component={StateProvinceSelectField}
            name="stateProvinceId"
            placeholder="State/Prov"
            countryId={countryId}
            validate={validators.required()}
            autoClose
          />
        </div>
      </Section>
      <Section title="Primary Contact" divider>
        <div className={setSufix('__contact')}>
          <Field
            component={InputField}
            name="primaryContactName"
            placeholder="Primary Contact Name"
            validate={validators.required()}
          />
          <Field
            component={InputField}
            name="primaryContactEmail"
            placeholder="Primary Contact Email"
            validate={validators.composeValidators(validators.required(), validators.email('Invalid email address'))}
          />
        </div>
      </Section>
      <Section title="Billing Contact">
        <div className={setSufix('__contact')}>
          <Field
            component={InputField}
            name="billingContactName"
            placeholder="Billing Contact Name"
            validate={validators.required()}
          />
          <Field
            component={InputField}
            name="billingContactEmail"
            placeholder="Billing Contact Email"
            validate={validators.composeValidators(validators.required(), validators.email('Invalid email address'))}
          />
        </div>
      </Section>
    </div>
  );
};

export default CompanyDetailsFormFields;
