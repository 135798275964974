import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import RadioButton from '../RadioButton';
import './styles.scss';

const CustomRadio = ({ children, input, label, required }) => {
  const preventBubbling = event => {
    event.stopPropagation();
  };

  const baseClass = 'ickyc-custom-radio';
  const classes = classNames({
    [baseClass]: true,
    [`${baseClass}--required`]: required,
  });
  return (
    <div className={classes} onClick={preventBubbling}>
      <RadioButton {...input} label={label} />
      {React.cloneElement(children, { disabled: !input.checked })}
    </div>
  );
};

CustomRadio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,

  // Generated by final-form's Field component
  input: PropTypes.object,
};

CustomRadio.defaultProps = {
  name: '',
  value: false,
  onChange: () => {},
  input: null,
  required: false,
};

export default CustomRadio;

export const FieldCustomRadio = props => {
  return <Field component={CustomRadio} {...props} type="radio" />;
};
