import CheckboxTable from 'components/Tables/CheckboxTable';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import socketKeys from 'utilities/enums/socketKeys';
import SignalRService from 'utilities/services/SignalRService';
import api from '../../../../../api';
import DebounceInput from '../../../../../components/DebounceInput';
import Section from '../../../../../components/Section';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import useTable from '../../../../../hooks/useTable';
import TableHeaders from '../../../../../utilities/services/TableHeaders';
import FilterToggleButton from '../../../../Kyc/components/FilterToggleButton';
import SearchControls from '../../../../Kyc/components/SearchControls';
import ReportsFilterModal from './components/ReportsFIlterModal';
import ReportsRow from './components/ReportsRow';
import './styles.scss';

const Reports = () => {
  const location = useLocation();
  const { state } = location;
  const { ids: selectedReports } = state || {};
  const [filterOn, setFilterOn] = useState(false);
  const [filters, setFilters] = useState();
  const { isLoading, records, changeParams, pagination, params, removeRow, updateRowData } = useTable(
    api.administration.getDataPdfReports,
    {
      filters,
      filterOn,
      limit: 10,
    },
  );

  const handleFilterToggle = useCallback(() => {
    setFilterOn(prev => !prev);
  }, []);

  const handleFilterSave = useCallback(vals => {
    setFilters(vals);
    setFilterOn(true);
  }, []);

  const applySearchTerm = useCallback(
    searchString => {
      changeParams({ searchString });
    },
    [changeParams],
  );
  const onRemoveHandler = useCallback(
    id => {
      removeRow('id', id);
    },
    [removeRow],
  );

  const headerData = useMemo(
    () => ({
      sortCriteria: params.sortCriteria,
      sortOrder: params.sortOrder,
      onClick: changeParams,
      items: TableHeaders.ReportsTable,
      withDelete: true,
    }),
    [changeParams, params.sortOrder, params.sortOrder, params.sortCriteria],
  );

  const handleSocketUpdate = useCallback(
    ({ id, status }) => {
      updateRowData('id', id, {
        status: status,
        expiryDate: dayjs(Date.now())
          .add(7, 'day')
          .toString(),
      });
    },
    [updateRowData],
  );

  const tableCotrols = () => {
    return <></>;
  };

  const initialRecords = useMemo(() => {
    return records?.map(report => ({
      ...report,
      checked: report.checked || !!selectedReports?.includes(report?.id),
    }));
  }, [selectedReports, records]);

  useEffect(() => {
    SignalRService.receiveMessage(socketKeys.REPORT_READY, handleSocketUpdate);
  }, [handleSocketUpdate]);
  return (
    <Section title="">
      <SearchControls>
        <div className="ickyc-search-controls__info">
          <span>{pagination.total > 1 ? `${pagination.total || 0}  Results` : `${pagination.total || 0} Result`}</span>
        </div>
        <div className="ickyc-search-controls__filter-section">
          <DebounceInput initialValue={params.searchString} placeholder="Search Reports" onChange={applySearchTerm} />
          <FilterToggleButton filters={filters} filterOn={filterOn} onToggleFilter={handleFilterToggle}>
            <ReportsFilterModal onFilterSave={handleFilterSave} />
          </FilterToggleButton>
        </div>
      </SearchControls>
      <CheckboxTable
        values={initialRecords}
        pagination={pagination}
        tableRow={ReportsRow}
        headerRow={TableHeader}
        className="ickyc-reports-table"
        withPagination
        withLimitChange
        onRemove={onRemoveHandler}
        updating={isLoading}
        updateRowData={updateRowData}
        handleParamsChange={changeParams}
        headerData={headerData}
        disabled={false}
        TableControlsComponent={tableCotrols}
      />
    </Section>
  );
};
export default Reports;
