import CloseButton from 'components/Buttons/CloseButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import CheckboxField from 'components/CheckboxField';
import InputField from 'components/InputField';
import ToggleField from 'components/ToggleField';
import React, { useCallback } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import validators from 'utilities/services/validators';
import './styles.scss';

const MultipleChoice = () => {
  const { mutators } = useForm();

  const {
    meta: { touched, error },
  } = useField('parameters.items');

  const addAnswer = useCallback(() => {
    mutators.push(`parameters.items`);
  }, [mutators]);

  const removeAnswer = useCallback(
    index => {
      mutators.remove(`parameters.items`, index);
    },
    [mutators],
  );

  return (
    <div className="ickyc-multiple-choice">
      <div className="ickyc-custom-document-options">
        <span className="ickyc-custom-document-options__label">Question ID</span>
        <span>
          <Field name="id" component={InputField} preview></Field>
        </span>
      </div>
      <div className="ickyc-multiple-choice__container">
        <div className="ickyc-multiple-choice__button-container">
          {error && touched && <span className="ickyc-discrete-choice__error">{error}</span>}
          <PrimaryButton onClick={addAnswer}>+ Add Answer</PrimaryButton>
        </div>
        <FieldArray
          name="parameters.items"
          validate={validators.required('Please note that at least one answer is required')}
        >
          {({ fields }) => {
            return fields.map((name, index) => (
              <div key={name} className="ickyc-multiple-choice__item">
                <Field component={InputField} name={`${name}.acceptedAnswer`} placeholder="Please set answer" />
                <div className="ickyc-multiple-choice__checkbox">
                  <Field component={CheckboxField} type="checkbox" name={`${name}preSelected`} label="Pre-Selected" />
                </div>
                <div className="ickyc-multiple-choice__close-button">
                  <CloseButton
                    onClick={e => {
                      e.stopPropagation();
                      removeAnswer(index);
                    }}
                  />
                </div>
              </div>
            ));
          }}
        </FieldArray>
        <div className="ickyc-multiple-choice__allow-other">
          <Field
            component={ToggleField}
            name="parameters.allowOther"
            label={`Allow User to Select "Other: " With Text Field to Clarify`}
          />
        </div>
      </div>
    </div>
  );
};
export default MultipleChoice;
