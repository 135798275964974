import DescriptionIcon from '@material-ui/icons/Description';
import PropTypes from 'prop-types';
import React from 'react';
import CloseButton from '../../Buttons/CloseButton';
import './styles.scss';

const CSVPlaceholder = ({ text, withRemove, onRemove }) => {
  const baseClass = 'ickyc-csv-placeholder';

  return (
    <div className={baseClass}>
      {withRemove && <CloseButton onClick={onRemove} />}
      <DescriptionIcon />
      <span>{text}</span>
    </div>
  );
};

export default CSVPlaceholder;

CSVPlaceholder.propTypes = {
  text: PropTypes.string,
  withRemove: PropTypes.bool,
  onRemove: PropTypes.func,
};
CSVPlaceholder.defaultProps = {
  text: '',
  withRemove: false,
  onRemove: () => {},
};
