import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { getBase64Strings } from 'exif-rotate-js';
import heic2any from 'heic2any';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import CameraIcon from '../../../../../assets/CameraIcon';
import Spinner from '../../../../../components/Spinner';
import './styles.scss';

/**
 * Component is used for uploading files (images) on which will OpenCV process (document Detection, MRZ Detection, etc.)
 * It provides original file, image preview as blobURL and data (detection data)
 *
 * @param {Number} maxFiles - number of file user is allowed to upload
 * @param {String} accept - file types user is allowed to upload
 * @param {Node} children - file preview component
 * @param {Boolean} exifCorrection -
 * @returns
 */
const DocumentDropzone = ({
  maxFiles,
  accept = '*',
  input: { onChange, value },
  meta,
  children,
  exifCorrection,
  title,
}) => {
  const baseClass = 'ickyc-document-detection-dropzone';
  const [uploading, setUploading] = useState(false);
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    accept,
    maxFiles,
    multiple: false,
    onDrop: async acceptedFiles => {
      setUploading(true);
      try {
        const tmp = await heic2any({ blob: acceptedFiles[0], toType: 'image/jpeg', quality: 0.5 });
        const blob = new Blob([tmp], { type: 'image/jpeg' });
        acceptedFiles[0] = new File([blob], acceptedFiles[0]?.name?.replace('.heic', '.jpeg'));
      } catch (e) {
        console.log(e);
      }
      if (value?.preview) {
        URL.revokeObjectURL(value.preview);
      }
      if (exifCorrection) {
        const image = new Image();
        const data = await getBase64Strings([acceptedFiles[0]], { maxSize: 3096 });
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(
            blob => {
              onChange({ file: acceptedFiles[0], preview: URL.createObjectURL(blob) });
            },
            acceptedFiles[0].type,
            0.92,
          );
        };
        [image.src] = data;
      } else {
        onChange({ file: acceptedFiles[0], preview: URL.createObjectURL(acceptedFiles[0]) });
      }
      setUploading(false);
    },
  });
  const classes = classNames({
    [baseClass]: true,
    [`${baseClass}--accept`]: isDragAccept,
    [`${baseClass}--reject`]: isDragReject,
  });

  const renderChild = () => {
    if (uploading) {
      return <Spinner />;
    }
    const { error, touched } = meta;
    if (value) {
      return React.cloneElement(children, {
        value,
        onChange,
        uploadHandlers: getRootProps,
        error: touched && error,
      });
    }
  };
  return (
    <div className={classes}>
      <input {...getInputProps()} />
      {!value ? (
        <div {...getRootProps()} className={`${baseClass}--normal`}>
          <p className={`${baseClass}--normal__instructions`}>
            {uploading ? (
              <Spinner />
            ) : (
              <>
                <span className={`${baseClass}--normal__instructions__upload-text`}>{title}</span>
                <IconButton transparent>
                  <CameraIcon />
                </IconButton>
                <span className={`${baseClass}--normal__instructions__upload-label`}>Upload Photo</span>
              </>
            )}
          </p>
        </div>
      ) : (
        renderChild()
      )}
    </div>
  );
};
DocumentDropzone.propTypes = {
  maxFiles: PropTypes.number,
  accept: PropTypes.string,
  input: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  exifCorrection: PropTypes.bool,
  meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool }).isRequired,
  title: PropTypes.string,
};
DocumentDropzone.defaultProps = {
  maxFiles: 1,
  accept: '*',
  input: {},
  exifCorrection: false,
  title: 'Selfie',
};
export default DocumentDropzone;
