import { useEffect, useRef } from 'react';

/**
 *
 * @param {Function} effectCallback
 * @param {Array<any>} deps
 */
export default function useEffectSkipFirst(effectCallback, deps = []) {
  const first = useRef(true);

  useEffect(() => {
    if (first.current) {
      first.current = false;
      return;
    }

    effectCallback();
  }, deps);
}
