import FilterFormsService from '../../../utilities/services/FilterFormsService';
import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/business-manager';

const ENDPOINTS = {
  getUser: userId => `${BASE_ROUTE}/internal-users/${userId}`,
  getAllUsers: () => `${BASE_ROUTE}/internal-users/all`,
  updateUser: userId => `${BASE_ROUTE}/internal-users/${userId}`,
  deleteUser: (userId, forceRequest) => `${BASE_ROUTE}/internal-users/${userId}?forceRequest=${forceRequest}`,
  createUser: () => `${BASE_ROUTE}/internal-users`,
  downloadCSV: () => `${BASE_ROUTE}/internal-users/csv`,
  inviteUser: userId => `${BASE_ROUTE}/internal-users/${userId}/invite`,
};

const getAllUsers = data => {
  const { filters, filterOn, ...params } = data ?? {};
  const transformedFilter = FilterFormsService.normalizeUserManagementFormData(filters, filterOn);

  return axiosInstance.post(ENDPOINTS.getAllUsers(), { ...transformedFilter }, { params });
};

const getUser = userId => axiosInstance.get(ENDPOINTS.getUser(userId));

const updateUser = (userId, data) => axiosInstance.put(ENDPOINTS.updateUser(userId), data);

const createUser = data => {
  return axiosInstance.post(ENDPOINTS.createUser(), data);
};

const deleteUser = (userId, forceRequest) => axiosInstance.delete(ENDPOINTS.deleteUser(userId, forceRequest));

const downloadCSV = data => axiosInstance.post(ENDPOINTS.downloadCSV(), {}, data);
const inviteUser = userId => axiosInstance.post(ENDPOINTS.inviteUser(userId), {});
export default {
  getAllUsers,
  getUser,
  updateUser,
  createUser,
  deleteUser,
  downloadCSV,
  inviteUser,
};
