import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/business-manager/clients';

const ENDPOINTS = {
  getDataApiKeys: clientId => `${BASE_ROUTE}/${clientId}/settings/api-keys`,
  createNewApiKey: clientId => `${BASE_ROUTE}/${clientId}/settings/api-keys`,
  deleteApiKey: (clientId, id) => `${BASE_ROUTE}/${clientId}/settings/api-keys/${id}`,
};
const getDataApiKeys = clientId => params => axiosInstance.get(ENDPOINTS.getDataApiKeys(clientId), { params });
const createNewApiKey = (clientId, data) => axiosInstance.post(ENDPOINTS.createNewApiKey(clientId), data);
const deleteApiKey = (clientId, id) => axiosInstance.delete(ENDPOINTS.deleteApiKey(clientId, id));

export default {
  getDataApiKeys,
  createNewApiKey,
  deleteApiKey,
};
