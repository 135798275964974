export default {
  listeners: {},

  addEventListener(event, callback) {
    if (!this.listeners[event]) {
      this.listeners[event] = [];
    }

    this.listeners[event].push(callback);

    return () => {
      this.removeEventListener(event, callback);
    };
  },

  removeEventListener(event, callback) {
    this.listeners[event] = this.listeners[event].filter(cb => cb !== callback);
  },

  broadcastEvent(event, payload = {}) {
    (this.listeners[event] || []).forEach(cb => cb(payload));
  },
};
