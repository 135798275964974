/**
 * Stacking Manager is used to control stakcing order on the app-level.
 */
export default {
  // Modal hiding functions
  modals: [],

  // Dropdow hiding functions
  dropdowns: [],

  // Boolean order array, true for modal, false for dropdown
  order: [],

  push(fn, isModal = true) {
    if (isModal) {
      this.modals.push(fn);
    } else {
      this.dropdowns.push(fn);
    }
    this.order.push(isModal);
  },

  pop(isModal = true) {
    this.order.pop();
    return isModal ? this.modals.pop() : this.dropdowns.pop();
  },

  clickPop(isModal = true) {
    return this.pop(isModal);
  },

  escPop() {
    const { length, [length - 1]: last } = this.order;
    return this.pop(last);
  },
};
