import { CircularProgress } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { SelectContext } from '../..';
import TagButton from '../../../Buttons/TagButton';
import './styles.scss';

const SelectTrigger = ({ placeholder, withoutArrow, TriggerComponent, ...rest }) => {
  const selectedContext = useContext(SelectContext);
  const { value, multipleSelection, preview, toggleView, loading, removeFromMultipleSelection } = selectedContext;

  const renderValues = useCallback(() => {
    if (!multipleSelection) {
      return TriggerComponent ? <TriggerComponent {...value} {...rest} /> : value?.label;
    }
    return value.map(val => (
      <TagButton
        text={TriggerComponent ? <TriggerComponent {...val} {...rest} /> : val?.label}
        key={val.id}
        preview={preview}
        onIconClick={e => {
          removeFromMultipleSelection(val, e);
        }}
      />
    ));
  }, [multipleSelection, value, TriggerComponent, removeFromMultipleSelection]);

  const noValue = useMemo(() => {
    if (multipleSelection && value.length === 0) {
      return true;
    }
    if (JSON.stringify(value) === '{}') {
      return true;
    }
  }, [value, multipleSelection]);

  const baseClass = 'icyc-select-trigger';
  const contentClasses = classNames({
    [`${baseClass}__content`]: true,
    [`${baseClass}__content--${multipleSelection ? 'multiple' : 'single'}`]: true,
    [`${baseClass}__content--disabled`]: loading,
  });
  return (
    <div className={baseClass} onClick={toggleView}>
      <div className={contentClasses}>
        {noValue ? <span className="ickyc-placeholder">{!preview && placeholder}</span> : renderValues()}
      </div>
      {!preview && !withoutArrow && (
        <span className={`${baseClass}__svg`}>{loading ? <CircularProgress /> : <ArrowDropDown />}</span>
      )}
    </div>
  );
};
SelectTrigger.propTypes = {
  placeholder: PropTypes.string,
  TriggerComponent: PropTypes.any,
  withoutArrow: PropTypes.bool,
};
SelectTrigger.defaultProps = {
  placeholder: '',
  TriggerComponent: undefined,
  withoutArrow: false,
};
export default SelectTrigger;
