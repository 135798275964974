import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import Section from '../../../../../../../../../components/Section';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import licenceEnums from '../../../../../../../../../utilities/enums/licenceEnums';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import validators from '../../../../../../../../../utilities/services/validators';
import './styles.scss';

const OverageNumOfPortals = ({ namePreffix, disabled }) => {
  const classBase = 'ickyc-bl-overage-num-of-portals';
  const setSufix = setClassSuffix(classBase);

  return (
    <>
      <Section className={classBase}>
        <h3>Overage</h3>
        <Field
          label="Overage Rate"
          name={`${namePreffix}.defaultValues.overageRate`}
          id={`${namePreffix}.defaultValues.overageRate`}
          placeholder=". . ."
          preffix="%"
          disabled={disabled}
          type="number"
          component={InputField}
          validate={validators.required()}
        />
        <Field
          label="Overage Billing Cycle"
          name={`${namePreffix}.defaultValues.overageBillingCycle`}
          id={`${namePreffix}.defaultValues.overageBillingCycle`}
          options={licenceEnums.BILLING_CYCLE_OPTIONS}
          disabled={disabled}
          component={SelectField}
          Trigger={<SelectTrigger placeholder="Choose" />}
          Content={<SelectFilterContent />}
          renderAsPortal
        />
      </Section>
      <Section className={classBase}>
        <h3 className={setSufix('__num-of-portals-title')}>Number of Portals</h3>
        <Field
          label="NP Portals"
          component={InputField}
          disabled={disabled}
          name={`${namePreffix}.defaultValues.npPortals`}
          id={`${namePreffix}.defaultValues.npPortals`}
          placeholder=""
          validate={validators.required()}
        />
        <Field
          label="LE Portals"
          component={InputField}
          disabled={disabled}
          name={`${namePreffix}.defaultValues.lePortals`}
          id={`${namePreffix}.defaultValues.lePortals`}
          placeholder=""
          validate={validators.required()}
        />
      </Section>
    </>
  );
};

OverageNumOfPortals.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};
OverageNumOfPortals.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default OverageNumOfPortals;
