import { CircularProgress } from '@material-ui/core';
import api from 'api';
import classNames from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DebounceInput from 'components/DebounceInput';
import BareHeading from 'components/Headings/BareHeading';
import Loader from 'components/Loader';
import FormModal from 'components/Modal/FormModal';
import Section from 'components/Section';
import Table from 'components/Tables/Table';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import { FORM_ERROR } from 'final-form';
import useTable from 'hooks/useTable';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import toastr from 'toastr';
import utilities from 'utilities';
import routesEnum from 'utilities/enums/routesEnum';
import { PermissionGroupContext } from 'utilities/services/contexts';
import SupportingDocumentTypeForm from './components/SupportingDocumentTypeForm';
import SupportingDocumentsTableRow from './components/SupportingDocumentsTableRow';
import './styles.scss';

const SupportingDocumentsPage = ({ className }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [initialModalData, setInitialModalData] = useState({});

  const pageRef = useRef(null);

  const history = useHistory();

  const { pagination, params, records, removeRow, isLoading, addRow, changeParams } = useTable(
    api.administration.getSupportingDocumentsPackages,
    { sortOrder: 1, sortCriteria: 'dateCreated', skip: 0, limit: 5 },
  );

  const { edit: canEdit } = useContext(PermissionGroupContext);

  const handleRowRemove = itemId => {
    removeRow('id', itemId);
  };

  const handleSubmit = async data => {
    try {
      const { data: supDoc } = await api.administration.createSupportingDocumentPackage(data);

      toastr.success('Successfully Created New Supporting Document Package');
      addRow(supDoc);

      return history.push(
        utilities.routeParams(routesEnum.administration.SUPPORTING_PACKAGE_DETAILS, {
          id: supDoc?.id,
        }),
      );
    } catch (err) {
      if (err?.response) {
        const { status, data: errorData } = err.response;
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: errorData.message || errorData.errors };
        }
        if (status === 500) {
          return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
        }
      } else return { [FORM_ERROR]: 'Error occured' };
    }
  };

  const handleSearch = useCallback(
    searchString => {
      changeParams({ searchString });
    },
    [changeParams],
  );

  const handleListItemClick = id => {
    if (!canEdit) {
      return;
    }
    history.push(
      utilities.routeParams(routesEnum.administration.SUPPORTING_PACKAGE_DETAILS, {
        id,
      }),
    );
  };

  const classes = classNames(className, 'ickyc-page', 'ickyc-supporting-documents');

  const CreateNewSuppDocsSubmitButtons = ({ disabled, onSubmit, showSpinner }) => {
    return (
      <div className="ickyc-new-supp-doc-submit-buttons">
        <PrimaryButton disabled={disabled} type="submit" onClick={onSubmit}>
          Submit
          {showSpinner && <CircularProgress />}
        </PrimaryButton>
      </div>
    );
  };

  const headerData = {
    sortCriteria: params.sortCriteria,
    sortOrder: params.sortOrder,
    onClick: changeParams,
    items: [
      { type: 'header', label: 'Name', clickable: true },
      { type: 'entityType', label: 'Type', clickable: true },
      { type: 'dateCreated', label: 'Date Created', clickable: true },
    ],
    withDelete: true,
  };

  return (
    <div className={classes}>
      {isLoading && <Loader />}
      <>
        <BareHeading title="Supporting Document Packages" />

        <div>
          <div>
            <p>Define Document Packages that you will be able to use in your Portal setup and manual upload.</p>
          </div>
          <Section className="ickyc-supporting-documents-section">
            <h2>Document Types</h2>
            <div className="ickyc-section__subheader">
              <div className="ickyc-search-controls__info">
                <span>
                  {pagination.total > 1 ? `${pagination.total || 0}  Results` : `${pagination.total || 0} Result`}
                </span>
              </div>
              <div className="ickyc-search-controls__filter-section">
                <DebounceInput
                  initialValue={params.searchString}
                  placeholder="Search Documents"
                  onChange={handleSearch}
                />
                <PrimaryButton
                  onClick={() => {
                    setInitialModalData({ fileFormats: [], isExisting: '1' });
                    setModalOpen(true);
                  }}
                  disabled={!canEdit}
                >
                  + Add Supporting Document Package
                </PrimaryButton>
              </div>
            </div>
            {modalOpen && (
              <FormModal
                className="ickyc-supporting-documents-modal"
                initialValues={initialModalData}
                onSubmit={handleSubmit}
                containerRef={pageRef}
                submitButtons={CreateNewSuppDocsSubmitButtons}
                hideModal={() => setModalOpen(false)}
                title={<>Add Supporting Document Package</>}
                showErrorsInConfirmation
              >
                <SupportingDocumentTypeForm />
              </FormModal>
            )}
            <Table
              className="ickyc-supporting-documents-table"
              values={records}
              onClick={handleListItemClick}
              onRemove={handleRowRemove}
              pagination={pagination}
              headerData={headerData}
              tableRow={SupportingDocumentsTableRow}
              headerRow={TableHeader}
              handleParamsChange={changeParams}
              withPagination
              withRemove
            />
          </Section>
        </div>
      </>
    </div>
  );
};

SupportingDocumentsPage.propTypes = {
  className: PropTypes.string,
};

SupportingDocumentsPage.defaultProps = {
  className: undefined,
};

export default SupportingDocumentsPage;
