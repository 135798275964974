import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import Section from '../../../../../../../../../components/Section';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import validators from '../../../../../../../../../utilities/services/validators';
import './styles.scss';

const CustomUrlsRow = ({ namePreffix, disabled }) => {
  const classBase = 'ickyc-bl-custom-urls-row';
  const setSufix = setClassSuffix(classBase);

  return (
    <Section className={classBase} divider>
      <h3 className={setSufix('__urls-title')}>Number of Custom URLs</h3>
      <Field
        label="NP URLs"
        component={InputField}
        disabled={disabled}
        name={`${namePreffix}.defaultValues.npCustomUrls`}
        id={`${namePreffix}.defaultValues.npCustomUrls`}
        placeholder=""
        validate={validators.required()}
      />
      <Field
        label="LE URLs"
        component={InputField}
        disabled={disabled}
        name={`${namePreffix}.defaultValues.leCustomUrls`}
        id={`${namePreffix}.defaultValues.leCustomUrls`}
        placeholder=""
        validate={validators.required()}
      />
    </Section>
  );
};

CustomUrlsRow.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};
CustomUrlsRow.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default CustomUrlsRow;
