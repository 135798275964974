import api from 'api';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import enums from 'utilities/enums';

export default (packages, showBothEnityTypes = false) => {
  const [clientPackages, setClientPackages] = useState(packages);
  const [documentPackageOptions, setDocumentPackageOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const { clientId } = useParams();
  const { pathname } = useLocation();

  const filterDocumentPackagesBasedOnEntityType = useCallback(
    packages => {
      const pathnameEntityType = pathname?.includes('legal-entity-portal')
        ? enums.ENTITY_TYPE_NAMES.LEGAL_ENTITY
        : enums.ENTITY_TYPE_NAMES.NATURAL_PERSON;

      return packages?.filter(documentPackage => documentPackage?.entityType === pathnameEntityType);
    },
    [pathname],
  );

  const getProperDocumentPackages = useCallback(
    packages => {
      return showBothEnityTypes ? packages : filterDocumentPackagesBasedOnEntityType(packages);
    },
    [showBothEnityTypes, filterDocumentPackagesBasedOnEntityType],
  );

  const parsePackagesToOptions = useCallback(
    packages => {
      return (
        getProperDocumentPackages(packages)?.map(document => {
          return {
            label: document?.header,
            value: document?.id,
          };
        }) || []
      );
    },
    [getProperDocumentPackages],
  );

  useEffect(() => {
    if (clientId) {
      setIsFetching(true);

      const fetchClientPackages = async () => {
        try {
          const { data } = await api.businessManager.getSupportingDocumentPackages(clientId);
          setClientPackages(data);
          setDocumentPackageOptions(parsePackagesToOptions(data));
        } catch (err) {
          console.error(err);
        } finally {
          setIsFetching(false);
        }
      };

      fetchClientPackages();
    } else {
      setDocumentPackageOptions(parsePackagesToOptions(packages));
    }
  }, [packages, clientId]);

  return { documentPackageOptions, clientPackages, isFetching };
};
