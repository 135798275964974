import React from 'react';

export default () => (
  <svg
    width="1198.000000pt"
    height="1294.000000pt"
    viewBox="0 0 1198.000000 1294.000000"
    preserveAspectRatio="xMidYMid meet"
    fill="currentColor"
  >
    <g transform="translate(0.000000,1294.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M5171 11795 c-110 -24 -226 -89 -311 -175 -119 -119 -181 -258 -196
-436 l-7 -84 -111 0 c-99 0 -159 -9 -546 -85 -239 -47 -448 -88 -465 -91 -48
-10 -98 -42 -125 -81 -24 -35 -25 -43 -28 -230 l-4 -193 -64 0 c-244 0 -471
-143 -579 -365 -60 -124 -63 -155 -66 -620 -3 -468 -3 -466 68 -615 28 -59 55
-94 122 -161 128 -127 246 -179 436 -195 l85 -7 0 -151 c0 -173 9 -215 53
-261 44 -46 989 -762 1022 -775 15 -6 132 -10 281 -10 l254 0 -2 -266 -3 -266
-105 -47 c-187 -83 -371 -202 -518 -334 l-63 -57 -503 0 c-551 0 -593 -3 -776
-60 -214 -66 -400 -180 -565 -345 -182 -181 -282 -358 -343 -605 -15 -58 -17
-251 -20 -1885 -1 -1001 0 -1839 3 -1863 7 -50 53 -108 102 -128 27 -12 634
-14 3648 -14 3582 0 3616 0 3655 20 21 11 49 34 62 52 l23 33 3 1830 c2 1348
0 1850 -9 1905 -16 104 -53 219 -105 323 -171 345 -495 599 -898 704 l-101 27
-544 4 -545 3 -85 75 c-151 132 -380 272 -568 347 l-43 17 0 260 0 260 253 1
c169 0 263 4 285 12 18 7 262 182 542 391 444 330 512 384 530 420 18 37 20
62 20 208 l0 165 93 6 c176 12 298 67 422 192 84 84 132 160 168 269 19 57 21
91 24 480 5 478 2 509 -70 656 -33 67 -58 102 -122 165 -123 123 -246 178
-427 192 l-88 6 0 180 c0 206 -11 247 -76 288 -26 15 -170 50 -506 120 -443
93 -478 99 -589 99 l-119 0 0 73 c0 239 -144 463 -365 571 -135 65 -146 66
-822 65 -479 0 -622 -4 -672 -14z m1346 -362 c96 -52 161 -155 171 -269 l5
-64 -842 0 -841 0 0 48 c0 121 65 228 172 281 l62 32 616 -3 615 -3 42 -22z
m1047 -749 c198 -41 371 -78 384 -81 l22 -5 0 -1182 0 -1181 -428 -318 -427
-318 -1269 3 -1269 3 -421 320 -421 320 -3 1182 -2 1182 32 6 c18 4 188 37
378 75 l345 68 1359 1 1359 1 361 -76z m-4186 -1243 l2 -634 -72 5 c-123 8
-220 75 -272 187 -20 45 -21 59 -21 441 l0 395 26 56 c55 117 172 191 294 186
l40 -2 3 -634z m5129 591 c66 -34 109 -78 143 -144 l30 -61 0 -396 0 -396 -29
-60 c-52 -110 -152 -175 -268 -175 l-63 0 0 631 0 632 73 -5 c46 -3 87 -12
114 -26z m-2454 -2781 l297 -1 0 -347 c0 -384 -5 -429 -61 -533 -53 -99 -151
-184 -257 -223 -70 -25 -233 -34 -315 -17 -172 35 -318 174 -363 345 -10 37
-14 141 -14 411 0 198 3 364 7 368 4 4 97 5 208 2 110 -2 334 -5 498 -5z
m-1012 -928 c45 -139 165 -306 279 -386 l25 -18 -40 -31 c-114 -89 -224 -227
-295 -368 -18 -36 -34 -67 -35 -68 -2 -2 -50 20 -107 49 -90 46 -116 66 -209
158 -92 93 -185 213 -205 264 -9 23 175 205 284 281 83 58 257 155 279 156 6
0 17 -17 24 -37z m1741 -16 c53 -30 133 -80 178 -113 96 -69 270 -239 270
-263 0 -24 -92 -156 -169 -241 -83 -93 -220 -190 -327 -230 -22 -9 -26 -5 -55
53 -74 146 -166 265 -270 349 -35 28 -64 52 -64 54 0 2 26 25 57 51 102 85
200 229 246 363 6 16 17 30 24 30 7 0 57 -24 110 -53z m-2932 -364 c0 -5 -30
-42 -67 -83 -36 -41 -166 -188 -289 -326 -123 -139 -232 -267 -243 -285 -26
-43 -27 -113 -3 -159 10 -19 97 -149 194 -288 l177 -253 -63 -107 c-34 -59
-89 -152 -121 -207 -53 -89 -59 -106 -59 -155 0 -48 6 -67 53 -150 173 -307
388 -585 643 -836 469 -462 1077 -801 1574 -880 430 -67 976 116 1535 517 436
312 779 692 1060 1174 61 104 64 113 64 175 l0 65 -118 199 c-65 110 -116 204
-114 210 3 6 88 131 190 278 119 172 187 280 191 302 9 47 -8 105 -42 148 -26
33 -227 260 -464 525 -65 72 -118 134 -118 138 0 4 127 5 283 2 246 -4 293 -7
366 -26 369 -95 652 -350 748 -676 17 -56 18 -168 21 -1782 l2 -1723 -3405 0
-3405 0 0 1708 c0 1680 0 1710 20 1788 62 242 234 451 480 582 123 66 268 113
390 125 70 7 520 7 520 0z m2075 -208 c111 -41 226 -106 284 -160 l55 -52 -94
-172 -94 -171 -234 2 -235 3 -93 168 -93 168 43 43 c23 24 71 60 107 81 79 47
236 114 266 115 13 0 52 -11 88 -25z m-1826 -140 c1 -66 48 -459 76 -640 92
-592 213 -967 444 -1379 140 -250 347 -544 539 -766 50 -57 89 -106 88 -108
-7 -7 -235 113 -348 184 -323 200 -617 459 -840 738 -88 110 -245 335 -288
413 l-23 42 122 209 c67 116 125 226 128 245 3 19 0 51 -7 71 -6 19 -90 149
-186 288 -96 139 -173 258 -172 264 2 7 104 126 227 265 240 270 239 269 240
174z m3723 -170 c117 -133 219 -248 226 -257 12 -15 -8 -48 -156 -260 -93
-134 -177 -258 -186 -275 -9 -18 -16 -54 -16 -80 0 -44 12 -69 125 -263 l125
-213 -61 -99 c-282 -456 -661 -832 -1126 -1114 -112 -68 -295 -164 -313 -164
-6 0 28 44 75 98 406 464 717 1009 859 1507 89 313 179 853 212 1278 3 48 10
87 15 85 4 -2 104 -111 221 -243z m-3234 -159 c121 -80 279 -152 409 -186 97
-25 142 -18 199 32 41 36 35 40 90 -67 l36 -70 -101 -655 c-124 -800 -127
-819 -133 -825 -15 -15 -208 316 -293 502 -106 231 -173 450 -234 758 -30 156
-93 585 -85 585 1 0 51 -33 112 -74z m2618 17 c-30 -257 -87 -576 -137 -775
-80 -320 -202 -600 -399 -915 -60 -96 -80 -119 -80 -92 0 10 -164 1069 -209
1354 l-20 120 40 75 c49 91 57 100 70 78 19 -33 79 -61 132 -61 64 0 232 55
348 114 84 42 222 130 234 149 3 5 11 10 17 10 7 0 8 -20 4 -57z m-1145 -623
c15 -96 53 -339 84 -540 31 -201 78 -507 106 -681 27 -174 49 -325 49 -335 0
-11 -37 -62 -82 -114 -110 -128 -359 -380 -374 -380 -16 0 -236 221 -363 364
l-101 114 60 388 c34 214 87 560 120 769 32 209 66 428 76 488 l17 107 190 -2
191 -3 27 -175z"
      />
      <path
        d="M4722 10411 c-124 -31 -215 -84 -312 -181 -139 -139 -200 -285 -200
-479 0 -120 19 -202 72 -310 55 -114 167 -228 283 -288 153 -79 133 -76 675
-83 267 -4 753 -6 1080 -5 587 1 596 2 670 24 127 38 206 86 300 180 132 134
188 258 197 441 6 132 -11 221 -68 339 -35 72 -56 101 -133 176 -97 96 -165
137 -295 178 -65 21 -80 21 -1131 24 -1002 2 -1069 1 -1138 -16z m2220 -358
c207 -95 265 -359 117 -527 -44 -50 -93 -81 -165 -102 -55 -16 -135 -17 -1064
-11 -993 6 -1006 6 -1062 27 -75 28 -149 98 -185 175 -80 174 11 385 196 451
29 11 243 13 1076 11 l1040 -2 47 -22z"
      />
      <path
        d="M4588 8740 c-42 -13 -87 -48 -105 -83 -22 -43 -21 -129 2 -164 10
-15 106 -130 213 -255 140 -163 205 -231 231 -242 52 -22 873 -216 916 -216
37 0 870 192 918 211 26 11 399 426 439 489 9 14 20 42 23 63 15 78 -53 180
-127 191 -59 9 -2482 15 -2510 6z m2072 -351 c0 -5 -17 -28 -37 -49 l-38 -40
-369 -86 -369 -86 -374 89 -374 88 -40 48 -41 48 821 -1 c518 -1 821 -5 821
-11z"
      />
    </g>
  </svg>
);
