import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

/* eslint-disable */

const IComplyLogo = (props) => (
  <SvgIcon {...props}>
    <path
      d="M128.2,0.2c-17.4,0-31.5,14.1-31.5,31.5c0,15.3,10.9,28.1,25.4,30.9v-0.1v0V46.9v-4.6
		                                            c-3.7-2.1-6.2-6.1-6.2-10.6c0-6.8,5.5-12.3,12.3-12.3c6.8,0,12.3,5.5,12.3,12.3c0,4.5-2.5,8.5-6.1,10.6v4.6v15.6v0.1
		                                            c14.5-2.9,25.4-15.6,25.4-30.9C159.8,14.3,145.6,0.2,128.2,0.2z"
    />
    <path
      d="M7.9,0.9c4.3,0,7.9,3.5,7.9,7.9c0,4.3-3.6,7.9-7.9,7.9C3.5,16.7,0,13.1,0,8.8C0,4.4,3.5,0.9,7.9,0.9z
		                                                M1.2,62.1V22.4h13.5v39.7H1.2z"
    />
    <path
      d="M88.3,41.3c-4.1,12.6-16,21.7-29.9,21.7c-17.4,0-31.5-14.1-31.5-31.5C26.8,14.1,41,0,58.3,0
		                                            C72.2,0,84,9,88.2,21.5h-15c-2.5-5.5-8.6-8.9-14.8-8.9c-9.9,0-17.8,8.5-17.8,18.9c0,10.4,7.9,18.9,17.8,18.9
		                                            c6.3,0,12.4-3.7,14.9-9.1H88.3z"
    />
    <path d="M232.7,62.1l-7.2-42.2l-12,42.2h-13.2l-12-42.2l-7.2,42.2h-14.8l11.9-61.2h16L207,43.8l12.7-42.9h16l11.9,61.2 H232.7z" />
    <path
      d="M272.1,62.1h-13.5V0.9h10.2h3.3h9.4c9.9,0,18.7,8,18.7,19.6c0,12.4-8.8,19.7-18.7,19.7h-9.4V62.1z M272.1,11.5
		                                            v18.1h7.2c4,0,8.1-3.2,8.1-9.1c0-5.6-4.1-9-8.1-9H272.1z"
    />
    <path d="M310.5,0.9H324v48.9h21.9v12.2h-35.4V0.9z" />
    <path d="M365,32.8L342.5,0.9h16.1L371.8,22L385,0.9h16.1l-22.6,31.9v29.2H365V32.8z" />
  </SvgIcon>
);

const IComplySVGWhite = (props) => (
  <IComplyLogo
    {...props}
    className="icomply-logo-img"
    htmlColor="white"
    fontSize="inherit"
    component={(svgProps) => <svg {...svgProps} />}
  />
);

export default IComplySVGWhite;
