import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import toastr from 'toastr';
import DateManager from 'utilities/services/DateManager';
import api from '../../../../../api';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import FormModal from '../../../../../components/Modal/FormModal';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import { PermissionGroupContext } from '../../../../../utilities/services/contexts';
import './styles.scss';

const SupportingDocumentsTableRow = ({
  rowInfo: { id, header, entityType, dateCreated },
  className,
  onClick,
  onRemove,
}) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [forceDelete, setForceDelete] = useState(false);

  const handleOnClick = useCallback(
    event => {
      event.stopPropagation();
      onClick(id);
    },
    [id, onClick],
  );

  const deleteSupportingDocument = () => {
    return api.administration
      .deleteSupportingDocumentPackage(id, forceDelete)
      .then(() => {
        toastr.success('Supporting Document type was deleted');
        onRemove(id);
        setConfirmModalOpen(false);
      })
      .catch(err => {
        const { status: resStatus, data } = err.response;
        if (resStatus >= 400 && resStatus < 500) {
          if (resStatus === 409) {
            setForceDelete(true);
          } else {
            setForceDelete(false);
          }
          return { [FORM_ERROR]: Array.isArray(data.errors) ? data.errors.join('') : data.errors };
        }
        return { [FORM_ERROR]: 'Error Occured While Trying to Delete Supporting Document Type' };
      });
  };
  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const { edit: canEdit } = useContext(PermissionGroupContext);
  return (
    <tr key={id} className={`${className}__row`}>
      <td onClick={handleOnClick} className={setColumnSuffix('__header')}>
        {header}
      </td>
      <td onClick={handleOnClick} className={setColumnSuffix('__entityType')}>
        {entityType ? 'Legal Entity' : 'Natural Person'}
      </td>
      <td onClick={handleOnClick} className={setColumnSuffix('__dateCreated')}>
        {DateManager.monDayYearLocal(dateCreated)}
      </td>
      <td className={setColumnSuffix('__remove')}>
        {canEdit && (
          <DeleteButton
            transparent
            onClick={ev => {
              ev.stopPropagation();
              setConfirmModalOpen(true);
            }}
          />
        )}
        <div>
          {confirmModalOpen && (
            <FormModal
              hideModal={() => setConfirmModalOpen(false)}
              title="Delete Supporting Document"
              onSubmit={deleteSupportingDocument}
              showConfirmationForm={forceDelete}
              onRejectClick={() => {
                setForceDelete(false);
                setConfirmModalOpen(false);
              }}
              showErrorsInConfirmation
              confirmButtonText="Yes"
              confirmationText="Would you like to proceed?"
              rejectButtonText="No"
              className="ickyc-form-modal--delete-supporting-doc"
            >
              <span className="ickyc-confirmation-message">
                Are you sure you want to delete the&nbsp;
                <b>{header}</b>&nbsp;supporting package?
              </span>
            </FormModal>
          )}
        </div>
      </td>
    </tr>
  );
};

SupportingDocumentsTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

SupportingDocumentsTableRow.defaultProps = {
  rowInfo: { name: '' },
  className: '',
  onClick: () => {},
  onRemove: () => {},
};

export default SupportingDocumentsTableRow;
