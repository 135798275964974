import Axios from 'axios';
import axiosInstance from '../axiosInstance';

const url = process.env.REACT_APP_BE_URL;

const getConfigurationsDomains = countryId =>
  axiosInstance.get(`/client-account/domains`, {
    params: {
      countryId,
    },
  });
const getPersonalInfo = () => axiosInstance.get('/client-user/info');
const setPassword = data => {
  return Axios.post(`${url}/api/client-account/users/set-password`, data);
};
export default {
  getConfigurationsDomains,
  getPersonalInfo,
  setPassword,
};
