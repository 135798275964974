import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Redirect as ReactRedirect, useRouteMatch } from 'react-router-dom';

const Redirect = props => {
  const { to, absolute = false } = props;
  const { url } = useRouteMatch();

  const finalPath = useMemo(() => {
    if (absolute) return to;
    const { length: urlLength } = url;
    const toString = to;
    if (url[urlLength - 1] === toString[0] && toString[0] === '/') {
      return url + toString.slice(1);
    }
    return url + to;
  }, [url, to, absolute]);

  return <ReactRedirect to={finalPath} />;
};

Redirect.propTypes = {
  to: PropTypes.string.isRequired,
  absolute: PropTypes.bool,
};
Redirect.defaultProps = {
  absolute: false,
};
export default Redirect;
