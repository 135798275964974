import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Select from '../Select';

const SelectField = ({ input, meta, className, preview, onChange, onClick, ...rest }) => {
  const hasError = useMemo(() => {
    if (meta && !preview) {
      const { invalid, touched: metaTouched, error: metaError, submitError, dirtySinceLastSubmit, submitFailed } = meta;
      if (submitError && !dirtySinceLastSubmit) {
        return submitError;
      }
      return (metaTouched || submitFailed) && invalid && metaError;
    }
    return false;
  }, [meta, preview]);

  const inputProps = {
    ...input,
    onChange: e => {
      input.onChange(e);
      onChange && onChange(e);
    },
    onClick,
  };

  const errorMsg = useMemo(() => (meta ? meta.error : null), [meta]);
  return (
    <Select className={className} {...rest} preview={preview} {...inputProps} errorMsg={errorMsg} hasError={hasError} />
  );
};

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
  className: PropTypes.string,
  onChange: PropTypes.func,
  preview: PropTypes.bool,
  options: PropTypes.array,
};
SelectField.defaultProps = {
  className: undefined,
  onChange: undefined,
  meta: { invalid: false, touched: false, error: '', submitError: '', dirtySinceLastSubmit: false },
  preview: false,
  options: [],
};
export default SelectField;
