import { Lock } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useField, useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../../../components/Accordion';
import InputField from '../../../../../../../../../components/InputField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import useEffectSkipFirst from '../../../../../../../../../hooks/useEffectSkipFirst';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import AutomationForm from '../AutomationForm';
import KYCRequestsLegalEntityForm from '../KYCRequestsLegalEntityForm';
import ModuleSettings from '../ModuleSettings';
import ModuleTitleSettings from '../ModuleTitleSettings';
import AMLCaseForm from './components/AMLCaseFormLE';
import CorporateDetailsFormFields from './components/CorporateDetailsFormFields';
import SelectAllToggle from './components/SelectAllToggle';
import './styles.scss';

const AddLegalEntityModule = ({ index, indexToShow, isDropAllowed, hasModuleError }) => {
  const [selectAllKYC, setSelectAllKYC] = useState(false);
  const [selectAllAutomation, setSelectAllAutomation] = useState(false);
  const { batch, change } = useForm();

  const {
    submitFailed,
    errors: { modules: modulesErrors },
  } = useFormState();

  const className = 'ickyc-table';
  const baseClass = 'ickyc-add-legal-entity-module';

  const evenSectionClass = useMemo(() => (indexToShow % 2 === 0 ? 'ickyc-gray-section' : 'ickyc-white-section'), [
    indexToShow,
  ]);
  const oddSectionClass = useMemo(() => (indexToShow % 2 !== 0 ? 'ickyc-gray-section' : 'ickyc-white-section'), [
    indexToShow,
  ]);

  const {
    input: {
      value: {
        entityDataValidation,
        entityDataValidationAutoAcceptance,
        entityDetails,
        entityDetailsCreateIssues,
        entityDetailsRequireSupportingDocument,
        addressDetails,
        addressDetailsCreateIssues,
        addressDetailsRequireSupportingDocument,
        autoAssign,
      },
    },
  } = useField(`modules[${index}].kycRequests`);

  const {
    input: {
      value: {
        enablePeriodicReview,
        autoUpdateEntityStatus,
        checkIfDataVerificationIsAccepted,
        checkIfMinimumDirectorsAndOfficersAreConfirmed,
        checkIfMinimumBeneficialOwnersAreConfirmed,
        checkIfAllAttachedCasesAreClosed,
        autoUpdateRiskLevel,
      },
    },
  } = useField(`modules[${index}].automation`);

  const handleOnClickEvent = e => {
    e.stopPropagation();
  };

  const allSelectedKYC = useMemo(
    () =>
      entityDataValidation &&
      entityDataValidationAutoAcceptance &&
      entityDetails &&
      entityDetailsCreateIssues &&
      entityDetailsRequireSupportingDocument &&
      addressDetails &&
      addressDetailsCreateIssues &&
      addressDetailsRequireSupportingDocument &&
      autoAssign,
    [
      entityDataValidation,
      entityDataValidationAutoAcceptance,
      entityDetails,
      entityDetailsCreateIssues,
      entityDetailsRequireSupportingDocument,
      addressDetails,
      addressDetailsCreateIssues,
      addressDetailsRequireSupportingDocument,
      autoAssign,
    ],
  );

  const allSelectedAutomation = useMemo(
    () =>
      enablePeriodicReview &&
      autoUpdateEntityStatus &&
      checkIfDataVerificationIsAccepted &&
      checkIfMinimumDirectorsAndOfficersAreConfirmed &&
      checkIfMinimumBeneficialOwnersAreConfirmed &&
      checkIfAllAttachedCasesAreClosed &&
      autoUpdateRiskLevel,
    [
      enablePeriodicReview,
      autoUpdateEntityStatus,
      checkIfDataVerificationIsAccepted,
      checkIfMinimumDirectorsAndOfficersAreConfirmed,
      checkIfMinimumBeneficialOwnersAreConfirmed,
      checkIfAllAttachedCasesAreClosed,
      autoUpdateRiskLevel,
    ],
  );

  const hasKYCRequestsError = useMemo(() => submitFailed && !!modulesErrors?.[index]?.kycRequests, [
    modulesErrors,
    submitFailed,
    index,
  ]);

  useEffect(() => {
    if (allSelectedKYC) {
      setSelectAllKYC(true);
    } else if (!allSelectedKYC) {
      setSelectAllKYC(false);
    }
    if (allSelectedAutomation) {
      setSelectAllAutomation(true);
    } else if (!allSelectedAutomation) {
      setSelectAllAutomation(false);
    }
  }, [allSelectedKYC, allSelectedAutomation]);

  useEffectSkipFirst(() => {
    if (entityDetailsRequireSupportingDocument || addressDetailsRequireSupportingDocument) {
      batch(() => {
        change(`modules[${7}].isEnabled`, true);
      }, []);
    }
  }, [addressDetailsRequireSupportingDocument, entityDetailsRequireSupportingDocument]);

  const {
    input: {
      value: {
        additionalNames: { isEnabled: additionalNamesEnabled },
        corporateDetails: { isEnabled: corporateDetailsEnabled },
        addresses: { isEnabled: addressesToggleEnabled },
        primaryContact: { isEnabled: primaryContactEnabled },
        taxJurisdictions: { isEnabled: taxJurisdictionsEnabled },
        reportingJurisdictions: { isEnabled: reportingJurisdictionsEnabled },
      },
    },
  } = useField(`modules[${index}]`);

  const handleKYCClick = useCallback(() => {
    let newSelectAllKYC;
    setSelectAllKYC(prev => {
      newSelectAllKYC = !prev;
      return !prev;
    });

    if (newSelectAllKYC) {
      batch(() => {
        change(`modules[${index}].kycRequests.entityDataValidation`, true);
        change(`modules[${index}].kycRequests.entityDataValidationAutoAcceptance`, true);
        change(`modules[${index}].kycRequests.entityDetails`, true);
        change(`modules[${index}].kycRequests.entityDetailsCreateIssues`, true);
        change(`modules[${index}].kycRequests.addressDetailsRequireSupportingDocument`, true);
        change(`modules[${index}].kycRequests.autoAssign`, true);
        change(`modules[${index}].kycRequests.autoUpdateStatus`, true);
        change(`modules[${index}].kycRequests.entityDetailsRequireSupportingDocument`, true);
        change(`modules[${index}].kycRequests.addressDetails`, true);
        change(`modules[${index}].kycRequests.addressDetailsCreateIssues`, true);
      }, [index]);
    }
  }, [setSelectAllKYC, index]);

  const handleAllAutomationClick = useCallback(() => {
    let newSelectAllAutomation;
    setSelectAllAutomation(prev => {
      newSelectAllAutomation = !prev;
      return !prev;
    });

    if (newSelectAllAutomation) {
      batch(() => {
        change(`modules[${index}].automation.enablePeriodicReview`, true);
        change(`modules[${index}].automation.autoUpdateEntityStatus`, true);
        change(`modules[${index}].automation.checkIfDataVerificationIsAccepted`, true);
        change(`modules[${index}].automation.checkIfMinimumDirectorsAndOfficersAreConfirmed`, true);
        change(`modules[${index}].automation.checkIfMinimumBeneficialOwnersAreConfirmed`, true);
        change(`modules[${index}].automation.checkIfAllAttachedCasesAreClosed`, true);
        change(`modules[${index}].automation.autoUpdateRiskLevel`, true);
      }, [index]);
    }
  }, [setSelectAllAutomation, index]);

  return (
    <CollapsibleTableRow
      isDropAllowed={isDropAllowed}
      className={className}
      rowClassName={hasModuleError ? 'ickyc-table__row--error' : ''}
      withIndexColumn
      index={indexToShow}
      icon={<Lock />}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Add Legal Entity</td>
          <td className={`${className}__column__enabled--empty`} />
        </>
      }
    >
      <ModuleSettings className={baseClass}>
        <Accordion title="Add Organization" accordionOpen className={evenSectionClass}>
          <ModuleTitleSettings index={index} withDescription namePreffix="addOrganization" />
        </Accordion>
        <Accordion
          title={
            <>
              <div onClick={handleOnClickEvent}>
                <Field preview name={`modules[${index}].corporateDetails.isEnabled`} component={ToggleField} />
              </div>
              <span>Corporate Details</span>
            </>
          }
          accordionOpen={corporateDetailsEnabled}
          controledOpen
          className={oddSectionClass}
        >
          <ModuleTitleSettings
            index={index}
            withDescription
            AdditionalFieldsComponent={CorporateDetailsFormFields}
            namePreffix="corporateDetails"
          />
        </Accordion>
        <Accordion
          title={
            <>
              <div onClick={handleOnClickEvent}>
                <Field preview name={`modules[${index}].additionalNames.isEnabled`} component={ToggleField} />
              </div>
              <span>Additional Names</span>
            </>
          }
          accordionOpen={additionalNamesEnabled}
          controledOpen
          className={evenSectionClass}
        >
          <ModuleTitleSettings index={index} withDescription namePreffix="additionalNames" />
        </Accordion>
        <Accordion
          title={
            <>
              <div onClick={handleOnClickEvent}>
                <Field preview name={`modules[${index}].addresses.isEnabled`} component={ToggleField} />
              </div>
              <span>Addresses</span>
            </>
          }
          controledOpen
          accordionOpen={addressesToggleEnabled}
          className={oddSectionClass}
        >
          <ModuleTitleSettings index={index} withDescription withAddressTypes namePreffix="addresses" />
        </Accordion>
        <Accordion
          title={
            <>
              <div onClick={handleOnClickEvent}>
                <Field preview name={`modules[${index}].primaryContact.isEnabled`} component={ToggleField} />
              </div>
              <span>Primary Contact</span>
            </>
          }
          accordionOpen={primaryContactEnabled}
          controledOpen
          className={evenSectionClass}
        >
          <ModuleTitleSettings index={index} withDescription namePreffix="primaryContact" />
        </Accordion>
        <Accordion
          title={
            <>
              <div onClick={handleOnClickEvent}>
                <Field preview name={`modules[${index}].taxJurisdictions.isEnabled`} component={ToggleField} />
              </div>
              <span>Tax Jurisdictions</span>
            </>
          }
          accordionOpen={taxJurisdictionsEnabled}
          controledOpen
          className={oddSectionClass}
        >
          <ModuleTitleSettings index={index} withDescription withRequireUserToggle namePreffix="taxJurisdictions" />
        </Accordion>
        <Accordion
          title={
            <>
              <div onClick={handleOnClickEvent}>
                <Field preview name={`modules[${index}].reportingJurisdictions.isEnabled`} component={ToggleField} />
              </div>
              <span>Reporting Jurisdictions</span>
            </>
          }
          accordionOpen={reportingJurisdictionsEnabled}
          controledOpen
          className={evenSectionClass}
        >
          <ModuleTitleSettings
            index={index}
            withDescription
            withRequireUserToggle
            namePreffix="reportingJurisdictions"
          />
        </Accordion>
        <Accordion
          title={
            <>
              <div onClick={handleOnClickEvent}>
                <Field preview name={`modules[${index}].kycRequests.isEnabled`} component={ToggleField} />
              </div>
              <span>KYC Requests</span>
            </>
          }
          accordionOpen
          className={classNames(oddSectionClass, { 'ickyc-section--error': hasKYCRequestsError })}
        >
          <SelectAllToggle value={selectAllKYC} onChange={handleKYCClick} />
          <div>
            <KYCRequestsLegalEntityForm index={index} />
          </div>
        </Accordion>
        <Accordion
          title={
            <>
              <div onClick={handleOnClickEvent}>
                <Field preview name={`modules[${index}].amlCaseDto.isEnabled`} component={ToggleField} />
              </div>
              <span>Create AML Case for Primary Legal Entity</span>
            </>
          }
          accordionOpen
          className={evenSectionClass}
        >
          <div className={classNames(evenSectionClass, 'ickyc-container')}>
            <AMLCaseForm index={index} namePreffix="amlCaseDto" />
          </div>
        </Accordion>
        <Accordion
          title={
            <>
              <div onClick={handleOnClickEvent}>
                <Field preview name={`modules[${index}].automation.enabled`} component={ToggleField} />
              </div>
              <span>Automation</span>
            </>
          }
          accordionOpen
          className={oddSectionClass}
        >
          <SelectAllToggle value={selectAllAutomation} onChange={handleAllAutomationClick} />
          <div className={classNames(oddSectionClass, 'ickyc-container')}>
            <AutomationForm index={index} />
          </div>
        </Accordion>
      </ModuleSettings>
    </CollapsibleTableRow>
  );
};
AddLegalEntityModule.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
};
export default AddLegalEntityModule;
