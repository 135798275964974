import { CircularProgress } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import api from '../../../../api';
import IComplyForm from '../../../../components/Form/IComplyForm';
import InputField from '../../../../components/InputField';
import Section from '../../../../components/Section';
import { updatePersonalInfo } from '../../../../store/actions/auth.action';
import setClassSuffix from '../../../../utilities/services/ClassManager';
import IconManager from '../../../../utilities/services/IconManager';
import validators from '../../../../utilities/services/validators';
import './styles.scss';

const PersonalDetailsForm = ({ initialValues }) => {
  const classBase = 'ickyc-personal-data-form';
  const [resendEmailText, setResendEmailText] = useState('Resend Verification Email');
  const [loading, setLoading] = useState(false);
  const setSuffix = setClassSuffix(classBase);
  const dispatch = useDispatch();

  const handleSaveChanges = async values => {
    return api.personalSettings
      .updatePersonalInfo(values)
      .then(({ data: responseData }) => {
        const { email, ...rest } = responseData;
        dispatch(updatePersonalInfo({ ...rest, pendingEmail: email }));
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.errors?.join('') };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to update Personal Details' };
      });
  };

  const onResendEmailHandler = () => {
    setLoading(true);
    api.personalSettings
      .resendEmail()
      .then(() => {
        setResendEmailText('Email sent!');
      })
      .catch(err => {
        setResendEmailText(`Email isn't sent! Try Again.`);

        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to update Personal Details' };
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Section title="Personal Details" className={classBase} divider>
      <IComplyForm
        onSubmit={handleSaveChanges}
        initialValues={initialValues}
        submitButtonText="Save Personal Detail Changes"
        showControls
        noDiscard
      >
        <span>Email</span>
        <Field
          component={InputField}
          name="email"
          validate={validators.composeValidators(validators.required(), validators.email('Invalid email address'))}
        />

        <FormSpy subscription={{ values: true }}>
          {({ values: { emailConfirmed } }) => (
            <div className={setSuffix('__status')}>
              {!emailConfirmed ? (
                <div className={setSuffix('__status--pending')}>
                  <div>Pending</div>
                  <div onClick={onResendEmailHandler}>
                    {resendEmailText}
                    {loading && <CircularProgress />}
                  </div>
                </div>
              ) : (
                <div>
                  <span className={setSuffix('__status--verification')}>
                    {IconManager.get(IconManager.names.CHECK)} Verified
                  </span>
                </div>
              )}
            </div>
          )}
        </FormSpy>

        <span>Your Name</span>
        <div className={setSuffix('__name')}>
          <Field component={InputField} name="firstName" validate={validators.required()} />
          <Field component={InputField} name="lastName" validate={validators.required()} />
        </div>
      </IComplyForm>
    </Section>
  );
};
PersonalDetailsForm.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    verification: PropTypes.bool,
  }),
};
PersonalDetailsForm.defaultProps = {
  initialValues: {},
};
export default PersonalDetailsForm;
