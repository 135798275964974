import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import api from '../../../api';
import useGroupPermissions from '../../../hooks/useGroupPermissions';
import authEnums from '../../../utilities/enums/authEnums';
import TableHeaders from '../../../utilities/services/TableHeaders';
import { UserManagementContext } from '../../../utilities/services/contexts';
import UserManagementPage from '../UserManagementPage';
import EditUserModal from '../UserManagementPage/components/EditUserModal';
import UserManagmentRow from '../UserManagementPage/components/UserManagmentRow';

const UserManagementWrapper = ({ className }) => {
  const classes = classNames('ickyc-page', className);
  const groupPermissions = useGroupPermissions(authEnums.PERMISSION_GROUP.USER_MANAGEMENT);

  return (
    <div className={classes}>
      <UserManagementContext.Provider
        value={{
          getAllUsers: api.administration.userManagement.getAllUsers,
          fetchUser: api.administration.userManagement.getUser,
          updateUserData: api.administration.userManagement.updateUser,
          createUser: api.administration.userManagement.createUser,
          downloadReportCSV: api.administration.userManagement.downloadCSV,
          deleteUser: api.administration.userManagement.deleteUser,
          getGlobalTwoFactorAuthentication: api.administration.account.getGlobalTwoFactorAuthentication,
          withTitle: true,
          withPassword: false,
          clientId: null,
          UserModal: EditUserModal,
          TableRow: UserManagmentRow,
          groupPermissions,
          CustomTableHeader: TableHeaders.UserManagmentTableHeader,
        }}
      >
        <UserManagementPage />
      </UserManagementContext.Provider>
    </div>
  );
};
UserManagementWrapper.propTypes = {
  className: PropTypes.string,
};

UserManagementWrapper.defaultProps = {
  className: undefined,
};
export default UserManagementWrapper;
