/* eslint-disable no-useless-escape */
import useCSVRead from 'hooks/useCSVRead';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import Loader from '../../../../../../../components/Loader';
import Section from '../../../../../../../components/Section';
import TableNavigation from '../../../../../../../components/TableNavigation';
import utilities from '../../../../../../../utilities';

const CSVDataPreview = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    values: { csvFile },
  } = useFormState();

  const { isLoading, previewData } = useCSVRead(csvFile[0].file);

  const nextEntityData = useCallback(() => {
    setCurrentIndex(prev => prev + 1);
  }, []);

  const previousEntityData = useCallback(() => {
    setCurrentIndex(prev => prev - 1);
  }, []);

  const entityDetails = useMemo(() => previewData[currentIndex], [currentIndex, previewData]);

  return (
    <div className="ickyc-csv-upload__data-preview">
      <p>
        You have uploaded a CSV with &nbsp;
        <b>
          {previewData.length} {previewData[currentIndex]?.Name ? 'Legal Entities' : 'Natural Persons'}
        </b>
      </p>
      <Section title="Entity Details">
        {isLoading && <Loader />}
        <ul>
          {Object.entries(entityDetails || {}).map(entry => (
            <li>
              <b>{utilities.insertSpaceBeforeCapitalLetter(entry[0])}</b> {entry[1]}
            </li>
          ))}
        </ul>
        <TableNavigation
          pageNumber={currentIndex + 1}
          totalNumber={previewData.length}
          pageSize={1}
          goToNextPage={nextEntityData}
          goToPrevPage={previousEntityData}
        />
      </Section>
    </div>
  );
};

CSVDataPreview.propTypes = {};

export default CSVDataPreview;
