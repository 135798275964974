import AccountConfirmation from '../../../scenes/Auth/AccountConfirmation';
import BasicInfoAndIdUplaodStep from '../../../scenes/Auth/BasicInfoAndIdUploadStep';
import BasicInfoStep from '../../../scenes/Auth/BasicInfoStep';
import CodeConfirmationTFA from '../../../scenes/Auth/CodeConfirmationTFA';
import EmailConfirmation from '../../../scenes/Auth/EmailConfirmation';
import EnableAuthenticatorTFA from '../../../scenes/Auth/EnableAuthenticatorTFA';
import Login from '../../../scenes/Auth/Login';
import RecoveryCodeConfirmationTFA from '../../../scenes/Auth/RecoveryCodeConfirmationTFA';
import redirect from '../../modules/redirect';

export default [
  {
    path: '/login',
    component: Login,
    exact: false,
  },
  {
    path: '/account-confirmation',
    component: AccountConfirmation,
    exact: false,
  },
  {
    path: '/two-factor-authentication/code-confirmation',
    component: CodeConfirmationTFA,
    exact: false,
  },
  {
    path: '/two-factor-authentication/enable-authenticator',
    component: EnableAuthenticatorTFA,
  },
  {
    path: '/two-factor-authentication/recovery-code-confirmation',
    component: RecoveryCodeConfirmationTFA,
    exact: false,
  },
  {
    path: '/email-confirmation',
    component: EmailConfirmation,
    exact: false,
  },
  {
    path: '/basic-info',
    component: BasicInfoStep,
    exact: false,
  },
  {
    path: '/basic-info-identity',
    component: BasicInfoAndIdUplaodStep,
    exact: false,
  },
  {
    path: '*',
    component: redirect('/login', true),
  },
];
