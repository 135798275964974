import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

/**
 * Transparent button component used across the application.
 * @param {func} onClick - onClick handler
 * @param {*} [left = <span/>] - svg placed on the left
 * @param {*} [right  = <span/>] - svg placed on the right
 * @param {*} children - button content
 * @param {boolean} [black = false] - flag which notes to apply black color
 * @param {string} [button = type] - button type
 * @param {boolean} [disabled = false] - disability flag
 * @param {boolean} [active = false] - should apply hover style
 */
const BUTTON_VARIANTS = ['primary', 'red'];
const OutlineButton = ({ onClick, left, right, children, black, type, disabled, active, className, variant }) => {
  const classBase = 'ickyc-outline-button';

  const classes = classNames([
    classBase,
    {
      'ickyc-outline-button--black': black,
      'ickyc-outline-button--active': active,
      'ickyc-outline-button--disabled': disabled,
      'ickyc-outline-button--red': variant === 'red',

      [className]: className,
    },
  ]);

  return (
    <button type={type} className={classes} onClick={onClick} disabled={disabled} tabIndex={0}>
      {left}

      <div className="ickyc-outline-button__center">{children}</div>

      {right}
    </button>
  );
};

OutlineButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  left: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  right: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  black: PropTypes.bool,
  type: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.oneOf(BUTTON_VARIANTS),
};

OutlineButton.defaultProps = {
  onClick: () => {},
  left: undefined,
  right: undefined,
  black: false,
  type: 'button',
  disabled: false,
  className: undefined,
  active: false,
  variant: 'primary',
};

export default OutlineButton;
