import PropTypes from 'prop-types';
import React from 'react';
import Card from '../../../../components/Card';
import CardItem from '../../../../components/Card/components/CardItem';
import history from '../../../../modules/history';
import './styles.scss';

const PersonalSettings = ({ row, column }) => {
  const redirectToPersonalSettings = () => {
    history.push('/account-settings/personal-settings');
  };
  return (
    <Card text="Personal Settings" row={row} column={column} className="ickyc-personal-settings-tile">
      <CardItem header="Update Password" subheader="Change your login password" onClick={redirectToPersonalSettings} />
      <CardItem
        header="Personal Information"
        subheader="View and update your details"
        onClick={redirectToPersonalSettings}
      />
    </Card>
  );
};
PersonalSettings.propTypes = {
  row: PropTypes.number.isRequired,
  column: PropTypes.number.isRequired,
};
export default PersonalSettings;
