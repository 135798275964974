import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Spinner from '../../../../../../../../../../components/Spinner';
import Quadruple from '../../../../../../../../../../utilities/models/Quadruple';
import OpenCVService from '../../../../../../../../../../utilities/services/OpenCVService';
import DetectionTopInfo from '../../../../../DetectionTopInfo';
import DocumentMiniUploader from '../../../../../DocumentMiniUploader';
import { DOCUMENT_INFO, DOCUMENT_MESSAGE } from '../../utilities/documentUploadEnums';

const DocumentBoundsDetectionPreview = ({
  value,
  onChange,
  uploadHandlers,
  faceDetection,
  initialRotation,
  errorOnSubmit,
  uploadingImage,
  onFaceNotDetected,
}) => {
  const canvasRef = useRef();
  const overlayRef = useRef();
  const [detectionInfo, setDetectionInfo] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [processingImage, setProcessingImage] = useState(true);

  const resizeContent = useCallback(() => {
    const canvas = canvasRef.current;
    const dropzone = document.querySelector('.ickyc-document-detection-dropzone');
    const imageWidth = canvas.width;
    const imageHeight = canvas.height;
    const originalImageAR = imageWidth / imageHeight;

    let newWidth = imageWidth;
    let newHeight = newWidth / originalImageAR;
    if (newWidth > dropzone.clientWidth) {
      newWidth = dropzone.clientWidth;
      newHeight = newWidth / originalImageAR;
    }
    if (dropzone.clientWidth < 500) {
      newWidth = dropzone.clientWidth;
      newHeight = newWidth / originalImageAR;
    }

    return {
      width: newWidth,
      height: newHeight,
    };
  }, []);

  const saveDetectionInfo = useCallback(() => {
    onChange({ ...value, data: detectionInfo });
  }, [detectionInfo, onChange, value]);

  useLayoutEffect(() => {
    onFaceNotDetected(false);
    const canvas = canvasRef?.current;
    const canvasCTX = canvas?.getContext('2d');
    const smallCanvas = document.createElement('canvas');
    const smallCanvasCTX = smallCanvas.getContext('2d');
    let imageAspectRatio = 1;

    const documentDetection = () => {
      let detectionObject = {};

      try {
        let RotationAngle = 0;
        if (faceDetection) {
          const { faceDetected, angle } = OpenCVService.detectFace(smallCanvas);
          if (!faceDetected) {
            const error = new Error();
            error.name = 'Passport Detection / Face Detection';
            error.message = 'Face Not Detected';
            onFaceNotDetected(true);
            error.code = DOCUMENT_MESSAGE.WARNING;
            // throw error;
          }
          if (angle) {
            OpenCVService.rotateImage(smallCanvas, angle);
            RotationAngle = angle;
          }
        } else if (initialRotation) {
          RotationAngle = initialRotation;
          OpenCVService.rotateImage(smallCanvas, RotationAngle);
        }
        let boundingRect = OpenCVService.detectDocumentBounds(smallCanvas);
        const { width, height } = resizeContent();
        let code;
        boundingRect.updateCoordinates(Math.max(width, height) / DOCUMENT_INFO.processImageSize);

        if (boundingRect.checkMinimalDimension(width, height)) {
          const startX = width / 4;
          const startY = height / 4;
          const point1 = { x: startX, y: startY };
          const point2 = { x: startX + width / 2, y: startY };
          const point3 = { x: startX, y: startY + height / 2 };
          const point4 = { x: startX + width / 2, y: startY + height / 2 };
          boundingRect = new Quadruple(point1, point2, point3, point4);
          boundingRect.updateCoordinates(Math.max(width, height) / DOCUMENT_INFO.processImageSize);
          code = DOCUMENT_MESSAGE.NOT_DETECTED;
        } else {
          code = DOCUMENT_MESSAGE.DETECTED;
        }
        detectionObject = {
          boundingRect,
          code,
          RotationAngle,
        };
      } catch (err) {
        const { code, message } = err;
        // NO LINE DRAW
        if (code === DOCUMENT_MESSAGE.WARNING) {
          detectionObject = {
            message,
            code,
          };
        }
      } finally {
        const { width, height } = resizeContent();
        OpenCVService.resizeImage(smallCanvas, width, height);
        setDetectionInfo({ ...detectionObject, width, height });
      }
    };
    const image = new Image();
    image.onload = () => {
      imageAspectRatio = image.width / image.height;
      let newWidth = 1;
      let newHeight = 1;
      if (imageAspectRatio < 1) {
        newWidth = DOCUMENT_INFO.outputImageSize * imageAspectRatio;
        newHeight = DOCUMENT_INFO.outputImageSize;
        canvasCTX.clearRect(0, 0, newWidth, newHeight);
      } else {
        newWidth = DOCUMENT_INFO.outputImageSize;
        newHeight = DOCUMENT_INFO.outputImageSize / imageAspectRatio;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;
      canvasCTX.drawImage(image, 0, 0, canvas.width, canvas.height);

      smallCanvas.width = newWidth / 6;
      smallCanvas.height = newHeight / 6;
      smallCanvasCTX.drawImage(image, 0, 0, canvas.width / 6, canvas.height / 6);

      if (!value.data) {
        documentDetection(image);
      } else {
        const { width, height, RotationAngle } = value.data;
        OpenCVService.resizeImage(canvas, width, height);
        OpenCVService.rotateImage(canvas, RotationAngle);
        setDetectionInfo(value.data);
      }
    };
    image.src = value.preview;
  }, [value, resizeContent, faceDetection, initialRotation]);

  useEffect(() => {
    if (detectionInfo) {
      if (processingImage) {
        saveDetectionInfo();
        setTimeout(() => {
          setProcessingImage(false);
        }, 1000);
      }
    }
  }, [canvasRef, detectionInfo, processingImage, saveDetectionInfo]);

  useEffect(() => {
    const canvas = overlayRef.current;
    const { boundingRect } = detectionInfo || {};
    const newBoundingRect = boundingRect;
    const onMouseMove = e => {
      e.preventDefault();
      e.stopPropagation();
      if (!isDragging) return;
      const bRect = e.target.getBoundingClientRect();
      let coordX;
      let coordY;
      if (e.type === 'touchmove') {
        coordY = e.touches[0].clientY;
        coordX = e.touches[0].clientX;
      } else {
        coordX = e.clientX;
        coordY = e.clientY;
      }
      if (newBoundingRect.isOutsideCanvas(bRect, coordX, coordY)) {
        newBoundingRect.resetCorners();
        setIsDragging(false);
      }
      newBoundingRect.updatePoint(coordX - bRect.left, coordY - bRect.top);
      setDetectionInfo(prev => ({ ...prev, boundingRect: newBoundingRect }));
    };
    const onMouseDown = e => {
      e.preventDefault();
      e.stopPropagation();
      let coordX;
      let coordY;
      const bRect = e.target.getBoundingClientRect();
      if (e.type === 'touchstart') {
        coordX = e.touches[0].clientX;
        coordY = e.touches[0].clientY;
      } else {
        coordX = e.clientX;
        coordY = e.clientY;
      }
      newBoundingRect.isPointClicked(coordX, coordY, bRect);
      if (newBoundingRect.isCornerSelected()) {
        setIsDragging(true);
      }
    };

    const onMouseUp = () => {
      if (isDragging) {
        newBoundingRect.resetCorners();
        setIsDragging(false);
        saveDetectionInfo();
      }
    };
    const setupListeners = () => {
      canvas.addEventListener('mouseup', onMouseUp, false);
      canvas.addEventListener('touchend', onMouseUp, false);
      canvas.addEventListener('mousedown', onMouseDown, false);
      canvas.addEventListener('touchstart', onMouseDown, false);
      canvas.addEventListener('mousemove', onMouseMove, false);
      canvas.addEventListener('touchmove', onMouseMove, false);
      canvas.addEventListener('mouseout', onMouseUp, false);
    };

    const removeListeners = () => {
      canvas.removeEventListener('mouseup', onMouseUp, false);
      canvas.removeEventListener('touchend', onMouseUp, false);
      canvas.removeEventListener('mousedown', onMouseDown, false);
      canvas.removeEventListener('touchstart', onMouseDown, false);
      canvas.removeEventListener('mousemove', onMouseMove, false);
      canvas.removeEventListener('touchmove', onMouseMove, false);
      canvas.removeEventListener('mouseout', onMouseUp, false);
    };
    if (canvas) {
      // setupListeners();
    }
    return () => {
      if (canvas) removeListeners();
    };
  }, [overlayRef, detectionInfo, isDragging, canvasRef, saveDetectionInfo]);

  const baseClass = 'ickyc-detection-preview';
  const classes = classNames({
    [`${baseClass}`]: true,
    [`${baseClass}--hidden`]: processingImage,
  });
  return (
    <div
      className={classes}
      style={{
        width: value?.data?.width,
      }}
    >
      {(processingImage || uploadingImage) && <Spinner />}
      {!uploadingImage && <DetectionTopInfo code={detectionInfo?.code} errorOnSubmit={errorOnSubmit} />}
      {!uploadingImage && <DocumentMiniUploader handlers={uploadHandlers} />}
      <div className={`${baseClass}__zone`}>
        {detectionInfo?.code !== DOCUMENT_MESSAGE.WARNING && (
          <canvas className={`${baseClass}__points-overlay`} ref={overlayRef} />
        )}
        <canvas ref={canvasRef} style={{ display: processingImage || uploadingImage ? 'none' : 'block' }} />

        {detectionInfo?.code === DOCUMENT_MESSAGE.WARNING && (
          <div className={`${baseClass}__error`}>
            <span className={`${baseClass}__error-message`}>{detectionInfo.message}</span>
          </div>
        )}
      </div>
    </div>
  );
};

DocumentBoundsDetectionPreview.propTypes = {
  value: PropTypes.shape({
    file: PropTypes.instanceOf(File),
    preview: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  uploadHandlers: PropTypes.func.isRequired,
  faceDetection: PropTypes.bool,
  initialRotation: PropTypes.bool,
  errorOnSubmit: PropTypes.string,
  uploadingImage: PropTypes.bool,
  onFaceNotDetected: PropTypes.func,
};

DocumentBoundsDetectionPreview.defaultProps = {
  faceDetection: false,
  initialRotation: 0,
  errorOnSubmit: null,
  uploadingImage: null,
  onFaceNotDetected: () => {},
};

export default DocumentBoundsDetectionPreview;
