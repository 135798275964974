/* eslint-disable radix */
/* eslint-disable no-restricted-globals */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import VirtualList from 'react-vlist';
import PrimaryButton from '../../Buttons/PrimaryButton';
import CheckboxField from '../../CheckboxField';
import SingleCountry from '../../CountrySelect/components/SingleCountry';
import DebounceInput from '../../DebounceInput';
import './styles.scss';

const itemHeight = 48;
const baseClass = 'ickyc-rules';

const mapRuleTypeToSearchPlaceholder = {
  country: 'Search Jurisdictions',
  kycStatus: 'Search KYC Statuses',
  riskType: 'Search Risk Types',
};

const CheckboxList = ({ disabled, ruleId, ruleIndex, options, scoringRuleType }) => {
  const { change, batch } = useForm();
  const {
    values: { rules, [scoringRuleType]: optionsValue },
  } = useFormState();

  const newRules = useMemo(() => rules.filter(({ id }) => id.startsWith('new_rule_')), [rules]);

  const selectedItemsIds = useMemo(() => {
    if (!optionsValue) return [];
    return Object.entries(optionsValue)
      .filter(([, value]) => value?.id === ruleId)
      .map(item => item[0].split('i_')[1]);
  }, [optionsValue, ruleId]);

  const onSelectAll = useCallback(() => {
    const allItemsIds = options.map(item => item.id);
    const freeIds = allItemsIds.filter(key => !optionsValue?.[`i_${key}`]);

    batch(() => {
      freeIds.forEach(id => {
        change(`${scoringRuleType}.i_${id}`, { id: ruleId, number: ruleIndex });
      });
    });
  }, [batch, change, scoringRuleType, options, optionsValue, ruleId, ruleIndex]);

  const onDeselectAll = useCallback(() => {
    batch(() => {
      selectedItemsIds.forEach(id => {
        change(`${scoringRuleType}.i_${id}`, undefined);
      });
    });
  }, [batch, change, scoringRuleType, selectedItemsIds]);

  const applySearchTerm = useCallback(
    searchTerm => {
      const itemIndex = options?.findIndex(item => item.name.toLowerCase().startsWith(searchTerm?.toLowerCase()));

      if (itemIndex !== -1) {
        const element = document.getElementsByClassName(`vListViewPort-${ruleId}`)[0];
        const top = itemIndex * itemHeight;
        element.scroll({
          top,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    [options, ruleId],
  );

  const renderRow = useCallback(
    (item, index, style) => {
      const option = optionsValue?.[`i_${item.id}`];
      const disabledField = option?.id ? option.id !== ruleId : false;

      const listItemClasses = classNames(`${baseClass}__list__item`, {
        [`${baseClass}__list__item--even`]: index % 2 !== 0,
        [`${baseClass}__list__item--disabled`]: disabledField,
      });

      let selectedRuleIndex = 0;

      if (disabledField) {
        if (newRules.length) {
          const paginationLimit = 5;
          const isNewRule = ruleId.startsWith('new_rule_');
          const currentPage = parseInt(ruleIndex / (paginationLimit + 1) + 1) - (isNewRule && rules.length > 5 ? 1 : 0);
          const maxRuleIndex = currentPage * paginationLimit;

          const noSelectedByNewRule = !option.id.startsWith('new_rule_');
          const selectedByRuleOnNextPage = option.number > maxRuleIndex;

          if (noSelectedByNewRule && selectedByRuleOnNextPage) selectedRuleIndex = option.number + newRules.length;
          else selectedRuleIndex = option.number;
        } else selectedRuleIndex = option.number;
      }

      return (
        <li className={listItemClasses} key={index} style={style}>
          <div className={`${baseClass}__list__item__checkbox`}>
            <Field
              component={CheckboxField}
              type="checkbox"
              name={`${scoringRuleType}.i_${item.id}`}
              key={`${scoringRuleType}.i_${item.id}`}
              parse={v => (v ? { id: ruleId, number: ruleIndex } : undefined)}
              format={v => v !== undefined}
              disabled={disabledField}
            />
          </div>
          <div className={`${baseClass}__list__item__country`}>
            {scoringRuleType === 'country' ? (
              <SingleCountry alpha2Code={item.alpha2Code} name={item.name} />
            ) : (
              <span>{item.name}</span>
            )}
          </div>
          {disabledField && (
            <div className={`${baseClass}__list__item__controls`}>
              Already Selected by Scoring Rule {selectedRuleIndex}
            </div>
          )}
        </li>
      );
    },
    [scoringRuleType, optionsValue, ruleId, rules, newRules, ruleIndex],
  );

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__controls`}>
        <div className={`${baseClass}__controls__container`}>
          <PrimaryButton onClick={onSelectAll} disabled={disabled}>
            Select All
          </PrimaryButton>
          <PrimaryButton onClick={onDeselectAll} disabled={disabled}>
            Deselect All
          </PrimaryButton>
        </div>
        <div className={`${baseClass}__controls__container`}>
          <span>
            {selectedItemsIds.length} Selected | {options.length} Shown
          </span>
          <DebounceInput onChange={applySearchTerm} placeholder={mapRuleTypeToSearchPlaceholder[scoringRuleType]} />
        </div>
      </div>
      <VirtualList
        className={`${baseClass}__list vListViewPort-${ruleId}`}
        data={options}
        itemheight={itemHeight}
        renderItems={(item, index, style) => renderRow(item, index, style)}
      />
    </div>
  );
};

CheckboxList.propTypes = {
  scoringRuleType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  ruleId: PropTypes.string,
  options: PropTypes.array,
  ruleIndex: PropTypes.number,
};
CheckboxList.defaultProps = {
  disabled: false,
  ruleId: '',
  options: [],
  ruleIndex: 0,
};
export default CheckboxList;
