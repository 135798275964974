import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import toastr from 'toastr';
import api from '../../../../../api';
import FormModal from '../../../../../components/Modal/FormModal';
import SelectCustomContent from '../../../../../components/Select/components/SelectCustomContent';
import SelectInputTrigger from '../../../../../components/Select/components/SelectInputTrigger';
import SelectField from '../../../../../components/SelectField';
import Spinner from '../../../../../components/Spinner';
import { BatchContext } from '../../../../../utilities/services/contexts';
import './styles.scss';

const BatchAddTagsModal = ({ hideModal, initialAttachments, entityId, onBatchUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tagsOptions, setTagsOptions] = useState([]);
  const { listOfSelectedIDs } = useContext(BatchContext);

  const entityIds = useMemo(() => {
    if (!listOfSelectedIDs.length) {
      return [entityId];
    }
    if (!listOfSelectedIDs.some(eId => eId === entityId)) {
      return [...listOfSelectedIDs, entityId];
    }
    return listOfSelectedIDs;
  }, [listOfSelectedIDs]);

  const handleSubmit = async values => {
    const { tags } = values;
    const submitObj = {
      entityIds,
      tags,
    };
    return api.kyc
      .batchAssignTags(submitObj)
      .then(({ data }) => {
        onBatchUpdate(data);
        toastr.success('Entities are Successfully attached to Case');
        hideModal();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errData?.message || 'Error Occured' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  useEffect(() => {
    const fetchTags = () => {
      setIsLoading(true);
      api.kyc.entityManagement
        .getAllEntityTags()
        .then(res => {
          const { data } = res;
          setTagsOptions(data.map(el => ({ id: el, value: el, label: el })));
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchTags();
  }, []);

  return (
    <FormModal
      hideModal={hideModal}
      title={<h3>Tags</h3>}
      onSubmit={handleSubmit}
      className="ickyc-tags-modal"
      initialValues={{
        tags: initialAttachments,
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <h3>Batch Adding Tags</h3>
          <div>
            <p>The following tags will be attached to selected entities:</p>
            <Field
              component={SelectField}
              name="tags"
              Trigger={<SelectInputTrigger placeholder="Tags" />}
              Content={<SelectCustomContent />}
              options={tagsOptions}
              multipleSelection
              withCustomOptions
            />
          </div>
        </>
      )}
    </FormModal>
  );
};

BatchAddTagsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  initialAttachments: PropTypes.arrayOf(PropTypes.shape({})),
  onBatchUpdate: PropTypes.func,
};

BatchAddTagsModal.defaultProps = {
  initialAttachments: [],
  onBatchUpdate: () => {},
};

export default BatchAddTagsModal;
