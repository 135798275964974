import PropTypes from 'prop-types';
import React, { useState } from 'react';
import api from '../../../../../../../api';
import OutlineButton from '../../../../../../../components/Buttons/OutlineButton';
import utilities from '../../../../../../../utilities';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../utilities/services/DateManager';
import IconManager from '../../../../../../../utilities/services/IconManager';

const CSVExportsRow = ({
  rowInfo: { dateTime, entityType, requestedBy, records, id, importedExported },
  className,
}) => {
  const [downloading, setDownloading] = useState(false);
  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const handleDownload = async () => {
    setDownloading(true);
    api.kyc.entityManagement
      .downloadCSVExport(id)
      .then(({ data }) => {
        utilities.downloadFile(data, 'application/csv', `csv-export-${dateTime}.csv`);
      })
      .finally(() => {
        setDownloading(false);
      });
  };
  return (
    <tr key={id} className={`${className}__row`}>
      <td className={setColumnSuffix('__dateTime')}>
        <span>{dateTime ? DateManager.monDayYearLocal(dateTime) : ''}</span>
      </td>

      <td className={setColumnSuffix('__entityType')}>
        <span>{entityType}</span>
      </td>
      <td className={setColumnSuffix('__requestedBy')}>
        <span>{requestedBy}</span>
      </td>
      <td className={setColumnSuffix('__records')}>
        <span>{records}</span>
      </td>
      <td className={setColumnSuffix('__importedExported')}>
        <span>{importedExported}</span>
      </td>
      <td className={setColumnSuffix('__download')}>
        <OutlineButton
          onClick={handleDownload}
          left={IconManager.get(IconManager.names.DOWNLOAD)}
          disabled={downloading}
        >
          Download
        </OutlineButton>
      </td>
    </tr>
  );
};

CSVExportsRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.string,
    dateTime: PropTypes.string,
    requestedBy: PropTypes.string,
    importedExported: PropTypes.number,
    entityType: PropTypes.string.isRequired,
    records: PropTypes.number,
  }),
  className: PropTypes.string,
};

CSVExportsRow.defaultProps = {
  className: '',
  rowInfo: {
    importedExported: 0,
    records: 0,
    dateTime: null,
  },
};
export default CSVExportsRow;
