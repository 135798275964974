import PropTypes from 'prop-types';
import React from 'react';
import iconManager from '../../../utilities/services/IconManager';
import './styles.scss';

/**
 * @name BareHeading
 *
 * @param {string} icon - icon displayed beside the title
 * @param {string} title - page title
 * @param {*} [children = <></>] - potential elements placed on the right
 */

const BareHeading = ({ icon, title, children, onClick, text }) => {
  return (
    <div className="ickyc-page__header">
      <div className="ickyc-page__header__left">
        {iconManager.get(icon)}
        <h1 onClick={onClick}>{title}</h1>
        {text && <span>{text}</span>}
      </div>
      <div className="ickyc-page__header__right">{children}</div>
    </div>
  );
};

BareHeading.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  text: PropTypes.string,
  onClick: PropTypes.func,
};

BareHeading.defaultProps = {
  icon: '',
  children: <></>,
  text: '',
  onClick: () => {},
};

export default BareHeading;
