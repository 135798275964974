import HelpIcon from '@material-ui/icons/Help';
import classNames from 'classnames';
import useMatchMedia from 'hooks/useMatchMedia';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import FieldDescription from '../FieldDescription';
import Tooltip from '../Tooltip';
import './styles.scss';

/**
 * @param {string} [props.label] - label text
 * @param {string} props.type - input type
 * @param {string} [props.placeholder] - input placeholder
 * @param {Object} [props.input] - Field component props
 * @param {number} [props.rowNum] - number of textarea rows
 * @param {boolean} [props.isEditable = false] - flag which notes if input is editable
 */

const Textarea = React.forwardRef(
  ({
    label,
    onChange,
    placeholder,
    rowNum,
    value,
    preview,
    disabled,
    required,
    errorMsg,
    className,
    hint,
    hasError,
    description,
    autoExpand,
    accentText,
    labelPosition = 'left',
    ...rest
  }) => {
    const isBelowTablet = useMatchMedia('isBelowTablet');
    const textAreaRef = useRef(null);

    const textareaProps = useMemo(() => {
      const componentProps = {
        rows: rowNum,
        spellCheck: false,
        placeholder,
        rowNum,
        value,
        preview,
        disabled,
        required,
        errorMsg,
        className,
        hint,
        hasError,
        description,
        accentText,
        ...rest,
      };
      return { ...componentProps, onChange, value };
    }, [rowNum, placeholder, onChange, value]);

    const classBase = 'ickyc-textarea';

    const classes = classNames({
      [`${classBase}`]: true,
      [`${classBase}--top-labeled`]: labelPosition === 'top' || isBelowTablet,
      [`${classBase}--disabled`]: disabled,
      [`${classBase}--preview`]: preview,
      [`${classBase}--error`]: hasError,
      [`${classBase}--required`]: !preview && required,
      [`${classBase}--red-colored-text`]: accentText,
      [className]: className,
    });

    // Expand verticaly if needed
    useEffect(() => {
      if (autoExpand && textAreaRef.current && textAreaRef.current.scrollHeight) {
        textAreaRef.current.style.height = 'auto';
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    }, [value]);

    return (
      <div className={classes}>
        {label && (
          <label>
            {label}
            {hint && <Tooltip trigger={<HelpIcon />} content={<span>{hint}</span>} />}
          </label>
        )}
        <div className={`${classBase}__content`}>
          <textarea {...textareaProps} disabled={disabled} ref={textAreaRef} {...rest} />
          {hasError && <span>{errorMsg}</span>}
        </div>
        {description && <FieldDescription text={description} />}
      </div>
    );
  },
);

Textarea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  preview: PropTypes.bool,
  onChange: PropTypes.func,
  rowNum: PropTypes.number,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  errorMsg: PropTypes.string,
  hint: PropTypes.string,
  hasError: PropTypes.bool,
  description: PropTypes.string,
  accentText: PropTypes.bool,
  autoExpand: PropTypes.bool,
  maxLength: PropTypes.number,
};

Textarea.defaultProps = {
  placeholder: '',
  preview: false,
  onChange: null,
  rowNum: 3,
  value: '',
  disabled: false,
  required: false,
  className: undefined,
  errorMsg: undefined,
  hint: undefined,
  hasError: false,
  label: undefined,
  description: undefined,
  accentText: false,
  autoExpand: undefined,
  maxLength: undefined,
};

export default Textarea;
