import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import CheckboxField from '../../../../components/CheckboxField';
import IconManager from '../../../../utilities/services/IconManager';
import './styles.scss';

const DocumentTypesCheckboxes = ({ modulePreffix }) => {
  return (
    <div className="ickyc-document-types-checkboxes">
      <Field
        component={CheckboxField}
        type="checkbox"
        classNameSuffix="--driver"
        name={`${modulePreffix}.driverLicense`}
        icon={IconManager.names.GEAR_GREEN}
        label="Driver's Licence"
      />
      <Field
        component={CheckboxField}
        type="checkbox"
        name={`${modulePreffix}.idCard`}
        icon={IconManager.names.GEAR_ORANGE}
        classNameSuffix="--identity-card"
        label="Identity Card"
      />
      <Field
        component={CheckboxField}
        type="checkbox"
        classNameSuffix="--passport"
        name={`${modulePreffix}.passport`}
        icon={IconManager.names.GEAR_GREEN}
        label="Passport"
      />
    </div>
  );
};

DocumentTypesCheckboxes.propTypes = {
  modulePreffix: PropTypes.string,
};

DocumentTypesCheckboxes.defaultProps = {
  modulePreffix: '',
};

export default React.memo(DocumentTypesCheckboxes);
