import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import SelectCustomContent from '../../../../../../../../../components/Select/components/SelectCustomContent';
import SelectInputTrigger from '../../../../../../../../../components/Select/components/SelectInputTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import TextEditorField from '../../../../../../../../../components/TextEditorField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import './styles.scss';

const ModuleTitleSettings = ({
  namePreffix,
  index,
  withActionButton,
  withRedirectUrl,
  withForwardButton,
  withBackButton,
  withDescription,
  withRequireUserToggle,
  withAddressTypes,
  withTermForNP,
  withTermForLE,
  withCollectIdentityToggle,
  withTwoIDDocuments,
  AdditionalFieldsComponent,
}) => {
  const baseClass = 'ickyc-module-title-settings';

  const fieldsNamePreffix = useMemo(() => `modules[${index}]${namePreffix ? `.${namePreffix}` : ''}`, [
    namePreffix,
    index,
  ]);

  return (
    <div className={baseClass}>
      {withDescription && <Field name={`${fieldsNamePreffix}.text`} component={TextEditorField} />}
      {withTwoIDDocuments && (
        <div className={`${baseClass}__button-container`}>
          <Field
            name={`${fieldsNamePreffix}.requireTwoIDDocuments`}
            component={ToggleField}
            label="Require 2 ID documents to be submitted "
          />
        </div>
      )}
      {AdditionalFieldsComponent && <AdditionalFieldsComponent index={index} />}
      {withActionButton && (
        <div className={`${baseClass}__action-button-text`}>
          <Field name={`${fieldsNamePreffix}.buttonText`} component={InputField} placeholder="Action Button Text" />
        </div>
      )}
      {withCollectIdentityToggle && (
        <div className={`${baseClass}__button-container`}>
          <Field
            name={`modules[${index}].idScanEnabled`}
            component={ToggleField}
            label="Collect identity document(s) as a first step and ask user to confirm extracted information"
          />
        </div>
      )}
      {withRedirectUrl && (
        <div className={`${baseClass}__button-container`}>
          <Field name={`${fieldsNamePreffix}.redirectToUrl`} component={InputField} placeholder="Redirect to URL" />
        </div>
      )}

      {withBackButton && (
        <div className={`${baseClass}__button-container`}>
          <Field name={`${fieldsNamePreffix}.backButtonText`} component={InputField} placeholder="Back Button Text" />
        </div>
      )}

      {withForwardButton && (
        <div className={`${baseClass}__button-container`}>
          <Field name={`${fieldsNamePreffix}.buttonText`} component={InputField} placeholder="Forward Button Text" />
        </div>
      )}

      {withTermForNP && (
        <div className={`${baseClass}__button-container`}>
          <Field name={`${fieldsNamePreffix}.npTherm`} component={InputField} placeholder="Term for Natural Person" />
        </div>
      )}
      {withTermForLE && (
        <div className={`${baseClass}__button-container`}>
          <Field name={`${fieldsNamePreffix}.leTherm`} component={InputField} placeholder="Term for Legal Entity" />
        </div>
      )}
      {withAddressTypes && (
        <div className={`${baseClass}__button-container`}>
          <Field
            component={SelectField}
            name={`${fieldsNamePreffix}.removedTagsOnCompletion`}
            Trigger={<SelectInputTrigger placeholder="Address Types Selectable by Subject" />}
            Content={<SelectCustomContent />}
            multipleSelection
            withCustomOptions
          />
        </div>
      )}
      {withTermForNP && (
        <div className={`${baseClass}__button-container`}>
          <Field name={`${fieldsNamePreffix}.npTherm`} component={InputField} placeholder="Term for Natural Person" />
        </div>
      )}
      {withTermForLE && (
        <div className={`${baseClass}__button-container`}>
          <Field name={`${fieldsNamePreffix}.leTherm`} component={InputField} placeholder="Term for Legal Entity" />
        </div>
      )}

      <div className={`${baseClass}__tags-container`}>
        <Field
          component={SelectField}
          name={`${fieldsNamePreffix}.addedTagsOnCompletion`}
          multipleSelection
          withCustomOptions
          Trigger={<SelectInputTrigger placeholder="Add Tag to Subject Upon Arrival" />}
          Content={<SelectCustomContent />}
        />
        <div className={`${baseClass}__spacer`} />
        <Field
          component={SelectField}
          name={`${fieldsNamePreffix}.removedTagsOnCompletion`}
          Trigger={<SelectInputTrigger placeholder="Remove Tag from Subject Upon Completion" />}
          Content={<SelectCustomContent />}
          multipleSelection
          withCustomOptions
        />
      </div>
      {withRequireUserToggle && (
        <div className={`${baseClass}__require-user-container`}>
          <Field
            name={`${fieldsNamePreffix}.requireMinimumOneJurisdiction`}
            component={ToggleField}
            label="Require the User to Submit a Minimum of 1 Jurisdiction to Proceed"
          />
        </div>
      )}
    </div>
  );
};
ModuleTitleSettings.propTypes = {
  namePreffix: PropTypes.string,
  index: PropTypes.number.isRequired,
  withActionButton: PropTypes.bool,
  withRedirectUrl: PropTypes.bool,
  withForwardButton: PropTypes.bool,
  withBackButton: PropTypes.bool,
  withDescription: PropTypes.bool,
  withRequireUserToggle: PropTypes.bool,
  withAddressTypes: PropTypes.bool,
  withTermForNP: PropTypes.bool,
  withTermForLE: PropTypes.bool,
  withCollectIdentityToggle: PropTypes.bool,
  withTwoIDDocuments: PropTypes.bool,
  AdditionalFieldsComponent: PropTypes.elementType,
};

ModuleTitleSettings.defaultProps = {
  namePreffix: undefined,
  withActionButton: false,
  withRedirectUrl: false,
  withForwardButton: false,
  withBackButton: false,
  withDescription: false,
  withRequireUserToggle: false,
  withAddressTypes: false,
  withTermForNP: false,
  withTermForLE: false,
  withCollectIdentityToggle: false,
  withTwoIDDocuments: false,
  AdditionalFieldsComponent: null,
};
export default ModuleTitleSettings;
