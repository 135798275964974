import utils from 'store/utils';
import * as actions from '../actions/global.action';

const { globalInitialState } = utils;

export default function(state = globalInitialState, action) {
  switch (action.type) {
    case actions.COUNTRIES_SET: {
      return {
        ...state,
        countries: action.payload,
      };
    }
    case actions.RISK_LEVELS_SAVE: {
      return {
        ...state,
        riskLevels: action.payload,
      };
    }
    case actions.USERS_TO_ASSIGN_SET: {
      return {
        ...state,
        usersToAssign: action.payload,
      };
    }
    case actions.DOCUMENT_TYPES_SET: {
      return {
        ...state,
        packagesTypes: action.payload,
      };
    }
    case actions.DEFAULT_SCREENING_PROFILE_SET: {
      const { searchTerms } = action.payload;
      const hasSearchTerms = searchTerms?.length;
      return {
        ...state,
        defaultScreeningProfile: { ...action.payload, searchTerms: hasSearchTerms ? searchTerms : [{}] },
      };
    }
    case actions.CURRENCIES_SET: {
      return {
        ...state,
        currencies: action.payload,
      };
    }
    case actions.NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    case actions.RESET_STORE: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}
