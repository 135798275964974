import Accordion from 'components/Accordion';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import EmailMessage from 'scenes/Administration/Emails/components/EmailMessage';
import rcuEnums from 'utilities/enums/rcuEnums';
import setClassSuffix from 'utilities/services/ClassManager';

import './styles.scss';

const GeneralSettingsAutomation = ({ namePrefix, errMessage }) => {
  const baseClass = 'ickyc-general-settings';
  const setSuffix = setClassSuffix(baseClass);

  const { batch, change } = useForm();

  const { values } = useFormState();

  const generalSettings = useMemo(
    () => (values[namePrefix] ? values[namePrefix].generalSettings : values.generalSettings),
    [values, namePrefix],
  );

  const { emailMessage: emailMessageInitial, emailSubject, linkText, supportEmail } = generalSettings || {};

  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  useEffect(() => {
    if (emailSubject) {
      batch(() => {
        change(`${namePrefixWithDot}generalSettings.emailSubject`, emailSubject);
      });
    }

    if (emailMessageInitial) {
      batch(() => {
        change(`${namePrefixWithDot}generalSettings.emailMessage`, emailMessageInitial);
      });
    }

    if (linkText) {
      batch(() => {
        change(`${namePrefixWithDot}generalSettings.linkText`, linkText);
      });
    }
    if (supportEmail) {
      batch(() => {
        change(`${namePrefixWithDot}generalSettings.supportEmail`, supportEmail);
      });
    }
  }, [emailMessageInitial, emailSubject, linkText, supportEmail, batch, change]);

  return (
    <Accordion
      accordionindex={rcuEnums.ACCORDION_INDEXES.GENERAL_SETTINGS}
      title={
        <>
          <h3>General Settings</h3>
          <span className="ickyc-spacer" />
          {errMessage && <div className={setSuffix('__error')}>{errMessage}</div>}
        </>
      }
    >
      <div className={baseClass}>
        <div>
          <span className={setSuffix('__label')}>Email Subject</span>
          <Field
            className={setSuffix('__input-box')}
            name={`${namePrefixWithDot}generalSettings.emailSubject`}
            placeholder="Subject"
            component={InputField}
          />
        </div>
        <div>
          <EmailMessage
            className={setSuffix('__input-box')}
            messageName={`${namePrefixWithDot}generalSettings.emailMessage`}
            linkTextName={`${namePrefixWithDot}generalSettings.linkText`}
            supportEmailName={`${namePrefixWithDot}generalSettings.supportEmail`}
            label={'Email Message'}
          />
        </div>
      </div>
    </Accordion>
  );
};
GeneralSettingsAutomation.propTypes = {
  domains: PropTypes.array,
  errMessage: PropTypes.string,
};
GeneralSettingsAutomation.defaultProps = {
  domains: [],
  errMessage: null,
};
export default GeneralSettingsAutomation;
