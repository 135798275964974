import { Lock } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import ModuleSettings from '../ModuleSettings';
import ModuleTitleSettings from '../ModuleTitleSettings';
import './styles.scss';

const FinalStepModuleLE = ({ index, indexToShow, isDropAllowed }) => {
  const className = 'ickyc-table';
  const classes = classNames([{ 'ickyc-final-step-white-section': indexToShow % 2 === 0 }, className]);

  const {
    input: { value: toggleValue },
  } = useField(`modules[${index}].isEnabled`);

  return (
    <CollapsibleTableRow
      isDropAllowed={isDropAllowed}
      className={classes}
      withIndexColumn
      index={indexToShow}
      isToggled={toggleValue}
      icon={<Lock />}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Final Screen</td>
          <td className={`${className}__column__enabled`} onClick={e => e.stopPropagation()}>
            <Field
              preview
              name={`modules[${index}].isEnabled`}
              component={ToggleField}
              showCustomText
              toggleOnText="Enabled"
            />
          </td>
        </>
      }
    >
      <ModuleSettings>
        <ModuleTitleSettings index={index} withDescription withActionButton withRedirectUrl />
      </ModuleSettings>
    </CollapsibleTableRow>
  );
};
FinalStepModuleLE.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
};
export default FinalStepModuleLE;
