import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';
import CountrySelectField from '../../../../../../../../components/CountrySelectField';
import InputField from '../../../../../../../../components/InputField';
import SelectFilterContent from '../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../components/SelectField';
import StateProvinceSelectField from '../../../../../../../../components/StateProvinceSelectField';
import enums from '../../../../../../../../utilities/enums';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';
import validators from '../../../../../../../../utilities/services/validators';
import './index.scss';

const AddressForm = ({ responsive, preview, namePreffix, checkList }) => {
  const baseClass = !responsive ? 'ickyc-address-info' : 'ickyc-address-info--responsive';

  const { values } = useFormState();
  const { countryId = 0 } = namePreffix.split('.').reduce((acc, namePref) => acc[namePref], values) || {};

  const mappedAddressTypes = useMemo(() => {
    return Object.entries(enums.ADDRESS_TYPES).map(type => ({
      label: type[0],
      value: type[1],
      id: type[1],
    }));
  }, [preview]);

  const setSuffix = setClassSuffix(baseClass);

  return (
    <div className={baseClass}>
      <div className={setSuffix('__block')}>
        <Field
          component={SelectField}
          name={`${namePreffix}.addressTypes`}
          accentText={!checkList?.addressTypeStatus}
          label="Address Type"
          multipleSelection
          preview={preview}
          options={mappedAddressTypes}
          validate={validators.required()}
          required
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContent />}
        />
        <Field
          component={CountrySelectField}
          label="Country"
          validate={validators.required()}
          required
          name={`${namePreffix}.countryId`}
          accentText={!checkList?.countryIdStatus}
          preview={preview}
          autoClose
        />
        <Field
          component={StateProvinceSelectField}
          name={`${namePreffix}.stateProvinceId`}
          accentText={!checkList?.stateProvinceIdStatus}
          label="Province"
          preview={preview}
          countryId={countryId}
        />

        <Field
          component={InputField}
          label="City"
          preview={preview}
          name={`${namePreffix}.city`}
          accentText={!checkList?.cityStatus}
        />
      </div>
      <div className={setSuffix('__block')}>
        <Field
          component={InputField}
          label="Postal/Zip"
          preview={preview}
          name={`${namePreffix}.postalZip`}
          accentText={!checkList?.postalZipStatus}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.street`}
          accentText={!checkList?.streetStatus}
          label="Street"
          type="text"
          preview={preview}
        />
        <Field
          component={InputField}
          name={`${namePreffix}.unit`}
          accentText={!checkList?.unitStatus}
          label="Unit"
          type="text"
          preview={preview}
        />
      </div>
    </div>
  );
};

AddressForm.propTypes = {
  responsive: PropTypes.bool,
  preview: PropTypes.bool,
  namePreffix: PropTypes.string,
  checkList: PropTypes.shape({
    addressTypeStatus: PropTypes.bool,
    countryIdStatus: PropTypes.bool,
    stateProvinceIdStatus: PropTypes.bool,
    cityStatus: PropTypes.bool,
    postalZipStatus: PropTypes.bool,
    streetStatus: PropTypes.bool,
    unitStatus: PropTypes.bool,
  }),
};

AddressForm.defaultProps = {
  responsive: false,
  preview: false,
  namePreffix: 'addressInformation',
  checkList: {
    addressTypeStatus: true,
    countryIdStatus: true,
    stateProvinceIdStatus: true,
    cityStatus: true,
    postalZipStatus: true,
    streetStatus: true,
    unitStatus: true,
  },
};

export default AddressForm;
