import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import LinkButton from '../Buttons/LinkButton';
import './styles.scss';

const DropdownMenu = ({ list }) => {
  const baseClass = 'ickyc-dropdown-menu';

  const classes = classNames([baseClass, `${baseClass}--horizontal`]);

  const renderListItem = useCallback(
    ({ asLink, path, icon, text, items, onClick, modal, modalRef, className }) =>
      asLink ? (
        <li key={path} className={className}>
          <LinkButton path={path}>
            {icon}
            {text}
          </LinkButton>
          {items && <DropdownMenu list={items} />}
        </li>
      ) : (
        <li onClick={onClick} key={text + items?.length} className={className}>
          <div ref={modalRef}>
            {icon}
            {text}
            {modal}
          </div>
          {items && <DropdownMenu list={items} />}
        </li>
      ),
    [],
  );

  return (
    <ul className={classes}>
      {list.map(item => {
        const { guardElement: GuardComponent } = item;
        if (GuardComponent) {
          return React.cloneElement(GuardComponent, { children: renderListItem(item) });
        }
        return renderListItem(item);
      })}
    </ul>
  );
};

DropdownMenu.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      text: PropTypes.string,
      asLink: PropTypes.bool,
      path: PropTypes.string,
      onClick: PropTypes.func,
      modal: PropTypes.bool,
      modalRef: undefined,
      className: PropTypes.string,

      items: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.element,
          text: PropTypes.string,
          /**
           * guardElement -> Protected or Private component
           */
          guardElement: PropTypes.element,
          /**
           * asLink -> list item is rendered as LinkButton
           */
          asLink: PropTypes.bool,
          path: PropTypes.string,
          onClick: PropTypes.func,
        }),
      ),
    }),
  ).isRequired,
};
export default DropdownMenu;
