import { useEffect } from 'react';
import bus from '../modules/bus';

// TODO WRITE
/**
 *
 */
export default (eventName, overrideTable, containerRef) => {
  useEffect(() => {
    const handleOverride = ({ payload, index, shouldFocus }) => {
      if (payload) {
        const { data, paginationData } = payload;
        overrideTable(data, paginationData);
      }
      if (index > -1) {
        const nodelist = containerRef.current.querySelectorAll(`.ickyc-table__row`);
        const targetRow = nodelist[index];
        const isCollapsed = targetRow.classList.contains(`.ickyc-table__row--exteded`);
        if (!isCollapsed) targetRow.click();
        if (shouldFocus) {
          setTimeout(() => {
            targetRow.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }, 500);
        }
      }
    };

    bus.addEventListener(eventName, handleOverride);

    return () => {
      bus.removeEventListener(eventName, handleOverride);
    };
  }, [eventName, overrideTable, containerRef]);
};
