import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import IconManager from '../../utilities/services/IconManager';
import PrimaryButton from '../Buttons/PrimaryButton';
import TagButton from '../Buttons/TagButton';
import './styles.scss';

const TagsContainer = ({
  options,
  onRemove,
  onClick,
  onAdd,
  preview,
  red,
  outline,
  filtersToHighlite,
  showMoreAfterNumberOfTags,
  withoutRemove,
}) => {
  const filteredOptions = useMemo(() => {
    return filtersToHighlite?.length
      ? options.sort((a, b) => {
          if (filtersToHighlite.some(val => val === a.name) && !filtersToHighlite.some(val => val === b.name))
            return -1;
          if (!filtersToHighlite.some(val => val === a.name) && filtersToHighlite.some(val => val === b.name)) return 1;
          return 0;
        })
      : options;
  }, [filtersToHighlite, options]);

  const baseClass = 'ickyc-tag-buttons-container';

  const [shouldViewAll, setShouldViewAll] = useState(true);
  const handleRemove = useCallback(
    (e, item) => {
      e.stopPropagation();
      onRemove(item);
    },
    [onRemove],
  );

  const handleOnAdd = useCallback(
    e => {
      e.stopPropagation();
      onAdd();
    },
    [onAdd],
  );

  const handleOnClick = useCallback(
    (e, item) => {
      e.stopPropagation();
      onClick(item);
    },
    [onClick],
  );

  const onLoadMore = useCallback(() => {
    setShouldViewAll(prev => !prev);
  }, []);

  const showLoadMoreButton = useCallback(() => {
    return (
      <PrimaryButton variant="link" onClick={onLoadMore} red={red}>
        {shouldViewAll ? 'Show More' : 'Show Less'}
      </PrimaryButton>
    );
  }, [shouldViewAll, onLoadMore, red]);

  return (
    <div className={baseClass}>
      {filteredOptions.length > 0 &&
        filteredOptions
          .slice(0, shouldViewAll ? showMoreAfterNumberOfTags : filteredOptions.length)
          .map(opt => (
            <TagButton
              text={opt.name}
              key={opt.name}
              preview={withoutRemove || preview}
              onClick={e => handleOnClick(e, opt)}
              onIconClick={ev => handleRemove(ev, opt)}
              red={red}
              outline={outline}
              filledRed={filtersToHighlite.some(el => el === opt.name)}
            />
          ))}
      {filteredOptions.length > showMoreAfterNumberOfTags && showLoadMoreButton()}
      {!preview && (
        <TagButton
          className="ickyc-tag-button--add"
          onClick={handleOnAdd}
          icon={IconManager.get(IconManager.names.PLUS_CIRCULAR)}
        />
      )}
    </div>
  );
};

TagsContainer.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape),
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
  onAdd: PropTypes.func,
  preview: PropTypes.bool,
  outline: PropTypes.bool,
  withoutRemove: PropTypes.bool,
  red: PropTypes.bool,
  filtersToHighlite: PropTypes.array,
  showMoreAfterNumberOfTags: PropTypes.number,
};
TagsContainer.defaultProps = {
  options: [],
  onAdd: () => {},
  onRemove: () => {},
  onClick: () => {},
  preview: false,
  red: false,
  outline: false,
  filtersToHighlite: [],
  showMoreAfterNumberOfTags: 3,
  withoutRemove: false,
};
export default TagsContainer;
