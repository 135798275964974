import classNames from 'classnames';
import useModalHandler from 'hooks/useModalHandler';
import React, { useCallback } from 'react';
import DateManager from 'utilities/services/DateManager';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import EmailTemplateModal from '../EmailTemplateModal';
import './styles.scss';

const EmailTemplateRow = ({ rowInfo, type, className, onRemove, handleSubmit }) => {
  const { domain, portalId, ...restRowInfo } = rowInfo;
  const { isOpen, open, close } = useModalHandler();

  const classes = classNames('ickyc-email-template-row ickyc-table__row', className);

  const handleRemove = useCallback(event => {
    event.stopPropagation();
    onRemove(rowInfo.id);
  }, []);

  return (
    <>
      <tr className={classes} onClick={() => open()}>
        <td>{domain}</td>
        <td>{rowInfo?.emailSubject}</td>
        <td>{DateManager.getFullDateLocal(rowInfo?.created)}</td>
        <td>
          <DeleteButton onClick={handleRemove} />
        </td>
      </tr>
      {isOpen && (
        <EmailTemplateModal
          type={type}
          hideModal={close}
          initialValues={{ domain: { domain: domain, portalId: portalId }, ...restRowInfo }}
          handleSubmit={values => handleSubmit(values, close)}
        />
      )}
    </>
  );
};

export default EmailTemplateRow;
