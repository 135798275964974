import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

const CachedCollapsible = props => {
  let { collapsible, children } = props;
  const { initialData } = props;

  const [data, setData] = useState(initialData);

  children = useMemo(() => {
    return React.cloneElement(children, { data, onDataFetch: setData });
  }, [data, children]);

  collapsible = useMemo(() => {
    return React.cloneElement(collapsible, {}, children);
  }, [collapsible, children]);

  return collapsible;
};

CachedCollapsible.propTypes = {
  collapsible: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  initialData: PropTypes.shape({}),
};
CachedCollapsible.defaultProsp = {
  initialData: {},
};

export default CachedCollapsible;
