import ToggleField from 'components/ToggleField';
import React from 'react';
import { Field } from 'react-final-form';

const CorporateDetailsFormRow = ({ className, index, label, name }) => {
  return (
    <>
      <div>
        <b>{label}</b>
      </div>
      <div className={className}>
        <Field component={ToggleField} name={`modules[${index}].corporateDetails.details.${name}.shown`} />
      </div>
      <div className={className}>
        <Field component={ToggleField} name={`modules[${index}].corporateDetails.details.${name}.required`} />
      </div>
    </>
  );
};

export default CorporateDetailsFormRow;
