import React, { useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';
import Accordion from '../../../../../components/Accordion';
import ToggleField from '../../../../../components/ToggleField';
import ProtectedComponent from '../../../../../router/components/ProtectedComponent';
import authEnums from '../../../../../utilities/enums/authEnums';
import AutoAssignSelect from '../../../components/AutoAssignSelect';
import AutoAssignmentControls from '../../../components/AutoAssignmentControls';
import AutoAssignmentSettings from '../../../components/AutoAssignmentSettings';
import FieldSupportingDocsList from '../FieldSupportingDocsList';
import './styles.scss';

const AutoAssignmentForm = ({ namePrefix, displaySupportingDocumentsSection = true }) => {
  const baseClass = 'ickyc-auto-assignment-form';
  const sectionClass = 'ickyc-white-section';
  const graySectionClass = 'ickyc-gray-section';
  const namePrefixWithDot = useMemo(() => (namePrefix ? `${namePrefix}.` : ''), [namePrefix]);
  const { values } = useFormState();

  const {
    autoAssignSupportingDocumentPackagesEnabled,
    autoAssignIdentityAuthenticationEnabled,
    autoAssignBiometricAuthenticationEnabled,
    autoAssignDocumentAuthenticationEnabled,
    autoAssignEntityProfileEnabled,
  } = values[namePrefix] ? values[namePrefix] : values;

  const handleOnClickEvent = e => {
    e.stopPropagation();
  };
  return (
    <div className={baseClass}>
      <Accordion
        title={
          <>
            <div onClick={handleOnClickEvent}>
              <Field
                name={`${namePrefixWithDot}autoAssignEntityProfileEnabled`}
                component={ToggleField}
                label="Entity Profile"
              />
            </div>
          </>
        }
        accordionOpen={autoAssignEntityProfileEnabled}
        className={graySectionClass}
      >
        <AutoAssignmentSettings
          disabled={false}
          tableName={`${namePrefixWithDot}entityProfile`}
          propertyName="autoAssignEntityProfileUserId"
        />
      </Accordion>

      <Accordion
        title={
          <>
            <div onClick={handleOnClickEvent}>
              <Field
                name={`${namePrefixWithDot}autoAssignIdentityAuthenticationEnabled`}
                component={ToggleField}
                label="Identity Authentication event"
              />
            </div>
          </>
        }
        accordionOpen={autoAssignIdentityAuthenticationEnabled}
        className={sectionClass}
      >
        <AutoAssignmentSettings
          disabled={false}
          tableName={`${namePrefixWithDot}identityAuthenticationEvent`}
          propertyName="autoAssignIdentityAuthenticationUserId"
        />
      </Accordion>

      <Accordion
        title={
          <>
            <div onClick={handleOnClickEvent}>
              <Field
                name={`${namePrefixWithDot}autoAssignDocumentAuthenticationEnabled`}
                component={ToggleField}
                label="Document Authentication event"
              />
            </div>
          </>
        }
        accordionOpen={autoAssignDocumentAuthenticationEnabled}
        className={graySectionClass}
      >
        <AutoAssignmentSettings
          disabled={false}
          tableName={`${namePrefixWithDot}documentAuthenticationEvent`}
          propertyName="autoAssignDocumentAuthenticationUserId"
        />
      </Accordion>

      <Accordion
        title={
          <>
            <div onClick={handleOnClickEvent}>
              <Field
                name={`${namePrefixWithDot}autoAssignBiometricAuthenticationEnabled`}
                component={ToggleField}
                label="Biometric Authentication event"
              />
            </div>
          </>
        }
        accordionOpen={autoAssignBiometricAuthenticationEnabled}
        className={sectionClass}
      >
        <AutoAssignmentSettings
          disabled={false}
          tableName={`${namePrefixWithDot}biometricAuthenticationEvent`}
          propertyName="autoAssignBiometricAuthenticationUserId"
        />
      </Accordion>
      {displaySupportingDocumentsSection && (
        <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.SUPPORTING_DOCUMENTS}>
          <Accordion
            title={
              <>
                <div onClick={handleOnClickEvent}>
                  <Field
                    name={`${namePrefixWithDot}autoAssignSupportingDocumentPackagesEnabled`}
                    component={ToggleField}
                    label="Supporting Document Packages"
                  />
                </div>
              </>
            }
            accordionOpen={autoAssignSupportingDocumentPackagesEnabled}
            className={graySectionClass}
          >
            <FieldSupportingDocsList
              modulePreffix="automationsSupportingDocsTransformed"
              TableControlsComponent={AutoAssignmentControls}
              tableRow={AutoAssignSelect}
              withInfiniteScroll
              disabled={false}
              propertyName="autoAssignedUserId"
            />
          </Accordion>
        </ProtectedComponent>
      )}
    </div>
  );
};
export default AutoAssignmentForm;
