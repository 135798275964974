import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-final-form';
import Spinner from '../../../../../../../../components/Spinner';
import useFaceApi from '../../../../../../../../hooks/useFaceApi';
import OpenCVService from '../../../../../../../../utilities/services/OpenCVService';
import DocumentMiniUploader from '../../../DocumentMiniUploader';
import './styles.scss';

const processImageSize = 600;

const FaceImagePreview = ({ value, onChange, uploadHandlers, faceDetection, error, onFaceNotDetectedHandler }) => {
  const imageCanvasPreviewRef = useRef();
  const previewWrapperRef = useRef();
  const faceapi = useFaceApi();
  const { data = {}, preview } = value;
  const [processingImage, setProcessingImage] = useState(true);

  const { blur } = useForm();

  const saveValue = useCallback(
    newValues => {
      blur('faceImage');
      onChange({ ...value, data: newValues });
    },
    [value, onChange, blur],
  );

  useEffect(() => {
    const processImage = () => {
      setProcessingImage(true);
      const image = new Image();
      onFaceNotDetectedHandler(false);
      image.onload = async () => {
        setProcessingImage(true);
        if (faceDetection && faceapi?.status !== 'succeeded') return;
        try {
          const smallImageCanvas = document.createElement('canvas');
          const smallImageCTX = smallImageCanvas.getContext('2d');
          if (image.width < image.height) {
            const origImgRatio = image.width / image.height;
            smallImageCanvas.width = processImageSize * origImgRatio;
            smallImageCanvas.height = processImageSize;
          } else {
            const origImgRatio = image.height / image.width;
            smallImageCanvas.width = processImageSize;
            smallImageCanvas.height = processImageSize * origImgRatio;
          }
          // drawing faceimage image
          // drawing faceimage image
          smallImageCTX.drawImage(image, 0, 0, smallImageCanvas.width, smallImageCanvas.height);
          if (faceDetection) {
            const face = await faceapi.detectFace(smallImageCanvas);
            if (!face) {
              onFaceNotDetectedHandler(true);
              // eslint-disable-next-line no-throw-literal
              throw 'Face was not detected';
            }
            saveValue({
              angle: 0,
              // ...size,
            });
          }
        } catch (err) {
          console.log(err);
          // const size = calculateImageSize(image.width, image.height);
          saveValue({
            errorMessage: err,
            // ...size,
          });
        } finally {
          setProcessingImage(false);
        }
      };
      image.src = preview;
    };
    if (JSON.stringify(data) === '{}') {
      processImage();
    }
  }, [data, value, faceapi]);

  useEffect(() => {
    const calculateImageSize = (width, height) => {
      const dropzone = document.querySelector('.ickyc-document-detection-dropzone');
      const imageWidth = width;
      const imageHeight = height;
      const aspectRatio = imageWidth / imageHeight;

      let newWidth = previewWrapperRef.current.clientWidth;
      let newHeight = newWidth / aspectRatio;
      if (newWidth > dropzone.clientWidth) {
        newWidth = dropzone.clientWidth;
        newHeight = newWidth / aspectRatio;
      }
      if (dropzone.clientWidth < 500) {
        newWidth = dropzone.clientWidth;
        newHeight = newWidth / aspectRatio;
      }
      return {
        width: newWidth,
        height: newHeight,
      };
    };
    const restoreDectionData = () => {
      const { angle } = data;
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);

        if (angle) {
          OpenCVService.rotateImage(canvas, angle);
        }

        const targetCanvas = imageCanvasPreviewRef.current;
        const targetContext = targetCanvas.getContext('2d');

        const size = calculateImageSize(canvas.width, canvas.height);
        targetCanvas.width = size.width;
        targetCanvas.height = size.height;
        targetContext.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, size.width, size.height);
      };
      image.src = preview;
    };
    if (JSON.stringify(data) !== '{}' && !processingImage && imageCanvasPreviewRef.current) {
      restoreDectionData();
    }
  }, [processingImage, data, imageCanvasPreviewRef, preview]);

  const baseClass = 'ickyc-detection-preview';

  return (
    <div className={baseClass} ref={previewWrapperRef}>
      {processingImage ? (
        <Spinner message="Processing Image" />
      ) : (
        <>
          <DocumentMiniUploader handlers={uploadHandlers} />
          <div className={`${baseClass}__zone`}>
            <canvas ref={imageCanvasPreviewRef} />

            {error && (
              <div className={`${baseClass}__error`}>
                <span className={`${baseClass}__error-message`}>{error}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

FaceImagePreview.propTypes = {
  value: PropTypes.shape({
    file: PropTypes.instanceOf(File),
    preview: PropTypes.string,
    data: PropTypes.object,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  uploadHandlers: PropTypes.func.isRequired,
  faceDetection: PropTypes.bool,
  error: PropTypes.string,
  onFaceNotDetectedHandler: PropTypes.func,
};
FaceImagePreview.defaultProps = {
  faceDetection: true,
  error: null,
  onFaceNotDetectedHandler: () => {},
};
export default FaceImagePreview;
