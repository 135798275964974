import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import api from '../../../../../api';
import ConfirmationPage from '../../../../../components/Modal/ConfirmationPage';
import InformationModal from '../../../../../components/Modal/InformationModal';
import WizardFormModal from '../../../../../components/Modal/WizardFormModal';
import WizardFormPage from '../../../../../components/Modal/WizardFormModal/components/WizardFormPage';
import IconManager from '../../../../../utilities/services/IconManager';
import CSVDataPreview from './components/CSVDataPreview';
import CSVImportForm from './components/CSVImportForm';
import './styles.scss';

const CSVUploadModal = ({ hideModal }) => {
  const [showConfirmationmModal, setShowConfirmationModal] = useState(false);
  const [shiftFocusToSubmit, setShiftFocusToSubmit] = useState(false);
  const pageRef = useRef(null);

  const onSubmit = values => {
    if (values.isNaturalPerson === 'true') {
      return api.kyc.entityManagement.naturalPerson
        .importCSV(values.csvFile[0].file)
        .then(() => {
          setShowConfirmationModal(true);
        })
        .catch(err => {
          if (err?.response) {
            const {
              data: { status },
              data,
            } = err.response;
            if (status === 400) {
              if (data?.title.includes('validation')) {
                return { [FORM_ERROR]: data?.errors['Validation error'] || data?.title };
              }
            }
            if (status > 400 && status < 500) {
              return { [FORM_ERROR]: data?.errors || data.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: data?.errors || data.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Unexpected Error occured' };
        });
    }
    return api.kyc.entityManagement.legalEntity
      .importCSV(values.csvFile[0].file)
      .then(() => {
        setShowConfirmationModal(true);
      })
      .catch(err => {
        if (err?.response) {
          const {
            data: { status },
            data,
          } = err.response;
          if (status === 400) {
            if (data?.title.includes('validation')) {
              return { [FORM_ERROR]: data?.errors['Validation error'] || data?.title };
            }
          }
          if (status > 400 && status < 500) {
            return { [FORM_ERROR]: data?.errors || data.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: data?.errors || data.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Unexpected Error occured' };
      });
  };

  if (showConfirmationmModal) {
    return (
      <InformationModal
        hideModal={hideModal}
        onClick={() => {
          hideModal();
          setShowConfirmationModal(false);
        }}
      >
        <ConfirmationPage
          icon={IconManager.get(IconManager.names.NEWS)}
          title="Preparing CSV Upload"
          onClick={hideModal}
          icons={<MailOutlineIcon />}
          text={
            <>
              We are adding the additional entities to the database. <br />
              We’ll send you an email when the process is complete.
            </>
          }
        />
      </InformationModal>
    );
  }

  return (
    <div ref={pageRef}>
      <WizardFormModal
        onSubmitHandler={onSubmit}
        initialValues={{ isNaturalPerson: 'true' }}
        hideModal={() => {
          hideModal();
        }}
        buttonTitles={['Upload CSV', 'Confirm CSV Upload']}
        className="ickyc-csv-upload-modal"
        containerRef={pageRef}
        titles={['Upload CSV', 'Confirm CSV Upload']}
        showErrorsInConfirmation
        shiftFocusToSubmit={shiftFocusToSubmit}
      >
        <WizardFormPage>
          <CSVImportForm setShiftFocusToSubmit={setShiftFocusToSubmit} />
        </WizardFormPage>
        <WizardFormPage>
          <CSVDataPreview />
        </WizardFormPage>
      </WizardFormModal>
    </div>
  );
};

CSVUploadModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default CSVUploadModal;
