import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useField } from 'react-final-form';
import { SelectContext } from '../..';
import useDebounce from '../../../../hooks/useDebounce';
import useEffectSkipFirst from '../../../../hooks/useEffectSkipFirst';
import useSelect from '../../../../hooks/useSelect';
import { selectCountries } from '../../../../store/selectors/global.selector';
import SingleCountry from '../../../CountrySelect/components/SingleCountry';
import './styles.scss';

const SelectCustomContentSpecial = ({ callback, changeParams, inputFieldName }) => {
  const {
    hoverIndex,
    onChange,
    multipleSelection,
    withCustomOptions,
    openContent,
    filterProps,
    closeContent,
  } = useContext(SelectContext);

  const [selectedValue, setSelectedValue] = useState('');

  const fieldProps = useField(inputFieldName);
  const {
    input: { value: filterPropsValue, onChange: onInputChange },
  } = fieldProps;

  const { suggestions } = filterProps;

  const customOptionsRef = useRef();

  const debouncedValue = useDebounce(filterPropsValue, 500);

  useEffectSkipFirst(() => {
    if (debouncedValue && debouncedValue !== '') {
      changeParams({ searchString: debouncedValue });

      if (selectedValue !== debouncedValue) {
        openContent();
      }
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (customOptionsRef.current) {
      customOptionsRef.current.focus();
    }
  }, [customOptionsRef]);

  const handleCustomClick = () => {
    callback({ name: debouncedValue });
    onInputChange(debouncedValue);
    closeContent();
  };

  const countries = useSelect(selectCountries);

  const showCustomOption = useMemo(() => {
    return (filterPropsValue && !suggestions?.some(el => el.label.includes(filterPropsValue))) || !withCustomOptions;
  }, [filterPropsValue, suggestions, withCustomOptions]);

  return (
    <div className="ickyc-select-content-special">
      <ul>
        <li
          className={`static ${hoverIndex === 0 ? 'hovered' : ''} ${!showCustomOption ? 'disabled hidden' : ''}`}
          onClick={handleCustomClick}
        >
          Press Enter to Create New Entity
        </li>
        {suggestions.map((suggestion, index) => {
          const optionClasses = classNames({
            hovered: hoverIndex === index + 1,
            selected: false,
            [suggestion.className]: suggestion.className,
          });
          const countryToShow = countries.find(country => country.id === suggestion.residenceId) || {};
          return (
            <li
              className={optionClasses}
              key={suggestion.id}
              onClick={e => {
                e.stopPropagation();
                if (!multipleSelection) {
                  onInputChange(suggestion.label);
                }
                onChange(suggestion);
                callback(suggestion);
                setSelectedValue(suggestion?.label);
                closeContent();
              }}
            >
              <div className="name">{suggestion.label}</div>
              <div className="country">
                <SingleCountry {...countryToShow} />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
SelectCustomContentSpecial.propTypes = {
  changeParams: PropTypes.func.isRequired,
  callback: PropTypes.func,
  inputFieldName: PropTypes.string,
};
SelectCustomContentSpecial.defaultProps = {
  callback: () => {},
  inputFieldName: '',
};
export default SelectCustomContentSpecial;
