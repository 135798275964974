import React from 'react';
import IComplySVGWhite from '../../../assets/IComply';
import setClassSuffix from '../../../utilities/services/ClassManager';
import Routes from '../../components/Routes';
import routes from './routes';
import './styles.scss';

const PublicSubrouter = () => {
  const baseClass = 'ickyc-widget-main';
  const currentYear = new Date().getFullYear();
  const setSuffix = setClassSuffix(baseClass);
  return (
    <div className={baseClass}>
      <header>
        <IComplySVGWhite viewBox="0 0 401 63" />
      </header>

      <div className={setSuffix('__content')}>
        <Routes routes={routes} />
      </div>
      <footer>
        <p>
          <b> Copyright {currentYear}. iComply Investor Services Inc.&nbsp;&nbsp;</b>
          All rights reserved.
        </p>
        <p>
          <a href="https://icomplyis.com/terms-of-use/" target="_blanc">
            Terms of Use
          </a>
          <b>&nbsp;&nbsp;|&nbsp;&nbsp;</b>
          <a href="https://icomplyis.com/privacy-policy/" target="_blanc">
            Privacy Policy
          </a>
        </p>
      </footer>
    </div>
  );
};

export default PublicSubrouter;
