/* eslint-disable no-console */
import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import toastr from 'toastr';
import api from '../../../../api';
import DeleteButton from '../../../../components/Buttons/DeleteButton';
import NestedListHeader from '../../../../components/Headings/NestedListHeading';
import ConfirmationPage from '../../../../components/Modal/ConfirmationPage';
import FormModal from '../../../../components/Modal/FormModal';
import InformationModal from '../../../../components/Modal/InformationModal';
import Spinner from '../../../../components/Spinner';
import useModalHandler from '../../../../hooks/useModalHandler';
import bus from '../../../../modules/bus';
import ProtectedComponent from '../../../../router/components/ProtectedComponent';
import enums from '../../../../utilities/enums';
import authEnums from '../../../../utilities/enums/authEnums';
import routesEnum from '../../../../utilities/enums/routesEnum';
import DateManager from '../../../../utilities/services/DateManager';
import IconManager from '../../../../utilities/services/IconManager';
import { EntityContext } from '../../../../utilities/services/contexts';
import CommentsSection from '../../components/CommentsSection';
import AffiliationSection from '../components/AffiliationSection';
import DocumentsSection from '../components/DocumentsSection';
import GeneralEntityInformation from '../components/GeneralEntityInformation';
import RiskScreeningSection from '../components/RiskScreeningSection';
import AddressSection from './components/AddressSection';
import AffiliatedEntitiesSection from './components/AffiliatedEntitiesSection';
import LegalEntityInformationSection from './components/LegalEntityInformationSection';
import './styles.scss';

const LegalEntityProfile = ({ className }) => {
  const history = useHistory();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [shouldOpenComModal, setShouldOpenComModal] = useState(false);
  const [isGlobalErrorModalOpen, setIsGlobalErrorModalOpen] = useState(false);
  const [legalEntityInfo, setLegalEntityInfo] = useState({});
  const [generalEntityInfo, setGeneralEntityInfo] = useState({});
  const [entityRiskScreeningOptions, setEntityRiskScreeningOptions] = useState(null);
  const [reloadInformationFlag, setReloadInformationFlag] = useState();

  const [issues, setIssues] = useState({});
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  useEffect(() => {
    const extractEntityInfo = data => {
      return {
        assignedUserId: data.assignedUserId,
        assignedUserName: data.assignedUserName,
        documentsIssuesCount: data.documentsIssuesCount,
        importantPersonnelIssuesCount: data.importantPersonnelIssuesCount,
        riskLevel: data.riskLevel,
        status: data.status,
        name: data.personalInformation.name,
        countryId: data.personalInformation.jurisdictionOfIncorporationId,
        stateProvinceId: data.personalInformation.jurisdictionOfIncorporationStateProvince,
        kycIssues: data.kycIssues,
        amlIssues: data.amlIssues,
        nextReview: data.nextReview,
        affiliatedEntitiesReviewRequired: data.affiliatedEntitiesReviewRequired,
        addressIssues: data.addressIssues,
        riskScore: data.personalInformation.score,
        riskLevelCalculationMethod: data.riskLevelCalculationMethod,
        riskLevelEnabled: data.riskLevelEnabled,
      };
    };

    async function fetchLegalEntityProfileInfo() {
      try {
        setIsLoading(true);
        const profileDataResponse = await api.kyc.entityManagement.legalEntity.getInformation(id);
        const {
          data: {
            personalInformation,
            corporateVerificationIssuesCount,
            documentsIssuesCount,
            importantPersonnelIssuesCount,
            amlIssues,
            defaultScreeningInfo,
            addressIssues,
            reportingJurisdictions,
            taxJurisdictions,
            allCorporateVerificationEventsRejected,
            allDocumentsRejected,
            allAddressesRejected,
          },
        } = profileDataResponse;
        setIssues({
          personal: {
            value: corporateVerificationIssuesCount,
            index: enums.ACCORDION_INDEXES.CORPORATE_INFORMATION,
            shouldFocus: false,
            isAllRejected: allCorporateVerificationEventsRejected,
          },
          documents: {
            value: documentsIssuesCount,
            index: enums.ACCORDION_INDEXES.DOCUMENTS,
            shouldFocus: false,
            isAllRejected: allDocumentsRejected,
          },
          riskScreening: { value: amlIssues, index: enums.ACCORDION_INDEXES.RISK_SCREENING, shouldFocus: false },
          affiliated: {
            value: importantPersonnelIssuesCount,
            index: enums.ACCORDION_INDEXES.AFFILIATED_ENTITIES,
            shouldFocus: false,
          },
          address: {
            value: addressIssues,
            index: enums.ACCORDION_INDEXES.ADDRESSES,
            shouldFocus: false,
            isAllRejected: allAddressesRejected,
          },
        });
        setEntityRiskScreeningOptions(defaultScreeningInfo);
        setGeneralEntityInfo(extractEntityInfo(profileDataResponse.data));
        setLegalEntityInfo({ ...personalInformation, taxJurisdictions, reportingJurisdictions });
      } catch (error) {
        const {
          response: {
            data: { message },
            status,
          },
        } = error;

        if (message.includes(enums.API_ERROR_TYPES.LEGAL_ENTITY_NOT_FOUND) || status === 404)
          setIsGlobalErrorModalOpen(true);
      } finally {
        setIsLoading(false);
      }
    }
    fetchLegalEntityProfileInfo();
  }, [id, dispatch, reloadInformationFlag]);

  useEffect(() => {
    const handleUpdate = newData => {
      setGeneralEntityInfo(prev => ({ ...prev, ...newData }));
    };
    bus.addEventListener(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, handleUpdate);
    return () => {
      return bus.removeEventListener(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, handleUpdate);
    };
  }, []);

  useEffect(() => {
    const handleUpdate = newData => {
      const { type, issuesChange, section, allRejected } = newData;

      if (type === 'kyc') {
        setGeneralEntityInfo(prev => ({ ...prev, kycIssues: prev.kycIssues + issuesChange }));
      }
      if (type === 'aml') {
        setGeneralEntityInfo(prev => ({ ...prev, amlIssues: prev.amlIssues + issuesChange }));
      }
      if (section === enums.ACCORDION_INDEXES.CORPORATE_INFORMATION) {
        setIssues(prev => ({
          ...prev,
          personal: {
            ...prev.personal,
            value: prev.personal.value + issuesChange,
            isAllRejected: allRejected,
          },
        }));
      }
      if (section === enums.ACCORDION_INDEXES.BIOMETRIC_AUTHENTICATIONS) {
        setIssues(prev => ({
          ...prev,
          biometric: {
            ...prev.biometric,
            value: prev.biometric.value + issuesChange,
            isAllRejected: allRejected,
          },
        }));
      }
      if (section === enums.ACCORDION_INDEXES.DOCUMENTS) {
        setIssues(prev => ({
          ...prev,
          documents: {
            ...prev.documents,
            value: prev.documents.value + issuesChange,
            isAllRejected: allRejected,
          },
        }));
      }
      if (section === enums.ACCORDION_INDEXES.RISK_SCREENING) {
        setIssues(prev => ({
          ...prev,
          riskScreening: {
            ...prev.riskScreening,
            value: prev.riskScreening.value + issuesChange,
            isAllRejected: allRejected,
          },
        }));
      }
      if (section === enums.ACCORDION_INDEXES.AFFILIATED_ENTITIES) {
        setIssues(prev => ({
          ...prev,
          affiliated: {
            ...prev.affiliated,
            value: prev.affiliated.value + issuesChange,
            isAllRejected: allRejected,
          },
        }));
      }
      if (section === enums.ACCORDION_INDEXES.ADDRESS) {
        setIssues(prev => ({
          ...prev,
          address: {
            ...prev.address,
            value: prev.address.value + issuesChange,
            isAllRejected: allRejected,
          },
        }));
      }
    };
    bus.addEventListener(enums.BUS_EVENTS.UPDATE_ISSUES, handleUpdate);
    return () => {
      return bus.removeEventListener(enums.BUS_EVENTS.UPDATE_ISSUES, handleUpdate);
    };
  }, []);

  const classes = classNames('ickyc-kyc-page', 'ickyc-corporate-profile', className);

  const goHistoryBack = useCallback(() => history.goBack(), [history]);

  const headingListItems = useMemo(() => {
    return [
      <>
        <b>{legalEntityInfo.name}</b>
      </>,
    ];
  }, [legalEntityInfo]);

  const { isOpen: isConfirmModalOpen, close: closeConfirmModal, open: openConfirmModal } = useModalHandler();

  const handleEntityDeletion = useCallback(() => {
    return api.kyc.entityManagement
      .deleteEntitySoft(id, 1)
      .then(() => {
        toastr.success(`${legalEntityInfo.name} has been deleted`);
        closeConfirmModal();
        history.push(routesEnum.kyc.ENTITY_MANAGEMENT);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  }, [history, legalEntityInfo.name, closeConfirmModal, id]);

  const openSectionsWithIssues = () => {
    const issuesInfo = Object.values(issues).filter(el => el.value > 0);
    if (issuesInfo.length > 0) {
      issuesInfo[0] = { ...issuesInfo[0], shouldFocus: true };
      bus.broadcastEvent(enums.BUS_EVENTS.EXPAND_SECTIONS, issuesInfo);
    }
  };
  const focusComments = () => {
    bus.broadcastEvent(enums.BUS_EVENTS.EXPAND_SECTIONS, [
      {
        index: enums.ACCORDION_INDEXES.COMMENTS,
        shouldFocus: true,
      },
    ]);
  };

  const handleConfirmationPageClick = useCallback(() => {
    history.goBack();
  }, [history]);

  if (isLoading) {
    return (
      <div className={classes} ref={containerRef}>
        <Spinner />
      </div>
    );
  }

  return (
    <EntityContext.Provider
      value={{
        entityId: id,
        isNaturalPerson: false,
        entityName: legalEntityInfo.name,
        updateEntityInfo: setLegalEntityInfo,
        affiliatedEntitiesReviewRequired: generalEntityInfo.affiliatedEntitiesReviewRequired,
        issues,
        reloadEntityInformation: flag => setReloadInformationFlag(flag),
        riskLevel: generalEntityInfo.riskLevel,
        riskLevelEnabled: generalEntityInfo.riskLevelEnabled,
      }}
    >
      <div className={classes} ref={containerRef}>
        {isGlobalErrorModalOpen ? (
          <InformationModal hideModal={() => setIsGlobalErrorModalOpen(false)} onClick={handleConfirmationPageClick}>
            <ConfirmationPage
              title="Oops!"
              subtitle="Something went wrong…"
              text={
                <>
                  We’re working on it, and we’ll get it fixed as soon as we can.
                  <br /> You may refresh the page, or try again later.
                </>
              }
              icons={<div>{IconManager.get(IconManager.names.GLOBAL_ERROR)}</div>}
            />
          </InformationModal>
        ) : (
          <>
            <NestedListHeader
              title="Entities"
              onClick={goHistoryBack}
              items={headingListItems}
              right={<DeleteButton onClick={openConfirmModal} />}
            />
            {isConfirmModalOpen && (
              <FormModal
                hideModal={closeConfirmModal}
                title="Delete Entity"
                className="ickyc-confirmation-modal"
                onSubmit={handleEntityDeletion}
              >
                <span className="ickyc-confirmation-message">
                  Are you sure you want to delete &nbsp;
                  <b>{legalEntityInfo.name}</b>?
                </span>
              </FormModal>
            )}
            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.PERSONAL_INFORMATION}
            >
              <GeneralEntityInformation
                info={generalEntityInfo}
                openModal={() => setShouldOpenComModal(true)}
                focusComments={focusComments}
                openSections={openSectionsWithIssues}
              />
            </ProtectedComponent>
            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.PERSONAL_INFORMATION}
            >
              <LegalEntityInformationSection
                data={legalEntityInfo}
                badge={{ count: issues.personal?.value, isAllRejected: issues.personal?.isAllRejected }}
              />
            </ProtectedComponent>

            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.ADDRESSES}
            >
              <AddressSection badge={{ count: issues.address?.value, isAllRejected: issues.address?.isAllRejected }} />
            </ProtectedComponent>
            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.AFFILIATED_ENTITIES}
            >
              <AffiliatedEntitiesSection badge={{ count: issues.affiliated?.value }} />
            </ProtectedComponent>

            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.DOCUMENTS}
              licenceAccessKey={authEnums.ACCESS_BY_LICENCE.SUPPORTING_DOCUMENTS}
            >
              <DocumentsSection
                badge={{ count: issues.documents?.value, isAllRejected: issues.documents?.isAllRejected }}
              />
            </ProtectedComponent>

            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.amlSearchComponent]}
              permissionGroup={authEnums.PERMISSION_GROUP.RISK_SCREENING}
              licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}
            >
              <RiskScreeningSection
                badge={{ count: issues.riskScreening?.value }}
                riskScreeningOptions={{
                  ...entityRiskScreeningOptions,
                  searchTerms: [
                    {
                      name: legalEntityInfo?.name,
                      yearOfBirth: new Date(DateManager.toDate(legalEntityInfo?.formationDate)).getFullYear(),
                      countries: legalEntityInfo?.jurisdictionOfIncorporationId
                        ? [legalEntityInfo?.jurisdictionOfIncorporationId]
                        : null,
                    },
                  ],
                }}
              />
            </ProtectedComponent>
            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.AFFILIATED_ENTITIES}
            >
              <AffiliationSection />
            </ProtectedComponent>
            <CommentsSection
              openModalOutside={shouldOpenComModal}
              setOpenModalOutside={setShouldOpenComModal}
              newCommentButton
              apiGetComments={api.comments.getTopLevelEntityComments}
              apiGetReplies={api.comments.getReplies}
              apiDeleteComment={api.comments.removeComment}
              apiEditComment={api.comments.editComment}
              apiCreateComment={api.comments.createNewComment}
              apiCreateReply={api.comments.createNewReply}
              additionalRequestParams={{
                entityId: id,
              }}
            />
          </>
        )}
      </div>
    </EntityContext.Provider>
  );
};
LegalEntityProfile.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

LegalEntityProfile.defaultProps = {
  className: undefined,
};
export default LegalEntityProfile;
