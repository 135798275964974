import PropTypes from 'prop-types';
import React from 'react';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import './styles.scss';

const EnableDisableControlButtons = ({ onClick, disabled }) => {
  return (
    <span className="ickyc-enable-disable-buttons">
      <PrimaryButton onClick={() => onClick({ enabled: 1 })} disabled={disabled}>
        Enable Selected
      </PrimaryButton>

      <PrimaryButton onClick={() => onClick({ enabled: 0 })} disabled={disabled}>
        <span>Disable Selected</span>
      </PrimaryButton>
    </span>
  );
};

EnableDisableControlButtons.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

EnableDisableControlButtons.defaultProps = {
  onClick: () => {},
  disabled: false,
};
export default EnableDisableControlButtons;
