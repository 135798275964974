import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../../../../api';
import ExistingEntitiesTableRow from '../../../../../../../components/Select/components/ExistingEntitiesTableRow';
import SelectContentTable from '../../../../../../../components/Select/components/SelectContentTable';
import SelectFilterContentToggleList from '../../../../../../../components/Select/components/SelectFilterContentToggleList';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import TableHeader from '../../../../../../../components/Tables/Table/components/TableHeader';
import ToggleField from '../../../../../../../components/ToggleField';
import useSelect from '../../../../../../../hooks/useSelect';
import { selectUsersToAssign } from '../../../../../../../store/selectors/global.selector';
import enums from '../../../../../../../utilities/enums';
import TableHeaders from '../../../../../../../utilities/services/TableHeaders';
import DateFromUntilPicker from '../../../../../components/DateFromUntilPicker';
import FilterItem from '../../../../../components/FilterItem';
import { AML_FILTER_DROPDOWN_OPTIONS } from '../../../../enums';

const CasesFilterForm = () => {
  const [params, setParams] = useState({ limit: 5, skip: 0, sortOrder: 1, sortCriteria: 'name' });
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    const fetchUsers = () => {
      setIsLoading(true);
      api.kyc.caseManagement
        .getCaseAttachmentSubjects(params)
        .then(({ data: response }) => {
          const mappedList = response.data.map(el => ({ ...el, value: el.id, label: el.name }));
          setEntities(mappedList);
          setPagination(response.paginationData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchUsers();
  }, [params]);

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  const assignedToItems = useSelect(selectUsersToAssign).map(user => ({
    id: user.id,
    label: user.name,
    value: user.id,
  }));

  const runByValues = useMemo(() => {
    return [
      { id: 'System', label: 'System', value: 'System' },
      ...assignedToItems.map(elm => ({ ...elm, value: elm?.label })),
    ];
  }, [assignedToItems]);

  const fieldNames = {
    DATE_FLAG: 'dateFlag',
    DATE_FROM: 'dateFrom',
    DATE_TO: 'dateTo',
    STATUS_FLAG: 'statusesFlag',
    STATUS_VALUES: 'statuses',
    ASSIGNED_TO_FLAG: 'assignsToFlag',
    ASSIGNED_TO_VALUES: 'assignsTo',
    ATTACHED_TO_FLAG: 'attachmentsFlag',
    ATTACHED_TO_VALUES: 'attachments',
    RUN_BY_FLAG: 'runByFlag',
    RUN_BY_VALUES: 'runBy',
    ONGOING_FLAG: 'ongoingFlag',
    ONGOING_VALUES: 'ongoing',
    RISK_LEVEL_FLAG: 'riskTypesFlag',
    RISK_LEVEL_VALUES: 'riskTypes',
    POTENTIAL_ISSUES_FLAG: 'potentialIssuesFlag',
    POTENTIAL_ISSUES_VALUES: 'potentialIssues',
  };
  return (
    <div className="ickyc-filter-form">
      <Field component={FilterItem} label="Date Created" name={fieldNames.DATE_FLAG}>
        <DateFromUntilPicker dateFromName={fieldNames.DATE_FROM} dateUntilName={fieldNames.DATE_TO} />
      </Field>
      <Field component={FilterItem} label="Attached To" name={fieldNames.ATTACHED_TO_FLAG}>
        <Field
          component={SelectField}
          Trigger={<SelectTrigger placeholder="- Choose Attached To -" />}
          options={entities}
          withTableContent
          Content={
            <SelectContentTable
              tableProps={{
                tableRow: ExistingEntitiesTableRow,
                headerRow: TableHeader,
                handleParamsChange: changeParams,
                pagination,
                headerData: {
                  sortCriteria: params.sortCriteria,
                  sortOrder: params.sortOrder,
                  onClick: changeParams,
                  items: TableHeaders.ExistingEntities,
                },
                updating: isLoading,
                withLoadMore: true,
                withPagination: true,
                className: 'ickyc-existing-entities-table',
              }}
            />
          }
          multipleSelection
          name={fieldNames.ATTACHED_TO_VALUES}
        />
      </Field>
      <Field component={FilterItem} label="Run By" name={fieldNames.RUN_BY_FLAG}>
        <Field
          multipleSelection
          component={SelectField}
          name={fieldNames.RUN_BY_VALUES}
          options={runByValues}
          Trigger={<SelectTrigger placeholder="- Choose Run By -" />}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
      <Field component={FilterItem} label="Ongoing" name={fieldNames.ONGOING_FLAG}>
        <Field component={ToggleField} name={fieldNames.ONGOING_VALUES} onOff />
      </Field>
      <Field component={FilterItem} label="Status" name={fieldNames.STATUS_FLAG}>
        <Field
          component={SelectField}
          multipleSelection
          name={fieldNames.STATUS_VALUES}
          options={enums.MAPPED_CASE_STATUSES}
          Content={<SelectFilterContentToggleList />}
          Trigger={<SelectTrigger placeholder="- Choose Status -" />}
        />
      </Field>

      <Field component={FilterItem} label="Potential Issues" name={fieldNames.POTENTIAL_ISSUES_FLAG}>
        <Field
          component={ToggleField}
          name={fieldNames.POTENTIAL_ISSUES_VALUES}
          showCustomText
          toggleOnText="Yes"
          toggleOffText="No"
        />
      </Field>
      <Field component={FilterItem} label="Assigned To" name={fieldNames.ASSIGNED_TO_FLAG}>
        <Field
          multipleSelection
          component={SelectField}
          name={fieldNames.ASSIGNED_TO_VALUES}
          options={[{ id: 'Unassigned', label: 'Unassigned', value: 'unassign' }, ...assignedToItems]}
          Trigger={<SelectTrigger placeholder="- Choose Assigned To -" />}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
      <Field component={FilterItem} label="Risk Type" name={fieldNames.RISK_LEVEL_FLAG}>
        <Field
          component={SelectField}
          multipleSelection
          name={fieldNames.RISK_LEVEL_VALUES}
          options={AML_FILTER_DROPDOWN_OPTIONS}
          Trigger={<SelectTrigger placeholder="- Choose Risk Type -" />}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
    </div>
  );
};
export default CasesFilterForm;
