import axiosInstance from '../axiosInstance';

const ENDPOINTS = {
  createCustomerRequest: '/support/requests',
  getConfiguration: '/support/requests/types',
  getRequestFields: id => `/support/requests/types/${id}/fields`,
};

const createCustomerRequest = params => {
  return axiosInstance.post(ENDPOINTS.createCustomerRequest, params);
};
const getConfiguration = () => {
  return axiosInstance.get(ENDPOINTS.getConfiguration);
};

const getRequestFields = id => {
  return axiosInstance.get(ENDPOINTS.getRequestFields(id));
};

export default {
  createCustomerRequest,
  getConfiguration,
  getRequestFields,
};
