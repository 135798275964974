import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowRight';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { default as React, useCallback, useContext, useEffect, useRef, useState } from 'react';
import useEffectSkipFirst from '../../../../hooks/useEffectSkipFirst';
import setClassSuffix from '../../../../utilities/services/ClassManager';
import { DragContext } from '../../../../utilities/services/contexts';

const CollapsibleTableRow = ({
  row,
  children,
  className,
  rowClassName,
  onClick,
  shouldExpand,
  withIndexColumn,
  isDraggable,
  index,
  icon,
  externalArrow,
  isToggled,
  isDropAllowed,
}) => {
  const [isCollapsed, setCollapseStatus] = useState(shouldExpand);

  const { dragList, dropCallback, setIsChildDragged, isNested = false } = useContext(DragContext) || {};

  const [isDragOver, setIsDragOver] = useState(false);

  useEffect(() => {
    setCollapseStatus(shouldExpand);
  }, [shouldExpand]);

  useEffectSkipFirst(() => {
    if (isCollapsed && !isToggled) {
      setCollapseStatus(false);
    }
    if (!isCollapsed && isToggled) {
      setCollapseStatus(true);
    }
  }, [isToggled]);

  const contentRef = useRef(null);

  const handleCollapseChange = event => {
    event.stopPropagation();
    // Don't close the modal if click has been detected on the row expanded content

    if (!contentRef.current || !contentRef.current.contains(event.target)) {
      setCollapseStatus(prev => !prev);
      // Currently this onClick is an empty function. Why is it here then?
      onClick();
    }
  };

  const baseClass = `${className}`;
  const classes = classNames(
    {
      [`${baseClass}__row`]: true,
      [`${baseClass}__row--extended`]: isCollapsed,
      [`${baseClass}__row--draggable`]: isDraggable,
      [`${baseClass}__row--drop-allowed`]: isDragOver && isDropAllowed,
      [`${baseClass}__row--drop-forbiden`]: isDragOver && !isDropAllowed,
    },
    rowClassName,
  );
  const setSuffix = setClassSuffix(baseClass);

  const renderAdditionalRow = () => {
    if (isCollapsed)
      return (
        <td ref={contentRef} className={setSuffix('__column__collapsed-content')}>
          {children}
        </td>
      );
  };

  const handleDragStart = useCallback(
    event => {
      event.stopPropagation();
      event.dataTransfer.setData('text/plain', index - 1);
      isNested && setIsChildDragged(true);
    },
    [index, dropCallback, dragList],
  );

  const handleDrop = useCallback(
    event => {
      const sourceIndex = event.dataTransfer.getData('text/plain');

      if (isDropAllowed) {
        const newRows = [...dragList];
        const removed = newRows.splice(Number(sourceIndex), 1)[0];

        newRows.splice(index - 1, 0, removed);
        dropCallback(newRows, removed);
      }

      setIsDragOver(false);
    },
    [index, dropCallback, dragList],
  );

  const handleDragOver = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      if (!isDropAllowed) {
        event.dataTransfer.dropEffect = 'none';
      }
      setIsDragOver(true);
    },
    [setIsDragOver, isDropAllowed],
  );

  const handleDragLeave = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();
      setIsDragOver(false);
    },
    [setIsDragOver],
  );

  const handleDragEnd = useCallback(() => isNested && setIsChildDragged(false), [isNested, setIsChildDragged]);

  return (
    <tr
      className={classes}
      onClick={handleCollapseChange}
      draggable={isDraggable}
      onDragStart={handleDragStart}
      onDrop={handleDrop}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {icon && <td className={setSuffix('__column__icon')}>{icon}</td>}
      {withIndexColumn && (
        <td className={setSuffix('__column__index')}>
          <h3>{index}</h3>
        </td>
      )}

      {!externalArrow && (
        <td className={setSuffix('__column__collapse')}>{isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}</td>
      )}
      {row}
      {children && renderAdditionalRow()}
    </tr>
  );
};

CollapsibleTableRow.propTypes = {
  row: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string.isRequired,
  rowClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
  shouldExpand: PropTypes.bool,
  withIndexColumn: PropTypes.bool,
  index: PropTypes.number,
  isDraggable: PropTypes.bool,
  icon: PropTypes.string,
  externalArrow: PropTypes.bool,
  isToggled: PropTypes.bool,
  dropCallback: PropTypes.func,
  isDropAllowed: PropTypes.bool,
};

CollapsibleTableRow.defaultProps = {
  children: <></>,
  withIndexColumn: false,
  onClick: () => {},
  shouldExpand: false,
  index: 0,
  isDraggable: false,
  icon: undefined,
  externalArrow: false,
  isToggled: true,
  dropCallback: () => {},
  isDropAllowed: true,
};

export default CollapsibleTableRow;
