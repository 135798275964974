import RadioButtonField from 'components/RadioButtonField';
import React from 'react';
import { Field } from 'react-final-form';
import './styles.scss';

const ApproveEntitiesForm = () => {
  return (
    <div className="approve-entities-form">
      <div className="approve-entities-form--container">
        <p>These entities may still have unresolved issues.</p>
        <Field
          component={RadioButtonField}
          type="radio"
          value={1}
          name={'acceptIssues'}
          parse={val => Number(val)}
          label="Accept all issues and Approve these entities"
        />
        <Field
          component={RadioButtonField}
          name={'acceptIssues'}
          type="radio"
          parse={val => Number(val)}
          value={0}
          label="Keep issues unresolved and Approve these entities"
        />
      </div>
    </div>
  );
};
export default ApproveEntitiesForm;
