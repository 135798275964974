import Tooltip from 'components/Tooltip';
import useGroupPermissions from 'hooks/useGroupPermissions';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { selectUserAccessByLicense } from 'store/selectors/auth.selector';
import authEnums from 'utilities/enums/authEnums';
import routesEnum from 'utilities/enums/routesEnum';
import Accordion from '../../../../../../../../../components/Accordion';
import PrimaryButton from '../../../../../../../../../components/Buttons/PrimaryButton';
import FieldArrayTable from '../../../../../../../../../components/Tables/FieldArrayTable';
import TableHeader from '../../../../../../../../../components/Tables/Table/components/TableHeader';
import ToggleField from '../../../../../../../../../components/ToggleField';
import utilities from '../../../../../../../../../utilities';
import DocsTableRow from './components/DocsTableRow';
import './styles.scss';

const SupportingDocumentsRCU = ({ namePrefix, handleRequestSupportingDocumentsChange }) => {
  const baseClass = 'supporting-documents-rcu';
  const [disableAddButton, setDisableAddButton] = useState(false);
  const link = routesEnum.administration.PORTAL_MANAGMENT;

  const { values } = useFormState();
  const dataCollection = values[namePrefix] ? values[namePrefix].dataCollection : values.dataCollection;

  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  const {
    mutators: { push, remove },
  } = useForm();

  const addRow = useCallback(() => {
    push(`${namePrefixWithDot}dataCollection.packagesTypes`, { assignedUserId: null, packageId: null });
  }, [push]);

  const removeRow = useCallback(
    index => {
      remove(`${namePrefixWithDot}dataCollection.packagesTypes`, index);
    },
    [remove],
  );
  const platformAccess = useSelector(selectUserAccessByLicense);
  const handleOptionArrayChange = useCallback(val => setDisableAddButton(val));

  const { view: canAccesPM } = useGroupPermissions(authEnums.PERMISSION_GROUP.KYC_PORTALS);
  const hasAccesByLicense = useMemo(() => {
    return platformAccess.some(el => el === authEnums.ACCESS_BY_LICENCE.PORTAL_MANAGEMENT);
  }, [platformAccess]);

  const TableControls = () => {
    return (
      <>
        <Tooltip
          placement="top"
          trigger={
            <div disabled={!disableAddButton}>
              <PrimaryButton disabled={disableAddButton} onClick={addRow}>
                + Add Supporting Document Package
              </PrimaryButton>
            </div>
          }
          content={
            <span>
              There are no more documents available in this portal. If you need additional documents please add them to
              the portal in{' '}
              {hasAccesByLicense && canAccesPM ? (
                <a href={link} className="supporting-documents-rcu__tooltip-link">
                  Portal Management
                </a>
              ) : (
                <span>Portal Management</span>
              )}{' '}
              first and then proceed with Request Client Update.
            </span>
          }
        />
      </>
    );
  };

  return (
    <Accordion
      title={
        <>
          <div onClick={utilities.handleOnClickEvent} className="supporting-documents-rcu__title">
            <Field
              preview
              name={`${namePrefixWithDot}dataCollection.requestSupportingDocuments`}
              component={ToggleField}
              label="Supporting Document Packages"
              onChange={handleRequestSupportingDocumentsChange}
            />
          </div>
          <span className="ickyc-spacer" />
        </>
      }
      accordionOpen={dataCollection?.requestSupportingDocuments}
    >
      <div className={baseClass}>
        <FieldArrayTable
          fieldName={`${namePrefixWithDot}dataCollection.packagesTypes`}
          tableControls={TableControls}
          tableRow={DocsTableRow}
          onOptionArrayChange={handleOptionArrayChange}
          headerRow={TableHeader}
          onRemove={removeRow}
          headerData={{
            items: [
              { type: 'template', label: 'Document Package' },
              { type: 'autoAssign', label: 'Auto-Assign' },
              { type: 'required' },
              { type: 'remove' },
            ],
          }}
          noResultMessage="No Docs To Show"
          namePrefix={namePrefix}
        />
      </div>
    </Accordion>
  );
};
export default SupportingDocumentsRCU;
