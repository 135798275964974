import classNames from 'classnames';
import CloseButton from 'components/Buttons/CloseButton';
import { SelectContext } from 'components/Select';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import Flag from '../../../Flag';
import './styles.scss';

const SingleCountry = props => {
  const { alpha2Code: flag, name, withClose } = props;
  const selectedContext = useContext(SelectContext);
  const baseClass = 'ickyc-single-country';
  const { onChange } = selectedContext || {};

  const removeValueFromSelect = useCallback(() => {
    onChange('');
  }, [onChange]);

  const classes = classNames(baseClass, { [`${baseClass}--with-close`]: withClose });
  return (
    <div className={classes}>
      {name === 'Unassigned' ? <span /> : <Flag code={flag} />}
      <span className="name">{name}</span>
      {withClose && <CloseButton onClick={removeValueFromSelect} className="ickyc-single-country__close-button" />}
    </div>
  );
};
SingleCountry.propTypes = { alpha2Code: PropTypes.string, name: PropTypes.string };
SingleCountry.defaultProps = { alpha2Code: undefined, name: '' };
export default SingleCountry;
