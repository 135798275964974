/* eslint-disable react/jsx-no-target-blank */
import PropTypes from 'prop-types';
import React from 'react';
import Info from '../../../../../../../../../components/Info';
import utilities from '../../../../../../../../../utilities';
import DateManager from '../../../../../../../../../utilities/services/DateManager';
import IconManager from '../../../../../../../../../utilities/services/IconManager';
import './styles.scss';

const ListingAdditionalInfo = ({ listItems, listName, startDate, endDate }) => {
  return (
    <div className="ickyc-listing-additinal-info">
      {listName && <Info label="List Name">{listName}</Info>}
      {startDate && <Info label="Start Date">{DateManager.monDayYearLocal(startDate)}</Info>}
      {endDate && <Info label="End Date">{DateManager.monDayYearLocal(endDate)}</Info>}
      {listItems.map(el => {
        return el.item1.toLowerCase().includes('url') ? (
          <div className="ickyc-listing-additinal-info__url">
            <span>{utilities.normalizeUrlName(el.item1)}</span>
            <a href={el.item2} target="_blank">
              <div>{el.item2.toLowerCase()}</div>
              {IconManager.get(IconManager.names.LINK)}
            </a>
          </div>
        ) : (
          <Info label={el.item1}>{el.item2}</Info>
        );
      })}
    </div>
  );
};

ListingAdditionalInfo.propTypes = {
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      item1: PropTypes.string,
      item2: PropTypes.string,
    }),
  ),
  listName: PropTypes.string,
  endDate: PropTypes.string,
  startDate: PropTypes.string,
};
ListingAdditionalInfo.defaultProps = {
  listItems: [],
  listName: null,
  endDate: null,
  startDate: null,
};
export default ListingAdditionalInfo;
