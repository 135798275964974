import DashboardPage from 'scenes/Administration/DashboardPage';
import AccountPage from '../../../../../scenes/Administration/AccountPage';
import Automations from '../../../../../scenes/Administration/Automations';
import PortalDetailsLegalEntity from '../../../../../scenes/Administration/PortalManagementPage/components/PortalDetailsLegalEntity';
import PortalDetailsNaturalPerson from '../../../../../scenes/Administration/PortalManagementPage/components/PortalDetailsNaturalPerson';
import PortalManagementWrapper from '../../../../../scenes/Administration/PortalManagementWrapper';
import WebHooksManagementPage from '../../../../../scenes/Administration/WebHooksManagmentPage';
import UserManagementWrapper from '../../../../../scenes/Administration/userManagementWrapper';
import authEnums from '../../../../../utilities/enums/authEnums';
import redirect from '../../../../modules/redirect';
import ComplianceSubrouter from './components/ComplianceSettingsSubrouter';
import FilesSubrouter from './components/FilesManagement';
import SettingsSubrouter from './components/Settings';

export default [
  {
    path: '/dashboard',
    component: DashboardPage,
    exact: false,
  },
  {
    path: '/account',
    component: AccountPage,
  },
  {
    path: '/compliance-settings',
    component: ComplianceSubrouter,
    withPermissionsCheck: true,
    exact: false,
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    permissionGroup: authEnums.PERMISSION_GROUP.COMPLIANCE_SETTINGS,
  },
  {
    path: '/files-management',
    component: FilesSubrouter,
    exact: false,
  },
  {
    path: '/settings',
    component: SettingsSubrouter,
    exact: false,
  },
  {
    path: '/web-hooks',
    component: WebHooksManagementPage,
  },
  {
    path: '/automation',
    component: Automations,
  },
  {
    path: '/user-management',
    component: UserManagementWrapper,
    withPermissionsCheck: true,
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    permissionGroup: authEnums.PERMISSION_GROUP.USER_MANAGEMENT,
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.USER_MANAGEMENT,
  },
  {
    path: '/portal-management',
    component: PortalManagementWrapper,
    withPermissionsCheck: true,
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    permissionGroup: authEnums.PERMISSION_GROUP.KYC_PORTALS,
  },
  {
    path: '/portal-management/legal-entity-portal/:id',
    component: PortalDetailsLegalEntity,
  },
  {
    path: '/portal-management/natural-person-portal/:id',
    component: PortalDetailsNaturalPerson,
  },
  {
    path: '*',
    component: redirect('/administration/dashboard', true),
  },
];
