/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import SelectFilterContent from '../../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../../components/SelectField';
import './index.scss';

const StepTwo = ({ npRoles, leRoles }) => {
  const {
    values: { entityType, existingEntityType },
  } = useFormState();
  const isExistingLE = entityType === 2 && existingEntityType === 1;
  const isExistingNP = entityType === 2 && existingEntityType === 0;
  const isNewLE = entityType === 1;
  const isNewNP = entityType === 0;
  return (
    <div className="ickyc-important-personnel-step-two">
      {(isNewNP || isExistingNP) && (
        <>
          <span>
            Please <b>select the roles</b> that this entity serves within the organization.
          </span>
          <Field
            component={SelectField}
            options={npRoles}
            name="personalInformation.roles"
            multipleSelection
            renderAsPortal
            Trigger={<SelectTrigger />}
            Content={<SelectFilterContent />}
          />
        </>
      )}
      {(isNewLE || isExistingLE) && (
        <>
          <span>
            Please <b>select the relationships</b>.
          </span>
          <Field
            component={SelectField}
            renderAsPortal
            Trigger={<SelectTrigger />}
            Content={<SelectFilterContent />}
            options={leRoles}
            name="corporateInformation.roles"
            multipleSelection
          />
        </>
      )}
    </div>
  );
};

StepTwo.propTypes = {
  npRoles: PropTypes.arrayOf(PropTypes.string),
  leRoles: PropTypes.arrayOf(PropTypes.string),
};
StepTwo.defaultProps = {
  npRoles: [],
  leRoles: [],
};
export default StepTwo;
