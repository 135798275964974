import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/business-manager';

const ENDPOINTS = {
  getClientUsage: clientId => `${BASE_ROUTE}/clients/${clientId}/usage/statistic`,
  downloadCSV: clientId => `${BASE_ROUTE}/clients/${clientId}/usage/export-csv`,
  getAllClientLicences: clientId => `${BASE_ROUTE}/clients/${clientId}/licences/names`,
};

const getClientUsage = (clientId, params) => {
  return axiosInstance.post(ENDPOINTS.getClientUsage(clientId), { ...params }, {});
};

const downloadCSV = (id, data) => axiosInstance.post(ENDPOINTS.downloadCSV(id), { ...data }, {});

const getAllClientLicences = clientId => {
  return axiosInstance.get(ENDPOINTS.getAllClientLicences(clientId));
};

export default {
  getClientUsage,
  downloadCSV,
  getAllClientLicences,
};
