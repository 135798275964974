import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import PrimaryButton from '../../../../../../../../../../components/Buttons/PrimaryButton';
import setClassSuffix from '../../../../../../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../../../../../../utilities/services/IconManager';
import './styles.scss';

const BiometricChecklist = ({ checklist, generatePdf, isGenerating }) => {
  const classBase = 'biometric-checklist';
  const setSuffix = setClassSuffix(classBase);

  const pickStatus = isPassing => {
    const passIcon = IconManager.get(IconManager.names.CHECK_FILLED);
    const closeIcon = IconManager.get(IconManager.names.CLOSE);

    if (typeof isPassing !== 'boolean') {
      return <span className={setSuffix('__content__item--pending')} />;
    }

    return isPassing ? (
      <span className={setSuffix('__content__item--success')}>{passIcon} Pass</span>
    ) : (
      <span className={setSuffix('__content__item--error')}>{closeIcon} Fail</span>
    );
  };

  return (
    <div className={classBase}>
      <h2>
        <span>Check List</span>
        <PrimaryButton onClick={generatePdf} disabled={isGenerating}>
          PDF Summary{isGenerating && <CircularProgress />}
        </PrimaryButton>
      </h2>

      <div className={setSuffix('__content')}>
        <div className={setSuffix('__content__item')}>
          Face Match
          {pickStatus(checklist.faceMatch)}
        </div>

        <div className={setSuffix('__content__item')}>
          Liveness Test
          {pickStatus(checklist.livenessTest)}
        </div>
      </div>
    </div>
  );
};

BiometricChecklist.propTypes = {
  checklist: PropTypes.shape({
    faceMatch: PropTypes.bool,
    livenessTest: PropTypes.bool,
  }),
  generatePdf: PropTypes.func,
  isGenerating: PropTypes.bool,
};

BiometricChecklist.defaultProps = {
  checklist: {},
  generatePdf: () => {},
  isGenerating: false,
};

export default BiometricChecklist;
