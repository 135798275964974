import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import CloseButton from '../Buttons/CloseButton';
import IconButton from '../Buttons/IconButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import Modal from '../Modal';
import './styles.scss';

const ImagePreview = ({ src, alt, mini, enlargeImage, withRemove, onRemove, mirror }) => {
  const [enlargeView, setEnlargeView] = useState(false);

  const classBase = 'ickyc-image-preview';

  const showEnlagedImage = useCallback(event => {
    event.stopPropagation();
    setEnlargeView(true);
  }, []);

  const stopPropagation = useCallback(event => {
    event.stopPropagation();
  }, []);

  const containerRef = useRef();
  const modalClass = 'ickyc-enlarge-image-modal';
  const classes = classNames(classBase, { 'ickyc-mirror-content': mirror });
  const modalClasses = classNames(modalClass, { 'ickyc-mirror-content': mirror });
  return (
    <div className={classes} ref={containerRef}>
      {enlargeImage && <PrimaryButton onClick={showEnlagedImage}>{mini ? 'View' : 'View Document'}</PrimaryButton>}
      <img src={src} alt={alt} />
      {withRemove && <CloseButton onClick={onRemove} />}

      {enlargeView && (
        <Modal containerRef={containerRef} className={modalClasses} hideModal={() => setEnlargeView(false)}>
          <div className="modal-content">
            <IconButton
              onClick={e => {
                e.stopPropagation();
                setEnlargeView(false);
              }}
            >
              <Close />
            </IconButton>
            <img src={src} alt={alt} onClick={stopPropagation} />
          </div>
        </Modal>
      )}
    </div>
  );
};

ImagePreview.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  mini: PropTypes.bool,
  enlargeImage: PropTypes.bool,
  withRemove: PropTypes.bool,
  onRemove: PropTypes.func,
  mirror: PropTypes.bool,
};
ImagePreview.defaultProps = {
  alt: '',
  mini: false,
  withRemove: false,
  onRemove: () => {},
  enlargeImage: true,
  mirror: false,
};

export default ImagePreview;
