import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

const SignalRService = {
  connection: null,

  startConnection: async token => {
    try {
      SignalRService.connection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_BE_URL}/events`, {
          withCredentials: false,
          accessTokenFactory: () => token,
        })
        .configureLogging(LogLevel.Error)
        .build();

      await SignalRService.connection.start();
    } catch (err) {
      console.error(err);
      await SignalRService.connection.stop();
    }
  },

  stopConnection: async () => {
    try {
      await SignalRService.connection.stop();
    } catch (err) {
      console.error(err);
    }
  },

  sendMessage: (key, message) => {
    SignalRService.connection.invoke(key, message).catch(err => console.error('Error while sending message: ', err));
  },

  receiveMessage: (key, callback) => {
    if (SignalRService.connection) {
      SignalRService.connection.on(key, message => {
        console.log('Received message:', message);
        if (callback) {
          callback(message);
        }
      });
    }
  },
};

export default SignalRService;
