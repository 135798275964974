export const FRONT_IMAGE_INSTRUCITONS = ' with a small border around all sides';
export const BACK_IMAGE_INSTRUCTIONS = ' with the barcode (if present) in clear focus';
export const PASSPORT_INSTRUCTIONS = ' with the picture page and barcode in clear focus';
export const DOCUMENT_MESSAGE = {
  DETECTED: 0,
  NOT_DETECTED: 1,
  WARNING: 2,
  NONE: 3,
};

export const DOCUMENT_INFO = {
  outputImageSize: 3600,
  processImageSize: 600,
};

export const DETECTION_COLORS = ['#02ce94', '#ff5353'];

export const DOCUMENT_TYPES = {
  PASSPORT: 'passport',
  ID: 'idCard',
  DRIVERS_LICENCE: 'driversLicense',
};
export const DOCUMENT_UPLOAD_TITLES = {
  PASSPORT: 'Passport Photo',
  FRONT_ID: 'Front of ID Card',
  FRONT_DRIVERS_LICENCE: "Front of Driver's license",
  BACK_ID: 'Back of ID Card',
  BACK_DRIVERS_LICENCE: "Back of Driver's license",
};
