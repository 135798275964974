import AngryIcon from 'assets/AngryIcon';
import ApiIcon from 'assets/ApiIcon';
import ApiWebFormIcon from 'assets/ApiWebFormIcon';
import ArchiveIcon from 'assets/ArchiveIcon';
import ArrowIcon from 'assets/ArrowIcon';
import BatchActionsIcon from 'assets/BatchActionsIcon';
import BellIcon from 'assets/BellIcon';
import BuildingIcon from 'assets/Building';
import Businessman from 'assets/Businessman';
import CaseIcon from 'assets/CaseIcon';
import CheckCircular from 'assets/CheckCircular';
import CheckFilled from 'assets/CheckFilled';
import CheckIcon from 'assets/CheckIcon';
import CloseIcon from 'assets/CloseIcon';
import CopyIcon from 'assets/CopyIcon';
import Document from 'assets/Document';
import Download from 'assets/Download';
import DriverLicenceIcon from 'assets/DriverLicenceIcon';
import EyeCrosedIcon from 'assets/EyeCrosedIcon';
import EyeIcon from 'assets/EyeIcon';
import Funnel from 'assets/Funnel';
import GearEmpty from 'assets/GearEmpty';
import GearGreenIcon from 'assets/GearGreenIcon';
import GearOrangeIcon from 'assets/GearOrangeIcon';
import GlobalError from 'assets/GlobalError';
import IComplyHeaderIcon from 'assets/IComplyHeaderIcon';
import IdentityCardIcon from 'assets/IdentityCardIcon';
import InternalIcon from 'assets/InternalIcon';
import LegalEntityIcon from 'assets/LegalEntityIcon';
import LinkIcon from 'assets/Link';
import Message from 'assets/Message';
import MessageFilled from 'assets/MessageFilled';
import MinusCircular from 'assets/MinusCircular';
import MinusIcon from 'assets/MinusIcon';
import NaturalPersonIcon from 'assets/NaturalPersonIcon';
import NeutralIcon from 'assets/NeutralIcon';
import News from 'assets/News';
import OfficeIcon from 'assets/OfficeIcon';
import PDFIcon from 'assets/PDFIcon';
import PassportIcon from 'assets/PassportIcon';
import PencilIcon from 'assets/PencilIcon';
import PhoneIcon from 'assets/PhoneIcon';
import PlusCircular from 'assets/PlusCircular';
import ProhibitedIcon from 'assets/ProhibitedIcon';
import QuestionMark from 'assets/QuestionMark';
import SadIcon from 'assets/SadIcon';
import SettingsIcon from 'assets/SettingsIcon';
import SmileIcon from 'assets/SmileIcon';
import SurpriseIcon from 'assets/SurpriseIcon';
import TemplateImage from 'assets/TemplateImage';
import WebFormIcon from 'assets/WebFormIcon';
import React from 'react';

export default {
  names: {
    ANGRY: 'angry',
    API: 'api',
    API_WEB_FORM: 'api-web-form',
    ARCHIVE: 'archive',
    BELL: 'bell',
    BUILDING: 'building',
    BUSINESSMAN: 'businessman',
    CHECK: 'check',
    CHECK_CIRCULAR: 'check-circular',
    CHECK_FILLED: 'check-filled',
    CLOSE: 'close',
    DOCUMENT: 'document',
    DOWNLOAD: 'download',
    FUNNEL: 'funnel',
    GEAR_ORANGE: 'gear-orange',
    GEAR_GREEN: 'gear-green',
    ID_CARD: 'identity-card',
    LINK: 'link',
    MESSAGE: 'message',
    MESSAGE_FILLED: 'message-filled',
    NEWS: 'news',
    PASSPORT: 'passport',
    PHONE: 'phone',
    PLUS_CIRCULAR: 'plus-circular',
    QUESTION: 'question-mark',
    SAD: 'sad',
    SETTINGS: 'settings',
    SMILE: 'smile',
    SURPRISE: 'surprise',
    WEB_FORM: 'web-form',
    OFFICE: 'office',
    PDF: 'pdf',
    PENCIL: 'pencil',
    TEMPLATE_IMAGE: 'template-image',
    DRIVERS_LICENCE: 'drivers-licence',
    COPY: 'copy',
    GLOBAL_ERROR: 'global-error',
    MINUS: 'minus',
    MINUS_CIRCULAR: 'minus-circular',
    EYE: 'eye',
    EYE_CROSSED: 'eye-crossed',
    CASE: 'case',
    LEGAL_ENTITY: 'legal-entity',
    NATURAL_PERSON: 'natural-person',
    INTERNAL: 'internal',
    ICOMPLY_HEADER: 'icomply-header',
    GEAR_EMPTY: 'gear-empty',
    NEUTRAL_SMILE: 'neutral-smile',
    BATCH_ACTIONS: 'batch-actions',
    ARROW: 'arrow',
    PROHIBITED: 'prohibited',
  },

  getIconMap() {
    return new Map([
      [this.names.API, <ApiIcon />],
      [this.names.API_WEB_FORM, <ApiWebFormIcon />],
      [this.names.ARCHIVE, <ArchiveIcon />],
      [this.names.ARROW, <ArrowIcon />],
      [this.names.ANGRY, <AngryIcon />],
      [this.names.BELL, <BellIcon />],
      [this.names.BUILDING, <BuildingIcon />],
      [this.names.BUSINESSMAN, <Businessman />],
      [this.names.CHECK, <CheckIcon />],
      [this.names.CHECK_CIRCULAR, <CheckCircular />],
      [this.names.CHECK_FILLED, <CheckFilled />],
      [this.names.CLOSE, <CloseIcon />],
      [this.names.DOCUMENT, <Document />],
      [this.names.DOWNLOAD, <Download />],
      [this.names.GEAR_ORANGE, <GearOrangeIcon />],
      [this.names.GEAR_GREEN, <GearGreenIcon />],
      [this.names.ID_CARD, <IdentityCardIcon />],
      [this.names.LINK, <LinkIcon />],
      [this.names.MESSAGE, <Message />],
      [this.names.MESSAGE_FILLED, <MessageFilled />],
      [this.names.NEWS, <News />],
      [this.names.PASSPORT, <PassportIcon />],
      [this.names.PLUS_CIRCULAR, <PlusCircular />],
      [this.names.PHONE, <PhoneIcon />],
      [this.names.QUESTION, <QuestionMark />],
      [this.names.SAD, <SadIcon />],
      [this.names.SETTINGS, <SettingsIcon />],
      [this.names.SMILE, <SmileIcon />],
      [this.names.SURPRISE, <SurpriseIcon />],
      [this.names.WEB_FORM, <WebFormIcon />],
      [this.names.QUESTION_MARK, <QuestionMark />],
      [this.names.FUNNEL, <Funnel />],
      [this.names.OFFICE, <OfficeIcon />],
      [this.names.PDF, <PDFIcon />],
      [this.names.PENCIL, <PencilIcon />],
      [this.names.TEMPLATE_IMAGE, <TemplateImage />],
      [this.names.DRIVERS_LICENCE, <DriverLicenceIcon />],
      [this.names.COPY, <CopyIcon />],
      [this.names.GLOBAL_ERROR, <GlobalError />],
      [this.names.MINUS, <MinusIcon />],
      [this.names.MINUS_CIRCULAR, <MinusCircular />],
      [this.names.EYE, <EyeIcon />],
      [this.names.EYE_CROSSED, <EyeCrosedIcon />],
      [this.names.CASE, <CaseIcon />],
      [this.names.LEGAL_ENTITY, <LegalEntityIcon />],
      [this.names.NATURAL_PERSON, <NaturalPersonIcon />],
      [this.names.INTERNAL, <InternalIcon />],
      [this.names.ICOMPLY_HEADER, <IComplyHeaderIcon />],
      [this.names.GEAR_EMPTY, <GearEmpty />],
      [this.names.NEUTRAL_SMILE, <NeutralIcon />],
      [this.names.BATCH_ACTIONS, <BatchActionsIcon />],
      [this.names.PROHIBITED, <ProhibitedIcon />],
    ]);
  },

  get(name) {
    return name ? this.getIconMap().get(name) : name;
  },
};
