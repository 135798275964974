import React, { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../components/Accordion';
import Section from '../../../../../../../components/Section';
import SelectContent from '../../../../../../../components/Select/components/SelectContent';
import SelectInputTrigger from '../../../../../../../components/Select/components/SelectInputTrigger';
import SelectField from '../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../components/ToggleField';
import authEnums from '../../../../../../../utilities/enums/authEnums';

const IComplyUsersTypePermissions = () => {
  const {
    values: { iComplyUser, iComplyAdmin, iComplySuperAdmin },
  } = useFormState();

  const { batch, change } = useForm();

  const canEdit = true;

  useEffect(() => {
    const selectAllPermissions = () => {
      batch(() => {
        authEnums.PERMISSION_TAGS_OPTIONS.ICOMPLY_USER.forEach(({ permissions, name }) => {
          change(name, permissions);
        });

        authEnums.PERMISSION_TAGS_OPTIONS.ICOMPLY_ADMIN_USER.forEach(({ permissions, name }) => {
          change(`${name}Admin`, permissions);
        });

        authEnums.PERMISSION_TAGS_OPTIONS.ICOMPLY_SUPER_ADMIN_USER.forEach(({ permissions, name }) => {
          change(`${name}SuperAdmin`, permissions);
        });
      });
    };
    selectAllPermissions();
  }, []);

  useEffect(() => {
    if (iComplyUser) {
      batch(() => {
        change('iComplyAdmin', false);
        change('iComplySuperAdmin', false);
      });
    }
  }, [iComplyUser]);

  useEffect(() => {
    if (iComplyAdmin) {
      batch(() => {
        change('iComplyUser', false);
        change('iComplySuperAdmin', false);
      });
    }
  }, [iComplyAdmin]);

  useEffect(() => {
    if (iComplySuperAdmin) {
      batch(() => {
        change('iComplyUser', false);
        change('iComplyAdmin', false);
      });
    }
  }, [iComplySuperAdmin]);

  return (
    <Section title="User Type + Permissions" className="ickyc-permission-section">
      <Accordion
        title={
          <>
            <Field component={ToggleField} name="iComplyUser" />
            <h3>iComply User</h3>
          </>
        }
        accordionOpen={iComplyUser}
        controledOpen
        accented
      >
        {authEnums.PERMISSION_TAGS_OPTIONS.ICOMPLY_USER.map(({ permissions, label, name }) => (
          <Field
            component={SelectField}
            disabled
            multipleSelection
            name={`${name}`}
            label={label}
            preview={!canEdit}
            Trigger={<SelectInputTrigger />}
            Content={<SelectContent />}
            renderAsPortal
            relativeTo=".ickyc-accordion"
            options={permissions.map(p => ({ id: p, value: p, label: p }))}
          />
        ))}
      </Accordion>
      <Accordion
        title={
          <>
            <Field component={ToggleField} name="iComplyAdmin" />
            <h3>iComply Admin</h3>
          </>
        }
        accordionOpen={iComplyAdmin}
        controledOpen
        accented
      >
        {authEnums.PERMISSION_TAGS_OPTIONS.ICOMPLY_ADMIN_USER.map(({ permissions, label, name }) => (
          <Field
            component={SelectField}
            multipleSelection
            name={`${name}Admin`}
            disabled
            label={label}
            preview={!canEdit}
            Trigger={<SelectInputTrigger />}
            Content={<SelectContent />}
            renderAsPortal
            relativeTo=".ickyc-accordion"
            options={permissions.map(p => ({ id: p, value: p, label: p }))}
          />
        ))}
      </Accordion>

      <Accordion
        title={
          <>
            <Field component={ToggleField} name="iComplySuperAdmin" disabled />
            <h3>iComply Super Admin</h3>
          </>
        }
        accordionOpen={iComplySuperAdmin}
        controledOpen
        accented
      >
        {authEnums.PERMISSION_TAGS_OPTIONS.ICOMPLY_SUPER_ADMIN_USER.map(({ permissions, label, name }) => (
          <Field
            component={SelectField}
            multipleSelection
            name={`${name}SuperAdmin`}
            disabled
            label={label}
            preview={!canEdit}
            Trigger={<SelectInputTrigger />}
            Content={<SelectContent />}
            renderAsPortal
            relativeTo=".ickyc-accordion"
            options={permissions.map(p => ({ id: p, value: p, label: p }))}
          />
        ))}
      </Accordion>
    </Section>
  );
};
export default IComplyUsersTypePermissions;
