import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import CountrySelectFilterContentToggleList from '../../../../../../../components/CountrySelect/components/CountrySelectFilterContentToggleList';
import CountrySelectField from '../../../../../../../components/CountrySelectField';
import SelectFilterContent from '../../../../../../../components/Select/components/SelectFilterContent';
import SelectFilterContentToggleList from '../../../../../../../components/Select/components/SelectFilterContentToggleList';
import SelectInputTrigger from '../../../../../../../components/Select/components/SelectInputTrigger';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import useSelect from '../../../../../../../hooks/useSelect';
import { selectRiskLevelLabels, selectUsersToAssign } from '../../../../../../../store/selectors/global.selector';
import enums from '../../../../../../../utilities/enums';
import DateFromUntilPicker from '../../../../../components/DateFromUntilPicker';
import EntityTypesToggleFilter from '../../../../../components/EntityTypesToggleFilter';
import FilterItem from '../../../../../components/FilterItem';
import './styles.scss';
const EntitiesFilterForm = ({ tagsOptions }) => {
  const riskItems = useSelect(selectRiskLevelLabels).map((risk, ind) => ({
    id: ind + 1,
    label: `${risk} (${ind + 1})`,
    value: `${ind + 1}`,
  }));

  const assignedToItems = useSelect(selectUsersToAssign).map(user => ({
    id: user.id,
    label: user.name,
    value: user.id,
  }));

  const mappedStatuses = useMemo(() => {
    return Object.entries(enums.ENTITY_STATUSES).map(status => ({
      id: status[0],
      label: status[1],
      value: status[1],
    }));
  }, []);

  const fieldNames = {
    DATE_FLAG: 'dateFlag',
    DATE_FROM: 'dateFrom',
    DATE_UNTIL: 'dateUntil',
    ENTITY_TYPE_FLAG: 'entityTypeFlag',
    NATURAL_PERSON_FLAG: 'naturalPersonFlag',
    ORGANIZATION_FLAG: 'organizationFlag',
    STATUS_FLAG: 'statusFlag',
    STATUS_VALUES: 'statuses',
    COUNTRY_FLAG: 'countryFlag',
    COUNTRY_VALUES: 'countries',
    RISK_LEVEL_FLAG: 'riskLevelFlag',
    RISK_LEVEL_VALUES: 'riskLevels',
    ASSIGNED_TO_FLAG: 'assignedToFlag',
    ASSIGNED_TO_VALUES: 'assignedTo',
    TAGS_FLAG: 'tagsFlag',
    TAGS_VALUES: 'tags',
    NEXT_KYC_REVIEW_FLAG: 'nextKYCReviewFlag',
    NEXT_KYC_REVIEW_FROM: 'nextKYCReviewFrom',
    NEXT_KYC_REVIEW_UNTIL: 'nextKYCReviewUntil',
  };

  return (
    <div className="ickyc-entities-fitler-form ickyc-filter-form">
      <Field component={FilterItem} label="Date" name={fieldNames.DATE_FLAG}>
        <DateFromUntilPicker dateFromName={fieldNames.DATE_FROM} dateUntilName={fieldNames.DATE_UNTIL} />
      </Field>
      <Field component={FilterItem} label="Next KYC Review" name={fieldNames.NEXT_KYC_REVIEW_FLAG}>
        <DateFromUntilPicker
          dateFromName={fieldNames.NEXT_KYC_REVIEW_FROM}
          dateUntilName={fieldNames.NEXT_KYC_REVIEW_UNTIL}
          todayAsMaxDate={false}
        />
      </Field>
      <Field component={FilterItem} label="Entity Type" name={fieldNames.ENTITY_TYPE_FLAG}>
        <EntityTypesToggleFilter
          npFieldName={fieldNames.NATURAL_PERSON_FLAG}
          leFieldname={fieldNames.ORGANIZATION_FLAG}
        />
      </Field>
      <Field component={FilterItem} label="Status" name={fieldNames.STATUS_FLAG}>
        <Field
          component={SelectField}
          placeholder="- Choose Status -"
          multipleSelection
          name={fieldNames.STATUS_VALUES}
          options={mappedStatuses}
          Content={<SelectFilterContentToggleList />}
          Trigger={<SelectTrigger />}
        />
      </Field>
      <Field component={FilterItem} label="Country" name={fieldNames.COUNTRY_FLAG}>
        <Field
          component={CountrySelectField}
          multipleSelection
          name={fieldNames.COUNTRY_VALUES}
          Trigger={<SelectTrigger placeholder="- Choose Countries -" />}
          Content={<CountrySelectFilterContentToggleList />}
        />
      </Field>
      <Field component={FilterItem} label="Risk Levels" name={fieldNames.RISK_LEVEL_FLAG}>
        <Field
          component={SelectField}
          multipleSelection
          name={fieldNames.RISK_LEVEL_VALUES}
          options={riskItems}
          Trigger={<SelectTrigger placeholder="- Choose Risk Levels -" />}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
      <Field component={FilterItem} label="Assigned To" name={fieldNames.ASSIGNED_TO_FLAG}>
        <Field
          multipleSelection
          component={SelectField}
          name={fieldNames.ASSIGNED_TO_VALUES}
          options={assignedToItems}
          Trigger={<SelectTrigger placeholder="- Choose Assigned To -" />}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
      <Field component={FilterItem} label="Tags" name={fieldNames.TAGS_FLAG}>
        <Field
          multipleSelection
          options={tagsOptions}
          component={SelectField}
          name={fieldNames.TAGS_VALUES}
          Trigger={<SelectInputTrigger placeholder=" - Enter Tags -" />}
          Content={<SelectFilterContent />}
        />
      </Field>
    </div>
  );
};
EntitiesFilterForm.propTypes = {
  tagsOptions: PropTypes.array,
};

EntitiesFilterForm.defaultProps = {
  tagsOptions: [],
};
export default EntitiesFilterForm;
