import classNames from 'classnames';
import InfoTooltip from 'components/InfoTooltip';
import InputField from 'components/InputField';
import TextEditorField from 'components/TextEditorField';
import React, { useMemo } from 'react';
import { Field, useField } from 'react-final-form';
import setClassSuffix from 'utilities/services/ClassManager';
import validators from 'utilities/services/validators';
import emailTemplateUtils from '../../../../Internal/EmailTemplates/components/EmailTemplateEditorPage/utils';
import './styles.scss';
import utils from './utils';

const { defaultPlaceholders, defaultValues } = utils;
const { placeholderDescriptions } = emailTemplateUtils;

const EmailMessage = ({
  className,
  messageName = 'emailMessage',
  linkTextName = 'linkText',
  supportEmailName = 'supportEmail',
  label,
  placeholders = defaultPlaceholders,
  isLinkRequired,
}) => {
  const classes = classNames('ickyc-email-message', className);
  const setSuffix = setClassSuffix('ickyc-email-message');

  const {
    input: { value: message },
  } = useField(messageName);

  const supportEmailExist = useMemo(() => message.includes('{{EMAIL}}'), [message]);
  const clickableLinkExist = useMemo(() => message.includes('{{LINK}}'), [message]);

  const tooltip = useMemo(
    () =>
      placeholders?.reduce(
        (acc, placeholder) => acc + `\n ${placeholderDescriptions[placeholder]}`,
        'Use following placeholders to customize email message:',
      ),
    [placeholders, placeholderDescriptions],
  );

  return (
    <div className={classes}>
      {label && (
        <div className={setSuffix('__label')}>
          <span>{label}</span>
          <InfoTooltip tooltip={tooltip} />
        </div>
      )}

      <div className={setSuffix('__content')}>
        <Field
          name={messageName}
          placeholder="Message"
          component={TextEditorField}
          forceUpdateInitialValues={false}
          defaultValue={defaultValues.message}
          validate={validators.composeValidators(
            validators.required(),
            validators.checkEmailMessage(placeholders, isLinkRequired),
          )}
        />
        {clickableLinkExist && (
          <Field
            label="Clickable Link Text"
            name={linkTextName}
            topLabeled
            component={InputField}
            validate={validators.required()}
            defaultValue={defaultValues.linkText}
          />
        )}
        {supportEmailExist && (
          <Field
            label="Support Email"
            name={supportEmailName}
            topLabeled
            component={InputField}
            validate={validators.email('Please provide proper email format')}
            description='Default support email is: "support@icomplyis.com"'
          />
        )}
      </div>
    </div>
  );
};

export default EmailMessage;
