import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../components/InputField';
import validators from '../../../../utilities/services/validators';
import AcceptRejectCaptureRadioButtons from '../AcceptRejectCaptureRadioButtons';
import './styles.scss';

const MinimumAgeRow = ({ modulePreffix }) => {
  return (
    <div className="ickyc-minimum-age-row">
      <Field
        name={`${modulePreffix}.minimumAcceptableAge`}
        component={InputField}
        type="number"
        label="Minimum Age To Accept: "
        parse={value => Number(value)}
        validate={validators.composeValidators(
          validators.maxAllowedValue('Max 99', 99),
          validators.minAllowedValue('Min 1', 1),
        )}
      />
      <AcceptRejectCaptureRadioButtons name={`${modulePreffix}.minimumAcceptableAgeStatus`} onlyIcons />
    </div>
  );
};

MinimumAgeRow.propTypes = {
  modulePreffix: PropTypes.string.isRequired,
};

export default React.memo(MinimumAgeRow);
