import classNames from 'classnames';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import toastr from 'toastr';
import enums from 'utilities/enums';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import IComplyForm from '../../../../../components/Form/IComplyForm';
import ModalTabHeader from '../../../../../components/Headers/ModalTabHeader';
import NestedListHeader from '../../../../../components/Headings/NestedListHeading';
import ConfirmationPage from '../../../../../components/Modal/ConfirmationPage';
import FormModal from '../../../../../components/Modal/FormModal';
import InformationModal from '../../../../../components/Modal/InformationModal';
import Spinner from '../../../../../components/Spinner';
import useModalHandler from '../../../../../hooks/useModalHandler';
import utilities from '../../../../../utilities';
import routesEnum from '../../../../../utilities/enums/routesEnum';
import IconManager from '../../../../../utilities/services/IconManager';
import PortalManagementService from '../../services/PortalManagement.service';
import DeleteDefaultPortalForm from '../DeleteDefaultPortalForm';
import Domains from '../NaturalPersonPortalModal/components/Domains';
import General from '../NaturalPersonPortalModal/components/General';
import Styling from '../NaturalPersonPortalModal/components/Styling';
import Technical from '../NaturalPersonPortalModal/components/Tehnical';
import RevertPortalSettingsButton from '../RevertPortalSettingsButton';
import SavePortalSettingsButton from '../SavePortalSettingsButton';
import LegalEntityModules from './components/LegalEntityModules';
import leModulesUtils from './components/LegalEntityModules/utils';
import './styles.scss';

const tabsOptionsLegalEntity = [
  {
    label: 'General ',
    key: 'general',
  },
  {
    label: 'Domains ',
    key: 'domains',
  },
  {
    label: 'Legal Entity Modules ',
    key: 'modules',
  },
  {
    label: 'Styling ',
    key: 'styling',
  },
  {
    label: 'Technical ',
    key: 'technical',
  },
];

const PortalDetailsLegalEntity = ({ className }) => {
  const [isGlobalErrorModalOpen, setIsGlobalErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { id, clientId } = useParams();
  const containerRef = useRef(null);
  const { isOpen: isConfirmModalOpen, close: closeConfirmModal, open: openConfirmModal } = useModalHandler();
  const [initialValues, setInitialValues] = useState({});
  const [initialRestrictions, setInitialRestrictions] = useState();
  const [forceDelete, setForceDelete] = useState(false);
  const [showDeleteDefaultPortalForm, setShowDeleteDefaultPortalForm] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [screens] = useState([<General />, <Domains />, <LegalEntityModules />, <Styling />, <Technical />]);
  const {
    fetchExistingConfiguration,
    updateConfigurationLogoImage,
    updateConfigurationLE,
    deleteConfiguration,
  } = PortalManagementService.initializeApiEndpoints(clientId);

  const classes = classNames('ickyc-kyc-page', 'ickyc-legal-entity-portal-details', className);

  const handlePortalDeletion = useCallback(
    ({ newDefaultPortalId }) => {
      const isNaturalPerson = initialValues?.type === 'NaturalPerson';
      const paramsData = clientId
        ? { id, clientId, type: isNaturalPerson ? 0 : 1 }
        : { id, type: isNaturalPerson ? 0 : 1 };

      return deleteConfiguration(...Object.entries(paramsData).map(el => el[1]), forceDelete, newDefaultPortalId)
        .then(() => {
          toastr.success(`Cofiguration has been deleted`);
          if (clientId) {
            history.push(
              utilities.routeParams(routesEnum.internal.BUSINESS_PROFILE, {
                id: clientId,
              }),
            );
          }
          history.push(utilities.routeParams(routesEnum.administration.PORTAL_MANAGMENT, { id }));
          setForceDelete(false);
        })
        .catch(err => {
          console.log(err);
          if (err?.response) {
            const { status: resStatus, data } = err.response;

            if (resStatus === 409) {
              setForceDelete(true);
              return;
            }

            if (resStatus === 406) {
              setForceDelete(true);
              setShowDeleteDefaultPortalForm(true);
              return;
            }

            if (resStatus >= 400 && resStatus < 500) {
              return { [FORM_ERROR]: data.message };
            }
            if (resStatus === 500) {
              return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        });
    },
    [clientId, deleteConfiguration, id, initialValues, history, forceDelete],
  );

  const handleConfirmationPageClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const goHistoryBack = useCallback(() => history.goBack(), [history]);

  const optionClick = useCallback(value => {
    setActiveTab(value);
  }, []);

  const headingListItems = useMemo(() => {
    return [
      <>
        <b>{`${initialValues?.name || `Portal Name`}`}</b>
      </>,
    ];
  }, [initialValues]);

  useEffect(() => {
    const fetchExistingConfigurationSettings = () => {
      setIsLoading(true);
      const paramsData = clientId ? { id, clientId } : { id };
      fetchExistingConfiguration(...Object.entries(paramsData).map(el => el[1]))
        .then(({ data: response }) => {
          setInitialRestrictions(
            response?.restrictions.map(el => {
              const {
                failbackToSelfieUploadMessage,
                minimumAcceptableAgeMessage,
                onboardingStatusMessage,
                ...rest
              } = el;
              return rest;
            }),
          );
          const tmpConf = PortalManagementService.parsePortalConfiguration(response);

          if (!tmpConf.domains.some(el => el.isClientUpdate)) {
            tmpConf.domains[0].isClientUpdate = true;
          }
          setInitialValues(tmpConf);
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              setIsGlobalErrorModalOpen(true);
            }
            if (status === 500) {
              toastr.error('Internal Server Error, Try Again Later');
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchExistingConfigurationSettings();
  }, [clientId, fetchExistingConfiguration, id]);

  const updateConfigurationSettings = useCallback(
    async submitObject => {
      if (submitObject.styling?.clientLogo) {
        const { imgUrl, enabled } = submitObject.styling.clientLogo;
        if (imgUrl?.file && enabled) {
          const formData = new FormData();
          formData.set('file', imgUrl.file);
          try {
            const paramsData = clientId
              ? { id: submitObject.id, clientId, formData }
              : { id: submitObject.id, formData };

            const { data: responseData } = await updateConfigurationLogoImage(
              ...Object.entries(paramsData).map(el => el[1]),
            );
            submitObject.styling.clientLogo.imgUrl = responseData.imgUrl;
          } catch (err) {
            utilities.errorHandler(err);
          }
        }
      }

      const paramsData = clientId
        ? { id: submitObject.id, clientId, submitObject }
        : { id: submitObject.id, submitObject };
      return updateConfigurationLE(...Object.entries(paramsData).map(el => el[1]))
        .then(({ data: responseData }) => {
          toastr.success('Portal Configuration Updated!');
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              toastr.error(
                Array.isArray(errData.message) ? errData.message.join('') : errData.message || errData.errors,
              );
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || errData.errors,
              };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        });
    },
    [clientId, updateConfigurationLE, updateConfigurationLogoImage],
  );

  const handleOnSubmit = async (vals, form) => {
    const updatedConfiguration = PortalManagementService.normalizePortalConfiguration(vals);
    updatedConfiguration.restrictions = Object.entries(
      diff(
        initialRestrictions,
        updatedConfiguration?.restrictions.map(elm => {
          const { selected, ...rest } = elm;
          return rest;
        }),
      ),
    ).map(elm => ({ countryId: initialRestrictions[Number(elm[0])].countryId, ...elm[1] }));

    form.initialize(vals);

    return updateConfigurationSettings(updatedConfiguration, false);
  };

  const handleRejectClick = useCallback(() => {
    setForceDelete(false);
    closeConfirmModal();
  }, [closeConfirmModal, setForceDelete]);

  if (isLoading) {
    return (
      <div className={classes} ref={containerRef}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={classes} ref={containerRef}>
      {isGlobalErrorModalOpen ? (
        <InformationModal hideModal={() => setIsGlobalErrorModalOpen(false)} onClick={handleConfirmationPageClick}>
          <ConfirmationPage
            title="Oops!"
            subtitle="Something went wrong…"
            text={
              <>
                We’re working on it, and we’ll get it fixed as soon as we can.
                <br /> You may refresh the page, or try again later.
              </>
            }
            icons={<div>{IconManager.get(IconManager.names.GLOBAL_ERROR)}</div>}
          />
        </InformationModal>
      ) : (
        <>
          <IComplyForm
            initialValues={initialValues}
            onSubmit={handleOnSubmit}
            mutators={{ ...arrayMutators }}
            validate={values => {
              const leModulesList = leModulesUtils.modulesList || [];

              const errors = {
                modules: Array(leModulesList.length).fill(null),
              };

              /* Natural Person Flow */
              const naturalPersonFlowModuleIndex = leModulesList.find(module => {
                return module.name === 'NaturalPersonFlowModule';
              })?.index;

              const { isEnabled, npDomain } = values.modules?.[naturalPersonFlowModuleIndex] || {};

              if (isEnabled && !npDomain) {
                errors.modules[naturalPersonFlowModuleIndex] = {
                  npDomain: 'Required',
                };
              }

              /* Add Legal Entity */
              // KYC Requests
              const addLegalEntityModuleIndex = leModulesList.find(module => module.name === 'AddLegalEntityModule')
                ?.index;

              const {
                entityDetailsRequireSupportingDocument,
                entityDetailsSupportingDocumentPackageId,
                addressDetailsRequireSupportingDocument,
                addressDetailsSupportingDocumentPackageId,
              } = values.modules?.[addLegalEntityModuleIndex]?.kycRequests || {};

              const kycRequestsError = { kycRequests: {} };

              if (
                entityDetailsRequireSupportingDocument &&
                (entityDetailsSupportingDocumentPackageId === null || entityDetailsSupportingDocumentPackageId === 0)
              ) {
                kycRequestsError.kycRequests = {
                  ...kycRequestsError.kycRequests,
                  entityDetailsSupportingDocumentPackageId: 'Required',
                };
                errors.modules[addLegalEntityModuleIndex] = kycRequestsError;
              }

              if (
                addressDetailsRequireSupportingDocument &&
                (addressDetailsSupportingDocumentPackageId === null || addressDetailsSupportingDocumentPackageId === 0)
              ) {
                kycRequestsError.kycRequests = {
                  ...kycRequestsError.kycRequests,
                  addressDetailsSupportingDocumentPackageId: 'Required',
                };
                errors.modules[addLegalEntityModuleIndex] = kycRequestsError;
              }

              /* Third Party Confirmation Module */
              const thirdPartyConfirmationModuleIndex = leModulesList.find(
                module => module.name === 'ThirdPartyConfirmationModule',
              )?.index;

              const { isEnabled: isTPCModuleEnabled, thirdPartyDomain } =
                values.modules?.[thirdPartyConfirmationModuleIndex] || {};

              if (isTPCModuleEnabled && !thirdPartyDomain) {
                errors.modules[thirdPartyConfirmationModuleIndex] = {
                  thirdPartyDomain: 'Required',
                };
              }

              return errors.modules.some(err => err) ? errors : {};
            }}
            formValidationErrors={errors => {
              const leModulesList = leModulesUtils.modulesList || [];

              const modulesErrors = errors?.modules;
              const formErrors = [];

              /* Natural Person Flow */
              const naturalPersonFlowModuleIndex = leModulesList.find(
                module => module.name === 'NaturalPersonFlowModule',
              )?.index;

              if (modulesErrors?.[naturalPersonFlowModuleIndex]?.npDomain) {
                formErrors.push('If natural person flow is enabled natural person portal domain must be selected');
              }

              /* Add Legal Entity */
              // KYC Requests
              const addLegalEntityModuleIndex = leModulesList.find(module => module.name === 'AddLegalEntityModule')
                ?.index;

              if (
                modulesErrors?.[addLegalEntityModuleIndex]?.kycRequests?.entityDetailsSupportingDocumentPackageId ||
                modulesErrors?.[addLegalEntityModuleIndex]?.kycRequests?.addressDetailsSupportingDocumentPackageId
              ) {
                formErrors.push('Supporting document is required for address details but document is not selected');
              }

              /* Third Party Confirmation Module */
              const thirdPartyConfirmationModuleIndex = leModulesList.find(
                module => module.name === 'ThirdPartyConfirmationModule',
              )?.index;

              if (modulesErrors?.[thirdPartyConfirmationModuleIndex]?.thirdPartyDomain) {
                formErrors.push(
                  'If Third-party confirmation module is enabled natural person portal domain must be selected',
                );
              }

              return formErrors.length ? formErrors : null;
            }}
          >
            <NestedListHeader
              title="Portal Settings"
              onClick={goHistoryBack}
              items={headingListItems}
              right={
                <>
                  <RevertPortalSettingsButton />
                  <SavePortalSettingsButton />

                  <div>
                    <DeleteButton onClick={openConfirmModal} tooltip="Delete Portal" tooltipPlacement="bottom" />
                    {isConfirmModalOpen && (
                      <FormModal
                        hideModal={closeConfirmModal}
                        title="Delete Portal"
                        className="ickyc-confirmation-modal ickyc-portal-management-confirm-modal"
                        onSubmit={handlePortalDeletion}
                        onRejectClick={handleRejectClick}
                        showErrorsInConfirmation={!forceDelete}
                        showConfirmationForm={forceDelete && !showDeleteDefaultPortalForm}
                        confirmationText="You are about to delete your default portal. Would you like to proceed?"
                      >
                        {showDeleteDefaultPortalForm ? (
                          <DeleteDefaultPortalForm portalType={enums.ENTITY_TYPE_NAMES.LEGAL_ENTITY} />
                        ) : (
                          <span className="ickyc-confirmation-message">
                            Are you sure you want to delete &nbsp;
                            <b>{`${initialValues?.name || `Portal Name`}`}</b>?
                          </span>
                        )}
                      </FormModal>
                    )}
                  </div>
                </>
              }
            />
            <div>
              <ModalTabHeader onClick={optionClick} options={tabsOptionsLegalEntity} />
              {screens[activeTab]}
            </div>
          </IComplyForm>
        </>
      )}
    </div>
  );
};
PortalDetailsLegalEntity.propTypes = {
  className: PropTypes.string,
};

PortalDetailsLegalEntity.defaultProps = {
  className: undefined,
};
export default PortalDetailsLegalEntity;
