import { diff } from 'deep-object-diff';
import authEnums from '../../../../../utilities/enums/authEnums';

const mergeJurisdictionTables = restOfConfig => {
  const {
    biometricAuthenticationEvent,
    documentAuthenticationEvent,
    entityProfile,
    identityAuthenticationEvent,
  } = restOfConfig;

  const restrictions = [];

  Object.entries(entityProfile).forEach(({ 0: key, 1: value }) => {
    const countryId = Number(key.replace('country', ''));
    const { selected, ...restOfValue } = value;
    restrictions.push({
      [`countryId`]: countryId,
      ...restOfValue,
      ...biometricAuthenticationEvent[key],
      ...documentAuthenticationEvent[key],
      ...identityAuthenticationEvent[key],
    });
  });
  return restrictions;
};

const parseDocumentsListTable = restOfConfig => {
  const { automationsSupportingDocsTransformed } = restOfConfig;

  const restrictions = [];

  Object.entries(automationsSupportingDocsTransformed).forEach(({ 0: key, 1: value }) => {
    const documentId = Number(key.replace('document', ''));
    const { selected, ...restOfValue } = value;
    restrictions.push({
      [`packageId`]: documentId,
      ...restOfValue,
    });
  });
  return restrictions;
};

const prepareNaturalPersonRcuValsForSubmit = (values, platformAccessByLicence) => {
  const {
    generalSettings,
    kycRequests: { runVerification, ...restOfKYCRequests },
    initialyFetchedData,
    biometricAuthenticationAutoAcceptance,
    documentAuthenticationAutoAcceptance,
    identityAuthenticationAutoAcceptance,
    periodicReviewInterval,
    periodicReviewValue,
    setProfileStatusTo,
    setRiskLevelTo,
    autoAssignSupportingDocumentPackagesEnabled,
    autoAssignIdentityAuthenticationEnabled,
    autoAssignBiometricAuthenticationEnabled,
    autoAssignDocumentAuthenticationEnabled,
    autoAssignEntityProfileEnabled,
    enablePeriodicReview,
    autoUpdateEntityStatus,
    autoUpdateRiskLevel,
    automation: { isEnabled },
    changeStatusForApprovedProfile,
    approvedProfileStatus,
    dataCollection,
    ...rest
  } = values;
  let automation = { isEnabled: false };

  if (platformAccessByLicence.some(elm => elm === authEnums.ACCESS_BY_LICENCE.AUTOMATIONS)) {
    const mergedJurisdiction = mergeJurisdictionTables(values);
    const mergedSuppDocs = parseDocumentsListTable(values);

    const jurisdictionChanges = diff(initialyFetchedData?.automationsJurisdictions, mergedJurisdiction);
    const suppDocsChanges = diff(initialyFetchedData?.automationsSupportingDocs, mergedSuppDocs);
    automation = {
      isEnabled,
      automationsJurisdictions: Object.entries(jurisdictionChanges).map(country => ({
        ...mergedJurisdiction[Number(country[0])],
      })),
      automationsSupportingDocs: Object.entries(suppDocsChanges).map(suppDoc => ({
        ...mergedSuppDocs[Number(suppDoc[0])],
      })),
      biometricAuthenticationAutoAcceptance,
      documentAuthenticationAutoAcceptance,
      identityAuthenticationAutoAcceptance,
      periodicReviewInterval,
      periodicReviewValue,
      setProfileStatusTo,
      setRiskLevelTo,
      autoAssignSupportingDocumentPackagesEnabled,
      autoAssignIdentityAuthenticationEnabled,
      autoAssignBiometricAuthenticationEnabled,
      autoAssignDocumentAuthenticationEnabled,
      autoAssignEntityProfileEnabled,
      enablePeriodicReview,
      autoUpdateEntityStatus,
      autoUpdateRiskLevel,
      changeStatusForApprovedProfile,
      approvedProfileStatus,
    };
  }
  restOfKYCRequests.identityVerification.runIdentityDataValidation = runVerification;
  restOfKYCRequests.documentAuthentication.runDocumentVerification = runVerification;
  dataCollection.packagesTypes = dataCollection.packagesTypes
    .filter(doc => doc?.packageId)
    .map(({ packageId, assignedUserId, required }) => ({
      packageId,
      assignedUserId,
      required,
    }));

  const plainlinkSelfRegex = /target=\\"_self\\">plain link</;
  const linkSelfRegex = /target=\\"_self\\">link</;

  generalSettings.emailMessage = JSON.stringify(generalSettings.emailMessage)
    .replace(plainlinkSelfRegex, 'target=\\"_blank\\" class=\\"plain-link-field\\">plain link<')
    .replace(linkSelfRegex, 'target=\\"_blank\\" class=\\"short-link-field\\">link<')
    .replace(/<br \/>/g, '');
  generalSettings.emailMessage = JSON.parse(generalSettings.emailMessage);

  return {
    ...rest,
    kycRequests: restOfKYCRequests,
    generalSettings,
    automation,
    dataCollection,
  };
};

const prepareLegalEntityValsForSubmit = (values, selectedDomainGroup) => {
  const submitObj = values;
  const plainlinkSelfRegex = /target=\\"_self\\">plain link</;
  const linkSelfRegex = /target=\\"_self\\">link</;
  submitObj.emailBody = JSON.stringify(submitObj.emailMessage)
    .replace(plainlinkSelfRegex, 'target=\\"_blank\\" class=\\"plain-link-field\\">plain link<')
    .replace(linkSelfRegex, 'target=\\"_blank\\" class=\\"short-link-field\\">link<')
    .replace(/<br \/>/g, '');
  submitObj.emailBody = JSON.parse(submitObj.emailBody);
  submitObj.configId = selectedDomainGroup?.id;
  submitObj.forceRequest = true;
  submitObj.requestDocuments = true;
  const { emailMessage, ...rest } = submitObj;
  return rest;
};
export default {
  prepareNaturalPersonRcuValsForSubmit,
  prepareLegalEntityValsForSubmit,
};
