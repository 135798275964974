import axios from '../axiosInstance';

// prefix -> ['case-management','entitty-managegement']

const ENDPOINTS = {
  getTopLevelCaseComments: complianceLogId => `/case-management/${complianceLogId}/comments`,
  getTopLevelEntityComments: entityId => `/entities/${entityId}/comments`,
  getTopLevelMatchComments: (complianceLogId, hitId) => `/case-management/${complianceLogId}/hits/${hitId}/comments`,
  getReplies: commentId => `/comments/${commentId}/replies`,
  editComment: commentId => `/comments/${commentId}`,
  deleteComment: commentId => `/comments/${commentId}`,
  createNewComment: () => `/comments`,
  createNewReply: commentId => `/comments/${commentId}`,
};

const getTopLevelCaseComments = data => {
  const { complianceLogId, ...params } = data;

  return axios.get(ENDPOINTS.getTopLevelCaseComments(complianceLogId), { params });
};
const getTopLevelEntityComments = data => {
  const { entityId, ...params } = data;
  return axios.get(ENDPOINTS.getTopLevelEntityComments(entityId), { params });
};

const editComment = (commentId, payload) => axios.put(ENDPOINTS.editComment(commentId), payload);

const removeComment = id => axios.delete(ENDPOINTS.deleteComment(id));

const createNewComment = payload => axios.post(ENDPOINTS.createNewComment(), payload);

const createNewReply = (commentId, payload) => axios.post(ENDPOINTS.createNewReply(commentId), payload);

const getReplies = (commentId, params) => axios.get(ENDPOINTS.getReplies(commentId), { params });

const getMatchComments = data => {
  const { complianceLogId, hitId, ...params } = data;
  return axios.get(ENDPOINTS.getTopLevelMatchComments(complianceLogId, hitId), { params });
};
export default {
  editComment,
  removeComment,
  createNewComment,
  getReplies,
  createNewReply,
  getMatchComments,
  getTopLevelCaseComments,
  getTopLevelEntityComments,
};
