import React, { useEffect, useRef, useState } from 'react';
import history from '../../../modules/history';
import setClassSuffix from '../../../utilities/services/ClassManager';
import TokenStorage from '../../../utilities/services/local-storage/TokenStorage';

const BasicInfoAndIdUploadStep = () => {
  const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
  const widgetRef = useRef();
  const baseClass = 'ickyc-basic-info-step';
  const setSuffix = setClassSuffix(baseClass);
  const params = new URLSearchParams(window.location.search);
  const token = params.get('verificationtoken');
  const twoFactor = params.get('twofactor') === 'true';
  const email = params.get('email');

  const [showMessage, setShowMessage] = useState(false);
  useEffect(() => {
    if (token) {
      TokenStorage.token = token;
      localStorage.removeItem('icomplyPreview');
    }
  }, [token]);

  useEffect(() => {
    const getConfiguration = async () => {
      if (widgetRef.current && window.iComply && !isWidgetLoaded) {
        window.iComply.init(widgetRef.current, {
          data: {
            verificationCode: token,
            email: email?.replaceAll(' ', '+'),
          },
          verificationIdCallback() {},
          callback(serverResponse) {
            const { resultCode } = serverResponse;
            if (resultCode < 400) {
              setShowMessage(true);
              setTimeout(() => {
                twoFactor
                  ? history.push(`/auth/two-factor-authentication/enable-authenticator?token=${token}`)
                  : history.push('/auth/login');
              }, 2000);
            }
          },
        });
        setIsWidgetLoaded(true);
      }
    };
    getConfiguration();
  }, [widgetRef.current, isWidgetLoaded, email]);

  if (showMessage) {
    return <h3 className="ickyc-widget__global-message">Verification successful. You will be redirected...</h3>;
  }
  return !token ? (
    <h3 className="ickyc-widget__global-message">No Token Present!</h3>
  ) : (
    <div className={setSuffix('__widget-container')} ref={widgetRef} />
  );
};
export default BasicInfoAndIdUploadStep;
