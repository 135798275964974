import classNames from 'classnames';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api';
import IComplyForm from '../../../components/Form/IComplyForm';
import BareHeading from '../../../components/Headings/BareHeading';
import Loader from '../../../components/Loader';
import Spinner from '../../../components/Spinner';
import setClassSuffix from '../../../utilities/services/ClassManager';
import { PermissionGroupContext } from '../../../utilities/services/contexts';
import RestrictionsSection from './components/RestrictionsSection';

const PERMISSION_RULES = ['Accepted', 'Rejected', 'Captured'];

const RestrictionsPage = ({ className }) => {
  const [initialValues, setInitialValues] = useState({});
  const [updatingStatus, setUpdatingStatus] = useState();
  const [isFetching, setIsFetching] = useState(true);

  const { edit: canEdit } = useContext(PermissionGroupContext);

  const handleSubmit = vals => {
    const changes = diff(initialValues, vals);

    if (changes.jurisdictions) {
      changes.jurisdictions = Object.entries(changes.jurisdictions).map(({ 0: key, 1: value }) => {
        const { selected, ...restValues } = value;

        return {
          countryId: key.split('country')[1],
          permissionRule:
            PERMISSION_RULES.indexOf(value.minimumAcceptableAgeStatus) === -1
              ? undefined
              : PERMISSION_RULES.indexOf(value.minimumAcceptableAgeStatus),
          ...restValues,
        };
      });
    }

    setUpdatingStatus(true);
    return api.administration
      .updateUserOnboarding(changes)
      .then(() => {
        setInitialValues(vals);
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errData.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => setUpdatingStatus(false));
  };

  useEffect(() => {
    const loadInitialValues = () => {
      setIsFetching(true);
      api.administration
        .getUserOnboardingInfo()
        .then(({ data }) => {
          const { jurisdictions, minimumAcceptableAgeMessage } = data;
          setInitialValues({
            minimumAcceptableAgeMessage,
            jurisdictions: jurisdictions.reduce(
              (accumulator, { countryId, permissionRule, minimumAcceptableAge }) => ({
                ...accumulator,
                [`country${countryId}`]: {
                  minimumAcceptableAgeStatus: PERMISSION_RULES[permissionRule],
                  minimumAcceptableAge,
                },
              }),
              {},
            ),
          });
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => setIsFetching(false));
    };
    loadInitialValues();
  }, []);

  const baseClass = 'ickyc-page';

  const setSuffix = setClassSuffix(baseClass);

  const classes = classNames(baseClass, 'ickyc-restrictions', className);

  return (
    <div className={classes}>
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <BareHeading title="Restrictions" />
          <div className={setSuffix('__content')}>
            {updatingStatus && <Loader />}
            <IComplyForm initialValues={initialValues} onSubmit={handleSubmit} showControls={canEdit}>
              <RestrictionsSection
                disabled={!canEdit}
                messageFieldName="minimumAcceptableAgeMessage"
                tableFieldPreffix="jurisdictions"
              />
            </IComplyForm>
          </div>
        </>
      )}
    </div>
  );
};

RestrictionsPage.propTypes = {
  className: PropTypes.string,
};

RestrictionsPage.defaultProps = {
  className: undefined,
};

export default RestrictionsPage;
