import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Field, useField, useForm, useFormState } from 'react-final-form';
import toastr from 'toastr';
import api from '../../../../../api';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import InputField from '../../../../../components/InputField';
import FormModal from '../../../../../components/Modal/FormModal';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../components/SelectField';
import CheckboxList from '../../../../../components/Tables/CheckboxList';
import ToggleField from '../../../../../components/ToggleField';
import useModalHandler from '../../../../../hooks/useModalHandler';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import validators from '../../../../../utilities/services/validators';
import CollapsibleTableRow from '../../../../Kyc/components/CollapsibleTableRow';

const filterOptions = (options = {}, ruleId, ruleIndex) =>
  Object.entries(options).reduce(
    (acc, [key, value]) =>
      value?.id === ruleId
        ? { ...acc }
        : {
            ...acc,
            [key]: value && { ...value, number: value?.number > ruleIndex ? value.number - 1 : value.number },
          },
    {},
  );

const removeRuleFromForm = (formValues, ruleId, ruleIndex) => {
  const { rules, country, riskType, kycStatus } = formValues || {};

  const filteredRules = (rules || []).filter(rule => rule.id !== ruleId);
  const newRules = filteredRules.map(rule => {
    if (rule.id.startsWith('new_rule_')) {
      const ruleNumber = +rule.id.split('new_rule_')[1];
      return {
        ...rule,
        id: `new_rule_${ruleNumber > ruleIndex ? ruleNumber - 1 : ruleNumber}`,
      };
    }
    return rule;
  });

  // remove all selected options by the rule, and change rule index
  const newCountryValues = filterOptions(country, ruleId, ruleIndex);
  const newRiskTypeValues = filterOptions(riskType, ruleId, ruleIndex);
  const newKycStatusValues = filterOptions(kycStatus, ruleId, ruleIndex);

  const newFormValue = {
    ...formValues,
    rules: newRules,
    country: newCountryValues,
    riskType: newRiskTypeValues,
    kycStatus: newKycStatusValues,
  };

  return newFormValue;
};

const RuleRow = ({ index, namePreffix, className, rulesOptions, onRemove, startIndex, onControlsClick }) => {
  const setSufix = setClassSuffix(`${className}__column`);
  const { values, initialValues } = useFormState();
  const { mutators, batch, change } = useForm();

  const {
    input: { value: rules },
  } = useField('rules');

  const {
    input: {
      value: { id: ruleId, scoringRuleType },
    },
  } = useField(`${namePreffix}`);

  const {
    input: { value: ruleTypeOptionsValue },
  } = useField(scoringRuleType);

  const {
    isOpen: isConfirmationModalOpen,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useModalHandler();

  const selectedOptions = useMemo(() => {
    const selectedOptionsIds = Object.entries(ruleTypeOptionsValue).reduce(
      (acc, [key, value]) => (value?.id === ruleId ? [...acc, key.split('i_')[1]] : acc),
      [],
    );

    const options = rulesOptions[scoringRuleType];

    const selectedOptionsNames = selectedOptionsIds.map(
      optionId => options.find(option => `${option.id}` === `${optionId}`)?.name,
    );

    return selectedOptionsNames.length > 0 ? selectedOptionsNames.join(', ') : 'No Selected Options';
  }, [rulesOptions, scoringRuleType, ruleTypeOptionsValue]);

  const removeRule = useCallback(() => {
    if (!ruleId.startsWith('new_rule_')) {
      // remove old rule
      onControlsClick(true);

      api.administration.riskScoring
        .removeRule(ruleId)
        .then(() => {
          const newFormValue = removeRuleFromForm(values, ruleId, startIndex + index + 1);

          const initDiffs = diff(initialValues, values);

          const hasMoreDiffs =
            initDiffs.rules ||
            ['country', 'riskType', 'kycStatus'].some(ruleType =>
              initDiffs[ruleType] ? Object.values(initDiffs[ruleType]).some(rule => rule.id !== ruleId) : false,
            );

          batch(() => {
            mutators.remove(`rules`, index);
            change('country', newFormValue.country);
            change('riskType', newFormValue.riskType);
            change('kycStatus', newFormValue.kycStatus);
          });

          if (!hasMoreDiffs) onRemove(newFormValue);

          closeConfirmationModal();
          toastr.success('Succesfully deleted Rule');
        })
        .catch(err => {
          toastr.error('Error occurred while deleting Rule');
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          onControlsClick(false);
        });
    } else {
      // remove new rule
      const newFormValue = removeRuleFromForm(values, ruleId, startIndex + index + 1);

      const initDiffs = diff(initialValues, values);

      batch(() => {
        mutators.remove(`rules`, index);
        change('country', newFormValue.country);
        change('riskType', newFormValue.riskType);
        change('kycStatus', newFormValue.kycStatus);
      });

      if (!initDiffs.rules) onRemove(newFormValue);

      closeConfirmationModal();
      toastr.success('Succesfully deleted Rule');
    }
  }, [
    ruleId,
    values,
    scoringRuleType,
    batch,
    initialValues,
    mutators,
    change,
    ruleTypeOptionsValue,
    index,
    onControlsClick,
    onRemove,
  ]);

  const handleOnClick = e => e.stopPropagation();

  return (
    <CollapsibleTableRow
      externalArrow
      className={className}
      row={
        <>
          <td className={setSufix('__index')} onClick={handleOnClick}>
            {startIndex + index + 1}
          </td>
          <td className={setSufix('__scoringRuleType')} onClick={handleOnClick}>
            <Field
              name={`${namePreffix}.scoringRuleType`}
              options={[
                {
                  id: 'country',
                  label: 'Subject is from:',
                  value: 'country',
                },
                {
                  id: 'riskType',
                  label: 'Subject has a case with a risk type:',
                  value: 'riskType',
                },
                {
                  id: 'kycStatus',
                  label: 'Subject has KYC Status:',
                  value: 'kycStatus',
                },
              ]}
              component={SelectField}
              defaultValue="country"
              disabled={!ruleId.startsWith('new_rule_')}
              Trigger={<SelectTrigger placeholder="Choose" />}
              Content={<SelectFilterContent />}
              validate={validators.required(' ')}
              renderAsPortal
            />
          </td>
          <td className={setSufix('__scoringRuleOperation')} onClick={handleOnClick}>
            <Field
              name={`${namePreffix}.scoringRuleOperation`}
              options={[
                {
                  id: 'addition',
                  label: 'Add +',
                  value: 'addition',
                },
                {
                  id: 'subtraction',
                  label: 'Subtract -',
                  value: 'subtraction',
                },
              ]}
              component={SelectField}
              defaultValue="country"
              Trigger={<SelectTrigger placeholder="Choose" />}
              Content={<SelectFilterContent />}
              validate={validators.required(' ')}
              renderAsPortal
            />
          </td>
          <td className={setSufix('__score')} onClick={handleOnClick}>
            <Field
              name={`${namePreffix}.score`}
              placeholder="Points"
              type="number"
              positiveNumbersOnly
              component={InputField}
              label="Points"
              validate={validators.required(' ')}
            />
          </td>
          <td className={setSufix('__active')} onClick={handleOnClick}>
            <Field
              name={`${namePreffix}.active`}
              component={ToggleField}
              showCustomText
              toggleOnText="Active"
              toggleOffText="Inactive"
            />
          </td>
          <td className={setSufix('__remove')} onClick={handleOnClick}>
            <DeleteButton onClick={() => openConfirmationModal()} />
            <div>
              {isConfirmationModalOpen && (
                <FormModal
                  hideModal={closeConfirmationModal}
                  title="Remove Rule"
                  className="ickyc-confirmation-modal"
                  onSubmit={removeRule}
                >
                  <span className="ickyc-confirmation-message">Are you sure you want to delete rule?</span>
                </FormModal>
              )}
            </div>
          </td>
          <td className={setSufix('__new-line-collapse')}>
            <ArrowDownIcon className={setSufix('__down-arrow-icon')} />
            <ArrowRight className={setSufix('__right-arrow-icon')} />
            <span className="selected-options-label">{selectedOptions}</span>
          </td>
        </>
      }
    >
      <div>
        <CheckboxList
          scoringRuleType={scoringRuleType}
          ruleIndex={startIndex + index + 1}
          options={rulesOptions[scoringRuleType] || []}
          ruleId={ruleId}
          key={`${ruleId}_${rules.length}`}
        />
      </div>
    </CollapsibleTableRow>
  );
};

RuleRow.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  namePreffix: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  onControlsClick: PropTypes.func,
  rulesOptions: PropTypes.object.isRequired,
  startIndex: PropTypes.number,
};
RuleRow.defaultProps = {
  className: '',
  onRemove: () => {},
  onControlsClick: () => {},
  startIndex: 0,
};
export default RuleRow;
