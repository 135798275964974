import { itemTypes } from 'scenes/Kyc/EntityManagement/components/AdditionalDocumentsForm/components/PackageItemsFields/utils';

const answerFormatOptions = [
  { label: 'Discrete Choice', value: 'DiscreteChoice' },
  { label: 'Text Field', value: 'TextField' },
  { label: 'Currency Field', value: 'CurrencyField' },
  { label: 'Numerical Field', value: 'NumericalField' },
  { label: 'Multiple Choice', value: 'MultipleChoice' },
  { label: 'Percentage Field', value: 'PercentageField' },
];

const validateCurrency = values => {
  const { parameters, type } = values || { parameters: {} };

  if (type === itemTypes.CURRENCYFIELD) {
    const atLeastOneCurrencyEnabled = Object.entries(parameters).some(([key, value]) => {
      if (key?.startsWith('currency')) {
        return value?.isEnabled;
      }
    });

    return atLeastOneCurrencyEnabled ? undefined : { currency: 'At least one currency must be enabled' };
  }
};

const stopPropagation = event => event.stopPropagation();
export default { answerFormatOptions, stopPropagation, validateCurrency };
