import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import utilities from 'utilities';
import routesEnum from 'utilities/enums/routesEnum';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';

const ThirdPartyConfirm = ({ thirdPartyInfo }) => {
  const { name, role, id: entityId } = thirdPartyInfo;

  const history = useHistory();
  const entityProfileLink = () => {
    if (!entityId) return;
    history.push(
      utilities.routeParams(routesEnum.kyc.NATURAL_PERSON, {
        id: entityId,
      }),
    );
  };

  const classBase = 'ickyc-third-party-confirm';
  const setSuffix = setClassSuffix(classBase);

  return (
    <div className={classBase}>
      <div className={setSuffix('__header')}>
        <div className={setSuffix('__header__left')}>
          <h3>Third Party Confirmation</h3>
          <span className={setSuffix('__status-info')}>
            Confirmed by {role} <strong onClick={entityProfileLink}>{name}</strong>
          </span>
        </div>
      </div>
    </div>
  );
};

ThirdPartyConfirm.propTypes = {
  thirdPartyInfo: PropTypes.object,
};
ThirdPartyConfirm.defaultProps = {
  thirdPartyInfo: {},
};

export default ThirdPartyConfirm;
