import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField, useFormState } from 'react-final-form';
import Input from '../../../../../components/Input';
import InputField from '../../../../../components/InputField';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import validators from '../../../../../utilities/services/validators';

const RiskLevelAssignmentRow = ({ className, namePreffix, index }) => {
  const setSufix = setClassSuffix(`${className}__column`);
  const {
    values: { riskLevels },
  } = useFormState();

  const {
    input: {
      value: { label },
    },
  } = useField(`${namePreffix}`);

  const lastMaximumScore = riskLevels[index - 1]?.maximumScore;
  // eslint-disable-next-line no-nested-ternary
  const minValue = index > 0 ? (lastMaximumScore === '' ? '' : +lastMaximumScore + 1) : 0;
  const isLastRow = index === riskLevels.length - 1;

  return (
    <tr className="ickyc-table__row" key={`${minValue}_${index}`}>
      <td className={setSufix('__label')}>
        <span>{`${index + 1} - ${label}`}</span>
      </td>
      <td className={setSufix('__level-min')}>
        <Input value={minValue} disabled />
      </td>
      <td className={setSufix('__level-max')}>
        <Field
          name={`${namePreffix}.maximumScore`}
          type="number"
          component={InputField}
          validate={validators.composeValidators(
            validators.required(' '),
            validators.minAllowedValue(`Min value ${minValue}`, minValue),
          )}
          {...(isLastRow && { preffix: '∞', disabled: true, validate: undefined })}
        />
      </td>
    </tr>
  );
};

RiskLevelAssignmentRow.propTypes = {
  className: PropTypes.string,
  namePreffix: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

RiskLevelAssignmentRow.defaultProps = {
  className: '',
};

export default RiskLevelAssignmentRow;
