import { CircularProgress } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import api from '../../../api';
import OutlineButton from '../../../components/Buttons/OutlineButton';
import TokenStorage from '../../../utilities/services/local-storage/TokenStorage';
import './styles.scss';

const EmailConfirmation = () => {
  const baseClass = 'ickyc-confirmation-page';
  const params = new URLSearchParams(window.location.search);
  const codeParam = params.get('code');
  const history = useHistory();
  const [cofirmationInProgress, setConfirmationInProgress] = useState(false);
  const [afterCofirmationInProgress, setAfterCofirmationInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const redirectToLogin = () => {
    setTimeout(function() {
      TokenStorage.remove();
      history.push('/login');
    }, 4000);
  };
  const redirectToLoginHandler = () => {
    setConfirmationInProgress(true);
    api.confirmation
      .confirmNewEmail(codeParam)
      .then(() => {
        setAfterCofirmationInProgress(true);
        redirectToLogin();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            setErrorMessage(errorData.error || `${errorData.message?.slice(0, 80)}...`);
            return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
          }
          if (status === 500) {
            setErrorMessage('Error occured while trying to update Personal Details');
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else {
          setErrorMessage('Error occured while trying to update Personal Details');
          return { [FORM_ERROR]: 'Error occured while trying to update Personal Details' };
        }
      })
      .finally(() => {
        setConfirmationInProgress(false);
      });
  };

  return !codeParam ? (
    <h3 className="ickyc-widget__global-message">No Token Present!</h3>
  ) : (
    <div className={baseClass}>
      {!afterCofirmationInProgress && (
        <div>
          <h1>Confirm Your New Email Address</h1>
          <h3>Just to make sure you really want to change your email, please click the button below</h3>
          <OutlineButton onClick={redirectToLoginHandler}>
            Confirm Email Address {cofirmationInProgress && <CircularProgress />}
          </OutlineButton>
          {errorMessage && <div className="ickyc-confirmation-page__error-container">{errorMessage}</div>}
        </div>
      )}
      {afterCofirmationInProgress && (
        <div>
          <h1>Email Successfully Confirmed!</h1>
          <h3>You will be automaticallly redirected to login page.</h3>
        </div>
      )}
    </div>
  );
};
export default EmailConfirmation;
