const SupportingDocumentFileFormat = {
  pdf: 'PDF',
  csv: 'CSV',
  doc: 'DOC',
  jpg: 'JPEG',
  jpeg: 'JPEG',
  png: 'PNG',
  docusign: 'DOCU',
};

const getProperFormat = fileName => {
  const extension = fileName.split('.').pop();
  return SupportingDocumentFileFormat[extension];
};

const utils = {
  getProperFormat,
};

export default utils;
