import classNames from 'classnames';
import Input from 'components/Input';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import toastr from 'toastr';
import api from '../../../../../../../../api';
import Badge from '../../../../../../../../components/Badge';
import DeleteButton from '../../../../../../../../components/Buttons/DeleteButton';
import SingleCountry from '../../../../../../../../components/CountrySelect/components/SingleCountry';
import Loader from '../../../../../../../../components/Loader';
import FormModal from '../../../../../../../../components/Modal/FormModal';
import TagsContainer from '../../../../../../../../components/TagButtonContainer';
import useModalHandler from '../../../../../../../../hooks/useModalHandler';
import useSelect from '../../../../../../../../hooks/useSelect';
import bus from '../../../../../../../../modules/bus';
import { selectCountries } from '../../../../../../../../store/selectors/global.selector';
import utilities from '../../../../../../../../utilities';
import enums from '../../../../../../../../utilities/enums';
import routesEnum from '../../../../../../../../utilities/enums/routesEnum';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../../utilities/services/DateManager';
import {
  AffiliatedEntitiesErrorContext,
  EntityContext,
  PermissionGroupContext,
} from '../../../../../../../../utilities/services/contexts';
import AttachRolesModal from '../AttachRolesModal';

const AffiliatedEntityTableRow = ({
  rowInfo,
  className,
  onRemove,
  onControlsClick,
  reviewPrimaryContactsIssue,
  onUpdate,
}) => {
  const { entityId, date, name, country, ownership: initialOwnership, status, roles, issues, entityType } = rowInfo;
  const checkStatus = s => s || undefined;
  const { entityId: parentId } = useContext(EntityContext);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [rolesArray, setRolesArray] = useState([]);
  const setSuffix = setClassSuffix(`${className}__column`);
  const statusClasses = classNames({
    [setSuffix('__status')]: true,
    [setSuffix('__status--pending')]: checkStatus(enums.ENTITY_STATUSES.PENDING),
    [setSuffix('__status--approved')]: checkStatus(enums.ENTITY_STATUSES.APPROVED),
    [setSuffix('__status--rejected')]: checkStatus(enums.ENTITY_STATUSES.REJECTED),
  });
  const countries = useSelect(selectCountries);
  const countryToShow = countries.find(c => c.id === country);
  const { setError } = useContext(AffiliatedEntitiesErrorContext);
  const [ownership, setOwnership] = useState(initialOwnership);
  const [ownershipDebouncer, setOwnershipDebouncer] = useState();
  const entityProfileLink = useMemo(() => {
    return utilities.routeParams(
      entityType === 'Legal Entity' ? routesEnum.kyc.LEGAL_ENTITY : routesEnum.kyc.NATURAL_PERSON,
      {
        id: entityId,
      },
    );
  }, [entityId, entityType]);

  useEffect(() => {
    setRolesArray(!roles ? [] : roles);
  }, [roles]);

  const { isOpen: isAttachModalOpen, close: closeAttachmentModal, open: openAttachmentModal } = useModalHandler();

  const removeRole = async role => {
    setUpdating(true);
    await api.kyc.entityManagement.legalEntity
      .updateAffiliatedEntityRoles(parentId, entityId, {
        roles: rolesArray.filter(rol => rol !== role.name),
        entityType: entityType === 'Legal Entity' ? 1 : 0,
      })
      .then(({ data }) => {
        if (data.comment) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, data?.comment);
        }
        setRolesArray(rolesArray.filter(rol => rol !== role.name));
        onControlsClick(data?.entity?.affiliatedEntitiesReviewRequired);
        onUpdate(data?.entity);
        toastr.success('Role was removed');
      })
      .catch(err => {
        if (err?.response) {
          const { status: s, data: errData } = err.response;
          if (s === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
          if (s >= 400 && s < 500) {
            if (s === 422) toastr.error('Affiliated entity must have at least one role');
            return { [FORM_ERROR]: errData?.message || 'Error Occured' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => setUpdating(false));
  };

  const removeEntity = () => {
    return api.kyc.entityManagement.legalEntity
      .deleteAffiliatedEntity(parentId, entityId)
      .then(data => {
        onControlsClick(data?.data?.affiliatedEntitiesReviewRequired);
        setConfirmModalOpen(false);
        onRemove(rowInfo);
        toastr.success(`${name} has been unlinked`);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  const updateAffiliatedEntity = entityInfo => {
    setRolesArray(entityInfo?.roles);
    onControlsClick(entityInfo?.affiliatedEntitiesReviewRequired);
    onUpdate(entityInfo);
  };
  const isPrimaryContact = useMemo(() => rolesArray.some(r => r === 'Primary Contact'), [rolesArray]);

  const { delete: canDelete, edit: canEdit } = useContext(PermissionGroupContext);

  const converterFuntion = useCallback(value => {
    // If the previous value was "0" and the current value is not empty,
    // remove the leading "0"
    if (value?.length > 1 && value?.startsWith('0')) {
      value = value.substring(1);
    }

    return value;
  }, []);

  const handleOwnershipChange = useCallback(
    event => {
      const ownershipNewValue = event?.target?.value;

      setOwnership(ownershipNewValue);

      clearTimeout(ownershipDebouncer);

      if (ownershipNewValue >= 0 && ownershipNewValue <= 100) {
        const debouncer = setTimeout(async () => {
          try {
            setError();
            setUpdating(true);
            await api.kyc.entityManagement.legalEntity.updateAffiliatedEntityOwnership(entityId, parentId, {
              shareholder: ownershipNewValue,
            });

            onUpdate({ ...rowInfo, ownership: ownershipNewValue });
            toastr.success('Successfully updated affiliated entities ownership');
          } catch (err) {
            setOwnership(initialOwnership);
            if (err.response) {
              const { data } = err.response;
              console.error(err);
              setError(data.errors[0]);
            }
            toastr.error('Error occured while updating affiliated entities ownership');
          } finally {
            setUpdating(false);
          }
        }, 1400);
        setOwnershipDebouncer(debouncer);
      }
    },
    [ownership, entityId, parentId, initialOwnership, setOwnership, onUpdate, ownershipDebouncer],
  );

  return (
    <tr className={`${className}__row`}>
      {updating && <Loader />}

      <td className={setSuffix('__date')}>
        <NavLink to={entityProfileLink}>{DateManager.monDayYearLocal(date)}</NavLink>
      </td>
      <td
        className={classNames({
          [setSuffix('__name')]: true,
          [setSuffix('__accented')]: reviewPrimaryContactsIssue && isPrimaryContact,
        })}
      >
        <NavLink to={entityProfileLink}>{name}</NavLink>
      </td>
      <td className={setSuffix('__roles')}>
        {isAttachModalOpen && (
          <AttachRolesModal
            hideModal={closeAttachmentModal}
            parentEntityId={parentId}
            affEntityId={entityId}
            entityType={entityType === 'Legal Entity' ? 1 : 0}
            initialRoles={rolesArray}
            setNewlyAdded={updateAffiliatedEntity}
          />
        )}
        <TagsContainer
          asTagButton={canEdit}
          options={rolesArray.map(role => ({ id: role, name: role, label: role }))}
          onRemove={removeRole}
          onAdd={openAttachmentModal}
        />
      </td>
      <td className={setSuffix('__countryName')}>
        <NavLink to={entityProfileLink}>
          {country && countryToShow ? (
            <SingleCountry alpha2Code={countryToShow.alpha2Code.toLowerCase()} name={countryToShow.name} />
          ) : (
            'N/A'
          )}
        </NavLink>
      </td>
      <td className={setSuffix('__ownership')}>
        <Input
          type="number"
          placeholder="Ownership percentage"
          value={ownership || 0}
          onChange={handleOwnershipChange}
          converterFunction={converterFuntion}
          min={0}
          max={100}
        />
        <span>%</span>
      </td>
      <td className={statusClasses}>
        <NavLink to={entityProfileLink}>{status}</NavLink>
      </td>
      <td className={setSuffix('__issues')}>
        <Badge content={issues} red />
      </td>
      <td className={setSuffix('__remove')}>
        {canDelete && (
          <DeleteButton
            transparent
            onClick={ev => {
              ev.stopPropagation();
              setConfirmModalOpen(true);
            }}
          />
        )}
        {confirmModalOpen && (
          <FormModal
            hideModal={() => setConfirmModalOpen(false)}
            title="Remove Affiliated Entity"
            onSubmit={removeEntity}
          >
            <span className="ickyc-confirmation-message">
              Are you sure you want to unlink &nbsp; <h3>{name}</h3>
            </span>
          </FormModal>
        )}
      </td>
    </tr>
  );
};

AffiliatedEntityTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    entityId: PropTypes.string.isRequired,
    date: PropTypes.string,
    name: PropTypes.string,
    country: PropTypes.number,
    risk: PropTypes.number,
    assignedUserName: PropTypes.string,
    status: PropTypes.string,
    roles: PropTypes.string,
    issues: PropTypes.number,
    entityType: PropTypes.string.isRequired,
    ownership: PropTypes.number,
  }),
  reviewPrimaryContactsIssue: PropTypes.bool,
  className: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  onControlsClick: PropTypes.func,
  onUpdate: PropTypes.func,
};
AffiliatedEntityTableRow.defaultProps = {
  rowInfo: {
    date: undefined,
    name: '',
    country: PropTypes.number,
    risk: 0,
    assignedUserName: null,
    status: PropTypes.string,
    roles: PropTypes.string,
    issues: PropTypes.number,
    ownership: 0,
  },
  reviewPrimaryContactsIssue: false,
  onRemove: () => {},
  onControlsClick: () => {},
  onUpdate: () => {},
};

export default AffiliatedEntityTableRow;
