import api from 'api';
import classNames from 'classnames';
import IComplyForm from 'components/Form/IComplyForm';
import Tooltip from 'components/Tooltip';
import { FORM_ERROR } from 'final-form';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import toastr from 'toastr';
import enums from 'utilities/enums';
import { EntityContext, HistoryRecordContext, PermissionGroupContext } from 'utilities/services/contexts';
import IconManager from 'utilities/services/IconManager';
import BiometricDetailsFields from './BiometricDetailsFields';
import './index.scss';

const BiometricDetails = ({
  handleUpdate,
  details: { type, biometricConfidence, livenessConfidence, duration, imagesRetained },
}) => {
  const { eventHistoryId } = useContext(HistoryRecordContext);
  const { entityId } = useContext(EntityContext);

  const initValues = {
    biometricConfidence,
    livenessConfidence,
    duration,
    imagesRetained,
  };

  const [editMode, setEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState(initValues);
  const goToEditMode = useCallback(event => {
    event.stopPropagation();
    setEditMode(true);
  }, []);

  const onSaveChanges = async values => {
    return api.kyc.entityManagement.naturalPerson
      .updateBiometricDataDetails(eventHistoryId, entityId, values)
      .then(({ data: response }) => {
        toastr.success('Succesfully Updated Biometric Details');
        bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, response.comment);
        setInitialValues(response.documentDetails);
        handleUpdate(response.documentDetails);
        setEditMode(false);
      })
      .catch(err => {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            const { message } = data;
            return { [FORM_ERROR]: message };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };
  const onDiscardChanges = async () => {
    setEditMode(false);
    setInitialValues(initValues);
  };

  const classes = classNames({
    'ickyc-biometric-details': true,
    'ickyc-biometric-details--preview': !editMode,
  });
  const { edit: canEdit } = useContext(PermissionGroupContext);
  return (
    <div className={classes}>
      <h2>
        <span>Details</span>
        <Tooltip
          placement="top"
          trigger={
            <div>{canEdit && <span onClick={goToEditMode}>{IconManager.get(IconManager.names.PENCIL)}</span>}</div>
          }
          content={<span>Click to edit details.</span>}
        />
      </h2>
      {editMode ? (
        <IComplyForm
          onSubmit={onSaveChanges}
          onDiscardClick={onDiscardChanges}
          initialValues={initialValues}
          showControls={editMode}
        >
          <BiometricDetailsFields />
        </IComplyForm>
      ) : (
        <>
          <div>
            <b>Type</b>

            <span>{type}</span>
          </div>

          <div>
            <b>Average Biometric Confidence</b>

            {biometricConfidence && <span>{biometricConfidence}%</span>}
          </div>

          <div>
            <b>Average Liveness Confidence</b>

            {livenessConfidence && <span>{livenessConfidence}%</span>}
          </div>

          <div>
            <b>Duration</b>

            <span>{duration}s</span>
          </div>

          <div>
            <b>Images Retained</b>

            <span>{imagesRetained}</span>
          </div>
        </>
      )}
    </div>
  );
};

BiometricDetails.propTypes = {
  details: PropTypes.shape({
    type: PropTypes.string,
    biometricConfidence: PropTypes.string,
    livenessConfidence: PropTypes.string,
    duration: PropTypes.number,
    imagesRetained: PropTypes.number,
  }),
  handleUpdate: PropTypes.func.isRequired,
};

BiometricDetails.defaultProps = {
  details: {},
};

export default BiometricDetails;
