import React, { useContext, useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../components/Accordion';
import Checkbox from '../../../../../../../components/Checkbox';
import Section from '../../../../../../../components/Section';
import SelectContent from '../../../../../../../components/Select/components/SelectContent';
import SelectInputTrigger from '../../../../../../../components/Select/components/SelectInputTrigger';
import SelectField from '../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../components/ToggleField';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import { PermissionGroupContext } from '../../../../../../../utilities/services/contexts';
import './styles.scss';

const UserTypesPermissions = () => {
  const [platformSelectAll, setPlatformSelectAll] = useState(true);
  const [adminSelectAll, setAdminSelectAll] = useState(false);

  const {
    values: { platformUser, adminUser },
  } = useFormState();

  const { batch, change } = useForm();
  const { values } = useFormState();

  const onSelectAllPermissionsPlatformUser = () => {
    batch(() => {
      authEnums.PERMISSION_TAGS_OPTIONS.PLATFORM_USER.forEach(({ permissions, name }) => {
        change(name, !platformSelectAll ? permissions : []);
      });
    });
  };

  useEffect(() => {
    setPlatformSelectAll(
      authEnums.PERMISSION_TAGS_OPTIONS.PLATFORM_USER.map(({ name, permissions }) => {
        return permissions.every(perm => (values[name] || []).includes(perm));
      }).every(el => !!el),
    );
    setAdminSelectAll(
      authEnums.PERMISSION_TAGS_OPTIONS.ADMIN_USER.map(({ name, permissions }) => {
        return permissions.every(perm => (values[name] || []).includes(perm));
      }).every(el => !!el),
    );
  }, [values]);

  const onSelectAllPermissionsAdminUser = () => {
    batch(() => {
      authEnums.PERMISSION_TAGS_OPTIONS.ADMIN_USER.forEach(({ permissions, name }) => {
        change(name, !adminSelectAll ? permissions : []);
      });
    });
  };

  const { edit: canEdit = true } = useContext(PermissionGroupContext);

  return (
    <Section title="User Type + Permissions" className="ickyc-permission-section">
      <Accordion
        title={
          <>
            {canEdit && <Field component={ToggleField} name="platformUser" disabled={!canEdit} />}
            <h3>Platform User</h3>
          </>
        }
        accordionOpen={!!platformUser}
        accented
        controledOpen
      >
        <Checkbox
          checked={platformSelectAll}
          name="selectAllPlatformUser"
          type="checkbox"
          label="Select All Permissions"
          onChange={onSelectAllPermissionsPlatformUser}
        />
        {authEnums.PERMISSION_TAGS_OPTIONS.PLATFORM_USER.map(({ permissions, label, name }) => (
          <Field
            component={SelectField}
            name={name}
            label={label}
            preview={!canEdit}
            multipleSelection
            Trigger={<SelectInputTrigger />}
            Content={<SelectContent />}
            renderAsPortal
            relativeTo=".ickyc-accordion"
            options={permissions.map(p => ({ id: p, value: p, label: p }))}
          />
        ))}
      </Accordion>
      <Accordion
        title={
          <>
            {canEdit && <Field component={ToggleField} name="adminUser" disabled={!canEdit} />}
            <h3>Admin User</h3>
          </>
        }
        accordionOpen={!!adminUser}
        controledOpen
        accented
      >
        <Checkbox
          checked={adminSelectAll}
          name="selectAllAdminUser"
          type="checkbox"
          label="Select All Permissions"
          onChange={onSelectAllPermissionsAdminUser}
        />
        {authEnums.PERMISSION_TAGS_OPTIONS.ADMIN_USER.map(({ permissions, label, name }) => (
          <Field
            component={SelectField}
            multipleSelection
            name={name}
            label={label}
            preview={!canEdit}
            Trigger={<SelectInputTrigger />}
            Content={<SelectContent />}
            renderAsPortal
            relativeTo=".ickyc-accordion"
            options={permissions.map(p => ({ id: p, value: p, label: p }))}
          />
        ))}
        <div className="ickyc-permission-section__api-user-toggle">
          <span>API</span>
          <Field component={ToggleField} name="api" />
        </div>
      </Accordion>
    </Section>
  );
};

export default UserTypesPermissions;
