import api from 'api';
import useSelect from 'hooks/useSelect';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setNotifications } from 'store/actions/global.action';
import { selectNotifications } from 'store/selectors/global.selector';
import DateManager from 'utilities/services/DateManager';
import utils from '../../utils';
import './styles.scss';

const {
  ICON_BASED_ON_TYPE,
  NOTIFICATION_STATUSES,
  NOTIFICATION_TITLE_PLACEHOLDER_BASED_ON_TYPE,
  NOTIFICATION_TYPES,
  getPathBasedOnType,
  replacePlaceholders,
} = utils;

const Notification = ({
  items: { activityLogs, headerItemForwardPath, headerItemName },
  type,
  dateCreated,
  status,
  id,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: notifications, unreadNotificationsCount, ...rest } = useSelect(selectNotifications) || [];

  const readNotifications = useMemo(() => {
    const newNotifications = notifications.map(notification => {
      if (notification.id === id) {
        return { ...notification, status: NOTIFICATION_STATUSES.READ };
      }
      return notification;
    });

    return { unreadNotificationsCount: unreadNotificationsCount - 1, data: newNotifications, ...rest };
  }, [notifications, unreadNotificationsCount, rest, id]);

  const handleClick = useCallback(async () => {
    history.push(getPathBasedOnType(type, headerItemForwardPath));
    if (status === NOTIFICATION_STATUSES.UNREAD || status === NOTIFICATION_STATUSES.UNREAD_MANUALLY) {
      try {
        await api.notifications.updateNotificationStatus({ id, notificationStatus: NOTIFICATION_STATUSES.READ });
        dispatch(setNotifications(readNotifications));
      } catch (err) {
        console.error(err);
      }
    }
  }, [type, headerItemForwardPath, getPathBasedOnType, history, readNotifications]);

  return (
    <div className="notification" onClick={handleClick}>
      <div className="notification__header-box">
        <div className="notification__dot">{status === NOTIFICATION_STATUSES.UNREAD && <span />}</div>
        <div className="notification__header">
          {ICON_BASED_ON_TYPE[type]}
          <b className="notification__title">{NOTIFICATION_TITLE_PLACEHOLDER_BASED_ON_TYPE[type]}</b>
          <span>
            {type === NOTIFICATION_TYPES.CASE_ASSIGNED
              ? `${headerItemName} (ID:${headerItemForwardPath})`
              : headerItemName}
          </span>
          <span className="notification__date">{DateManager.formatRelativeTime(dateCreated)}</span>
        </div>
      </div>
      <div className="notification__info">
        <div className="notification__activity-items">
          {activityLogs.map(({ contentPlaceholder, id, ...rest }) => (
            <p key={id} className="notification__activity-item">
              {replacePlaceholders(contentPlaceholder, rest)}
            </p>
          ))}
        </div>
        <div className="notification__widget"></div>
      </div>
    </div>
  );
};
export default Notification;
