import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import setClassSuffix from '../../../../../../../../../../utilities/services/ClassManager';
import AddressForm from '../../../../../AddressSection/components/AddressForm';
import './styles.scss';

const ThirdPartyEditAddress = ({ editedFields }) => {
  const classBase = 'ickyc-third-party-edit-address';
  const setSuffix = setClassSuffix(classBase);

  const editedChecklist = useMemo(() => {
    const checkList = {
      addressTypeStatus: true,
      countryIdStatus: true,
      stateProvinceIdStatus: true,
      cityStatus: true,
      postalZipStatus: true,
      streetStatus: true,
      unitStatus: true,
    };

    editedFields.forEach(field => {
      checkList[field] = false;
    });

    return checkList;
  }, [editedFields]);

  return (
    <div className={setSuffix('__info')}>
      <AddressForm
        preview
        checkList={editedChecklist}
        namePreffix="addressInformation.thirdPartySuggestion.editedAddressEvent"
      />
    </div>
  );
};

ThirdPartyEditAddress.propTypes = {
  editedFields: PropTypes.object,
};
ThirdPartyEditAddress.defaultProps = {
  editedFields: {},
};

export default ThirdPartyEditAddress;
