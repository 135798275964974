import { ListItem, styled } from '@material-ui/core';

export const Row = styled(ListItem)(({ theme }) => ({
  width: '100%',
  padding: '5px 12px',
  paddingRight: '4px',
  borderRadius: '16px',
  boxSizing: 'border-box',

  '& p, button': {
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  '@media (max-width: 480px)': {
    '& p, button': {
      fontSize: '12px',
    },
  },
  '& span.Mui-checked': {
    color: theme.palette.secondary.main,
  },

  '&:nth-of-type(odd)': {
    backgroundColor: '#F5F5F5',
  },

  '&:nth-of-type(even)': {
    backgroundColor: '#fff',
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
  },

  '& span.MuiIcon-root': {
    color: theme.palette.text.primary,
  },
}));

export const ListCell = styled('div', {
  shouldForwardProp: prop => prop !== 'alias',
})(({ alias }) => {
  const styleObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px',
  };

  if (alias === 'name') {
    styleObject.flex = '3';
  }
  if (alias === 'lei-duns') {
    styleObject.flex = '3';
    styleObject.minWidth = '150px';
  }
  if (alias === 'address') {
    styleObject.flex = '4';
  }
  if (alias === 'country') {
    styleObject.flex = '2';
  }

  if (alias === 'checkbox') {
    styleObject.width = '32px';
    styleObject.height = '24px';
    styleObject.justifyContent = 'end';
  }

  return styleObject;
});

export const ListHeaderCell = styled(ListCell)({});

export const HeaderRow = styled('li')({
  display: 'flex',
  width: 'auto',
  height: '40px',
  padding: '0 12px',
  paddingRight: '4px',
});

export const CollapsibleRow = styled(Row)`
  background: ${props => (props.collapsed ? 'palevioletred' : 'white')};
  height: ${props => (props.collapsed ? '32px' : '0')};
  transition: height 300ms ease;
`;

export const ListWrapper = styled('ul')({
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  paddingInline: 0,

  maxHeight: '300px',
  margin: 'auto',
});
