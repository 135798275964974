import classNames from 'classnames';
import useModalHandler from 'hooks/useModalHandler';
import usePopupSetup from 'hooks/usePopupSetup';
import useVisibleInViewport from 'hooks/useVisibleInViewport';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { ModalContext } from 'utilities/services/contexts';
import './styles.scss';
import utils from './utils';

const { extractTabableElements } = utils;
const Modal = forwardRef(
  ({ children, renderAsPortal, relativeToParent, selectInModal, className, preventTabbing, onKeyDown }, ref) => {
    const modalRef = ref || useRef(null);
    const { isOpen: leaveModalOpen, open: openLeaveModal, close } = useModalHandler();
    const isVisible = useVisibleInViewport(modalRef);

    usePopupSetup(openLeaveModal, modalRef);

    const closeLeaveModal = useCallback(() => {
      close();
      modalRef.current.focus();
    }, [close]);

    // native DOM tabindex order of elements is different than required, code below changes order and limits focus inside modal
    const keyDownHandler = e => {
      if (onKeyDown) {
        return onKeyDown(e);
      }

      if (e.key === 'Escape') {
        openLeaveModal();
      }

      if (e.key === 'Tab') {
        if (preventTabbing) {
          return e.preventDefault();
        }
        const { firstElement, beforeCancelElement, cancelElement, lastElement } = extractTabableElements(modalRef);

        // Handle first tab
        if (modalRef.current === document.activeElement) {
          firstElement.focus();
          return e.preventDefault();
        }

        // Forward tabbing
        if (!e.shiftKey && document.activeElement === beforeCancelElement) {
          lastElement.focus();
          return e.preventDefault();
        }
        if (!e.shiftKey && document.activeElement === cancelElement) {
          firstElement.focus();
          return e.preventDefault();
        }
        if (!e.shiftKey && document.activeElement === lastElement) {
          cancelElement.focus();
          return e.preventDefault();
        }

        // Reverse tabbing
        if (e.shiftKey && document.activeElement === cancelElement) {
          lastElement.focus();
          return e.preventDefault();
        }
        if (e.shiftKey && document.activeElement === lastElement) {
          beforeCancelElement.focus();
          return e.preventDefault();
        }
        if (e.shiftKey && document.activeElement === firstElement) {
          cancelElement.focus();
          e.preventDefault();
        }
      }
    };

    const modalClasses = classNames(
      {
        'ickyc-modal': renderAsPortal,
        'ickyc-modal--absolute-to-parent': !renderAsPortal,
        'ickyc-modal--select': selectInModal,
      },
      className,
    );
    const renderModalContent = () => (
      <div className={modalClasses} ref={modalRef} tabIndex={-1} onKeyDown={keyDownHandler}>
        <ModalContext.Provider value={modalRef}>
          {React.cloneElement(children, {
            leaveModalOpen,
            closeLeaveModal,
            openLeaveModal,
          })}
        </ModalContext.Provider>
      </div>
    );

    useEffect(() => {
      isVisible && modalRef.current.focus();
    }, [isVisible]);

    return renderAsPortal
      ? ReactDOM.createPortal(renderModalContent(), relativeToParent ?? document.querySelector('#root'))
      : renderModalContent();
  },
);

Modal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  renderAsPortal: PropTypes.bool,
  relativeToParent: PropTypes.instanceOf(Element),
  element: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
Modal.defaultProps = {
  className: '',
  renderAsPortal: true,
  relativeToParent: undefined,
  element: null,
};

export default Modal;
