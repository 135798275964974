import React, { useState } from 'react';
import { DragContext } from '../../../../../../../utilities/services/contexts';
import Modules from './LegalEntityModules.component';

const LegalEntityModules = () => {
  const [dragList, setDragList] = useState([]);
  const [dropCallback, setDropCallback] = useState(() => reorderedList => {});
  const [isChildDragged, setIsChildDragged] = useState(false);

  return (
    <DragContext.Provider
      value={{ dragList, setDragList, dropCallback, setDropCallback, setIsChildDragged, isChildDragged }}
    >
      <Modules />
    </DragContext.Provider>
  );
};

export default LegalEntityModules;
