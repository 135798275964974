const CaseManagementTable = [
  {
    type: 'checkbox',
  },
  {
    type: 'ongoingAlert',
  },
  {
    type: 'created',
    label: 'Created',
    clickable: true,
  },
  {
    type: 'attachedTo',
    label: 'Attached To',
    clickable: true,
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'runby',
    label: 'Run By',
    clickable: true,
  },
  {
    type: 'ongoing',
    label: 'Ongoing',
    clickable: true,
  },
  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'potential',
    label: 'Potential',
    clickable: true,
  },
  {
    type: 'comments',
  },
];
const EntityRiskScreeningTable = [
  {
    type: 'name',
    label: 'Search Term',
    clickable: true,
  },
  {
    type: 'created',
    label: 'Created',
    clickable: true,
  },
  {
    type: 'ongoing',
    label: 'Ongoing',
    clickable: true,
  },
  {
    type: 'runby',
    label: 'Run By',
    clickable: true,
  },
  {
    type: 'potential',
    label: 'Potential',
    clickable: true,
  },
];
const EntityManagement = [
  {
    type: 'checkbox',
    label: '',
  },
  {
    type: 'lastUpdated',
    label: 'Last Update',
    clickable: true,
  },

  {
    type: 'type',
    label: 'Type',
    clickable: true,
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'countryName',
    label: 'Nationality / Formation Country',
    clickable: true,
  },
  {
    type: 'risk',
    label: 'Risk',
    clickable: true,
  },
  {
    type: 'assignedUserName',
    label: 'Assigned',
    clickable: true,
  },
  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'nextKYCReview',
    label: 'Next KYC Review',
    clickable: true,
  },
  {
    type: 'issues',
    label: 'Issues',
    clickable: true,
  },
  {
    type: 'tags',
    label: 'Tags',
    clickable: true,
  },
  {
    type: 'date',
    label: 'Date',
    clickable: true,
  },
];
const ExistingEntities = [
  {
    type: 'added',
    label: 'Added',
    clickable: true,
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'countryName',
    label: 'Country',
    clickable: true,
  },
  {
    type: 'email',
    label: 'Email',
    clickable: true,
  },
];

const AdministrationRiskScreening = [
  {
    label: 'Default',
    type: 'radio',
    clickable: false,
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'createdBy',
    label: 'Created by',
    clickable: true,
  },
];
const ExistingEntitiesShort = [
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'countryName',
    label: 'Country',
    clickable: true,
  },
];
const AffiliatedEntityTable = [
  {
    type: 'date',
    label: 'Date',
    clickable: true,
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'roles',
    label: 'Roles',
    clickable: true,
  },
  {
    type: 'countryName',
    label: 'Country',
    clickable: true,
  },
  {
    type: 'ownership',
    label: 'Ownership',
    clickable: true,
  },

  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'issues',
    label: 'Issues',
    clickable: true,
  },
  {
    type: 'remove',
    label: '',
    clickable: false,
  },
];

const CVSImportsTable = [
  {
    type: 'dateTime',
    label: 'Date',
    clickable: true,
  },
  {
    type: 'entityType',
    label: 'Entity Type',
    clickable: true,
  },
  {
    type: 'requestedBy',
    label: 'Requested By',
    clickable: true,
  },
  {
    type: 'records',
    label: 'Records',
    clickable: true,
  },
  {
    type: 'importedExported',
    label: 'Imported',
    clickable: true,
  },
  {
    type: 'download',
  },
];
const CVSExportsTable = [
  {
    type: 'dateTime',
    label: 'Date',
    clickable: true,
  },
  {
    type: 'entityType',
    label: 'Entity Type',
    clickable: true,
  },
  {
    type: 'requestedBy',
    label: 'Requested By',
    clickable: true,
  },
  {
    type: 'records',
    label: 'Records',
    clickable: true,
  },
  {
    type: 'importedExported',
    label: 'Exported',
    clickable: true,
  },
  {
    type: 'download',
  },
];

const AddressTable = [
  {
    type: 'collapse',
  },
  {
    type: 'addressTypes',
    label: '',
  },
  {
    type: 'address',
    label: 'Address',
    clickable: true,
  },
  {
    type: 'country',
    label: 'Country',
    clickable: true,
  },
  {
    type: 'assignedTo',
    label: 'Assigned To',
    clickable: true,
  },
  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'issues',
    label: 'Issues',
    clickable: true,
  },
];
const CaseMatchesTableHeader = [
  {
    type: 'checkbox',
  },
  {
    type: 'ongoingAlert',
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'type',
    label: 'Type',
    clickable: true,
  },
  {
    type: 'relevance',
    label: 'Relevance',
    clickable: true,
  },
  {
    type: 'countries',
    label: 'Countries',
  },
  {
    type: 'riskTypeCount',
    label: 'Risk Type',
    clickable: true,
  },
  {
    type: 'resultsFound',
    label: 'Results Found',
    clickable: true,
  },
  {
    type: 'comments',
  },
  {
    type: 'controls',
  },
];

const ListingsTableHeader = [
  { type: 'collapse' },
  {
    type: 'checkbox',
  },
  {
    type: 'ongoingAlert',
  },
  {
    type: 'riskType',
    label: 'Risk Type',
    clickable: true,
  },
  {
    type: 'updated',
    label: 'Updated',
    clickable: true,
  },
  {
    type: 'assignedTo',
    label: 'Assigned To',
    clickable: true,
  },
  {
    type: 'source',
    label: 'Source',
    clickable: true,
  },

  {
    type: 'controls',
  },
];
const ListingsTableHeaderWithHiddenControlls = [
  { type: 'collapse' },
  {
    type: 'checkbox',
  },
  {
    type: 'ongoingAlert',
  },
  {
    type: 'riskType',
    label: 'Risk Type',
    clickable: true,
  },
  {
    type: 'updated',
    label: 'Updated',
    clickable: true,
  },
  {
    type: 'assignedTo',
    label: 'Assigned To',
    clickable: true,
  },
  {
    type: 'source',
    label: 'Source',
    clickable: true,
  },
];

const AdverseMediaListingTableHeader = [
  {
    type: 'collapse',
  },
  {
    type: 'checkbox',
  },
  {
    type: 'ongoingAlert',
  },
  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'date',
    label: 'Created',
    clickable: true,
  },
  {
    type: 'assignedTo',
    label: 'Assigned To',
    clickable: true,
  },
  {
    type: 'source',
    label: 'Source',
  },

  {
    type: 'controls',
  },
];

const AffiliationsTableHeader = [
  {
    type: 'date',
    label: 'Date',
    clickable: true,
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'roles',
    label: 'Roles',
    clickable: true,
  },
  {
    type: 'countryName',
    label: 'Country',
    clickable: true,
  },
  {
    type: 'ownership',
    label: 'Ownership',
    clickable: true,
  },
  {
    type: 'issues',
    label: 'Issues',
    clickable: true,
  },
];
const MyWebHooksTableHeader = [
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'endpoint',
    label: 'Endpoint',
    clickable: true,
  },
  {
    type: 'requestUrl',
    label: 'Request URL',
    clickable: true,
  },
  {
    type: 'types',
    label: 'Type',
    clickable: true,
  },
  {
    type: 'dateCreated',
    label: 'Created',
    clickable: true,
  },
  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'actions',
    label: 'Actions',
  },
  {
    type: 'secret',
    label: '',
  },
];

const DocumentsTable = [
  { type: 'collapse' },
  {
    type: 'date',
    label: 'Date',
    clickable: true,
  },
  {
    type: 'type',
    label: 'Document Package',
    clickable: true,
  },
  {
    type: 'attached',
    label: 'Attached To',
    clickable: true,
  },
  {
    type: 'assignedTo',
    label: 'Assigned To',
    clickable: true,
  },
  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'issues',
    label: 'Issues',
    clickable: true,
  },
];

const UserManagmentTableHeader = [
  {
    type: 'verifiedTag',
    label: '',
    clickable: false,
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'type',
    label: 'Type',
    clickable: true,
  },
  {
    type: 'email',
    label: 'Email',
    clickable: true,
  },
  {
    type: 'tags',
    label: 'Tags',
    clickable: true,
  },
  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'lastLogin',
    label: 'Last Login',
    clickable: true,
  },
];

const ReportsTable = [
  {
    type: 'checkbox',
  },
  {
    type: 'date',
    label: 'Date',
    clickable: true,
  },
  {
    type: 'type',
    label: 'Report Type',
    clickable: true,
  },
  {
    type: 'requestedBy',
    label: 'Requested By',
    clickable: true,
  },
  {
    type: 'reportName',
    label: 'Report Name',
    clickable: true,
  },
  {
    type: 'status',
    label: 'Report Status',
    clickable: true,
  },
  {
    type: 'download',
  },
];

const ExistingCases = [
  {
    type: 'id',
    label: 'Id',
    clickable: true,
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
];

const BusinessManagerTableHeader = [
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'country',
    label: 'Country',
    clickable: true,
  },
  {
    type: 'dateCreated',
    label: 'Date Created',
    clickable: true,
  },
];

const UsersTableHeader = [
  {
    type: 'verifiedTag',
    label: '',
    clickable: false,
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'type',
    label: 'Type',
    clickable: true,
  },
  {
    type: 'email',
    label: 'Email',
    clickable: true,
  },
  {
    type: 'tags',
    label: 'Tags',
    clickable: true,
  },
  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'lastLogin',
    label: 'Last Login',
    clickable: true,
  },
  {
    type: 'invite',
    label: 'Invite',
  },
];

const LicenceManagmentTableHeader = [
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'lastModified',
    label: 'Last Modified',
    clickable: true,
  },
  {
    type: 'updatedBy',
    label: 'Updated By',
    clickable: true,
  },
  {
    type: 'tags',
    label: 'Tags',
    clickable: true,
  },
  {
    type: 'type',
    label: 'Type',
    clickable: true,
  },
];

const DocumentsTableWithService = [
  { type: 'collapse' },
  {
    type: 'date',
    label: 'Date',
    clickable: true,
  },
  {
    type: 'type',
    label: 'Document Type',
    clickable: true,
  },
  {
    type: 'source',
    label: 'Source',
    clickable: true,
  },
  {
    type: 'attached',
    label: 'Attached To',
    clickable: true,
  },
  {
    type: 'assignedTo',
    label: 'Assigned To',
    clickable: true,
  },
  {
    type: 'status',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'issues',
    label: 'Issues',
    clickable: true,
  },
];

const EmailTemplateTableHeader = [
  { type: 'portal', label: 'Portal', clickable: true },
  { type: 'emailSubject', label: 'Email Subject', clickable: true },
  { type: 'createdAt', label: 'Created At', clickable: true },
];

const BillingHistoryTableHeader = [
  {
    type: 'date',
    label: 'Date',
    clickable: true,
  },
  {
    type: 'description',
    label: 'Description',
    clickable: true,
  },
  {
    type: 'total',
    label: 'Total',
    clickable: true,
  },
  {
    type: 'invoice',
    label: 'Invoice',
    clickable: true,
  },
];

const ApiKeysTableHeader = [
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'secret',
    label: 'Key',
    clickable: true,
  },
  {
    type: 'dateCreated',
    label: 'Created',
    clickable: true,
  },
  {
    type: 'remove',
    label: '',
  },
];

const EmailTemplatesTableHeader = [
  {
    type: 'type',
    label: 'Template Name',
    clickable: true,
  },
  {
    type: 'lastModifiedAt',
    label: 'Last Modified Date',
    clickable: true,
  },
  {
    type: 'lastModifiedBy',
    label: 'Modified by',
    clickable: true,
  },
];

const PlatformUsage = [
  { type: 'item', label: 'Item' },
  { type: 'creditsUsed', label: 'Credits Used' },
];

const DocumentsAndCustomQuestionsTableHeader = [
  { type: 'order', label: 'Order' },
  { type: 'requiredToProceed', label: 'Required to Proceed' },
];

const DocumentsPackageTableHeader = [
  { type: 'collapse' },
  { type: 'index' },
  { type: 'type' },
  { type: 'name', label: 'Name', clickable: true },
  { type: 'submission', label: 'Submission' },
  { type: 'remove' },
];

const EntitiesAssignedToMeTableHeader = [
  {
    type: 'entityType',
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'caseStatus',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'issues',
    label: 'Issues',
    clickable: true,
  },
  {
    type: 'nextKYCReview',
    label: 'Next KYC Review',
    clickable: true,
  },
];

const CasesAssignedToMeTableHeader = [
  {
    type: 'entityType',
  },
  {
    type: 'id',
    label: 'ID',
    clickable: true,
  },
  {
    type: 'name',
    label: 'Name',
    clickable: true,
  },
  {
    type: 'caseStatus',
    label: 'Status',
    clickable: true,
  },
  {
    type: 'issues',
    label: 'Issues',
    clickable: true,
  },
];

export default {
  EmailTemplateTableHeader,
  CaseManagementTable,
  ExistingEntitiesShort,
  EntityManagement,
  ExistingEntities,
  AdministrationRiskScreening,
  AffiliatedEntityTable,
  EntityRiskScreeningTable,
  CVSImportsTable,
  CVSExportsTable,
  AddressTable,
  CaseMatchesTableHeader,
  ListingsTableHeader,
  ListingsTableHeaderWithHiddenControlls,
  AdverseMediaListingTableHeader,
  AffiliationsTableHeader,
  MyWebHooksTableHeader,
  DocumentsTable,
  UserManagmentTableHeader,
  ReportsTable,
  ExistingCases,
  BusinessManagerTableHeader,
  UsersTableHeader,
  LicenceManagmentTableHeader,
  DocumentsTableWithService,
  BillingHistoryTableHeader,
  EmailTemplatesTableHeader,
  PlatformUsage,
  ApiKeysTableHeader,
  DocumentsAndCustomQuestionsTableHeader,
  DocumentsPackageTableHeader,
  EntitiesAssignedToMeTableHeader,
  CasesAssignedToMeTableHeader,
};
