import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function() {
  const initialLocation = useLocation();

  const pathname = useMemo(() => {
    return initialLocation.pathname || initialLocation.location.pathname;
  }, [initialLocation]);

  return pathname;
}
