import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import toastr from 'toastr';
import IconManager from 'utilities/services/IconManager';
import api from '../../../../../../../api';
import CloseButton from '../../../../../../../components/Buttons/CloseButton';
import FormModal from '../../../../../../../components/Modal/FormModal';
import useModalHandler from '../../../../../../../hooks/useModalHandler';
import utilities from '../../../../../../../utilities';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../utilities/services/DateManager';

const ApiKeysRow = ({ rowInfo: { name, dateCreated, secret, id }, className, onRemove, clientId }) => {
  const { isOpen, open: openConfirmationModal, close: closeConfirmationModal } = useModalHandler();

  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const onDeleteConfirmation = useCallback(() => {
    return api.administration.apikeys
      .deleteApiKey(clientId, id)
      .then(() => {
        openConfirmationModal();
        onRemove('id', id);
        toastr.success(`${name} has been deleted`);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  }, [onRemove]);

  const onRemoveIconClick = useCallback(e => {
    e.preventDefault();
    openConfirmationModal();
  }, []);

  const onCopyIconCLick = () => {
    utilities.copyToClipboard(secret);
    toastr.success('Successfully copied API key!');
  };

  return (
    <tr key={id} className={`${className}__row`}>
      <td className={setColumnSuffix('__name')}>
        <span>{name}</span>
      </td>
      <td className={setColumnSuffix('__secret')}>
        <span>{secret}</span>
        <span className="ickyc-confirmation-message" onClick={onCopyIconCLick}>
          {IconManager.get(IconManager.names.COPY)}
        </span>
      </td>
      <td className={setColumnSuffix('__dateCreated')}>{DateManager.monDayYearLocal(dateCreated)}</td>
      <td className={setColumnSuffix('__remove')}>
        <CloseButton onClick={onRemoveIconClick} />
        <div>
          {isOpen && (
            <FormModal
              hideModal={closeConfirmationModal}
              title="Remove API Key"
              className="ickyc-confirmation-modal"
              onSubmit={onDeleteConfirmation}
            >
              <span className="ickyc-confirmation-message">
                Are you sure you want to delete &nbsp; <h3>{name}</h3>
              </span>
            </FormModal>
          )}
        </div>
      </td>
    </tr>
  );
};
ApiKeysRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    secret: PropTypes.string,
    types: PropTypes.string,
    dateCreated: PropTypes.string,
  }),
  className: PropTypes.string,
  onRemove: PropTypes.func,
  clientId: PropTypes.string,
};

ApiKeysRow.defaultProps = {
  className: '',
  rowInfo: {},
  onRemove: null,
  clientId: '',
};
export default ApiKeysRow;
