import DeleteButton from 'components/Buttons/DeleteButton';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import toastr from 'toastr';
import api from '../../../../../api';
import Badge from '../../../../../components/Badge';
import FormModal from '../../../../../components/Modal/FormModal';
import capitalSplit from '../../../../../utilities/services/CapitalSplitter';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../utilities/services/DateManager';
import {
  EntityContext,
  HistoryRecordContext,
  PermissionGroupContext,
} from '../../../../../utilities/services/contexts';
import AssignToUserDropdown from '../../../components/AssignToUserDropdown';

const HistoryRecordTableRow = ({ onRemove, className, isCorporateRow }) => {
  const { assignedUserId, date, source, type, status, eventHistoryId, issues, services } = useContext(
    HistoryRecordContext,
  );

  const setSuffix = setClassSuffix(`${className}__column`);
  const { entityId } = useContext(EntityContext);
  const [confirmationModal, setConfirmModalOpen] = useState(false);

  const onHistoryRecordDelete = useCallback(() => {
    return api.kyc.entityManagement
      .deleteHistoryRecordsSoft(entityId, eventHistoryId)
      .then(() => {
        setConfirmModalOpen(false);
        onRemove(eventHistoryId);
        toastr.success(`History Record has been removed`);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  }, [entityId, onRemove, eventHistoryId]);

  const onRemoveIconClick = useCallback(e => {
    e.stopPropagation();
    setConfirmModalOpen(true);
  }, []);
  const preventBubbling = event => {
    event.stopPropagation();
  };
  const { delete: canDelete, edit: canEdit } = useContext(PermissionGroupContext);

  return (
    <>
      <td className={setSuffix('__date')}>
        <span>{DateManager.getFullDateLocal(date)}</span>
      </td>
      {source ? (
        <td className={setSuffix('__source')}>
          <b>{capitalSplit(source)}</b>
        </td>
      ) : (
        <td className={setSuffix('__type')}>
          <b>{capitalSplit(type)}</b>
        </td>
      )}
      {!isCorporateRow && <td className={setSuffix('__services')}>{services.join(' | ')}</td>}

      <td className={setSuffix('__assignedTo')} onClick={preventBubbling}>
        <AssignToUserDropdown
          renderAsPortal
          eventId={eventHistoryId}
          assignEvent
          assignedUserId={assignedUserId}
          preview={!canEdit}
        />
      </td>
      <td className={setSuffix('__status')}>
        <span>{capitalSplit(status)}</span>
      </td>
      <td className={setSuffix('__issues')}>
        <Badge content={issues} showOnlyNumber={status === 'Rejected'} red />
      </td>
      {canDelete && (
        <td className={setSuffix('__remove')} onClick={preventBubbling}>
          <DeleteButton onClick={onRemoveIconClick} />
          {confirmationModal && (
            <FormModal
              hideModal={() => setConfirmModalOpen(false)}
              title="Remove Record"
              className="ickyc-confirmation-modal"
              onSubmit={onHistoryRecordDelete}
            >
              <span className="ickyc-confirmation-message">Are you sure you want to delete record ?</span>
            </FormModal>
          )}
        </td>
      )}
    </>
  );
};

HistoryRecordTableRow.propTypes = {
  className: PropTypes.string,
  onRemove: PropTypes.func,
  isCorporateRow: PropTypes.bool,
};

HistoryRecordTableRow.defaultProps = {
  className: '',
  onRemove: () => {},
  isCorporateRow: false,
};

export default HistoryRecordTableRow;
