import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import utilities from 'utilities';
import routesEnum from 'utilities/enums/routesEnum';
import Badge from '../../../../../../../../components/Badge';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';

const ThirdPartyHeader = ({ className, info, issuesCount, actionType }) => {
  const { name, role, id: entityId } = info;
  const setSuffix = setClassSuffix(className);

  const history = useHistory();
  const entityProfileLink = () => {
    if (!entityId) return;
    history.push(
      utilities.routeParams(routesEnum.kyc.NATURAL_PERSON, {
        id: entityId,
      }),
    );
  };

  return (
    <div className={setSuffix('__header')}>
      <div className={setSuffix('__header__left')}>
        <h3>Third Party Confirmation</h3>
        <span className={setSuffix('__status-info')}>
          {actionType} by {role} <strong onClick={entityProfileLink}>{name}</strong>
        </span>
      </div>
      <div className={setSuffix('__header__right')}>
        <Badge content={issuesCount} red />
      </div>
    </div>
  );
};

ThirdPartyHeader.propTypes = {
  className: PropTypes.string,
  info: PropTypes.object,
  issuesCount: PropTypes.number,
  actionType: PropTypes.string,
};
ThirdPartyHeader.defaultProps = {
  className: 'ickyc-third-party-confirm',
  info: {},
  issuesCount: 1,
  actionType: 'Confirmed',
};

export default ThirdPartyHeader;
