import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';

/**
 * Link styles as button component.
 * @param {string} path - Link component path
 * @param {func} onClick - onClick handler
 * @param {string} base - base for the header link buttons
 * @param {*} children - button content
 */

const LinkButton = ({ path, key, onClick, children }) => {
  const classes = classNames({
    'ickyc-nav-button': true,
  });

  return (
    <NavLink className={classes} key={key} to={path} activeClassName="ickyc-nav-button--active" onClick={onClick}>
      {children}
    </NavLink>
  );
};

LinkButton.propTypes = {
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  key: PropTypes.string,
};

LinkButton.defaultProps = {
  onClick: () => {},
  key: '',
};

export default LinkButton;
