import api from 'api';
import FormModal from 'components/Modal/FormModal';
import { FORM_ERROR } from 'final-form';
import useSelect from 'hooks/useSelect';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { selectPersonalId } from 'store/selectors/auth.selector';
import toastr from 'toastr';
import licenceEnums from 'utilities/enums/licenceEnums';
import LicenceManagementService from '../../../BusinessManager/components/BusinessProfile/components/BusinessLicenseSection/services/LicenceManagement.service';
import AddLicenceFields from './components/AddLicenceFields';
import './styles.scss';

const AddLicenceModal = ({ hideModal, updateLicence, initialLicenceValues, setNewlyAdded }) => {
  const baseClass = 'ickyc-add-licence-modal';
  const id = useSelect(selectPersonalId);

  const [licenceType, setLicenceType] = useState(initialLicenceValues?.type);

  const handleSubmit = async vals => {
    let submitObj;

    if (licenceType === licenceEnums.LICENCE_TYPES.WEBSITE) {
      submitObj = LicenceManagementService.normalizeWebsiteLicence(vals);
    } else if (licenceType === licenceEnums.LICENCE_TYPES.CUSTOM) {
      submitObj = LicenceManagementService.normalizeModalValues(vals);
      if (!submitObj?.administrationAccess?.supportingDocuments) {
        submitObj.defaultValues.supportingDocumentsLimit = 0;
      }
      if (!submitObj?.administrationAccess?.riskScreening) {
        submitObj.defaultValues.riskScreeningLimit = 0;
      }
    } else return;

    try {
      if (submitObj?.id) {
        const response = await api.businessManager.licences.updateLicence(submitObj?.id, submitObj);
        updateLicence(submitObj?.id, response?.data);
        toastr.success('License has been successfully updated');
        hideModal();
      } else {
        const data = await api.businessManager.licences.createLicence(id, submitObj);
        setNewlyAdded(data?.data);
        toastr.success('License has been successfully added');
        hideModal();
      }
    } catch (err) {
      if (err?.response) {
        const { status, data: errData } = err.response;
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: errData.message || errData.errors };
        }
        if (status === 500) {
          return {
            [FORM_ERROR]: Array.isArray(errData.message)
              ? errData.message.join('')
              : errData.message || 'Internal Server Error, Try Again Later',
          };
        }
      } else return { [FORM_ERROR]: 'Error occured' };
    }
  };

  return (
    <FormModal
      showErrorsInConfirmation
      submitButtonText="Save"
      onSubmit={handleSubmit}
      initialValues={initialLicenceValues}
      hideModal={hideModal}
      title={initialLicenceValues?.id ? 'Edit License' : 'Add License'}
      className={baseClass}
    >
      <AddLicenceFields
        licenceType={licenceType}
        setLicenceType={setLicenceType}
        initialLicenceValues={initialLicenceValues}
      />
    </FormModal>
  );
};
AddLicenceModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  initialLicenceValues: PropTypes.shape({ id: PropTypes.string }),
  setNewlyAdded: PropTypes.func.isRequired,
  updateLicence: PropTypes.func,
};
AddLicenceModal.defaultProps = {
  initialLicenceValues: {},
  updateLicence: () => {},
};
export default AddLicenceModal;
