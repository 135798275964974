import React from 'react';
import CurrencyField from '../components/CurrencyField';
import DiscreteChoice from '../components/DiscreteChoice';
import MultipleChoice from '../components/MultipleChoice';
import Numerical from '../components/Numerical';
import Text from '../components/Text';

const answerFormatComponent = {
  DiscreteChoice: <DiscreteChoice />,
  TextField: <Text />,
  CurrencyField: <CurrencyField />,
  NumericalField: <Numerical />,
  MultipleChoice: <MultipleChoice />,
  PercentageField: <Numerical customLabel="Decimal Places" fieldName="parameters.decimalPlaces" />,
};

export default { answerFormatComponent };
