import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../../api';
import Accordion from '../../../../../../../components/Accordion';
import CheckboxTable from '../../../../../../../components/Tables/CheckboxTable';
import TableHeader from '../../../../../../../components/Tables/Table/components/TableHeader';
import useSelect from '../../../../../../../hooks/useSelect';
import bus from '../../../../../../../modules/bus';
import { selectUserAccessByLicense } from '../../../../../../../store/selectors/auth.selector';
import enums from '../../../../../../../utilities/enums';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import { CaseContext } from '../../../../../../../utilities/services/contexts';
import TableHeaders from '../../../../../../../utilities/services/TableHeaders';
import MatchesControls from '../../../Matches/components/MatchesControls';
import AdverseMediaTableRow from './components/AdverseMediaTableRow';
import './styles.scss';

const AdverseMediaListings = ({ complianceLogId, hitId, onHitStatusUpdate, selectedStatus, shouldOpen, count }) => {
  const [params, setParams] = useState({ sortOrder: 0 });
  const [pagination, setPagination] = useState({});

  const [listings, setListings] = useState([]);

  const [isloading, setIsLoading] = useState(false);
  const { isCaseClosed } = useContext(CaseContext);

  useEffect(() => {
    const fetchHitListings = async () => {
      setIsLoading(true);
      try {
        const {
          data: { adverseMedia, counts },
        } = await api.kyc.caseManagement.getAdverseMediaHitsTAble({
          hitId,
          complianceLogId,
          params: { ...params, status: selectedStatus },
        });
        setListings(adverseMedia?.data);
        onHitStatusUpdate(prev => ({
          ...prev,
          adverseMediaCounter: counts?.adverseMediaCount,
        }));
        setPagination(adverseMedia?.paginationData);
      } finally {
        setIsLoading(false);
      }
    };
    fetchHitListings();
  }, [complianceLogId, hitId, params, selectedStatus]);

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  const updateSelectedHitsStatus = async (newStatus, selectedListings) => {
    setIsLoading(true);
    try {
      const response = await api.kyc.caseManagement.updateHitStatus({
        complianceLogId,
        hitId,
        status: newStatus,
        ids: selectedListings,
      });
      setListings(prev => prev.filter(match => !selectedListings.some(m => m === match.id)));
      onHitStatusUpdate(prev => ({
        ...prev,
        [`${selectedStatus}Counter`]: prev[`${selectedStatus}Counter`] - selectedListings.length,
        [`${newStatus}Counter`]: prev[`${newStatus}Counter`] + selectedListings.length,
      }));

      bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, response.data);
      setParams(prev => {
        const limitDiff = prev.limit - selectedListings.length;
        return {
          total: prev.total - selectedListings.length,
          limit: !limitDiff ? prev.limit : limitDiff,
        };
      });
    } finally {
      setIsLoading(false);
    }
  };
  const platformAccessByLicence = useSelect(selectUserAccessByLicense);
  const tableHeaderItems = useMemo(() => {
    let items = TableHeaders.AdverseMediaListingTableHeader;
    if (!platformAccessByLicence.some(el => el === authEnums.ACCESS_BY_LICENCE.ENTITIES)) {
      items = items.filter(item => item.type !== 'assignedTo');
    }
    return items;
  }, [platformAccessByLicence]);

  return (
    <Accordion
      title={<h3>Adverse Media</h3>}
      accented
      withBadge
      badgeCount={count}
      accordionOpen={shouldOpen}
      className="ickyc-adverse-media-table"
      accordionindex={enums.ACCORDION_INDEXES.ADVERSE_LISTINGS}
    >
      <CheckboxTable
        values={listings}
        pagination={pagination}
        tableRow={AdverseMediaTableRow}
        headerRow={TableHeader}
        withPagination
        withLimitChange
        handleParamsChange={changeParams}
        TableControlsComponent={MatchesControls}
        onControls={updateSelectedHitsStatus}
        updating={isloading}
        disabled={isCaseClosed}
        headerData={{
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          onClick: changeParams,
          items: tableHeaderItems,
        }}
      />
    </Accordion>
  );
};

AdverseMediaListings.propTypes = {
  complianceLogId: PropTypes.string.isRequired,
  hitId: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  onHitStatusUpdate: PropTypes.func,
  shouldOpen: PropTypes.bool,
  count: PropTypes.number,
};
AdverseMediaListings.defaultProps = {
  onHitStatusUpdate: () => {},
  shouldOpen: false,
  count: 0,
};
export default AdverseMediaListings;
