import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import { uuid } from 'uuidv4';
import SelectFilterContent from '../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../components/SelectField';
import useSelect from '../../../../hooks/useSelect';
import { selectPersonalInfo } from '../../../../store/selectors/auth.selector';

const AutoAssignSelect = ({ modulePreffix, propertyName }) => {
  const users = useSelector(state => state.global.usersToAssign);
  const [options, setOptions] = useState();
  const [key, setKey] = useState(uuid());
  const {
    input: { value: selectdUserId },
  } = useField(`${modulePreffix}.${propertyName}`);

  const { id, firstName, lastName } = useSelect(selectPersonalInfo) || {};

  useEffect(() => {
    if (selectdUserId === id) {
      setOptions([
        { label: `${firstName} ${lastName}`, value: id, id },
        ...users.map(({ id: uId, name }) => ({
          label: name,
          value: uId,
          id: uId,
        })),
      ]);
      setKey(uuid());
    } else {
      setOptions([
        { label: 'Current User', value: id, id },
        ...users.map(({ id: uId, name }) => ({
          label: name,
          value: uId,
          id: uId,
        })),
      ]);
      setKey(uuid());
    }
  }, [selectdUserId]);

  return (
    <Field
      key={key}
      component={SelectField}
      name={`${modulePreffix}.${propertyName}`}
      options={options}
      renderAsPortal
      Trigger={<SelectTrigger placeholder="Choose Assigned To" />}
      Content={<SelectFilterContent />}
    />
  );
};

AutoAssignSelect.propTypes = {
  modulePreffix: PropTypes.string,
  propertyName: PropTypes.string,
};

AutoAssignSelect.defaultProps = {
  modulePreffix: '',
  propertyName: 'autoAssignedUserId',
};

export default React.memo(AutoAssignSelect);
