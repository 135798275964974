import Tooltip from 'components/Tooltip';
import useMatchMedia from 'hooks/useMatchMedia';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField } from 'react-final-form';
import PrimaryButton from '../../../../../../../../../components/Buttons/PrimaryButton';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import Table from '../../../../../../../../../components/Tables/Table';
import useEffectSkipFirst from '../../../../../../../../../hooks/useEffectSkipFirst';
import utilities from '../../../../../../../../../utilities';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../../../utilities/services/DateManager';
import IconManager from '../../../../../../../../../utilities/services/IconManager';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import BillingInfoRow from '../BillingInfoRow';

const statusOptions = [
  {
    id: '0',
    value: 'false',
    label: 'Pending',
  },
  {
    id: '1',
    value: 'true',
    label: 'Paid',
  },
];
const BillingTableRow = ({
  rowInfo: { dateCreated, saas, kycPlan, consumption, overage, overageRate, balance, monthLicenceStatistics, id },
  className,
  onClick,
}) => {
  const isBelowLaptopLarge = useMatchMedia('isBelowLaptopLarge');
  const setSufix = setClassSuffix(`${className}__column`);
  const {
    input: { value: paidOrNotValue },
  } = useField(`isPaid${id}`);

  useEffectSkipFirst(() => {
    onClick(id, paidOrNotValue);
  }, [paidOrNotValue]);

  return (
    <CollapsibleTableRow
      className={className}
      row={
        <>
          <td className={setSufix('__month')}>
            <span>{dateCreated ? DateManager.monYear(dateCreated) : ''}</span>
          </td>
          <td className={setSufix('__saas')}>
            <span>$ {utilities.formatDecimalByIndianLocalization(saas)}</span>
          </td>
          <td className={setSufix('__kycPlan')}>
            <span>${utilities.formatDecimalByIndianLocalization(kycPlan)} </span>
          </td>
          <td className={setSufix('__consumption')}>
            <span>${utilities.formatDecimalByIndianLocalization(consumption)} </span>
          </td>
          <td className={setSufix('__overage')}>
            <span>${utilities.formatDecimalByIndianLocalization(overage)}</span>
          </td>
          <td className={setSufix('__overageRate')}>
            <span>{overageRate}%</span>
          </td>
          <td className={setSufix('__balance')}>
            <span>${utilities.formatDecimalByIndianLocalization(balance)} </span>
          </td>
          <td className={setSufix('__status')} onClick={e => e.stopPropagation()}>
            <Field
              name={`isPaid${id}`}
              options={statusOptions}
              component={SelectField}
              Trigger={<SelectTrigger placeholder="Choose" />}
              Content={<SelectFilterContent />}
              renderAsPortal
            />
          </td>
          <td className={setSufix('__downloadInvoice')}>
            <span>
              <PrimaryButton>
                <Tooltip
                  trigger={<span>{IconManager.get(IconManager.names.DOWNLOAD)}</span>}
                  content="Download Invoice"
                />
              </PrimaryButton>
            </span>
          </td>
        </>
      }
    >
      <div className={`${className}__billing_info`}>
        <Table
          values={monthLicenceStatistics}
          tableRow={BillingInfoRow}
          headerData={{
            items: [
              { type: 'name' },
              { type: 'saas' },
              { type: 'kycPlan' },
              { type: 'consumption' },
              { type: 'overage' },
              { type: 'overageRate' },
              { type: 'balance' },
              { type: 'status' },
              { type: 'downloadInvoice' },
            ],
          }}
          noResultMessage="No Licences To Show"
        />
      </div>
    </CollapsibleTableRow>
  );
};
BillingTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    dateCreated: PropTypes.string,
    id: PropTypes.number,
    saas: PropTypes.number,
    kycPlan: PropTypes.number,
    consumption: PropTypes.number,
    overage: PropTypes.number,
    overageRate: PropTypes.number,
    balance: PropTypes.number,
    monthLicenceStatistics: PropTypes.array,
    isPaid: PropTypes.bool,
  }),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

BillingTableRow.defaultProps = {
  className: '',
  rowInfo: {
    id: 0,
    dateCreated: '',
    saas: 0,
    kycPlan: 0,
    consumption: 0,
    overage: 0,
    overageRate: 0,
    balance: 0,
    isPaid: false,
    monthLicenceStatistics: [],
  },
  onClick: () => {},
};
export default BillingTableRow;
