import React, { useContext } from 'react';
import { Field } from 'react-final-form';
import SelectFilterContentToggleList from '../../../../../../../components/Select/components/SelectFilterContentToggleList';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import { UserManagementContext } from '../../../../../../../utilities/services/contexts';
import DateFromUntilPicker from '../../../../../../Kyc/components/DateFromUntilPicker';
import FilterItem from '../../../../../../Kyc/components/FilterItem';

const UserManagementFilterForm = () => {
  const { iComplyTeam } = useContext(UserManagementContext);
  return (
    <div className="ickyc-filter-form">
      <Field component={FilterItem} label="Date" name="dateFlag">
        <DateFromUntilPicker dateFromName="dateFrom" dateUntilName="dateUntil" />
      </Field>
      <Field component={FilterItem} label="Type" name="userTypesFlag">
        <Field
          component={SelectField}
          placeholder="- Choose User Types -"
          withFilter
          multipleSelection
          name="userTypes"
          options={iComplyTeam ? authEnums.ICOMPLY_USERS_TYPES_DROPDOWN_VALUES : authEnums.USER_TYPES_DROPDOWN_VALUES}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
      <Field component={FilterItem} label="Status" name="userStatusesFlag">
        <Field
          component={SelectField}
          placeholder="- Choose Status -"
          withFilter
          multipleSelection
          name="userStatuses"
          options={authEnums.USER_STATUS}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
      <Field component={FilterItem} label="Tags" name="tagsOfUser">
        <Field
          component={SelectField}
          placeholder="- Choose Tags -"
          withFilter
          multipleSelection
          name="tags"
          Trigger={<SelectTrigger />}
          options={authEnums.TAGS}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
    </div>
  );
};
export default UserManagementFilterForm;
