import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';
import utils from './utils';

const CustomQuestionOptions = ({ answerFormat }) => {
  return answerFormat ? utils.answerFormatComponent[answerFormat] : <div>Please select answer format</div>;
};

CustomQuestionOptions.propTypes = {
  answerFormat: PropTypes.string,
};

CustomQuestionOptions.defaultProps = {
  answerFormat: '',
};

export default CustomQuestionOptions;
