import FormModal from 'components/Modal/FormModal';
import React from 'react';
const RevertRiskLevelModal = ({ closeModal, handleSubmit }) => {
  return (
    <FormModal
      hideModal={closeModal}
      title="Override Risk Level"
      className="ickyc-confirmation-modal"
      onSubmit={handleSubmit}
      submitButtonText="Confirm"
    >
      <span className="ickyc-confirmation-message">
        You are about to revert manual changes. Would you like to proceed?
      </span>
    </FormModal>
  );
};
export default RevertRiskLevelModal;
