import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toastr from 'toastr';
import api from '../../../../api';
import DeleteButton from '../../../../components/Buttons/DeleteButton';
import NestedListHeader from '../../../../components/Headings/NestedListHeading';
import ConfirmationPage from '../../../../components/Modal/ConfirmationPage';
import FormModal from '../../../../components/Modal/FormModal';
import InformationModal from '../../../../components/Modal/InformationModal';
import Spinner from '../../../../components/Spinner';
import useModalHandler from '../../../../hooks/useModalHandler';
import bus from '../../../../modules/bus';
import ProtectedComponent from '../../../../router/components/ProtectedComponent';
import enums from '../../../../utilities/enums';
import authEnums from '../../../../utilities/enums/authEnums';
import routesEnum from '../../../../utilities/enums/routesEnum';
import DateManager from '../../../../utilities/services/DateManager';
import IconManager from '../../../../utilities/services/IconManager';
import { EntityContext } from '../../../../utilities/services/contexts';
import CommentsSection from '../../components/CommentsSection';
import AffiliationSection from '../components/AffiliationSection';
import DocumentsSection from '../components/DocumentsSection';
import GeneralEntityInformation from '../components/GeneralEntityInformation';
import RiskScreeningSection from '../components/RiskScreeningSection';
import BiometricAuthenticationSection from './components/BiometricsAuthenticationSection';
import IdentityDocumentsSection from './components/IdentityDocumentsSection';
import PersonalInformationSection from './components/PersonalInformationSection';
import './styles.scss';

function NaturalPersonProfile({ className }) {
  const { id: npeId } = useParams();
  const history = useHistory();
  const pageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [entityInfo, setEntityInfo] = useState({});
  const [generalEntityInfo, setGeneralEntityInfo] = useState({});
  const [shouldOpenComModal, setShouldOpenComModal] = useState(false);
  const [isGlobalErrorModalOpen, setIsGlobalErrorModalOpen] = useState(false);
  const [issues, setIssues] = useState({});
  const [entityRiskScreeningOptions, setEntityRiskScreeningOptions] = useState(null);
  const [reloadInformationFlag, setReloadInformationFlag] = useState();

  useEffect(() => {
    const extractGeneralEntityInformation = data => ({
      name: `${data.firstName} ${data.lastName}`,
      countryId: data.nationality,
      assignedUserName: data.assignedUser,
      status: data.status,
      riskLevel: data.riskLevel,
      amlIssues: data.amlIssues,
      kycIssues: data.kycIssues,
      assignedUserId: data.assignedUserId,
      nextReview: data.nextReview,
      riskScore: data.score,
      riskLevelCalculationMethod: data.riskLevelCalculationMethod,
      riskLevelEnabled: data.riskLevelEnabled,
    });

    const replaceNull = value => value || '';
    const extractPersonalInfo = data => ({
      firstName: replaceNull(data.firstName),
      middleName: replaceNull(data.middleName),
      lastName: replaceNull(data.lastName),
      birthDate: DateManager.toDate(data.birthDate),
      phone: replaceNull(data.phone),
      email: replaceNull(data.email),
      nationality: replaceNull(data.nationality),
      residence: replaceNull(data.residence),
      unit: replaceNull(data.unit),
      streetAddress: replaceNull(data.streetAddress),
      postalZip: replaceNull(data.postalZip),
      city: replaceNull(data.city),
      stateProvince: replaceNull(data.stateProvince),
      additionalDetails: replaceNull(data.additionalDetails),
      association: replaceNull(data.association),
      registrationNumber: replaceNull(data.registrationNumber),
    });

    const extractIssues = data => ({
      personal: {
        value: data.personalInformationIssuesCount,
        index: enums.ACCORDION_INDEXES.PERSONAL_INFORMATION,
        shouldFocus: false,
        isAllRejected: data.allPersonalEventsRejected,
      },
      identity: {
        value: data.identityDocumentsIssuesCount,
        index: enums.ACCORDION_INDEXES.IDENTITY_DOCUMENTS,
        shouldFocus: false,
        isAllRejected: data.allIdentityDocumentsRejected,
      },
      biometric: {
        value: data.faceMatchingIssuesCount,
        index: enums.ACCORDION_INDEXES.BIOMETRIC_AUTHENTICATIONS,
        shouldFocus: false,
        isAllRejected: data.allFaceMatchsRejected,
      },
      documents: {
        value: data.documentsIssuesCount,
        index: enums.ACCORDION_INDEXES.DOCUMENTS,
        shouldFocus: false,
        isAllRejected: data.allDocumentsRejected,
      },
      riskScreening: { value: data.amlIssuesCount, index: enums.ACCORDION_INDEXES.RISK_SCREENING, shouldFocus: false },
    });

    const fetchInfo = async () => {
      setIsLoading(true);
      try {
        // todo: check if these entties already exist

        const personalInfoResponse = await api.kyc.entityManagement.naturalPerson.getNaturalPersonProfile(npeId);
        const {
          data: { personalInformationEvents, defaultScreeningInfo, ...rest },
        } = personalInfoResponse;
        setEntityRiskScreeningOptions(defaultScreeningInfo);
        setIssues(extractIssues(rest));
        setGeneralEntityInfo(extractGeneralEntityInformation(rest));
        setEntityInfo(extractPersonalInfo(rest));
      } catch (error) {
        const { response } = error;
        if (response) {
          const {
            data: { message },
            status,
          } = response;

          if (message?.includes(enums.API_ERROR_TYPES.NATURAL_PERSON_NOT_FOUND) || status === 404) {
            setIsGlobalErrorModalOpen(true);
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchInfo();
  }, [npeId, pageRef, reloadInformationFlag]);

  useEffect(() => {
    const handleUpdate = newData => {
      setGeneralEntityInfo(prev => ({ ...prev, ...newData }));
    };
    bus.addEventListener(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, handleUpdate);
    return () => {
      return bus.removeEventListener(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, handleUpdate);
    };
  }, []);

  useEffect(() => {
    const handleUpdate = newData => {
      const { type, issuesChange, section, allRejected } = newData;

      if (type === 'kyc') {
        setGeneralEntityInfo(prev => ({ ...prev, kycIssues: Number(prev?.kycIssues) + issuesChange }));
      }
      if (type === 'aml') {
        setGeneralEntityInfo(prev => ({ ...prev, amlIssues: prev.amlIssues + issuesChange }));
      }
      if (section === enums.ACCORDION_INDEXES.PERSONAL_INFORMATION) {
        setIssues(prev => ({
          ...prev,
          personal: {
            ...prev.personal,
            value: prev.personal.value + issuesChange,
            isAllRejected: allRejected,
          },
        }));
      }
      if (section === enums.ACCORDION_INDEXES.IDENTITY_DOCUMENTS) {
        setIssues(prev => ({
          ...prev,
          identity: {
            ...prev.identity,
            value: prev.identity.value + issuesChange,
            isAllRejected: allRejected,
          },
        }));
      }
      if (section === enums.ACCORDION_INDEXES.BIOMETRIC_AUTHENTICATIONS) {
        setIssues(prev => ({
          ...prev,
          biometric: {
            ...prev.biometric,
            value: prev.biometric.value + issuesChange,
            isAllRejected: allRejected,
          },
        }));
      }
      if (section === enums.ACCORDION_INDEXES.DOCUMENTS) {
        setIssues(prev => ({
          ...prev,
          documents: {
            ...prev.documents,
            value: prev.documents.value + issuesChange,
            isAllRejected: allRejected,
          },
        }));
      }
      if (section === enums.ACCORDION_INDEXES.RISK_SCREENING) {
        setIssues(prev => ({
          ...prev,
          riskScreening: {
            ...prev.riskScreening,
            value: prev.riskScreening.value + issuesChange,
          },
        }));
      }
    };
    bus.addEventListener(enums.BUS_EVENTS.UPDATE_ISSUES, handleUpdate);
    return () => {
      return bus.removeEventListener(enums.BUS_EVENTS.UPDATE_ISSUES, handleUpdate);
    };
  }, []);

  const classes = classNames('ickyc-kyc-page', 'ickyc-natural-profile', className);

  const goHistoryBack = useCallback(() => history.goBack(), [history]);

  const headingListItems = useMemo(() => {
    return [
      <>
        <b>{generalEntityInfo.name}</b>
      </>,
    ];
  }, [generalEntityInfo]);

  const openSectionsWithIssues = () => {
    const issuesInfo = Object.values(issues).filter(el => el.value > 0);
    if (issuesInfo.length > 0) {
      issuesInfo[0] = { ...issuesInfo[0], shouldFocus: true };
      bus.broadcastEvent(enums.BUS_EVENTS.EXPAND_SECTIONS, issuesInfo);
    }
  };

  const focusComments = () => {
    bus.broadcastEvent(enums.BUS_EVENTS.EXPAND_SECTIONS, [
      {
        index: enums.ACCORDION_INDEXES.COMMENTS,
        shouldFocus: true,
      },
    ]);
  };

  const handleConfirmationPageClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const { isOpen: isConfirmModalOpen, close: closeConfirmModal, open: openConfirmModal } = useModalHandler();

  const handleEntittyDeletion = useCallback(() => {
    return api.kyc.entityManagement
      .deleteEntitySoft(npeId, 0)
      .then(() => {
        toastr.success(`${entityInfo.firstName} ${entityInfo.lastName} has been deleted`);

        closeConfirmModal();
        history.push(routesEnum.kyc.ENTITY_MANAGEMENT);
      })
      .catch(err => {
        console.error(err);
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  }, [history, entityInfo.firstName, entityInfo.lastName, closeConfirmModal, npeId]);

  const resetAllIssues = () => {
    setIssues({
      personal: {
        value: 0,
        index: enums.ACCORDION_INDEXES.PERSONAL_INFORMATION,
        shouldFocus: false,
      },
      identity: {
        value: 0,
        index: enums.ACCORDION_INDEXES.IDENTITY_DOCUMENTS,
        shouldFocus: false,
      },
      biometric: {
        value: 0,
        index: enums.ACCORDION_INDEXES.BIOMETRIC_AUTHENTICATIONS,
        shouldFocus: false,
      },
      documents: { value: 0, index: enums.ACCORDION_INDEXES.DOCUMENTS, shouldFocus: false },
      riskScreening: { value: 0, index: enums.ACCORDION_INDEXES.RISK_SCREENING, shouldFocus: false },
    });
  };

  if (isLoading) {
    return (
      <div className={classes} ref={pageRef}>
        <Spinner />
      </div>
    );
  }
  return (
    <EntityContext.Provider
      value={{
        entityId: npeId,
        isNaturalPerson: true,
        email: entityInfo.email,
        entityName: `${entityInfo.firstName} ${entityInfo.lastName}`,
        updateEntityInfo: setEntityInfo,
        issues,
        reloadEntityInformation: flag => setReloadInformationFlag(flag),
        riskLevelEnabled: generalEntityInfo.riskLevelEnabled,
        riskLevel: generalEntityInfo.riskLevel,
      }}
    >
      <div className={classes} ref={pageRef}>
        {isGlobalErrorModalOpen ? (
          <InformationModal hideModal={() => setIsGlobalErrorModalOpen(false)} onClick={handleConfirmationPageClick}>
            <ConfirmationPage
              title="Oops!"
              subtitle="Something went wrong…"
              text={
                <>
                  We’re working on it, and we’ll get it fixed as soon as we can.
                  <br /> You may refresh the page, or try again later.
                </>
              }
              icons={<div>{IconManager.get(IconManager.names.GLOBAL_ERROR)}</div>}
            />
          </InformationModal>
        ) : (
          <>
            <NestedListHeader
              title="Entities"
              onClick={goHistoryBack}
              items={headingListItems}
              right={<DeleteButton onClick={openConfirmModal} tooltip="Delete Entity" />}
            />

            {isConfirmModalOpen && (
              <FormModal
                hideModal={closeConfirmModal}
                title="Delete Entity"
                className="ickyc-confirmation-modal"
                onSubmit={handleEntittyDeletion}
              >
                <span className="ickyc-confirmation-message">
                  Are you sure you want to delete &nbsp;
                  <b>
                    {entityInfo.firstName} {entityInfo.lastName}
                  </b>
                  ?
                </span>
              </FormModal>
            )}
            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.PERSONAL_INFORMATION}
            >
              <GeneralEntityInformation
                info={generalEntityInfo}
                openModal={() => setShouldOpenComModal(true)}
                focusComments={focusComments}
                openSections={openSectionsWithIssues}
              />
            </ProtectedComponent>
            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.PERSONAL_INFORMATION}
            >
              <PersonalInformationSection
                data={entityInfo}
                badge={{ count: issues.personal?.value, isAllRejected: issues.personal?.isAllRejected }}
              />
            </ProtectedComponent>

            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.IDENTITY_DOCUMENTS}
            >
              <IdentityDocumentsSection
                badge={{ count: issues.identity?.value, isAllRejected: issues.identity?.isAllRejected }}
              />
            </ProtectedComponent>

            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.BIOMETRIC_AUTHENTICATION}
            >
              <BiometricAuthenticationSection
                badge={{ count: issues.biometric?.value, isAllRejected: issues.biometric?.isAllRejected }}
              />
            </ProtectedComponent>

            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.DOCUMENTS}
              licenceAccessKey={authEnums.ACCESS_BY_LICENCE.SUPPORTING_DOCUMENTS}
            >
              <DocumentsSection
                badge={{ count: issues.documents?.value, isAllRejected: issues.documents?.isAllRejected }}
              />
            </ProtectedComponent>

            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.amlSearchComponent]}
              permissionGroup={authEnums.PERMISSION_GROUP.RISK_SCREENING}
            >
              <RiskScreeningSection
                badge={{ count: issues.riskScreening?.value }}
                riskScreeningOptions={{
                  ...entityRiskScreeningOptions,
                  searchTerms: [
                    {
                      name: `${entityInfo?.firstName} ${entityInfo?.lastName}`,
                      yearOfBirth: new Date(DateManager.toDate(entityInfo?.birthDate)).getFullYear(),
                      countries: entityInfo?.nationality ? [entityInfo?.nationality] : null,
                    },
                  ],
                }}
              />
            </ProtectedComponent>

            <ProtectedComponent
              requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.view]}
              permissionGroup={authEnums.PERMISSION_GROUP.AFFILIATED_ENTITIES}
            >
              <AffiliationSection />
            </ProtectedComponent>

            <CommentsSection
              openModalOutside={shouldOpenComModal}
              setOpenModalOutside={setShouldOpenComModal}
              newCommentButton
              apiGetComments={api.comments.getTopLevelEntityComments}
              apiGetReplies={api.comments.getReplies}
              apiDeleteComment={api.comments.removeComment}
              apiEditComment={api.comments.editComment}
              apiCreateComment={api.comments.createNewComment}
              apiCreateReply={api.comments.createNewReply}
              additionalRequestParams={{
                entityId: npeId,
              }}
            />
          </>
        )}
      </div>
    </EntityContext.Provider>
  );
}
NaturalPersonProfile.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

NaturalPersonProfile.defaultProps = {
  className: undefined,
};

export default NaturalPersonProfile;
