import useSelect from 'hooks/useSelect';
import React, { useEffect } from 'react';
import { useField, useForm } from 'react-final-form';
import { selectCurrencies } from 'store/selectors/global.selector';
import Currency from '../Currency';

const CurrencyField = () => {
  const { change } = useForm();

  const {
    input: { value: parameters },
  } = useField('parameters');

  const currencies = useSelect(selectCurrencies) || [];

  useEffect(() => {
    const listOfAvailableCurrencies = currencies.reduce(
      (acc, curr) => ({
        ...acc,
        [`currency${curr?.id}`]: { ...curr, isEnabled: false, selected: false, decimalPlaces: 2 },
      }),
      {},
    );

    change(`parameters`, { ...listOfAvailableCurrencies, ...(parameters || {}) });
  }, []);

  return <Currency></Currency>;
};
export default CurrencyField;
