import { useEffect } from 'react';

/**
 * Pop-up setup is custom hook used to bootstrap basic modal functionalites.
 * @param {func} hideModal - function which hides modal
 * @param {reference} container - container current reference property
 * @param {reference} modal - modal reference
 */

const usePopupSetup = (hideModal, modalRef) => {
  useEffect(() => {
    const handleClick = event => {
      const { firstElementChild } = modalRef.current;
      if (!firstElementChild.contains(event.target)) {
        event.stopPropagation();
        hideModal();
      }
    };
    const setUp = () => {
      modalRef.current.addEventListener('click', handleClick, false);
    };

    const cleanUp = () => {
      if (modalRef.current) modalRef.current.removeEventListener('click', handleClick, false);
    };

    setUp();

    return cleanUp;
  }, [hideModal, modalRef]);
};

export default usePopupSetup;
