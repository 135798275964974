import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { useDispatch } from 'react-redux';
import api from '../../../api';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import InputField from '../../../components/InputField';
import history from '../../../modules/history';
import { setToken } from '../../../store/actions/auth.action';
import authEnums from '../../../utilities/enums/authEnums';
import TokenStorage from '../../../utilities/services/local-storage/TokenStorage';
import './styles.scss';

const CodeConfirmationTFA = () => {
  const [wrondCode, setWrongCode] = useState(false);
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  useEffect(() => {
    if (token) {
      TokenStorage.token = token;
    }
  }, [token]);

  const baseClass = 'ickyc-form';
  const handleSaveChanges = async values => {
    setWrongCode(false);
    const data = {
      twoFactorCode: values.code,
      token: TokenStorage.token,
    };
    return api.auth
      .confirmCodeTwoFactor(data)
      .then(res => {
        setToken(res.data.token);
        TokenStorage.token = res.data.token;
        TokenStorage.refreshToken = res.data.refreshToken;
        dispatch(setToken(res.data.token));
        const { roles } = res.data;
        if (
          roles &&
          roles?.some(role => {
            return (
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN ||
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN ||
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER
            );
          })
        ) {
          history.push('/internal/business-manager');
        } else {
          history.push('/kyc/home-screen');
        }
      })
      .catch(err => {
        setWrongCode(true);
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to get Authentication Info' };
      });
  };
  const redirectToRecovery = () => {
    history.push(`/auth/two-factor-authentication/recovery-code-confirmation?token=${token}`);
  };
  return !token ? (
    <h3 className="ickyc-widget__global-message">No Token Present!</h3>
  ) : (
    <FinalForm
      onSubmit={handleSaveChanges}
      validate={values => {
        const errors = {};
        if (!values.code) {
          errors.code = 'Required';
        }
        return errors;
      }}
    >
      {({ submitting, handleSubmit }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className={classNames(baseClass, 'ickyc-confirm-code-two-factor-authentication-page')}
          >
            <>
              <div className={`${baseClass}__fields ${baseClass}__fields--rounded`}>
                <h3>Two Factor Authentication</h3>
                <Field component={InputField} name="code" label="Authenticator Code" autocomplete="off" />
                <span>
                  Don't have access to your authenticator device?&nbsp;
                  <span
                    className="ickyc-confirm-code-two-factor-authentication-page__recovery-code-link"
                    onClick={redirectToRecovery}
                  >
                    You can log in with recovery code.
                  </span>
                </span>
                {wrondCode && (
                  <div className="ickyc-confirm-code-two-factor-authentication-page__error">Wrong code Entered!</div>
                )}
              </div>
              <div className={`${baseClass}__actions`}>
                <PrimaryButton type="submit" disabled={submitting}>
                  Authenticate
                  {submitting && <CircularProgress />}
                </PrimaryButton>
              </div>
            </>
          </form>
        );
      }}
    </FinalForm>
  );
};
export default CodeConfirmationTFA;
