import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useLocation, useParams } from 'react-router';
import InputField from '../../../../../../../components/InputField';
import Section from '../../../../../../../components/Section';
import SelectCustomContent from '../../../../../../../components/Select/components/SelectCustomContent';
import SelectFilterContent from '../../../../../../../components/Select/components/SelectFilterContent';
import SelectInputTrigger from '../../../../../../../components/Select/components/SelectInputTrigger';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../components/ToggleField';
import useSelect from '../../../../../../../hooks/useSelect';
import { selectPersonalInfo } from '../../../../../../../store/selectors/auth.selector';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import validators from '../../../../../../../utilities/services/validators';
import './styles.scss';

const options = [
  { id: 0, label: 'Natural Person', value: 'NaturalPerson' },
  { id: 1, label: 'Legal Entity', value: 'LegalEntity' },
];

const General = ({ domainProp }) => {
  const { autogeneratedDomain } = useSelect(selectPersonalInfo);

  const { state } = useLocation();
  const { clientId } = useParams();
  const {
    values: { id, type, clientRef, isDefault, enabled },
    initialValues: { isDefault: initialIsDefault },
  } = useFormState();
  const { change } = useForm();
  const domain = state?.domain || domainProp || null;

  const isNaturalPerson = type === 'NaturalPerson' || type === 'Natural Person';

  const domainPreffix = useMemo(() => {
    const tmpSuffix = isNaturalPerson ? '/np' : '/le';

    if (!isNaturalPerson && domain && domain.includes('verify')) {
      return `${domain?.replace('verify', 'corp')}${tmpSuffix}`;
    }
    if (!isNaturalPerson && !domain && autogeneratedDomain.includes('verify')) {
      return `${autogeneratedDomain?.replace('verify', 'corp')}${tmpSuffix}`;
    }
    if (clientId) {
      return domain + tmpSuffix;
    }
    return (autogeneratedDomain !== null && autogeneratedDomain !== '' ? autogeneratedDomain : domain) + tmpSuffix;
  }, [clientId, domain, autogeneratedDomain, isNaturalPerson]);

  const handleIsDefaultChange = useCallback(isDefault => {
    if (isDefault) {
      change('enabled', true);
    }
  }, []);

  return (
    <div className="ickyc-portal-general-info">
      <Section divider title="Name">
        <Field component={InputField} name="name" autoFocus validate={validators.required()} />
      </Section>
      <Section divider title="Portal Enabled">
        <Field
          component={ToggleField}
          name="enabled"
          disabled={isDefault}
          showCustomText
          toggleOnText="Enabled"
          toggleOffText="Disabled"
        />
      </Section>
      <Section divider title="Domain Pathname">
        <Field
          component={InputField}
          name="domainPathname"
          preffix={`${domainPreffix}/` || ''}
          validate={validators.required()}
          placeholder=". . ."
        />
      </Section>
      <Section divider title="Default">
        <Field component={ToggleField} name="isDefault" onChange={handleIsDefaultChange} disabled={initialIsDefault} />
      </Section>

      {clientRef && (
        <Section divider title="ClientRef">
          <span>{clientRef}</span>
        </Section>
      )}

      <Section divider title="Tags">
        <Field
          component={SelectField}
          multipleSelection
          withCustomOptions
          name="tags"
          options={authEnums.TAGS}
          Trigger={<SelectInputTrigger placeholder="Select Tags" />}
          Content={<SelectCustomContent />}
        />
      </Section>
      <Section title="Type">
        <Field
          component={SelectField}
          name="type"
          options={options}
          renderAsPortal
          preview={!!id}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContent />}
        />
      </Section>
    </div>
  );
};
General.propTypes = {
  domainProp: PropTypes.string,
};
General.defaultProps = {
  domainProp: '',
};
export default General;
