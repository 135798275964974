import React from 'react';
import { Field } from 'react-final-form';
import ToggleField from '../../../../../../../../../../../components/ToggleField';
import ModuleOption from '../../../ModuleOptions';

const AdditionalDetailsFormFields = () => {
  const baseClass = 'ickyc-additional-details-form-fields';
  return (
    <ModuleOption title="Form Fields">
      <div className={baseClass}>
        <div className={`${baseClass}__controll`}>Show Field</div>
        <div className={`${baseClass}__controll`}>Require Field</div>
        <div>
          <b>Employer Name</b>
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[2].details.employerName.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[2].details.employerName.required" />
        </div>
        <div>
          <b>Industry</b>
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[2].details.industry.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[2].details.industry.required" />
        </div>
        <div>
          <b>Title</b>
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[2].details.title.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[2].details.title.required" />
        </div>
        <div>
          <b>Role / Occupation</b>
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[2].details.role.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[2].details.role.required" />
        </div>
        <div>
          <b>Are you a Politically Exposed Person?</b>
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[2].details.pep.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[2].details.pep.required" />
        </div>
      </div>
    </ModuleOption>
  );
};
export default AdditionalDetailsFormFields;
