import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import DocumentDropzone from '../DocumentDropzone';

const DocumentDropzoneField = ({ meta, input, ...rest }) => {
  const { invalid, touched: metaTouched, error: metaError, submitError, dirtySinceLastSubmit } = meta;

  const errorMsg = useMemo(() => (meta ? meta.error || meta.submitError : null), [meta]);
  const hasError = useMemo(() => {
    if (meta && !rest.preview) {
      if (submitError && !dirtySinceLastSubmit) {
        return submitError;
      }
      return metaTouched && invalid && metaError;
    }
    return false;
  }, [meta, rest.preview]);

  return <DocumentDropzone errorMessage={errorMsg} hasError={hasError} {...input} {...rest} />;
};
export default DocumentDropzoneField;

DocumentDropzoneField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
    modified: PropTypes.bool,
  }),
  uploadText: PropTypes.string,
  preview: PropTypes.bool,
};

DocumentDropzoneField.defaultProps = {
  meta: { invalid: false, touched: false, error: '', submitError: '', dirtySinceLastSubmit: false },
  label: undefined,
  description: undefined,
  required: undefined,
  uploadText: 'Upload a Document Template',
  preview: false,
};
