import api from 'api';
import SelectContentTable from 'components/Select/components/SelectContentTable';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import ToggleField from 'components/ToggleField';
import { FORM_ERROR } from 'final-form';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import ProtectedComponent from 'router/components/ProtectedComponent';
import enums from 'utilities/enums';
import authEnums from 'utilities/enums/authEnums';
import { EntityContext } from 'utilities/services/contexts';
import utils from '../../utils';
import ExistingDocumentsTableRow from '../ExistingDocumentsTableRow';
import './styles.scss';

const { initialParams } = utils;

const ReportsModalFields = ({ entitiesIds }) => {
  const [documentsParams, setDocumentsParams] = useState(initialParams);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [casesAvaliable, setCasesAvaliable] = useState(false);
  const { isNaturalPerson } = useContext(EntityContext) || {};

  const {
    values: { includeImages, includeAmlCases, includeSupportingDocs, includeAllSupportingDocuments },
  } = useFormState();
  const { change } = useForm();

  const changeDocumentsParams = useCallback(newParams => {
    setDocumentsParams(prev => ({ ...prev, ...newParams }));
  }, []);

  const handleIncludeAmlCasesChange = useCallback(
    async includeAmlCases => {
      if (!includeAmlCases) {
        change('includeOnlyAcceptedMatches', false);
        change('includeAllCases', false);
        change('includeActiveOngoingMonitoringCases', false);
      }
    },
    [change],
  );

  const includeAllSupportingDocsCallback = useCallback(
    value => {
      if (value) {
        const docs = documents?.map(item => item.value);
        change('documentItemsIds', docs);
      }
    },
    [documents, change],
  );

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const { data } = await api.kyc.entityManagement.getDocumentsForReport(entitiesIds);
        const documentsData = data.map(item => ({
          label: item.name,
          value: item.id,
          id: item.id,
        }));
        setDocuments(documentsData);
      } catch (err) {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      }
    };

    const loadCases = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.kyc.entityManagement.checkForCasesPresence(entitiesIds);
        setCasesAvaliable(data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadDocuments();
    loadCases();
  }, [entitiesIds]);
  return (
    <di className="ickyc-reports-modal-fields">
      <Field
        component={SelectField}
        name="format"
        label="Format"
        options={enums.EXPORT_REPORT_TYPES}
        Trigger={<SelectTrigger />}
        Content={<SelectFilterContent />}
      />
      <Field component={ToggleField} name="includeAcceptedOnly" label="Include Accepted Only" />
      <Field component={ToggleField} name="includeImages" label="Include Images" />
      {!!includeImages && (
        <div className="ickyc-reports-modal-fields__hidden-options">
          {isNaturalPerson && <Field component={ToggleField} name="includeIdDocsImages" label="ID Docs" />}
          {isNaturalPerson && <Field component={ToggleField} name="includeFaceMatchImages" label="Face Matches" />}
          <Field component={ToggleField} name="includeSupportingDocs" label="Supporting Documents" />

          {!!includeSupportingDocs && (
            <div className="ickyc-reports-modal-fields__hidden-options">
              <Field
                component={ToggleField}
                name="includeAllSupportingDocuments"
                label="Include All Supporting Documents"
                onChange={includeAllSupportingDocsCallback}
              />
              <Field
                component={SelectField}
                name="documentItemsIds"
                label="What supporting documents do you want to include in this report?"
                multipleSelection
                Trigger={<SelectTrigger />}
                withTableContent
                options={documents}
                disabled={includeAllSupportingDocuments}
                Content={
                  <SelectContentTable
                    disabled={includeAllSupportingDocuments}
                    tableProps={{
                      tableRow: ExistingDocumentsTableRow,
                      headerRow: TableHeader,
                      handleParamsChange: changeDocumentsParams,
                      headerData: {
                        sortCriteria: documentsParams.sortCriteria,
                        sortOrder: documentsParams.sortOrder,
                        onClick: changeDocumentsParams,
                        items: [
                          {
                            type: 'name',
                            label: 'Name',
                            clickable: true,
                          },
                        ],
                      },
                      updating: isLoading,
                      withLoadMore: true,
                      withPagination: true,
                      className: 'ickyc-existing-cases-table',
                    }}
                  />
                }
              />
            </div>
          )}
        </div>
      )}
      <Field component={ToggleField} name="includeCustomQuestions" label="Include Custom Questions" />
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}>
        <div className="ickyc-reports-modal-fields__aml-toggle">
          <Field
            component={ToggleField}
            name="includeAmlCases"
            label="Include AML cases"
            onChange={handleIncludeAmlCasesChange}
            disabled={!casesAvaliable || isLoading}
          />
          {!casesAvaliable && !isLoading && (
            <span className="ickyc-reports-modal-fields__aml-toggle__no-cases">
              There are no cases available for the profile
            </span>
          )}
        </div>

        {includeAmlCases && (
          <div className="ickyc-reports-modal-fields__hidden-options">
            <Field component={ToggleField} name="includeAllCases" label="Include All Cases" />
            <Field
              component={ToggleField}
              name="includeActiveOngoingMonitoringCases"
              label="Include Active Ongoing Monitoring Cases"
            />
            <Field component={ToggleField} name="includeOnlyAcceptedMatches" label="Include Only Accepted Matches" />
          </div>
        )}
      </ProtectedComponent>
    </di>
  );
};
export default ReportsModalFields;
