/* eslint-disable react/jsx-curly-newline */
import CloseButton from 'components/Buttons/CloseButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import NaturalPerson from '../NaturalPerson';

const NaturalPersonTable = ({ successIds }) => {
  const { mutators } = useForm();
  const addPerson = () => {
    mutators.push('persons', { data: undefined, sessionId: '' });
  };
  const removePerson = useCallback(
    index => {
      mutators.remove('persons', index);
    },
    [mutators],
  );

  useEffect(() => {
    if (successIds.length > 0) {
      successIds.forEach(ind => {
        removePerson(ind);
      });
    }
  }, [successIds, removePerson]);

  const baseClass = 'ickyc-natural-persons';

  return (
    <>
      <div className={baseClass}>
        <div className={`${baseClass}__row`}>
          <h4> </h4>
          <h4 className="required">First Name</h4>
          <h4>Middle Name</h4>
          <h4 className="required">Last Name</h4>
          <h4>Nationality</h4>
          <h4>Date Of Birth</h4>
          <h4 className="required">Email</h4>
          <h4>Phone</h4>
          <h4>Country</h4>
          <h4>Street Address</h4>
          <h4>Unit</h4>
          <h4>City</h4>
          <h4>State/Province</h4>

          <h4>Zip/Postal Code</h4>
          <div className={`${baseClass}__row__remove`} />
        </div>
        <FieldArray name="persons">
          {({ fields, meta: { submitError, dirtySinceLastSubmit } }) => {
            return fields.map((name, index) => (
              <div className={`${baseClass}__row`} key={name}>
                <NaturalPerson index={index + 1} namePreffix={`${name}`} />
                <div className={`${baseClass}__remove`}>
                  <CloseButton onClick={() => removePerson(index)} />
                </div>
                {submitError?.length > 0 && submitError[index] && !dirtySinceLastSubmit && (
                  <div className={`${baseClass}__error`}>{submitError[index]}</div>
                )}
              </div>
            ));
          }}
        </FieldArray>
      </div>
      <OutlineButton className="ickyc-natural-persons__outline-button" onClick={addPerson}>
        + Add Additional Person{' '}
      </OutlineButton>
    </>
  );
};

NaturalPersonTable.propTypes = {
  successIds: PropTypes.arrayOf(PropTypes.number),
};
NaturalPersonTable.defaultProps = {
  successIds: [],
};

export default NaturalPersonTable;
