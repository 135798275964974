import classNames from 'classnames';
import Spinner from 'components/Spinner';
import useCentralisedApi from 'hooks/useCentralisedApi';
import useGlobalKeyListener from 'hooks/useGlobalKeyListener';
import useNotifications from 'hooks/useNotifications';
import usePathname from 'hooks/usePathname';
import useScrollTo from 'hooks/useScrollTo';
import useSelect from 'hooks/useSelect';
import useWindowResizeListener from 'hooks/useWindowResizeListener';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { selectToken, selectUserRoles } from 'store/selectors/auth.selector';
import userflow from 'userflow.js';
import authEnums from 'utilities/enums/authEnums';
import routesEnum from 'utilities/enums/routesEnum';
import SignalRService from 'utilities/services/SignalRService';
import { NavigationContext } from 'utilities/services/contexts';
import TokenStorage from 'utilities/services/local-storage/TokenStorage';
import { selectPersonalInfo } from '../../../store/selectors/auth.selector';
import Header from '../../components/Header';
import ResponsiveSideMenu from '../../components/ResponsiveSideMenu';
import Routes from '../../components/Routes';
import SideMenu from '../../components/SideMenu';
import '../../styles.scss';
import routes from './routes';

const administrationList = [
  {
    path: routesEnum.administration.DASHBOARD,
    title: 'Dashboard',
    allowedUserTypes: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
  },
  {
    path: routesEnum.administration.ACCOUNT,
    permissionGroup: authEnums.PERMISSION_GROUP.ACCOUNT,
    title: 'Account',
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    allowedUserTypes: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.ACCOUNT,
  },
  {
    path: routesEnum.administration.EMAILS,
    title: 'Settings',
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    allowedUserTypes: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.EMAILS,
    items: [
      {
        path: routesEnum.administration.EMAILS,
        title: 'Emails',
        allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
        requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
        licenceAccessKey: authEnums.ACCESS_BY_LICENCE.EMAILS,
      },
    ],
  },
  {
    path: routesEnum.administration.COMPLIANCE_SETTINGS,
    permissionGroup: authEnums.PERMISSION_GROUP.COMPLIANCE_SETTINGS,
    title: 'Compliance Settings',
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    allowedUserTypes: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
    items: [
      {
        path: routesEnum.administration.SUPPORTING_DOCUMENTS,
        title: 'Supporting Documents',
        allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
        permissionGroup: authEnums.PERMISSION_GROUP.COMPLIANCE_SETTINGS,
        requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
        licenceAccessKey: authEnums.ACCESS_BY_LICENCE.SUPPORTING_DOCUMENTS,
      },
      {
        path: routesEnum.administration.RISK_SCREENING,
        title: 'Risk Screening',
        allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
        permissionGroup: authEnums.PERMISSION_GROUP.COMPLIANCE_SETTINGS,
        requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
        licenceAccessKey: authEnums.ACCESS_BY_LICENCE.RISK_SCREENING,
      },
      {
        path: routesEnum.administration.RISK_LEVELS,
        title: 'Risk Levels',
        allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
        permissionGroup: authEnums.PERMISSION_GROUP.COMPLIANCE_SETTINGS,
        requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
        licenceAccessKey: authEnums.ACCESS_BY_LICENCE.RISK_LEVELS,
      },
      {
        path: routesEnum.administration.RISK_SCORING,
        title: 'Risk Scoring',
        allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
        permissionGroup: authEnums.PERMISSION_GROUP.COMPLIANCE_SETTINGS,
        requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
        licenceAccessKey: authEnums.ACCESS_BY_LICENCE.RISK_SCORING,
      },
      {
        path: routesEnum.administration.QUICK_SETTINGS_SUBROUTER,
        title: 'Quick Setup Settings',
        allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
        licenceAccessKey: authEnums.ACCESS_BY_LICENCE.QUICK_SETUP_SETTINGS,
        items: [
          {
            path: routesEnum.administration.RESTRICTIONS,
            title: 'Restrictions',
            allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
          },
          {
            path: routesEnum.administration.IDENTITY_VERIFICATION,
            title: 'Identity Verification',
            allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
          },
          {
            path: routesEnum.administration.DOCUMENT_VERIFICATION,
            title: 'Document Verification',
            allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
          },
          {
            path: routesEnum.administration.BIOMETRIC_AUTHENTICATION,
            title: 'Biometric Authentication',
            allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
          },
        ],
      },
    ],
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.COMPLIENCE_SETTINGS,
  },
  {
    path: routesEnum.administration.DATA_MANAGEMENT,
    title: 'Files Management',
    allowedUserTypes: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
    items: [
      {
        path: routesEnum.administration.DATA_MANAGEMENT,
        licenceAccessKey: authEnums.ACCESS_BY_LICENCE.ENTITIES,
        title: 'CSV',
        allowedUserTypes: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
      },
      {
        path: routesEnum.administration.REPORTS,
        title: 'Reports',
        allowedUserTypes: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
      },
    ],
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.FILES_MANAGEMENT,
  },
  {
    path: routesEnum.administration.WEB_HOOKS_MANAGMENT,
    title: 'Web Hooks',
    allowedUserTypes: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.WEBHOOKS,
  },
  {
    path: routesEnum.administration.AUTOMATION,
    title: 'Automations',
    allowedUserTypes: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.AUTOMATIONS,
  },
  {
    path: routesEnum.administration.USER_MANAGEMENT,
    title: 'User Management',
    allowedUserTypes: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    permissionGroup: authEnums.PERMISSION_GROUP.USER_MANAGEMENT,
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.USER_MANAGEMENT,
  },
  {
    path: routesEnum.administration.PORTAL_MANAGMENT,
    title: 'Portal Management',
    allowedRoles: [authEnums.USER_TYPES.RESTRICTED_ADMIN, authEnums.USER_TYPES.FULL_ADMIN],
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    permissionGroup: authEnums.PERMISSION_GROUP.KYC_PORTALS,
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.PORTAL_MANAGEMENT,
  },
];

const kycList = [
  {
    path: routesEnum.kyc.HOME_SCREEN,
    title: 'Home Screen',
    allowedUserTypes: [
      authEnums.USER_TYPES.CLIENT_USER,
      authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
      authEnums.USER_TYPES.PLATFORM_USER,
      authEnums.USER_TYPES.RESTRICTED_ADMIN,
      authEnums.USER_TYPES.FULL_ADMIN,
    ],
  },
  {
    path: routesEnum.kyc.ENTITY_MANAGEMENT,
    title: 'Entities',
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    allowedUserTypes: [authEnums.USER_TYPES.CLIENT_USER, authEnums.USER_TYPES.FULL_ADMIN],
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.ENTITIES,
  },
  {
    path: routesEnum.kyc.CASE_MANAGEMENT,
    title: 'Case Management',
    allowedUserTypes: [authEnums.USER_TYPES.CLIENT_USER, authEnums.USER_TYPES.FULL_ADMIN],
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.amlSearchDashboard],
    permissionGroup: authEnums.PERMISSION_GROUP.RISK_SCREENING,
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.CASES,
  },
  {
    path: routesEnum.kyc.MY_WORK,
    title: 'My Work',
    allowedUserTypes: [
      authEnums.USER_TYPES.CLIENT_USER,
      authEnums.USER_TYPES.RESTRICTED_PLATFORM_USER,
      authEnums.USER_TYPES.PLATFORM_USER,
      authEnums.USER_TYPES.RESTRICTED_ADMIN,
      authEnums.USER_TYPES.FULL_ADMIN,
    ],
  },
];

const accountSettingsList = [
  {
    path: routesEnum.accountSettings.PERSONAL_SETTINGS,
    title: 'Personal Settings',
  },
];

const businessList = [
  {
    path: routesEnum.internal.BUSINESS_MANAGER,
    title: 'Business Manager',
    allowedUserRoles: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
    ],
  },
  {
    path: routesEnum.internal.ICOMPLY_TEAM,
    title: 'iComply Team',
    allowedUserTypes: [
      authEnums.ICOMPLY_USERS_TYPES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_TYPES.ICOMPLY_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
    ],
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    permissionGroup: authEnums.PERMISSION_GROUP.ICOMPLY_TEAM,
  },
  {
    path: routesEnum.internal.LICENCE_MANAGMENT,
    title: 'License Manager',
    allowedUserTypes: [
      authEnums.ICOMPLY_USERS_TYPES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_TYPES.ICOMPLY_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
    ],
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    permissionGroup: authEnums.PERMISSION_GROUP.LICENCE_MANAGER,
  },
  {
    path: routesEnum.internal.EMAIL_TEMPLATES,
    title: 'Email Templates',
    allowedUserTypes: [
      authEnums.ICOMPLY_USERS_TYPES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_TYPES.ICOMPLY_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
    ],
    requiredPermissions: [authEnums.PERMISSION_TAGS_MAPPING.view],
    permissionGroup: authEnums.PERMISSION_GROUP.EMAIL_TEMPLATES,
  },
];

const PrivateSubrouter = () => {
  const lsToken = TokenStorage.get();
  const pathname = usePathname();
  const widgetRef = useRef();
  const reduxToken = useSelect(selectToken);
  const isLoading = useCentralisedApi(lsToken || reduxToken);
  const userRoles = useSelect(selectUserRoles);
  useNotifications();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const isIComplyTeamMemeber = useMemo(
    () =>
      userRoles.some(role => {
        return (
          role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN ||
          role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN ||
          role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER
        );
      }),
    [userRoles],
  );
  const handleBurgerClick = () => {
    setIsMenuVisible(prev => !prev);
  };

  const closeMenu = () => {
    setIsMenuVisible(false);
  };

  useScrollTo(widgetRef, pathname);
  useWindowResizeListener(closeMenu);
  useGlobalKeyListener(widgetRef);

  const personalInfo = useSelect(selectPersonalInfo) || {};

  const userflowKey = process.env.REACT_APP_USERFLOW_KEY;

  useEffect(() => {
    const { id, firstName, lastName, email } = personalInfo;

    if (id) {
      const currentTime = new Date().toISOString();

      userflow.init(userflowKey);
      // Delete key of old anonymous users
      if (localStorage.getItem('userflow:anonymousId')) {
        localStorage.removeItem('userflow:anonymousId');
      }

      userflow.identify(id, {
        name: `${firstName} ${lastName}`,
        email,
        signed_up_at: currentTime,
      });
    }
  }, [personalInfo]);

  useEffect(() => {
    SignalRService.startConnection(lsToken);

    return SignalRService.stopConnection;
  }, [lsToken]);

  if (isLoading) {
    return (
      <div className="ickyc-widget--loading">
        <Spinner />
      </div>
    );
  }

  const routeClasses = classNames({ 'ickyc-margin': !showSideMenu });

  return (
    <div className="ickyc-widget" ref={widgetRef}>
      <Header burgerClick={handleBurgerClick} />

      <NavigationContext.Provider
        value={{
          hideModal: closeMenu,
          kycList: isIComplyTeamMemeber ? [] : kycList,
          administrationList: isIComplyTeamMemeber ? [] : administrationList,
          accountSettingsList,
          businessList: isIComplyTeamMemeber ? businessList : [],
        }}
      >
        <div className="ickyc-widget__content">
          <SideMenu setShowSideMenu={setShowSideMenu} />
          <Routes routes={routes} className={routeClasses} />
        </div>
        <ResponsiveSideMenu isOpen={isMenuVisible} hideModal={() => setIsMenuVisible(false)} />
      </NavigationContext.Provider>
    </div>
  );
};

export default PrivateSubrouter;
