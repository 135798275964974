import api from 'api';
import Accordion from 'components/Accordion';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import IComplyForm from 'components/Form/IComplyForm';
import Table from 'components/Tables/Table';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import Tooltip from 'components/Tooltip';
import { FORM_ERROR } from 'final-form';
import useModalHandler from 'hooks/useModalHandler';
import useTable from 'hooks/useTable';
import useViewRelatedEventsListener from 'hooks/useViewRelatedEventsListener';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import toastr from 'toastr';
import enums from 'utilities/enums';
import TableHeaders from 'utilities/services/TableHeaders';
import { EntityContext, PermissionGroupContext } from 'utilities/services/contexts';
import AdditionalDocumentsForm from '../AdditionalDocumentsForm';
import prepareItems from '../AdditionalDocumentsForm/utils';
import RequestClientUpdateLegalEntityModal from '../RequestClientUpdateLegalEntityModal';
import RequestClientUpdateModal from '../RequestClientUpdateNaturalPersonModal';
import DocumentsTableRow from './components/DocumentsTableRow';
import './styles.scss';

const DocumentsSection = ({ badge }) => {
  const { isOpen: rcuOpen, open: openRCU, close: closeRCU } = useModalHandler();
  const { isOpen: rcuLegalEntityOpen, open: openLegalEntityRCU, close: closeLegalEntityRCU } = useModalHandler();

  const containerRef = useRef(null);
  const { entityId, isNaturalPerson } = useContext(EntityContext);
  const { isOpen: canShow, open: showForm, close: hideForm } = useModalHandler();
  const {
    isLoading,
    removeRow,
    records,
    addRow,
    changeParams,
    pagination,
    params,
    overrideTable,
    updateRowData,
  } = useTable(api.kyc.entityManagement.getDocumentsTable, { entityId });

  useViewRelatedEventsListener(enums.BUS_EVENTS.OVERRIDE_DOCUMENTS_TABLE, overrideTable, containerRef);

  useEffect(() => {
    const updateHistoryEventStatus = ({ eventHistoryId, data }) => {
      updateRowData('eventHistoryId', eventHistoryId, data);
    };
    bus.addEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    };
  }, [updateRowData]);

  useEffect(() => {
    const onNewRecordEvent = newRecords => {
      if (newRecords?.length) {
        newRecords.forEach(element => {
          addRow(element);
        });
      } else {
        addRow(newRecords);
      }
    };
    bus.addEventListener(enums.BUS_EVENTS.NEW_DOCUMENTS_RECORD, onNewRecordEvent);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.NEW_DOCUMENTS_RECORD, onNewRecordEvent);
    };
  }, [addRow]);

  const handleSave = ({ items, ...restValues }) => {
    const { answersDTO, itemsDTO } = prepareItems(items);
    const values = { ...restValues, items: itemsDTO, answers: answersDTO };
    return api.kyc.entityManagement
      .createDocumentsTableRecord(entityId, values)
      .then(({ data: response }) => {
        const { documentDto, commentDto } = response;
        bus.broadcastEvent(enums.BUS_EVENTS.NEW_DOCUMENTS_RECORD, documentDto);
        bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, commentDto);
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.DOCUMENTS,
          issuesChange: documentDto?.issues,
        });
        toastr.success('Successfully created Supporting Document ');
        hideForm();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.message };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else {
          return { [FORM_ERROR]: 'Error occured while trying to add Supporting Document' };
        }
      });
  };

  const handleHistoryRecordDelete = useCallback(
    eventId => {
      removeRow('eventHistoryId', eventId);
    },
    [removeRow],
  );
  const { create: canCreate, requestClientUpdate: canRCU, delete: canDelete } = useContext(PermissionGroupContext);

  const TableControls = () => {
    return (
      <>
        <h3>History</h3>
        <div>
          {canCreate && (
            <Tooltip
              placement="top"
              trigger={
                <div>
                  <OutlineButton onClick={showForm}>+ Add Documents Package</OutlineButton>
                </div>
              }
              content={<span>Adding a document manually will NOT trigger any KYC services.</span>}
            />
          )}
          {canRCU && <>{isNaturalPerson && <PrimaryButton onClick={openRCU}> Request Client Update</PrimaryButton>}</>}
          {canRCU && (
            <>
              {!isNaturalPerson && <PrimaryButton onClick={openLegalEntityRCU}> Request Client Update</PrimaryButton>}
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <Accordion
      title="Supporting Document Packages"
      accordionOpen={false}
      accordionindex={enums.ACCORDION_INDEXES.DOCUMENTS}
      accented
      withBadge
      badge={badge}
    >
      <div ref={containerRef}>
        {canShow && (
          <IComplyForm initialValues={{}} onSubmit={handleSave} onDiscardClick={hideForm} showControls={canShow}>
            <AdditionalDocumentsForm noToggle />
          </IComplyForm>
        )}
        {rcuOpen && (
          <RequestClientUpdateModal
            supportingDocumentPackagesEnabled
            hideModal={closeRCU}
            containerRef={containerRef}
          />
        )}
        {rcuLegalEntityOpen && (
          <RequestClientUpdateLegalEntityModal
            supportingDocumentPackagesEnabled
            hideModal={closeLegalEntityRCU}
            containerRef={containerRef}
          />
        )}
        <Table
          tableControls={TableControls}
          headerRow={TableHeader}
          tableRow={DocumentsTableRow}
          values={records}
          handleParamsChange={changeParams}
          pagination={pagination}
          withPagination
          onRemove={handleHistoryRecordDelete}
          updating={isLoading}
          headerData={{
            sortCriteria: params.sortCriteria,
            sortOrder: params.sortOrder,
            onClick: changeParams,
            items: TableHeaders.DocumentsTable,
            withDelete: canDelete,
          }}
          idAttributeName="eventHistoryId"
          className="ickyc-documents-table"
        />
      </div>
    </Accordion>
  );
};
DocumentsSection.propTypes = {
  badge: PropTypes.number,
  hasServicesColumn: PropTypes.bool,
};
DocumentsSection.defaultProps = {
  badge: 0,
  hasServicesColumn: false,
};

export default DocumentsSection;
