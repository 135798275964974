import PropTypes from 'prop-types';
import React from 'react';
import Routes from '../../../../../../components/Routes';
import routes from './routes/index';

const FilesSubrouter = ({ className }) => {
  return <Routes routes={routes} className={className} />;
};

FilesSubrouter.propTypes = {
  className: PropTypes.string,
};
FilesSubrouter.defaultProps = {
  className: undefined,
};
export default FilesSubrouter;
