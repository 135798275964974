import { useEffect } from 'react';

/**
 * Effect of scrolling to the top of the page on route change.
 * @param {reference} widgetRef - App's reference
 * @param {string} location - router location
 */

const useScrollTo = (widgetRef, location) => {
  useEffect(() => {
    if (widgetRef.current) {
      widgetRef.current.scrollTo(0, 0);
    }
  }, [widgetRef, location]);
};

export default useScrollTo;
