import React from 'react';
import ProtectedComponent from 'router/components/ProtectedComponent';
import authEnums from 'utilities/enums/authEnums';
import AutoAcceptenceForm from '../components/AutoAcceptenceForm';
import AutoAssignmentForm from '../components/AutoAssignmentForm';
import EntityProfileForm from '../components/EntityProfileForm';
import NextKYCReviewForm from '../components/NextKYCReviewForm';

const defaultInitialValues = {
  generalSettings: { clientEmail: '', domain: undefined, emailSubject: '', emailMessage: '' },
  amlCase: {
    isEnabled: false,
    screeningProfileId: null,
    createCaseAfterAllRequestsAreAccepted: false,
    monitorCase: false,
    autoUpdateStatus: false,
    caseStatus: 0,
  },
  dataCollection: {
    requestPersonalInformation: true,
    personalInformationInputOption: '1',
    requestAddressInformation: true,
    addressInformationInputOption: '0',
    requestAdditionalDetails: true,
    requestProofOfAddress: false,
    additionalDetailsInputOption: '1',
    requestIdentityDocument: false,
    requireTwoPiecesOfId: false,
    requestSupportingDocuments: false,
    packagesTypes: [],
  },
  comment: { isEnabled: false, commentBody: '' },
  kycRequests: {
    allEnabled: false,
    runVerification: false,
    identityVerification: {
      isEnabled: false,
      runIdentityDataConfirmation: false,
      livenessDetection: false,
    },

    documentAuthentication: {
      isEnabled: false,
      templateVerification: false,
      documentDataVerification: false,
      includeFaceMatch: false,
      faceMatchStrategy: '1',
    },
    biometricAuthentication: {
      isEnabled: false,
      faceMatchType: '0',
    },
  },
};

const mapJurisdictionEntity = data =>
  data.reduce(
    (accumulator, { countryId, autoAssignEntityProfileUserId }) => ({
      ...accumulator,
      [`country${countryId}`]: {
        autoAssignEntityProfileUserId,
      },
    }),
    {},
  );

const mapidentityAuthenticationEvent = data =>
  data.reduce(
    (accumulator, { countryId, autoAssignIdentityAuthenticationUserId }) => ({
      ...accumulator,
      [`country${countryId}`]: {
        autoAssignIdentityAuthenticationUserId,
      },
    }),
    {},
  );

const mapdocumentAuthenticationEvent = data =>
  data.reduce(
    (accumulator, { countryId, autoAssignDocumentAuthenticationUserId }) => ({
      ...accumulator,
      [`country${countryId}`]: {
        autoAssignDocumentAuthenticationUserId,
      },
    }),
    {},
  );

const mapbiometricAuthenticationEvent = data =>
  data.reduce(
    (accumulator, { countryId, autoAssignBiometricAuthenticationUserId }) => ({
      ...accumulator,
      [`country${countryId}`]: {
        autoAssignBiometricAuthenticationUserId,
      },
    }),
    {},
  );

const mapSupportingDocs = automationsSupportingDocs =>
  automationsSupportingDocs.reduce(
    (accumulator, { documentTypeName, autoAssignedUserId, packageId }) => ({
      ...accumulator,
      [`document${packageId}`]: {
        documentTypeName,
        autoAssignedUserId,
        packageId,
      },
    }),
    {},
  );

const mergeJurisdictionTables = restOfConfig => {
  const {
    biometricAuthenticationEvent,
    documentAuthenticationEvent,
    entityProfile,
    identityAuthenticationEvent,
  } = restOfConfig;

  const restrictions = [];

  Object.entries(entityProfile).forEach(([key, value]) => {
    const countryId = Number(key.replace('country', ''));
    const { selected, ...restOfValue } = value;
    countryId &&
      restrictions.push({
        [`countryId`]: countryId,
        ...restOfValue,
        ...biometricAuthenticationEvent[key],
        ...documentAuthenticationEvent[key],
        ...identityAuthenticationEvent[key],
      });
  });
  return restrictions;
};

const parseDocumentsListTable = restOfConfig => {
  const { automationsSupportingDocsTransformed } = restOfConfig;

  const restrictions = [];

  Object.entries(automationsSupportingDocsTransformed).forEach(({ 0: key, 1: value }) => {
    const documentId = Number(key.replace('document', ''));
    const { selected, ...restOfValue } = value;
    restrictions.push({
      [`packageId`]: documentId,
      ...restOfValue,
    });
  });
  return restrictions;
};

const tabOptions = [
  { label: 'Auto Acceptance', key: 'autoAcceptance' },
  { label: 'Auto Assignment', key: 'autoAssignment' },
  { label: 'Entity Profile', key: 'entityProfile' },
  { label: 'Next KYC Review', key: 'nextKycReview' },
];

const screens = [
  <AutoAcceptenceForm />,
  <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
    <AutoAssignmentForm />
  </ProtectedComponent>,
  <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}>
    <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
      <EntityProfileForm />
    </ProtectedComponent>
  </ProtectedComponent>,
  <NextKYCReviewForm />,
];

export default {
  defaultInitialValues,
  mapJurisdictionEntity,
  mapidentityAuthenticationEvent,
  mapdocumentAuthenticationEvent,
  mapbiometricAuthenticationEvent,
  mapSupportingDocs,
  mergeJurisdictionTables,
  parseDocumentsListTable,
  tabOptions,
  screens,
};
