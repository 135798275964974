import classNames from 'classnames';
import BareHeading from 'components/Headings/BareHeading';
import React from 'react';

const Notifications = ({ className }) => {
  const classes = classNames('ickyc-page', 'ickyc-notifications', className);

  return (
    <div className={classes}>
      <BareHeading title="Notifications" />;
    </div>
  );
};
export default Notifications;
