import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import Select from '../../../../components/Select';
import SelectFilterContent from '../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../components/Select/components/SelectTrigger';
import './styles.scss';

const AutoAssignmentControls = ({ onClick, disabled, value, setValue, propertyName }) => {
  const baseClass = 'ickyc-assignment-constrols';
  const label = 'Assign To Selected';

  const handleOnChange = val => {
    setValue(val);
  };
  const usersToAssign = useSelector(state =>
    state.global.usersToAssign.map(({ id: uId, name }) => ({
      label: name,
      value: uId,
      id: uId,
    })),
  );
  return (
    <span className={baseClass}>
      <Select
        className={`${baseClass}__assigned-user`}
        disabled={disabled}
        value={value}
        options={usersToAssign}
        Trigger={<SelectTrigger placeholder="Choose Assigned To" />}
        Content={<SelectFilterContent />}
        onChange={handleOnChange}
      />
      <PrimaryButton key={label} onClick={() => onClick({ [propertyName]: value })} disabled={disabled}>
        {label}
      </PrimaryButton>
    </span>
  );
};
AutoAssignmentControls.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  propertyName: PropTypes.string,
};
AutoAssignmentControls.defaultProps = {
  onClick: () => {},
  disabled: true,
  value: '',
  setValue: () => {},
  propertyName: 'assignToUser',
};
export default AutoAssignmentControls;
