import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ArrowIcon from '../../../assets/ArrowIcon';
import LogoAnimation from '../../../assets/LogoAnimation';
import LogoAnimationReverse from '../../../assets/LogoAnimationReverse';
import IconButton from '../../../components/Buttons/IconButton';
import Tooltip from '../../../components/Tooltip';
import NavigationList from './components/NavigationList';

import './styles.scss';

/**
 * Sidebar menu navigation for screens wider than 1024px.x
 */

const SideMenu = ({ setShowSideMenu }) => {
  const [showMenuHover, setShowMenuHover] = useState(false);
  const [showMenuClick, setShowMenuClick] = useState(false);

  const showMenu = useMemo(() => showMenuHover || showMenuClick, [showMenuClick, showMenuHover]);

  useEffect(() => {
    setShowSideMenu(showMenuClick);
  }, [showMenuClick, setShowSideMenu]);

  const classes = classNames('ickyc-side-menu', {
    'ickyc-side-menu--clicked': showMenuClick,
    'ickyc-side-menu--hovered': showMenuHover,
  });

  const closeMenu = useCallback(() => {
    setShowMenuClick(old => !old);
    setShowMenuHover(false);
  }, [setShowMenuClick, setShowMenuHover]);

  return (
    <div className={classes}>
      {showMenu ? <LogoAnimationReverse /> : <LogoAnimation />}

      <nav
        className="ickyc-side-menu__content-box"
        onMouseEnter={() => setShowMenuHover(true)}
        onMouseLeave={() => setShowMenuHover(false)}
      >
        {showMenu && (
          <Tooltip
            content={<span>Collapse</span>}
            trigger={
              <div className="ickyc-side-menu__button-box">
                <IconButton className="ickyc-side-menu__toggle-button" onClick={() => closeMenu()}>
                  <ArrowIcon className={showMenuClick ? 'rotate' : undefined} />
                </IconButton>
              </div>
            }
          />
        )}

        <NavigationList />
      </nav>

      {!showMenu && (
        <Tooltip
          content={<span>Expand</span>}
          trigger={
            <div className="ickyc-side-menu__button-box">
              <IconButton className="ickyc-side-menu__toggle-button" onClick={() => setShowMenuClick(old => !old)}>
                <ArrowIcon />
              </IconButton>
            </div>
          }
        />
      )}
    </div>
  );
};
SideMenu.propTypes = {
  setShowSideMenu: PropTypes.func.isRequired,
};
export default SideMenu;
