const globalInitialState = {
  // List of countries
  countries: [],
  riskLevels: [],
  usersToAssign: [],
  defaultScreeningProfile: {
    sources: [],
    fuzziness: 50,
    entityType: 0,
    searchTerms: [{}],
  },
  documentTypes: [],
  notifications: [],
};

export default {
  globalInitialState,
};
