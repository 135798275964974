import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const RecoveryCodes = ({ recoveryCodes, successMessage }) => {
  return (
    <div className="recovery-codes">
      <h3 className="recovery-codes__success">{successMessage}</h3>
      Put this <span className="recovery-codes__emphasized-text">{recoveryCodes.length}</span> codes in a safe place.
      Each code is valid for one login. If you lose your device and don't have recovery codes you will lose access to
      your account.
      <div className="recovery-codes__recovery-codes-label">Recovery codes:</div>
      <div className="recovery-codes__list-container">
        {recoveryCodes.map(code => (
          <div>{code}</div>
        ))}
      </div>
    </div>
  );
};
RecoveryCodes.propTypes = {
  recoveryCodes: PropTypes.arrayOf(PropTypes.string),
  successMessage: PropTypes.string,
};
RecoveryCodes.defaultProps = {
  recoveryCodes: [],
  successMessage: 'Success!',
};
export default RecoveryCodes;
