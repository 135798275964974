export const formFields = [
  { label: 'Business Number', name: 'businessNumber' },
  { label: 'EIN/TIN', name: 'einTin' },
  { label: 'LEI Number', name: 'leiNumber' },
  { label: 'DUNS Number', name: 'dunsNumber' },
  { label: 'Formation Country', name: 'formationCountry' },
  { label: 'Formation Date', name: 'formationDate' },
  { label: 'Domicile', name: 'domicile' },
  { label: 'Phone', name: 'phone' },
  { label: 'Website', name: 'website' },
];
