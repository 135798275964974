import api from 'api';
import classNames from 'classnames';
import Loader from 'components/Loader';
import React, { useCallback, useEffect, useState } from 'react';
import ModalTabHeader from '../../../components/Headers/ModalTabHeader';
import BareHeading from '../../../components/Headings/BareHeading';
import BillingPage from './components/BillingPage';
import CompanyDetailsPage from './components/CompanyDetailsPage';
import CompanySettingsPage from './components/CompanySettingsPage';
import UsagePage from './components/UsagePage';
import './styles.scss';

const AccountPage = ({ className }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tabOptions, setTabOptions] = useState([
    { label: 'Company Details', key: 'companyDetails' },
    { label: 'Company Settings', key: 'companySettings' },
  ]);
  const [screens, setScreens] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { data } = await api.administration.account.companyDetails();
        const newScreens = [<CompanyDetailsPage initialValues={data} />, <CompanySettingsPage />];
        if (data?.activeLicenseType === 'Website') {
          newScreens.push(<BillingPage />, <UsagePage />);
          setTabOptions(old => [...old, { label: 'Billing', key: 'billing' }, { label: 'Usage', key: 'usage' }]);
        }

        setScreens(newScreens);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const optionClick = useCallback(value => {
    setActiveTab(value);
  }, []);

  const classes = classNames('ickyc-account-page', 'ickyc-page', className);
  return (
    <div className={classes}>
      {isLoading && <Loader />}

      <div className="">
        <BareHeading title="Account" />
        <div>
          <ModalTabHeader onClick={optionClick} options={tabOptions} />
          <div className="ickyc-account-page__active-screen">{screens[activeTab]}</div>
        </div>
      </div>
    </div>
  );
};
export default AccountPage;
