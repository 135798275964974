import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import enums from '../../../../../utilities/enums';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import SortingButton from '../../../../Buttons/SortingButton';

/* eslint-disable jsx-a11y/control-has-associated-label */

/**
 * Header displayed inside the address table
 * @param {boolean = false} [hasAttachedTo] - does have an attachedTo header cell
 */

const TableHeader = ({ onClick, className, sortCriteria, sortOrder, items = [], withDelete }) => {
  const setSuffix = setClassSuffix(className);

  const handleSort = useCallback(
    newSortCriteria => {
      onClick({ sortCriteria: newSortCriteria, sortOrder: sortOrder ? 0 : 1 });
    },
    [sortOrder, onClick],
  );

  return (
    <thead>
      <tr className={`${className}__header__row`} onClick={event => event.stopPropagation()}>
        {items.map(({ type, label, clickable }) => (
          <th className={setSuffix(`__column__${type}`)} key={type + sortOrder}>
            {clickable ? (
              <SortingButton
                sortType={type === sortCriteria ? sortOrder : enums.SORTING.CHAOTIC}
                onClick={e => {
                  e.stopPropagation();
                  handleSort(type);
                }}
              >
                {label}
              </SortingButton>
            ) : (
              <span>{label}</span>
            )}
          </th>
        ))}
        {withDelete && <td className={`${className}__column__remove`} />}
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string.isRequired,
  sortCriteria: PropTypes.string,
  sortOrder: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      clickable: PropTypes.bool,
    }),
  ).isRequired,
  withDelete: PropTypes.bool,
};

TableHeader.defaultProps = {
  onClick: () => {},
  withDelete: false,
  sortCriteria: undefined,
  sortOrder: undefined,
};

export default TableHeader;
