import React from 'react';
import ModuleOption from '../../../ModuleOptions';
import CorporateDetailsFormRow from './components/CorporateDetailsFormRow';
import './styles.scss';
import { formFields } from './utils';

const CorporateDetailsFormFields = ({ index }) => {
  const baseClass = 'ickyc-corporate-details-form-fields';
  return (
    <ModuleOption title="Form Fields">
      <div className={baseClass}>
        <div className={`${baseClass}__controll`}>Show Field</div>
        <div className={`${baseClass}__controll`}>Require Field</div>
        {formFields.map(formField => (
          <CorporateDetailsFormRow
            className={`${baseClass}__controll`}
            index={index}
            {...formField}
            key={formField.name}
          />
        ))}
      </div>
    </ModuleOption>
  );
};
export default CorporateDetailsFormFields;
