import PropTypes from 'prop-types';
import React from 'react';
import OutlineButton from '../../../../../../../components/Buttons/OutlineButton';
import IconManager from '../../../../../../../utilities/services/IconManager';
import { CASE_MATCH_STATUS } from '../../../../enums';
import './styles.scss';

const MatchesControls = ({ disabled, onClick }) => {
  return (
    <div className="ickyc-matches-controls">
      <OutlineButton
        left={IconManager.get(IconManager.names.QUESTION)}
        onClick={event => {
          event.stopPropagation();
          onClick(CASE_MATCH_STATUS.UNKNOWN);
        }}
        disabled={disabled}
      >
        Unknown
      </OutlineButton>
      <OutlineButton
        left={IconManager.get(IconManager.names.CLOSE)}
        onClick={event => {
          event.stopPropagation();
          onClick(CASE_MATCH_STATUS.REJECTED);
        }}
        disabled={disabled}
      >
        Reject
      </OutlineButton>
      <OutlineButton
        left={IconManager.get(IconManager.names.CHECK_FILLED)}
        onClick={event => {
          event.stopPropagation();
          onClick(CASE_MATCH_STATUS.ACCEPTED);
        }}
        disabled={disabled}
      >
        Accept
      </OutlineButton>
    </div>
  );
};

MatchesControls.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

MatchesControls.defaultProps = {
  onClick: () => {},
  disabled: true,
};

export default MatchesControls;
