import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import IconManager from '../../../../../../../utilities/services/IconManager';
import { AML_FILTERS, AML_FILTER_TYPES } from '../../../../enums';
import './styles.scss';

/**
 * AML filter component
 * @param {string} name - text displayed inside the filter
 */

const AMLFilter = ({ code, type, onClick }) => {
  const classBase = 'ickyc-aml-filter';
  const classes = classNames({
    [classBase]: true,
    [`${classBase}--disabled`]: !code,
    [`${classBase}--filled`]: code === AML_FILTER_TYPES.SELECTED,
    [`${classBase}--outlined`]: code === AML_FILTER_TYPES.MATCHED,
    [`${classBase}--green-outline`]: code === AML_FILTER_TYPES.REQUESTED,
  });

  return (
    <label onClick={onClick} className={classes}>
      {code === AML_FILTER_TYPES.SELECTED && IconManager.get(IconManager.names.CHECK_FILLED)}
      <span>{AML_FILTERS[type]}</span>
    </label>
  );
};

AMLFilter.propTypes = {
  type: PropTypes.number.isRequired,
  code: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};
AMLFilter.defaultProps = {
  onClick: () => {},
};

export default AMLFilter;
