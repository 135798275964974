import React from 'react';

export default () => {
  return (
    <svg x="0" y="0" viewBox="0 0 154 154">
      <path fill="gray" d="M3.7 3.5h147v147H3.7z" />
      <path
        fill="currentColor"
        d="M52.2 61.1l.2-.2c.7-.5 1.1-1.4 1-2.3-1.8-11.1-.6-15.7-.2-16.8 3.3-9.9 13.3-14.4 15.3-15.2.4-.2 1.2-.4 2-.5l.2-.1 1.6-.1v.1l.4-.1c.3-.1.7-.1 1-.2l.4-.1c.3 0 4.2.5 10.1 2.3l4.1 1.4c7.4 2.2 10.8 6.3 11.4 7.1 5.9 6.7 4.4 16.9 2.9 22.4-.2.6-.1 1.3.3 1.8l.3.4c.4.6.8 2.8-.5 7.6-.2 1.5-.8 2.7-1.6 3.4-.3.3-.5.7-.6 1.2-1.9 11.8-12.5 25-23.6 25-9.4 0-20.2-12.1-22.1-24.9-.1-.5-.2-.9-.6-1.3-.8-.8-1.3-2.1-1.6-3.8-1-3.2-1.1-6-.4-7.1z"
      />
      <path
        fill="currentColor"
        d="M31 112.1c.4-.5 2.7-3.2 7.3-5 4.1-1.3 14.1-4.6 19.5-8.6.2-.2.5-.4.7-.6l2.2-2.2.5-.5.5.5c4.8 4.5 10.2 7.1 15.1 7.1 5.1 0 10.4-2.2 15.2-6.4l.4-.3 1 .5c1 .8 2.5 2 3.3 2.4l1 .5-.1.1.4.2c.9.5 1.9 1 3 1.6 1.1.5 2.1.9 3.1 1.2.8.2 5.4 1.8 10.5 4.2l1 .3c5 1.9 7.3 4.6 7.5 4.8 5.9 8.8 8.2 25.3 9.1 34.5H22c.9-9 3.2-25.4 9-34.3z"
      />
    </svg>
  );
};
