import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import Table from '../Table';
const FieldCheckBoxTable = ({ values, onChange, TableControlsComponent, className, ...props }) => {
  const [controlsValue, setControlValues] = useState();

  const selectedAll = useMemo(() => {
    const selectedOptionsLength = values.filter(val => val.checked).length;
    return values.length > 0 && values.length === selectedOptionsLength;
  }, [values]);

  const onSelectAll = useCallback(() => {
    onChange(values.map(p => ({ ...p, checked: !selectedAll })));
  }, [selectedAll, values, onChange]);

  const applyToMultipleRows = useCallback(
    val => {
      onChange(
        values.map(p => (p.checked ? { ...p, value: val instanceof Object ? { ...p.value, ...val } : val } : p)),
      );
    },
    [onChange, values],
  );

  const onCheckboxClick = useCallback(
    id => {
      onChange(values.map(p => (p.id === id ? { ...p, checked: !p.checked } : p)));
    },
    [values, onChange],
  );

  const onControlsClick = useCallback(
    (id, value) => {
      onChange(values.map(p => (p.id === id ? { ...p, value } : p)));
    },
    [onChange, values],
  );

  const disableControls = useMemo(() => values.every(lv => !lv.checked), [values]);

  const TableControls = () => {
    return (
      <>
        <label>
          <input type="checkbox" checked={selectedAll} onChange={onSelectAll} /> Select All
        </label>
        <TableControlsComponent
          disabled={disableControls}
          onClick={applyToMultipleRows}
          value={controlsValue}
          setValue={setControlValues}
        />
      </>
    );
  };

  return (
    <Table
      values={values}
      {...props}
      onCheckboxClick={onCheckboxClick}
      onControlsClick={onControlsClick}
      tableControls={TableControlsComponent && TableControls}
      className={className || 'ickyc-checkbox-table'}
    />
  );
};

export default FieldCheckBoxTable;

FieldCheckBoxTable.propTypes = {
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.shape({
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
    total: PropTypes.number,
  }),
  withPagination: PropTypes.bool,
  handleParamsChange: PropTypes.func,
  headerData: PropTypes.shape({
    sortCriteria: PropTypes.string,
    sortOrder: PropTypes.number,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  tableControls: PropTypes.elementType,
  TableControlsComponent: PropTypes.elementType,

  rowControls: PropTypes.elementType,
  tableRow: PropTypes.elementType.isRequired,
  headerRow: PropTypes.elementType,
  updating: PropTypes.bool,
  shouldReset: PropTypes.bool,
  className: PropTypes.string,
  onControls: PropTypes.func,
};
FieldCheckBoxTable.defaultProps = {
  rowControls: undefined,
  tableControls: undefined,
  onChange: () => {},
  withPagination: false,
  values: [],
  pagination: {},
  handleParamsChange: () => {},
  headerData: {},
  options: [],
  updating: false,
  shouldReset: false,
  headerRow: null,
  TableControlsComponent: null,
  onControls: () => {},
  className: undefined,
};
