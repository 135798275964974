import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useContext, useImperativeHandle, useState } from 'react';
import Table from '../../../../../components/Tables/Table';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import useTable from '../../../../../hooks/useTable';
import { PortalManagementContext } from '../../../../../utilities/services/contexts';
import PortalsTableRow from '../PortalsTableRow';

const PortalConfigsTable = forwardRef(({ getAllPortalsRoute }, ref) => {
  const [updatingDefault, setUpdatingDefault] = useState(false);
  const { updateDefault, clientId } = useContext(PortalManagementContext);
  const {
    records,
    pagination,
    params,
    changeParams,
    isLoading,
    removeRow,
    addRow,
    updateRowData,
  } = useTable(getAllPortalsRoute, { limit: 10, sortCriteria: 'isDefault,creationDate', sortOrder: 1, id: clientId });

  const setDefault = useCallback(
    (id, portalType) => {
      const type = portalType === 'NaturalPerson' ? 0 : 1;
      setUpdatingDefault(true);
      const paramsData = clientId ? { clientId, data: { id, type } } : { data: { id, type } };
      updateDefault(...Object.entries(paramsData).map(el => el[1]))
        .then(() => {
          updateRowData('isDefault', true, { isDefault: false });
          updateRowData('id', id, { isDefault: true });
        })
        .finally(() => {
          setUpdatingDefault(false);
        });
    },
    [updateRowData, clientId],
  );

  const applySearchTerm = useCallback(
    searchString => {
      changeParams({ searchString });
    },
    [changeParams],
  );
  useImperativeHandle(ref, () => ({ records, addRow, updateRowData, applySearchTerm }));

  const handleRemove = useCallback(
    (deletionKey, deletionValue, newDefaultPortalId) => {
      removeRow(deletionKey, deletionValue);
      const newDefaultRow = records.find(row => row.id === newDefaultPortalId);
      if (newDefaultRow) {
        setDefault(newDefaultRow.id, newDefaultRow.type);
      }
    },
    [removeRow, setDefault, records],
  );

  return (
    <Table
      tableRow={PortalsTableRow}
      headerRow={TableHeader}
      values={records}
      className="ickyc-portals-table"
      pagination={pagination}
      withLimitChange
      withPagination
      onRemove={handleRemove}
      updating={isLoading || updatingDefault}
      handleParamsChange={changeParams}
      // onClick={openPortalModalEditMode}
      onRadioClick={setDefault}
      onUpdateRow={updateRowData}
      setUpdating={setUpdatingDefault}
      headerData={{
        sortCriteria: params.sortCriteria,
        sortOrder: params.sortOrder,
        onClick: changeParams,
        items: [
          {
            type: 'default',
            label: 'Default',
            clickable: false,
          },
          {
            type: 'creationDate',
            label: 'Date Created',
            clickable: true,
          },
          { type: 'dependency' },
          {
            type: 'name',
            label: 'Name',
            clickable: true,
          },
          {
            type: 'type',
            label: 'Type',
            clickable: true,
          },
          {
            type: 'url',
            label: 'URLs',
            clickable: true,
          },
          {
            type: 'tags',
            label: 'Tags',
            clickable: true,
          },
          {
            type: 'status',
            label: 'Status',
            clickable: true,
          },
          { type: 'remove' },
        ],
      }}
    />
  );
});
PortalConfigsTable.propTypes = {
  getAllPortalsRoute: PropTypes.string.isRequired,
};
PortalConfigsTable.defaultProps = {};
export default PortalConfigsTable;
