import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../../../api';
import InputField from '../../../../../../components/InputField';
import FormModal from '../../../../../../components/Modal/FormModal';
import Spinner from '../../../../../../components/Spinner';
import RecoveryCodes from '../../../../../Auth/EnableAuthenticatorTFA/components/RecoveryCodes';
import './styles.scss';

const ConfigureAuthenticatorAppModal = ({ hideModal }) => {
  const baseClass = 'ickyc-form';
  const [code, setCode] = useState('');
  const [authenticatorUri, setAuthenticatorUri] = useState('');
  const [submitButtonText, setSubmitButtonText] = useState('Configure');
  const [isLoading, setIsLoading] = useState(true);
  const [recoveryCodes, setRecoveryCodes] = useState([]);

  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      return api.auth
        .enableTwoFactorAuthentication()
        .then(res => {
          setCode(res.data.sharedKey);
          setAuthenticatorUri(res.data.authenticatorUri);
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errorData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
            }
            if (status === 500) {
              return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
            }
          } else return { [FORM_ERROR]: 'Error occured while trying to get Authentication Info' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    getData();
  }, []);
  const handleSaveChanges = async values => {
    if (recoveryCodes.length) {
      hideModal();
      return;
    }
    return api.auth
      .verifyTwoFactorAuthenticator(values)
      .then(({ data }) => {
        setSubmitButtonText('Close');
        setRecoveryCodes(data?.recoveryCodes || []);
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.Code || `${errorData.Code?.slice(0, 80)}...` };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to get Authentication Info' };
      });
  };

  return (
    <FormModal
      onSubmit={handleSaveChanges}
      hideModal={hideModal}
      validate={values => {
        const errors = {};
        if (!values.code) {
          errors.code = 'Required';
        }
        return errors;
      }}
      title="Configure Authenticator App"
      className="ickyc-configure-authenticator-app-modal"
      submitButtonText={submitButtonText}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div
            className={classNames(
              `${baseClass}__fields ${baseClass}__fields--rounded`,
              'ickyc-enable-two-factor-authentication-page',
            )}
          >
            <h2>Enable Authenticator</h2>
            <h3>To authenticate go through following steps:</h3>
            <ol>
              <li>
                Download a two factor authenticator app like Microsoft Authenticator for Windows Phone, Android and iOS
                or Google Authenticator for Android and iOS.
              </li>
              <li>
                Scan the QR code or enter this key <br /> <b>{code}</b> <br />
                into your two factor authenticator app
                <div className="ickyc-enable-two-factor-authentication-page__qr">
                  <QRCode value={authenticatorUri} />
                </div>
              </li>

              <li>
                Once you have scanned the QR code or input the key above, your two factor authenticator app will provide
                you with a unique code. Enter the code in the confirmation box below.
                <Field component={InputField} name="code" label="Verification Code" autocomplete="off" />
              </li>
            </ol>
            {recoveryCodes.length !== 0 && (
              <RecoveryCodes
                successMessage="You successfully configured your authenticator app!"
                recoveryCodes={recoveryCodes}
              />
            )}
          </div>
        </>
      )}
    </FormModal>
  );
};
ConfigureAuthenticatorAppModal.propTypes = {
  hideModal: PropTypes.func,
};
ConfigureAuthenticatorAppModal.defaultProps = {
  hideModal: null,
};
export default ConfigureAuthenticatorAppModal;
