import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import OutlineButton from '../../../../../components/Buttons/OutlineButton';

const RevertPortalSettingsButton = () => {
  const { restart } = useForm();
  const { initialValues, submitting } = useFormState();
  const handleRevertActionClick = () => {
    restart(initialValues);
  };
  return (
    <OutlineButton disabled={submitting} type="button" onClick={handleRevertActionClick}>
      Revert
    </OutlineButton>
  );
};
export default RevertPortalSettingsButton;
