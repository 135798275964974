import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import setClassSuffix from '../../../../../../../../../../utilities/services/ClassManager';
import LegalEntityInformationForm from '../../../../../../../components/LegalEntityInformationForm';
import './styles.scss';

const ThirdPartyEditEntity = ({ editedFields }) => {
  const classBase = 'ickyc-third-party-edit-entity';
  const setSuffix = setClassSuffix(classBase);

  const editedChecklist = useMemo(() => {
    const checkList = {
      nameStatus: true,
      doingBusinessAsStatus: true,
      businessNumberStatus: true,
      leiNumberStatus: true,
      dunsNumberStatus: true,
      einTinStatus: true,
      alsoKnownAsStatus: true,
      formerlyKnownAsStatus: true,
      jurisdictionOfIncorporationIdStatus: true,
      jurisdictionOfTaxResidenceIdStatus: true,
      jurisdictionOfDomicileIdStatus: true,
      companyPhoneStatus: true,
      dateOfFormationStatus: true,
      websiteStatus: true,
    };

    editedFields.forEach(field => {
      checkList[field] = false;
    });

    return checkList;
  }, [editedFields]);

  return (
    <div className={setSuffix('__info')}>
      <LegalEntityInformationForm
        preview
        responsive
        checkList={editedChecklist}
        namePreffix="corporateInformation.thirdPartySuggestion.editedCorporateEvent"
      />
    </div>
  );
};

ThirdPartyEditEntity.propTypes = {
  editedFields: PropTypes.object,
};
ThirdPartyEditEntity.defaultProps = {
  editedFields: {},
};

export default ThirdPartyEditEntity;
