import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Tooltip from '../../../../components/Tooltip';
import ProtectedComponent from '../../../../router/components/ProtectedComponent';
import authEnums from '../../../../utilities/enums/authEnums';
import tooltips from '../../../../utilities/enums/tooltips';
import setClassSuffix from '../../../../utilities/services/ClassManager';
import './styles.scss';

/**
 * Rounded representation of issue number
 * @param {number} kycIssues - kyc issue number
 * @param {number} amlIssues - aml issue number
 */

const IssueNumber = ({ kycIssues, amlIssues, onClick }) => {
  const classBase = 'ickyc-issue-number';
  const setSuffix = setClassSuffix(classBase);
  const [totalIssues, setTotalIssues] = useState(0);
  const issuesClasses = classNames(setSuffix('__circle'), {
    [`${setSuffix('__circle__red')}`]: !!(kycIssues + amlIssues),
    [`${setSuffix('__circle__gray')}`]: !(kycIssues + amlIssues),
  });
  const kycIssuesClasses = classNames({
    [`${setSuffix('__red')}`]: !!kycIssues,
    [`${setSuffix('__gray')}`]: !kycIssues,
  });

  const amlIssuesClasses = classNames({
    [`${setSuffix('__red')}`]: !!amlIssues,
    [`${setSuffix('__gray')}`]: !amlIssues,
  });

  const getWordIssue = number => {
    return number === 1 ? 'Issue' : 'Issues';
  };

  useEffect(() => {
    setTotalIssues(kycIssues + amlIssues);
  }, [setTotalIssues, kycIssues, amlIssues]);

  return (
    <div className={classBase}>
      <div className={issuesClasses} onClick={onClick}>
        <Tooltip trigger={<span> {totalIssues}</span>} content={<div>{tooltips.ISSUES}</div>} />

        <span>{getWordIssue(totalIssues)}</span>
      </div>

      <div className={setSuffix('__details')}>
        <span>
          KYC {getWordIssue(kycIssues)} <span className={kycIssuesClasses}>{kycIssues}</span>
        </span>
        <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}>
          <span>
            AML {getWordIssue(kycIssues)} <span className={amlIssuesClasses}>{amlIssues}</span>
          </span>
        </ProtectedComponent>
      </div>
    </div>
  );
};

IssueNumber.propTypes = {
  kycIssues: PropTypes.number,
  amlIssues: PropTypes.number,
  onClick: PropTypes.func,
};

IssueNumber.defaultProps = {
  kycIssues: 0,
  amlIssues: 0,
  onClick: () => {},
};

export default IssueNumber;
