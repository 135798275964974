// eslint-disable-next-line import/prefer-default-export
export const WEB_HOOK_EVENT_TYPES = [
  {
    id: 1,
    value: 'Legal Entity Created',
    label: 'Legal Entity Created',
  },
  {
    id: 4,
    value: 'Natural Person Created',
    label: 'Natural Person Created',
  },
  {
    id: 16,
    value: 'Natural Person Pending',
    label: 'Natural Person Pending',
  },
  {
    id: 32,
    value: 'Natural Person Manually Approved',
    label: 'Natural Person Manually Approved',
  },
  {
    id: 64,
    value: 'Natural Person Auto Approved',
    label: 'Natural Person Auto Approved',
  },
  {
    id: 128,
    value: 'Natural Person Started ID Upload',
    label: 'Natural Person Started ID Upload',
  },
  {
    id: 256,
    value: 'Natural Person Uploaded ID',
    label: 'Natural Person Uploaded ID',
  },
  {
    id: 1024,
    value: 'Natural Person Uploaded Supporting Document',
    label: 'Natural Person Uploaded Supporting Document',
  },
  {
    id: 2048,
    value: 'Natural Person Has AML Alert',
    label: 'Natural Person Has AML Alert',
  },
];
