import userflow from 'userflow.js';
import api from '../api';
import TokenStorage from '../utilities/services/local-storage/TokenStorage';
import RefreshToken from '../utilities/services/RefreshToken';
import history from './history';

export default async function() {
  RefreshToken.reinit();
  api.auth.logout();
  TokenStorage.remove();
  history.push('/auth/login');
  userflow.reset();
}
