/**
 * Date manipulator.
 */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const dayJS = dayjs.extend(utc);

export default {
  /**
   * Converts date string into short format: mm dd, yyyy
   * e.g. Jan 20, 2002
   * @param {string} dateString - string which represents date
   */
  monDayYearLocal(dateString) {
    return dayJS
      .utc(dateString)
      .local()
      .format('MMM DD, YYYY');
  },
  monDayYear(dateString) {
    if (!dayjs(dateString).isValid()) {
      return undefined;
    }
    return dayJS.utc(dateString).format('MMM DD, YYYY');
  },
  monYear(dateString) {
    if (!dayjs(dateString).isValid()) {
      return undefined;
    }
    return dayJS.utc(dateString).format('MMMM YYYY');
  },
  /**
   * Converts date string into time format: (h)h:mm AM/PM".
   * e.g. 05:30 AM
   * @param {string} dateString - string which represents date
   */

  hhmm(dateString) {
    return dayJS.utc(dateString).format('HH:mm');
  },
  /**
   * Converts date string into full date.
   * e.g. Feb 24, 2020 10:30
   * @param {string} dateString - string which represents date
   *
   */
  getFullDateLocal(dateString) {
    return dayJS
      .utc(dateString)
      .local()
      .format('MMM DD, YYYY HH:mm');
  },

  /**
   * Converts date string into full date.
   * e.g. February 24, 2020 at 10:30 AM
   * @param {string} dateString - string which represents date
   *
   */
  getFullDateLocalModified(dateString) {
    return dayJS
      .utc(dateString)
      .local()
      .format('MMMM DD, YYYY [at] hh:mm A');
  },

  toLocal_hhmm(dateString) {
    return dayJS
      .utc(dateString)
      .local()
      .format('HH:mm');
  },

  getCurrentUTC() {
    return dayJS.utc().format();
  },
  toUTC(date) {
    if (!date) return '';
    return dayJS.utc(date).toDate();
  },
  toLocal(date) {
    return dayJS.utc(date).local();
  },
  getLocal() {
    return dayjs();
  },
  toBekend(date) {
    return date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : undefined;
  },
  toDate(date) {
    const newDate = dayjs(date);
    return newDate.isValid() ? newDate.toDate() : undefined;
  },
  parseDate(date, format) {
    const newDate = dayjs(date, format);
    return newDate.isValid() ? newDate.toDate() : undefined;
  },

  compareDates(dateFrom, dateUntil) {
    const date1 = dayjs(dateFrom);
    const date2 = dayjs(dateUntil);
    if (date1.diff(date2) > 0) {
      return true;
    }
    return false;
  },

  calculateWhenExpiresInDays(expiryDate) {
    const days = dayjs(expiryDate).diff(dayjs(this.getCurrentUTC()), 'days');
    return `${days} ${days !== 1 ? 'days' : 'day'}`;
  },

  formatRelativeTime(dateString) {
    const date = this.toLocal(dateString);
    const today = dayjs();

    // Check if the dates have the same year, month, and day
    if (date.isSame(today, 'day')) {
      return `Today at ${date.format('HH:mm')}`;
    } else {
      // Check if the date is yesterday
      const yesterday = today.subtract(1, 'day');
      if (date.isSame(yesterday, 'day')) {
        return `Yesterday at ${date.format('HH:mm')}`;
      } else {
        // Check if the date is within the past week
        const daysDiff = today.diff(date, 'day');
        if (daysDiff < 7) {
          return `${daysDiff} days ago at ${date.format('HH:mm')}`;
        } else {
          // Check if the date is within the past year
          const weeksDiff = Math.floor(daysDiff / 7);
          if (weeksDiff < 52) {
            return `${weeksDiff} ${weeksDiff !== 1 ? 'weeks' : 'week'} ago`;
          } else {
            const yearsDiff = Math.floor(weeksDiff / 52);
            return `${yearsDiff} ${yearsDiff !== 1 ? 'years' : 'year'} ago`;
          }
        }
      }
    }
  },
};
