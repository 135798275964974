import TokenStorage from '../utilities/services/local-storage/TokenStorage';
import httpClient from './index';

function refresh() {
  const { refreshToken } = TokenStorage;
  return httpClient
    .post(
      'refresh-token',
      {
        refreshToken,
      },
      {
        isRefresh: true,
      },
    )
    .then(res => res.data);
}

export default {
  refresh,
};
