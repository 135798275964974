const TRANSPARENT_GREY = 'rgb(102,102,102,.75)';

const CircleSize = 7;
const TopLeft = 'Top Left';
const TopRight = 'Top Right';
const BottomLeft = 'Bottom Left';
const BottomRight = 'Bottom Right';

export default class Quadruple {
  constructor(p1, p2, p3, p4) {
    const points = [p1, p2, p3, p4];

    points.sort((a, b) => {
      return a.x - b.x;
    });

    const left = [points[0], points[1]];
    const right = [points[2], points[3]];

    if (left[0].y < left[1].y) {
      [this.TopLeft, this.BottomLeft] = left;
    } else {
      [this.BottomLeft, this.TopLeft] = left;
    }

    if (right[0].y < right[1].y) {
      [this.TopRight, this.BottomRight] = right;
    } else {
      [this.BottomRight, this.TopRight] = right;
    }

    this.TopLeftSelected = false;
    this.TopRightSelected = false;
    this.BottomLeftSelected = false;
    this.BottomRightSelected = false;
    this.cornerRectSize = 8;
    this.cornerTouchArea = 20;
  }

  drawCircle = (canvasContext, xPosition, yPosition) => {
    canvasContext.beginPath();
    canvasContext.arc(xPosition, yPosition, CircleSize, 0, 2 * Math.PI, false);
    canvasContext.fill();
    canvasContext.lineWidth = 3;
    canvasContext.stroke();
    canvasContext.closePath();
  };

  drawLines = canvasContext => {
    canvasContext.moveTo(this.TopLeft.x, this.TopLeft.y);
    canvasContext.lineTo(this.TopRight.x, this.TopRight.y);
    canvasContext.lineTo(this.BottomRight.x, this.BottomRight.y);
    canvasContext.lineTo(this.BottomLeft.x, this.BottomLeft.y);
    canvasContext.lineTo(this.TopLeft.x, this.TopLeft.y);
  };

  drawRoundedRectangles = canvasContext => {
    canvasContext.beginPath();

    this.drawTextRectangle(canvasContext, TopLeft, this.TopLeft.x + 13, this.TopLeft.y + 20);

    this.drawTextRectangle(canvasContext, TopRight, this.TopRight.x - 62, this.TopRight.y + 20);

    this.drawTextRectangle(canvasContext, BottomLeft, this.BottomLeft.x + 13, this.BottomLeft.y - 14);

    this.drawTextRectangle(canvasContext, BottomRight, this.BottomRight.x - 78, this.BottomRight.y - 14);

    canvasContext.fillStyle = TRANSPARENT_GREY;
    canvasContext.fill();
  };

  drawAllText = canvasContext => {
    canvasContext.font = '11px Sans-serif';
    canvasContext.fillStyle = 'white';

    canvasContext.fillText(TopLeft, this.TopLeft.x + 13, this.TopLeft.y + 20);

    canvasContext.fillText(TopRight, this.TopRight.x - 62, this.TopRight.y + 20);

    canvasContext.fillText(BottomLeft, this.BottomLeft.x + 13, this.BottomLeft.y - 14);

    canvasContext.fillText(BottomRight, this.BottomRight.x - 78, this.BottomRight.y - 14);
  };

  drawTextRectangle = (canvasContext, text, x, y) => {
    let rectangleX = 0;
    let rectangleY = 0;
    let rectangleWidth = 0;
    const rectangleHeight = 20;

    switch (text) {
      case TopLeft:
        rectangleX = x - 5;
        rectangleY = y - 13;
        rectangleWidth = 52;
        break;
      case TopRight:
        rectangleX = x - 7;
        rectangleY = y - 13;
        rectangleWidth = 60;
        break;
      case BottomLeft:
        rectangleX = x - 5;
        rectangleY = y - 13;
        rectangleWidth = 67;
        break;
      case BottomRight:
        rectangleX = x - 7;
        rectangleY = y - 13;
        rectangleWidth = 77;
        break;
      default:
        break;
    }

    this.createRoundedRectangle(canvasContext, rectangleX, rectangleY, rectangleWidth, rectangleHeight, 5);
  };

  correctCorners = (width, height, minimum) => {
    const min = minimum || CircleSize;
    const maxHeight = height - min;
    const maxWidth = width - min;
    if (this.TopLeft.x < min) this.TopLeft.x = min;
    if (this.BottomLeft.x < min) this.BottomLeft.x = min;
    if (this.TopRight.x > maxWidth) this.TopRight.x = maxWidth;
    if (this.BottomRight.x > maxWidth) this.BottomRight.x = maxWidth;
    if (this.TopLeft.y < min) this.TopLeft.y = min;
    if (this.BottomLeft.y > maxHeight) this.BottomLeft.y = maxHeight;
    if (this.TopRight.y < min) this.TopRight.y = min;
    if (this.BottomRight.y > maxHeight) this.BottomRight.y = maxHeight;
  };

  createRoundedRectangle = (canvasContext, xPosition, yPosition, width, height, borderRadius) => {
    if (width < 2 * borderRadius) {
      borderRadius = width / 2;
    }

    if (height < 2 * borderRadius) {
      borderRadius = height / 2;
    }

    canvasContext.moveTo(xPosition + borderRadius, yPosition);

    canvasContext.arcTo(xPosition + width, yPosition, xPosition + width, yPosition + height, borderRadius);

    canvasContext.arcTo(xPosition + width, yPosition + height, xPosition, yPosition + height, borderRadius);

    canvasContext.arcTo(xPosition, yPosition + height, xPosition, yPosition, borderRadius);

    canvasContext.arcTo(xPosition, yPosition, xPosition + width, yPosition, borderRadius);
  };

  drawCornersAsRectangles = (canvasContext, cornerRectSize) => {
    canvasContext.fillRect(
      this.TopLeft.x - cornerRectSize,
      this.TopLeft.y - cornerRectSize,
      2 * cornerRectSize,
      2 * cornerRectSize,
    );

    canvasContext.fillRect(
      this.TopRight.x - cornerRectSize,
      this.TopRight.y - cornerRectSize,
      2 * cornerRectSize,
      2 * cornerRectSize,
    );

    canvasContext.fillRect(
      this.BottomLeft.x - cornerRectSize,
      this.BottomLeft.y - cornerRectSize,
      2 * cornerRectSize,
      2 * cornerRectSize,
    );

    canvasContext.fillRect(
      this.BottomRight.x - cornerRectSize,
      this.BottomRight.y - cornerRectSize,
      2 * cornerRectSize,
      2 * cornerRectSize,
    );
  };

  drawCornersAsCircles = canvasContext => {
    canvasContext.fillStyle = 'white';

    this.drawCircle(canvasContext, this.TopLeft.x, this.TopLeft.y);

    this.drawCircle(canvasContext, this.TopRight.x, this.TopRight.y);

    this.drawCircle(canvasContext, this.BottomLeft.x, this.BottomLeft.y);

    this.drawCircle(canvasContext, this.BottomRight.x, this.BottomRight.y);
  };

  updateCoordinates = value => {
    this.TopLeft.x *= value;
    this.TopLeft.y *= value;
    this.TopRight.x *= value;
    this.TopRight.y *= value;
    this.BottomRight.x *= value;
    this.BottomRight.y *= value;
    this.BottomLeft.x *= value;
    this.BottomLeft.y *= value;
  };

  updatePoint = (x, y) => {
    if (this.TopLeftSelected) {
      this.TopLeft.x = x;
      this.TopLeft.y = y;
      return;
    }
    if (this.TopRightSelected) {
      this.TopRight.x = x;
      this.TopRight.y = y;
      return;
    }
    if (this.BottomLeftSelected) {
      this.BottomLeft.x = x;
      this.BottomLeft.y = y;
      return;
    }
    if (this.BottomRightSelected) {
      this.BottomRight.x = x;
      this.BottomRight.y = y;
    }
  };

  isPointClicked = (x, y, targetBoundingRect) => {
    if (
      x - targetBoundingRect.left > this.TopLeft.x - this.cornerTouchArea &&
      x - targetBoundingRect.left < this.TopLeft.x + this.cornerTouchArea &&
      y - targetBoundingRect.top > this.TopLeft.y - this.cornerTouchArea &&
      y - targetBoundingRect.top < this.TopLeft.y + this.cornerTouchArea
    ) {
      this.TopLeftSelected = true;
      return;
    }
    if (
      x - targetBoundingRect.left > this.TopRight.x - this.cornerTouchArea &&
      x - targetBoundingRect.left < this.TopRight.x + this.cornerTouchArea &&
      y - targetBoundingRect.top > this.TopRight.y - this.cornerTouchArea &&
      y - targetBoundingRect.top < this.TopRight.y + this.cornerTouchArea
    ) {
      this.TopRightSelected = true;
      return;
    }
    if (
      x - targetBoundingRect.left > this.BottomRight.x - this.cornerTouchArea &&
      x - targetBoundingRect.left < this.BottomRight.x + this.cornerTouchArea &&
      y - targetBoundingRect.top > this.BottomRight.y - this.cornerTouchArea &&
      y - targetBoundingRect.top < this.BottomRight.y + this.cornerTouchArea
    ) {
      this.BottomRightSelected = true;
      return;
    }
    if (
      x - targetBoundingRect.left > this.BottomLeft.x - this.cornerTouchArea &&
      x - targetBoundingRect.left < this.BottomLeft.x + this.cornerTouchArea &&
      y - targetBoundingRect.top > this.BottomLeft.y - this.cornerTouchArea &&
      y - targetBoundingRect.top < this.BottomLeft.y + this.cornerTouchArea
    ) {
      this.BottomLeftSelected = true;
    }
  };

  isOutsideCanvas = ({ left, top, right, bottom }, x, y) => {
    return left > x || x > right || top > y || y > bottom;
  };

  resetCorners = () => {
    this.TopLeftSelected = false;
    this.TopRightSelected = false;
    this.BottomLeftSelected = false;
    this.BottomRightSelected = false;
  };

  checkBounds = (width, height) => {
    if (this.TopLeft.x < 0 || this.TopLeft.y < 0) return true;
    if (this.BottomLeft.x < 0 || this.BottomLeft.y > height) return true;
    if (this.TopRight.x > width || this.BottomRight.y < 0) {
      return true;
    }
    if (this.BottomRight.x > width || this.BottomRight.y > height) {
      return true;
    }
    return false;
  };

  checkMinimalDimension = () => {
    let detectedRatio = 0;
    const lineHorizLength = Math.sqrt(
      this.BottomLeft.x - this.BottomRight.x ** 2 + (this.BottomLeft.y - this.BottomRight.y ** 2),
    );
    const lineVertLength = Math.sqrt(
      this.BottomLeft.x - this.TopLeft.x ** 2 + (this.BottomLeft.y - this.TopLeft.y ** 2),
    );

    const isPortrait = lineVertLength > lineHorizLength;
    if (isPortrait) {
      detectedRatio = lineHorizLength / lineVertLength;
    } else {
      detectedRatio = lineVertLength / lineHorizLength;
    }
    let result;
    if (detectedRatio < 0.5 || detectedRatio > 0.7) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  isCornerSelected = () => {
    return this.TopLeftSelected || this.TopRightSelected || this.BottomLeftSelected || this.BottomRightSelected;
  };
}
