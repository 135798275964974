import api from 'api';
import classNames from 'classnames';
import NestedListHeader from 'components/Headings/NestedListHeading';
import ConfirmationPage from 'components/Modal/ConfirmationPage';
import InformationModal from 'components/Modal/InformationModal';
import Spinner from 'components/Spinner';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import utilities from 'utilities';
import enums from 'utilities/enums';
import commentTypes from 'utilities/enums/commentTypes';
import routesEnum from 'utilities/enums/routesEnum';
import IconManager from 'utilities/services/IconManager';
import { CaseContext } from 'utilities/services/contexts';
import CommentSection from '../../../components/CommentSection';
import AMLFiltering from '../AMLFiltering';
import GeneralCaseInformation from '../GeneralCaseInformation';
import Listings from '../Listings';

import './styles.scss';

const MatchDetails = ({ className }) => {
  const { complianceLogId, hitId } = useParams();
  const classBase = 'ickyc-match-details';

  const classes = classNames('ickyc-kyc-page', classBase, className);
  const [matchInfo, setMatchInfo] = useState({ attachedTo: [] });

  const [isGlobalErrorModalOpen, setIsGlobalErrorModalOpen] = useState(false);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedAMLFilters, setSelectedAMLFilters] = useState();
  const [consolidatedMatchTypes, setConsolidatedMatchTypes] = useState([]);

  const [hitCounts, setHitCounts] = useState({});

  useEffect(() => {
    const extractCaseDetails = data => ({
      searchTerm: data.searchTerm,
      assignedTo: data.assignedTo,
      assignedToId: data.assignedToId,
      year: data.year,
      attachedTo: data.attachedTo || [],
      fuzziness: data.fuzziness,
      dateOfBirth: data.dateOfBirth,
      runBy: data.runBy,
      created: data.created,
      countries: data.countries || '',
      searchResult: data.searchResult,
      onGoing: data.ongoingMonitoring,
      caseStatus: data.caseStatus,
      entityType: data.entityType,
    });
    const getCaseDetails = () => {
      setIsLoading(true);
      api.kyc.caseManagement
        .getComplianceHitDetailsApi(complianceLogId, hitId)
        .then(response => {
          const { data } = response;
          setMatchInfo(extractCaseDetails(data));
          setSelectedAMLFilters(data.consolidatedMatchTypes);
          setConsolidatedMatchTypes(data.consolidatedMatchTypes);
          const {
            acceptedCounter,
            adverseMediaCounter,
            documentsCounter,
            listingsCounter,
            potentialCounter,
            rejectedCounter,
            unknownCounter,
          } = data;
          setHitCounts({
            acceptedCounter,
            adverseMediaCounter,
            documentsCounter,
            listingsCounter,
            potentialCounter,
            rejectedCounter,
            unknownCounter,
          });
        })
        .catch(error => {
          const {
            response: {
              data: { message },
              status,
            },
          } = error;

          if (message.includes(enums.API_ERROR_TYPES.CASE_DETAILS_NOT_FOUND) || status === 404)
            setIsGlobalErrorModalOpen(true);
        })
        .finally(() => setIsLoading(false));
    };
    getCaseDetails();
  }, [complianceLogId, hitId]);
  const selectOrUnselectFilterGroup = (filterVal, codesOfTypes) => {
    const availableCodes = codesOfTypes.filter(code => consolidatedMatchTypes.includes(code));
    availableCodes.push(filterVal);
    setSelectedAMLFilters(prev =>
      selectedAMLFilters.includes(filterVal)
        ? selectedAMLFilters.filter(aml => !availableCodes.includes(aml))
        : [...prev, ...availableCodes],
    );
  };

  const columns = {
    firstGroup: [0, 1, 2],
    secondGroup: [4, 5, 6, 7],
    thirdGroup: [9, 10, 11, 12, 13, 14, 15],
  };

  const findElementsGroup = filterEl => {
    if (columns.firstGroup.includes(filterEl)) return { group: columns.firstGroup, mainEl: 16 };
    if (columns.secondGroup.includes(filterEl)) return { group: columns.secondGroup, mainEl: 3 };
    return { group: columns.thirdGroup, mainEl: 8 };
  };

  const handleFilterSelect = filter => {
    if (filter === 16) {
      selectOrUnselectFilterGroup(filter, columns.firstGroup);
    } else if (filter === 3) {
      selectOrUnselectFilterGroup(filter, columns.secondGroup);
    } else if (filter === 8) {
      selectOrUnselectFilterGroup(filter, columns.thirdGroup);
    } else {
      const { group: codesOfTypes, mainEl } = findElementsGroup(filter);
      const availableCodes = codesOfTypes.filter(code => consolidatedMatchTypes.includes(code));

      if (
        !selectedAMLFilters.includes(filter) &&
        availableCodes.filter(elm => elm !== filter).every(el => selectedAMLFilters.includes(el))
      ) {
        setSelectedAMLFilters(prev =>
          consolidatedMatchTypes.includes(mainEl) ? [...prev, filter, mainEl] : [...prev, filter],
        );
      } else if (!selectedAMLFilters.includes(filter)) {
        setSelectedAMLFilters(prev => [...prev, filter]);
      } else {
        setSelectedAMLFilters(prev => prev.filter(aml => aml !== filter && aml !== mainEl));
      }
    }
  };
  const navigateToCaseDetails = useMemo(() => {
    return utilities.routeParams(routesEnum.kyc.CASE_DETAIL, {
      id: complianceLogId,
    });
  }, [complianceLogId]);

  const navigateToCaseManagement = useCallback(() => history.push(routesEnum.kyc.CASE_MANAGEMENT), [history]);
  const headingListItems = useMemo(() => {
    return [
      <>
        <b>Case:</b>
        <NavLink to={navigateToCaseDetails} className="ickyc-match-details__link">
          {matchInfo.searchTerm}
        </NavLink>
      </>,
      {
        items: [
          <>
            <b>Match:</b> &nbsp;
            <span>{matchInfo.searchResult}</span>
          </>,
        ],
      },
    ];
  }, [matchInfo, navigateToCaseDetails]);

  const handleConfirmationPageClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const commentsPaarams = useMemo(() => ({ complianceLogId, hitId }), [complianceLogId, hitId]);

  if (isLoading) {
    return (
      <div className={classes}>
        <Spinner />
      </div>
    );
  }

  return (
    <CaseContext.Provider
      value={{ complianceLogId, caseStatus: matchInfo?.caseStatus, isCaseClosed: matchInfo?.caseStatus === 'Closed' }}
    >
      <div className={classes}>
        {isGlobalErrorModalOpen ? (
          <InformationModal hideModal={() => setIsGlobalErrorModalOpen(false)} onClick={handleConfirmationPageClick}>
            <ConfirmationPage
              title="Oops!"
              subtitle="Something went wrong…"
              text={
                <>
                  We’re working on it, and we’ll get it fixed as soon as we can.
                  <br /> You may refresh the page, or try again later.
                </>
              }
              icons={<div>{IconManager.get(IconManager.names.GLOBAL_ERROR)}</div>}
            />
          </InformationModal>
        ) : (
          <>
            <NestedListHeader title="Match Details" onClick={navigateToCaseManagement} items={headingListItems} />

            <GeneralCaseInformation info={matchInfo} matchDetails disabled={matchInfo?.caseStatus} />
            <AMLFiltering
              onFilterSelect={handleFilterSelect}
              selectedAMLFilters={selectedAMLFilters}
              consolidatedMatchTypes={consolidatedMatchTypes}
              requestedAMLFilters={consolidatedMatchTypes}
              title="Listing Filtering"
            />
            {selectedAMLFilters ? (
              <Listings
                selectedAMLFilters={selectedAMLFilters}
                complianceLogId={complianceLogId}
                hitId={hitId}
                hitCounts={hitCounts}
                updateHitCounts={setHitCounts}
              />
            ) : (
              <Spinner />
            )}
            <CommentSection
              newCommentButton
              apiGetComments={api.comments.getMatchComments}
              apiGetReplies={api.comments.getReplies}
              apiDeleteComment={api.comments.removeComment}
              apiEditComment={api.comments.editComment}
              apiCreateComment={api.comments.createNewComment}
              apiCreateReply={api.comments.createNewReply}
              isCollapsible={false}
              additionalRequestParams={commentsPaarams}
              type={commentTypes.SCREENING}
            />
          </>
        )}
      </div>
    </CaseContext.Provider>
  );
};

MatchDetails.propTypes = {
  className: PropTypes.string,
};

MatchDetails.defaultProps = {
  className: undefined,
};

export default MatchDetails;
