import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import prepareItems from 'scenes/Kyc/EntityManagement/components/AdditionalDocumentsForm/utils';
import toastr from 'toastr';
import api from '../../../../../../api';
import Accordion from '../../../../../../components/Accordion';
import OutlineButton from '../../../../../../components/Buttons/OutlineButton';
import IComplyForm from '../../../../../../components/Form/IComplyForm';
import Table from '../../../../../../components/Tables/Table';
import TableHeader from '../../../../../../components/Tables/Table/components/TableHeader';
import useModalHandler from '../../../../../../hooks/useModalHandler';
import useTable from '../../../../../../hooks/useTable';
import useViewRelatedEventsListener from '../../../../../../hooks/useViewRelatedEventsListener';
import bus from '../../../../../../modules/bus';
import enums from '../../../../../../utilities/enums';
import TableHeaders from '../../../../../../utilities/services/TableHeaders';
import { EntityContext, PermissionGroupContext } from '../../../../../../utilities/services/contexts';
import AdditionalDocumentsForm from '../../../components/AdditionalDocumentsForm';
import AddressForm from './components/AddressForm';
import AddressTableRow from './components/AddressTableRow';
import './styles.scss';
import utils from './utils';

const AddressSection = ({ badge }) => {
  const { isOpen: editMode, open: openEditForm, close: closeEditFOorm } = useModalHandler();

  const containerRef = useRef();
  const { entityId } = useContext(EntityContext);

  const {
    isLoading,
    removeRow,
    records,
    changeParams,
    pagination,
    params,
    overrideTable,
    addRow,
    updateRowData,
  } = useTable(api.kyc.entityManagement.legalEntity.getAddressesTable, {
    entityId,
  });

  useViewRelatedEventsListener(enums.BUS_EVENTS.OVERRIDE_ADDRESS_TABLE, overrideTable, containerRef);

  const handleSave = async values => {
    const { attachSupportingPackages, addressInformation, documentEvent, items, status: eventStatus } = values;
    const { answersDTO, itemsDTO } = prepareItems(items);
    const dataToSubmit = {};
    if (attachSupportingPackages) {
      dataToSubmit.documentEvent = documentEvent;
      dataToSubmit.items = itemsDTO;
      dataToSubmit.answers = answersDTO;
    }
    dataToSubmit.addressInformation = addressInformation;
    dataToSubmit.status = eventStatus;
    return api.kyc.entityManagement.legalEntity
      .createAddressTableRecord(entityId, dataToSubmit)
      .then(response => {
        const { adddressInfo: newAddress, commentDto, documentInfo } = response.data;
        if (newAddress.addressTypes.includes('headquarter')) {
          overrideTable([
            newAddress,
            ...records.map(address => utils.removeHeadquarterAndAddAdditionalAddressIfNeeded(address)),
          ]);
        } else {
          addRow(newAddress);
        }
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.ADDRESS,
          issuesChange: newAddress?.issues,
        });
        if (documentInfo.eventHistoryId) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_DOCUMENTS_RECORD, documentInfo);
        }
        if (commentDto) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, commentDto);
        }
        bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, commentDto);
        toastr.success('Successfully Added Address Information');
        closeEditFOorm();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return {
              [FORM_ERROR]: errorData.error || `${errorData?.message?.slice(0, 80)}...`,
            };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else
          return {
            [FORM_ERROR]: 'Error occured while trying to add new Address',
          };
      });
  };
  const { create: canCreate, delete: canDelete } = useContext(PermissionGroupContext);

  const TableControls = () => {
    return (
      <>
        <h3>Locations</h3>
        <div>{canCreate && <OutlineButton onClick={openEditForm}>+ Add Address</OutlineButton>}</div>
      </>
    );
  };

  const mappedStatuses = useMemo(() => {
    return Object.entries(enums.HISTORY_EVENT_STATUSES_REDUCED).map(status => ({
      id: status[0],
      label: status[1],
      value: status[1],
    }));
  }, []);

  const handleHistoryRecordDelete = useCallback(
    eventId => {
      removeRow('eventId', eventId);
    },
    [removeRow],
  );

  useEffect(() => {
    const updateHistoryEventStatus = ({ eventHistoryId, data: newData }) => {
      updateRowData('eventId', eventHistoryId, newData);
    };
    bus.addEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    };
  }, [updateRowData]);

  return (
    <Accordion title="Addresses" badge={badge} withBadge accented accordionindex={enums.ACCORDION_INDEXES.ADDRESS}>
      <div ref={containerRef}>
        {editMode && (
          <IComplyForm
            initialValues={{ attachSupportingPackages: true }}
            onSubmit={handleSave}
            onDiscardClick={closeEditFOorm}
            showControls={editMode}
          >
            <AddressForm responsive />
            <AdditionalDocumentsForm />
          </IComplyForm>
        )}

        <Table
          tableControls={TableControls}
          headerRow={TableHeader}
          tableRow={AddressTableRow}
          values={records}
          handleParamsChange={changeParams}
          pagination={pagination}
          withPagination
          updating={isLoading}
          onRemove={handleHistoryRecordDelete}
          headerData={{
            sortCriteria: params.sortCriteria,
            sortOrder: params.sortOrder,
            hasSourceColumn: true,
            onClick: changeParams,
            items: TableHeaders.AddressTable,
            withDelete: canDelete,
          }}
          className="ickyc-address-table"
        />
      </div>
    </Accordion>
  );
};

AddressSection.propTypes = {
  badge: PropTypes.number,
};
AddressSection.defaultProps = {
  badge: 0,
};

export default AddressSection;
