import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import CountrySelectFilterContentToggleList from '../../../../../components/CountrySelect/components/CountrySelectFilterContentToggleList';
import CountrySelectField from '../../../../../components/CountrySelectField';
import FormModal from '../../../../../components/Modal/FormModal';
import SelectFilterContentToggleList from '../../../../../components/Select/components/SelectFilterContentToggleList';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../components/SelectField';
import authEnums from '../../../../../utilities/enums/authEnums';
import DateManager from '../../../../../utilities/services/DateManager';
import DateFromUntilPicker from '../../../../Kyc/components/DateFromUntilPicker';
import FilterItem from '../../../../Kyc/components/FilterItem';
import './styles.scss';

const BusinessFilterModal = ({ hideModal, onFilterSave, initialFilters }) => {
  const applyFilters = async values => {
    const { countriesFlag, fromDate, toDate, userStatusesFlag, dateFlag } = values;
    const otherValidation = dateFlag || countriesFlag || userStatusesFlag;

    if (!otherValidation) {
      return {
        [FORM_ERROR]: 'No Filters Selected ',
      };
    }
    if (DateManager.compareDates(fromDate, toDate)) {
      return {
        [FORM_ERROR]: 'Date From Must Be Before Date Until ',
      };
    }
    onFilterSave(values);
    hideModal();
  };

  return (
    <FormModal
      onSubmit={applyFilters}
      title="Filter"
      initialValues={initialFilters}
      hideModal={hideModal}
      className={classNames('ickyc-filter-modal', 'ickyc-business-filter-modal')}
      renderAsPortal={false}
    >
      <div className={classNames('ickyc-business-filter-form')}>
        <Field component={FilterItem} label="Date" name="dateFlag">
          <DateFromUntilPicker dateFromName="fromDate" dateUntilName="toDate" />
        </Field>
        <Field component={FilterItem} label="Country" name="countriesFlag">
          <Field
            component={CountrySelectField}
            multipleSelection
            name="countryIds"
            Trigger={<SelectTrigger placeholder="- Choose Countries -" />}
            Content={<CountrySelectFilterContentToggleList />}
          />
        </Field>
        <Field component={FilterItem} label="Status" name="userStatusesFlag">
          <Field
            component={SelectField}
            multipleSelection
            name="statuses"
            options={authEnums.USER_STATUS}
            Content={<SelectFilterContentToggleList />}
            Trigger={<SelectTrigger placeholder="- Choose Status -" />}
          />
        </Field>
      </div>
    </FormModal>
  );
};
BusinessFilterModal.propTypes = {
  hideModal: PropTypes.func,
  onFilterSave: PropTypes.func,
  initialFilters: PropTypes.shape({}),
};
BusinessFilterModal.defaultProps = {
  initialFilters: {
    dateFlag: false,
    userStatusesFlag: false,
    countriesFlag: false,
    countryIds: [],
    statuses: [],
  },
  hideModal: () => {},
  onFilterSave: () => {},
};
export default BusinessFilterModal;
