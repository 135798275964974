import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import List from '../../../../../components/List';
import Section from '../../../../../components/Section';
import FieldCountriesList from '../../../../../components/Tables/FieldCountriesList';
import TextareaField from '../../../../../components/TextareaField';
import MinimumAgeControls from '../../../components/MinimumAgeControls';
import MinimumAgeRow from '../../../components/MinimumAgeRow';
import './styles.scss';

const RestrictionsSection = ({ disabled, messageFieldName, instructionFieldName, tableFieldPreffix }) => {
  return (
    <Section className="ickyc-portal-restrictions" title="Accept, Reject or Capture by Jurisdiction">
      <p>
        You are able to automatically perform actions based on the jurisdiction and date of birth entered by your user.
        For each jurisdiction, you can choose to
        <strong> accept</strong>,<strong> reject</strong> or
        <strong> capture</strong> user information, and<strong> set a minimum age</strong> required.
      </p>
      <List
        options={[
          {
            title: 'Accept',
            text:
              'Users from this jurisdiction will be accepted through the onboarding process, ' +
              'and their data will be submitted for identity verification.',
            icon: 'check',
          },
          {
            title: 'Reject',
            text:
              'Users from this jurisdiction will be kindly informed ' +
              'that they are not eligible to complete onboarding.',
            icon: 'close',
          },
          {
            title: 'Capture',
            text:
              'Users from this jurisdiction will be accepted through the onboarding ' +
              'process, but their data will not be submitted for identity verification.',
            icon: 'archive',
          },
        ]}
      />
      {instructionFieldName && (
        <Field
          component={TextareaField}
          name={instructionFieldName}
          label="In case something goes wrong, show this message with instructions: "
          placeholder="Type the message"
          disabled={disabled}
        />
      )}
      <Field
        component={TextareaField}
        name={messageFieldName}
        label="If users are not eligible to complete onboarding, display the message: "
        placeholder="Type the message"
        disabled={disabled}
      />
      <FieldCountriesList
        modulePreffix={tableFieldPreffix}
        TableControlsComponent={MinimumAgeControls}
        tableRow={MinimumAgeRow}
        withInfiniteScroll
        disabled={disabled}
      />
    </Section>
  );
};
RestrictionsSection.propTypes = {
  disabled: PropTypes.bool,
  messageFieldName: PropTypes.string.isRequired,
  tableFieldPreffix: PropTypes.string.isRequired,
  instructionFieldName: PropTypes.string,
};
RestrictionsSection.defaultProps = {
  disabled: false,
  instructionFieldName: null,
};
export default RestrictionsSection;
