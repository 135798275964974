/* eslint-disable jsx-a11y/no-autofocus */
import classNames from 'classnames';
import React, { useContext } from 'react';
import { SelectContext } from '../..';
import Toggle from '../../../Toggle';
import './styles.scss';

const SelectFilterContentToggleList = () => {
  const {
    hoverIndex,
    multipleSelection,
    onChange,
    value,
    filterProps: { inputProps, suggestions },
  } = useContext(SelectContext);

  return (
    <div className="ickyc-select-content-toggle-list">
      <input {...inputProps} autoFocus />
      <ul>
        {suggestions.length > 0 ? (
          suggestions.map((suggestion, index) => {
            const isSelected = multipleSelection
              ? value.some(val => val.value === suggestion.value)
              : value.value === suggestion.value;

            const classes = classNames({
              'ickyc-toggle-list-item': true,
              'ickyc-toggle-list-item--hovered': hoverIndex === index,
            });
            return (
              <li className={classes}>
                <Toggle
                  value={isSelected}
                  onChange={() => {
                    onChange(suggestion);
                  }}
                  label={suggestion.label}
                />
              </li>
            );
          })
        ) : (
          <li>No Results</li>
        )}
      </ul>
    </div>
  );
};
SelectFilterContentToggleList.propTypes = {};
SelectFilterContentToggleList.defaultProps = {};
export default SelectFilterContentToggleList;
