import React from 'react';

export default () => (
  <svg viewBox="0 0 137 107.5" fill="currentColor">
    <g>
      <path
        d="M127.1,64.5L112,49.4v10.1H99.6V36.4c0-8.2-6.7-14.9-14.9-14.9h-6v10h6c2.7,0,5,2.2,5,5v56.2c0,2.7-2.2,5-5,5
		H55.9c-2.6,0-4.7-2-4.9-4.5H41c0.2,8,6.8,14.5,14.9,14.5h28.7c8.2,0,14.9-6.7,14.9-14.9V69.5H112v10.1L127.1,64.5z"
      />
      <path
        d="M48.4,75.7H28.2c-2.7,0-5-2.2-5-5V14.5c0-2.7,2.2-5,5-5h28.7c2.7,0,5,2.2,5,5v40.6l10,10V14.5
		c0-8.2-6.7-14.9-14.9-14.9H28.2c-8.2,0-14.9,6.7-14.9,14.9v56.2c0,8.2,6.7,14.9,14.9,14.9h28.7c0.5,0,0.9,0,1.4-0.1L48.4,75.7z"
      />
      <path
        d="M77.5,77.6c-2.4-2.4-6-2.8-8.8-1.2L53.3,60.9c1.6-2.8,1.2-6.4-1.2-8.8c-2.9-2.9-7.5-2.9-10.4,0
		c-2.9,2.9-2.9,7.5,0,10.4c2.4,2.4,6,2.8,8.8,1.2L66,79.2c-1.6,2.8-1.2,6.4,1.2,8.8c2.9,2.9,7.5,2.9,10.4,0
		C80.4,85.1,80.4,80.5,77.5,77.6z"
      />
    </g>
  </svg>
);
