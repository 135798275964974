import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import api from '../../../api';
import useGroupPermissions from '../../../hooks/useGroupPermissions';
import authEnums from '../../../utilities/enums/authEnums';
import { UserManagementContext } from '../../../utilities/services/contexts';
import TableHeaders from '../../../utilities/services/TableHeaders';
import UserManagementPage from '../../Administration/UserManagementPage';
import IComplyEditUserModal from './components/IComplyEditUserModal';
import IComplyUserRow from './components/IComplyEditUserModal/components/IComplyUserRow';

const IComplyTeam = ({ className }) => {
  const classes = classNames('ickyc-page', 'ickyc-icomply-team', className);

  const groupPermissions = useGroupPermissions(authEnums.PERMISSION_GROUP.ICOMPLY_TEAM);
  return (
    <div className={classes}>
      <UserManagementContext.Provider
        value={{
          updateUserData: api.internal.iComplyTeam.updateUser,
          createUser: api.internal.iComplyTeam.createUser,
          getAllUsers: api.internal.iComplyTeam.getAllUsers,
          downloadReportCSV: api.internal.iComplyTeam.downloadCSV,
          fetchUser: api.internal.iComplyTeam.getUser,
          deleteUser: api.internal.iComplyTeam.deleteUser,
          withTitle: true,
          withPassword: true,
          clientId: null,
          UserModal: IComplyEditUserModal,
          groupPermissions,
          titleText: 'iComply Users Management',
          TableRow: IComplyUserRow,
          CustomTableHeader: TableHeaders.UsersTableHeader,
          iComplyTeam: true,
        }}
      >
        <UserManagementPage />
      </UserManagementContext.Provider>
    </div>
  );
};
IComplyTeam.propTypes = {
  className: PropTypes.string,
};

IComplyTeam.defaultProps = {
  className: undefined,
};
export default IComplyTeam;
