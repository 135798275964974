import React, { useContext, useEffect, useState } from 'react';
import NestedMenuList from '../../../../../components/NestedMenuList';
import usePathname from '../../../../../hooks/usePathname';
import routesEnum from '../../../../../utilities/enums/routesEnum';
import { NavigationContext } from '../../../../../utilities/services/contexts';
import './styles.scss';

/**
 * List of existing pages displayed on screens wider than 1024px.
 */

const NavigationList = () => {
  const pathname = usePathname();
  const [pageGroup, setPageGroup] = useState(routesEnum.pages.ADMINISTRATION);
  const [activePages, setActivePages] = useState([]);
  const { hideModal, administrationList, kycList, prefactoList, accountSettingsList, businessList } = useContext(
    NavigationContext,
  );

  useEffect(() => {
    setPageGroup(pathname.split('/')[1]);
  }, [pathname, setPageGroup]);

  useEffect(() => {
    const groupMap = new Map([
      [routesEnum.pages.ADMINISTRATION, administrationList],
      [routesEnum.pages.KYC, kycList],
      [routesEnum.pages.PREFACTO, prefactoList],
      [routesEnum.pages.ACCOUNT_SETTINGS, accountSettingsList],
      [routesEnum.pages.INTERNAL, businessList],
    ]);

    const active = groupMap.get(pageGroup);
    active ? setActivePages(active) : setActivePages([]);
  }, [pageGroup, administrationList, kycList, prefactoList, accountSettingsList, pathname, businessList]);

  return <NestedMenuList items={activePages} hideModal={hideModal} />;
};

export default NavigationList;
