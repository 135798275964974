import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

const Loader = () => (
  <div className="ickyc-loader">
    <PulseLoader size={20} color="#02ce94" />
  </div>
);

export default Loader;
