/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import api from '../../api';
import SelectFilterContent from '../Select/components/SelectFilterContent';
import SelectTrigger from '../Select/components/SelectTrigger';
import SelectField from '../SelectField';

const StateProvinceSelectField = ({ countryId, placeholder, ...rest }) => {
  const [states, setStates] = useState([]);
  const [fetchingStates, setFetchingStates] = useState(false);
  useEffect(() => {
    const fetchStatesProvinces = () => {
      setFetchingStates(true);
      api.utilities
        .getStateProvincesByCountryIdApi(countryId)
        .then(({ data }) => {
          setStates(
            data.map(({ id, name: stateName }) => ({
              label: stateName,
              id,
              value: id,
            })),
          );
        })
        .finally(() => {
          setFetchingStates(false);
        });
    };
    if (countryId) {
      fetchStatesProvinces();
    }
  }, [setStates, countryId]);

  return (
    <SelectField
      {...rest}
      options={states}
      loading={fetchingStates}
      Trigger={<SelectTrigger placeholder={placeholder} />}
      Content={<SelectFilterContent />}
    />
  );
};

StateProvinceSelectField.propTypes = {
  countryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
};

StateProvinceSelectField.defaultProps = {
  countryId: undefined,
  placeholder: undefined,
};

export default StateProvinceSelectField;
