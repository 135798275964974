import MailOutlineIcon from '@material-ui/icons/MailOutlineOutlined';
import api from 'api';
import Accordion from 'components/Accordion';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import ConfirmationPage from 'components/Modal/ConfirmationPage';
import InformationModal from 'components/Modal/InformationModal';
import Table from 'components/Tables/Table';
import Tooltip from 'components/Tooltip';
import useModalHandler from 'hooks/useModalHandler';
import useTable from 'hooks/useTable';
import useViewRelatedEventsListener from 'hooks/useViewRelatedEventsListener';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import enums from 'utilities/enums';
import tooltips from 'utilities/enums/tooltips';
import IconManager from 'utilities/services/IconManager';
import { EntityContext, PermissionGroupContext } from 'utilities/services/contexts';
import HistoryRecordTableHeader from '../../../components/HistoryRecordTableHeader';
import RequestClientUpdateModal from '../../../components/RequestClientUpdateNaturalPersonModal';
import IdentityTableRow from './components/IdentityTableRow';
import ManualDocumentUpload from './components/ManualDocumentUpload';

function IdentityDocumentsSection({ badge }) {
  const { entityId } = useContext(EntityContext);
  const { isOpen, close: closeConfirmationModal, open: openConfirmationModal } = useModalHandler();

  const containerRef = useRef(null);

  const { isOpen: rcuOpen, open: openRCU, close: closeRCU } = useModalHandler();
  const { isOpen: documentUploadOpen, open: openDocumentUpload, close: closeDocumentUpload } = useModalHandler();

  const handleDocumentModalClose = openConfirm => {
    closeDocumentUpload();
    if (!!openConfirm === openConfirm) {
      openConfirmationModal();
    }
  };
  const {
    isLoading,
    removeRow,
    records,
    changeParams,
    pagination,
    params,
    overrideTable,
    updateRowData,
    addRow,
  } = useTable(api.kyc.entityManagement.naturalPerson.getIdentityDocumentsTable, { entityId });

  useViewRelatedEventsListener(enums.BUS_EVENTS.OVERRIDE_IDENTITY_TABLE, overrideTable, containerRef);

  const handleHistoryRecordDelete = useCallback(
    eventId => {
      removeRow('eventHistoryId', eventId);
    },
    [removeRow],
  );

  useEffect(() => {
    const updateHistoryEventStatus = ({ eventHistoryId, data }) => {
      updateRowData('eventHistoryId', eventHistoryId, data);
    };
    bus.addEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    };
  }, [updateRowData]);

  useEffect(() => {
    const onNewRecordEvent = newRecord => {
      addRow(newRecord);
    };
    bus.addEventListener(enums.BUS_EVENTS.NEW_IDENTITY_RECORD, onNewRecordEvent);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.NEW_IDENTITY_RECORD, onNewRecordEvent);
    };
  }, [addRow]);

  const { create: canCreate, requestClientUpdate: canRCU, delete: canDelete } = useContext(PermissionGroupContext);
  const TableControls = () => {
    return (
      <>
        <h3>History</h3>
        <div>
          {canCreate && (
            <Tooltip
              placement="top"
              trigger={
                <div>
                  <OutlineButton onClick={openDocumentUpload}>+ Add Documents Package</OutlineButton>
                </div>
              }
              content={<span>Adding a document manually will NOT trigger any KYC services.</span>}
            />
          )}
          {canRCU && <PrimaryButton onClick={openRCU}> Request Client Update</PrimaryButton>}
        </div>
      </>
    );
  };

  return (
    <Accordion
      title="Identity Documents"
      withBadge
      badge={badge}
      accordionOpen={false}
      accented
      accordionindex={enums.ACCORDION_INDEXES.IDENTITY_DOCUMENTS}
      hint={tooltips.ISSUES}
    >
      <div ref={containerRef}>
        {documentUploadOpen && <ManualDocumentUpload hideModal={handleDocumentModalClose} />}
        {isOpen && (
          <InformationModal hideModal={closeConfirmationModal}>
            <ConfirmationPage
              icon={IconManager.get(IconManager.names.NEWS)}
              title="Document Verification"
              onClick={closeConfirmationModal}
              icons={<MailOutlineIcon />}
              text={
                <>
                  We are verifying your document <br />
                  We’ll send you an email when the process is complete.
                </>
              }
            />
          </InformationModal>
        )}
        {rcuOpen && (
          <RequestClientUpdateModal documentAuthenticationEnabled hideModal={closeRCU} containerRef={containerRef} />
        )}
        <Table
          tableControls={TableControls}
          headerRow={HistoryRecordTableHeader}
          tableRow={IdentityTableRow}
          values={records}
          handleParamsChange={changeParams}
          pagination={pagination}
          withPagination
          updating={isLoading}
          onRemove={handleHistoryRecordDelete}
          headerData={{
            sortCriteria: params.sortCriteria,
            onClick: changeParams,
            sortOrder: params.sortOrder,
            withDelete: canDelete,
            hasServicesColumn: true,
          }}
          className="ickyc-history-record-table"
          containerRef={containerRef}
        />
      </div>
    </Accordion>
  );
}

IdentityDocumentsSection.propTypes = {
  badge: PropTypes.number,
};

IdentityDocumentsSection.defaultProps = {
  badge: 0,
};

export default IdentityDocumentsSection;
