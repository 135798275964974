import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fill: 'none',
      stroke: '#2f6fbc',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeMiterlimit: 10,
      strokeWidth: '4px',
    }}
    width="98.035"
    height="129.381"
  >
    <path d="M25.509 25.509c0 20.405 10.529 36.945 23.509 36.945s23.513-16.54 23.513-36.945a23.511 23.511 0 00-47.022 0z" />
    <path d="M49.018 2a23.509 23.509 0 00-23.509 23.509 56.719 56.719 0 00.329 5.877l8.99-11.756a20.06 20.06 0 0028.373 0l9 11.754a58.078 58.078 0 00.324-5.877A23.509 23.509 0 0049.018 2zM44.479 80.574l-8.3-10.378v-.006c.136-.119.293-.212.417-.34a11 11 0 003.23-7.8v-2.534a15.843 15.843 0 0018.387 0v2.53a11.03 11.03 0 003.224 7.8c.13.129.287.227.422.346l-8.3 10.378z" />
    <path d="M61.853 70.195a11.174 11.174 0 003.167 2.045l31.014 12.842v30.543H58.812l-5.258-35.052zM36.177 70.189a11.061 11.061 0 01-3.167 2.052L2 85.087v30.538h37.222s5.26-35.051 5.257-35.051l-8.3-10.378z" />
    <path d="M53.554 80.574h-9.073l-5.261 35.051 9.8 11.754 9.8-11.754z" />
  </svg>
);
