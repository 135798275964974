import utilities from '..';
import axiosInstance from '../../api/axiosInstance';
import Line from '../models/Line';
import Quadruple from '../models/Quadruple';

const DEST_URL = `${process.env.REACT_APP_OPECV_XAAR}/assets/js/`;

class OpenCV {
  classifier = null;

  constructor() {
    utilities.loadScript('opencvLib', `${DEST_URL}opencv.js`).then(() => {
      if (window?.cv) {
        window.cv.onRuntimeInitialized = () => {
          axiosInstance.get(`${DEST_URL}haarcascade_frontalface_default.xml`).then(({ data: response }) => {
            window.cv.FS_createDataFile('/', `haarcascade_frontalface_default.xml`, response, true, false, false);
          });
        };
      }
    });
  }

  /**
   *
   * @param {CanvasElement} src
   */
  detectFace = src => {
    this.classifier = new window.cv.CascadeClassifier();
    this.classifier.load(`haarcascade_frontalface_default.xml`);
    let rotations = 0;
    let faceDetected = false;
    let faceRect;
    const srcMat = window.cv.imread(src);
    window.cv.cvtColor(srcMat, srcMat, window.cv.COLOR_BGRA2GRAY, 0);
    // DETECTING ON ORIGINAL IMAGE
    const { facesCount, facesRects } = this.detectFaces(srcMat);

    // IF DETECTION FAILED ROTATE & TRY AGAIN
    if (facesCount === 0) {
      while (!faceDetected && rotations <= 3) {
        const rotatedImage = srcMat.clone();
        const angle = rotations * 90;
        for (let k = 90; k <= angle; k += 90) {
          window.cv.rotate(rotatedImage, rotatedImage, window.cv.ROTATE_90_CLOCKWISE);
        }
        rotations += 1;
        const { facesCount: rotatedImageFaceCount, facesRects: rotatedImagefacesRects } = this.detectFaces(
          rotatedImage,
        );
        // we found a face, we can break out of the rotation loop
        // eslint-disable-next-line no-loop-func
        if (rotatedImageFaceCount > 0) {
          const sortedFacesRects = rotatedImagefacesRects.sort((a, b) => {
            return a.width * a.height > b.width * b.height ? 1 : -1;
          });
          rotations -= 1;
          [faceRect] = sortedFacesRects;

          const point1 = new window.cv.Point(faceRect.x, faceRect.y);
          const point2 = new window.cv.Point(faceRect.x + faceRect.width, faceRect.y + faceRect.height);
          window.cv.rectangle(srcMat, point1, point2, [255, 0, 0, 255]);

          faceDetected = true;
          rotatedImage.delete();
        }
      }
    } else {
      const sortedFacesRects = facesRects.sort((a, b) => {
        return a.width * a.height > b.width * b.height ? -1 : 1;
      });
      [faceRect] = sortedFacesRects;

      const point1 = new window.cv.Point(faceRect.x, faceRect.y);
      const point2 = new window.cv.Point(faceRect.x + faceRect.width, faceRect.y + faceRect.height);
      window.cv.rectangle(srcMat, point1, point2, [255, 0, 0, 255]);

      faceDetected = true;
    }

    srcMat.delete();
    this.classifier.delete();
    return {
      angle: rotations * 90,
      faceRect,
      faceDetected,
    };
  };

  detectFaces = imageMat => {
    const faces = new window.cv.RectVector();
    this.classifier.detectMultiScale(imageMat, faces, 1.3, 5, 0);
    const facesCount = faces.size();
    const facesRects = [];
    for (let i = 0; i < faces.size(); i += 1) {
      facesRects.push(new window.cv.Rect(faces.get(i).x, faces.get(i).y, faces.get(i).width, faces.get(i).height));
    }
    faces.delete();
    return {
      facesCount,
      facesRects: facesRects || [],
    };
  };

  /**
   *
   * @param {CanvasElement} src
   * @param {number} angle
   */
  rotateImage = (src, angle) => {
    const sourceMat = window.cv.imread(src);
    for (let i = 90; i <= angle; i += 90) {
      window.cv.rotate(sourceMat, sourceMat, window.cv.ROTATE_90_CLOCKWISE);
    }
    window.cv.imshow(src, sourceMat);
    sourceMat.delete();
  };

  /**
   *
   * @param {CanvasElement} sourceImage
   * @param {Object} detectedFaceRect
   * @param {*} imageResizeRatio
   * @returns
   */
  detectMRZ = src => {
    let mrzDetected = false;
    let mrzMinRect = null;

    const sourceImageMat = window.cv.imread(src);
    const smallImage = sourceImageMat.clone();
    window.cv.cvtColor(smallImage, smallImage, window.cv.COLOR_BGR2GRAY, 0);

    const gradX = new window.cv.Mat();
    const gradY = new window.cv.Mat();
    const absGradX = new window.cv.Mat();
    const blackhat = new window.cv.Mat();
    const thresh = new window.cv.Mat();

    // small image will somethimes pass , might want to try different sizes
    let kernHt = sourceImageMat.rows / 23;
    if (kernHt % 2 !== 0) kernHt += 1;

    // Detect the MRZ  of the photo now that we have the orientation
    // initialize a rectangular and sqaure structuring kernel

    const sqkerneSizes = [21, 19, 16];
    let counter = 0;
    let rectKernel;
    let sqKernel;
    // delete

    while (!mrzDetected && counter < 3) {
      const rectKernelSize = {
        width: 13,
        height: 5,
      };
      rectKernel = window.cv.getStructuringElement(window.cv.MORPH_RECT, rectKernelSize);
      const sqKernelSize = {
        width: sqkerneSizes[counter],
        height: sqkerneSizes[counter],
      };

      sqKernel = window.cv.getStructuringElement(window.cv.MORPH_RECT, sqKernelSize);

      // Smooth the image using a 3x3 Gaussian, then apply the blackhat
      // morphological opertaot to find dark regions on a light background

      const gaussianSize = {
        width: 3,
        height: 3,
      };

      window.cv.GaussianBlur(smallImage, smallImage, gaussianSize, 0, 0, window.cv.BORDER_DEFAULT);

      window.cv.morphologyEx(smallImage, blackhat, window.cv.MORPH_BLACKHAT, rectKernel);

      // Compute the Scharr gradient of the blackhat image
      window.cv.Sobel(blackhat, gradX, window.cv.CV_32F, 1, 0, -1, 1, 0);

      const res = window.cv.minMaxLoc(gradX);
      const { minVal } = res;
      const { maxVal } = res;
      // Scale the result into the range [0, 255] and convert the array to CV_8U
      // gradX = (255 * ((gradX - minVal) / (maxVal - minVal))).astype("uint8")
      gradX.convertTo(gradX, window.cv.CV_8U, 255.0 / (maxVal - minVal), -255.0 / minVal);
      window.cv.convertScaleAbs(gradX, absGradX);

      // apply a closing operation using the rectangular kernel to close
      // gaps in between letters -- then apply Otsu's thresholding method
      // threshold(gradX, thresh, 0, 255, Imgproc.THRESH_BINARY | Imgproc.THRESH_OTSU);
      window.cv.morphologyEx(absGradX, gradX, window.cv.MORPH_CLOSE, rectKernel);
      window.cv.threshold(gradX, thresh, 0, 255, window.cv.THRESH_BINARY || window.cv.THRESH_OTSU);

      // perform another closing operation, this time using the square
      // kernel to close gaps between lines of the MRZ, then perform a
      // series of erosions to break apart connected components
      window.cv.morphologyEx(thresh, thresh, window.cv.MORPH_CLOSE, sqKernel);
      const M = window.cv.Mat.ones(5, 5, window.cv.CV_8U);
      window.cv.erode(thresh, thresh, M, new window.cv.Point(-1, -1), window.cv.BORDER_CONSTANT, 4);
      M.delete();
      // displayImage(toBufferedImage(thresh), "erode");
      // during thresholding, it's possible that border pixels were
      // included in the thresholding, so let's set 5% of the left and
      // right borders to zero
      // int pRows = (int)(smallImage.Rows * 0.05);
      // let pCols = smallImage.cols * 0.05;

      const pCols = smallImage.cols * 0.05;

      let fixBorder = thresh.clone();
      const roiRect = {
        x: pCols,
        y: 0,
        width: fixBorder.cols - 2 * pCols,
        height: fixBorder.rows,
      };
      fixBorder = fixBorder.roi(roiRect);

      const fixBorderClone = fixBorder.clone();

      window.cv.copyMakeBorder(fixBorderClone, fixBorder, 0, 0, pCols, pCols, window.cv.BORDER_CONSTANT, [
        0,
        0,
        0,
        255,
      ]);
      fixBorderClone.delete();

      const contours = new window.cv.MatVector();
      const hierarchy = new window.cv.Mat();
      window.cv.findContours(fixBorder, contours, hierarchy, window.cv.RETR_EXTERNAL, window.cv.CHAIN_APPROX_SIMPLE);
      let contourIndex = 0;
      while (contourIndex < contours.size() && !mrzDetected) {
        const contour = contours.get(contourIndex);
        // compute the bounding box of the contour and use the contour to
        // compute the aspect ratio and coverage ratio of the bounding box
        // compute MRZ width compared to the width of the image
        const bRect = window.cv.boundingRect(contour);

        const aspectRatio = bRect.width / bRect.height;
        const crWidth = bRect.width / thresh.cols;

        const boundingMinRect = window.cv.minAreaRect(contour);
        const minAspectRatio = boundingMinRect.width / boundingMinRect.height;

        // If the image is oriented in landscape format after face detection
        // change the mrz percentage of the image that we are looking for
        const isLandScape = smallImage.cols > smallImage.rows;
        const mrzPercentWidth = isLandScape ? 0.4 : 0.55;

        if ((aspectRatio > 8 || minAspectRatio > 8) && crWidth > mrzPercentWidth) {
          mrzMinRect = boundingMinRect;
          mrzDetected = true;
        }
        contourIndex += 1;
      }
      contours.delete();
      hierarchy.delete();
      fixBorder.delete();
      counter += 1;
    }
    let cropRect = null;
    if (mrzMinRect) {
      const mrzBoundingRect = window.cv.RotatedRect.boundingRect(mrzMinRect);
      let cropWidth = mrzBoundingRect.width * 1.2;
      let cropHeight = cropWidth * 0.72;

      const testX = (cropWidth - mrzBoundingRect.width) / 2;
      const testY = cropHeight * 0.1 + 15;

      let cropX = mrzBoundingRect.x - testX + 5;
      let cropY = mrzBoundingRect.y + mrzBoundingRect.height - cropHeight + testY; // cropHeight + 45;

      // Check that dimensions of the crop rect are not out of bounds of the image
      cropX = cropX < 0 ? 0 : cropX;
      cropY = cropY < 0 ? 0 : cropY;
      cropWidth = cropX + cropWidth > smallImage.cols ? smallImage.cols - cropX : cropWidth - 0;
      cropHeight = cropY + cropHeight > smallImage.rows ? smallImage.rows - cropY : cropHeight - 0;
      cropRect = {
        left: cropX,
        top: cropY,
        right: cropX + cropWidth,
        bottom: cropY + cropHeight,
      };
    }
    sourceImageMat.delete();
    smallImage.delete();
    gradX.delete();
    gradY.delete();
    absGradX.delete();
    blackhat.delete();
    thresh.delete();

    return {
      mrzDetected,
      mrzMinRect,
      cropRect,
    };
  };

  resizeImage(canvas, width, height) {
    const imageMat = window.cv.imread(canvas);
    window.cv.resize(
      imageMat,
      imageMat,
      {
        width,
        height,
      },
      0,
      0,
      window.cv.INTER_AREA,
    );
    window.cv.imshow(canvas, imageMat);
    imageMat.delete();
  }

  isFocusGoodEnough(src) {
    const sourceImageMat = window.cv.imread(src);
    window.cv.cvtColor(sourceImageMat, sourceImageMat, window.cv.COLOR_BGRA2GRAY, 0);

    window.cv.resize(
      sourceImageMat,
      sourceImageMat,
      { width: sourceImageMat.cols / 4, height: sourceImageMat.rows / 4 },
      0,
      0,
      window.cv.INTER_AREA,
    );

    const mu = new window.cv.Mat();
    const sigma = new window.cv.Mat();
    const lap = new window.cv.Mat();
    window.cv.Laplacian(sourceImageMat, sourceImageMat, window.cv.CV_64F);
    window.cv.meanStdDev(lap, mu, sigma);

    const mean = window.cv.mean(sigma)[0];
    const focusMeasure = mean * mean;
    mu.delete();
    sigma.delete();
    lap.delete();

    const resolution = src.width * src.height;
    const threshold = -103.71575521 * Math.log(resolution) + 1447.73395971;
    sourceImageMat.delete();

    return focusMeasure < threshold;
  }

  brightnessContrast = (src, brightness, contrast) => {
    const modifiedSrc = new window.cv.Mat();
    let alpha;
    let beta;
    let delta;

    if (contrast > 0) {
      delta = (127.0 * contrast) / 100.0;
      alpha = 255.0 / (255.0 - delta * 2);
      beta = alpha * (brightness - delta);
    } else {
      delta = (-128.0 * contrast) / 100.0;
      alpha = (256.0 - delta * 2) / 255.0;
      beta = alpha * brightness + delta;
    }

    src.convertTo(modifiedSrc, window.cv.CV_8UC3, alpha, beta);

    return modifiedSrc;
  };

  detectDocumentBounds = source => {
    let sourceMat = window.cv.imread(source);
    let boundingRect;
    window.cv.cvtColor(sourceMat, sourceMat, window.cv.COLOR_BGRA2GRAY);

    window.cv.normalize(sourceMat, sourceMat, 10, 255, window.cv.NORM_MINMAX);

    sourceMat = this.brightnessContrast(sourceMat, 0, 25);

    const newMat = new window.cv.Mat();
    window.cv.bilateralFilter(sourceMat, newMat, 11, 17, 17);
    sourceMat = newMat.clone();
    newMat.delete();

    window.cv.GaussianBlur(sourceMat, sourceMat, { width: 5, height: 5 }, 0, 0, window.cv.BORDER_DEFAULT);
    const reservedGrayImage = sourceMat.clone();

    window.cv.threshold(sourceMat, sourceMat, 0, 255, window.cv.THRESH_OTSU);

    window.cv.Canny(sourceMat, sourceMat, 50, 200);

    let cornerPoints = this.findDocumentBoundingRect(sourceMat);

    boundingRect = new Quadruple(
      cornerPoints[0] === -1 ? cornerPoints[1] : cornerPoints[0],
      cornerPoints[0] === -1 ? cornerPoints[2] : cornerPoints[1],
      cornerPoints[0] === -1 ? cornerPoints[3] : cornerPoints[2],
      cornerPoints[0] === -1 ? cornerPoints[4] : cornerPoints[3],
    );

    if (
      cornerPoints[0] === -1 ||
      boundingRect.checkBounds(sourceMat.cols, sourceMat.rows) ||
      Math.sqrt(cornerPoints[0].x - cornerPoints[1].x ** 2 + (cornerPoints[0].y - cornerPoints[1].y ** 2)) /
        Math.sqrt(cornerPoints[0].x - cornerPoints[3].x ** 2 + (cornerPoints[0].y - cornerPoints[3].y ** 2)) >
        0.85
    ) {
      window.cv.adaptiveThreshold(
        reservedGrayImage,
        reservedGrayImage,
        255,
        window.cv.ADAPTIVE_THRESH_GAUSSIAN_C,
        window.cv.THRESH_BINARY,
        3,
        2,
      );

      window.cv.Canny(reservedGrayImage, reservedGrayImage, 50, 200);

      cornerPoints = this.findDocumentBoundingRect(reservedGrayImage);

      boundingRect = new Quadruple(
        cornerPoints[0] === -1 ? cornerPoints[1] : cornerPoints[0],
        cornerPoints[0] === -1 ? cornerPoints[2] : cornerPoints[1],
        cornerPoints[0] === -1 ? cornerPoints[3] : cornerPoints[2],
        cornerPoints[0] === -1 ? cornerPoints[4] : cornerPoints[3],
      );
    }

    sourceMat.delete();

    reservedGrayImage.delete();
    return boundingRect;
  };

  findDocumentBoundingRect(srcMat) {
    const lines = new window.cv.Mat();
    const vertLines = [];
    const horizLines = [];
    window.cv.HoughLinesP(srcMat, lines, 1, Math.PI / 180, 100, 100, 50);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < lines.rows; ++i) {
      const delta = 100;
      if (Math.abs(lines.data32S[i * 4 + 2] - lines.data32S[i * 4]) < delta) {
        const point1 = { x: lines.data32S[i * 4], y: lines.data32S[i * 4 + 1] };
        const point2 = { x: lines.data32S[i * 4 + 2], y: lines.data32S[i * 4 + 3] };
        vertLines.push(new Line(point1, point2));
      } else if (Math.abs(lines.data32S[i * 4 + 3] - lines.data32S[i * 4 + 1]) < delta) {
        const point1 = { x: lines.data32S[i * 4], y: lines.data32S[i * 4 + 1] };
        const point2 = { x: lines.data32S[i * 4 + 2], y: lines.data32S[i * 4 + 3] };
        horizLines.push(new Line(point1, point2));
      }
    }
    lines.delete();

    const cornerPoints = [];

    let changeThresholdingFlag = false;

    if (vertLines.length < 2 || horizLines.length < 2) {
      changeThresholdingFlag = true;
    }

    while (vertLines.length < 2) {
      const x1 = Math.floor(Math.random() * srcMat.cols);
      const y1 = Math.floor(Math.random() * srcMat.rows);
      const y2 = Math.floor(Math.random() * srcMat.rows);
      const point1 = { x: x1, y: y1 };
      const point2 = { x: x1, y: y2 };
      vertLines.push(new Line(point1, point2));
    }

    while (horizLines.length < 2) {
      const x1 = Math.floor(Math.random() * srcMat.cols);
      const x2 = Math.floor(Math.random() * srcMat.cols);
      const y1 = Math.floor(Math.random() * srcMat.rows);
      const point1 = { x: x1, y: y1 };
      const point2 = { x: x2, y: y1 };
      horizLines.push(new Line(point1, point2));
    }
    vertLines.sort((a, b) => {
      return a.startPoint.x - b.startPoint.x;
    });
    horizLines.sort((a, b) => {
      return a.startPoint.y - b.startPoint.y;
    });

    if (vertLines.length > 2) {
      vertLines.splice(1, vertLines.length - 2);
    }

    if (horizLines.length > 2) {
      horizLines.splice(1, horizLines.length - 2);
    }

    for (let i = 0; i < horizLines.length; i += 1) {
      for (let j = 0; j < vertLines.length; j += 1) {
        const x1 = horizLines[i].startPoint.x;
        const y1 = horizLines[i].startPoint.y;
        const f1 = horizLines[i].endPoint.x - horizLines[i].startPoint.x;
        const g1 = horizLines[i].endPoint.y - horizLines[i].startPoint.y;
        const x2 = vertLines[j].startPoint.x;
        const y2 = vertLines[j].startPoint.y;
        const f2 = vertLines[j].endPoint.x - vertLines[j].startPoint.x;
        const g2 = vertLines[j].endPoint.y - vertLines[j].startPoint.y;

        const det = f2 * g1 - f1 * g2;

        if (!Math.abs(det) < 1e-9) {
          const dx = x2 - x1;
          const dy = y2 - y1;
          const t1 = (f2 * dy - g2 * dx) / det;
          cornerPoints.push({ x: x1 + f1 * t1, y: y1 + g1 * t1 });
        }
      }
    }

    if (changeThresholdingFlag) {
      cornerPoints.unshift(-1);
    }

    return cornerPoints;
  }
}

export default new OpenCV();
