import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import prepareItems from 'scenes/Kyc/EntityManagement/components/AdditionalDocumentsForm/utils';
import api from '../../../../../../api';
import Accordion from '../../../../../../components/Accordion';
import OutlineButton from '../../../../../../components/Buttons/OutlineButton';
import PrimaryButton from '../../../../../../components/Buttons/PrimaryButton';
import IComplyForm from '../../../../../../components/Form/IComplyForm';
import Table from '../../../../../../components/Tables/Table';
import useModalHandler from '../../../../../../hooks/useModalHandler';
import useTable from '../../../../../../hooks/useTable';
import useViewRelatedEventsListener from '../../../../../../hooks/useViewRelatedEventsListener';
import bus from '../../../../../../modules/bus';
import enums from '../../../../../../utilities/enums';
import tooltips from '../../../../../../utilities/enums/tooltips';
import DateManager from '../../../../../../utilities/services/DateManager';
import { EntityContext, PermissionGroupContext } from '../../../../../../utilities/services/contexts';
import AdditionalDocumentsForm from '../../../components/AdditionalDocumentsForm';
import HistoryRecordTableHeader from '../../../components/HistoryRecordTableHeader';
import NaturalPersonInformationForm from '../../../components/NaturalPersonformationForm';
import RequestClientUpdateModal from '../../../components/RequestClientUpdateNaturalPersonModal';
import PersonalInformationTableRow from './components/PersonalInformationTableRow';
/**
 * Personal information displayed at the top of the Natural Person profile page along with the table
 * @param {object} info - object containing the info
 * @param {object} historyInfo - object used for the the table
 * @param {function} oHistoryUpdate - adds row into history table after successful update
 * @param {function} onPaginationChange - set pagination params on page change
 * @param {number} indexToOpen - index which should be open in the history table
 * @param {number} issuesCount - total number of issues displayed on the table
 * @param {func} setInfo - sets personal info data
 */

const PersonalInformationSection = ({ data, badge }) => {
  const { entityId, updateEntityInfo } = useContext(EntityContext);
  const containerRef = useRef(null);

  const { isOpen: rcuOpen, open: openRCU, close: closeRCU } = useModalHandler();
  const { isOpen: editMode, open: openEditForm, close: closeEditForm } = useModalHandler();

  const {
    isLoading,
    removeRow,
    records,
    changeParams,
    pagination,
    params,
    overrideTable,
    addRow,
    updateRowData,
  } = useTable(api.kyc.entityManagement.naturalPerson.getInformationTable, { entityId });

  useViewRelatedEventsListener(enums.BUS_EVENTS.OVERRIDE_PERSONAL_TABLE, overrideTable, containerRef);

  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({ personalInformation: data, attachSupportingPackages: false });
  }, [data]);

  const handleSave = async (changes, form) => {
    try {
      const {
        attachSupportingPackages,
        documentEvent,
        items,
        personalInformation: { birthDate, residence, stateProvince, additionalDetails, ...rest },
        status: eventStatus,
      } = changes;
      const { answersDTO, itemsDTO } = prepareItems(items);

      const dataToSubmit = {};

      if (attachSupportingPackages) {
        dataToSubmit.documentEvent = documentEvent;
        dataToSubmit.items = itemsDTO;
        dataToSubmit.answers = answersDTO;
      }
      dataToSubmit.personalInformation = {
        ...rest,
        residence: residence || null,
        stateProvince: stateProvince || null,
        birthDate: DateManager.toBekend(birthDate),
        additionalDetails: additionalDetails !== '' ? additionalDetails : null,
      };
      dataToSubmit.status = eventStatus;

      const { data: updateResponse } = await api.kyc.entityManagement.naturalPerson.updateInformation(
        entityId,
        dataToSubmit,
      );
      // Update risk score
      const entityRiskAndScoreResponse = await api.kyc.entityManagement.getEntityRiskAndScore(entityId);
      const { riskLevel, score } = entityRiskAndScoreResponse.data;
      bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, { riskLevel, riskScore: score });

      const { personalInformationDto, documentDto, commentDto } = updateResponse;

      if (personalInformationDto) {
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.PERSONAL_INFORMATION,
          issuesChange: personalInformationDto.issues,
        });
        addRow(personalInformationDto);
      }

      if (commentDto) {
        bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, commentDto);
      }

      if (documentDto) {
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.DOCUMENTS,
          issuesChange: documentDto.issues,
        });
        bus.broadcastEvent(enums.BUS_EVENTS.NEW_DOCUMENTS_RECORD, documentDto);
      }

      if (eventStatus === enums.HISTORY_EVENT_STATUSES.ACCEPTED) {
        const updateData = {
          countryId: rest.residence,
          stateProvinceId: rest.stateProvince,
          name: `${rest.firstName} ${rest.lastName}`,
        };
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_GENERAL_INFO, updateData);
        updateEntityInfo(dataToSubmit.personalInformation);
      } else {
        form.reset();
      }

      closeEditForm();
    } catch (err) {
      if (err?.response) {
        const { status, data: errorData } = err.response;
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
        }
        if (status === 500) {
          return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
        }
      }
      return { [FORM_ERROR]: 'Error occurred while trying to update Natural Person' };
    }
  };

  useEffect(() => {
    const updateHistoryEventStatus = ({ eventHistoryId, data: newData }) => {
      updateRowData('eventHistoryId', eventHistoryId, newData);
    };
    bus.addEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, updateHistoryEventStatus);
    };
  }, [updateRowData]);

  useEffect(() => {
    const updateHistoryEventStatus = rowData => {
      addRow(rowData);
    };
    bus.addEventListener(enums.BUS_EVENTS.NEW_PERSONAL_RECORD, updateHistoryEventStatus);
    return () => {
      bus.removeEventListener(enums.BUS_EVENTS.NEW_PERSONAL_RECORD, updateHistoryEventStatus);
    };
  }, [addRow]);

  const handleHistoryRecordDelete = useCallback(
    eventId => {
      removeRow('eventHistoryId', eventId);
    },
    [removeRow],
  );
  const { edit: canEdit, requestClientUpdate: canRCU, delete: canDelete } = useContext(PermissionGroupContext);
  const TableControls = () => {
    return (
      <>
        <h3>History</h3>
        <div>
          {canEdit && <OutlineButton onClick={openEditForm}>Edit</OutlineButton>}
          {canRCU && <PrimaryButton onClick={openRCU}> Request Client Update</PrimaryButton>}
        </div>
      </>
    );
  };

  return (
    <Accordion
      title="Entity Information"
      accordionindex={enums.ACCORDION_INDEXES.PERSONAL_INFORMATION}
      accordionOpen
      accented
      withBadge
      badge={badge}
      hint={tooltips.ISSUES}
    >
      <div ref={containerRef}>
        <IComplyForm
          initialValues={initialValues}
          onSubmit={handleSave}
          onDiscardClick={closeEditForm}
          showControls={editMode}
          submitAcceptButtonText="Save and Accept"
          withTwoSubmitButtons
          noDiscardValidation
        >
          <NaturalPersonInformationForm preview={!editMode} responsive />
          {editMode && <AdditionalDocumentsForm noStatus />}
        </IComplyForm>
        <div className="ickyc-content-breaker" />
        {rcuOpen && (
          <RequestClientUpdateModal hideModal={closeRCU} containerRef={containerRef} identityVerificationEnabled />
        )}
        <Table
          tableControls={TableControls}
          headerRow={HistoryRecordTableHeader}
          tableRow={PersonalInformationTableRow}
          values={records}
          handleParamsChange={changeParams}
          pagination={pagination}
          withPagination
          updating={isLoading}
          onRemove={handleHistoryRecordDelete}
          headerData={{
            sortCriteria: params.sortCriteria,
            sortOrder: params.sortOrder,
            hasSourceColumn: true,
            hasServicesColumn: true,
            onClick: changeParams,
            withDelete: canDelete,
          }}
          className="ickyc-history-record-table"
        />
      </div>
    </Accordion>
  );
};

PersonalInformationSection.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    nationality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phone: PropTypes.string,
    residence: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    stateProvince: PropTypes.number,
    unit: PropTypes.string,
    zip: PropTypes.string,
    birthDate: PropTypes.instanceOf(Date),
    association: PropTypes.string,
    registrationNumber: PropTypes.string,
  }).isRequired,
  badge: PropTypes.number,
};
PersonalInformationSection.defaultProps = {
  badge: 0,
};
export default PersonalInformationSection;
