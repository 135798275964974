import React from 'react';

export default () => (
  <svg version="1.2" baseProfile="tiny" id="Layer_1" x="0px" y="0px" viewBox="0 0 256 265" overflow="visible">
    <g>
      <path
        fill="#4972D2"
        d="M214.11,225.92c12.85-14.07,20.86-32.74,20.86-53.35c0-21.58-8.73-40.99-22.8-55.05
		c-6.06,50.44-49.48,89.49-102.11,89.25H84.84c12.61,26.68,40.02,45.11,71.3,45.11l85.86-0.24L214.11,225.92z"
      />
      <path
        fill="#4972D2"
        d="M120.73,113.87v7.76H105.2V99.56h7.76c6.55,0,11.64-5.34,11.64-11.64c0-6.55-5.34-11.64-11.64-11.64
		c-6.31,0-11.64,5.34-11.64,11.64H85.8c0-15.04,12.37-27.41,27.41-27.41c15.04,0,27.41,12.37,27.41,27.41
		C140.37,100.05,132.13,110.48,120.73,113.87L120.73,113.87z M120.73,144.67H105.2v-13.34h15.52V144.67z M113.21,14.44
		c-49.96-0.24-90.46,40.02-90.71,89.98c0,23.53,8.97,45.11,23.77,61.36l-32.26,28.86l98.47,0.24c49.96,0.24,90.47-40.26,90.71-89.98
		C203.19,54.94,162.93,14.44,113.21,14.44L113.21,14.44z"
      />
    </g>
  </svg>
);
