import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="38.422" height="47.465">
    <g data-name="Group 1087" fill="#4972d2">
      <path
        data-name="Path 538"
        d="M3.387 0A3.406 3.406 0 000 3.392v40.682a3.406 3.406 0 003.387 3.392H35.03a3.41 3.41 0 003.392-3.392v-33.9a1.13 1.13 0 00-.33-.8L29.049.33a1.13 1.13 0 00-.8-.33zm0 2.26h23.735v5.653a3.408 3.408 0 003.39 3.39h5.649v32.771a1.1 1.1 0 01-1.13 1.131H3.387a1.106 1.106 0 01-1.131-1.131V3.392a1.106 1.106 0 011.131-1.131zm25.995 1.6l5.179 5.183h-4.049a1.1 1.1 0 01-1.13-1.13z"
        fillRule="evenodd"
      />
      <path data-name="Path 539" d="M9.04 18.083a1.13 1.13 0 100 2.261h20.343a1.13 1.13 0 100-2.261z" />
      <path data-name="Path 540" d="M9.04 22.605a1.13 1.13 0 100 2.261h20.343a1.13 1.13 0 000-2.261z" />
      <path data-name="Path 541" d="M9.04 27.123a1.13 1.13 0 000 2.261h20.343a1.13 1.13 0 000-2.261z" />
      <path data-name="Path 542" d="M9.04 31.64a1.13 1.13 0 100 2.261h20.343a1.13 1.13 0 100-2.261z" />
    </g>
  </svg>
);
