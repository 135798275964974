import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { diff } from 'deep-object-diff';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Form as FinalForm } from 'react-final-form';
import toastr from 'toastr';
import OutlineButton from '../../Buttons/OutlineButton';
import PrimaryButton from '../../Buttons/PrimaryButton';
import Loader from '../../Loader';
import './index.scss';

/**
 *
 * @param {Object} initialValues - initial values of form fields
 * @param {Function} onSubmit - handle Form submit
 * @param {HTMLElement} formcontrols - array of buttons,one of them is submit button
 * @param {Boolean} showControls - show/hide formcontrols
 *
 *
 */
const IComplyForm = React.forwardRef(
  (
    {
      children,
      initialValues,
      onSubmit,
      onDiscardClick,
      showControls,
      noDiscard,
      className,
      withTwoSubmitButtons,
      submitButtonText,
      submitAcceptButtonText,
      formValidationErrors,
      disableButtons,
      withCloseButton,
      onCloseClick = onDiscardClick,
      noDiscardValidation,
      isUpdating,
      ...rest
    },
    ref,
  ) => {
    const classes = useCallback(
      isLoading => {
        return classNames({
          'ickyc-form': true,
          'ickyc-form--submitting': isLoading || disableButtons,
          [className]: className,
        });
      },
      [className, disableButtons],
    );

    const isFunctionAsChild = typeof children === 'function';

    return (
      <FinalForm initialValues={initialValues} onSubmit={onSubmit} {...rest}>
        {props => {
          const {
            handleSubmit,
            submitting,
            submitError,
            form,
            form: { change },
            modifiedSinceLastSubmit,
            values,
            errors,
            submitFailed,
          } = props;

          return (
            <form
              onSubmit={event => {
                const hasValidationErrors = !!formValidationErrors?.(errors);

                if (hasValidationErrors) {
                  toastr.error('Validation Error');
                  const containerElement = document.getElementsByClassName('ickyc-widget')?.[0];
                  if (containerElement)
                    // scroll to error messages
                    setTimeout(() => containerElement.scrollTo(0, containerElement.scrollHeight + 1000), 100);
                }

                handleSubmit(event, form);
              }}
              className={classes(submitting)}
              ref={ref}
            >
              {(submitting || isUpdating) && <Loader />}
              <div className="ickyc-form__content">{isFunctionAsChild ? children(props) : children}</div>

              {submitError && !modifiedSinceLastSubmit && (
                <div className="ickyc-form__submissionError">
                  {(Array.isArray(submitError) ? submitError : [submitError]).map(err => (
                    <p>{err}</p>
                  ))}
                </div>
              )}
              {!submitError && submitFailed && formValidationErrors?.(errors) && (
                <div className="ickyc-form__submissionError">
                  {formValidationErrors(errors).map(err => (
                    <p>{err}</p>
                  ))}
                </div>
              )}
              {showControls && (
                <div className="ickyc-form__actions">
                  {!noDiscard && (
                    <PrimaryButton
                      variant="red"
                      disabled={
                        submitting ||
                        disableButtons ||
                        (noDiscardValidation ? false : !Object.entries(diff(values, initialValues))?.length)
                      }
                      onClick={() => {
                        form.reset(initialValues);
                        onDiscardClick();
                      }}
                    >
                      Discard
                    </PrimaryButton>
                  )}
                  {!withTwoSubmitButtons ? (
                    <PrimaryButton
                      type="submit"
                      disabled={submitting || disableButtons || !Object.entries(diff(values, initialValues))?.length}
                    >
                      {submitButtonText} {(submitting || disableButtons) && <CircularProgress />}
                    </PrimaryButton>
                  ) : (
                    <>
                      <OutlineButton
                        type="submit"
                        disabled={submitting || disableButtons || !Object.entries(diff(values, initialValues))?.length}
                        onClick={() => change('status', 'Review')}
                      >
                        {submitButtonText} {(submitting || disableButtons) && <CircularProgress />}
                      </OutlineButton>
                      <PrimaryButton
                        type="submit"
                        disabled={submitting || disableButtons || !Object.entries(diff(values, initialValues))?.length}
                        onClick={() => change('status', 'Accepted')}
                      >
                        {submitAcceptButtonText} {(submitting || disableButtons) && <CircularProgress />}
                      </PrimaryButton>
                    </>
                  )}
                  {withCloseButton && (
                    <PrimaryButton variant="link" onClick={onCloseClick}>
                      Close
                    </PrimaryButton>
                  )}
                </div>
              )}
            </form>
          );
        }}
      </FinalForm>
    );
  },
);
IComplyForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  formValidationErrors: PropTypes.func,
  showControls: PropTypes.bool,
  onDiscardClick: PropTypes.func,
  noDiscard: PropTypes.bool,
  withTwoSubmitButtons: PropTypes.bool,
  submitButtonText: PropTypes.string,
  className: PropTypes.string,
  submitAcceptButtonText: PropTypes.string,
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  disableButtons: PropTypes.bool,
  noDiscardValidation: PropTypes.bool,
};

IComplyForm.defaultProps = {
  initialValues: {},
  showControls: false,
  noDiscard: false,
  withTwoSubmitButtons: false,
  onDiscardClick: () => {},
  submitButtonText: 'Save',
  className: undefined,
  submitAcceptButtonText: 'Save and Accept',
  ref: null,
  disableButtons: false,
  noDiscardValidation: false,
};

export default IComplyForm;
