const csvUploadValidation = () => async (value, { isNaturalPerson }) => {
  const isNaturalPersonTrue = isNaturalPerson === 'true';
  let isCSVForLE;

  const readCSVData = async file => {
    return new Promise(resolve => {
      const reader = new FileReader();

      reader.onload = function() {
        const dataString = reader.result;
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const dataArray = dataStringLines
          .slice(1, dataStringLines.length)
          .filter(dsl => !dsl.split(',').every(dslRow => dslRow === '' || dslRow === ' '))
          .map(dsl =>
            dsl.split(/,(?=(?:[^\"]*\"[^\"]*\")*(?![^\"]*\"))/g).reduce(
              (acc, curr, index) => ({
                ...acc,
                [headers[index]]: curr.replace(/^"|"$/g, ''),
              }),
              {},
            ),
          );

        resolve(!!dataArray[0].Name);
      };
      reader.readAsText(file);
    });
  };

  if (value?.length) {
    isCSVForLE = await readCSVData(value[0].file); // Wait for the CSV data
  }

  if (isCSVForLE && isNaturalPersonTrue) {
    return 'Please upload a CSV containing Natural Persons or change the CSV type.';
  }
  if (!isCSVForLE && !isNaturalPersonTrue) {
    return 'Please upload a CSV containing Legal Entities or change the CSV type.';
  }

  return;
};

export default {
  csvUploadValidation,
};
