/* eslint-disable import/no-unresolved */
/**
 * Date input component
 * @param {string} [props.label] - label text
 * @param {string} [props.value] - input value
 * @param {func} [props.onChange] - onChange handler
 * @param {boolean} [editable = false] - add editable styles
 * @param {boolean} [disabled = false] - disable and add disabled styles
 *
 */
import DayJSUtils from '@date-io/date-fns';
import HelpIcon from '@material-ui/icons/Help';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import classNames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Tooltip from '../Tooltip';
import './styles.scss';

dayjs.extend(utc);

const Datepicker = ({
  label,
  value,
  onChange,
  preview,
  disabled,
  placeholder,
  hasError,
  maxDate,
  minDate,
  hint,
  errorMsg,
  className,
  endOfDay,
  inputVariant,
  accentText,
  onBlur,
}) => {
  const classBase = 'ickyc-datepicker';
  const [dateValue, setDateValue] = useState(null);

  const containerRef = useRef();

  const classes = classNames({
    [`${classBase}`]: true,
    [`${classBase}--disabled`]: disabled,
    [`${classBase}--preview`]: preview,
    [`${classBase}--error`]: hasError,
    [`${classBase}--red-colored-text`]: accentText,
    [className]: className,
  });

  const onDateChange = useCallback(
    date => {
      const dateObject = endOfDay ? dayjs(date).endOf('day') : dayjs(date).startOf('day');
      onChange(dateObject);
    },
    [onChange, endOfDay],
  );

  useEffect(() => {
    setDateValue(value);
  }, [value]);

  useEffect(() => {
    const containerElement = containerRef.current;
    const input = containerElement?.getElementsByTagName('input')?.[0];
    input && input.setAttribute && input.setAttribute('data-lpignore', 'true');
  }, []);

  return (
    <div className={classes} ref={containerRef}>
      {label && (
        <label>
          {label}
          {hint && <Tooltip trigger={<HelpIcon />} content={<span>{hint}</span>} />}
        </label>
      )}
      <MuiPickersUtilsProvider utils={DayJSUtils}>
        <KeyboardDatePicker
          value={dateValue}
          onChange={onDateChange}
          variant="inline"
          disabled={disabled}
          format={preview ? 'MMM dd, yyyy' : 'MM-dd-yyyy'}
          placeholder={placeholder}
          maxDate={maxDate}
          minDate={minDate}
          error={hasError}
          inputVariant={inputVariant}
          autoOk
          helperText={hasError && errorMsg}
          onBlur={onBlur}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

Datepicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func,
  preview: PropTypes.bool,
  disabled: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  hasError: PropTypes.bool,
  errorMsg: PropTypes.string,
  hint: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  endOfDay: PropTypes.bool,
  inputVariant: PropTypes.string,
  accentText: PropTypes.bool,
};

Datepicker.defaultProps = {
  value: undefined,
  onChange: () => {},
  preview: false,
  disabled: false,
  label: null,
  maxDate: '2999-11-05T13:15:30Z',
  minDate: '1900-11-05T13:15:30Z',
  hasError: false,
  className: undefined,
  errorMsg: '',
  placeholder: '',
  hint: undefined,
  endOfDay: false,
  inputVariant: 'outlined',
  accentText: false,
};

export default Datepicker;
