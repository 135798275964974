import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import IconManager from '../../utilities/services/IconManager';
import './styles.scss';

const POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};
/**
 * Custom content Radio Button
 *
 * @param {string} name
 * @param {string} label
 * @param {string} icon
 * @param {boolean} inline
 * @param {boolean} disabled
 * @param {func} onChange - handles click on radio button
 * @param {string} value - input value
 * @param {string} checked - checked value
 *
 */
const RadioButton = ({
  name,
  label,
  onChange,
  disabled,
  value,
  checked,
  classNameSuffix,
  icon,
  iconPosition,
  tabIndex,
  className,
}) => {
  const classes = classNames(
    {
      'ickyc-radio-button': true,
      'ickyc-radio-button--disabled': disabled,
      [`ickyc-radio-button${classNameSuffix}`]: classNameSuffix,
    },
    className,
  );

  const inputProps = useMemo(() => {
    return {
      type: 'radio',
      name,
      disabled,
      value,
      checked,
      onChange,
      tabIndex,
    };
  }, [name, disabled, value, checked, onChange, tabIndex]);

  return (
    <label className={classes}>
      <input {...inputProps} />
      {iconPosition === POSITION.LEFT && IconManager.get(icon)}
      {label}
      {iconPosition === POSITION.RIGHT && IconManager.get(icon)}
    </label>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  center: PropTypes.bool,
  ickycTable: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  label: PropTypes.string,
  icon: PropTypes.string,
  classNameSuffix: PropTypes.string,
  iconPosition: PropTypes.string,
};

RadioButton.defaultProps = {
  center: false,
  ickycTable: false,
  disabled: false,
  inline: false,
  onChange: () => {},
  checked: '',
  label: '',
  icon: undefined,
  classNameSuffix: undefined,
  iconPosition: POSITION.LEFT,
};

export default RadioButton;
