import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import extentEnum from './enums/extentEnum';
import './styles.scss';

/**
 *
 * @param {number} [extent = 10] - selected extent
 * @param {function} [setExtent = () => {}] - extent setter
 */

const ResultsPerPage = ({ extent, setExtent }) => {
  return (
    <div className="ickyc-results-per-page">
      <div>
        {Object.values(extentEnum).map(el => {
          const activityClass = classNames({ 'ickyc-results-per-page__extent--active': extent === el });

          return (
            <span onClick={() => setExtent(el)} key={el} className={activityClass}>
              {el}
            </span>
          );
        })}
      </div>

      <span>Results Per Page</span>
    </div>
  );
};

ResultsPerPage.propTypes = {
  extent: PropTypes.number,
  setExtent: PropTypes.func,
};

ResultsPerPage.defaultProps = {
  extent: 10,
  setExtent: () => {},
};

export default ResultsPerPage;
