import classNames from 'classnames';
import React from 'react';
import setClassSuffix from 'utilities/services/ClassManager';
import './styles.scss';

const BillingHistoryTableRow = ({ rowInfo, index }) => {
  const { date, description, total, invoice, invoiceUrl } = rowInfo;
  const setSuffix = setClassSuffix('ickyc-table__column');

  const classes = classNames('kyc-billing-history-table-row', {
    'kyc-billing-history-table-row--colored': index % 2 === 0,
  });

  return (
    <tr className={classes}>
      <td className={setSuffix('__date')}>{date}</td>
      <td className={setSuffix('__description')}>{description || 'asdf'}</td>
      <td className={setSuffix('__total')}>{total}</td>
      <td className={setSuffix('__invoice')}>
        <a href={invoiceUrl}>{invoice}</a>
      </td>
    </tr>
  );
};

export default BillingHistoryTableRow;
