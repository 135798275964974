import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import Input from '../../../../components/Input';
import setClassSuffix from '../../../../utilities/services/ClassManager';
import AcceptRejectCaptureControlButtons from '../AcceptRejectCaptureControlButtons';
import './styles.scss';

const MinimumAgeControls = ({ onClick, disabled, value, setValue }) => {
  const [age, setAge] = useState(value);
  const baseClass = 'ickyc-minimum-age-controls';
  const setSuffix = setClassSuffix(baseClass);

  const changeAge = useCallback(e => {
    e.stopPropagation();
    setAge(e.target.value);
  }, []);

  const handleOnClick = useCallback(() => {
    if (age < 0 || age === '') return;
    onClick({
      minimumAcceptableAge: age,
    });
    setValue(age);
  }, [onClick, setValue, age]);

  return (
    <span className={baseClass}>
      <div
        className={classNames(setSuffix('__minimum-age'), {
          [setSuffix('__minimum-age--not-valid')]: age < 0 || age === '',
        })}
      >
        <Input value={age} onChange={changeAge} type="number" max={99} min={1} />
        <span onClick={handleOnClick}>Set Minimum Age for Selected</span>
      </div>
      <AcceptRejectCaptureControlButtons onClick={onClick} disabled={disabled} />
    </span>
  );
};

MinimumAgeControls.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.number,
  setValue: PropTypes.func,
};

MinimumAgeControls.defaultProps = {
  onClick: () => {},
  disabled: true,
  value: 16,
  setValue: () => {},
};
export default MinimumAgeControls;
// function areEqual(prevProps, nextProps) {
//   return JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value);
// }
// export default React.memo(MinimumAgeControls, areEqual);
