import InputField from 'components/InputField';
import Section from 'components/Section';
import SelectContent from 'components/Select/components/SelectContent';
import SelectCustomContent from 'components/Select/components/SelectCustomContent';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectInputTrigger from 'components/Select/components/SelectInputTrigger';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import ToggleField from 'components/ToggleField';
import React, { useCallback } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import authEnums from 'utilities/enums/authEnums';
import licenceEnums from 'utilities/enums/licenceEnums';
import setClassSuffix from 'utilities/services/ClassManager';
import validators from 'utilities/services/validators';
import ModulesSection from '../ModulesSection';
import SetupSection from '../SetupSection';

const AddLicenceFields = ({ licenceType, setLicenceType, initialLicenceValues }) => {
  const baseClass = 'ickyc-add-licence-modal';
  const setSuffix = setClassSuffix(baseClass);
  const { batch, change, reset } = useForm();

  const {
    input: { value: entities },
  } = useField('platformAccess.entities');
  const {
    input: { value: cases },
  } = useField('platformAccess.cases');
  const {
    input: { value: administrationAccess },
  } = useField('administrationAccess');

  const { portalManagement, api: apiToggle, supportingDocuments, riskScreening } = administrationAccess;

  const handleEntitiesChange = useCallback(
    value => {
      batch(() => {
        change('administrationAccess.automations', value);
        change('administrationAccess.portalManagement', value);
        change('administrationAccess.webHooks', value);
        change('administrationAccess.riskScoring', value);
        change('administrationAccess.supportingDocuments', value);
        change('administrationAccess.riskLevels', value);
        change('administrationAccess.quickSetup', value);
        change('administrationAccess.emails', value);
      }, []);
    },
    [change],
  );

  const handleCasesChange = useCallback(
    value => {
      change('administrationAccess.riskScreening', value);
    },
    [change],
  );

  const handleLicenceTypeChange = useCallback(
    value => {
      setLicenceType(value);
      if (value === licenceEnums.LICENCE_TYPES.CUSTOM) {
        reset();
      } else if (value === licenceEnums.LICENCE_TYPES.WEBSITE) {
        change('setupAndMaintenance', {
          oneTimeSetupFeeEnabled: false,
          oneTimeSetupFee: 0,
          recurringMaintenanceEnabled: false,
          recurringMaintenance: 0,
          recurringMaintenancePeriod: 0,
          saasFeeEnabled: false,
          saasFee: 0,
          saasFeePeriod: 0,
        });
        change('modules.core', {
          userManagementEnabled: false,
          filesManagementEnabled: false,
          notificationEnabled: false,
        });
      }
    },
    [change, reset, setLicenceType],
  );

  const handlePortalManagementChange = useCallback(
    value => {
      change('administrationAccess.quickSetup', value);
    },
    [change],
  );

  const handleApiChange = useCallback(
    value => {
      change('administrationAccess.apiManager', value);
    },
    [change],
  );

  const handleRiskLevelsChange = useCallback(value => {
    value && change('administrationAccess.riskScoring', value);
  });

  const handleRiskScoringChange = useCallback(value => {
    !value && change('administrationAccess.riskLevels', value);
  });

  return (
    <>
      <Section
        {...((licenceType === licenceEnums.LICENCE_TYPES.CUSTOM ||
          licenceType === licenceEnums.LICENCE_TYPES.WEBSITE) && {
          divider: true,
        })}
        title="License Type *"
        headerAdditionalContent={
          <>
            <h3>License Name *</h3>
            <h3>Tags</h3>
          </>
        }
      >
        <Field
          autoFocus
          component={SelectField}
          name="type"
          options={authEnums.LICENSE_TYPES}
          Trigger={<SelectTrigger placeholder="Select Type" />}
          Content={<SelectContent />}
          validate={validators.required(' ')}
          onChange={handleLicenceTypeChange}
          disabled={
            initialLicenceValues?.type === licenceEnums.LICENCE_TYPES.CUSTOM ||
            initialLicenceValues?.type === licenceEnums.LICENCE_TYPES.WEBSITE
          }
        />
        <Field component={InputField} name="name" validate={validators.required(' ')} />
        <Field
          multipleSelection
          withCustomOptions
          component={SelectField}
          name="tags"
          options={authEnums.TAGS}
          Trigger={<SelectInputTrigger placeholder="Select Tags" />}
          Content={<SelectCustomContent />}
        />
      </Section>

      {licenceType === licenceEnums.LICENCE_TYPES.CUSTOM && (
        <>
          <Section divider title="Platform Access" className={setSuffix('__grid-layout')}>
            <Field
              component={ToggleField}
              name="platformAccess.entities"
              label="Entities"
              onChange={handleEntitiesChange}
            />
            <Field component={ToggleField} name="platformAccess.cases" label="Cases" onChange={handleCasesChange} />
          </Section>
          <Section divider title="Administration Access" className={setSuffix('__grid-layout')}>
            <Field component={ToggleField} name="administrationAccess.filesManagement" label="Files Management" />
            <Field
              component={ToggleField}
              name="administrationAccess.portalManagement"
              disabled={!entities}
              label="Portal Management"
              onChange={handlePortalManagementChange}
            />
            <Field component={ToggleField} name="administrationAccess.userManagement" label="User Management" />
            <Field component={ToggleField} name="administrationAccess.webHooks" label="Webhooks" disabled={!entities} />
            <Field
              component={ToggleField}
              name="administrationAccess.automations"
              disabled={!entities}
              label="Automations"
            />
            <Field component={ToggleField} name="administrationAccess.account" label="Account" />
            <Field component={ToggleField} name="administrationAccess.notifications" label="Notifications" />
            <Field component={ToggleField} name="administrationAccess.emails" disabled={!entities} label="Emails" />
          </Section>
          <Section divider title="Compliance Settings" className={setSuffix('__grid-layout')}>
            <Field
              component={ToggleField}
              name="administrationAccess.supportingDocuments"
              disabled={!entities}
              label="Supporting Document Packages"
            />
            <Field
              component={ToggleField}
              name="administrationAccess.riskScreening"
              disabled={!cases}
              label="Risk Screening"
            />
            <Field
              component={ToggleField}
              name="administrationAccess.riskLevels"
              disabled={!entities}
              onChange={handleRiskLevelsChange}
              label="Risk Levels"
            />
            <Field
              component={ToggleField}
              name="administrationAccess.riskScoring"
              disabled={!entities}
              label="Risk Scoring"
              onChange={handleRiskScoringChange}
            />
            <Field
              component={ToggleField}
              name="administrationAccess.quickSetup"
              disabled={!portalManagement}
              label="Quick Setup Settings"
            />
            <div />
            {supportingDocuments && (
              <Field
                component={InputField}
                name="defaultValues.supportingDocumentsLimit"
                type="number"
                label="Supporting Document Packages Limit"
                placeholder="Maximum number"
                validate={validators.required(' ')}
              />
            )}
            {riskScreening && (
              <Field
                component={InputField}
                name="defaultValues.riskScreeningLimit"
                type="number"
                label="Risk Screening Limit"
                placeholder="Maximum number"
                validate={validators.required(' ')}
              />
            )}
          </Section>

          <Section divider title="Technical" className={setSuffix('__grid-layout')}>
            <Field component={ToggleField} name="administrationAccess.api" label="API" onChange={handleApiChange} />
            <Field
              component={ToggleField}
              name="administrationAccess.apiManager"
              label="API Manager"
              disabled={!apiToggle}
            />
          </Section>
          <Section divider title="Number of Users Default" className={setSuffix('__grid-layout')}>
            <Field
              component={InputField}
              name="defaultValues.numOfAdminUsersDefault"
              type="number"
              label="Admin Users"
              placeholder="Number of Users"
              validate={validators.required(' ')}
            />
            <Field
              component={InputField}
              name="defaultValues.numOfUsersDefault"
              type="number"
              label="Platform Users"
              placeholder="Number of Users"
              validate={validators.required(' ')}
            />
          </Section>
          <Section divider title="Number of Portals Default & Custom URLs" className={setSuffix('__grid-layout')}>
            <Field
              component={InputField}
              name="defaultValues.npPortals"
              placeholder="Number of Portals"
              type="number"
              label="NP Portals"
              validate={validators.required(' ')}
            />
            <Field
              component={InputField}
              name="defaultValues.lePortals"
              placeholder="Number of Portals"
              type="number"
              label="LE Portals"
              validate={validators.required(' ')}
            />
            <Field
              component={InputField}
              name="defaultValues.npCustomUrls"
              placeholder="Number of URLs"
              type="number"
              label="NP Custom URLs"
              validate={validators.required(' ')}
            />
            <Field
              component={InputField}
              name="defaultValues.leCustomUrls"
              placeholder="Number of URLs"
              type="number"
              label="LE Custom URLs"
              validate={validators.required(' ')}
            />
          </Section>
          <Section divider title="SaaS Fee Default" className={setSuffix('__grid-layout')}>
            <Field
              component={InputField}
              preffix="$"
              name="defaultValues.saasFee"
              placeholder=". . ."
              label="Fee Amount"
              type="number"
              validate={validators.required(' ')}
            />
            <Field
              component={SelectField}
              name="defaultValues.saasBillingCycle"
              options={licenceEnums.BILLING_CYCLE_OPTIONS}
              label="SaaS Billing Cycle"
              Trigger={<SelectTrigger placeholder="Billing Cycle" />}
              Content={<SelectFilterContent />}
              validate={validators.required(' ')}
            />
          </Section>
          <Section divider title="KYC Services Plan Default" className={setSuffix('__grid-layout')}>
            <Field
              component={InputField}
              preffix="$"
              name="defaultValues.kycServicePlan"
              placeholder=". . ."
              type="number"
              label="KYC Services Plan"
              validate={validators.required(' ')}
            />
            <Field
              component={SelectField}
              name="defaultValues.kycBillingCycle"
              options={licenceEnums.BILLING_CYCLE_OPTIONS}
              label="KYC Service Billing Cycle"
              Trigger={<SelectTrigger placeholder="Billing Cycle" />}
              Content={<SelectFilterContent />}
              validate={validators.required(' ')}
            />
          </Section>
          <Section title="Overage Default" className={setSuffix('__grid-layout')}>
            <Field
              component={InputField}
              preffix="%"
              name="defaultValues.overageRate"
              placeholder=". . ."
              type="number"
              label="Overage Rate"
              validate={validators.required(' ')}
            />
            <Field
              renderAsPortal
              component={SelectField}
              name="defaultValues.overageBillingCycle"
              options={licenceEnums.BILLING_CYCLE_OPTIONS}
              label="Overage Billing Cycle"
              Trigger={<SelectTrigger placeholder="Billing Cycle" />}
              Content={<SelectFilterContent />}
              validate={validators.required(' ')}
            />
          </Section>
        </>
      )}
      {licenceType === licenceEnums.LICENCE_TYPES.WEBSITE && (
        <>
          <SetupSection />
          <ModulesSection initialModules={initialLicenceValues?.modules} />
        </>
      )}
    </>
  );
};
export default AddLicenceFields;
