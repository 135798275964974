import React from 'react';

export default () => (
  <svg version="1.2" baseProfile="tiny" id="Layer_1" x="0px" y="0px" viewBox="0 0 256 265" overflow="visible">
    <g>
      <path
        fill-rule="evenodd"
        fill="#02CE94"
        d="M209.29,86.42h-52.26c-1.6,0-3.05-0.65-4.11-1.7c-1.05-1.06-1.7-2.51-1.7-4.11V28.35
		h-87.1c-9.62,0-17.42,7.8-17.42,17.42v174.2c0,9.61,7.8,17.42,17.42,17.42h127.74c9.62,0,17.42-7.8,17.42-17.42V86.42z
		 M201.08,74.8l-38.24-38.24V74.8H201.08z M35.09,45.77c0-16.04,12.99-29.03,29.03-29.03h92.9c1.63,0,3.09,0.66,4.15,1.74
		l57.98,57.98c1.08,1.06,1.74,2.52,1.74,4.15v139.36c0,16.04-12.99,29.03-29.03,29.03H64.12c-16.04,0-29.03-13-29.03-29.03V45.77z"
      />
      <path
        fill="#02CE94"
        d="M133.78,136.27l15.14,15.12c2.25,2.26,5.91,2.26,8.18,0.01c2.25-2.25,2.26-5.91,0.01-8.16l-25.06-24.97
		c-2.25-2.24-5.89-2.24-8.14,0l-25.05,24.97c-2.25,2.25-2.25,5.91,0.01,8.16c2.26,2.25,5.92,2.25,8.16-0.01l15.15-15.12v40.82
		c0,3.19,2.59,5.78,5.78,5.78c3.19,0,5.78-2.59,5.78-5.78v-40.82H133.78z"
      />
      <path
        fill="#02CE94"
        d="M91.39,195.31h73.21c3.18,0,5.76,2.57,5.76,5.75c0.01,3.18-2.57,5.76-5.75,5.77h-0.01H91.39
		c-3.18,0-5.76-2.58-5.76-5.76c-0.01-3.18,2.57-5.76,5.75-5.76H91.39z"
      />
    </g>
  </svg>
);
