import { CircularProgress } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import api from '../../../api';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import InputField from '../../../components/InputField';
import history from '../../../modules/history';
import TokenStorage from '../../../utilities/services/local-storage/TokenStorage';
import validators from '../../../utilities/services/validators';
import './styles.scss';

const CreateAccountConfirmation = () => {
  const baseClass = 'ickyc-form';
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const email = params.get('email');

  useEffect(() => {
    if (token) {
      TokenStorage.token = token;
    }
  }, [token]);

  const goToNextStep = (identityVerification, twoFactorAuthenticationEnabled, personalDetails, tokenFromRes) => {
    setShowMessage(true);
    setTimeout(function() {
      if (!personalDetails) {
        return twoFactorAuthenticationEnabled
          ? history.push(`/auth/two-factor-authentication/enable-authenticator?token=${tokenFromRes}&email=${email}`)
          : history.push('/auth/login');
      }

      if (identityVerification) {
        history.push(
          `/auth/basic-info-identity?verificationtoken=${tokenFromRes}&twofactor=${twoFactorAuthenticationEnabled}&email=${email}`,
        );
      } else {
        history.push(
          `/auth/basic-info?verificationtoken=${tokenFromRes}&twofactor=${twoFactorAuthenticationEnabled}&email=${email}`,
        );
      }
    }, 2000);
  };

  const handleSaveChanges = async values => {
    const data = {
      token,
      password: values.password,
      confirmedPassword: values.confirmPassword,
    };

    return api.clientUser
      .setPassword(data)
      .then(({ data: responseData }) => {
        const { identityVerificaion, token: resToken, twoFactorAuthenticationEnabled, personalDetails } = responseData;
        goToNextStep(identityVerificaion, twoFactorAuthenticationEnabled, personalDetails, resToken);
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            setErrorMessage(errorData.error || `${errorData.message?.slice(0, 80)}`);
            return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}` };
          }
          if (status === 500) {
            setErrorMessage('Error occured while trying to set Password');
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else {
          setErrorMessage('Error occured while trying to set Password');
          return { [FORM_ERROR]: 'Error occured while trying to set Password' };
        }
      });
  };

  if (showMessage) {
    return (
      <h3 className="ickyc-widget__global-message">
        Password set successfully. You will be redirected to next step ...
      </h3>
    );
  }
  return !token ? (
    <h3 className="ickyc-widget__global-message">No Token Present!</h3>
  ) : (
    <FinalForm
      className=""
      onSubmit={handleSaveChanges}
      validate={values => {
        const errors = {};
        if (!values.password) {
          errors.password = 'Required';
        }
        if (values.password !== values.confirmPassword) {
          errors.confirmPassword = 'Must match';
        }
        return errors;
      }}
    >
      {({ submitting, handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className="ickyc-form ickyc-create-account-confirmation-page">
            <>
              <div className={`${baseClass}__fields ${baseClass}__fields--rounded`}>
                <h3 className="ickyc-create-account-confirmation-page__title">Create a New Password</h3>
                <Field
                  component={InputField}
                  name="email"
                  initialValue={email.replaceAll(' ', '+')}
                  label="Email"
                  type="text"
                  autocomplete="off"
                  preview
                />
                <Field
                  component={InputField}
                  name="password"
                  label="New Password"
                  type="password"
                  autocomplete="off"
                  validate={validators.password(
                    'Password must contain at least one uppercase letter, one lowercase letter, one digit number and special character',
                  )}
                />
                <Field
                  component={InputField}
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  autocomplete="off"
                  validate={validators.password(
                    'Password must contain at least one uppercase letter, one lowercase letter, one digit number and special character',
                  )}
                />
                {errorMessage && (
                  <div className="ickyc-create-account-confirmation-page__error-container">{errorMessage}</div>
                )}
              </div>

              <div className={`${baseClass}__actions`}>
                <PrimaryButton type="submit" disabled={submitting}>
                  Create New Password
                  {submitting && <CircularProgress />}
                </PrimaryButton>
              </div>
            </>
          </form>
        );
      }}
    </FinalForm>
  );
};
export default CreateAccountConfirmation;
