import { Box } from '@material-ui/core';
import classNames from 'classnames';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import DocumentsPackageTable from 'scenes/Kyc/EntityManagement/components/DocumentsSection/components/DocumentsData/components/DocumentsPackageTable';
import toastr from 'toastr';
import enums from 'utilities/enums';
import api from '../../../../../../../../../../api';
import PrimaryButton from '../../../../../../../../../../components/Buttons/PrimaryButton';
import Loader from '../../../../../../../../../../components/Loader';
import setClassSuffix from '../../../../../../../../../../utilities/services/ClassManager';
import { EntityContext, HistoryRecordContext } from '../../../../../../../../../../utilities/services/contexts';
import errorMessage from '../../../ErrorMessage';
import ThirdPartyHeader from '../../../ThirdPartyHeader';
import './styles.scss';

//TODO: check if this component works properly
const ThirdPartyEditDocument = ({ data, onDataFetch }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { entityId } = useContext(EntityContext);
  const { issues: historyRecordIssues } = useContext(HistoryRecordContext);

  const { thirdPartySuggestion, eventHistoryId } = data;
  const { editedSupportingDoc, suggestionStatus, thirdPartyInfo } = thirdPartySuggestion;
  const { items, issues } = editedSupportingDoc;

  const classBase = 'ickyc-third-party-edit-document';
  const setSuffix = setClassSuffix(classBase);
  const classes = classNames(classBase, {
    [setSuffix('__issues')]: issues?.length,
  });

  const handleSave = async suggestionStatus => {
    setIsLoading(true);
    const dataToSubmit = { status: suggestionStatus, operation: 'edit' };

    return api.kyc.entityManagement.legalEntity
      .updateSupportingDocument(entityId, eventHistoryId, dataToSubmit)
      .then(response => {
        onDataFetch({
          ...(suggestionStatus === 'accepted' ? editedSupportingDoc : data),
          issues: response?.data?.issues,
          eventHistoryId: data?.eventHistoryId,
          thirdPartySuggestion: {
            ...thirdPartySuggestion,
            suggestionStatus,
            editedSupportingDoc: { ...editedSupportingDoc, issues: [] },
          },
        });
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, {
          eventHistoryId,
          data: {
            issues: historyRecordIssues - 2,
            status: response?.data?.status,
          },
        });
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.DOCUMENTS,
          issuesChange: -2,
        });
        toastr.success(`Suggestion has been ${suggestionStatus}.`);
        setError();
      })
      .catch(err => {
        return setError(errorMessage(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={classes}>
      <ThirdPartyHeader
        className={classBase}
        info={thirdPartyInfo}
        issuesCount={issues?.length}
        actionType="Suggested"
      />
      {items?.length > 0 ? (
        <>
          <div className={setSuffix('__content')}>
            <DocumentsPackageTable items={items} />
          </div>
        </>
      ) : (
        <div>No items To Show</div>
      )}
      {error && <Box className={setSuffix('__submissionError')}>{error}</Box>}
      {issues?.length ? (
        <div className={setSuffix('__buttons')}>
          {isLoading && <Loader />}
          <PrimaryButton variant="red" disabled={isLoading} onClick={() => handleSave('ignored')}>
            Ignore Suggestion
          </PrimaryButton>
          <PrimaryButton type="submit" disabled={isLoading} onClick={() => handleSave('accepted')}>
            Accept Suggestion
          </PrimaryButton>
        </div>
      ) : (
        <div className={setSuffix('__status-message')}>
          <span className={setSuffix(`__status-message--${suggestionStatus}`)}>Suggestion {suggestionStatus}</span>
        </div>
      )}
    </div>
  );
};

ThirdPartyEditDocument.propTypes = {
  data: PropTypes.object,
  onDataFetch: PropTypes.func,
};
ThirdPartyEditDocument.defaultProps = {
  data: {},
  onDataFetch: () => {},
};

export default ThirdPartyEditDocument;
