import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useGroupPermissions from '../../../hooks/useGroupPermissions';
import useSelect from '../../../hooks/useSelect';
import {
  selectUserAccessByLicense,
  selectUserPermissions,
  selectUserType,
} from '../../../store/selectors/auth.selector';
import { PermissionGroupContext } from '../../../utilities/services/contexts';

const ProtectedComponent = ({ requiredPermissions, permissionGroup, children, allowedUserTypes, licenceAccessKey }) => {
  const groupPermissions = useGroupPermissions(permissionGroup);
  const userInfo = useSelect(selectUserPermissions);
  const userType = useSelect(selectUserType);
  const platformAccess = useSelector(selectUserAccessByLicense);
  const selectedPermissions = userInfo[permissionGroup];

  const isAllowed = allowedUserTypes ? allowedUserTypes.some(r => r === userType) : true;

  const hasPermissions =
    permissionGroup !== ''
      ? requiredPermissions.some(rp => selectedPermissions?.find(permission => permission === rp))
      : true;

  const hasAccessByLicence = useMemo(() => {
    if (!licenceAccessKey) return true; // if key not passed do not check access
    return platformAccess.some(el => el === licenceAccessKey);
  }, [licenceAccessKey]);

  return hasPermissions && isAllowed && hasAccessByLicence ? (
    <PermissionGroupContext.Provider value={groupPermissions}>{children}</PermissionGroupContext.Provider>
  ) : null;
};
ProtectedComponent.propTypes = {
  permissionGroup: PropTypes.string,
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element,
  allowedUserTypes: PropTypes.arrayOf(PropTypes.string),
  licenceAccessKey: PropTypes.string,
};

ProtectedComponent.defaultProps = {
  permissionGroup: '',
  requiredPermissions: [],
  children: null,
  allowedUserTypes: null,
  licenceAccessKey: undefined,
};

export default ProtectedComponent;
