import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import toastr from 'toastr';
import api from '../../../../../api';
import FormModal from '../../../../../components/Modal/FormModal';
import ExistingEntitiesTableRow from '../../../../../components/Select/components/ExistingEntitiesTableRow';
import SelectContentTable from '../../../../../components/Select/components/SelectContentTable';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../components/SelectField';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import { BatchContext } from '../../../../../utilities/services/contexts';
import TableHeaders from '../../../../../utilities/services/TableHeaders';
import './styles.scss';

const BatchAtachUsersToCaseModal = ({ hideModal, onBatchAction, complianceLogId, initialAttachments }) => {
  const [params, setParams] = useState({ limit: 5, skip: 0, sortOrder: 1, sortCriteria: 'name' });
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    const fetchUsers = () => {
      setIsLoading(true);
      api.kyc.caseManagement
        .getCaseAttachmentSubjects(params)
        .then(({ data: response }) => {
          const mappedList = response.data.map(el => ({ ...el, value: el.id, label: el.name }));
          setEntities(mappedList);
          setPagination(response.paginationData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchUsers();
  }, [params]);

  const { listOfSelectedIDs } = useContext(BatchContext) || [];

  const caseIds = useMemo(() => {
    if (!listOfSelectedIDs.length) {
      return [complianceLogId];
    }
    if (!listOfSelectedIDs.some(eId => eId === complianceLogId)) {
      return [...listOfSelectedIDs, complianceLogId];
    }
    return listOfSelectedIDs;
  }, [listOfSelectedIDs]);

  const handleSubmit = async values => {
    const { attachedUsers } = values;
    const entityIds = attachedUsers.map(({ id, name, label }) => ({ id, name: name || label }));
    return api.kyc.caseManagement
      .batchAttachEntities({ entityIds, complianceLogsIds: caseIds })
      .then(({ data }) => {
        onBatchAction(data);
        toastr.success('Cases are Successfully attached to Case');
        hideModal();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errData?.message || 'Error Occured' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  return (
    <FormModal
      hideModal={hideModal}
      title={<h3>Attach Users</h3>}
      onSubmit={handleSubmit}
      showErrorsInConfirmation
      className="ickyc-case-attachments-modal"
      initialValues={{
        attachedUsers: initialAttachments,
      }}
    >
      <h3>Case Attachment</h3>

      <div>
        <p>The case will be attached to KYC subject(s):</p>
        <Field
          component={SelectField}
          Trigger={<SelectTrigger />}
          options={entities}
          withTableContent
          Content={
            <SelectContentTable
              tableProps={{
                tableRow: ExistingEntitiesTableRow,
                headerRow: TableHeader,
                handleParamsChange: changeParams,
                pagination,
                headerData: {
                  sortCriteria: params.sortCriteria,
                  sortOrder: params.sortOrder,
                  onClick: changeParams,
                  items: TableHeaders.ExistingEntities,
                },
                updating: isLoading,
                withLoadMore: true,
                withPagination: true,
                className: 'ickyc-existing-entities-table',
              }}
            />
          }
          multipleSelection
          name="attachedUsers"
          useFullValue
        />
      </div>
    </FormModal>
  );
};

BatchAtachUsersToCaseModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  initialAttachments: PropTypes.arrayOf(PropTypes.shape({})),
  entity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    country: PropTypes.number,
    additionalInfo: PropTypes.string,
  }),
  complianceLogId: PropTypes.string,
  onBatchAction: PropTypes.func,
};

BatchAtachUsersToCaseModal.defaultProps = {
  entity: null,
  complianceLogId: undefined,
  initialAttachments: [],
  onBatchAction: () => {},
};

export default BatchAtachUsersToCaseModal;
