/* eslint-disable max-len */
import React from 'react';

const Camera = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 50.3 42.5" fill="currentColor">
    <path d="M48.4,8.5c1.3,1.2,2,2.9,2,4.6V36c0,1.8-0.7,3.4-2,4.6c-1.3,1.3-3,1.9-4.7,1.9H6.7    c-1.8,0-3.5-0.7-4.7-1.9c-1.3-1.2-2-2.9-2-4.6V13.1c0-1.7,0.7-3.4,2-4.6c1.3-1.3,3-1.9,4.7-1.9h5.9l1.3-3.5c0.4-0.9,1-1.6,1.8-2.2    C16.5,0.3,17.5,0,18.5,0h13.4c1,0,1.9,0.3,2.7,0.9c0.8,0.5,1.4,1.3,1.8,2.2l1.3,3.5h5.9C45.4,6.5,47.1,7.2,48.4,8.5z M25.2,37.5    c7.7,0,14-6.3,14-14c0-7.7-6.3-14-14-14s-14,6.3-14,14S17.4,37.5,25.2,37.5L25.2,37.5z M26.6,17.5l3.1,3.1c0.5,0.5,1.2,0.5,1.7,0    c0.5-0.5,0.5-1.2,0-1.7l-5.1-5.1c-0.5-0.5-1.2-0.5-1.7,0l-5.1,5.1c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0l0,0l3.1-3.1v8.3    c0,0.7,0.5,1.2,1.2,1.2c0.7,0,1.2-0.5,1.2-1.2L26.6,17.5L26.6,17.5z M18,29.5h15c0.7,0,1.2,0.5,1.2,1.2c0,0.7-0.5,1.2-1.2,1.2    c0,0,0,0,0,0H18c-0.7,0-1.2-0.5-1.2-1.2C16.8,30.1,17.3,29.5,18,29.5C18,29.5,18,29.5,18,29.5z" />
  </svg>
);

export default Camera;
