import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import CloseButton from 'components/Buttons/CloseButton';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import Modal from '..';
import './styles.scss';

const WizardFormModal = ({
  hideModal,
  titles,
  buttonTitles,
  onSubmitHandler,
  children,
  initialValues,
  className,
  renderAsPortal,
  disableSubmitButton,
  showErrorsInConfirmation,
  shiftFocusToSubmit,
  ...rest
}) => {
  const [page, setPage] = useState(0);
  const [values, setValues] = useState(initialValues || {});
  const baseClass = 'ickyc-wizard-form';
  const submitButtonRef = useRef(null);
  const modalRef = useRef(null);

  const contentDivRef = useRef(null);

  const next = vals => {
    setPage(prev => Math.min(prev + 1, children.length - 1));
    setValues(vals);

    if (contentDivRef.current) {
      contentDivRef.current.scrollTop = 0;
    }
    modalRef.current.focus();
  };

  const previous = () => setPage(prev => Math.max(prev - 1, 0));

  const isLastPage = page === React.Children.count(children) - 1;

  const activePage = React.Children.toArray(children)[page];

  const activeButtonTitle = buttonTitles && Array.isArray(buttonTitles) ? buttonTitles[page] : buttonTitles;

  const onSubmit = vals => {
    if (isLastPage) {
      return onSubmitHandler(vals);
    }
    next(values);
  };
  const AdditionalFooterContent = React.Children.toArray(activePage?.props?.footerContent)[0];

  const validate = vals => {
    return activePage?.props?.validate ? activePage.props.validate(vals) : {};
  };

  const currentTitle = useMemo(() => (Array.isArray(titles) ? titles[page] : titles), [titles, page]);
  const renderErrors = err => {
    return (
      <div className={`${baseClass}__submissionError`}>
        {Array.isArray(err) ? (
          <ul>
            {err.map(e => (
              <li>{e}</li>
            ))}
          </ul>
        ) : (
          err
        )}
      </div>
    );
  };

  const modalClasses = classNames({ [className]: className, [baseClass]: true });

  useEffect(() => {
    shiftFocusToSubmit && submitButtonRef.current.focus();
  }, [shiftFocusToSubmit]);

  return (
    <Modal hideModal={hideModal} renderAsPortal={renderAsPortal} ref={modalRef}>
      <FinalForm initialValues={values} validate={validate} onSubmit={onSubmit} {...rest}>
        {({
          handleSubmit,
          submitting,
          submitError,
          modifiedSinceLastSubmit,
          pristine,
          leaveModalOpen,
          closeLeaveModal,
          showConfirmationForm,
          onRejectClick,
          onConfirmClick,
          confirmButtonText,
          confirmationText,
          rejectButtonText,
          openLeaveModal,
          valid,
          submitFailed,
          submitSucceeded,
        }) => {
          if (leaveModalOpen && pristine && !submitSucceeded) hideModal();
          return (
            <form onSubmit={handleSubmit} className={modalClasses}>
              <h2 className={`${baseClass}__header`}>
                {currentTitle}
                <CloseButton
                  transparent
                  onClick={() => {
                    openLeaveModal();
                  }}
                />
              </h2>
              <div
                ref={contentDivRef}
                className={`${baseClass}__content ${submitting ? `${baseClass}__content--loading` : ''}`}
              >
                {activePage}
                {isLastPage && submitError && !modifiedSinceLastSubmit && renderErrors(submitError)}
              </div>
              <div className={`${baseClass}__footer`}>
                {page > 0 ? (
                  <PrimaryButton variant="link" type="button" onClick={previous} disabled={submitting}>
                    « Previous
                  </PrimaryButton>
                ) : (
                  <PrimaryButton variant="link" type="button" onClick={() => openLeaveModal()} disabled={submitting}>
                    Cancel
                  </PrimaryButton>
                )}
                <span className={`${baseClass}__footer__spacer`} />
                {AdditionalFooterContent}
                <PrimaryButton
                  type="submit"
                  disabled={(isLastPage && submitting) || disableSubmitButton || (!valid && submitFailed)}
                  ref={submitButtonRef}
                >
                  {activeButtonTitle || (isLastPage ? 'Submit' : 'Next »')}
                  {submitting && <CircularProgress />}
                </PrimaryButton>
              </div>
              {showConfirmationForm && (
                <div className={`${baseClass}__warning`}>
                  <div>
                    {showErrorsInConfirmation && submitError && renderErrors(submitError)}
                    <h2 className={`${baseClass}__warning--message`}>{confirmationText}</h2>
                    <div>
                      <OutlineButton disabled={submitting} onClick={onRejectClick}>
                        {rejectButtonText}
                      </OutlineButton>
                      <PrimaryButton disabled={submitting} type="submit" onClick={onConfirmClick}>
                        {confirmButtonText}
                        {submitting && <CircularProgress />}
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              )}
              {leaveModalOpen && (
                <div className={`${baseClass}__warning`}>
                  <div>
                    <h2 className={`${baseClass}__warning--message`}>
                      Are you sure you want to navigate away from this window and discard the data?
                    </h2>
                    <div>
                      <OutlineButton onClick={closeLeaveModal}>Stay</OutlineButton>
                      <PrimaryButton onClick={hideModal}>Leave</PrimaryButton>
                    </div>
                  </div>
                </div>
              )}
            </form>
          );
        }}
      </FinalForm>
    </Modal>
  );
};
WizardFormModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  titles: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    ]),
  ),
  onSubmitHandler: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  className: PropTypes.string,
  withConfirmationPage: PropTypes.bool,
  buttonTitles: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])]),
  ),
  renderAsPortal: PropTypes.bool,
  showErrorsInConfirmation: PropTypes.bool,
  disableSubmitButton: PropTypes.bool,
};

WizardFormModal.defaultProps = {
  titles: [],
  initialValues: {},
  className: undefined,
  withConfirmationPage: false,
  buttonTitles: undefined,
  renderAsPortal: true,
  showErrorsInConfirmation: false,
  disableSubmitButton: false,
};

export default WizardFormModal;
