import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import CheckIcon from '../../assets/RoundedCheckMark';
import RejectIcon from '../../assets/RoundingRejectIcon';
import { DOCUMENT_MESSAGE } from '../IdentityDocumentsSection/components/ManualDocumentUpload/utilities/documentUploadEnums';
import './styles.scss';

const DetectionTopInfo = ({ code, errorOnSubmit }) => {
  const variants = [
    {
      icon: <CheckIcon />,
      text: 'Document Detected',
    },
    {
      icon: <RejectIcon />,
      text: 'Document MRZ Not Detected',
    },
    {
      icon: <RejectIcon />,
      text: 'Face Was Not Detected',
    },
    {
      icon: <RejectIcon />,
      text: 'Document Not Detected',
    },
  ];
  const baseClass = 'ickyc-detection-top-info';
  const classes = classNames({
    [baseClass]: true,
    [`${baseClass}--detected`]: code === DOCUMENT_MESSAGE.DETECTED,
    [`${baseClass}--warning`]: code === DOCUMENT_MESSAGE.WARNING,
    [`${baseClass}--error`]: code === DOCUMENT_MESSAGE.NOT_DETECTED,
  });
  return (
    <div className={classes}>
      {errorOnSubmit ? (
        <>
          {variants[3].icon}
          {errorOnSubmit}
        </>
      ) : (
        <>
          {variants[code].icon}
          {variants[code].text}
        </>
      )}
    </div>
  );
};
DetectionTopInfo.propTypes = {
  code: PropTypes.number,
  errorOnSubmit: PropTypes.string,
};
DetectionTopInfo.defaultProps = {
  code: 0,
  errorOnSubmit: null,
};
export default DetectionTopInfo;
