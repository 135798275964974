import api from 'api';
import CustomQuestionIcon from 'assets/CustomQuestionIcon';
import classNames from 'classnames';
import DeleteButton from 'components/Buttons/DeleteButton';
import CheckboxGroupField from 'components/CheckboxGroupField';
import FormActions from 'components/DocumentsAndCustomQuestions/components/DocumentsAndCustomQuestionsTableRow/components/FormActions';
import IComplyForm from 'components/Form/IComplyForm';
import InputField from 'components/InputField';
import FormModal from 'components/Modal/FormModal';
import RadioGroupField from 'components/RadioGroupField';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import TextareaField from 'components/TextareaField';
import Tooltip from 'components/Tooltip';
import useModalHandler from 'hooks/useModalHandler';
import useSelect from 'hooks/useSelect';
import React, { useCallback, useContext, useMemo } from 'react';
import { Field } from 'react-final-form';
import CollapsibleTableRow from 'scenes/Kyc/components/CollapsibleTableRow';
import {
  extraIdentifiers,
  itemTypes,
  typeToProps,
} from 'scenes/Kyc/EntityManagement/components/AdditionalDocumentsForm/components/PackageItemsFields/utils';
import { selectCurrencies } from 'store/selectors/global.selector';
import toastr from 'toastr';
import setClassSuffix from 'utilities/services/ClassManager';
import { DocumentDataContext, EntityContext, HistoryRecordContext } from 'utilities/services/contexts';
import validators from 'utilities/services/validators';
import './styles.scss';

const CustomQuestionTableRow = ({ className, index, rowInfo, onPackageItemDelete }) => {
  const { itemName, itemType, itemId, answers } = rowInfo;
  const { content, questionParameters } = answers[0];
  const currencies = useSelect(selectCurrencies);

  const { entityId } = useContext(EntityContext);
  const { eventHistoryId } = useContext(HistoryRecordContext);
  const { setDocumentRecord } = useContext(DocumentDataContext);

  const contentObject = JSON.parse(content);
  const questionParametersObject = JSON.parse(questionParameters);

  const classes = classNames('ickyc-document-package-table-row', className);
  const setSufix = setClassSuffix(`${className}__column`);

  const {
    open: openConfirmationMOdal,
    close: closeConfirmationModal,
    isOpen: isConfirmationModalOpen,
  } = useModalHandler();

  const answerText = useMemo(() => {
    const formatters = {
      [itemTypes.CURRENCYFIELD]: ({ symbol, amount }) => symbol + amount,
      [itemTypes.TEXTFIELD]: ({ typedText }) => typedText,
      [itemTypes.NUMERICALFIELD]: ({ insertedNumber }) => insertedNumber,
      [itemTypes.PERCENTAGEFIELD]: ({ insertedNumber }) => `${insertedNumber}%`,
      [itemTypes.MULTIPLECHOICE]: ({ acceptedAnswers, otherAnswer }) => [...acceptedAnswers, otherAnswer].join(', '),
      [itemTypes.DISCRETECHOICE]: ({ acceptedAnswer, otherAnswer }) => otherAnswer || acceptedAnswer,
    };

    return formatters[itemType]?.(contentObject) ?? 'N/A';
  }, [itemType, contentObject]);

  const answerComponent = useMemo(() => {
    const typeToAnswerComponent = {
      [itemTypes.TEXTFIELD]: ({ typedText }) => (
        <Field
          name="answer.typedText"
          validate={validators.required()}
          component={TextareaField}
          {...typeToProps(itemType, questionParameters)}
          initialValue={typedText}
        />
      ),
      [itemTypes.CURRENCYFIELD]: ({ currency, amount }) => {
        const currencyProps = typeToProps(itemType, questionParameters, extraIdentifiers.CURRENCYSELECT);
        return (
          <div className="ickyc-package-items-fields__currency">
            <Field
              component={SelectField}
              name="answer.currency"
              validate={validators.required()}
              Trigger={<SelectTrigger />}
              Content={<SelectFilterContent />}
              {...currencyProps}
              initialValue={currencyProps?.options?.find(({ value }) => value === currency)?.value}
            />
            <Field
              name="answer.amount"
              validate={validators.required()}
              component={InputField}
              positiveNumbersOnly
              {...typeToProps(itemType, questionParameters, extraIdentifiers.CURRENCYAMOUNT)}
              initialValue={amount}
            />
          </div>
        );
      },
      [itemTypes.NUMERICALFIELD]: ({ insertedNumber }) => (
        <Field
          name="answer.insertedNumber"
          validate={validators.required()}
          component={InputField}
          type="number"
          {...typeToProps(itemType, questionParameters)}
          initialValue={insertedNumber}
        />
      ),
      [itemTypes.MULTIPLECHOICE]: ({ acceptedAnswers, otherAnswer }) => (
        <div className="ickyc-package-items-fields__multiple-choice">
          <Field
            name="answer.acceptedAnswers"
            validate={validators.required()}
            component={CheckboxGroupField}
            {...typeToProps(itemType, questionParameters)}
            initialValue={acceptedAnswers}
          />
          <Field
            name="answer.otherAnswer"
            component={InputField}
            label="Other Answer"
            topLabeled
            labelPosition={'top'}
            initialValue={otherAnswer}
          />
        </div>
      ),
      [itemTypes.PERCENTAGEFIELD]: ({ insertedNumber }) => (
        <Field
          name="answer.insertedNumber"
          validate={validators.required()}
          component={InputField}
          type="number"
          min={0}
          preffix="%"
          {...typeToProps(itemType, questionParameters)}
          initialValue={insertedNumber}
        />
      ),
      [itemTypes.DISCRETECHOICE]: ({ acceptedAnswer, otherAnswer }) => (
        <div className="ickyc-package-items-fields__discrete-choice">
          <Field
            name="answer.acceptedAnswer"
            validate={validators.required()}
            component={RadioGroupField}
            {...typeToProps(itemType, questionParameters)}
            initialValue={acceptedAnswer}
          />
          <Field
            name="answer.otherAnswer"
            component={InputField}
            label="Other Answer"
            topLabeled
            labelPosition={'top'}
            initialValue={otherAnswer}
          />
        </div>
      ),
    };

    return typeToAnswerComponent[itemType]?.(contentObject) ?? 'N/A';
  }, [questionParametersObject, contentObject, itemType, itemId]);

  const handleSubmit = useCallback(
    async values => {
      const { answer } = values;

      try {
        await api.kyc.entityManagement.updateSupportingDocumentPackageItem(entityId, eventHistoryId, itemId, {
          type: itemType,
          answer: JSON.stringify(answer),
        });
        setDocumentRecord(old => ({
          ...old,
          items: old.items.map(item => {
            const { answers } = item;
            const oldAnswer = answers[0];
            const oldContent = JSON.parse(oldAnswer?.content || '{}');
            let content = JSON.stringify({ ...oldContent, ...answer });
            if (itemType === itemTypes.CURRENCYFIELD) {
              const symbol = currencies.find(currency => currency.id === answer.currency)?.symbol;
              content = JSON.stringify({ ...oldContent, currency: answer.currency, amount: answer.amount, symbol });
            }

            return item.itemId !== itemId ? item : { ...item, answers: [{ ...oldAnswer, content }] };
          }),
        }));
        toastr.success('Answer updated successfully');
      } catch (err) {
        toastr.error('Error occured while updating answer');
        console.error(err);
      }
    },
    [entityId, eventHistoryId, itemId],
  );

  return (
    <>
      <CollapsibleTableRow
        className={classes}
        row={
          <>
            <td className={setSufix('__index')}>
              <span>{index + 1}</span>
            </td>
            <td className={setSufix('__type')}>
              <span>{<CustomQuestionIcon />}</span>
            </td>
            <td className={setSufix('__name')}>
              <span>{itemName}</span>
            </td>
            <td className={setSufix('__submission')}>
              <Tooltip
                content={answerText}
                trigger={<span className={setSufix('__submission__answer')}>{answerText}</span>}
              />
            </td>
            <td className={setSufix('__remove')}>
              <span>
                <DeleteButton onClick={openConfirmationMOdal} />
              </span>
            </td>
          </>
        }
      >
        <IComplyForm onSubmit={handleSubmit}>
          {answerComponent}
          <FormActions />
        </IComplyForm>
      </CollapsibleTableRow>
      {isConfirmationModalOpen && (
        <FormModal
          hideModal={closeConfirmationModal}
          title="Remove Package Item"
          className="ickyc-confirmation-modal"
          onSubmit={onPackageItemDelete}
        >
          <span className="ickyc-confirmation-message">Are you sure you want to delete this item ?</span>
        </FormModal>
      )}
    </>
  );
};
export default CustomQuestionTableRow;
