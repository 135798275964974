import axiosInstance from '../axiosInstance';

const ENDPOINTS = {
  getRecentCases: () => '/entities/get-my-recent-cases',
  getMyOpenCases: () => '/entities/get-my-open-cases',
  getSummaryInfo: () => '/entities/get-summary-data',
};

const getRecentCases = params => {
  return axiosInstance.get(ENDPOINTS.getRecentCases(), { params });
};

const getMyOpenCases = params => {
  return axiosInstance.get(ENDPOINTS.getMyOpenCases(), { params });
};

const getSummaryInfo = () => {
  return axiosInstance.get(ENDPOINTS.getSummaryInfo());
};
export default {
  getRecentCases,
  getMyOpenCases,
  getSummaryInfo,
};
