import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import ButtonHeading from '../../../../components/Headings/ButtonHeading';
import IconManager from '../../../../utilities/services/IconManager';
import CommentsSections from '../CommentsSection';
import './styles.scss';

const CommentSection = ({ commentNumber, ...rest }) => {
  const pageRef = useRef(null);
  const [shouldOpenComModal, setShouldOpenComModal] = useState(false);
  return (
    <div className="ickyc-comment-section">
      <ButtonHeading
        h1={false}
        title={`Comments ${commentNumber ? { commentNumber } : ''}`}
        buttonCopies={[
          <>
            <span>{IconManager.get(IconManager.names.MESSAGE)}</span>
            Add Comment
          </>,
        ]}
        clickHandlers={[() => setShouldOpenComModal(true)]}
      />

      <CommentsSections
        isCollapsible={false}
        openModalOutside={shouldOpenComModal}
        setOpenModalOutside={setShouldOpenComModal}
        pageRef={pageRef}
        {...rest}
      />
    </div>
  );
};

CommentSection.propTypes = {
  commentNumber: PropTypes.number,
};

CommentSection.defaultProps = {
  commentNumber: null,
};

export default CommentSection;
