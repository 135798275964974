import PropTypes from 'prop-types';
import React from 'react';
import SortingButton from '../../../../../components/Buttons/SortingButton';
import enums from '../../../../../utilities/enums';
import setClassSuffix from '../../../../../utilities/services/ClassManager';

function HistoryRecordTableHeader({
  className,
  onClick,
  sortCriteria,
  sortOrder,
  hasSourceColumn,
  withDelete,
  hasServicesColumn,
}) {
  function handleSort(newSortCriteria) {
    onClick({ sortCriteria: newSortCriteria, sortOrder: sortOrder ? 0 : 1 });
  }
  const headerData = [
    {
      type: 'date',
      label: 'Date',
    },
    {
      type: 'source',
      label: 'Source',
    },
    {
      type: 'assignedTo',
      label: 'Assigned To',
    },
    {
      type: 'status',
      label: 'Status',
    },
    {
      type: 'issues',
      label: 'Issues',
    },
  ];

  if (!hasSourceColumn) {
    headerData[1].type = 'type';
    headerData[1].label = 'Type';
  }

  if (hasServicesColumn) {
    headerData.splice(2, 0, { type: 'services', label: 'Services' });
  }

  const setSuffix = setClassSuffix(`${className}__column`);

  return (
    <thead>
      <tr>
        <th className={setSuffix('__collapse')}>
          <span />
        </th>
        {headerData.map(({ type, label }) => (
          <th className={setSuffix(`__${type}`)} key={type + sortOrder}>
            <SortingButton
              sortType={type === sortCriteria ? sortOrder : enums.SORTING.CHAOTIC}
              onClick={() => handleSort(type)}
            >
              {label}
            </SortingButton>
          </th>
        ))}
        {withDelete && (
          <th className={setSuffix('__remove')}>
            <span />
          </th>
        )}
      </tr>
    </thead>
  );
}
HistoryRecordTableHeader.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string.isRequired,
  sortCriteria: PropTypes.string.isRequired,
  sortOrder: PropTypes.number.isRequired,
  hasSourceColumn: PropTypes.bool,
  withDelete: PropTypes.bool,
  hasServicesColumn: PropTypes.bool,
};
HistoryRecordTableHeader.defaultProps = {
  onClick: () => {},
  withDelete: false,
  hasSourceColumn: false,
  hasServicesColumn: false,
};
export default HistoryRecordTableHeader;
