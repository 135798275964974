import PropTypes from 'prop-types';
import React from 'react';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';

const ThirdPartyReportMessages = ({ className, info }) => {
  const { messages, note } = info;

  const errorMap = new Map([
    ['incorrectData', 'The client data is incorrect.'],
    ['notMyClient', 'This is not my client.'],
    ['confirmViaPortal', 'I decline to provide confirmation via portal.'],
    ['somethingElse', 'Something else is wrong.'],
  ]);
  const mappedReports = messages?.map(report => <li>{errorMap?.get(report) || 'An error has occured.'}</li>);

  const setSuffix = setClassSuffix(className);

  return (
    <div className={setSuffix('__reports')}>
      <ul>{mappedReports}</ul>
      {note && <div>Note: {note}</div>}
    </div>
  );
};

ThirdPartyReportMessages.propTypes = {
  className: PropTypes.string,
  info: PropTypes.object,
};
ThirdPartyReportMessages.defaultProps = {
  className: 'ickyc-third-party-confirm',
  info: {},
};

export default ThirdPartyReportMessages;
