import React from 'react';

/* eslint-disable max-len */

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <path
      d="M27 11h-3.47a9.53 9.53 0 00-.67-1.62l2.45-2.45a1 1 0 000-1.42l-2.82-2.82a1 1 0 00-1.42 0l-2.45 2.45A9.53 9.53 0 0017 4.47V1a1 1 0 00-1-1h-4a1 1 0 00-1 1v3.47a9.53 9.53 0 00-1.62.67L6.93 2.69a1 1 0 00-1.42 0L2.69 5.51a1 1 0 000 1.42l2.45 2.45c-.12.23-.23.47-.34.71a8.92 8.92 0 00-.33.91H1a1 1 0 00-1 1v4a1 1 0 001 1h3.47a9.53 9.53 0 00.67 1.62l-2.45 2.45a1 1 0 000 1.42l2.82 2.82a1 1 0 001.42 0l2.45-2.45a9.53 9.53 0 001.62.67V27a1 1 0 001 1h4a1 1 0 001-1v-3.47a9.53 9.53 0 001.62-.67l2.45 2.45a1 1 0 001.42 0l2.82-2.82a1 1 0 000-1.42l-2.45-2.45a9.53 9.53 0 00.67-1.62H27a1 1 0 001-1v-4a1 1 0 00-1-1zm-13 8a5 5 0 115-5 5 5 0 01-5 5z"
      fill="currentColor"
    />
  </svg>
);
