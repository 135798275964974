import useModalHandler from 'hooks/useModalHandler';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import toastr from 'toastr';
import api from '../../../../../api';
import Select from '../../../../../components/Select';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import enums from '../../../../../utilities/enums';
import { BatchContext } from '../../../../../utilities/services/contexts';
import ApproveEntitiesModal from './components/ApproveEntitiesModal';
import './styles.scss';

const BatchStatusDropdown = React.memo(
  ({
    assignedUserStatus,
    assignEvent,
    eventId,
    onBatchUpdate,
    assignCase,
    complianceLogId,
    assignUser,
    disabled,
    renderAsPortal,
    label,
    logId,
    preview,
    hint,
    useFullValue,
    className,
    entityId,
    setUpdating,
    ...rest
  }) => {
    const { listOfSelectedIDs } = useContext(BatchContext) || [];
    const { isOpen: isConfirmModalOpen, close: closeConfirmModal, open: openConfirmModal } = useModalHandler();

    const mappedStatuses = useMemo(() => {
      return Object.values(enums.ENTITY_STATUSES).map(status => ({
        id: status,
        label: status,
        value: status,
      }));
    }, []);
    const entityIds = useMemo(() => {
      if (!listOfSelectedIDs.length) {
        return [entityId];
      }
      if (!listOfSelectedIDs.some(eId => eId === entityId)) {
        return [...listOfSelectedIDs, entityId];
      }
      return listOfSelectedIDs;
    }, [listOfSelectedIDs]);

    const batchAssignStatus = useCallback(
      newStatus => {
        if (newStatus === 'Approved') {
          openConfirmModal();
          return;
        }
        setUpdating(entityIds);
        const submitObj = {
          entityIds,
          entityStatus: newStatus,
        };
        api.kyc
          .batchAssignStatus(submitObj)
          .then(response => {
            onBatchUpdate(response?.data);
            toastr.success('Entities successfully updated!');
          })
          .catch(err => {
            console.log(err);
            toastr.error('Error occurred during update');
          })
          .finally(() => {
            setUpdating([]);
          });
      },
      [entityId, onBatchUpdate, entityIds],
    );

    const handleStatusAssign = newStatus => {
      batchAssignStatus(newStatus);
    };

    const handleApproveEntities = async data => {
      setUpdating(entityIds);
      const submitObj = {
        entityIds,
        entityStatus: 'Approved',
        acceptIssues: Boolean(data?.acceptIssues),
      };
      return api.kyc
        .batchAssignStatus(submitObj)
        .then(response => {
          onBatchUpdate(response?.data);
          closeConfirmModal();
          toastr.success('Entities successfully updated!');
        })
        .catch(err => {
          console.log(err);
          toastr.error('Error occurred during update');
        })
        .finally(() => {
          setUpdating([]);
        });
    };

    return (
      <>
        <Select
          value={assignedUserStatus}
          onChange={handleStatusAssign}
          options={mappedStatuses}
          useFullValue={false}
          renderAsPortal={false}
          disabled={disabled}
          label={label}
          preview={preview}
          hint={hint}
          Trigger={<SelectTrigger placeholder="N/A" withoutArrow />}
          Content={<SelectFilterContent />}
          className={className}
          {...rest}
        />
        {isConfirmModalOpen && <ApproveEntitiesModal hideModal={closeConfirmModal} onApprove={handleApproveEntities} />}
      </>
    );
  },
);
BatchStatusDropdown.propTypes = {
  assignedUserStatus: PropTypes.string,
  assignEvent: PropTypes.bool,
  eventId: PropTypes.string,
  onBatchUpdate: PropTypes.func,
  assignCase: PropTypes.bool,
  assignUser: PropTypes.bool,
  complianceLogId: PropTypes.string,
  disabled: PropTypes.bool,
  renderAsPortal: PropTypes.bool,
  label: PropTypes.string,
  preview: PropTypes.bool,
  hint: PropTypes.string,
  listingId: PropTypes.string,
  assignlisting: PropTypes.bool,
  hitId: PropTypes.string,
  logId: PropTypes.string,
  input: PropTypes.shape(PropTypes.object),
  useFullValue: PropTypes.bool,
  className: PropTypes.string,
  entityId: PropTypes.string.isRequired,
  setUpdating: PropTypes.func,
};
BatchStatusDropdown.defaultProps = {
  assignedUserStatus: undefined,
  assignCase: false,
  assignEvent: false,
  eventId: undefined,
  complianceLogId: undefined,
  assignUser: undefined,
  onBatchUpdate: () => {},
  renderAsPortal: false,
  disabled: false,
  label: undefined,
  preview: false,
  hint: undefined,
  listingId: undefined,
  assignlisting: false,
  hitId: undefined,
  logId: undefined,
  input: {},
  useFullValue: true,
  className: undefined,
  setUpdating: () => {},
};

export default BatchStatusDropdown;
