import React from 'react';

/* eslint-disable max-len */

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.7 90.7" className="icomply-header-icon">
    <path
      d="M46 11.3c-19.6 0-35.6 15.9-35.6 35.6 0 17 12 31.6 28.6 34.9V58.9c-6.6-3.8-8.8-12.3-5-18.9 3.8-6.6 12.3-8.9 18.9-5.1 6.6 3.8 8.9 12.3 5.1 18.9-1.2 2.1-3 3.9-5.1 5.1V81.7c19.3-3.8 31.8-22.6 27.9-41.8C77.5 23.3 62.9 11.3 46 11.3z"
      fill="currentColor"
    />
  </svg>
);
