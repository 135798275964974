import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { putBrAfterClosingPTag } from 'scenes/Administration/Emails/utils';

const toHtml = es => {
  const html = draftToHtml(convertToRaw(es.getCurrentContent()));

  const htmlWithBreaksInsteadOfEmptyParagraphs = html.replace(/(<p><\/p>)/g, '<br>');

  return putBrAfterClosingPTag(htmlWithBreaksInsteadOfEmptyParagraphs);
};

export default {
  toHtml,
};
