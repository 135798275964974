import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import IComplySVGWhite from '../../../assets/IComply';
import CloseButton from '../../../components/Buttons/CloseButton';
import ResponsiveNavigationList from './components/ResponsiveNavigationList';
import './styles.scss';

/**
 * Responsive sidebar menu navigation.
 * @param {func} activePage - page which content is displayed
 * @param {func} activePageHandler - active page setter
 * @param {boolean} isOpen - flag which denotes if responsive menu is displayed
 * @param {func} hideModal - function which hides the modal
 * @param {reference} container - container reference used for modal logic
 */

const ResponsiveSideMenu = ({ isOpen, hideModal }) => {
  const navRef = useRef(null);

  const classes = classNames({
    'ickyc-drawer__menu': true,
    'ickyc-drawer__menu--opened': isOpen,
  });

  return (
    <div className="ickyc-drawer" tabIndex="-1" ref={navRef}>
      <nav className={classes}>
        <div>
          <IComplySVGWhite viewBox="0 0 401 63" />

          <CloseButton transparent onClick={hideModal} />
        </div>

        <ResponsiveNavigationList />
      </nav>

      {isOpen && <div onClick={hideModal} />}
    </div>
  );
};

ResponsiveSideMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default ResponsiveSideMenu;
