import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import RadioButtonField from '../../../../components/RadioButtonField';
import './styles.scss';

const FaceMatchRadioButtons = ({ modulePreffix }) => {
  return (
    <span className="ickyc-face-match-radio-buttons">
      <Field
        component={RadioButtonField}
        classNameSuffix="--automatic"
        name={`${modulePreffix}.biometricAuthenticationType`}
        type="radio"
        value="Live Face Match"
        label="Live Face Match"
      />
      <Field
        component={RadioButtonField}
        name={`${modulePreffix}.biometricAuthenticationType`}
        type="radio"
        classNameSuffix="--on-demand"
        value="Selfie Upload"
        label="Selfie Upload"
      />
    </span>
  );
};

FaceMatchRadioButtons.propTypes = {
  modulePreffix: PropTypes.string.isRequired,
};

export default React.memo(FaceMatchRadioButtons);
