import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import BareHeading from '../../../components/Headings/BareHeading';
import ProtectedComponent from '../../../router/components/ProtectedComponent';
import authEnums from '../../../utilities/enums/authEnums';
import setClassSuffix from '../../../utilities/services/ClassManager';
import KnowledgeBase from './KnowledgeBase';
import MyOpenCases from './MyOpenCases';
import PersonalSettings from './PersonalSettings';
import RecentActivity from './RecentActivity';
import Summary from './Summary';
import './index.scss';

const HomeScreen = ({ className }) => {
  const baseClass = 'ickyc-home-screen';
  const classes = classNames('ickyc-kyc-page', baseClass, className);
  const setSuffix = setClassSuffix(baseClass);

  const containerRef = useRef(null);
  return (
    <div className={classes} ref={containerRef}>
      <BareHeading title="Home" />
      <div className={setSuffix('__content')}>
        <RecentActivity />
        <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}>
          <MyOpenCases />
        </ProtectedComponent>
        <Summary containerRef={containerRef} />
        <PersonalSettings />
        <KnowledgeBase />
        {/* TODO: Remove "Support" component entirely from project once decided */}
        {/* <Support /> */}
      </div>
    </div>
  );
};
HomeScreen.propTypes = {
  className: PropTypes.string,
};

HomeScreen.defaultProps = {
  className: undefined,
};
export default HomeScreen;
