import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormSpy } from 'react-final-form';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';
import FormModal from '../../../../../components/Modal/FormModal';
import Section from '../../../../../components/Section';
import useSelect from '../../../../../hooks/useSelect';
import { setUserPermissions, setUserType } from '../../../../../store/actions/auth.action';
import { selectPersonalInfo } from '../../../../../store/selectors/auth.selector';
import authEnums from '../../../../../utilities/enums/authEnums';
import { PermissionGroupContext, UserManagementContext } from '../../../../../utilities/services/contexts';
import UserInformationForm from '../UserInformationForm';
import SecurityOptions from './components/SecurityOptions';
import UserTypesPermissions from './components/UserPersimissionTypes';
import './styles.scss';

const EditUserModal = ({ hideModal, updateUser, initialValues, setNewlyAdded }) => {
  const { userInfo, ...restInitialValues } = initialValues;
  const [editUserInfo, setEditUserInfo] = useState(false);

  const [canSubmit, setCanSubmit] = useState(true);
  const dispatch = useDispatch();
  const personalInfo = useSelect(selectPersonalInfo) || {};
  const [initialUserValues, setInitialUserValues] = useState({
    userInfo: userInfo.initialUserInfo,
    ...restInitialValues,
  });
  // FIX REVERT
  useEffect(() => {
    if (
      authEnums.PERMISSION_TAGS_OPTIONS.PLATFORM_USER.map(({ name, permissions }) => {
        return permissions.some(perm => (initialValues[name] || []).includes(perm));
      }).some(el => !!el)
    ) {
      setInitialUserValues(old => ({ ...old, platformUser: true }));
    }
  }, []);

  const revertInitialUserInfo = () => {
    setEditUserInfo(false);
  };
  const { updateUserData, createUser, clientId } = useContext(UserManagementContext);
  const { edit: canEdit } = useContext(PermissionGroupContext);

  const handleSubmit = async values => {
    const { userInfo, ...rest } = values;
    const submitObject = { ...rest, ...userInfo };
    const updateParamsData = clientId
      ? { userId: values.userId, clientId, submitObject }
      : { userId: values.userId, submitObject };
    if (values.userId) {
      return updateUserData(...Object.entries(updateParamsData).map(el => el[1]))
        .then(async res => {
          if (values.userId === personalInfo.id) {
            const { type } = res.data;
            const { userInfo: uInfo, ...permissions } = values;
            dispatch(setUserType(type));
            dispatch(setUserPermissions(permissions));
            if (submitObject.platformUser && submitObject.platformUser) {
              dispatch(setUserType(authEnums.USER_TYPES.FULL_ADMIN));
            } else if (submitObject.platformUser) {
              dispatch(setUserType(authEnums.USER_TYPES.CLIENT_USER));
            } else if (submitObject.adminUser) {
              dispatch(setUserType(authEnums.USER_TYPES.RESTRICTED_ADMIN));
            }
          }
          toastr.success('Successfully updated user');
          updateUser(values.userId, res.data);

          hideModal();
        })
        .catch(err => {
          toastr.error('Failed to update user');

          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        });
    }

    const submitParamsData = clientId ? { clientId, submitObject } : { submitObject };

    return createUser(...Object.entries(submitParamsData).map(el => el[1]))
      .then(res => {
        toastr.success('Successfully created user');
        hideModal();
        setNewlyAdded(res.data);
      })
      .catch(err => {
        toastr.error('Failed to create user');

        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return {
              // eslint-disable-next-line no-nested-ternary
              [FORM_ERROR]: clientId
                ? Array.isArray(errData.errors)
                  ? errData.errors.join('')
                  : errData.errors
                : Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message,
            };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  return (
    <FormModal
      onSubmit={handleSubmit}
      initialValues={initialUserValues}
      hideModal={hideModal}
      title={initialValues?.userId ? 'Edit User' : 'Add User'}
      className="ickyc-edit-user-modal"
      disableSubmitButton={!canEdit || !canSubmit}
      showErrorsInConfirmation
    >
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const { platformUser, adminUser } = values;
          setCanSubmit(adminUser || platformUser);
          return <span />;
        }}
      </FormSpy>
      <UserInformationForm
        responsive
        editable={editUserInfo}
        onCancel={revertInitialUserInfo}
        onUpdate={() => setEditUserInfo(true)}
      />
      <SecurityOptions />
      <Section>
        <UserTypesPermissions />
      </Section>
    </FormModal>
  );
};

EditUserModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ userId: PropTypes.string }),
  setNewlyAdded: PropTypes.func.isRequired,
  updateUser: PropTypes.func,
};
EditUserModal.defaultProps = {
  initialValues: {},
  updateUser: () => {},
};
export default EditUserModal;
