import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import Section from '../../../../../../../../../components/Section';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import licenceEnums from '../../../../../../../../../utilities/enums/licenceEnums';
import validators from '../../../../../../../../../utilities/services/validators';
import './styles.scss';

const Billing = ({ namePreffix, disabled }) => {
  return (
    <Section className="ickyc-billing-section" divider>
      <h3>Billing</h3>
      <Field
        label="Billing Date"
        name={`${namePreffix}.billingDate`}
        options={licenceEnums.BILLING_DAYS_OPTIONS}
        component={SelectField}
        Trigger={<SelectTrigger placeholder="Choose" />}
        Content={<SelectFilterContent />}
        renderAsPortal
        validate={validators.required(' ')}
      />
      <Field
        label="Payment Due"
        name={`${namePreffix}.defaultValues.paymentDueCount`}
        placeholder="Count of"
        type="number"
        component={InputField}
        parse={value => Number(value)}
        validate={validators.required(' ')}
      />
      <Field
        name={`${namePreffix}.defaultValues.paymentDueType`}
        options={licenceEnums.PERIOD_OPTIONS}
        component={SelectField}
        Trigger={<SelectTrigger placeholder="Period" />}
        Content={<SelectFilterContent />}
        renderAsPortal
        validate={validators.required(' ')}
      />

      <Field
        label={'Contract Value'}
        component={InputField}
        name={`${namePreffix}.contractValue`}
        placeholder=". . ."
        preffix="$"
        parse={value => Number(value)}
        type="number"
      />
      <Field
        label="Payment Method"
        name={`${namePreffix}.paymentMethod`}
        options={licenceEnums.PAYMENT_METHODS}
        component={SelectField}
        Trigger={<SelectTrigger placeholder="Choose" />}
        Content={<SelectFilterContent />}
        renderAsPortal
        validate={validators.required(' ')}
      />
    </Section>
  );
};

Billing.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
};
Billing.defaultProps = {
  namePreffix: '',
  disabled: false,
};

export default Billing;
