/* eslint-disable max-len */
import { Lock } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Field, useField } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import RadioButtonField from '../../../../../../../../../components/RadioButtonField';
import RadioGroup from '../../../../../../../../../components/RadioGroup';
import ToggleField from '../../../../../../../../../components/ToggleField';
import { DragContext, PermissionGroupContext } from '../../../../../../../../../utilities/services/contexts';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import DocumentTypesSettings from '../../../../../../../components/DocumentTypesSettings';
import ModuleOption from '../ModuleOption';
import ModuleOptions from '../ModuleOptions';
import ModuleSettings from '../ModuleSettings';
import ModuleTitleSettings from '../ModuleTitleSettings';
import './styles.scss';

const documentVerificationOffPredifinedIndex = 5;
const documentVerificationOnPredifinedIndex = 2;

const DocumentVerificationModule = ({ index, indexToShow, isDropAllowed }) => {
  const className = 'ickyc-table';
  const { createGeneralPortals, createCustomPortals } = useContext(PermissionGroupContext);
  const { setDragList } = useContext(DragContext);
  const hasOnlyGeneralPermission = useMemo(() => createGeneralPortals && !createCustomPortals, [
    createGeneralPortals,
    createCustomPortals,
  ]);

  const {
    input: { value: toggleValue },
  } = useField(`modules[${index}].isEnabled`);
  const {
    input: { value: collectIdentityDocuments },
  } = useField(`modules[${index}].idScanEnabled`);

  const reorderList = useCallback(
    list => {
      const documentVerificationIndex = list.findIndex(item => item.component === DocumentVerificationModule);

      if (
        collectIdentityDocuments &&
        toggleValue &&
        documentVerificationIndex !== documentVerificationOnPredifinedIndex
      ) {
        const documentVerificationModule = list.splice(documentVerificationIndex, 1)[0];
        list.splice(documentVerificationOnPredifinedIndex, 0, documentVerificationModule);
      }

      if (
        documentVerificationIndex !== documentVerificationOffPredifinedIndex &&
        (!collectIdentityDocuments || !toggleValue)
      ) {
        const documentVerificationModule = list.splice(documentVerificationIndex, 1)[0];
        list.splice(documentVerificationOffPredifinedIndex, 0, documentVerificationModule);
      }

      return [...list];
    },
    [collectIdentityDocuments, toggleValue],
  );

  useEffect(() => {
    setDragList(old => reorderList(old));
  }, [setDragList, collectIdentityDocuments, toggleValue, reorderList]);

  return (
    <CollapsibleTableRow
      isDropAllowed={isDropAllowed}
      className={className}
      withIndexColumn
      isToggled={toggleValue}
      index={indexToShow}
      icon={<Lock />}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Document Verification</td>
          <td className={`${className}__column__enabled`} onClick={e => e.stopPropagation()}>
            <Field
              preview
              name={`modules[${index}].isEnabled`}
              component={ToggleField}
              showCustomText
              toggleOnText="Enabled"
            />
          </td>
        </>
      }
    >
      <ModuleSettings>
        <ModuleTitleSettings index={index} withDescription withTwoIDDocuments withCollectIdentityToggle />
        <ModuleOptions>
          <ModuleOption>
            <RadioGroup label="Upload Docu ment / API Pass-In">
              <Field
                component={RadioButtonField}
                name={`modules[${index}].moduleType`}
                type="radio"
                parse={value => Number(value)}
                value={0}
                label="Subject Uploads New Document"
              />
              <Field
                component={RadioButtonField}
                name={`modules[${index}].moduleType`}
                type="radio"
                value={1}
                parse={value => Number(value)}
                label="API Pass In and Skip Screen"
              />
            </RadioGroup>
          </ModuleOption>

          <ModuleOption>
            <RadioGroup label="When Upload/Verification Fails">
              <Field
                component={RadioButtonField}
                name={`modules[${index}].source`}
                type="radio"
                parse={value => Number(value)}
                value={0}
                label={
                  <>
                    Allow&nbsp;&nbsp;
                    <Field
                      name={`modules[${index}].numberOfRetries`}
                      component={InputField}
                      className="document-verification__number-of-retries"
                      max="3"
                      min="0"
                      type="number"
                    />
                    Retries, Then Capture Data
                  </>
                }
              />
              <Field
                component={RadioButtonField}
                name={`modules[${index}].source`}
                type="radio"
                value={1}
                parse={value => Number(value)}
                label="Capture Data"
              />
            </RadioGroup>
          </ModuleOption>
          <ModuleOption>
            <DocumentTypesSettings divider tableName="documentTypesRestrictions" disabled={hasOnlyGeneralPermission} />
          </ModuleOption>
        </ModuleOptions>
      </ModuleSettings>
    </CollapsibleTableRow>
  );
};
DocumentVerificationModule.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
};

export default DocumentVerificationModule;
