import { useEffect, useState } from 'react';
import { selectUserPermissions } from '../store/selectors/auth.selector';
import authEnums from '../utilities/enums/authEnums';
import useSelect from './useSelect';

export default permissionGroup => {
  const [userPermissions, setUserPermissions] = useState({});
  const userInfo = useSelect(selectUserPermissions);
  useEffect(() => {
    if (permissionGroup) {
      setUserPermissions(
        Object.entries(authEnums.PERMISSION_TAGS_MAPPING)
          .filter(entry => userInfo[permissionGroup]?.includes(entry[1]))
          .map(entry => entry[0])
          .reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
      );
    }
  }, [userInfo, permissionGroup]);
  return userPermissions;
};
