import administration from './administration';
import auth from './auth';
import axiosInstance from './axiosInstance';
import businessManager from './businessManager';
import clientUser from './clientUser';
import comments from './comments';
import confirmation from './confirmation';
import homescreen from './homescreen';
import internal from './internal';
import kyc from './kyc';
import notifications from './notifications';
import personalSettings from './personalSettings';
import support from './support';
import token from './token';
import utilities from './utilities';

const getSecurrencySubmissionToken = () => {
  return axiosInstance.get('/corp/get-submission-token');
};

const getWebFonts = (googleAuthToken = 'AIzaSyDFm98F_FaUOAjCRs0SQO1KqVjvxBExmUM') => {
  return axiosInstance.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${googleAuthToken}`);
};

export default {
  clientUser,
  homescreen,
  token,
  comments,
  kyc,
  auth,
  administration,
  utilities,
  personalSettings,
  confirmation,
  getSecurrencySubmissionToken,
  getWebFonts,
  businessManager,
  internal,
  support,
  notifications,
};
