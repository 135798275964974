import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import RadioButton from '../RadioButton';

const RadioButtonField = ({ input, className, onChange, ...rest }) => {
  const handleChange = useCallback(
    e => {
      onChange && onChange(e);
      input && input.onChange(e);
    },
    [onChange, input?.onChange],
  );

  return <RadioButton className={className} type="radio" {...rest} {...input} onChange={handleChange} />;
};

RadioButtonField.propTypes = {
  input: PropTypes.object.isRequired,
  className: PropTypes.string,
};
RadioButtonField.defaultProps = {
  className: undefined,
};
export default RadioButtonField;
