import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import CloseButton from '../../Buttons/CloseButton';
import PrimaryButton from '../../Buttons/PrimaryButton';
import './styles.scss';

const PdfPlaceholder = ({ url, text, withRemove, onRemove }) => {
  const baseClass = 'ickyc-pdf-placeholder';

  const onClick = useCallback(
    event => {
      event.stopPropagation();
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    [url],
  );

  return (
    <div className={baseClass}>
      {withRemove && <CloseButton onClick={onRemove} />}
      <img src="/assets/images/pdf.png" alt="pdfPlaceholder" />
      <PrimaryButton onClick={onClick}>View</PrimaryButton>
      <span>{text}</span>
    </div>
  );
};

export default PdfPlaceholder;

PdfPlaceholder.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string,
  withRemove: PropTypes.bool,
  onRemove: PropTypes.func,
};
PdfPlaceholder.defaultProps = {
  text: '',
  withRemove: false,
  onRemove: () => {},
};
