import IconButton from 'components/Buttons/IconButton';
import React, { useCallback } from 'react';
import IconManager from 'utilities/services/IconManager';

const PlusComponent = ({ text, isChecked, handleShow }) => {
  const handleClick = useCallback(() => {
    handleShow(text);
  }, [text, handleShow]);

  return (
    <div className="licence-item">
      <IconButton onClick={handleClick} transparent>
        {IconManager.get(isChecked ? IconManager.names.CHECK_CIRCULAR : IconManager.names.PLUS_CIRCULAR)}
      </IconButton>
      {text && <p>{text}</p>}
    </div>
  );
};

export default PlusComponent;
