import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';

/**
 * React portal component.
 * @param {*} children - portal content
 * @param {bool} isOpen - is portal open flag
 * @param {func} setIsOpen - setter for displaying poltar
 * @param {func} [setPortalRef = () => {}] - portal reference setter in the parent
 */

const Portal = ({ children, isOpen, setIsOpen }) => {
  const portal = useMemo(() => document.createElement('div'), []);
  const className = 'ickyc-portal';

  useEffect(() => {
    if (!isOpen) return;
    const { body } = document;
    const portals = body?.getElementsByClassName(className);
    if (portals.length < 1) {
      body.appendChild(portal);
      portal.classList.add(className);
      return () => {
        body.removeChild(portal);
      };
    }
  }, [isOpen, portal]);

  useEffect(() => {
    const handleClick = event => {
      const { firstElementChild } = portal;
      if (!firstElementChild.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const setUp = () => {
      portal.addEventListener('click', handleClick);
    };

    const cleanUp = () => {
      portal.removeEventListener('click', handleClick);
    };

    setUp();

    return cleanUp;
  }, [portal, setIsOpen]);
  return ReactDOM.createPortal(children, portal);
};

Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closePortal: PropTypes.func,
};

Portal.defaultProps = {
  closePortal: () => {},
};

export default Portal;
