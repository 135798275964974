import React from 'react';

export default () => (
  <svg fill="none" height="48" viewBox="0 0 70 48" width="70">
    <rect fill="#fff" height="47" rx="5.5" stroke="#d9d9d9" width="69" x=".5" y=".5" />
    <g clipRule="evenodd" fillRule="evenodd">
      <path
        d="m35.3945 34.7619c-2.3831 2.0565-5.4745 3.298-8.8524 3.298-7.5374 0-13.6476-6.1811-13.6476-13.8059 0-7.6249 6.1102-13.806 13.6476-13.806 3.3779 0 6.4693 1.2415 8.8524 3.2981 2.3832-2.0566 5.4745-3.2981 8.8525-3.2981 7.5373 0 13.6475 6.1811 13.6475 13.806 0 7.6248-6.1102 13.8059-13.6475 13.8059-3.378 0-6.4693-1.2415-8.8525-3.298z"
        fill="#ed0006"
      />
      <path
        d="m35.3945 34.7619c2.9344-2.5323 4.7951-6.3003 4.7951-10.5079 0-4.2077-1.8607-7.9757-4.7951-10.5079 2.3832-2.0566 5.4745-3.2981 8.8525-3.2981 7.5373 0 13.6475 6.1811 13.6475 13.806 0 7.6248-6.1102 13.8059-13.6475 13.8059-3.378 0-6.4693-1.2415-8.8525-3.298z"
        fill="#f9a000"
      />
      <path
        d="m35.3946 13.7461c2.9344 2.5323 4.7951 6.3002 4.7951 10.5079 0 4.2076-1.8607 7.9755-4.7951 10.5078-2.9343-2.5323-4.795-6.3002-4.795-10.5078 0-4.2077 1.8607-7.9756 4.795-10.5079z"
        fill="#ff5e00"
      />
    </g>
  </svg>
);
