import React from 'react';

export default () => (
  <svg width="24px" height="24px" viewBox="0 0 104 104" fill="currentColor">
    <path d="M102 66.6H63.6c-1.1 0-2-.9-2-2v-6.1c0-1.1.9-2 2-2H102c1.1 0 2 .9 2 2v6.1c0 1.1-.9 2-2 2zm-36.4-4h34.3v-2H65.6v2zM61.8 50.696h13.7v4H61.8zM102 85.8H63.6c-1.1 0-2-.9-2-2v-6.1c0-1.1.9-2 2-2H102c1.1 0 2 .9 2 2v6.1c0 1.1-.9 2-2 2zm-36.4-4h34.3v-2H65.6v2zM61.8 69.415h13.7v4H61.8z" />
    <g>
      <path d="M102 103.999H63.6c-1.1 0-2-.9-2-2v-6.1c0-1.1.9-2 2-2H102c1.1 0 2 .9 2 2v6.1c0 1.1-.9 2-2 2zm-36.4-4h34.3v-2H65.6v2zM61.8 87.613h13.7v4H61.8z" />
    </g>
    <g>
      <path d="M25.2 51.5C11.3 51.5 0 39.9 0 25.8S11.3.1 25.2.1c13.9 0 25.2 11.6 25.2 25.7S39.2 51.5 25.2 51.5zm0-47.5C13.5 4 4 13.7 4 25.7s9.5 21.7 21.2 21.7 21.2-9.7 21.2-21.7C46.4 13.8 36.9 4 25.2 4z" />
      <path d="M29.474 20.51h-4c-.6 0-1.1.5-1.1 1.1v9.3c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1v-2.5h3c2.2 0 4-1.8 4-4-.1-2.1-1.9-3.9-4.1-3.9zm0 5.8h-3v-3.6h3c1 0 1.8.9 1.8 1.8 0 1-.8 1.8-1.8 1.8zM19.474 21.21c-.2-.4-.6-.7-1-.7h-1.2c-.5 0-.9.3-1 .7l-3.5 9.3c-.2.6.1 1.2.6 1.4.6.2 1.2-.1 1.4-.6l.6-1.6h4.9l.6 1.6c.2.6.8.9 1.4.7.6-.2.9-.8.7-1.4l-3.5-9.4zm-3.2 6.2l1.6-4.3 1.6 4.3h-3.2zM35.874 20.51c-.6 0-1.1.5-1.1 1.1v9.3c0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1v-9.4c0-.5-.5-1-1.1-1z" />
    </g>
    <g>
      <path d="M50.287 51.111h-6.3l6.3-6.3v6.3z" />
      <path d="M49.787 46.011l-4.6 4.6h4.6v-4.6m1-2.5v8.1h-8.1l8.1-8.1z" />
    </g>
  </svg>
);
