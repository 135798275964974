import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useParams } from 'react-router';
import toastr from 'toastr';
import api from '../../../../../../../../../api';
import OutlineButton from '../../../../../../../../../components/Buttons/OutlineButton';
import DatePickerField from '../../../../../../../../../components/DatepickerField';
import Table from '../../../../../../../../../components/Tables/Table';
import TableHeader from '../../../../../../../../../components/Tables/Table/components/TableHeader';
import ToggleField from '../../../../../../../../../components/ToggleField';
import useEffectSkipFirst from '../../../../../../../../../hooks/useEffectSkipFirst';
import utilities from '../../../../../../../../../utilities';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../../../utilities/services/DateManager';
import IconManager from '../../../../../../../../../utilities/services/IconManager';
import UsageTableRow from '../UsageRow';
import './styles.scss';

const filterButtons = {
  previousBillingCycle: 'previousBillingCycle',
  lastWeek: 'lastWeek',
  lastMonth: 'lastMonth',
  lastYear: 'lastYear',
  custom: 'custom',
};
const UsageFormFields = ({ clientLicences }) => {
  const classBase = 'ickyc-usage-form-fields';
  const [activeFilterButton, setActiveFilterButton] = useState(filterButtons.custom);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const { batch, change } = useForm();
  const date = new Date();

  const [params, setParams] = useState({
    filterOn: true,
    dateFrom: DateManager.toBekend(new Date(date.getFullYear(), date.getMonth() - 1, 1)),
    dateTo: DateManager.toBekend(new Date(date.getFullYear(), date.getMonth() + 1, 0)),
    idsOfLicences: clientLicences.map(lic => lic?.id),
  });

  const setPreffix = setClassSuffix(classBase);
  const { id: clientId } = useParams();

  const {
    values: { dateFrom: dateFromFormData, dateTo: dateToFormData, idsOfLicences },
  } = useFormState();

  const mapSelectedLicences = () => {
    if (!idsOfLicences) return [];
    return idsOfLicences
      .map((el, index) => ({ id: clientLicences[index]?.id, selected: el }))
      .filter(elm => elm.selected)
      .map(licc => licc?.id);
  };

  useEffectSkipFirst(() => {
    const fetchUsage = () => {
      setIsLoading(true);
      api.businessManager.usage
        .getClientUsage(clientId, params)
        .then(res => {
          const { data } = res;
          setRecords(data);
        })
        .catch(err => {
          if (err?.response) {
            const { status: resStatus, data } = err.response;
            if (resStatus >= 400 && resStatus < 500) {
              return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
            }
            if (resStatus === 500) {
              return {
                [FORM_ERROR]: Array.isArray(data.message)
                  ? data.message.join('')
                  : data.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (clientLicences?.length) fetchUsage();
  }, [params]);

  useEffect(() => {
    const applyChangesBasedOnLicencesIDs = () => {
      if (!params) {
        setParams({
          filterOn: true,
          dateFrom: DateManager.toBekend(new Date(date.getFullYear(), date.getMonth() - 1, 1)),
          dateTo: DateManager.toBekend(new Date(date.getFullYear(), date.getMonth(), 0)),
          idsOfLicences: clientLicences.map(lic => lic?.id),
        });
      } else {
        setParams(prev => ({ ...prev, idsOfLicences: mapSelectedLicences() }));
      }
    };

    if (clientLicences?.length) {
      applyChangesBasedOnLicencesIDs();
    }
  }, [idsOfLicences]);

  const buttonProps = filterType => {
    if (filterType === activeFilterButton)
      return {
        className: 'ickyc-outline-button--active',
        left: IconManager.get(IconManager.names.CHECK),
      };
  };

  const handlePrevBillingCycleClick = () => {
    let dateFrom = null;
    let dateTo = null;

    if (date.getMonth() - 1 >= 1) {
      dateFrom = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      dateTo = new Date(date.getFullYear(), date.getMonth(), 0);
    } else {
      dateFrom = new Date(date.getFullYear() - 1, 12, 1);
      dateTo = new Date(date.getFullYear() - 1, 12, 0);
    }

    setActiveFilterButton(filterButtons.previousBillingCycle);
    batch(() => {
      change('dateFrom', dateFrom);
      change('dateTo', dateTo);
    }, []);

    setParams({
      filterOn: true,
      dateFrom: DateManager.toBekend(dateFrom),
      dateTo: DateManager.toBekend(dateTo),
      idsOfLicences: mapSelectedLicences(),
    });
  };

  const handleLastWeekClick = () => {
    let dateFrom = null;
    let dateTo = null;
    if (date.getDate() - date.getDay() - 7 >= 1) {
      dateFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() - 7);
      dateTo = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
    } else if (date.getDate() - date.getDay() - 7 < 1 && date.getMonth() > 1) {
      // edge of month not braking year
      dateFrom = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        new Date(date.getFullYear(), date.getMonth() - 1, 0).getDate() + (date.getDate() - date.getDay() - 7),
      );
      if (date.getDate() - date.getDay() >= 1) {
        // last day of week in this month
        dateTo = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
      } else {
        // last day of week in last month
        dateTo = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          new Date(date.getFullYear(), date.getMonth() - 1, 0).getDate() + (date.getDate() - date.getDay()),
        );
      }
    } else if (date.getDate() - date.getDay() - 7 < 1 && date.getMonth() === 1) {
      // edge of month braking year
      dateFrom = new Date(date.getFullYear() - 1, 12, 31 + (date.getDate() - date.getDay() - 7));

      if (date.getDate() - date.getDay() >= 1) {
        // last day of week in this month
        dateTo = new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay());
      } else {
        // last day of week in last month
        dateTo = new Date(
          date.getFullYear(),
          date.getMonth() - 1,
          new Date(date.getFullYear() - 1, 12, 0).getDate() + (date.getDate() - date.getDay()),
        );
      }
    }

    setActiveFilterButton(filterButtons.lastWeek);

    batch(() => {
      change('dateFrom', dateFrom);
      change('dateTo', dateTo);
    }, []);

    setParams({
      filterOn: true,
      dateFrom: DateManager.toBekend(dateFrom),
      dateTo: DateManager.toBekend(dateTo),
      idsOfLicences: mapSelectedLicences(),
    });
  };

  const handleLastMonthClick = () => {
    let dateFrom = null;
    let dateTo = null;

    if (date.getMonth() - 1 >= 1) {
      dateFrom = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      dateTo = new Date(date.getFullYear(), date.getMonth(), 0);
    } else {
      dateFrom = new Date(date.getFullYear() - 1, 12, 1);
      dateTo = new Date(date.getFullYear() - 1, 12, 0);
    }

    setActiveFilterButton(filterButtons.lastMonth);

    batch(() => {
      change('dateFrom', dateFrom);
      change('dateTo', dateTo);
    }, []);

    setParams({
      filterOn: true,
      dateFrom: DateManager.toBekend(dateFrom),
      dateTo: DateManager.toBekend(dateTo),
      idsOfLicences: mapSelectedLicences(),
    });
  };

  const handleLastYearClick = () => {
    const dateFrom = new Date(date.getFullYear() - 1, 0, 1);
    const dateTo = new Date(date.getFullYear() - 1, 11, 31);
    setActiveFilterButton(filterButtons.lastYear);

    batch(() => {
      change('dateFrom', dateFrom);
      change('dateTo', dateTo);
    }, []);

    setParams({
      filterOn: true,
      dateFrom: DateManager.toBekend(dateFrom),
      dateTo: DateManager.toBekend(dateTo),
      idsOfLicences: mapSelectedLicences(),
    });
  };

  const handleCustomClick = () => {
    setActiveFilterButton(filterButtons.custom);
    setParams({
      filterOn: true,
      dateFrom: DateManager.toBekend(dateFromFormData),
      dateTo: DateManager.toBekend(dateToFormData),
      idsOfLicences: mapSelectedLicences(),
    });
  };

  const handleDownloadCsv = async () => {
    setDownloading(true);
    api.businessManager.usage
      .downloadCSV(clientId, params)
      .then(({ data }) => {
        utilities.downloadFile(data, 'application/csv', `csv-usage-export.csv`);
      })
      .catch(() => {
        toastr.error('Error while trying to download file');
      })
      .finally(() => {
        setDownloading(false);
      });
  };
  return (
    <div className={classBase}>
      <div className={setPreffix('__top-filter')}>
        <div>
          <OutlineButton {...buttonProps(filterButtons.previousBillingCycle)} onClick={handlePrevBillingCycleClick}>
            Previous Billing Cycle
          </OutlineButton>
          <OutlineButton {...buttonProps(filterButtons.lastWeek)} onClick={handleLastWeekClick}>
            Last Week
          </OutlineButton>
          <OutlineButton {...buttonProps(filterButtons.lastMonth)} onClick={handleLastMonthClick}>
            Last Month
          </OutlineButton>
          <OutlineButton {...buttonProps(filterButtons.lastYear)} onClick={handleLastYearClick}>
            Last Year
          </OutlineButton>
          <OutlineButton {...buttonProps(filterButtons.custom)} onClick={handleCustomClick}>
            Custom:
          </OutlineButton>
        </div>
        <div>
          <span>From</span>
          <Field
            name="dateFrom"
            placeholder="Pick Date"
            component={DatePickerField}
            inputVariant="standard"
            className={setPreffix('__custom-date-picker')}
          />
          <span>to</span>
          <Field name="dateTo" placeholder="Pick Date" component={DatePickerField} inputVariant="standard" />
        </div>
      </div>

      <div className={setPreffix('__table-section')}>
        <div className={setPreffix('__licences-filter')}>
          <div className={setPreffix('__licences-filter__label')}>Licenses</div>
          {clientLicences.length ? (
            clientLicences.map((liccence, index) => (
              <Field component={ToggleField} name={`idsOfLicences[${index}]`} label={liccence?.name} />
            ))
          ) : (
            <div className={setPreffix('__licences-filter__no-licences')}>The client has no licenses</div>
          )}
          <div className={setPreffix('__licences-filter__buttton-container')}>
            <OutlineButton
              left={IconManager.get(IconManager.names.DOWNLOAD)}
              disabled={downloading || clientLicences.length === 0}
              onClick={handleDownloadCsv}
            >
              Download CSV
            </OutlineButton>
          </div>
        </div>
        <div className={setPreffix('__licences-filter__table-container')}>
          <Table
            values={records}
            tableRow={UsageTableRow}
            headerRow={TableHeader}
            className="ickyc-usage-table"
            updating={isLoading}
            headerData={{
              withDelete: false,
              items: [
                { type: 'kycService', label: 'Kyc Services' },
                { type: 'usage', label: 'Usage' },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
};
UsageFormFields.propTypes = {
  clientLicences: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
};

UsageFormFields.defaultProps = {
  clientLicences: [],
};
export default UsageFormFields;
