const SORTING = {
  CHAOTIC: -1,
  ASCENDING: 0,
  DESCEDING: 1,
};

const REDUCER_ENUM = {
  INIT: 'init',
  UPDATE_SINGLE: 'updateSingle',
  UPDATE_ARRAY: 'updateArray',
};

const HISTORY_EVENT_STATUSES = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
  REVIEW: 'Review',
};
const HISTORY_EVENT_STATUSES_REDUCED = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  REVIEW: 'Review',
};

const API_ERROR_TYPES = {
  LEGAL_ENTITY_NOT_FOUND: 'Cannot find Incorporated entity with given Id',
  NATURAL_PERSON_NOT_FOUND: 'Cannot find Natural person with given Id',
};

const ENTITY_STATUSES = {
  NEW: 'New',
  REVIEW: 'Review',
  ESCALATED: 'Escalated',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  KYC_REFRESH: 'KYC Refresh',
};

const AUTO_ACCEPTANCE_ENTITY_STATUSES = {
  NEW: 'New',
  PENDING: 'Pending',
  REVIEW: 'Review',
  ESCALATED: 'Escalated',
  APPROVED: 'Approved',
};

const BUS_EVENTS = {
  NEW_PERSONAL_RECORD: 'NEW_PERSONAL_RECORD',
  NEW_IDENTITY_RECORD: 'NEW_IDENTITY_RECORD',
  NEW_BIOMETRIC_RECORD: 'NEW_BIOMETRIC_RECORD',

  NEW_CORPORATE_TABLE_RECORD: 'NEW_CORPORATE_TABLE_RECORD',
  NEW_DOCUMENTS_RECORD: 'NEW_DOCUMENTS_RECORD',
  NEW_ADDRESS_TABLE_RECORD: 'NEW_ADDRESS_TABLE_RECORD',
  OVERRIDE_PERSONAL_TABLE: 'OVERRIDE_PERSONAL_TABLE',
  OVERRIDE_BIOMETRICS_TABLE: 'OVERRIDE_BIOMETRICS_TABLE',
  OVERRIDE_IDENTITY_TABLE: 'OVERRIDE_IDENTITY_TABLE',
  OVERRIDE_DOCUMENTS_TABLE: 'OVERRIDE_DOCUMENTS_TABLE',
  OVERRIDE_CORPORATE_TABLE: 'OVERRIDE_CORPORATE_TABLE',
  OVERRIDE_ADDRESS_TABLE: 'OVERRIDE_ADDRESS_TABLE',
  SORT_PERSONAL_TABLE: 'SORT_PERSONAL_TABLE',
  UPDATE_GENERAL_INFO: 'UPDATE_GENERAL_INFO',
  NEW_HISTORY_RECORD_CHANGE: 'NEW_HISTORY_RECORD_CHANGE',
  NEW_LOG_COMMENT: 'NEW_LOG_COMMENT',
  REMOVE_CASE: 'REMOVE_CASE',
  UPDATE_CASE_MANAGEMENT_LISTING_HEADER: 'UPDATE_CASE_MANAGEMENT_LISTING_HEADER',
  UPDATE_HISTORY_TABLE_RECORD: 'UPDATE_HISTORY_TABLE_RECORD',
  EXPAND_SECTIONS: 'EXPAND_SECTIONS',
  EXPAND_COMMENTS: 'EXPAND_COMMENTS',
  UPDATE_ISSUES: 'UPDATE_ISSUES',
  OPENCV_LOADED: 'OPECV_LOADED',
  SCROLL_BOTTOM_MODAL: 'SCROLL_MODAL',
};

const ADDRESS_TYPES = {
  Headquarters: 'headquarter',
  Additional: 'additional',
  'Legal Address': 'legalAddress',
  'Mailing Address': 'mailingAddress',
  'Physical Address': 'physicalAddress',
  'Billing Address': 'billingAddress',
};

const RISK_SCREENING_ASSIGNEMENT_TYPES = {
  AssignAllTo: 0,
  AssignByJurisdiction: 1,
  AssignBySource: 2,
  DoNotAssign: 3,
};

const SUPPORTING_DOC_NAMES = {
  PROOF_OF_ADDRESS: 'Proof of Address ',
  PROOF_OF_INCOME: 'Proof of Income ',
  PROOF_OF_WEALTH: 'Proof of Wealth ',
};
const NATURAL_PERSON_ROLES = ['Director', 'Officer', 'Shareholder', 'Other', 'Primary Contact'];

const LEGAL_ENTITY_ROLES = [
  'Shareholder',
  'Parent',
  'Subsidiary',
  'Beneficial Owner',
  'Trustee',
  'Accountant',
  'Lawyer',
  'Service Provider',
];
const AFFILIATED_MODAL_ENTITY_OPTIONS = {
  NEW_NP: 0,
  NEW_LE: 1,
  EXISTING: 2,
};

const ACCORDION_INDEXES = {
  PERSONAL_INFORMATION: 'personalInformation',
  CORPORATE_INFORMATION: 'corporateInformation',
  IDENTITY_DOCUMENTS: 'identityDocuments',
  BIOMETRIC_AUTHENTICATIONS: 'biometricAuthentication',
  DOCUMENTS: 'documents',
  RISK_SCREENING: 'riskScreening',
  AFFILIATED_ENTITIES: 'affiliatedEntities',
  ADDRESS: 'addressInfo',
  AFFILIATIONS: 'affiliations',
  COMMENTS: 'comments',
  FULL_LISTINGS: 'fullListings',
  ADVERSE_LISTINGS: 'adverseListings',
  DOCUMENTS_LISTINGS: 'documentListings',
};

// USED IN ADMINISTRATION PAGES TO TOGGLE ON/OFF WHOLE PAGE
const ADMINISTRATION_PAGES = {
  Restrictions: 0,
  IdentityVerification: 1,
  DocumentAuthentication: 2,
  AdditionalSupportingDocs: 3,
  FacialMatching: 4,
  RiskScoring: 5,
  RiskScreening: 6,
  RiskLevels: 7,
  DataGovernance: 8,
};

const EXPORT_REPORT_TYPES = [
  { value: 'pdf', label: 'PDF' },
  { value: 'docx', label: 'DOCX' },
];

const NATURAL_PERSON_CONTROL_TAGS = [
  { id: 'Director', label: 'Director', value: 'Director' },
  { id: 'Officer', label: 'Officer', value: 'Officer' },
  { id: 'Trustee', label: 'Trustee', value: 'Trustee' },
  { id: 'Power of Attorney', label: 'Power of Attorney', value: 'Power of Attorney' },
  { id: 'Nominee', label: 'Nominee', value: 'Nominee' },
  { id: 'Employee', label: 'Employee', value: 'Employee' },
  { id: 'Account Admin', label: 'Account Admin', value: 'Account Admin' },
];

const LEGAL_ENTTITY_CONTROL_TAGS = [
  { id: 'Beneficial owner', label: 'Beneficial owner', value: 'Beneficial owner' },
  { id: 'Shareholder', label: 'Shareholder', value: 'Shareholder' },
  { id: 'Sole Owner', label: 'Sole Owner', value: 'Sole Owner' },
  { id: 'Subsidiary', label: 'Subsidiary', value: 'Subsidiary' },
  { id: 'Trustee', label: 'Trustee', value: 'Trustee' },
];

const OWNERSHIP_TAGS = [
  { id: 'Shareholder', label: 'Shareholder', value: 'Shareholder' },
  { id: 'Sole Shareholder', label: 'Sole Shareholder', value: 'Sole Shareholder' },
  { id: 'Majority Shareholder', label: 'Majority Shareholder', value: 'Majority Shareholder' },
  { id: 'Beneficiary', label: 'Beneficiary', value: 'Beneficiary' },
];

const ATTACH_NP_ENTITY_ROLES = [
  { id: 'Director', label: 'Director', value: 'Director' },
  { id: 'Officer', label: 'Officer', value: 'Officer' },
  { id: 'Trustee', label: 'Trustee', value: 'Trustee' },
  { id: 'Power of Attorney', label: 'Power of Attorney', value: 'Power of Attorney' },
  { id: 'Nominee', label: 'Nominee', value: 'Nominee' },
  { id: 'Employee', label: 'Employee', value: 'Employee' },
  { id: 'Primary Contact', label: 'Primary Contact', value: 'Primary Contact' },
  { id: 'Shareholder', label: 'Shareholder', value: 'Shareholder' },
  { id: 'Sole Shareholder', label: 'Sole Shareholder', value: 'Sole Shareholder' },
  { id: 'Majority Shareholder', label: 'Majority Shareholder', value: 'Majority Shareholder' },
  { id: 'Beneficiary', label: 'Beneficiary', value: 'Beneficiary' },
  { id: 'Contact', label: 'Contact', value: 'Contact' },
  { id: 'Authorized Representative', label: 'Authorized Representative', value: 'Authorized Representative' },
  { id: 'Account Admin', label: 'Account Admin', value: 'Account Admin' },
];

const ENTITY_TYPES = [
  { id: 'Natural Person', label: 'Natural Person', value: '0' },
  { id: 'Legal Entity', label: 'Legal Entity', value: '1' },
];

const ENTITY_TYPE_NAMES = {
  NATURAL_PERSON: 0,
  LEGAL_ENTITY: 1,
};

const MAPPED_CASE_STATUSES = [
  {
    id: 'Open',
    label: 'Open',
    value: 'Open',
  },
  {
    id: 'Closed',
    label: 'Closed',
    value: 'Closed',
  },
];

// Map event(acordian) types from BE response to Accordian Indexes
const EVENT_TYPES = {
  'Identity Verification': ACCORDION_INDEXES.PERSONAL_INFORMATION,
  'Document Authentication': ACCORDION_INDEXES.IDENTITY_DOCUMENTS,
  'Facial Matching': ACCORDION_INDEXES.BIOMETRIC_AUTHENTICATIONS,
  'Supporting Document': ACCORDION_INDEXES.DOCUMENTS,
};

const ENTITY_STATUSES_OPTIONS = [
  { label: 'New', value: 5, id: 'New' },
  { label: 'Review', value: 0, id: 'Review' },
  { label: 'Escalated', value: 2, id: 'Escalated' },
  { label: 'Rejected', value: 3, id: 'Rejected' },
  { label: 'Approved', value: 4, id: 'Approved' },
  { label: 'KYCRefresh', value: 6, id: 'KYCRefresh' },
];

export default {
  ACCORDION_INDEXES,
  SORTING,
  REDUCER_ENUM,
  HISTORY_EVENT_STATUSES,
  API_ERROR_TYPES,
  ENTITY_STATUSES,
  BUS_EVENTS,
  ADDRESS_TYPES,
  SUPPORTING_DOC_NAMES,
  RISK_SCREENING_ASSIGNEMENT_TYPES,
  NATURAL_PERSON_ROLES,
  LEGAL_ENTITY_ROLES,
  AFFILIATED_MODAL_ENTITY_OPTIONS,
  ADMINISTRATION_PAGES,
  AUTO_ACCEPTANCE_ENTITY_STATUSES,
  HISTORY_EVENT_STATUSES_REDUCED,
  EXPORT_REPORT_TYPES,
  LEGAL_ENTTITY_CONTROL_TAGS,
  ENTITY_TYPES,
  ENTITY_TYPE_NAMES,
  NATURAL_PERSON_CONTROL_TAGS,
  OWNERSHIP_TAGS,
  ATTACH_NP_ENTITY_ROLES,
  MAPPED_CASE_STATUSES,
  EVENT_TYPES,
  ENTITY_STATUSES_OPTIONS,
};
