import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import React from 'react';
import { Field } from 'react-final-form';
import enums from 'utilities/enums';
import InputField from '../../../../../components/InputField';
import TextareaField from '../../../../../components/TextareaField';
import validators from '../../../../../utilities/services/validators';
import './index.scss';

const SupportingDocumentTypeForm = ({ entityTypePreview }) => {
  const baseClass = 'ickyc-supporting-document-type-form';
  return (
    <div className={baseClass}>
      <Field
        component={InputField}
        name="header"
        label="Header"
        className="ickyc-requirement-modal__image-container"
        placeholder="Document Package Name"
        validate={validators.required()}
      />
      <Field
        component={TextareaField}
        rows={3}
        label="Description"
        name="description"
        placeholder="Document Description"
      />
      <Field
        component={SelectField}
        Trigger={<SelectTrigger placeholder="Entity Type" />}
        Content={<SelectFilterContent />}
        name="entityType"
        label="Entity Type"
        className="ickyc-requirement-modal__image-container"
        options={enums.ENTITY_TYPES}
        validate={validators.required()}
        preview={entityTypePreview}
      />
    </div>
  );
};

export default SupportingDocumentTypeForm;
