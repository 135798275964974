import FilterFormsService from '../../../utilities/services/FilterFormsService';
import axiosInstance from '../../axiosInstance';

const CLIENT_BASE_ROUTE = '/business-manager';
const INTERNAL_BASE_ROUTE = '/internal';

const ENDPOINTS = {
  createLicence: () => `${INTERNAL_BASE_ROUTE}/licenses`,
  getAllLicences: () => `${INTERNAL_BASE_ROUTE}/licenses/all`,
  updateLicence: id => `${INTERNAL_BASE_ROUTE}/licenses/${id}`,
  getLicence: id => `${INTERNAL_BASE_ROUTE}/licenses/${id}`,
  deleteLicence: id => `${INTERNAL_BASE_ROUTE}/licenses/${id}`,

  getClientLicences: clientId => `${CLIENT_BASE_ROUTE}/clients/${clientId}/licences`,
  updateClientLicences: clientId => `${CLIENT_BASE_ROUTE}/clients/${clientId}/licences`,
  getAllLicencesOptions: () => `${CLIENT_BASE_ROUTE}/licences/names`,
  deleteClientLicence: (id, clientId) => `${CLIENT_BASE_ROUTE}/clients/${clientId}/licences/${id}`,
};

const createLicence = (id, data) => {
  return axiosInstance.post(ENDPOINTS.createLicence(id), data);
};
const updateLicence = (id, data) => {
  return axiosInstance.put(ENDPOINTS.updateLicence(id), data);
};
const getAllLicences = data => {
  const { filters, filterOn, ...params } = data;
  const transformedFilter = FilterFormsService.normalizeLicenceFormData(filters, filterOn);
  return axiosInstance.post(ENDPOINTS.getAllLicences(), { ...transformedFilter }, { params });
};
const getLicence = id => axiosInstance.get(ENDPOINTS.getLicence(id), {});
const deleteLicence = id => axiosInstance.delete(ENDPOINTS.deleteLicence(id), {});

const getClientLicences = data => {
  const { clientId, ...params } = data;
  return axiosInstance.get(ENDPOINTS.getClientLicences(clientId), { params });
};
const updateClientLicences = (clientId, data) => axiosInstance.put(ENDPOINTS.updateClientLicences(clientId), data);
const getAllLicencesOptions = () => axiosInstance.get(ENDPOINTS.getAllLicencesOptions());
const deleteClientLicence = (id, clientId) => axiosInstance.delete(ENDPOINTS.deleteClientLicence(id, clientId));

export default {
  createLicence,
  updateLicence,
  getAllLicences,
  getLicence,
  deleteLicence,
  getClientLicences,
  updateClientLicences,
  getAllLicencesOptions,
  deleteClientLicence,
};
