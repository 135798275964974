import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import './styles.scss';

/**
 * Input component used inside the search modal
 * @param {string} label - input label
 * @param {boolean} optional - is input optional
 */

const DebounceInput = ({ initialValue, onChange, placeholder, debounce }) => {
  const [value, setValue] = useState(initialValue);
  const debouncedValue = useDebounce(value, debounce);

  useEffect(() => {
    if (debouncedValue) {
      onChange(debouncedValue);
    }
    if (debouncedValue === '') {
      onChange();
    }
  }, [debouncedValue, onChange]);

  const handleInputChange = useCallback(e => {
    setValue(e.target.value);
  }, []);

  return (
    <div className="ickyc-debounce-input">
      <Search />
      <input placeholder={placeholder} value={value} onChange={handleInputChange} />
    </div>
  );
};

DebounceInput.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  debounce: PropTypes.number,
};

DebounceInput.defaultProps = {
  initialValue: undefined,
  onChange: () => {},
  placeholder: '',
  debounce: 1000,
};

export default DebounceInput;
