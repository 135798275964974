import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/portal-configurations';

const ENDPOINTS = {
  portalsConfigurationsNatural: () => `${BASE_ROUTE}/natural-person`,
  portalsConfigurationsLegalEntity: () => `${BASE_ROUTE}/legal-entity`,
  portalConfigById: configId => `${BASE_ROUTE}/${configId}`,
  createPortalsConfiguration: () => `${BASE_ROUTE}`,
  updatePortalsConfigurationNaturalPerson: id => `${BASE_ROUTE}/natural-person/${id}`,
  updatePortalsConfigurationLegalEntity: id => `${BASE_ROUTE}/legal-entity/${id}`,
  deleteConfiguration: (id, type) => `${BASE_ROUTE}/${id}?portalType=${type}`,
  updateConfigurationLogoImage: confId => `${BASE_ROUTE}/${confId}/logo-image`,
  updateDefault: () => `${BASE_ROUTE}/default-configuration`,
  getDomainsForLegalEntity: () => `${BASE_ROUTE}/legal-entity/domains`,
  getDomainsForNaturalPerson: () => `${BASE_ROUTE}/natural-person/domains`,

  getRiskScreeningProfiles: () => `/case-management/get-screening-profiles`,
  updateNaturalPersonPortalStatus: id => `${BASE_ROUTE}/natural-person/${id}/status`,
  updateLegalEntityPortalStatus: id => `${BASE_ROUTE}/legal-entity/${id}/status`,
  getAllConfigurationsForSpecificEntityType: () => `${BASE_ROUTE}/basic-info`,
};

const getPortalsConfigurationsNaturalPerson = params =>
  axiosInstance.get(ENDPOINTS.portalsConfigurationsNatural(), { params });
const getPortalsConfigurationsLegalEntity = params =>
  axiosInstance.get(ENDPOINTS.portalsConfigurationsLegalEntity(), { params });
const getPortalsConfigurationById = configId => axiosInstance.get(ENDPOINTS.portalConfigById(configId));
const createPortalsConfiguration = data => axiosInstance.post(ENDPOINTS.createPortalsConfiguration(), data);
const updatePortalsConfigurationNaturalPerson = (id, data, shouldForce) => {
  const params = shouldForce ? { forceRequest: true } : {};
  return axiosInstance.put(ENDPOINTS.updatePortalsConfigurationNaturalPerson(id), data, { params });
};
const updatePortalsConfigurationLegalEntity = (id, data) =>
  axiosInstance.put(ENDPOINTS.updatePortalsConfigurationLegalEntity(id), data);
const deleteConfiguration = (id, type, forceRequest, newDefaultPortalId) => {
  return axiosInstance.delete(ENDPOINTS.deleteConfiguration(id, type), {
    params: { forceRequest },
    data: { newDefaultPortalId },
  });
};
const updateConfigurationLogoImage = (confId, data) =>
  axiosInstance.put(ENDPOINTS.updateConfigurationLogoImage(confId), data);
const updateDefault = data => axiosInstance.put(ENDPOINTS.updateDefault(), data);
const getRiskScreeningProfiles = params => axiosInstance.get(ENDPOINTS.getRiskScreeningProfiles(), { params });
const getDomainsForLegalEntity = () => axiosInstance.get(ENDPOINTS.getDomainsForLegalEntity());
const getDomainsForNaturalPerson = () => axiosInstance.get(ENDPOINTS.getDomainsForNaturalPerson());

const updatePortalStatus = (id, status, type, shouldForce) => {
  const params = shouldForce ? { forceRequest: true } : {};
  return axiosInstance.patch(
    type === 'NaturalPerson'
      ? ENDPOINTS.updateNaturalPersonPortalStatus(id)
      : ENDPOINTS.updateLegalEntityPortalStatus(id),
    { enabled: status },
    { params },
  );
};

const getAllConfigurationsForSpecificEntityType = portalType =>
  axiosInstance.get(ENDPOINTS.getAllConfigurationsForSpecificEntityType(), { params: { portalType } });

export default {
  getPortalsConfigurationsNaturalPerson,
  getPortalsConfigurationsLegalEntity,
  getPortalsConfigurationById,
  createPortalsConfiguration,
  updatePortalsConfigurationNaturalPerson,
  updatePortalsConfigurationLegalEntity,
  deleteConfiguration,
  updateConfigurationLogoImage,
  updateDefault,
  getRiskScreeningProfiles,
  getDomainsForLegalEntity,
  getDomainsForNaturalPerson,
  updatePortalStatus,
  getAllConfigurationsForSpecificEntityType,
};
