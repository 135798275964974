import BareHeading from 'components/Headings/BareHeading';
import { FORM_ERROR } from 'final-form';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import toastr from 'toastr';
import api from '../../../../../../../api';
import IComplyForm from '../../../../../../../components/Form/IComplyForm';
import Spinner from '../../../../../../../components/Spinner';
import BusinessAccountFormFields from '../../../BusinessAccountFormFields';

const BusinessAccountSection = () => {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onSaveChanges = values => {
    const { stateProvince, domain, ...restOfValues } = values;
    return api.businessManager
      .updateClientAccount(id, { ...restOfValues, domain: `${domain}.icomplykyc.com` })
      .then(() => {
        toastr.success('Successfully updated account');
      })
      .catch(err => {
        if (err?.response) {
          toastr.error('Failed to update account');

          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
          }
          if (resStatus === 500) {
            return {
              [FORM_ERROR]: Array.isArray(data.message)
                ? data.message.join('')
                : data.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  useEffect(() => {
    const fetchBusinessInfo = () => {
      setIsLoading(true);
      api.businessManager
        .getClientAccount(id)
        .then(({ data: responseData }) => {
          setInitialValues({
            ...responseData,
            domain: responseData.domain ? responseData.domain.split('.icomplykyc.com')[0] : '',
          });
        })
        .catch(err => {
          if (err?.response) {
            const { status, data } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(data.message)
                  ? data.message.join('')
                  : data.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchBusinessInfo();
  }, [id]);

  return (
    <div>
      <BareHeading title={'Account'} />
      {isLoading ? (
        <Spinner />
      ) : (
        <IComplyForm onSubmit={onSaveChanges} initialValues={initialValues} showControls onD>
          <BusinessAccountFormFields />
        </IComplyForm>
      )}
    </div>
  );
};
export default BusinessAccountSection;
