import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../components/Accordion';
import SelectFilterContent from '../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../components/ToggleField';
import ProtectedComponent from '../../../../../../../router/components/ProtectedComponent';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import rcuEnums from '../../../../../../../utilities/enums/rcuEnums';
import { RCUContext } from '../../../../../../../utilities/services/contexts';
import kycServicesEnums from '../kycServicesEnums';
import SupportingDocumentsRCU from './components/SupportingDocumentsRCU';
import './styles.scss';

const DataCollection = ({ namePrefix, accordionOpen }) => {
  const {
    initialConfiguration: {
      supportingDocumentPackagesEnabled: supportingDocsVisible,
      supportingDocumentPackagesTypes,
      documentAuthenticationEnabled: docsAuthVisible,
    },
  } = useContext(RCUContext);

  const baseClass = 'ickyc-data-collection';
  const { values } = useFormState();

  const dataCollection = useMemo(
    () => (values[namePrefix] ? values[namePrefix].dataCollection : values.dataCollection),
    [values],
  );

  const { change } = useForm();

  const namePrefixWithDot = useMemo(() => (namePrefix ? `${namePrefix}.` : ''), [namePrefix]);

  const handleRequestProofOfAddressChange = useCallback(
    value => {
      const proofOfAddressDocumentType = supportingDocumentPackagesTypes?.find(
        doc => doc.name.includes('address') || doc.name.includes('Address') || doc.name.includes('Proof of A'),
      );

      const proofOfAddressDocument =
        dataCollection?.packagesTypes?.find(doc => doc.packageId === proofOfAddressDocumentType?.id) || {};

      const documentsWithoutAddress =
        dataCollection?.packagesTypes?.filter(doc => doc.packageId !== proofOfAddressDocumentType?.id) || [];

      if (value) {
        change(`${namePrefixWithDot}dataCollection.requestSupportingDocuments`, true);

        if (proofOfAddressDocumentType) {
          change(`${namePrefixWithDot}dataCollection.packagesTypes`, [
            {
              packageId: proofOfAddressDocumentType?.id,
              assignedUserId: proofOfAddressDocument?.assignedUserId || null,
              locked: true,
              required: true,
            },
            ...documentsWithoutAddress,
          ]);
        } else {
          change(`${namePrefixWithDot}dataCollection.packagesTypes`, [
            { required: false, locked: false },
            ...documentsWithoutAddress,
          ]);
        }
      }
    },
    [dataCollection?.packagesTypes, supportingDocumentPackagesTypes, change, namePrefixWithDot],
  );

  const handleRequestSupportingDocumentsChange = useCallback(
    value => {
      if (value && dataCollection?.packagesTypes?.length === 0) {
        change(`${namePrefixWithDot}dataCollection.packagesTypes`, [{ required: false, locked: false }]);
      }
      if (!value) {
        change(`${namePrefixWithDot}dataCollection.packagesTypes`, []);
      }
    },
    [change, namePrefixWithDot, dataCollection?.packagesTypes],
  );

  useEffect(() => {
    dataCollection?.requestSupportingDocuments && handleRequestSupportingDocumentsChange(true);
  }, []);

  return (
    <Accordion
      accordionindex={rcuEnums.ACCORDION_INDEXES.DATA_COLLECTION}
      accordionOpen={accordionOpen}
      title={
        <>
          <h3>Data Collection</h3>
          <span className={`${namePrefix}-spacer`} />
        </>
      }
    >
      <div className={baseClass}>
        <Field
          name={`${namePrefixWithDot}dataCollection.personalInformationInputOption`}
          label={
            <Field
              name={`${namePrefixWithDot}dataCollection.requestPersonalInformation`}
              component={ToggleField}
              label="Request Personal Information"
              disabled
            />
          }
          component={SelectField}
          options={kycServicesEnums.INFORMATION_PREFIL_OR_ENTER}
          Trigger={<SelectTrigger placeholder="Prefil Info or Enter New Data" />}
          Content={<SelectFilterContent />}
        />
        <Field
          name={`${namePrefixWithDot}dataCollection.addressInformationInputOption`}
          label={
            <Field
              name={`${namePrefixWithDot}dataCollection.requestAddressInformation`}
              component={ToggleField}
              label="Request Address Information"
            />
          }
          component={SelectField}
          options={kycServicesEnums.INFORMATION_PREFIL_OR_ENTER}
          Trigger={<SelectTrigger placeholder="Prefil Info or Enter New Data" />}
          Content={<SelectFilterContent />}
        />
        {supportingDocsVisible && (
          <Field
            name={`${namePrefixWithDot}dataCollection.requestProofOfAddress`}
            className="ickyc-custom-toggle--w2"
            component={ToggleField}
            label="Require Proof of Address"
            onChange={handleRequestProofOfAddressChange}
          />
        )}
        <Field
          name={`${namePrefixWithDot}dataCollection.additionalDetailsInputOption`}
          label={
            <Field
              name={`${namePrefixWithDot}dataCollection.requestAdditionalDetails`}
              component={ToggleField}
              label="Request Additional Details"
            />
          }
          component={SelectField}
          options={kycServicesEnums.INFORMATION_PREFIL_OR_ENTER}
          Trigger={<SelectTrigger placeholder="Prefil Info or Enter New Data" />}
          Content={<SelectFilterContent />}
        />
        {docsAuthVisible && (
          <Field
            name={`${namePrefixWithDot}dataCollection.requestIdentityDocument`}
            className="ickyc-custom-toggle"
            component={ToggleField}
            label="Request Identity Document"
          />
        )}
        <Field
          name={`${namePrefixWithDot}dataCollection.requireTwoPiecesOfId`}
          className="ickyc-custom-toggle--w2"
          component={ToggleField}
          label="Require 2 Pieces of ID"
        />
        {supportingDocsVisible && (
          <ProtectedComponent
            permissionGroup={authEnums.PERMISSION_GROUP.DOCUMENTS}
            requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.requestClientUpdate]}
          >
            <SupportingDocumentsRCU
              namePrefix={namePrefix}
              handleRequestSupportingDocumentsChange={handleRequestSupportingDocumentsChange}
            />
          </ProtectedComponent>
        )}
      </div>
    </Accordion>
  );
};
export default DataCollection;
