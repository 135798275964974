export const RCUDefaultValues = {
  generalSettings: { clientEmail: '', domain: undefined, emailSubject: '', emailMessage: '' },
  amlCase: {
    isEnabled: false,
    screeningProfileId: null,
    createCaseAfterAllRequestsAreAccepted: false,
    monitorCase: false,
    autoUpdateStatus: false,
    caseStatus: 0,
  },
  dataCollection: {
    requestPersonalInformation: true,
    personalInformationInputOption: '1',
    requestAddressInformation: true,
    addressInformationInputOption: '0',
    requestAdditionalDetails: true,
    requestProofOfAddress: false,
    additionalDetailsInputOption: '1',
    requestIdentityDocument: false,
    requireTwoPiecesOfId: false,
    requestSupportingDocuments: false,
    packagesTypes: [],
  },
  automation: {
    isEnabled: false,
    identityAuthenticationAutoAcceptance: false,
    documentAuthenticationAutoAcceptance: false,
    biometricAuthenticationAutoAcceptance: false,
    enablePeriodicReview: true,
    periodicReviewInterval: 'Years',
    periodicReviewValue: 2,
    setProfileStatus: true,
    status: 1,
    setRiskLevel: true,
    riskLevel: 2,
    automationsJurisdictions: [],
  },
  comment: { isEnabled: false, commentBody: '' },
  kycRequests: {
    allEnabled: false,
    runVerification: false,
    identityVerification: {
      isEnabled: false,
      runIdentityDataConfirmation: false,
      livenessDetection: false,
    },

    documentAuthentication: {
      isEnabled: false,
      templateVerification: false,
      documentDataVerification: false,
      includeFaceMatch: false,
      faceMatchStrategy: '1',
    },
    biometricAuthentication: {
      isEnabled: false,
      faceMatchType: '0',
    },
  },
};
