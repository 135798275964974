import { Box } from '@material-ui/core';
import classNames from 'classnames';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import toastr from 'toastr';
import enums from 'utilities/enums';
import api from '../../../../../../../../api';
import PrimaryButton from '../../../../../../../../components/Buttons/PrimaryButton';
import Loader from '../../../../../../../../components/Loader';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';
import { EntityContext, HistoryRecordContext } from '../../../../../../../../utilities/services/contexts';
import errorMessage from '../ErrorMessage';
import ThirdPartyHeader from '../ThirdPartyHeader';
import ThirdPartyEditAddress from './components/ThirdPartyEditAddress';
import ThirdPartyEditEntity from './components/ThirdPartyEditEntity';

const ThirdPartyEdit = ({ data, onDataFetch }) => {
  const [error, setError] = useState();
  const [issuesCount, setIssuesCount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { entityId } = useContext(EntityContext);
  const { issues, country: isAddressType } = useContext(HistoryRecordContext);

  const { thirdPartySuggestion, eventId } = data;
  const {
    suggestionStatus,
    editedAddressEvent,
    editedCorporateEvent,
    thirdPartyInfo,
    editedFields,
  } = thirdPartySuggestion;

  const classBase = 'ickyc-third-party-edit';
  const setSuffix = setClassSuffix(classBase);
  const classes = classNames(classBase, {
    [setSuffix('__issues')]: issuesCount,
  });

  const updateEvent = useMemo(() => {
    if (isAddressType) {
      setIssuesCount(editedAddressEvent?.issues?.length);
      return api.kyc.entityManagement.legalEntity.updateAddressEvent;
    }
    setIssuesCount(editedCorporateEvent?.issues?.length);
    return api.kyc.entityManagement.legalEntity.updateCorporateEvent;
  }, [isAddressType]);

  const handleSave = async suggestionStatus => {
    setIsLoading(true);
    const dataToSubmit = { status: suggestionStatus, operation: 'edit' };

    return updateEvent(entityId, eventId, dataToSubmit)
      .then(response => {
        if (isAddressType) {
          onDataFetch({
            addressInformation: {
              ...(suggestionStatus === 'accepted' ? editedAddressEvent : data),
              eventId: data?.eventId,
              issues: response?.data?.issues,
              thirdPartySuggestion: {
                ...thirdPartySuggestion,
                suggestionStatus,
                editedAddressEvent: { ...editedAddressEvent, issues: [] },
              },
            },
          });
        } else {
          onDataFetch({
            corporateInformation: {
              ...(suggestionStatus === 'accepted' ? editedCorporateEvent : data),
              eventId: data?.eventId,
              issues: response?.data?.issues,
              thirdPartySuggestion: { ...thirdPartySuggestion, suggestionStatus },
            },
          });
        }
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, {
          eventHistoryId: eventId,
          data: {
            issues: issues - 2,
            status: response?.data?.status,
          },
        });
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: isAddressType ? enums.ACCORDION_INDEXES.ADDRESS : enums.ACCORDION_INDEXES.CORPORATE_INFORMATION,
          issuesChange: -2,
        });
        toastr.success(`Suggestion has been ${suggestionStatus}.`);
        setIssuesCount(0);
        setError();
      })
      .catch(err => {
        return setError(errorMessage(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={classes}>
      <ThirdPartyHeader className={classBase} info={thirdPartyInfo} issuesCount={issuesCount} actionType="Suggested" />
      {isAddressType ? (
        <ThirdPartyEditAddress editedFields={editedFields} />
      ) : (
        <ThirdPartyEditEntity editedFields={editedFields} />
      )}
      {error && <Box className={setSuffix('__submissionError')}>{error}</Box>}
      {issuesCount ? (
        <div className={setSuffix('__buttons')}>
          {isLoading && <Loader />}
          <PrimaryButton variant="red" disabled={isLoading} onClick={() => handleSave('ignored')}>
            Ignore Suggestion
          </PrimaryButton>
          <PrimaryButton type="submit" disabled={isLoading} onClick={() => handleSave('accepted')}>
            Accept Suggestion
          </PrimaryButton>
        </div>
      ) : (
        <div className={setSuffix('__status-message')}>
          <span className={setSuffix(`__status-message--${suggestionStatus}`)}>Suggestion {suggestionStatus}</span>
        </div>
      )}
    </div>
  );
};

ThirdPartyEdit.propTypes = {
  data: PropTypes.object,
  onDataFetch: PropTypes.func,
};
ThirdPartyEdit.defaultProps = {
  data: {},
  onDataFetch: () => {},
};

export default ThirdPartyEdit;
