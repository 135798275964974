import React from 'react';

export default () => (
  <svg viewBox="0 0 104 104" fill="currentColor" width="12px" height="12px">
    <path d="M51.8 104c-28.1 0-51-23.3-51-52s22.9-52 51-52 51 23.3 51 52-22.8 52-51 52zm0-100c-25.9 0-47 21.5-47 48s21.1 48 47 48 47-21.5 47-48-21-48-47-48z" />
    <path d="M60.29 41.33h-8.2c-1.2 0-2.2 1-2.2 2.2v18.6c0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2v-4.8h6c4.4 0 8-3.6 8-8s-3.6-8-8-8zm0 11.7h-6v-7.3h6c2 0 3.6 1.7 3.6 3.7-.1 1.9-1.6 3.5-3.6 3.6zM40.19 42.73c-.3-.9-1.1-1.4-2-1.4h-2.3c-.9 0-1.7.6-2 1.4l-7 18.6c-.4 1.1.1 2.4 1.3 2.8 1.1.4 2.4-.1 2.8-1.3l1.2-3.2h9.8l1.2 3.2c.4 1.1 1.6 1.7 2.8 1.3 1.1-.4 1.7-1.6 1.3-2.8v-.1l-7.1-18.5zm-6.5 12.6l3.3-8.7 3.3 8.7h-6.6zM73.09 41.33c-1.2 0-2.2 1-2.2 2.2v18.6c0 1.2 1 2.2 2.2 2.2 1.2 0 2.2-1 2.2-2.2v-18.6c0-1.2-1-2.2-2.2-2.2z" />
    <g>
      <path d="M102.653 103.53h-14.6l14.6-14.6v14.6z" />
      <path d="M102.153 90.13l-12.9 12.9h12.9v-12.9m1-2.4v16.3h-16.3l16.3-16.3z" />
    </g>
  </svg>
);
