import FilterFormsService from '../../utilities/services/FilterFormsService';
import axiosInstance from '../axiosInstance';
import licences from '../businessManager/licences';
import account from './account';
import apikeys from './api-keys';
import automations from './automations';
import portalManagement from './portalManagement';
import riskScoring from './riskScoring';
import settings from './settings';
import supportingDocumentPackages from './supportingDocumentPackages';
import userManagement from './userManagement';
import webhooks from './webhooks';

const BASE_ROUTE = '/client-account';

const ENDPOINTS = {
  changePageStatus: () => `${BASE_ROUTE}/enable-status`,
  naturalPersonOnboarding: () => `${BASE_ROUTE}/onboarding`,
  identityVerification: () => `${BASE_ROUTE}/identity-verification`,
  documentAuthentication: () => `${BASE_ROUTE}/document-auth`,
  biometricAuthentication: () => `${BASE_ROUTE}/facial-matching`,
  riskLevels: () => `${BASE_ROUTE}/risk-levels`,
  getRiskScreening: () => '/case-management/get-screening-profiles',
  createRiskScreeningProfile: () => '/case-management/create-screening-profile',
  updateRiskScreeningProfile: () => '/case-management/update-screening-profile',
  getRiskScreeningPprofile: profileId => `/case-management/get-screening-profile-details?profileId=${profileId}`,
  checkRiskScreeningProfileName: () => '/case-management/check-screening-profile',
  deleteRiskScreeningProfile: () => '/case-management/delete-screening-profile',
  setDefaultRiskScreeningProfile: () => '/case-management/set-default-screening-profile',
  getSupportingDocumentsSuggestions: () => `${BASE_ROUTE}/existing-names`,
  supportingDocumentsPackage: docId => `${BASE_ROUTE}/supporting-document-packages${docId ? `/${docId}` : ''}`,

  getFilesManagement: () => '/client-account/data-import-export',
  getRCUScreeningProfiles: entityId => `/case-management/screening-profiles/${entityId}/request-client-update`,
  getDataPdfReports: () => `/reports`,

  getUserOnboardingInfo: () => `${BASE_ROUTE}/onboarding`,
  updateUserOnboarding: () => `${BASE_ROUTE}/onboarding`,

  getIdentityVerificationInfo: () => `${BASE_ROUTE}/identity-verification`,
  updateIdentityVerificationInfo: () => `${BASE_ROUTE}/identity-verification`,

  getDocumentAuthInfo: () => `${BASE_ROUTE}/document-auth`,
  updateDocumentAuthInfo: () => `${BASE_ROUTE}/document-auth`,

  getFacialMatchingInfo: () => `${BASE_ROUTE}/facial-matching`,
  updateFacialMatchingInfo: () => `${BASE_ROUTE}/facial-matching`,
  getLicenceLimits: () => `${BASE_ROUTE}/licence-limits`,
  removeReport: reportId => `/reports/${reportId}`,
};

const togglePageStatus = tab => {
  return axiosInstance.put(ENDPOINTS.changePageStatus(), undefined, {
    params: {
      tab,
    },
  });
};

const getDocumentAuthentication = () => axiosInstance.get(ENDPOINTS.documentAuthentication());
const updateDocumentAuthentication = body => axiosInstance.put(ENDPOINTS.documentAuthentication(), body);

const getRiskLevels = () => axiosInstance.get(ENDPOINTS.riskLevels());
const updateRiskLevels = (body, shouldForce = false) => {
  const params = shouldForce ? { forceRequest: true } : {};
  return axiosInstance.put(ENDPOINTS.riskLevels(), body, { params });
};

const getRiskScreening = params => axiosInstance.get(ENDPOINTS.getRiskScreening(), { params });
const createRiskScreeningProfile = data => axiosInstance.post(ENDPOINTS.createRiskScreeningProfile(), data);
const updateRiskScreeningProfile = data => axiosInstance.put(ENDPOINTS.updateRiskScreeningProfile(), data);
const getRiskScreeningPprofile = profileId => {
  return axiosInstance.get(ENDPOINTS.getRiskScreeningPprofile(profileId));
};
const checkRiskScreeningProfileName = profileName =>
  axiosInstance.get(ENDPOINTS.checkRiskScreeningProfileName(), {
    params: {
      profileName,
    },
  });

const deleteRiskScreeningProfile = (profileId, forceRequest, deleteOption) => {
  const params = forceRequest ? { profileId, forceRequest, deleteOption } : { profileId };
  return axiosInstance.delete(ENDPOINTS.deleteRiskScreeningProfile(), {
    params,
  });
};

const setDefaultRiskScreeningProfile = id => axiosInstance.put(ENDPOINTS.setDefaultRiskScreeningProfile(), { id });

const getSupportingDocumentsPackages = params => axiosInstance.get(ENDPOINTS.supportingDocumentsPackage(), { params });
const getSupportingDocumentsSuggestions = () => axiosInstance.get(ENDPOINTS.getSupportingDocumentsSuggestions());
const getSupportingDocumentsPackage = id => axiosInstance.get(ENDPOINTS.supportingDocumentsPackage(id), {});
const deleteSupportingDocumentPackage = (documentId, forceRequest) => {
  return axiosInstance.delete(ENDPOINTS.supportingDocumentsPackage(documentId), { params: { forceRequest } });
};
const updateSupportingDocumentPackage = body => axiosInstance.put(ENDPOINTS.supportingDocumentsPackage(), body);
const createSupportingDocumentPackage = body => axiosInstance.post(ENDPOINTS.supportingDocumentsPackage(), body);

const getFilesManagement = params => axiosInstance.get(ENDPOINTS.getFilesManagement(), { params });

const getRCUScreeningProfiles = data => {
  const { entityId, ...params } = data;
  return axiosInstance.get(ENDPOINTS.getRCUScreeningProfiles(entityId), { params });
};

const getDataPdfReports = data => {
  const { filters, filterOn, ...params } = data;

  const filterData = FilterFormsService.normalizeReportsFormData(filters, filterOn);
  return axiosInstance.post(ENDPOINTS.getDataPdfReports(), filterData, {
    params,
  });
};
const removeReport = reportId => axiosInstance.delete(ENDPOINTS.removeReport(reportId));
const getUserOnboardingInfo = () => axiosInstance.get(ENDPOINTS.getUserOnboardingInfo());
const updateUserOnboarding = data => axiosInstance.put(ENDPOINTS.updateUserOnboarding(), data);

const getIdentityVerificationInfo = () => axiosInstance.get(ENDPOINTS.getIdentityVerificationInfo());
const updateIdentityVerificationInfo = data => axiosInstance.put(ENDPOINTS.updateIdentityVerificationInfo(), data);

const getDocumentAuthInfo = () => axiosInstance.get(ENDPOINTS.getDocumentAuthInfo());
const updateDocumentAuthInfo = data => axiosInstance.put(ENDPOINTS.updateDocumentAuthInfo(), data);

const getFacialMatchingInfo = () => axiosInstance.get(ENDPOINTS.getFacialMatchingInfo());
const updateFacialMatchingInfo = data => axiosInstance.put(ENDPOINTS.updateFacialMatchingInfo(), data);

const getLicenceLimits = () => axiosInstance.get(ENDPOINTS.getLicenceLimits());

export default {
  portalManagement,
  togglePageStatus,
  getDocumentAuthentication,
  updateDocumentAuthentication,
  getRiskLevels,
  updateRiskLevels,
  getRiskScreening,
  getRiskScreeningPprofile,
  createRiskScreeningProfile,
  getRCUScreeningProfiles,
  deleteRiskScreeningProfile,
  updateRiskScreeningProfile,
  checkRiskScreeningProfileName,
  setDefaultRiskScreeningProfile,
  getSupportingDocumentsPackages,
  getSupportingDocumentsSuggestions,
  deleteSupportingDocumentPackage,
  updateSupportingDocumentPackage,
  createSupportingDocumentPackage,
  getFilesManagement,
  webhooks,
  apikeys,
  riskScoring,
  userManagement,
  getDataPdfReports,
  getUserOnboardingInfo,
  updateUserOnboarding,
  getIdentityVerificationInfo,
  updateIdentityVerificationInfo,
  getDocumentAuthInfo,
  updateDocumentAuthInfo,
  getFacialMatchingInfo,
  updateFacialMatchingInfo,
  removeReport,
  automations,
  licences,
  settings,
  account,
  supportingDocumentPackages,
  getSupportingDocumentsPackage,
  getLicenceLimits,
};
