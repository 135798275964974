/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Lock } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import DeleteButton from '../../../../../../../../../../../components/Buttons/DeleteButton';
import SelectFilterContent from '../../../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../../../../../components/ToggleField';
import setClassSuffix from '../../../../../../../../../../../utilities/services/ClassManager';
import { RCUContext } from '../../../../../../../../../../../utilities/services/contexts';
import validators from '../../../../../../../../../../../utilities/services/validators';

const DocsTableRow = ({ index, namePrefix, className, onRemove, onOptionArrayChange }) => {
  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const { initialConfiguration } = useContext(RCUContext);

  const originalSupportingDocumentPackagesTypes = useMemo(
    () =>
      initialConfiguration?.supportingDocumentPackagesTypes?.map(elm => ({
        id: elm.id,
        label: elm.name,
        value: elm.id,
      })) || [],
    [initialConfiguration],
  );

  const [docOptions, setDocOptions] = useState(originalSupportingDocumentPackagesTypes);

  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  const {
    input: { value: suppDocs },
  } = useField(`${namePrefixWithDot}dataCollection.packagesTypes`);

  const {
    input: { value: packageId },
  } = useField(`${namePrefixWithDot}dataCollection.packagesTypes.${index}.packageId`);
  useEffect(() => {
    const selectedSuppDocWithoutCurrent = suppDocs.filter(el => el?.packageId !== packageId);
    const notSelectedSuppDocsIncludingCurrent = originalSupportingDocumentPackagesTypes.filter(
      doc => !selectedSuppDocWithoutCurrent.some(el => el?.packageId === doc?.value),
    );
    onOptionArrayChange(originalSupportingDocumentPackagesTypes.length === suppDocs.length);
    setDocOptions(notSelectedSuppDocsIncludingCurrent);
  }, [suppDocs, originalSupportingDocumentPackagesTypes]);

  const handleOnRemove = useCallback(() => {
    suppDocs.length === 1 && onOptionArrayChange(false);
    onRemove(index);
  }, [onRemove, index, originalSupportingDocumentPackagesTypes, suppDocs]);

  const usersToAssign = useSelector(state =>
    state.global.usersToAssign.map(({ id: uId, name }) => ({
      label: name,
      value: uId,
      id: uId,
    })),
  );

  const isLocked = suppDocs[index].locked;

  const hideRemove = useMemo(() => {
    const isOnlyDocument = suppDocs.length === 1;
    return isLocked || isOnlyDocument;
  }, [suppDocs, isLocked]);
  return (
    <tr className={`${className}__row`}>
      <td className={setColumnSuffix('__template')}>
        <Field
          name={`${namePrefixWithDot}dataCollection.packagesTypes.${index}.packageId`}
          component={SelectField}
          options={docOptions}
          renderAsPortal
          Trigger={<SelectTrigger placeholder="Document Package" />}
          Content={<SelectFilterContent />}
          disabled={isLocked}
          validate={validators.customValidatorForSupportingDocumentsRow()}
        />
      </td>
      <td className={setColumnSuffix('__autoAssign')}>
        <Field
          name={`${namePrefixWithDot}dataCollection.packagesTypes.${index}.assignedUserId`}
          className={`${className}__assigned-user`}
          component={SelectField}
          useFullValue={false}
          renderAsPortal
          placeholder="Search Users"
          options={usersToAssign}
          Trigger={<SelectTrigger placeholder="Search Users" />}
          Content={<SelectFilterContent />}
        />
      </td>
      <td className={setColumnSuffix('__required')}>
        <Field
          name={`${namePrefixWithDot}dataCollection.packagesTypes.${index}.required`}
          component={ToggleField}
          showCustomText
          toggleOnText="Required"
          toggleOffText="Optional"
        />
      </td>
      <td className={setColumnSuffix('__remove')}>
        {hideRemove ? <Lock /> : <DeleteButton onClick={handleOnRemove} />}
      </td>
    </tr>
  );
};

DocsTableRow.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
  namePreffix: PropTypes.string.isRequired,
  onOptionArrayChange: PropTypes.func,
};
DocsTableRow.defaultProps = {
  className: '',
  onRemove: () => {},
  onOptionArrayChange: () => {},
};
export default DocsTableRow;
