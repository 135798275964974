import toastr from 'toastr';
import api from '../../api';
import bus from '../../modules/bus';
import enums from '../enums';

/**
 * View Related Events
 */
const openTableRecord = (event, payload, index, shouldFocus) => {
  bus.broadcastEvent(event, {
    payload,
    index,
    shouldFocus,
  });
};
const avoidNegativeSkip = skip => (skip === -1 ? 0 : skip);

const calcSkip = (skipIndex, sectionSize) => sectionSize * avoidNegativeSkip(skipIndex);

// index = section alias
const openCorrespondingSection = (index, shouldFocus) => {
  bus.broadcastEvent(enums.BUS_EVENTS.EXPAND_SECTIONS, [
    {
      index,
      shouldFocus,
    },
  ]);
};

const viewRelatedEvents = async (entityId, isNaturalPerson, eventId, sectionEvents = false) => {
  const {
    data: { value: relatedResponse },
  } = await api.kyc.getRelatedEvents(entityId, eventId, isNaturalPerson);
  const sectionSize = 5;
  const defaultSortOrder = 1;
  const defaultSortCriteria = 'date';

  const requestQueue = [];
  const handlerDataQueue = [];

  const {
    personalInformationSectionIndex,
    personalInformationEventId,
    personalInformationEventIndex,
    documentSectionIndex,
    documentEventId,
    documentEventIndex,
    biometricAuthenticationSectionIndex,
    biometricAuthenticationId: biometricAuthenticationEventId,
    biometricAuthenticationIndex,
    identityDocumentSectionIndex,
    identityDocumentEventId,
    identityDocumentEventIndex,
    addressEventId,
    addressSectionIndex,
    addressEventIndex,
  } = relatedResponse;

  if (isNaturalPerson) {
    if (personalInformationSectionIndex > -1) {
      const personalSkip = calcSkip(personalInformationSectionIndex, sectionSize);

      requestQueue.push(
        personalSkip > 0
          ? api.kyc.entityManagement.naturalPerson.getInformationTable({
              entityId,
              limit: 5,
              skip: personalSkip,
              sortOrder: defaultSortOrder,
              sortCriteria: defaultSortCriteria,
            })
          : () => {},
      );

      const indexToOpen = sectionEvents && eventId === personalInformationEventId ? -1 : personalInformationEventIndex;
      handlerDataQueue.push({
        tableEvent: enums.BUS_EVENTS.OVERRIDE_PERSONAL_TABLE,
        tableRecordIndex: indexToOpen,
        tableSection: enums.ACCORDION_INDEXES.PERSONAL_INFORMATION,
      });
    }
    if (identityDocumentSectionIndex > -1) {
      const identitySkip = calcSkip(identityDocumentSectionIndex, sectionSize);

      requestQueue.push(
        identitySkip > 0
          ? api.kyc.entityManagement.naturalPerson.getIdentityDocumentsTable({
              entityId,
              skip: identitySkip,
              limit: sectionSize,
              sortOrder: defaultSortOrder,
              sortCriteria: defaultSortCriteria,
            })
          : () => {},
      );

      const indexToOpen = sectionEvents && eventId === identityDocumentEventId ? -1 : identityDocumentEventIndex;

      handlerDataQueue.push({
        tableEvent: enums.BUS_EVENTS.OVERRIDE_IDENTITY_TABLE,
        tableRecordIndex: indexToOpen,
        tableSection: enums.ACCORDION_INDEXES.IDENTITY_DOCUMENTS,
      });
    }
    if (biometricAuthenticationSectionIndex > -1) {
      const biometricSkip = calcSkip(biometricAuthenticationSectionIndex, sectionSize);

      requestQueue.push(
        biometricSkip
          ? api.kyc.entityManagement.naturalPerson.getBiometricTable({
              entityId,
              skip: biometricSkip,
              sortOrder: defaultSortOrder,
              sortCriteria: defaultSortCriteria,
              limit: sectionSize,
            })
          : () => {},
      );
      const indexToOpen =
        sectionEvents && eventId === biometricAuthenticationEventId ? -1 : biometricAuthenticationIndex;

      handlerDataQueue.push({
        tableEvent: enums.BUS_EVENTS.OVERRIDE_BIOMETRICS_TABLE,
        tableRecordIndex: indexToOpen,
        tableSection: enums.ACCORDION_INDEXES.BIOMETRIC_AUTHENTICATIONS,
      });
    }
  } else {
    if (personalInformationSectionIndex > -1) {
      const corporateSkip = calcSkip(personalInformationSectionIndex, sectionSize);

      requestQueue.push(
        corporateSkip > 0
          ? api.kyc.entityManagement.legalEntity.getInformationTable({
              entityId,
              limit: 5,
              skip: corporateSkip,
              sortOrder: defaultSortOrder,
              sortCriteria: defaultSortCriteria,
            })
          : () => {},
      );

      const indexToOpen = sectionEvents && eventId === personalInformationEventId ? -1 : personalInformationEventIndex;

      handlerDataQueue.push({
        tableEvent: enums.BUS_EVENTS.OVERRIDE_CORPORATE_TABLE,
        tableRecordIndex: indexToOpen,
        tableSection: enums.ACCORDION_INDEXES.CORPORATE_INFORMATION,
      });
    }
    if (addressSectionIndex > -1) {
      const addressSkip = calcSkip(relatedResponse.addressSectionIndex, sectionSize);
      requestQueue.push(
        addressSkip > 0
          ? api.kyc.entityManagement.legalEntity.getAddressesTable({
              entityId,
              limit: 5,
              skip: addressSkip,
            })
          : () => {},
      );

      const indexToOpen = sectionEvents && eventId === addressEventId ? -1 : addressEventIndex;

      handlerDataQueue.push({
        tableEvent: enums.BUS_EVENTS.OVERRIDE_ADDRESS_TABLE,
        tableRecordIndex: indexToOpen,
        tableSection: enums.ACCORDION_INDEXES.ADDRESS,
      });
    }
  }

  if (documentSectionIndex > -1) {
    const documentSkip = calcSkip(relatedResponse.documentSectionIndex, sectionSize);
    requestQueue.push(
      documentSkip > 0
        ? api.kyc.entityManagement.getDocumentsTable({
            entityId,
            skip: documentSkip,
            limit: sectionSize,
            sortOrder: defaultSortOrder,
            sortCriteria: defaultSortCriteria,
          })
        : () => {},
    );
    const indexToOpen = sectionEvents && eventId === documentEventId ? -1 : documentEventIndex;

    handlerDataQueue.push({
      tableEvent: enums.BUS_EVENTS.OVERRIDE_DOCUMENTS_TABLE,
      tableRecordIndex: indexToOpen,
      tableSection: enums.ACCORDION_INDEXES.DOCUMENTS,
    });
  }
  if (requestQueue.length > 0) {
    const responsesQueue = await Promise.all(requestQueue);
    let shouldNavigate = true;
    responsesQueue.forEach((response, index) => {
      const { tableEvent, tableRecordIndex, tableSection } = handlerDataQueue[index];
      openCorrespondingSection(tableSection);
      openTableRecord(tableEvent, response.data, tableRecordIndex, tableRecordIndex > -1 && shouldNavigate);
      if (tableRecordIndex > -1 && shouldNavigate) {
        shouldNavigate = false;
      }
    });
    if (handlerDataQueue.length === 1 && handlerDataQueue[0].tableRecordIndex === -1) {
      toastr.warning('There is no Related Events');
    }
  }
};
export default viewRelatedEvents;
