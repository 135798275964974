import api from 'api';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import toastr from 'toastr';
import PrimaryButton from '../../../../../../../components/Buttons/PrimaryButton';
import './styles.scss';
import utils from './utils';

const CardInfo = ({ brand, expMonth, expYear, id, default: isDefault, lastFour, setCards, setIsLoading }) => {
  const classes = classNames('kyc-card-info', { 'kyc-card-info--default': isDefault });

  const handleSetDefaultCard = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: defaultCard } = await api.administration.account.setDefaultPaymentMethod(id);
      setCards(old =>
        old.map(card => (card.id === defaultCard.cardId ? { ...card, default: true } : { ...card, default: false })),
      );
      toastr.success('Successfully updated Default Payment Method');
    } catch (err) {
      console.error(err);
      toastr.error('Error occured while updating Default Payment Method');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  const handleDeleteCard = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: deletedCard } = await api.administration.account.deletePaymentMethod(id);
      setCards(old => old.filter(card => card.id !== deletedCard.id));
      toastr.success('Successfully deleted payment method');
    } catch (err) {
      console.error(err);
      toastr.error('Error occured while deleting Payment Method');
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  return (
    <div className={classes}>
      <div className="kyc-card-info__brand-icon">{utils.cardBrandToIcon[brand]}</div>
      <div className="kyc-card-info__info-box">
        <div className="kyc-card-info__info">
          <p className="kyc-card-info__main">
            {brand} ending in {lastFour}
          </p>
          <p className="kyc-card-info__expiry">
            Expiry {expMonth}/{expYear}
          </p>
          {isDefault ? (
            <span>Default card</span>
          ) : (
            <div>
              <PrimaryButton onClick={handleSetDefaultCard} variant="link">
                Set as Default
              </PrimaryButton>
              <PrimaryButton onClick={handleDeleteCard} variant="link">
                Delete Card
              </PrimaryButton>
            </div>
          )}
        </div>

        <div className="kyc-card-info__circle" />
      </div>
    </div>
  );
};
CardInfo.propTypes = {
  addressZip: PropTypes.string,
  brand: PropTypes.string.isRequired,
  expMonth: PropTypes.number,
  expYear: PropTypes.number,
  id: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  lastFour: PropTypes.string.isRequired,
};

CardInfo.defaultProps = {
  addressZip: undefined,
  expMonth: undefined,
  expYear: undefined,
};

export default CardInfo;
