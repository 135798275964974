import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../../../../../components/InputField';

const MAX = 100;
const MIN = 0;
const BiometricDetailsFields = () => {
  return (
    <>
      <span className="ickyc-biometric-details__divider">Biometric Confidence</span>
      <Field component={InputField} type="number" max={MAX} min={MIN} name="biometricConfidence" />

      <span className="ickyc-biometric-details__divider">Liveness Confidence</span>
      <Field component={InputField} type="number" max={MAX} min={MIN} name="livenessConfidence" />
    </>
  );
};
export default BiometricDetailsFields;
