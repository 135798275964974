import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import FormModal from '../../../../../../../components/Modal/FormModal';
import DateManager from '../../../../../../../utilities/services/DateManager';
import ReportsFilterForm from '../ReportsFIlterForm';

const ReportsFilterModal = ({ hideModal, onFilterSave, initialFilters }) => {
  const users = useSelector(state =>
    state.global.usersToAssign.map(({ id: uId, name }) => ({
      label: name,
      value: uId,
      id: uId,
    })),
  );
  const applyFilters = async values => {
    const { dateFrom, dateUntil, userTypes, requestedBy } = values;
    const otherValidation = dateFrom || dateUntil || userTypes || requestedBy;
    if (!otherValidation) {
      return {
        [FORM_ERROR]: 'No Filters Selected ',
      };
    }
    if (DateManager.compareDates(dateFrom, dateUntil)) {
      return {
        [FORM_ERROR]: 'Date From Must Be Before Date Until ',
      };
    }
    onFilterSave(values);
    hideModal();
  };

  return (
    <FormModal
      onSubmit={applyFilters}
      title="Filter"
      initialValues={initialFilters}
      hideModal={hideModal}
      className="ickyc-filter-modal"
      renderAsPortal={false}
    >
      <ReportsFilterForm users={users} />
    </FormModal>
  );
};
ReportsFilterModal.propTypes = {
  hideModal: PropTypes.func,
  onFilterSave: PropTypes.func,
  initialFilters: PropTypes.shape({}),
};
ReportsFilterModal.defaultProps = {
  initialFilters: {},
  hideModal: () => {},
  onFilterSave: () => {},
};
export default ReportsFilterModal;
