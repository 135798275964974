import { Lock } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, useField } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import RadioButtonField from '../../../../../../../../../components/RadioButtonField';
import RadioGroup from '../../../../../../../../../components/RadioGroup';
import ToggleField from '../../../../../../../../../components/ToggleField';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import ModuleOption from '../ModuleOption';
import ModuleOptions from '../ModuleOptions';
import ModuleSettings from '../ModuleSettings';
import ModuleTitleSettings from '../ModuleTitleSettings';
import AdditionalDetailsFormFields from './components/AdditionalDetailsFormFields';

const AdditionalDetailsModule = ({ index, indexToShow, isDropAllowed }) => {
  const className = 'ickyc-table';

  const {
    input: { value: toggleValue },
  } = useField(`modules[${index}].isEnabled`);

  return (
    <CollapsibleTableRow
      isDropAllowed={isDropAllowed}
      className={className}
      withIndexColumn
      index={indexToShow}
      isToggled={toggleValue}
      icon={<Lock />}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Additional Details</td>
          <td className={`${className}__column__enabled`} onClick={e => e.stopPropagation()}>
            <Field
              preview
              name={`modules[${index}].isEnabled`}
              component={ToggleField}
              showCustomText
              toggleOnText="Enabled"
            />
          </td>
        </>
      }
    >
      <ModuleSettings>
        <ModuleTitleSettings index={index} withDescription AdditionalFieldsComponent={AdditionalDetailsFormFields} />
        <ModuleOptions>
          <ModuleOption>
            <RadioGroup label="Form / API">
              <Field
                component={RadioButtonField}
                name={`modules[${index}].source`}
                type="radio"
                parse={value => Number(value)}
                value={0}
                label="Subject Enters In Form"
              />
              <Field
                component={RadioButtonField}
                name={`modules[${index}].source`}
                type="radio"
                value={1}
                parse={value => Number(value)}
                label="API Pass In / Subject Enters In Form"
              />
              <Field
                component={RadioButtonField}
                name={`modules[${index}].source`}
                parse={value => Number(value)}
                type="radio"
                value={2}
                label="API Pass In / Skip Screen"
              />
            </RadioGroup>
          </ModuleOption>
        </ModuleOptions>
      </ModuleSettings>
    </CollapsibleTableRow>
  );
};
AdditionalDetailsModule.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
};
export default AdditionalDetailsModule;
