import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import classNames from 'classnames';
import LoadMore from 'components/Tables/Table/components/LoadMore';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import IconButton from '../Buttons/IconButton';
import ResultsPerPage from '../ResultsPerPage';
import './styles.scss';

/**
 * Navigation for pages in the tables.
 * @param {number} pageNumber - number of the current page
 * @param {number} pageSize - number of items per page
 * @param {number} totalNumber - total number of elements
 * @param {func} goToNextPage - moves to the next page
 * @param {func} goToPrevPage - moves to the previous page
 * @param {func} onLoadMore - load more content
 * @param {boolean} withLoadMore - if true "Load More" button is displayed
 */

const TableNavigation = ({
  pageNumber,
  pageSize,
  tempPageSize,
  totalNumber,
  goToNextPage,
  goToPrevPage,
  withLimitChange,
  changeLimit,
  onLoadMore,
  withLoadMore,
}) => {
  const [beginning, setBeginning] = useState(0);
  const [ending, setEnding] = useState(0);
  const [navigationData, setNavigationData] = useState('');

  const renderPreviousButton = () => (
    <IconButton
      className="ickyc-table-navigation__prev-button"
      withBorder
      disabled={pageNumber <= 1}
      onClick={goToPrevPage}
    >
      <KeyboardArrowLeft />
    </IconButton>
  );

  const renderNextButton = () => (
    <IconButton
      className="ickyc-table-navigation__next-button"
      withBorder
      disabled={totalNumber <= pageNumber * pageSize}
      onClick={goToNextPage}
    >
      <KeyboardArrowRight />
    </IconButton>
  );

  const classes = classNames({
    'ickyc-table-navigation': true,
    'ickyc-table-navigation--with-limit': withLimitChange,
    'ickyc-table-navigation--with-load-more': withLoadMore,
  });
  const removeLoadMore = useMemo(() => {
    return pageSize > totalNumber;
  }, [pageSize, totalNumber]);

  useEffect(() => {
    setBeginning(pageNumber ? (pageNumber - 1) * pageSize + 1 : 0);
    setEnding(totalNumber < pageNumber * pageSize ? totalNumber : tempPageSize || pageNumber * pageSize);
  }, [pageNumber, pageSize, totalNumber, tempPageSize]);

  useEffect(() => {
    if (beginning !== ending) {
      setNavigationData(`${beginning}-${ending} of ${totalNumber}`);
    } else {
      setNavigationData(`${beginning} of ${totalNumber}`);
    }
  }, [beginning, ending, totalNumber]);

  if (removeLoadMore) return;

  return withLoadMore ? (
    <LoadMore onLoadMore={onLoadMore} total={totalNumber} limit={pageSize} currentPage={pageNumber} />
  ) : (
    <nav className={classes}>
      {withLimitChange && (
        <div className="ickyc-table-navigation__block">
          <ResultsPerPage extent={pageSize} setExtent={changeLimit} />
        </div>
      )}

      <div className="ickyc-table-navigation__block">
        <p>{navigationData}</p>
        {renderPreviousButton()}
        {renderNextButton()}
      </div>
    </nav>
  );
};

TableNavigation.propTypes = {
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  tempPageSize: PropTypes.number,
  totalNumber: PropTypes.number,
  goToNextPage: PropTypes.func.isRequired,
  goToPrevPage: PropTypes.func.isRequired,
  changeLimit: PropTypes.func,
  withLimitChange: PropTypes.bool,
  onLoadMore: PropTypes.func,
  withLoadMore: PropTypes.bool,
};

TableNavigation.defaultProps = {
  changeLimit: () => {},
  withLimitChange: false,
  pageNumber: 0,
  pageSize: 0,
  totalNumber: 0,
  onLoadMore: () => {},
  withLoadMore: false,
  tempPageSize: undefined,
};

export default TableNavigation;
