import api from 'api';
import IComplyForm from 'components/Form/IComplyForm';
import Spinner from 'components/Spinner';
import arrayMutators from 'final-form-arrays';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import enums from 'utilities/enums';
import setClassSuffix from 'utilities/services/ClassManager';
import { EntityContext, HistoryRecordContext } from 'utilities/services/contexts';
import HistoryRecordControls from '../../../../../components/HistoryRecordControls';
import IssueList from '../../../../../components/IssueList';
import LegalEntityInformationForm from '../../../../../components/LegalEntityInformationForm';
import ThirdPartyConfirmation from '../../../ThirdPartyConfirmation';
import './index.scss';

const CorporateInformationPreview = ({ data, onDataFetch }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { entityId, updateEntityInfo } = useContext(EntityContext);
  const { eventHistoryId } = useContext(HistoryRecordContext);
  const [controlsInfo, setControlsInfo] = useState({});

  const showControlls = useMemo(
    () =>
      data?.corporateInformation?.thirdPartySuggestion?.suggestionStatus !== 'pending' &&
      !data?.corporateInformation?.thirdPartySuggestion?.thirdPartyReport?.issuesCount,
    [
      data?.corporateInformation?.thirdPartySuggestion?.suggestionStatus,
      data?.corporateInformation?.thirdPartySuggestion?.thirdPartyReport?.issuesCount,
    ],
  );

  useEffect(() => {
    if (JSON.stringify(data) !== '{}') {
      setIsLoading(false);
      return;
    }
    const fetchRecord = async () => {
      try {
        setIsLoading(true);
        const { data: resData } = await api.kyc.entityManagement.legalEntity.getInformationTableRecord(
          entityId,
          eventHistoryId,
        );
        const {
          status,
          sourceType,
          sourceDetail,
          eventId,
          portalConfigurationId,
          portalDomain,
          portalName,
          ...rest
        } = resData;
        setControlsInfo({ status, sourceType, sourceDetail, eventId, portalConfigurationId, portalDomain, portalName });
        onDataFetch({ corporateInformation: resData });
      } catch (err) {
        onDataFetch({ message: `Error Loading History Record: ${eventHistoryId}` });
      } finally {
        setIsLoading(false);
      }
    };
    fetchRecord();
  }, [data, onDataFetch, eventHistoryId, entityId]);

  const handleStatusChange = useCallback(
    (issuesChange, issuesList, status, allRejected) => {
      if (status === enums.HISTORY_EVENT_STATUSES.ACCEPTED) {
        // eslint-disable-next-line react/prop-types
        const { corporateInformation } = data;
        updateEntityInfo(corporateInformation);
      }
      bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
        type: 'kyc',
        section: enums.ACCORDION_INDEXES.CORPORATE_INFORMATION,
        issuesChange,
        allRejected,
      });

      const newData = {
        ...data,
        corporateInformation: {
          ...data.corporateInformation,
          issues: issuesList,
        },
      };

      onDataFetch({ ...newData, issuesList });
    },
    [onDataFetch, data, updateEntityInfo],
  );

  const classBase = 'ickyc-corporate-record';
  const setSuffix = setClassSuffix(classBase);

  if (isLoading) {
    return (
      <div className={setSuffix('__wrapper')}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={setSuffix('__wrapper')}>
      {data.message ? (
        <div className={setSuffix('__message')}>{data.message}</div>
      ) : (
        <>
          <div className={classBase}>
            <div className={setSuffix('__full')}>
              <IComplyForm initialValues={data} onSubmit={() => {}} mutators={{ ...arrayMutators }}>
                <IssueList list={data?.corporateInformation?.issues || []} />
                <LegalEntityInformationForm preview responsive />
                {data?.corporateInformation?.thirdPartySuggestion && (
                  <ThirdPartyConfirmation data={data?.corporateInformation} onDataFetch={onDataFetch} />
                )}
              </IComplyForm>
            </div>
          </div>
          {showControlls && (
            <HistoryRecordControls
              info={controlsInfo}
              onStatusChange={handleStatusChange}
              apiRequest={api.kyc.entityManagement.changeHistoryCorporateEventStatus}
            />
          )}
        </>
      )}
    </div>
  );
};
CorporateInformationPreview.propTypes = {
  data: PropTypes.shape({
    message: PropTypes.string,
    corporateInformation: PropTypes.shape({
      issues: PropTypes.arrayOf(PropTypes.string),
    }),
    thirdPartySuggestion: PropTypes.shape({
      thirdPartyReport: PropTypes.shape({
        issuesCount: PropTypes.number,
      }),
      suggestionStatus: PropTypes.string,
    }),
  }),
  onDataFetch: PropTypes.func,
};
CorporateInformationPreview.defaultProps = {
  data: {},
  onDataFetch: () => {},
};
export default CorporateInformationPreview;
