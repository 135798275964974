import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import setClassSuffix from '../../utilities/services/ClassManager';
import './styles.scss';

/**
 * Badge icon with content inside.
 * @param {*} content - content displayed inside the badge, usually number, or initials
 * @param {boolean} red -
 */

const Badge = ({ content, red, gray, showOnlyNumber, ...rest }) => {
  const classBase = 'ickyc-badge';
  const setSuffix = setClassSuffix(classBase);

  const classes = classNames([
    classBase,
    {
      [setSuffix('--zero')]: !content || showOnlyNumber,
      [setSuffix('--red')]: red,
      [setSuffix('--gray')]: gray,
      [setSuffix('--string')]: typeof content === 'string',
    },
  ]);

  return (
    <span className={classes} {...rest}>
      {content || 0}
    </span>
  );
};

Badge.propTypes = {
  content: PropTypes.node.isRequired,
  red: PropTypes.bool,
  gray: PropTypes.bool,
  showOnlyNumber: PropTypes.bool,
};

Badge.defaultProps = {
  red: false,
  gray: false,
  showOnlyNumber: false,
};

export default Badge;
