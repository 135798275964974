import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import EnhancedRoute from '../../routes/Enhanced';

const Routes = ({ routes, className }) => {
  const { url } = useRouteMatch();

  const finalPath = useCallback(
    (to = '') => {
      const { length } = url;
      if (url[length - 1] === to[0] && to[0] === '/') {
        return url + to.slice(1);
      }
      return url + to;
    },
    [url],
  );

  return (
    <Switch>
      {routes.map(({ path, exact = true, ...rest }) => (
        <Route
          key={`${path}`}
          path={finalPath(path)}
          exact={exact}
          render={props => <EnhancedRoute {...props} {...rest} className={className} />}
        />
      ))}
    </Switch>
  );
};
Routes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
};

Routes.defaultProps = {
  className: undefined,
};

export default Routes;
