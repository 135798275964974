import PropTypes from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import Section from '../../../../../../../../../components/Section';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import validators from '../../../../../../../../../utilities/services/validators';
import './styles.scss';

const UsersAndLimitsRow = ({ namePreffix, disabled, index }) => {
  const classBase = 'ickyc-bl-users-and-limits-row';
  const setSufix = setClassSuffix(classBase);

  const { values } = useFormState();
  const { riskScreeningEnabled: riskScreenFlag = false, supportingDocumentsEnabled: suppDocFlag = false } = {
    ...values?.licences?.at(index),
  };

  return (
    <>
      <Section className={classBase}>
        <h3>Number of Users</h3>
        <Field
          label="Admin Users"
          component={InputField}
          disabled={disabled}
          name={`${namePreffix}.defaultValues.numOfAdminUsersDefault`}
          id={`${namePreffix}.defaultValues.numOfAdminUsersDefault`}
          placeholder=""
          validate={validators.required()}
        />
        <Field
          label="Platform Users"
          component={InputField}
          disabled={disabled}
          name={`${namePreffix}.defaultValues.numOfUsersDefault`}
          id={`${namePreffix}.defaultValues.numOfUsersDefault`}
          placeholder=""
          validate={validators.required()}
        />
      </Section>
      <Section className={classBase} divider>
        <h3 className={setSufix('__limits-title')}>Limits</h3>
        <Field
          label="Supporting Document Packages"
          component={InputField}
          disabled={disabled || !suppDocFlag}
          hintMessage={suppDocFlag ? '' : 'Supporting Document Packages are disabled'}
          name={`${namePreffix}.defaultValues.supportingDocumentsLimit`}
          id={`${namePreffix}.defaultValues.supportingDocumentsLimit`}
          placeholder=""
          validate={suppDocFlag && validators.required()}
        />
        <Field
          label="Risk Screening"
          component={InputField}
          disabled={disabled || !riskScreenFlag}
          hintMessage={riskScreenFlag ? '' : 'Risk Screening is disabled'}
          name={`${namePreffix}.defaultValues.riskScreeningLimit`}
          id={`${namePreffix}.defaultValues.riskScreeningLimit`}
          placeholder=""
          validate={riskScreenFlag && validators.required()}
        />
      </Section>
    </>
  );
};

UsersAndLimitsRow.propTypes = {
  namePreffix: PropTypes.string,
  disabled: PropTypes.bool,
  index: PropTypes.number,
};
UsersAndLimitsRow.defaultProps = {
  namePreffix: '',
  disabled: false,
  index: 0,
};

export default UsersAndLimitsRow;
