import AMLCaseModule from './components/AMLCaseModule';
import AdditionalDetailsModule from './components/AdditionalDetailsModule';
import AddressInformationModule from './components/AddressInformationModule';
import BiometricAuthenticationModule from './components/BiometricAuthenticationModule';
import ConsentGatewayLeModule from './components/ConsentGatewayLeModule';
import DocumentVerificationModule from './components/DocumentVerificationModule';
import FinalStepModule from './components/FinalStepModule';
import LandingScreenModule from './components/LandingScreenModule';
import PersonalDetailsModule from './components/PersonalDetailsModule';
import SupportingDocumentsModule from './components/SupportingDocumentsModule';

const modulesList = [
  { component: LandingScreenModule, index: 0 },
  { component: ConsentGatewayLeModule, index: 8 },
  { component: PersonalDetailsModule, index: 1 },
  { component: AdditionalDetailsModule, index: 2 },
  { component: AddressInformationModule, index: 3 },
  { component: DocumentVerificationModule, index: 4 },
  { component: BiometricAuthenticationModule, index: 5 },
  { component: AMLCaseModule, index: 7 },
  { component: SupportingDocumentsModule, index: 6 },
  { component: FinalStepModule, index: 9 },
];

export default { modulesList };
