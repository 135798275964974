/* eslint-disable jsx-a11y/no-autofocus */
import classNames from 'classnames';
import React, { useContext } from 'react';
import { SelectContext } from '../../../Select';
import Toggle from '../../../Toggle';
import SingleCountry from '../SingleCountry';
import './styles.scss';

const CountrySelectFilterContentToggleList = () => {
  const { options, hoverIndex, onChange, filterProps, multipleSelection, value } = useContext(SelectContext);
  return (
    <div className="ickyc-select-content-toggle-list">
      <input {...filterProps} autoFocus />

      <ul>
        {options.length > 0 ? (
          options.map((suggestion, index) => {
            const isSelected = multipleSelection
              ? value.some(val => val.value === suggestion.value)
              : value.value === suggestion.value;

            const classes = classNames({
              'ickyc-country-toggle-list-item': true,
              'ickyc-country-toggle-list-item--hovered': hoverIndex === index,
            });
            return (
              <li className={classes}>
                <Toggle
                  value={isSelected}
                  onChange={() => {
                    onChange(suggestion);
                  }}
                  label={<SingleCountry alpha2Code={suggestion.alpha2Code} name={suggestion.name} />}
                />
              </li>
            );
          })
        ) : (
          <li>No Results</li>
        )}
      </ul>
    </div>
  );
};

CountrySelectFilterContentToggleList.propTypes = {};
CountrySelectFilterContentToggleList.defaultProps = {};
export default CountrySelectFilterContentToggleList;
