import axiosInstance from '../axiosInstance';
import caseManagement from './caseManagement';
import entityManagement from './entityManagement';

const ENDPOINTS = {
  getClientUsers: () => `/client-account/assigned-users`,
  assignEntityToUser: entityId => `/entities/${entityId}/assigned-user`,
  assingEventToUser: (entityId, eventId) => `/entities/${entityId}/events/${eventId}/assigned-user`,
  setEntityRiskLevel: entityId => `/entities/${entityId}/risk-level`,
  performRiskScreening: () => '/case-management/risk-screening',
  getRelatedEvents: (entityId, eventId, isNaturalPerson, limit) =>
    '/entities/get-related-events-indexes' +
    `?eventId=${eventId}&entityId=${entityId}&isNpe=${isNaturalPerson}&limit=${limit}`,
  getAffiliations: entityId => `/entities/${entityId}/affiliations`,
  assingUserToListing: (logId, hitId, listingId) =>
    `/case-management/${logId}/hits/${hitId}/listings/${listingId}/assigned-user`,
  batchAssignUser: () => '/entities/batch/assigned-user',
  batchAssignStatus: () => '/entities/batch/entity-status',
  batchAssignRisk: () => '/entities/batch/risk-level',
  batchAssignTags: () => '/entities/batch/tags',
};
const assingEventToUser = (entityId, eventId, userId) => {
  return axiosInstance.put(ENDPOINTS.assingEventToUser(entityId, eventId), {
    userId,
  });
};

const assignEntityToUser = (entityId, userId) => {
  return axiosInstance.put(ENDPOINTS.assignEntityToUser(entityId), {
    userId,
  });
};

const setEntityRiskLevel = (entityId, value) => {
  return axiosInstance.put(ENDPOINTS.setEntityRiskLevel(entityId), {
    value,
  });
};
const getClientUsers = () => axiosInstance.get(ENDPOINTS.getClientUsers());

const performRiskScreening = data => {
  return axiosInstance.post(ENDPOINTS.performRiskScreening(), data);
};
const getRelatedEvents = (entityId, eventId, isNaturalPerson, limit = 5) => {
  return axiosInstance.get(ENDPOINTS.getRelatedEvents(entityId, eventId, isNaturalPerson, limit));
};
const getAffiliations = data => {
  const { entityId, ...params } = data;
  return axiosInstance.get(ENDPOINTS.getAffiliations(entityId), { params });
};
const assingUserToListing = (logId, hitId, listingId, userId) => {
  return axiosInstance.post(ENDPOINTS.assingUserToListing(logId, hitId, listingId), {
    userId,
  });
};
const batchAssignUser = data => axiosInstance.put(ENDPOINTS.batchAssignUser(), data);
const batchAssignStatus = data => axiosInstance.put(ENDPOINTS.batchAssignStatus(), data);
const batchAssignRisk = data => axiosInstance.put(ENDPOINTS.batchAssignRisk(), data);
const batchAssignTags = data => axiosInstance.put(ENDPOINTS.batchAssignTags(), data);

export default {
  performRiskScreening,
  assingEventToUser,
  assignEntityToUser,
  setEntityRiskLevel,
  caseManagement,
  entityManagement,
  getClientUsers,
  getRelatedEvents,
  getAffiliations,
  assingUserToListing,
  batchAssignUser,
  batchAssignStatus,
  batchAssignRisk,
  batchAssignTags,
};
