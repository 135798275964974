import CloseButton from 'components/Buttons/CloseButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import CountrySelectField from 'components/CountrySelectField';
import InputField from 'components/InputField';
import Section from 'components/Section';
import ToggleField from 'components/ToggleField';
import React, { useCallback, useContext, useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import Constants from 'utilities/constants';
import { PermissionGroupContext } from 'utilities/services/contexts';
import validators from 'utilities/services/validators';
import './styles.scss';

const invalidNumberChars = ['e', 'E', '+', '-', '.'];

const SearchTerms = () => {
  const classBase = 'ickyc-search-terms';

  const { ongoingMonitoring: canSetOngoingMonitoring } = useContext(PermissionGroupContext);

  const { mutators, change, reset } = useForm();

  const {
    values: { addCountry, addYear, ongoingMonitoring, searchTerms, name: selectedScreeningProfile },
  } = useFormState();

  const addSearchTerm = () => {
    mutators.push('searchTerms');
  };

  const removeSearchTerm = useCallback(
    index => {
      mutators.remove('searchTerms', index);
    },
    [mutators],
  );

  const handleCopyPaste = e => {
    const clipboardText = e?.clipboardData?.getData('text/plain');

    for (const char of clipboardText) {
      if (invalidNumberChars.includes(char)) {
        e.preventDefault();
        return;
      }
    }
  };

  useEffect(() => {
    reset();
  }, [addYear, addCountry]);

  useEffect(() => {
    searchTerms.forEach((_, index) => change(`searchTerms[${index}].ongoing`, ongoingMonitoring));
  }, [change, ongoingMonitoring, selectedScreeningProfile]);

  return (
    <Section
      title={
        <>
          Search Terms
          <PrimaryButton tabIndex={0} onClick={addSearchTerm}>
            + Add New Search Term
          </PrimaryButton>
        </>
      }
      divider
    >
      <FieldArray name="searchTerms">
        {({ fields }) =>
          fields.map((name, index) => (
            <div className={classBase} key={name}>
              <Field
                component={InputField}
                label="Name"
                required
                name={`${name}.name`}
                validate={validators.composeValidators(
                  validators.required(' '),
                  validators.stringMaxLength(Constants.CORP_NAME_MAX_LENGTH),
                )}
              />
              <Field
                component={InputField}
                label={`Year of Birth / Incorporation${addYear ? '' : ' (Optional)'}`}
                required={addYear}
                type="number"
                min={0}
                name={`${name}.yearOfBirth`}
                onKeyPress={e => invalidNumberChars.includes(e.key) && e.preventDefault()}
                onPaste={handleCopyPaste}
                validate={
                  addYear
                    ? validators.composeValidators(
                        validators.required(),
                        validators.minAllowedValue(`Value must be between 1850 and ${new Date().getFullYear()}`, 1850),
                        validators.maxAllowedValue(
                          `Value must be between 1850 and ${new Date().getFullYear()}`,
                          new Date().getFullYear(),
                        ),
                      )
                    : undefined
                }
              />
              <Field
                component={CountrySelectField}
                required={addCountry}
                validate={addCountry && validators.required()}
                label={
                  <>
                    <b>Country</b> {!addCountry && <>&nbsp;(Optional)</>}
                  </>
                }
                multipleSelection
                name={`${name}.countries`}
              />
              <div className={`${classBase}__remove`}>
                {fields.length > 1 && (
                  <CloseButton
                    onClick={ev => {
                      ev.stopPropagation();
                      removeSearchTerm(index);
                    }}
                  />
                )}
              </div>
              {canSetOngoingMonitoring && (
                <div className={`${classBase}__ongoing`}>
                  <h4>Ongoing Monitoring</h4>
                  <Field
                    component={ToggleField}
                    name={`${name}.ongoing`}
                    editable
                    label="If feature is off, system will perform a one-time search. If feature is on, ongoing monitoring will update daily for one year."
                  />
                </div>
              )}
            </div>
          ))
        }
      </FieldArray>
    </Section>
  );
};

export default SearchTerms;
