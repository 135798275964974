import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import api from '../../../../../api';
import FormModal from '../../../../../components/Modal/FormModal';
import Spinner from '../../../../../components/Spinner';
import DateManager from '../../../../../utilities/services/DateManager';
import EntitiesFilterForm from './components/EntitiesFilterForm';

const EntitiesFilterModal = ({ hideModal, onFilterSave, initialFilters }) => {
  const [isLoading, setIsLoading] = useState(true);
  const applyFilters = async values => {
    const arraysValidation = [
      values?.assignedTo,
      values?.countries,
      values?.riskLevels,
      values?.statuses,
      values?.tags,
    ].some(arr => arr?.length > 0);

    const {
      dateFrom,
      dateUntil,
      naturalPersonFlag,
      organizationFlag,
      tagsFlag,
      nextKYCReviewUntil,
      nextKYCReviewFrom,
    } = values;
    const otherValidation =
      dateFrom ||
      dateUntil ||
      naturalPersonFlag ||
      organizationFlag ||
      tagsFlag ||
      nextKYCReviewUntil ||
      nextKYCReviewFrom;
    if (!arraysValidation && !otherValidation) {
      return {
        [FORM_ERROR]: 'No Filters Selected ',
      };
    }
    if (
      DateManager.compareDates(dateFrom, dateUntil) ||
      DateManager.compareDates(nextKYCReviewFrom, nextKYCReviewUntil)
    ) {
      return {
        [FORM_ERROR]: 'Date From Must Be Before Date Until ',
      };
    }

    onFilterSave(values);
    hideModal();
  };
  const [tagsOptions, setTagsOptions] = useState([]);
  useEffect(() => {
    const fetchTags = () => {
      setIsLoading(true);
      api.kyc.entityManagement
        .getAllEntityTags()
        .then(res => {
          const { data } = res;
          setTagsOptions(data.map(el => ({ id: el, value: el, label: el })));
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchTags();
  }, []);
  return (
    <FormModal
      onSubmit={applyFilters}
      title="Filter"
      initialValues={initialFilters}
      hideModal={hideModal}
      className="ickyc-filter-modal"
      renderAsPortal={false}
      showErrorsInConfirmation
    >
      {isLoading ? <Spinner /> : <EntitiesFilterForm tagsOptions={tagsOptions} />}
    </FormModal>
  );
};

EntitiesFilterModal.propTypes = {
  hideModal: PropTypes.func,
  onFilterSave: PropTypes.func,
  initialFilters: PropTypes.shape({}),
};
EntitiesFilterModal.defaultProps = {
  initialFilters: {},
  hideModal: () => {},
  onFilterSave: () => {},
};
export default EntitiesFilterModal;
