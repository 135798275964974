import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
    <g data-name="Group 1106">
      <g data-name="Ellipse 170" fill="#fff" stroke="#02ce94" strokeWidth="2">
        <circle cx="10" cy="10" r="8" stroke="none" />
        <circle cx="10" cy="10" r="8.5" fill="none" />
      </g>
      <g data-name="Group 1105">
        <g data-name="Group 1096" fill="none" stroke="#02ce94" strokeWidth="2">
          <path data-name="Line 55" d="M10 5.019v9.963" />
          <path data-name="Line 56" d="M15.0 10h-9.964" />
        </g>
      </g>
    </g>
  </svg>
);
