import { useEffect, useState } from 'react';
import useDebounce from './useDebounce';

export default function useDebouncedFilter(debounceTime = 1000, callback) {
  const [searchTerm, setSearchTerm] = useState(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, debounceTime);

  useEffect(() => {
    if (debouncedSearchTerm) {
      callback({ searchString: debouncedSearchTerm });
      return;
    }
    if (debouncedSearchTerm === '') {
      callback({ searchString: undefined });
    }
  }, [debouncedSearchTerm, callback]);

  return {
    inputProps: {
      value: searchTerm,
      onChange: ev => {
        setSearchTerm(ev.target.value);
      },
    },
  };
}
