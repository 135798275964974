import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import api from '../../../../../../../api';
import Accordion from '../../../../../../../components/Accordion';
import Select from '../../../../../../../components/Select';
import SelectContentTable from '../../../../../../../components/Select/components/SelectContentTable';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import TableHeader from '../../../../../../../components/Tables/Table/components/TableHeader';
import ToggleField from '../../../../../../../components/ToggleField';
import useTable from '../../../../../../../hooks/useTable';
import utilities from '../../../../../../../utilities';
import rcuEnums from '../../../../../../../utilities/enums/rcuEnums';
import RiskProfileSelectRow from '../../../../../components/SearchModal/components/SearchForm/RiskProfileSelectRow';
import './styles.scss';

const AddAMLCase = ({ entityID, screeningProfileId, namePrefix }) => {
  const baseClass = 'ickyc-add-aml-case';
  const [accordionOpen, setAccordionOpen] = useState(false);
  const { batch, change } = useForm();
  const { records, changeParams, params, pagination, isLoading } = useTable(
    api.administration.getRCUScreeningProfiles,
    {
      limit: 10,
      sortCriteria: 'name',
      sortOrder: 0,
      entityId: entityID,
    },
  );
  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  const { values } = useFormState();

  const amlCase = values[namePrefix] ? values[namePrefix].amlCase : values.amlCase;
  const { isEnabled, createCaseAfterAllRequestsAreAccepted, screeningProfileId: screeningProfileIdInitialValue } =
    amlCase || {};

  const mappedRiskProfiles = useMemo(
    () =>
      records.map(profile => ({
        ...profile,
        id: profile.id,
        value: profile.id,
        label: profile.name,
        disabled: !profile.isAllowed,
      })),
    [records],
  );

  const isDefaultRSPAvailable = mappedRiskProfiles.find(mrp => mrp.id === screeningProfileId)?.isAllowed;

  const profileSelectError = useMemo(() => {
    if (isDefaultRSPAvailable && !screeningProfileIdInitialValue) {
      batch(() => {
        change(`${namePrefixWithDot}amlCase.screeningProfileId`, screeningProfileId);
      }, []);
    }

    if (!mappedRiskProfiles?.length || mappedRiskProfiles.every(profile => !profile.isAllowed)) {
      return 'There is no Risk Profile that covers this Jurisdiction. You can add it in Compliance Settings';
    }
    if (!isDefaultRSPAvailable) {
      return 'Please select Risk Screening Profile';
    }
  }, [isDefaultRSPAvailable, mappedRiskProfiles, screeningProfileIdInitialValue]);

  useEffect(() => {
    batch(() => {
      change(`${namePrefixWithDot}amlCase.createCaseAfterAllRequestsAreAccepted`, isEnabled);
    });
  }, [amlCase?.isEnabled]);

  useEffect(() => {
    if (!createCaseAfterAllRequestsAreAccepted) change(`${namePrefixWithDot}amlCase.isEnabled`, false);
  }, [createCaseAfterAllRequestsAreAccepted]);

  return (
    <Accordion
      accordionindex={rcuEnums.ACCORDION_INDEXES.ADD_AML_CASE}
      accordionOpen={accordionOpen}
      title={
        <>
          <h3>Add AML Case</h3>
          <span className="ickyc-spacer" />
          <div onClick={utilities.handleOnClickEvent}>
            <Field
              preview
              name={`${namePrefixWithDot}amlCase.isEnabled`}
              component={ToggleField}
              showCustomText
              label="Enable"
              onChange={value => setAccordionOpen(value)}
            />
          </div>
        </>
      }
    >
      <div className={baseClass}>
        <Field
          name={`${namePrefixWithDot}amlCase.screeningProfileId`}
          render={({ input }) => (
            <div className={`${baseClass}__risk-screening`}>
              <span>Risk Screening Profile</span>
              <Select
                Trigger={<SelectTrigger />}
                options={mappedRiskProfiles}
                withTableContent
                Content={
                  <SelectContentTable
                    className="ickyc-aml-case-select-content"
                    tableProps={{
                      tableRow: RiskProfileSelectRow,
                      headerRow: TableHeader,
                      handleParamsChange: changeParams,
                      pagination,
                      headerData: {
                        sortCriteria: params.sortCriteria,
                        sortOrder: params.sortOrder,
                        onClick: changeParams,
                        items: [
                          {
                            label: 'Name',
                            type: 'name',
                            clickable: true,
                          },
                        ],
                      },
                      updating: isLoading,
                      withLoadMore: true,
                      withPagination: true,
                    }}
                  />
                }
                onChange={input.onChange}
                value={input.value}
                renderAsPortal
                disabled={!isEnabled}
                hasError={profileSelectError && !input.value && isEnabled}
                errorMsg={profileSelectError}
              />
            </div>
          )}
        />
        <Field
          name={`${namePrefixWithDot}amlCase.createCaseAfterAllRequestsAreAccepted`}
          component={ToggleField}
          label="Create Case After All KYC Requests are Accepted"
          disabled={!isEnabled}
        />
      </div>
    </Accordion>
  );
};
AddAMLCase.propTypes = {
  entityID: PropTypes.string,
  screeningProfileId: PropTypes.number,
};
AddAMLCase.defaultProps = {
  entityID: null,
  screeningProfileId: null,
};
export default AddAMLCase;
