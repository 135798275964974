import React from 'react';
import Card from '../../../../components/Card';
import CardItem from '../../../../components/Card/components/CardItem';
import { cardItems } from './utils/data';

import './styles.scss';

const KnowledgeBase = () => {
  return (
    <Card text="Knowledge Base" className="ickyc-knowlegde-base">
      {cardItems.map(({ header, subheader, redirectToSpecificUrl }) => (
        <CardItem key={header} header={header} subheader={subheader} onClick={() => redirectToSpecificUrl()} />
      ))}
    </Card>
  );
};

export default KnowledgeBase;
