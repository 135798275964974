import React from 'react';

export default () => (
  <svg
    id="Capa_1"
    enable-background="new 0 0 512 512"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    fill="currentColor"
  >
    <g>
      <path d="m256 0c-140.61 0-256 115.39-256 256s115.39 256 256 256 256-115.39 256-256-115.39-256-256-256zm0 452c-107.695 0-196-88.305-196-196 0-43.013 14.095-83.643 37.687-115.888l274.199 274.201c-32.245 23.59-72.873 37.687-115.886 37.687zm158.313-80.114-274.199-274.199c32.245-23.59 72.873-37.687 115.886-37.687 107.695 0 196 88.305 196 196 0 43.013-14.097 83.641-37.687 115.886z" />
    </g>
  </svg>
);
