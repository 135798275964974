import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import toastr from 'toastr';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import DebounceInput from '../../../components/DebounceInput';
import TabHeader from '../../../components/Headers/ModalTabHeader';
import BareHeading from '../../../components/Headings/BareHeading';
import useModalHandler from '../../../hooks/useModalHandler';
import utilities from '../../../utilities';
import routesEnum from '../../../utilities/enums/routesEnum';
import { PortalManagementContext } from '../../../utilities/services/contexts';
import NaturalPersonPortalModal from './components/NaturalPersonPortalModal';
import PortalConfigsTable from './components/PortalConfigsTable';
import './styles.scss';

const tabOptions = [
  {
    label: 'Natural Person Portals ',
    key: 'natural',
  },
  {
    label: 'Legal Entity Portals ',
    key: 'legal',
  },
];
const PortalManagement = () => {
  const [selectedConfiguration, setSelectedConfiguration] = useState();
  const history = useHistory();
  const { state } = useLocation();

  const {
    getAllNaturalPortalsRoute,
    getAllLegalEntityPortalsRoute,
    createPortalsConfiguration,
    withTitle,
    clientId,
    domain,
  } = useContext(PortalManagementContext);

  const { isOpen, open: openModal, close: closeModal } = useModalHandler();

  const classes = classNames(['ickyc-portal-management']);

  const naturalPersonPortalsRef = useRef(null);
  const legalEntityPortalsRef = useRef(null);

  const [activeTab, setActiveTab] = useState(0);
  const [screens] = useState([
    <PortalConfigsTable getAllPortalsRoute={getAllNaturalPortalsRoute} ref={naturalPersonPortalsRef} />,
    <PortalConfigsTable getAllPortalsRoute={getAllLegalEntityPortalsRoute} ref={legalEntityPortalsRef} />,
  ]);

  const openPortalModalCreationMode = useCallback(() => {
    setSelectedConfiguration({ type: activeTab ? 'LegalEntity' : 'NaturalPerson', domain });
    openModal();
  }, [openModal, activeTab]);

  const onCreatePortalSubmit = async (vals, isQuickSetup) => {
    const { name, enabled, domainPathname, tags = [], type, isDefault } = vals;

    const aditionalData = {
      name,
      enabled,
      quickSetup: true, // alsways true
      type,
      tags,
      domainPathname,
      isDefault,
    };

    const paramsData = clientId
      ? {
          clientId,
          data: aditionalData,
        }
      : {
          data: aditionalData,
        };
    return createPortalsConfiguration(...Object.entries(paramsData).map(el => el[1]))
      .then(({ data: responseData }) => {
        if (responseData) {
          const ref = type !== 'LegalEntity' ? naturalPersonPortalsRef : legalEntityPortalsRef;
          if (responseData.isDefault) {
            const defaultPortal = ref.current.records.find(portal => portal.isDefault);
            defaultPortal && ref.current.updateRowData('id', defaultPortal.id, { isDefault: false });
          }
          ref.current.addRow(responseData);

          if (isQuickSetup) {
            closeModal();
          } else {
            const { id } = responseData;

            if (clientId) {
              return history.push(
                utilities.routeParams(
                  type === 'LegalEntity'
                    ? routesEnum.internal.LEGAL_ENTITY_PORTAL_PROFILE
                    : routesEnum.internal.NATURAL_PERSON_PORTAL_PROFILE,
                  {
                    id,
                    clientId,
                  },
                ),
              );
            }
            return history.push(
              utilities.routeParams(
                type === 'LegalEntity'
                  ? routesEnum.administration.PORTAL_DETAILS_LEGAL
                  : routesEnum.administration.PORTAL_DETAILS_NATURAL,
                {
                  id,
                },
              ),
            );
          }
        }
        toastr.success('Successfully created portal');
      })
      .catch(err => {
        console.log({ err });
        toastr.error('Failed to create portal');

        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || errData.errors,
            };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  const optionClick = useCallback(value => {
    setActiveTab(value);
  }, []);

  const handleSearchChange = useCallback(
    searchVal => {
      activeTab
        ? legalEntityPortalsRef.current.applySearchTerm(searchVal)
        : naturalPersonPortalsRef.current.applySearchTerm(searchVal);
    },
    [activeTab],
  );

  useEffect(() => {
    state?.openFromDashboard && openPortalModalCreationMode();
  }, [state?.openFromDashboard, openPortalModalCreationMode]);

  return (
    <div className={classes}>
      <BareHeading title={withTitle ? 'Portals' : ''}>
        <DebounceInput placeholder="Search Portals" initialValue={undefined} onChange={handleSearchChange} />
        <PrimaryButton onClick={openPortalModalCreationMode}>+ Add Portal</PrimaryButton>
      </BareHeading>
      <div>
        <TabHeader onClick={optionClick} options={tabOptions} />
        {screens[activeTab]}
      </div>

      {isOpen && (
        <NaturalPersonPortalModal
          hideModal={closeModal}
          initialValues={selectedConfiguration}
          onSubmit={onCreatePortalSubmit}
        />
      )}
    </div>
  );
};
export default PortalManagement;
