import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import setClassSuffix from 'utilities/services/ClassManager';
import './styles.scss';

const PlatformUsageRow = ({ rowInfo: { item, creditsUsed, limit }, className, index }) => {
  const setSufix = setClassSuffix(`${className}__column`);
  const classes = classNames('kyc-platform-usage-table-row', {
    'kyc-platform-usage-table-row--colored': index % 2 === 0,
  });
  return (
    <tr className={classes}>
      <td className={setSufix('__item')}>
        <span>{item}</span>
      </td>
      <td className={setSufix('__creditsUsed')}>
        <span>
          {creditsUsed}/{limit}
        </span>
      </td>
    </tr>
  );
};
PlatformUsageRow.propTypes = {
  rowInfo: PropTypes.shape({
    adminUsers: PropTypes.string,
    dateCreated: PropTypes.string,
    saas: PropTypes.number,
    kycPlan: PropTypes.number,
    consumption: PropTypes.number,
    overage: PropTypes.number,
    overageRate: PropTypes.number,
    balance: PropTypes.number,
    clientLicenceForStatistic: PropTypes.shape({
      startDate: PropTypes.string,
      invoiceDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }),
  className: PropTypes.string,
};

PlatformUsageRow.defaultProps = {
  className: '',
  rowInfo: {
    adminUsers: '',
    dateCreated: '',
    saas: 0,
    kycPlan: 0,
    consumption: 0,
    overage: 0,
    overageRate: 0,
    balance: 0,
    clientLicenceForStatistic: {},
  },
};
export default PlatformUsageRow;
