import { combineReducers } from 'redux';
import authReducer from './auth.reducer';
import globalReducer from './global.reducer';

const appReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
});

const rootReducer = function(state, action) {
  return appReducer(state, action);
};

export default rootReducer;
