import axiosInstance from '../axiosInstance';

const BASE_ROUTE = '/notifications';

const ENDPOINTS = {
  notifications: BASE_ROUTE,
  updateNotificationStatus: id => `${BASE_ROUTE}/${id}/status`,
  markAllAsRead: () => `${BASE_ROUTE}/mark-all-as-read`,
};

const fetchNotifications = params => {
  return axiosInstance.get(ENDPOINTS.notifications, { params });
};

const updateNotificationStatus = ({ id, notificationStatus }) => {
  return axiosInstance.put(ENDPOINTS.updateNotificationStatus(id), { notificationStatus });
};

const markAllAsRead = () => {
  return axiosInstance.put(ENDPOINTS.markAllAsRead());
};

export default {
  fetchNotifications,
  updateNotificationStatus,
  markAllAsRead,
};
