import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/client-account/scoring';

const ENDPOINTS = {
  getScoringRules: () => `${BASE_ROUTE}/rules`,
  getRulesOptions: () => `${BASE_ROUTE}/rules/sections`,
  updateRules: () => `${BASE_ROUTE}/rules`,
  removeRule: ruleId => `${BASE_ROUTE}/rules/${ruleId}`,
  getScoringRiskLevels: () => `${BASE_ROUTE}/risk-levels`,
  updateScoringRiskLevels: () => `${BASE_ROUTE}/risk-levels`,
};

const getScoringRules = params => axiosInstance.get(ENDPOINTS.getScoringRules(), { params });
const getRulesOptions = () => axiosInstance.get(ENDPOINTS.getRulesOptions());
const updateRules = body => axiosInstance.put(ENDPOINTS.updateRules(), body);
const removeRule = ruleId => axiosInstance.delete(ENDPOINTS.removeRule(ruleId));

const getScoringRiskLevels = () => axiosInstance.get(ENDPOINTS.getScoringRiskLevels());
const updateScoringRiskLevels = body => axiosInstance.put(ENDPOINTS.updateScoringRiskLevels(), body);

export default {
  getScoringRules,
  getRulesOptions,
  updateRules,
  removeRule,
  getScoringRiskLevels,
  updateScoringRiskLevels,
};
