import PropTypes from 'prop-types';
import React from 'react';
import List from '../../../../components/List';
import Section from '../../../../components/Section';
import FieldCountriesList from '../../../../components/Tables/FieldCountriesList';
import AutomaticOnDemandControlButtons from '../AutomaticOnDemandControlButtons';
import AutomaticOnDemandRadioButtons from '../AutomaticOnDemandRadioButtons';

const AutomaticOnDemandSettings = ({ disabled, divider }) => {
  return (
    <Section divider={divider} className="document-verification__automatic-ondemand-verification">
      <h2>Automatic or On-Demand Verification by Jurisdiction</h2>
      <p>
        Choose whether you want your users' images to be
        <strong> automatically submitted for identity verification</strong>, or whether you want to{' '}
        <strong> manually initiate each image to be submitted for identity verification.</strong>
      </p>
      <List
        options={[
          {
            title: 'Automatic',
            text: 'When your users upload an image, they will be automatically submitted for identity verification',
            icon: 'gear-green',
          },
          {
            title: 'On-Demand',
            text:
              'When your users upload an image, they will not be automatically submitted for identity ' +
              "verification. You can manually initiate a submission on that user's KYC profie page",
            icon: 'gear-orange',
          },
        ]}
      />
      <FieldCountriesList
        modulePreffix="verificationRestrictions"
        TableControlsComponent={AutomaticOnDemandControlButtons}
        tableRow={AutomaticOnDemandRadioButtons}
        withInfiniteScroll
        disabled={disabled}
      />
    </Section>
  );
};
AutomaticOnDemandSettings.propTypes = {
  disabled: PropTypes.bool,
  divider: PropTypes.bool,
};
AutomaticOnDemandSettings.defaultProps = {
  disabled: false,
  divider: false,
};
export default AutomaticOnDemandSettings;
