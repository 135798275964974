import * as actions from '../actions/auth.action';

const initialState = {
  // Access token
  token: '',
  personalInfo: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case actions.PERSONAL_INFO_SET: {
      return {
        ...state,
        personalInfo: action.payload,
      };
    }
    case actions.PERSONAL_INFO_UPDATE: {
      return {
        ...state,
        personalInfo: {
          ...state.personalInfo,
          ...action.payload,
        },
      };
    }

    case actions.USER_PERMISSIONS_SET: {
      return {
        ...state,
        personalInfo: { ...state.personalInfo, permissions: action.payload },
      };
    }
    case actions.USER_TYPE: {
      return {
        ...state,
        personalInfo: { ...state.personalInfo, type: action.payload },
      };
    }
    default: {
      return state;
    }
  }
}
