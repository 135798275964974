import api from 'api';
import Spinner from 'components/Spinner';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import typeToProperFieldComponent from './utils';

const PackageItemsFields = ({ packageId, outerManagedItems }) => {
  const [packageItems, setPackageItems] = useState(outerManagedItems);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPackageItems = async () => {
      try {
        setIsLoading(true);
        const {
          data: { data },
        } = await api.administration.supportingDocumentPackages.getSupportingDocumentPackagesItems({
          packageId,
        });

        setPackageItems(data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    !outerManagedItems && fetchPackageItems();
  }, [outerManagedItems, packageId]);

  return (
    <div className="ickyc-package-items-fields">
      {isLoading ? (
        <Spinner />
      ) : (
        packageItems?.map((item, index) => {
          return <div key={item?.id}>{typeToProperFieldComponent(item, index)[item.type]}</div>;
        })
      )}
    </div>
  );
};
export default PackageItemsFields;
