import PropTypes from 'prop-types';
import React from 'react';
import FieldCountriesList from '../../../../components/Tables/FieldCountriesList';
import AutoAssignmentControls from '../AutoAssignmentControls';
import AutoAssignSelect from '../AutoAssignSelect';

const AutoAssignmentSettings = ({ tableName, disabled, propertyName }) => {
  return (
    <FieldCountriesList
      modulePreffix={tableName}
      TableControlsComponent={AutoAssignmentControls}
      tableRow={AutoAssignSelect}
      withInfiniteScroll
      disabled={disabled}
      propertyName={propertyName}
    />
  );
};
AutoAssignmentSettings.propTypes = {
  disabled: PropTypes.bool,
  tableName: PropTypes.string.isRequired,
  propertyName: PropTypes.string,
};
AutoAssignmentSettings.defaultProps = {
  disabled: false,
  propertyName: '',
};
export default AutoAssignmentSettings;
