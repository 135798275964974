import PersonalSettings from '../../../../../scenes/PersonalSettings';
import redirect from '../../../../modules/redirect';

export default [
  {
    path: '/personal-settings',
    component: PersonalSettings,
  },
  {
    path: '*',
    component: redirect('/account-settings/personal-settings', true),
  },
];
