import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../components/InputField';
import Section from '../../../../../../../components/Section';
import TextareaField from '../../../../../../../components/TextareaField';
import './styles.scss';

const Technical = () => {
  return (
    <div className="ickyc-portal-technical">
      <Section title="Redirect URLs" divider clas>
        <div>
          <b>Portal Success Exit Point</b>
          <p>The URL that your subjects will be sent to upon successful portal completion</p>
          <Field component={InputField} name="settings.successRedirectUrl" />
        </div>
        <div>
          <b>Portal Failure Exit Point</b>
          <p>The URL that your subjects will be sent to upon failure to complete portal.</p>
          <Field component={InputField} name="settings.failureRedirectUrl" />
        </div>
      </Section>
      <Section title="Callback URL" divider>
        <p>Advanced setup only. Setup and test a Callback URL for this portal. View documentation to learn more.</p>
        <Field component={TextareaField} name="settings.callbackUrl" />
      </Section>
      <Section title="iComply API">
        <p>
          View the &nbsp;
          <a
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            href="https://docs.icomplyis.com/api-docs/"
            rel="noreferrer"
          >
            API documentation
          </a>
        </p>
      </Section>
    </div>
  );
};

export default Technical;
