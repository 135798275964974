import InputField from 'components/InputField';
import React from 'react';
import { Field } from 'react-final-form';
import validators from 'utilities/services/validators';

const Text = () => {
  return (
    <div className="ickyc-custom-document-options">
      <span className="ickyc-custom-document-options--label">Question ID</span>
      <span>
        <Field name="id" component={InputField} preview></Field>
      </span>
      <span className="ickyc-custom-document-options--label">Character Limit</span>
      <span>
        <Field
          name="parameters.characterLimit"
          type="number"
          component={InputField}
          validate={validators.composeValidators(
            validators.required(),
            validators.minAllowedValue('Please note that character liimit must be a positive number', 1),
          )}
        />
      </span>
    </div>
  );
};
export default Text;
