/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import HelpIcon from '@material-ui/icons/Help';
import classNames from 'classnames';
import FieldDescription from 'components/FieldDescription';
import deepEqual from 'deep-equal';
import useMatchMedia from 'hooks/useMatchMedia';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useFilter from '../../hooks/useFilter';
import usePopup from '../../hooks/usePopup';
import utilities from '../../utilities';
import authEnums from '../../utilities/enums/authEnums';
import Portal from '../Portal';
import Tooltip from '../Tooltip';
import useResizePositioning from './hooks/useResizePositioning';
import './styles.scss';

export const SelectContext = createContext();
const Select = ({
  value,
  onChange,
  onClick,
  onBlur,
  label,
  loading,
  hint,
  options: initialOptions = [],
  multipleSelection,
  useFullValue,
  disabled,
  preview,
  hasError,
  errorMsg,
  renderAsPortal,
  withCustomOptions,
  Trigger,
  Content,
  hintMessage,
  className,
  shouldAutoFocus = false,
  withTableContent,
  accentText,
  required,
  labelPosition = 'left',
  description,
}) => {
  const baseClass = 'ickyc-select';
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);
  const [hoverIndex, setHoverIndex] = useState(0);

  const [options, setOptions] = useState(initialOptions);
  const filterProps = useFilter(options);

  const isBelowTablet = useMatchMedia('isBelowTablet');

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  const {
    suggestions,
    inputProps: { value: searchTerm },
  } = filterProps;

  const [isOpen, setIsOpen] = usePopup({
    defaultOpen: false,
    accessible: true,
  });

  const toggleView = useCallback(() => {
    setIsOpen(prev => !prev);
  }, [setIsOpen]);

  const withTriggerInput = useMemo(() => {
    return triggerRef.current?.querySelector('.icyc-select-input-trigger');
  }, [triggerRef]);

  useEffect(() => {
    if (suggestions.length) {
      setHoverIndex(0);
    }
  }, [suggestions]);

  const parsedValue = useMemo(() => {
    if (value === '' || value === undefined) {
      return multipleSelection ? [] : {};
    }
    if (!useFullValue) {
      if (multipleSelection) {
        return options.filter(opt => value.includes(opt.value));
      }
      return options.find(opt => opt.value === value || deepEqual(opt.value, value)) || {};
    }
    return value;
  }, [value, multipleSelection, options, useFullValue]);

  const normalizeValue = useCallback(
    newValue => {
      if (useFullValue) {
        onChange(newValue);
      } else if (multipleSelection) {
        onChange(newValue.map(val => val.value));
      } else {
        onChange(newValue.value);
      }
      if (!multipleSelection) setIsOpen(false);
    },
    [onChange, multipleSelection, useFullValue, setIsOpen],
  );

  const removeFromMultipleSelection = useCallback(
    (valueToRemove, event) => {
      if (event) {
        event.stopPropagation();
      }
      normalizeValue(parsedValue.filter(val => val.id !== valueToRemove.id));
    },
    [parsedValue, normalizeValue],
  );

  const handleChange = useCallback(
    valueToAdd => {
      if (multipleSelection) {
        if (parsedValue.some(val => val.id === valueToAdd.id)) {
          removeFromMultipleSelection(valueToAdd);
        } else {
          normalizeValue([...parsedValue, valueToAdd]);
        }
      } else {
        normalizeValue(valueToAdd);
      }
      if (!multipleSelection) setIsOpen(false);
      if (!withTriggerInput) {
        triggerRef.current.focus();
      }
    },
    [normalizeValue, multipleSelection, removeFromMultipleSelection, parsedValue, withTriggerInput, setIsOpen],
  );

  const classes = classNames({
    [baseClass]: true,
    [`${baseClass}--disabled`]: disabled,
    [`${baseClass}--error`]: hasError && !isOpen,
    [`${baseClass}--preview`]: preview,
    [`${baseClass}--open`]: isOpen,
    [`${baseClass}--loading`]: loading,
    [`${baseClass}--required`]: !preview && required,
    [`${baseClass}--red-colored-text`]: accentText,
    [`${baseClass}--top-labeled`]: labelPosition === 'top' || isBelowTablet,
    [className]: className,
  });

  const supportKeyboardNavigation = event => {
    if (disabled) return;
    // press down -> go next

    // Escape
    if (event.target.tagName === 'INPUT' && multipleSelection && event.keyCode === 8) {
      if (!filterProps.inputProps.value && parsedValue.length) {
        // handleChange(parsedValue[parsedValue.length - 1]);
      }
    }
    if (event.keyCode === 27) {
      setIsOpen(false);
      event.preventDefault();
      event.stopPropagation();
    }

    // tab
    if (event.keyCode === 9) {
      if (isOpen) {
        setIsOpen(false);
        // event.preventDefault();
        if (!withTriggerInput) {
          triggerRef.current.focus();
        }
      }
    }
    if (event.keyCode === 40 && !isOpen) {
      setIsOpen(true);
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    let list = [];
    if (renderAsPortal) {
      const portal = document.body.querySelector('.ickyc-portal');
      if (!portal) {
        return;
      }
      list = portal.querySelector(`${withTableContent ? 'tbody' : 'ul'}`);
    } else {
      list = dropdownRef.current.querySelector(withTableContent ? 'tbody' : 'ul');
    }
    const listItems = list?.childNodes || [];
    if (event.keyCode === 40) {
      let newIndex = hoverIndex + 1;
      event.stopPropagation();
      event.preventDefault();
      const elementToFocus = listItems.item(newIndex);
      if (!elementToFocus) {
        return;
      }
      if (elementToFocus.classList?.value?.includes('disabled')) {
        let nextNonDisabledItem = {};
        Array.from(listItems).find((item, index) => {
          if (!item.classList.value.includes('disabled') && newIndex < index) {
            nextNonDisabledItem = {
              element: item,
              index,
            };
            return true;
          }
          return false;
        });
        if (nextNonDisabledItem.element) {
          newIndex = nextNonDisabledItem.index;
        }
      }
      const parentOffset = list.offsetTop;
      const itemOffset = elementToFocus.offsetTop;
      const parentHeight = list.offsetHeight;
      const itemHeight = elementToFocus.offsetHeight;
      if (itemOffset - parentOffset > parentHeight - itemHeight) {
        list.scrollTop = itemHeight * newIndex;
      }
      setHoverIndex(newIndex);
    }
    // press up -> go previous
    if (event.keyCode === 38) {
      let newIndex = hoverIndex - 1;
      const elementToFocus = listItems.item(newIndex);
      event.stopPropagation();
      event.preventDefault();
      if (!elementToFocus || newIndex === -1) {
        return;
      }
      if (elementToFocus.classList?.value?.includes('disabled')) {
        let nextNonDisabledItem = {};
        Array.from(listItems)
          .reverse()
          .find((item, index) => {
            if (!item.classList.value.includes('disabled') && newIndex < index) {
              nextNonDisabledItem = {
                element: item,
                index: listItems.length - index - 1,
              };
              return true;
            }
            return false;
          });
        if (nextNonDisabledItem.element) {
          newIndex = nextNonDisabledItem.index;
        }
      }
      const parentOffset = list.offsetTop;
      const itemOffset = elementToFocus.offsetTop;
      if (itemOffset - parentOffset > list.offsetHeight) {
        list.scrollTop = itemOffset - parentOffset;
      } else {
        list.scrollTop = 0;
      }
      event.preventDefault(); // prevent page scrolling
      setHoverIndex(newIndex);
    }
    // press Enter or space -> select the option
    if (event.keyCode === 13) {
      if (!isOpen) {
        return;
      }
      // FIX
      const elementToClick = listItems.item(hoverIndex);
      event.preventDefault();
      event.stopPropagation();
      if (elementToClick?.click) {
        elementToClick.click();
      }
    }
  };
  useLayoutEffect(() => {
    const element = dropdownRef?.current;
    const handleClickOutside = event => {
      if (isOpen && !renderAsPortal && !element.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, isOpen, renderAsPortal, setIsOpen]);

  useEffect(() => {
    const resizePortal = () => {
      const { width, x, y, height } = triggerRef.current.getBoundingClientRect();
      const content = document.body.querySelector('.ickyc-portal > div');
      content.style.width = `${width}px`;
      content.style.top = `${y + height}px`;
      content.style.left = `${x}px`;
    };
    if (renderAsPortal && isOpen) {
      resizePortal();
      window.addEventListener('resize', resizePortal);
    }
    return () => {
      window.removeEventListener('resize', resizePortal);
    };
  }, [renderAsPortal, triggerRef, isOpen]);

  useResizePositioning({
    triggerRef,
    dropdownRef,
    renderAsPortal,
  });

  useLayoutEffect(() => {
    const element = dropdownRef?.current;
    const handleClickOutside = event => {
      if (isOpen && !renderAsPortal && !element.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (element) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, isOpen, renderAsPortal, setIsOpen]);

  useResizePositioning({
    dropdownRef,
    renderAsPortal,
  });

  const triggerClasses = 'ickyc-select__trigger';

  const addCustomOptions = useCallback(newOptions => {
    setOptions(prev => [newOptions, ...prev]);
  }, []);

  useEffect(() => {
    if (withCustomOptions && value !== '') {
      const additionalOptions = Array.isArray(value)
        ? value
            .filter(v => !utilities.transformFromArrayOfObjectsToArrayOfStrings(authEnums.TAGS).includes(v))
            .map(v => ({ id: v, label: v, value: v }))
        : [{ id: value, label: value, value }];
      setOptions(prev => [...prev, ...additionalOptions]);
    }
  }, [withCustomOptions]);

  useEffect(() => {
    if (
      multipleSelection &&
      !isOpen &&
      parsedValue &&
      parsedValue?.length >= 1 &&
      searchTerm !== '' &&
      withCustomOptions
    ) {
      setIsOpen(true);
    }
  }, [suggestions, parsedValue]);

  useEffect(() => {
    return () => {
      if (isOpen && onBlur) onBlur();
    };
  }, [isOpen, onBlur]);

  const openContent = useCallback(() => setIsOpen(true), []);
  const closeContent = useCallback(() => setIsOpen(false), []);

  return (
    <div className={classes} onClick={onClick}>
      {label && (
        <label>
          {label}
          {hint && <Tooltip trigger={<HelpIcon />} content={<span>{hint}</span>} />}
        </label>
      )}

      <SelectContext.Provider
        value={{
          value: parsedValue,
          multipleSelection,
          options: suggestions,
          preview,
          filterProps,
          onChange: handleChange,
          hasError,
          toggleView,
          isOpen,
          hoverIndex,
          loading,
          openContent,
          closeContent,
          supportKeyboardNavigation,
          withCustomOptions,
          addCustomOptions,
          shouldAutoFocus,
          removeFromMultipleSelection,
        }}
      >
        <div ref={dropdownRef} onKeyDown={supportKeyboardNavigation}>
          <div className={triggerClasses} ref={triggerRef}>
            {Trigger}
            {!withTriggerInput && (
              <label
                tabIndex="0"
                onFocus={e => {
                  e.stopPropagation();
                  if (!isOpen) {
                    setIsOpen(true);
                  }
                }}
              >
                <input hidden />
              </label>
            )}
          </div>

          {renderAsPortal ? (
            <Portal isOpen={isOpen} setIsOpen={setIsOpen}>
              {Content}
            </Portal>
          ) : (
            isOpen && Content
          )}
          {hasError && errorMsg && !isOpen && <span className="ickyc-error-message">{errorMsg}</span>}

          {hintMessage && <div className="ickyc-select__content--hint-message">{hintMessage}</div>}
          {description && <FieldDescription text={description} />}
        </div>
      </SelectContext.Provider>
    </div>
  );
};

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  loading: PropTypes.bool,
  hint: PropTypes.string,
  useFullValue: PropTypes.bool,
  hasError: PropTypes.bool,
  withCustomOptions: PropTypes.bool,
  hintMessage: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  shouldAutoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  renderAsPortal: PropTypes.bool,
  preview: PropTypes.bool,
  Trigger: PropTypes.any.isRequired,
  Content: PropTypes.any.isRequired,
  multipleSelection: PropTypes.bool,
  withTableContent: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any),
  errorMsg: PropTypes.string,
  required: PropTypes.bool,
  accentText: PropTypes.bool,
};
Select.defaultProps = {
  onBlur: undefined,
  shouldAutoFocus: false,
  withTableContent: false,
  useFullValue: false,
  withCustomOptions: false,
  multipleSelection: false,
  hasError: false,
  loading: false,
  hint: undefined,
  hintMessage: undefined,
  className: undefined,
  preview: false,
  label: '',
  disabled: false,
  renderAsPortal: false,
  errorMsg: undefined,
  required: false,
  accentText: false,
};
export default Select;
