import BiometricAuthenticationPage from '../../../../../../../../../../scenes/Administration/BiometricAuthenticationPage';
import DocumentVerificationPage from '../../../../../../../../../../scenes/Administration/DocumentVerificationPage';
import IdentityVerificationPage from '../../../../../../../../../../scenes/Administration/IdentityVerificationPage';
import RestrictionsPage from '../../../../../../../../../../scenes/Administration/RestrictionsPage';
import redirect from '../../../../../../../../../modules/redirect';

export default [
  {
    path: '/restrictions',
    component: RestrictionsPage,
  },
  {
    path: '/identity-verification',
    component: IdentityVerificationPage,
  },
  {
    path: '/document-verification',
    component: DocumentVerificationPage,
  },
  {
    path: '/biometric-authentication',
    component: BiometricAuthenticationPage,
  },
  {
    path: '*',
    component: redirect('/administration/compliance-settings/quick-setup-subrouter/restrictions', true),
  },
];
