import HelpIcon from '@material-ui/icons/Help';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

import './styles.scss';

const InfoTooltip = ({ tooltip }) => {
  return (
    <div className="ickyc-info-tooltip">
      <Tooltip content={<span>{tooltip}</span>} trigger={<HelpIcon className="ickyc-info-tooltip__icon" />} />
    </div>
  );
};

InfoTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
};
export default InfoTooltip;
