import { Box } from '@material-ui/core';
import classNames from 'classnames';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import toastr from 'toastr';
import enums from 'utilities/enums';
import api from '../../../../../../../../api';
import PrimaryButton from '../../../../../../../../components/Buttons/PrimaryButton';
import Loader from '../../../../../../../../components/Loader';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';
import { EntityContext, HistoryRecordContext } from '../../../../../../../../utilities/services/contexts';
import errorMessage from '../ErrorMessage';
import ThirdPartyHeader from '../ThirdPartyHeader';
import ThirdPartyReportMessages from '../ThirdPartyReportMessages';
import './styles.scss';

const ThirdPartyReportDocument = ({ data, onDataFetch }) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { entityId } = useContext(EntityContext);
  const { issues } = useContext(HistoryRecordContext);

  const { eventHistoryId, thirdPartySuggestion } = data;
  const { thirdPartyReport, thirdPartyInfo } = thirdPartySuggestion;
  const { issuesCount } = thirdPartyReport;

  const classBase = 'ickyc-third-party-report-document';
  const setSuffix = setClassSuffix(classBase);
  const classes = classNames(classBase, {
    [setSuffix('__issues')]: issuesCount,
  });

  const handleSave = async () => {
    setIsLoading(true);
    const dataToSubmit = { operation: 'report' };

    return api.kyc.entityManagement.legalEntity
      .updateSupportingDocument(entityId, eventHistoryId, dataToSubmit)
      .then(response => {
        onDataFetch({
          ...data,
          eventHistoryId,
          issues: response?.data?.issues,
          thirdPartySuggestion: {
            ...thirdPartySuggestion,
            thirdPartyReport: { ...thirdPartyReport, issuesCount: 0 },
          },
        });
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, {
          eventHistoryId,
          data: {
            issues: issues - 1,
          },
        });
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.DOCUMENTS,
          issuesChange: -1,
        });
        toastr.success(`Report has been resolved.`);
        setError();
      })
      .catch(err => {
        return setError(errorMessage(err));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={classes}>
      <ThirdPartyHeader className={classBase} info={thirdPartyInfo} issuesCount={issuesCount} actionType="Reported" />
      <ThirdPartyReportMessages className={classBase} info={thirdPartyReport} />
      {error && <Box className={setSuffix('__submissionError')}>{error}</Box>}
      {issuesCount ? (
        <div className={setSuffix('__buttons')}>
          {isLoading && <Loader />}
          <PrimaryButton type="submit" disabled={isLoading} onClick={() => handleSave()}>
            Resolve
          </PrimaryButton>
        </div>
      ) : (
        <div className={setSuffix('__status-message')}>
          <span className={setSuffix('__status-message--accepted')}>Report resolved</span>
        </div>
      )}
    </div>
  );
};

ThirdPartyReportDocument.propTypes = {
  data: PropTypes.object,
  onDataFetch: PropTypes.func,
};
ThirdPartyReportDocument.defaultProps = {
  data: {},
  onDataFetch: () => {},
};

export default ThirdPartyReportDocument;
