import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import api from '../../../api';
import DebounceInput from '../../../components/DebounceInput';
import BareHeading from '../../../components/Headings/BareHeading';
import ButtonHeading from '../../../components/Headings/ButtonHeading';
import Table from '../../../components/Tables/Table';
import TableHeader from '../../../components/Tables/Table/components/TableHeader';
import useGroupPermissions from '../../../hooks/useGroupPermissions';
import useModalHandler from '../../../hooks/useModalHandler';
import useTable from '../../../hooks/useTable';
import authEnums from '../../../utilities/enums/authEnums';
import TableHeaders from '../../../utilities/services/TableHeaders';
import FilterToggleButton from '../../Kyc/components/FilterToggleButton';
import SearchControls from '../../Kyc/components/SearchControls';
import BusinessFilterModal from './components/BusinessFilterModal';
import BusinessManagerTableRow from './components/BusinessManagerTableRow';
import BusinessManagerModal from './components/BusinessMangerModal';

import './styles.scss';

const BusinessManager = ({ className }) => {
  const [initialBusinessInfo, setInitialBusinessInfo] = useState({});
  const [fetchingData, setFetchingData] = useState(false);
  const [filterOn, setFilterOn] = useState(false);
  const [filters, setFilters] = useState();

  const { isOpen: isBusinessModalOpen, close: closeBusinessModal, open: openBusinessModal } = useModalHandler();

  const { isLoading, records, changeParams, pagination, params, updateRowData, addRow, removeRow } = useTable(
    api.businessManager.getAllBusiness,
    { sortCriteria: 'dateCreated', limit: 10, sortOrder: 1, filters, filterOn },
  );

  const applySearchTerm = useCallback(
    searchString => {
      changeParams({ searchString });
    },
    [changeParams],
  );

  const fetchBusinessInfo = id => {
    setFetchingData(true);
    api.businessManager
      .getClientAccount(id)
      .then(({ data: responseData }) => {
        setInitialBusinessInfo(responseData);
        openBusinessModal();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(data.message)
                ? data.message.join('')
                : data.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => {
        setFetchingData(false);
      });
  };

  const applyUserChanges = changes => {
    updateRowData('organizationId', changes.account.id, changes);
  };

  const setNewlyAdded = business => {
    addRow(business);
  };

  const handleUpdateUser = (Id, data) => {
    updateRowData('organizationId', Id, data);
  };

  const handleBusinessModalClose = () => {
    setInitialBusinessInfo({});
    closeBusinessModal();
  };
  const handleFilterToggle = useCallback(() => {
    setFilterOn(prev => !prev);
  }, []);

  const handleFilterSave = useCallback(vals => {
    setFilters(vals);
    setFilterOn(true);
  }, []);

  const classBase = 'ickyc-business-manager';
  const classes = classNames('ickyc-kyc-page', classBase, className);
  const groupPermissions = useGroupPermissions(authEnums.PERMISSION_GROUP.BUSINESS_MANAGER);

  return (
    <div className={classes}>
      {groupPermissions.create ? (
        <ButtonHeading
          title="Business Manager"
          buttonCopies={[<>+ Add Business</>]}
          clickHandlers={[
            () => {
              setInitialBusinessInfo({});
              openBusinessModal();
            },
          ]}
        />
      ) : (
        <BareHeading title="Business Manager" />
      )}
      <SearchControls>
        <div className="ickyc-search-controls__info">
          <span>{pagination.total > 1 ? `${pagination.total || 0}  Results` : `${pagination.total || 0} Result`}</span>
        </div>

        <div className="ickyc-search-controls__filter-section">
          <DebounceInput
            placeholder="Search Businesses"
            initialValue={params.searchString}
            onChange={applySearchTerm}
          />
          <FilterToggleButton filters={filters} filterOn={filterOn} onToggleFilter={handleFilterToggle}>
            <BusinessFilterModal onFilterSave={handleFilterSave} />
          </FilterToggleButton>
        </div>
      </SearchControls>
      <Table
        values={records}
        onClick={fetchBusinessInfo}
        pagination={pagination}
        tableRow={BusinessManagerTableRow}
        headerRow={TableHeader}
        onRemove={removeRow}
        handleParamsChange={changeParams}
        className="ickyc-business-manager-table"
        withPagination
        withLimitChange
        updating={isLoading || fetchingData}
        headerData={{
          withDelete: groupPermissions?.delete,
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          onClick: changeParams,
          items: TableHeaders.BusinessManagerTableHeader,
        }}
      />
      {isBusinessModalOpen && (
        <BusinessManagerModal
          className="ickyc-user-management-table"
          hideModal={handleBusinessModalClose}
          handleUpdateInfo={applyUserChanges}
          disableAttachTo
          initialValues={initialBusinessInfo}
          setNewlyAdded={setNewlyAdded}
          updateUser={handleUpdateUser}
        />
      )}
    </div>
  );
};
BusinessManager.propTypes = {
  className: PropTypes.string,
};

BusinessManager.defaultProps = {
  className: undefined,
};
export default BusinessManager;
