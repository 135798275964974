import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../components/InputField';
import SelectFilterContent from '../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import useSelect from '../../../../../../../hooks/useSelect';
import { selectUserAccessByLicense } from '../../../../../../../store/selectors/auth.selector';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import validators from '../../../../../../../utilities/services/validators';
import { WEB_HOOK_EVENT_TYPES } from '../../../../enums';
import './styles.scss';

const WebHookForm = () => {
  const classBase = 'ickyc-create-new-web-hook-form';
  const platformAccess = useSelect(selectUserAccessByLicense);
  const webHookTypes = useMemo(() => {
    if (!platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.CASES)) {
      return WEB_HOOK_EVENT_TYPES.filter(type => type?.id !== 2048);
    }
    return WEB_HOOK_EVENT_TYPES;
  }, [platformAccess]);
  return (
    <div className={classBase}>
      <Field component={InputField} required label="Name" name="name" validate={validators.required()} />

      <Field
        component={InputField}
        required
        label="Endpoint"
        name="endpoint"
        validate={validators.composeValidators(validators.required(), validators.url('Invalid endpoint'))}
      />

      <Field
        component={SelectField}
        label="Type"
        required
        multipleSelection
        options={webHookTypes}
        validate={validators.required()}
        showErrors
        name="types"
        Trigger={<SelectTrigger />}
        Content={<SelectFilterContent />}
      />
      <Field
        component={InputField}
        required
        label="Request URL"
        name="requestUrl"
        validate={validators.composeValidators(validators.required(), validators.url('Invalid Request URL'))}
      />
    </div>
  );
};
export default WebHookForm;
