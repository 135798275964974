import redirect from './modules/redirect';
import PrivateSubrouter from './subrouters/Private/Private.subrouter';
import PublicSubrouter from './subrouters/Public/Public.subrouter';

export default [
  {
    path: '/not-found',
    component: () => `not found`,
  },

  {
    path: '/auth',
    authorized: false,
    component: PublicSubrouter,
    exact: false,
  },
  {
    path: '/',
    authorized: true,
    component: PrivateSubrouter,
    exact: false,
  },
  {
    path: '*',
    component: redirect('/not-found', true),
  },
];
