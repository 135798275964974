import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import api from '../../api';
import UploadImageWrapper from '../UploadImageWrapper';

const prepareInitValue = value => ({
  ...value,
  status: value.status ?? 'idle',
});

const UploadDocument = props => {
  const { photoLabel, input } = props;
  const { value = {}, onChange, ...rest } = input;

  const [imageData, setImageData] = useState(prepareInitValue(value));

  const uploadImage = useCallback(async (image, imgData) => {
    const formData = new FormData();
    formData.append('file', image, imgData.name);

    try {
      const {
        data: { uploadedName },
      } = await api.kyc.entityManagement.naturalPerson.uploadFile(formData);

      setImageData(prev => ({
        ...prev,
        status: 'succeeded',
        other: { uploadedName },
      }));
    } catch (error) {
      let errorMsg;
      const { status, data } = error;

      if (status === 400) {
        errorMsg = data.errors?.[0];
        errorMsg = errorMsg ? `${errorMsg} - Please Replace!` : undefined;
      } else if (status === 401) {
        errorMsg = data.errors?.[0];
      } else if (status === 500) {
        errorMsg = 'Server Error 500';
      } else if (status === 0) {
        errorMsg = 'Please check your internet connection';
      }

      setImageData(prev => ({
        ...prev,
        status: 'failed',
        other: { errorMsg },
      }));
    }
  }, []);

  const handleOnDrop = useCallback(
    (
      acceptedFiles,
      // fileRejections: Array<FileRejection>
    ) => {
      const acceptedFile = acceptedFiles[0];

      // one image in a container
      if (imageData.blobURL) URL.revokeObjectURL(imageData.blobURL);

      const newImageData = {
        name: acceptedFile.name,
        type: acceptedFile.type,
        blobURL: URL.createObjectURL(acceptedFile),
        status: 'pending',
      };

      setImageData(newImageData);
      uploadImage(acceptedFile, newImageData);
    },
    [imageData.blobURL, uploadImage],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleOnDrop,
    multiple: false,
    ...rest,
  });

  useEffect(() => {
    if (!onChange) return;
    if (imageData.status === 'succeeded') onChange(imageData);
    else if (imageData.status === 'failed') onChange(undefined);
  }, [imageData, onChange]);

  return (
    <UploadImageWrapper
      open={open}
      imageData={imageData}
      photoLabel={photoLabel}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      messages={{
        failed: imageData.other?.errorMsg,
      }}
    />
  );
};

UploadDocument.propTypes = {
  input: PropTypes.object.isRequired,
  photoLabel: PropTypes.string,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
};

UploadDocument.defaultProps = {
  photoLabel: '',
  meta: { invalid: false, touched: false, error: '', submitError: '', dirtySinceLastSubmit: false },
};

export default UploadDocument;
