import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import ToggleField from '../../../../components/ToggleField';

const EntityTypesToggleFilter = ({ npFieldName, leFieldname, disabled }) => {
  return (
    <div className="ickyc-entity-types">
      <Field component={ToggleField} label="Natural Person" name={npFieldName} disabled={disabled} />
      <Field component={ToggleField} label="Legal Entity" name={leFieldname} disabled={disabled} />
    </div>
  );
};
EntityTypesToggleFilter.propTypes = {
  disabled: PropTypes.bool,
  npFieldName: PropTypes.string.isRequired,
  leFieldname: PropTypes.string.isRequired,
};
EntityTypesToggleFilter.defaultProps = {
  disabled: false,
};
export default EntityTypesToggleFilter;
