import React, { useCallback, useEffect, useState } from 'react';
import { useField, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import api from '../../../../../api';
import OutlineButton from '../../../../../components/Buttons/OutlineButton';
import TableNavigation from '../../../../../components/TableNavigation';
import AffiliatedEntitiesFields from './components/AffiliatedEntityFields';
import './styles.scss';

const LinkAffiliatedEntitiesStep = () => {
  const [currentEntityindex, setCurrentEntityIndex] = useState(0);
  const { mutators } = useForm();
  const {
    values: { entities },
  } = useFormState();
  const namePreffix = `entities.${currentEntityindex}.affiliatedEntities`;

  const loadNextEntity = useCallback(() => setCurrentEntityIndex(prev => Math.min(entities.length, prev + 1)), [
    entities.length,
  ]);
  const loadPreviousEntity = useCallback(() => setCurrentEntityIndex(prev => Math.max(0, prev - 1)), []);
  const addPerson = () => {
    mutators.push(namePreffix, {});
  };

  const [enitiesList, setEntities] = useState([]);

  const {
    input: { value: selectedEntityType },
  } = useField(`${namePreffix}.type`);

  const [params, setParams] = useState({
    sortOrder: 1,
    searchCriteria: 'date',
    limit: 15,
    entityType: 0,
  });

  useEffect(() => {
    const fetch = () => {
      api.kyc.entityManagement.legalEntity
        .getALLAffiliatedEntitiesSuggestios({
          ...params,
        })
        .then(({ data: response }) => {
          setEntities(
            response.data.map(({ id, name, ...rest }) => ({
              id,
              label: name,
              name,
              value: id,
              ...rest,
            })),
          );
        })
        .finally(() => {});
    };
    if (params.searchString) {
      fetch();
    }
  }, [params, selectedEntityType]);

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  return (
    <div className="ickyc-link-affiliated-entities-form">
      <div className="ickyc-link-affiliated-entities-form__header">
        <h1>Step 2 &nbsp;</h1>
        <h2>Link Affiliated Entities</h2>
      </div>
      <div className="ickyc-link-affiliated-entities-form__current-entity">
        <h1>{currentEntityindex + 1}</h1>
        <h2>{entities[currentEntityindex]?.name}</h2>
        <span className="ickyc-spacer" />
        <TableNavigation
          totalNumber={entities.length}
          pageNumber={currentEntityindex + 1}
          goToNextPage={loadNextEntity}
          goToPrevPage={loadPreviousEntity}
          pageSize={1}
        />
      </div>
      <div className="ickyc-link-affiliated-entities-form__fields">
        <FieldArray name={namePreffix}>
          {({ fields, meta: { submitError, dirtySinceLastSubmit } }) => {
            return fields.map((name, index) => (
              <AffiliatedEntitiesFields
                className="ickyc-link-affiliated-entities-form__fields__row"
                key={`${name}${currentEntityindex}`}
                index={index}
                namePreffix={name}
                entities={enitiesList}
                removeRow={i => fields.remove(i)}
                currentEntityindex={currentEntityindex}
                changeParams={changeParams}
                submitError={submitError}
                dirtySinceLastSubmit={dirtySinceLastSubmit}
                isOnlyAffilitedEntity={fields.length === 1}
              />
            ));
          }}
        </FieldArray>
      </div>
      <OutlineButton onClick={addPerson}>+ Add Row </OutlineButton>
    </div>
  );
};

export default LinkAffiliatedEntitiesStep;
