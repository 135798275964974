import React from 'react';

export default () => (
  <svg viewBox="0 0 119.87 109.47" fill="currentColor" width="20px" height="20px">
    <g transform="translate(0,-273.187)">
      <path d="M39.46,337.54c-18.2,4.77-29.66,16.31-33.38,32.52c-1.08,4.64,0.59,5.99,2.68,6.63   c14.31,4.41,40.78,5.97,51.17,5.97c10.39,0,36.87-1.56,51.17-5.97c2.08-0.64,3.76-1.99,2.68-6.63   c-3.72-16.21-15.18-27.75-33.38-32.52l-18.81,15.76c-0.96,0.8-2.35,0.8-3.31,0L39.46,337.54z" />
      <path d="M59.93,273.19c-15.83,0-28.57,12.88-28.57,28.87v1.95c0,16,12.74,28.87,28.57,28.87s28.57-12.88,28.57-28.87   v-1.95C88.5,286.07,75.76,273.19,59.93,273.19z" />
    </g>
  </svg>
);
