import StorageFactory from './StorageFactory';

class TokenStorage extends StorageFactory {
  set(value) {
    super.set('token', value);
  }

  get() {
    return super.get('token');
  }

  get token() {
    return super.get('token');
  }

  set token(value) {
    super.set('token', value);
  }

  get refreshToken() {
    return super.get('refreshToken');
  }

  set refreshToken(token) {
    super.set('refreshToken', token);
  }

  remove() {
    super.remove('refreshToken');
    super.remove('token');
  }
}

export default new TokenStorage();
