import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import DatepickerField from '../../../../components/DatepickerField';

const DateFromUntilPicker = ({ disabled, dateFromName, dateUntilName, todayAsMaxDate }) => {
  return (
    <div className="ickyc-from-until-date">
      <Field
        label="Date From "
        component={DatepickerField}
        name={dateFromName}
        maxDate={todayAsMaxDate ? new Date() : undefined}
        disabled={disabled}
      />
      <Field
        label="Date Until"
        component={DatepickerField}
        name={dateUntilName}
        maxDate={todayAsMaxDate ? new Date() : undefined}
        disabled={disabled}
        endOfDay
      />
    </div>
  );
};

DateFromUntilPicker.propTypes = {
  disabled: PropTypes.bool,
  dateFromName: PropTypes.string.isRequired,
  dateUntilName: PropTypes.string.isRequired,
  todayAsMaxDate: PropTypes.bool,
};
DateFromUntilPicker.defaultProps = {
  disabled: false,
  todayAsMaxDate: true,
};
export default DateFromUntilPicker;
