import React, { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../../../components/Accordion';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import useEffectSkipFirst from '../../../../../../../../../hooks/useEffectSkipFirst';
import utilities from '../../../../../../../../../utilities';
import kycServicesEnums from '../../../kycServicesEnums';
import './styles.scss';

const DocumentAuthenticationRCU = ({ namePrefix }) => {
  const baseClass = 'ickyc-docment-auth-rcu';

  const { values } = useFormState();

  const { kycRequests, dataCollection } = values[namePrefix]
    ? { kycRequests: values[namePrefix].kycRequests, dataCollection: values[namePrefix].dataCollection }
    : { kycRequests: values.kycRequests, dataCollection: values.dataCollection };

  const { batch, change } = useForm();

  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  useEffect(() => {
    batch(() => {
      change(
        `${namePrefixWithDot}kycRequests.documentAuthentication.templateVerification`,
        kycRequests?.documentAuthentication?.isEnabled,
      );
      change(
        `${namePrefixWithDot}kycRequests.documentAuthentication.documentDataVerification`,
        kycRequests.documentAuthentication?.isEnabled,
      );
      change(
        `${namePrefixWithDot}kycRequests.documentAuthentication.includeFaceMatch`,
        kycRequests?.documentAuthentication?.isEnabled,
      );
      // synced
      change(
        `${namePrefixWithDot}dataCollection.requestIdentityDocument`,
        kycRequests?.documentAuthentication?.isEnabled,
      );
    }, []);
  }, [kycRequests?.documentAuthentication?.isEnabled]);

  useEffectSkipFirst(() => {
    if (
      !kycRequests?.documentAuthentication?.templateVerification &&
      !kycRequests?.documentAuthentication?.documentDataVerification &&
      !kycRequests?.documentAuthentication?.includeFaceMatch
    ) {
      change(`${namePrefixWithDot}kycRequests.documentAuthentication.isEnabled`, false);
    }
  }, [
    kycRequests?.documentAuthentication?.templateVerification,
    kycRequests?.documentAuthentication?.documentDataVerification,
    kycRequests?.documentAuthentication?.includeFaceMatch,
  ]);

  useEffectSkipFirst(() => {
    change(`${namePrefixWithDot}kycRequests.documentAuthentication.isEnabled`, dataCollection?.requestIdentityDocument);
  }, [dataCollection?.requestIdentityDocument]);

  return (
    <Accordion
      title={
        <>
          <div onClick={utilities.handleOnClickEvent}>
            <Field
              preview
              name={`${namePrefixWithDot}kycRequests.documentAuthentication.isEnabled`}
              component={ToggleField}
              label="Document Authentication"
            />
          </div>
          <span className="ickyc-spacer" />
        </>
      }
      accordionOpen={kycRequests?.documentAuthentication?.isEnabled}
    >
      <div className={baseClass}>
        <Field
          name={`${namePrefixWithDot}kycRequests.documentAuthentication.templateVerification`}
          component={ToggleField}
          label="Run KYC Service: Template Verification"
        />
        <Field
          name={`${namePrefixWithDot}kycRequests.documentAuthentication.documentDataVerification`}
          component={ToggleField}
          label="Run KYC Service: Document Data Verification"
        />
        <div className="ickyc-docment-auth-rcu__include-face-match">
          <Field
            name={`${namePrefixWithDot}kycRequests.documentAuthentication.faceMatchStrategy`}
            label={
              <Field
                name={`${namePrefixWithDot}kycRequests.documentAuthentication.includeFaceMatch`}
                component={ToggleField}
                label="Include Face Match"
              />
            }
            component={SelectField}
            renderAsPortal
            options={kycServicesEnums.INCLUDE_FACE_MATCH_OPTIONS}
            Trigger={<SelectTrigger placeholder="Face Match or Existing Record" />}
            Content={<SelectFilterContent />}
          />
        </div>
      </div>
    </Accordion>
  );
};
export default DocumentAuthenticationRCU;
