import React from 'react';

const WebFormIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 104 104" fill="currentColor">
    <path d="M92.7 27.6H11.3c-1.1 0-2-.9-2-2V9.7c0-1.1.9-2 2-2h81.4c1.1 0 2 .9 2 2v15.9c0 1.1-.9 2-2 2zm-79.3-4h77.4V11.7H13.4v11.9z" />
    <path d="M16.2 14.196h4v7.2h-4zM9.3-.004h28.3v4H9.3z" />
    <g>
      <path d="M92.7 66.3H11.3c-1.1 0-2-.9-2-2V48.4c0-1.1.9-2 2-2h81.4c1.1 0 2 .9 2 2v15.9c0 1.1-.9 2-2 2zm-79.3-4h77.4V50.4H13.4v11.9zM9.3 38.706h28.3v4H9.3z" />
    </g>
    <g>
      <path d="M92.7 104H11.3c-1.1 0-2-.9-2-2V86.1c0-1.1.9-2 2-2h81.4c1.1 0 2 .9 2 2V102c0 1.1-.9 2-2 2zm-79.3-4h77.4V88.1H13.4V100zM9.3 76.53h28.3v4H9.3z" />
    </g>
  </svg>
);
export default WebFormIcon;
