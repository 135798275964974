import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import setClassSuffix from 'utilities/services/ClassManager';
import DashboardTile from '../DashboardTile';
import './styles.scss';

const EntitiesCountTile = ({ currentUsage, isLoading }) => {
  const baseClass = 'ickyc-entities-count-tile';
  const setSuffix = setClassSuffix(baseClass);
  const history = useHistory();

  const navigateToEntities = useCallback(() => {
    history.push('/kyc/entities');
  }, [history]);

  return (
    <DashboardTile
      header="Entities"
      buttonText="Add Entity"
      className={baseClass}
      isLoading={isLoading}
      buttonAction={navigateToEntities}
    >
      <span className={setSuffix('__count')}>{currentUsage}</span>
    </DashboardTile>
  );
};
EntitiesCountTile.propTypes = {
  className: PropTypes.string,
  currentUsage: PropTypes.number,
  isLoading: PropTypes.bool,
};

EntitiesCountTile.defaultProps = {
  className: undefined,
  currentUsage: 0,
  isLoading: true,
};
export default EntitiesCountTile;
