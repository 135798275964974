import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import RadioButtonField from '../../../../components/RadioButtonField';
import IconManager from '../../../../utilities/services/IconManager';
import './styles.scss';

const AcceptRejectCaptureRadioButtons = ({ name, onlyIcons, disabled }) => {
  return (
    <span className="ickyc-accept-reject-capture-radio-buttons">
      <Field
        component={RadioButtonField}
        classNameSuffix="--accept"
        type="radio"
        value="Accepted"
        label={onlyIcons ? '' : 'Accept'}
        icon={IconManager.names.CHECK_FILLED}
        inline
        iconPosition="left"
        name={name}
        disabled={disabled}
      />
      <Field
        component={RadioButtonField}
        name={name}
        type="radio"
        classNameSuffix="--reject"
        value="Rejected"
        icon={IconManager.names.CLOSE}
        label={onlyIcons ? '' : 'Reject'}
        iconPosition="left"
        inline
        disabled={disabled}
      />
      <Field
        component={RadioButtonField}
        name={name}
        type="radio"
        inline
        iconPosition="left"
        classNameSuffix="--capture"
        icon={IconManager.names.ARCHIVE}
        value="Captured"
        label={onlyIcons ? '' : 'Capture'}
        disabled={disabled}
      />
    </span>
  );
};

AcceptRejectCaptureRadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  onlyIcons: PropTypes.bool,
  disabled: PropTypes.bool,
};

AcceptRejectCaptureRadioButtons.defaultProps = {
  onlyIcons: false,
  disabled: false,
};

export default React.memo(AcceptRejectCaptureRadioButtons);
