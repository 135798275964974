import axiosInstance from '../axiosInstance';

const BASE_ROUTE = '/client-user';

const ENDPOINTS = {
  updateEmailNotificationPreferences: () => `${BASE_ROUTE}/email-notification-preferences`,
  updatePersonalInfo: () => `${BASE_ROUTE}/info`,
  resetPassword: () => `${BASE_ROUTE}/password`,
  getUserDetails: () => `${BASE_ROUTE}/user-details`,
  resendEmail: () => `${BASE_ROUTE}/email-verification`,
};

const updateEmailNotificationPreferences = data => {
  return axiosInstance.put(ENDPOINTS.updateEmailNotificationPreferences(), data);
};

const updatePersonalInfo = data => {
  return axiosInstance.put(ENDPOINTS.updatePersonalInfo(), data);
};

const resetPassword = (currentPassword, newPassword) => {
  const formdata = new FormData();
  formdata.set('currentPassword', currentPassword);
  formdata.set('newPassword', newPassword);
  return axiosInstance.post(ENDPOINTS.resetPassword(), formdata);
};

const resendEmail = () => {
  return axiosInstance.get(ENDPOINTS.resendEmail());
};
export default {
  updateEmailNotificationPreferences,
  updatePersonalInfo,
  resetPassword,
  resendEmail,
};
