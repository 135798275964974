import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import BareHeading from '../../../components/Headings/BareHeading';
import MyWebHooks from './components/MyWebHooks';

const WebHooksManagmentPage = ({ className }) => {
  const classes = classNames('ickyc-page', className);

  return (
    <div className={classes}>
      <BareHeading title="API Webhooks" />
      <MyWebHooks />
    </div>
  );
};

WebHooksManagmentPage.propTypes = {
  className: PropTypes.string,
};
WebHooksManagmentPage.defaultProps = {
  className: undefined,
};

export default WebHooksManagmentPage;
