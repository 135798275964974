import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ProgressBar from '../ProgressBar';

import './styles.scss';

const UsageRow = ({ className, name, used, limit }) => {
  const classes = classNames('ickyc-usage-row', { [className]: className });

  return (
    <tr className={classes}>
      <td>{name}</td>
      <td>
        <div className="ickyc-usage-row__credits-used">
          <ProgressBar creditsLimit={limit} creditsUsed={used} />
          <p>{used}</p>
        </div>
      </td>
      <td>{limit}</td>
    </tr>
  );
};
UsageRow.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  used: PropTypes.string,
  limit: PropTypes.string,
};

UsageRow.defaultProps = {
  className: undefined,
  name: '',
  used: 0,
  limit: 0,
};
export default UsageRow;
