import EmailTemplates from 'scenes/Internal/EmailTemplates';
import EmailTemplateEditorPage from 'scenes/Internal/EmailTemplates/components/EmailTemplateEditorPage';
import PortalDetailsLegalEntity from '../../../../../scenes/Administration/PortalManagementPage/components/PortalDetailsLegalEntity';
import PortalDetailsNaturalPerson from '../../../../../scenes/Administration/PortalManagementPage/components/PortalDetailsNaturalPerson';
import BusinessManager from '../../../../../scenes/Internal/BusinessManager';
import BusinessProfile from '../../../../../scenes/Internal/BusinessManager/components/BusinessProfile';
import IComplyTeam from '../../../../../scenes/Internal/IComplyTeam';
import LicenceManager from '../../../../../scenes/Internal/LicenceManager';
import authEnums from '../../../../../utilities/enums/authEnums';
import redirect from '../../../../modules/redirect';

export default [
  {
    path: '/business-manager',
    component: BusinessManager,
    allowedUserType: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
    ],
    allowedUserRoles: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
    ],
    authorized: true,
  },
  {
    path: '/business-manager/:id',
    component: BusinessProfile,
    allowedUserType: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
    ],
    allowedUserRoles: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
    ],
    authorized: true,
  },
  {
    path: '/business-manager/:clientId/natural-person-portal/:id',
    component: PortalDetailsNaturalPerson,
    allowedUserType: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
    ],
    allowedUserRoles: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
    ],
    authorized: true,
  },
  {
    path: '/business-manager/:clientId/legal-entity-portal/:id',
    component: PortalDetailsLegalEntity,
    allowedUserType: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
    ],
    allowedUserRoles: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
    ],
    authorized: true,
  },
  {
    path: '/icomply-team',
    component: IComplyTeam,
    allowedUserType: [authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN, authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN],
    allowedUserRoles: [authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN, authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN],
    authorized: true,
  },
  {
    path: '/license-management',
    component: LicenceManager,
    allowedUserType: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
    ],
    allowedUserRoles: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
    ],
    authorized: true,
  },
  {
    path: '/email-templates',
    component: EmailTemplates,
    allowedUserType: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
    ],
    allowedUserRoles: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
    ],
    authorized: true,
  },
  {
    path: '/email-templates/:id',
    component: EmailTemplateEditorPage,
    allowedUserType: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
    ],
    allowedUserRoles: [
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN,
      authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER,
    ],
    authorized: true,
  },
  {
    path: '*',
    component: redirect('/internal/business-manager', true),
  },
];
