import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import Toggle from '../../../../components/Toggle';
import './styles.scss';

const FilterItem = ({ label, children, value, onChange, input }) => {
  const preventBubbling = event => {
    event.stopPropagation();
  };

  const classes = classNames({
    'ickyc-filter-item': true,
  });

  const toggleChange = useCallback(
    ev => {
      return input ? input.onChange(ev) : onChange(ev);
    },
    [input, onChange],
  );
  const toggleValue = useMemo(() => (input ? input.value : value), [input, value]);

  return (
    <div className={classes} onClick={preventBubbling}>
      <Toggle label={label} value={toggleValue} checked={!!toggleValue} onChange={toggleChange} />

      {React.cloneElement(children, { disabled: !toggleValue })}
    </div>
  );
};

FilterItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,

  // Generated by final-form's Field component
  input: PropTypes.object,
};

FilterItem.defaultProps = {
  value: false,
  onChange: () => {},
  input: null,
};

export default FilterItem;
