import PropTypes from 'prop-types';
import React from 'react';
import CloseButton from '../../../../../../../components/Buttons/CloseButton';
import PrimaryButton from '../../../../../../../components/Buttons/PrimaryButton';
import Modal from '../../../../../../../components/Modal';

import './styles.scss';

const ConfirmCompanyModal = ({ onCloseModal, data }) => {
  const { name, code, address, type } = data;
  const isDuns = type === 'duns';
  const classBase = 'ickyc-business-account-company-modal';

  return (
    <Modal className={`ickyc-modal ${classBase}`} hideModal={() => onCloseModal(false)}>
      <div className="ickyc-portal-management-modal ickyc-form-modal">
        <h2 className="ickyc-form-modal__header">
          Confirm Company Information
          <CloseButton transparent onClick={() => onCloseModal(false)} />
        </h2>
        <div className="ickyc-form-modal__content">
          <ul className="ickyc-modal-info">
            <li>
              Name: <span className="ickyc-modal-info__name">{name}</span>
            </li>
            <li>{`Address: ${address}`}</li>
            <li>{`${isDuns ? `DUNS: ${code}` : `GLEI: ${code}`}`}</li>
          </ul>
        </div>
        <div className="ickyc-form-modal__footer">
          <PrimaryButton variant="link" type="button" onClick={() => onCloseModal(false)}>
            Decline
          </PrimaryButton>
          <PrimaryButton type="button" onClick={() => onCloseModal(true)}>
            Confirm
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

ConfirmCompanyModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
    address: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
};

export default ConfirmCompanyModal;
