import Notifications from '../../../scenes/Notifications';
import redirect from '../../modules/redirect';
import AdministratorSubrouter from './subrouters/Administrator';
import BusinessSubrouter from './subrouters/Business';
import KycSubrouter from './subrouters/Kyc';
import SettingsSubrouter from './subrouters/Settings';

// TODO: Add className prop to every page in every subrouter
export default [
  {
    path: '/administration',
    component: AdministratorSubrouter,
    exact: false,
  },
  {
    path: '/kyc',
    component: KycSubrouter,
    exact: false,
  },

  {
    path: '/account-settings',
    component: SettingsSubrouter,
    exact: false,
  },
  {
    path: '/internal',
    component: BusinessSubrouter,
    exact: false,
  },
  {
    path: '/notifications',
    component: Notifications,
  },
  {
    path: '*',
    component: redirect('/kyc/home-screen', true),
  },
];
