import { itemTypes } from '../components/PackageItemsFields/utils';

const prepareItems = packageItems => {
  let answersDTO;
  const { answers, ...items } = packageItems || {};

  if (answers) {
    answersDTO = Object.entries(answers).reduce((acc, [itemId, valueWithKeyType]) => {
      const result = Object.entries(valueWithKeyType).reduce((innerAcc, [itemType, nestedValue]) => {
        if (itemType === itemTypes.CURRENCYFIELD && !nestedValue.amount) {
          return innerAcc; // Skip if CURRENCYFIELD and no amount
        }
        innerAcc.push({
          itemId,
          answer: nestedValue,
          type: itemType,
        });
        return innerAcc;
      }, []);
      return acc.concat(result);
    }, []);
  }

  return { answersDTO, itemsDTO: items };
};

const utils = { prepareItems };
export default prepareItems;
