import api from 'api';
import React, { useCallback } from 'react';
import toastr from 'toastr';
import IComplyForm from '../../../../../components/Form/IComplyForm';
import CompanyDetailsFormFields from './components/CompanyDetailsFormFields';
import './styles.scss';

const CompanyDetailsPage = ({ initialValues }) => {
  const handleFormSubmit = useCallback(async data => {
    try {
      await api.administration.account.updateCompanyDetails(data);
      toastr.success('Successfully updated Company Details');
    } catch (err) {
      console.error(err);
      toastr.error('Error occured while updating Company Details');
    } finally {
    }
  }, []);

  return (
    <IComplyForm
      showControls
      onSubmit={handleFormSubmit}
      className="ickyc-company-details"
      initialValues={initialValues}
    >
      {<CompanyDetailsFormFields />}
    </IComplyForm>
  );
};
export default CompanyDetailsPage;
