import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import Badge from '../../../../../../../components/Badge';
import SingleCountry from '../../../../../../../components/CountrySelect/components/SingleCountry';
import useSelect from '../../../../../../../hooks/useSelect';
import { selectCountries } from '../../../../../../../store/selectors/global.selector';
import utilities from '../../../../../../../utilities';
import routesEnum from '../../../../../../../utilities/enums/routesEnum';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../utilities/services/DateManager';

const AffiliationsTableRow = ({ rowInfo, className }) => {
  const { id, date, name, country, roles, ownership, issues, entityId, entityType } = rowInfo;
  const setSuffix = setClassSuffix(`${className}__column`);
  const countries = useSelect(selectCountries);
  const countryToShow = countries.find(c => c.id === country);
  const entityProfileLink = useMemo(() => {
    return utilities.routeParams(
      entityType === 'Legal Entity' ? routesEnum.kyc.LEGAL_ENTITY : routesEnum.kyc.NATURAL_PERSON,
      {
        id: entityId,
      },
    );
  }, [entityId, entityType]);
  return (
    <tr className={`${className}__row`} key={id}>
      <td className={setSuffix('__date')}>
        <NavLink to={entityProfileLink}>{date ? DateManager.monDayYearLocal(date) : 'N/A'}</NavLink>
      </td>
      <td className={setSuffix('__name')}>
        <NavLink to={entityProfileLink}>{name}</NavLink>
      </td>
      <td className={setSuffix('__roles')}>
        <NavLink to={entityProfileLink}>{roles ? roles.join(', ') : ''}</NavLink>
      </td>
      <td className={setSuffix('__countryName')}>
        {country && countryToShow ? (
          <SingleCountry alpha2Code={countryToShow.alpha2Code.toLowerCase()} name={countryToShow.name} />
        ) : (
          'N/A'
        )}
      </td>
      <td className={setSuffix('__ownership')}>
        <NavLink to={entityProfileLink}>{ownership || 0}%</NavLink>
      </td>
      <td className={setSuffix('__issues')}>
        <NavLink to={entityProfileLink}>
          <Badge content={issues} red />
        </NavLink>
      </td>
    </tr>
  );
};
AffiliationsTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    date: PropTypes.string.isRequired,
    issues: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    assignedUserName: PropTypes.string,
    risk: PropTypes.number,
    roles: PropTypes.string,
    country: PropTypes.number,
    entityId: PropTypes.string.isRequired,
    assignedUserId: PropTypes.string,
    entityType: PropTypes.string.isRequired,
    ownership: PropTypes.number,
  }),
  className: PropTypes.string.isRequired,
};
AffiliationsTableRow.defaultProps = {
  rowInfo: {
    issues: 0,
    assignedUserName: null,
    assignedUserId: null,
    roles: '',
    ownership: 0,
  },
};
export default AffiliationsTableRow;
