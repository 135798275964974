import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import VirtualList from 'react-vlist';
import Checkbox from '../../../../../components/Checkbox';
import CheckboxField from '../../../../../components/CheckboxField';
import './styles.scss';

const FieldSupportingDocsList = ({
  initValue,
  TableControlsComponent,
  tableRow: TableRow,
  modulePreffix,
  idsToInclude,
  withInfiniteScroll,
  disabled,
  propertyName,
}) => {
  const [controlsValue, setControlsValue] = useState();

  const [selectedAll, setSelectedAll] = useState(false);

  const { change, batch } = useForm();
  const {
    values: { [modulePreffix]: restrictions, automationsSupportingDocs },
  } = useFormState();

  const areSelectedAll = useMemo(
    () =>
      selectedAll || (Object.values(restrictions || []).every(val => val.selected) && (restrictions || []).length > 0),
    [selectedAll, restrictions],
  );
  const onSelectAll = useCallback(() => {
    batch(() => {
      Object.entries(restrictions).forEach(el => {
        change(`${modulePreffix}.${el[0]}.selected`, !selectedAll);
      });
    });
    setSelectedAll(prev => !prev);
  }, [batch, change, restrictions, selectedAll, modulePreffix]);

  const applyToMultipleRows = useCallback(
    val => {
      batch(() => {
        Object.entries(restrictions).forEach(
          el => {
            if (el[1].selected) {
              change(`${modulePreffix}.${el[0]}`, { ...el[1], ...val });
            }
          }, // listeners not notified
        );
      }); // NOW all listeners notified
    },
    [restrictions, change, batch, modulePreffix],
  );

  const disableControls = useMemo(() => !Object.entries(restrictions || []).some(el => el[1]?.selected) || disabled, [
    restrictions,
    disabled,
  ]);

  const [controlsState, setControlsState] = useState(initValue);
  const TableControls = () => {
    return (
      <>
        <Checkbox
          checked={areSelectedAll}
          disabled={disabled}
          onChange={onSelectAll}
          label="Select All"
          name="countries.selectAll"
        />
        <TableControlsComponent
          disabled={disableControls}
          value={controlsValue}
          setState={setControlsState}
          initValue={controlsState}
          onClick={applyToMultipleRows}
          setValue={setControlsValue}
          propertyName={propertyName}
        />
      </>
    );
  };

  const baseClass = 'ickyc-countries';

  const listItemClasses = classNames({
    [`${baseClass}__list__item`]: true,
    [`${baseClass}__list__item--disabled`]: disabled,
  });
  const renderRow = useCallback(
    (supDoc, index, style) => (
      <li className={listItemClasses} key={index} style={style}>
        <div className={`${baseClass}__list__item__checkbox`}>
          <Field
            component={CheckboxField}
            type="checkbox"
            checked={`restrictions.document${supDoc.packageId}.selected`}
            readOnly
            name={`${modulePreffix}.document${supDoc.packageId}.selected`}
            disabled={disabled}
          />
        </div>
        <div className={`${baseClass}__list__item__country`}>
          <span>{supDoc?.documentTypeName}</span>
        </div>
        <div className={`${baseClass}__list__item__controls`}>
          <TableRow
            modulePreffix={`${modulePreffix}.document${supDoc.packageId.toString()}`}
            disabled={disabled}
            propertyName={propertyName}
          />
        </div>
      </li>
    ),
    [modulePreffix, disabled, listItemClasses, propertyName],
  );

  return (
    <div className={baseClass}>
      {TableControls && (
        <div className={`${baseClass}__controls`}>
          <TableControls />
        </div>
      )}
      {withInfiniteScroll ? (
        <VirtualList
          className={`${baseClass}__list`}
          data={automationsSupportingDocs}
          itemheight={48}
          renderItems={(item, index, style) => renderRow(item, index, style)}
        />
      ) : (
        <ul className={`${baseClass}__list`}>
          {automationsSupportingDocs.map((item, index) => renderRow(item, index))}
        </ul>
      )}
    </div>
  );
};

FieldSupportingDocsList.propTypes = {
  tableRow: PropTypes.elementType.isRequired,
  modulePreffix: PropTypes.string.isRequired,
  TableControlsComponent: PropTypes.elementType,
  idsToInclude: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  withInfiniteScroll: PropTypes.bool,
  disabled: PropTypes.bool,
  initValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])),
  propertyName: PropTypes.string,
};
FieldSupportingDocsList.defaultProps = {
  withInfiniteScroll: false,
  idsToInclude: [],
  TableControlsComponent: null,
  disabled: false,
  initValue: [],
  propertyName: '',
};
export default FieldSupportingDocsList;
