import PropTypes from 'prop-types';
import React from 'react';

/**
 * Flag icon
 * @param {string} code - country alpha 2 code
 */

const Flag = ({ code }) => {
  return <span className={`flag-icon flag-icon-${code.toLowerCase()}`} />;
};

Flag.propTypes = {
  code: PropTypes.string,
};

Flag.defaultProps = {
  code: '',
};

export default Flag;
