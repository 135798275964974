import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

/**
 * One icon-component made up from two icons placed one on top of the other.
 * @param {component} [top = <span />] - icon placed on top
 * @param {component} [bottom = <span />] - icon placed on bottom
 *
 * In case one of the components is omitted that will be rendered as blank space.
 */
const IconCombinator = ({ top, bottom }) => (
  <div className="icon-combinator">
    <span>{top}</span>
    <span>{bottom}</span>
  </div>
);

IconCombinator.propTypes = {
  top: PropTypes.element,
  bottom: PropTypes.element,
};

IconCombinator.defaultProps = {
  top: <span />,
  bottom: <span />,
};

export default IconCombinator;
