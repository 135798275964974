import api from 'api';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import validators from 'utilities/services/validators';
import './styles.scss';

const DeleteDefaultPortalForm = ({ portalType, clientId }) => {
  const [isConfigurationListLoading, setIsConfigurationListLoading] = useState(false);
  const [configurationList, setConfigurationList] = useState([]);

  const mappedConfigurationList = useMemo(() => {
    return configurationList.map(({ id, name }) => ({
      label: name,
      value: id,
      key: id,
    }));
  }, [configurationList]);

  useEffect(() => {
    (async () => {
      try {
        setIsConfigurationListLoading(true);
        const { data } = clientId
          ? await api.businessManager.getAllConfigurationsForSpecificEntityType(portalType, clientId)
          : await api.administration.portalManagement.getAllConfigurationsForSpecificEntityType(portalType);
        setConfigurationList(data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsConfigurationListLoading(false);
      }
    })();
  }, []);

  return (
    <div className="ickyc-delete-default-portal-form">
      <span>You are about to delete your default portal. Choose your new default portal.</span>
      <Field
        component={SelectField}
        name="newDefaultPortalId"
        autoClose
        validate={validators.required('Please Select Configuration')}
        options={mappedConfigurationList}
        required
        Trigger={<SelectTrigger placeholder="Select Configuration" />}
        Content={<SelectFilterContent />}
        loading={isConfigurationListLoading}
      />
    </div>
  );
};
export default DeleteDefaultPortalForm;
