import api from 'api';
import classNames from 'classnames';
import NestedListHeader from 'components/Headings/NestedListHeading';
import ConfirmationPage from 'components/Modal/ConfirmationPage';
import FormModal from 'components/Modal/FormModal';
import InformationModal from 'components/Modal/InformationModal';
import Spinner from 'components/Spinner';
import { FORM_ERROR } from 'final-form';
import useDebounce from 'hooks/useDebounce';
import useModalHandler from 'hooks/useModalHandler';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toastr from 'toastr';
import enums from 'utilities/enums';
import commentTypes from 'utilities/enums/commentTypes';
import routesEnum from 'utilities/enums/routesEnum';
import IconManager from 'utilities/services/IconManager';
import { CaseContext } from 'utilities/services/contexts';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import CommentSection from '../../../components/CommentSection';
import AMLFiltering from '../AMLFiltering';
import GeneralCaseInformation from '../GeneralCaseInformation';
import Matches from '../Matches';

const CaseDetails = ({ className }) => {
  const { id: complianceLogId } = useParams();
  const classBase = 'ickyc-case-management';

  const classes = classNames('ickyc-kyc-page', classBase, className);
  const [caseInfo, setCaseInfo] = useState({ attachedTo: [] });

  const [isGlobalErrorModalOpen, setIsGlobalErrorModalOpen] = useState(false);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedAMLFilters, setSelectedAMLFilters] = useState();
  const [consolidatedMatchTypes, setConsolidatedMatchTypes] = useState([]);
  const [requestedAMLFilters, setRequestedAMLFilters] = useState([]);
  const [isFiltersDisabled, setIsFiltersDisabled] = useState(false);

  const debouncedSelectedFilters = useDebounce(selectedAMLFilters, 1000);

  useEffect(() => {
    const extractCaseDetails = data => ({
      searchTerm: data.searchTerm,
      assignedTo: data.assignedTo,
      assignedToId: data.assignedToId,
      year: data.year,
      attachedTo: data.attachedTo || [],
      fuzziness: data.fuzziness,
      dateOfBirth: data.dateOfBirth,
      runBy: data.runBy,
      created: data.created,
      countries: data.countries || '',
      caseStatus: data.caseStatus,
      onGoing: data.onGoing,
      entityType: data.entityType,
    });
    const getCaseDetails = async () => {
      try {
        setIsLoading(true);
        const response = await api.kyc.caseManagement.getCaseDetailsApi(complianceLogId);
        const { data } = response;
        setCaseInfo(extractCaseDetails(data));
        const { consolidatedMatchTypes: cmt, requestedAmlTypes } = data;
        setConsolidatedMatchTypes(cmt);
        setRequestedAMLFilters(requestedAmlTypes);
        setSelectedAMLFilters(requestedAmlTypes.filter(filter => cmt.includes(filter)));
      } catch (error) {
        const {
          response: {
            data: { message },
            status,
          },
        } = error;

        if (message.includes(enums.API_ERROR_TYPES.CASE_DETAILS_NOT_FOUND) || status === 404) {
          setIsGlobalErrorModalOpen(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    getCaseDetails();
  }, [complianceLogId]);

  const selectOrUnselectFilterGroup = (filterVal, codesOfTypes) => {
    const availableCodes = codesOfTypes.filter(code => consolidatedMatchTypes.includes(code));
    availableCodes.push(filterVal);
    setSelectedAMLFilters(prev =>
      selectedAMLFilters.includes(filterVal)
        ? selectedAMLFilters.filter(aml => !availableCodes.includes(aml))
        : [...prev, ...availableCodes],
    );
  };

  const columns = {
    firstGroup: [0, 1, 2],
    secondGroup: [4, 5, 6, 7],
    thirdGroup: [9, 10, 11, 12, 13, 14, 15],
  };

  const findElementsGroup = filterEl => {
    if (columns.firstGroup.includes(filterEl)) return { group: columns.firstGroup, mainEl: 16 };
    if (columns.secondGroup.includes(filterEl)) return { group: columns.secondGroup, mainEl: 3 };
    return { group: columns.thirdGroup, mainEl: 8 };
  };

  const handleFilterSelect = filter => {
    if (filter === 16) {
      selectOrUnselectFilterGroup(filter, columns.firstGroup);
    } else if (filter === 3) {
      selectOrUnselectFilterGroup(filter, columns.secondGroup);
    } else if (filter === 8) {
      selectOrUnselectFilterGroup(filter, columns.thirdGroup);
    } else {
      const { group: codesOfTypes, mainEl } = findElementsGroup(filter);
      const availableCodes = codesOfTypes.filter(code => consolidatedMatchTypes.includes(code));

      if (
        !selectedAMLFilters.includes(filter) &&
        availableCodes.filter(elm => elm !== filter).every(el => selectedAMLFilters.includes(el))
      ) {
        setSelectedAMLFilters(prev =>
          consolidatedMatchTypes.includes(mainEl) ? [...prev, filter, mainEl] : [...prev, filter],
        );
      } else if (!selectedAMLFilters.includes(filter)) {
        setSelectedAMLFilters(prev => [...prev, filter]);
      } else {
        setSelectedAMLFilters(prev => prev.filter(aml => aml !== filter && aml !== mainEl));
      }
    }
  };
  const navigateToCaseManagement = useCallback(() => history.push(routesEnum.kyc.CASE_MANAGEMENT), [history]);
  const headingListItems = useMemo(() => {
    return [
      <>
        <b>Case:</b> {caseInfo.searchTerm}
      </>,
    ];
  }, [caseInfo]);

  const handleConfirmationPageClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const { isOpen: isConfirmModalOpen, close: closeConfirmModal, open: openConfirmModal } = useModalHandler();

  const onCaseDelete = async () => {
    try {
      await api.kyc.caseManagement.removeCase(complianceLogId);
      toastr.success('Case was deleted');
      closeConfirmModal();
      history.push(routesEnum.kyc.CASE_MANAGEMENT);
    } catch {
      return { [FORM_ERROR]: 'Error Occured While Trying to Delete Case' };
    }
  };

  const handleCaseStatusChange = useCallback(caseStatus => {
    setCaseInfo(prev => ({ ...prev, caseStatus }));
  }, []);

  const handleMatchHitStatusChange = useCallback(consolidatedMatchTypes => {
    setConsolidatedMatchTypes(consolidatedMatchTypes);
    setSelectedAMLFilters(old => {
      return old.filter(filter => consolidatedMatchTypes.includes(filter));
    });
  }, []);

  return isLoading ? (
    <div className={classes}>
      <Spinner />
    </div>
  ) : (
    <CaseContext.Provider value={{ complianceLogId, isCaseClosed: caseInfo?.caseStatus === 'Closed' }}>
      <div className={classes}>
        {isGlobalErrorModalOpen ? (
          <InformationModal hideModal={() => setIsGlobalErrorModalOpen(false)} onClick={handleConfirmationPageClick}>
            <ConfirmationPage
              title="Oops!"
              subtitle="Something went wrong…"
              text={
                <>
                  We’re working on it, and we’ll get it fixed as soon as we can.
                  <br /> You may refresh the page, or try again later.
                </>
              }
              icons={<div>{IconManager.get(IconManager.names.GLOBAL_ERROR)}</div>}
            />
          </InformationModal>
        ) : (
          <>
            <NestedListHeader
              title="Case Details"
              onClick={navigateToCaseManagement}
              items={headingListItems}
              right={<DeleteButton onClick={openConfirmModal} />}
            />
            {isConfirmModalOpen && (
              <FormModal
                hideModal={closeConfirmModal}
                title="Delete Case"
                className="ickyc-confirmation-modal"
                onSubmit={onCaseDelete}
              >
                <span className="ickyc-confirmation-message">Are you sure you want to delete this case?</span>
              </FormModal>
            )}
            <GeneralCaseInformation info={caseInfo} onCaseStatusChange={handleCaseStatusChange} />
            <AMLFiltering
              onFilterSelect={handleFilterSelect}
              selectedAMLFilters={selectedAMLFilters}
              consolidatedMatchTypes={consolidatedMatchTypes}
              requestedAMLFilters={requestedAMLFilters}
              title="Potential Match Filtering"
              disabled={isFiltersDisabled}
            />
            {debouncedSelectedFilters ? (
              <>
                <Matches
                  onMatchHitStatusChange={handleMatchHitStatusChange}
                  selectedAMLFilters={debouncedSelectedFilters}
                  setIsFiltersDisabled={setIsFiltersDisabled}
                />
                <CommentSection
                  newCommentButton
                  apiGetComments={api.comments.getTopLevelCaseComments}
                  apiGetReplies={api.comments.getReplies}
                  apiDeleteComment={api.comments.removeComment}
                  apiEditComment={api.comments.editComment}
                  apiCreateComment={api.comments.createNewComment}
                  apiCreateReply={api.comments.createNewReply}
                  isCollapsible={false}
                  additionalRequestParams={{
                    complianceLogId,
                  }}
                  type={commentTypes.SCREENING}
                />
              </>
            ) : (
              <Spinner />
            )}
          </>
        )}
      </div>
    </CaseContext.Provider>
  );
};
CaseDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};
CaseDetails.defaultProps = {
  className: undefined,
};
export default CaseDetails;
