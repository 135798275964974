/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import toastr from 'toastr';
import api from '../../../api';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import IComplyForm from '../../../components/Form/IComplyForm';
import BareHeading from '../../../components/Headings/BareHeading';
import Section from '../../../components/Section';
import FieldArrayTable from '../../../components/Tables/FieldArrayTable';
import TableHeader from '../../../components/Tables/Table/components/TableHeader';
import useSelect from '../../../hooks/useSelect';
import useTable from '../../../hooks/useTable';
import { selectCountries } from '../../../store/selectors/global.selector';
import RiskLevelSection from './components/RiskLevelSection';
import RuleRow from './components/RiskScoringRow';
import './styles.scss';

/**
 * Risk Scoring page
 */

const mapRulesTypesOptions = {
  // Kyc Statuses
  review: 'Review',
  pending: 'Pending',
  escalated: 'Escalated',
  rejected: 'Rejected',
  approved: 'Approved',
  new: 'New',
  kYCRefresh: 'KYC Refresh',
  deleted: 'Deleted',

  // Risk Types
  sanction: 'Sanction',
  warning: 'Warning',
  fitnessProbity: 'Fitness + Probity',
  pep: 'PEP',
  pepClass1: 'PEP Class 1',
  pepClass2: 'PEP Class 2',
  pepClass3: 'PEP Class 3',
  pepClass4: 'PEP Class 4',
  adverseMedia: 'Adverse Media',
  adverseMediaFinancialCrime: 'Adverse Media Financial Crime',
  adverseMediaViolentCrime: 'Adverse Media Violent Crime',
  adverseMediaSexualCrime: 'Adverse Media Sexual Crime',
  adverseMediaTerrorism: 'Adverse Media Terrorism',
  adverseMediaFraud: 'Adverse Media Fraud',
  adverseMediaNarcotics: 'Adverse Media Narcotics',
  adverseMediaGeneral: 'Adverse Media General',
};

const prepareInitOptionsValue = options =>
  Object.entries(options).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`i_${key}`]: value ?? undefined,
    }),
    {},
  );

const prepareOptionsData = options =>
  Object.keys(options).reduce(
    (acc, key) => [
      ...acc,
      {
        id: key.split('i_')[1],
        name: mapRulesTypesOptions[key.split('i_')[1]],
      },
    ],
    [],
  );

const getSelectedOptionsByRule = (options, ruleId) =>
  Object.entries(options).reduce((acc, [key, value]) => {
    if (value?.id === ruleId) {
      return [...acc, key.split('i_')[1]];
    }
    return acc;
  }, []);

const getOptionsWithRule = options =>
  Object.entries(options).reduce(
    (acc, [itemId, value]) => ({
      ...acc,
      [itemId]: value?.id,
      // without number prop
    }),
    {},
  );

const changeOptions = (changedRulesArg, optionsName, changedOptions, initialValues, newValues) => {
  let changedRules = [...changedRulesArg];

  // optionValue is ruleId or undefined
  for (const [optionId, optionValue] of Object.entries(getOptionsWithRule(changedOptions))) {
    const option = optionId.split('i_')[1];
    // remove selected option from init rule if used before
    if (initialValues[optionsName]?.[optionId]) {
      const initRuleId = initialValues[optionsName][optionId].id;
      const isRuleChangedBefore = changedRules.some(({ id }) => id === initRuleId);

      if (isRuleChangedBefore) {
        changedRules = changedRules.map(rule => {
          if (rule.id === initRuleId)
            return {
              ...rule,
              countryTypes: null,
              types: (rule.types || []).filter(prevOption => prevOption !== option),
            };
          return rule;
        });
      } else {
        const changedRule = newValues.rules.find(({ id }) => id === initRuleId);
        changedRules = [
          ...changedRules,
          {
            ...changedRule,
            countryTypes: null,
            types: getSelectedOptionsByRule(initialValues[changedRule.scoringRuleType], initRuleId).filter(
              prevOption => prevOption !== option,
            ),
          },
        ];
      }
    }

    // add option to rule
    if (optionValue) {
      const isRuleChangedBefore = changedRules.some(({ id }) => id === optionValue);
      if (isRuleChangedBefore) {
        changedRules = changedRules.map(rule => {
          if (rule.id === optionValue)
            return {
              ...rule,
              countryTypes: null,
              types: [...(rule.types || []), option],
            };
          return rule;
        });
      } else {
        const changedRule = newValues.rules.find(({ id }) => id === optionValue);
        changedRules = [
          ...changedRules,
          {
            ...changedRule,
            countryTypes: null,
            types: [...getSelectedOptionsByRule(initialValues[changedRule.scoringRuleType], changedRule.id), option],
          },
        ];
      }
    }
  }

  return changedRules;
};

const RiskScoringPage = ({ className }) => {
  const [initialValues, setInitialValues] = useState({});
  const [initOptionsValues, setInitOptionsValues] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const hasNewRules = useRef(true);

  const classes = classNames('ickyc-page', 'ickyc-risk-scoring', className);

  const countries = useSelect(selectCountries) || [];

  const rulesOptionsData = useMemo(() => {
    if (!initOptionsValues || !countries.length) return { kycStatus: [], riskType: [], country: [] };

    const kycStatus = prepareOptionsData(initOptionsValues.kycStatus);
    const riskType = prepareOptionsData(initOptionsValues.riskType);
    return { kycStatus, riskType, country: countries };
  }, [initOptionsValues, countries]);

  const {
    records: rules,
    pagination,
    isLoading: isLoadingTableData,
    changeParams,
    afterAddRowFieldArray,
    afterRemoveRowFieldArray,
  } = useTable(api.administration.riskScoring.getScoringRules);

  const fetchRulesOptions = async () => {
    setIsLoading(true);

    api.administration.riskScoring
      .getRulesOptions()
      .then(({ data: { alreadyUsedCountries, alreadyUsedKycStatuses, alreadyUsedRiskTypes } }) => {
        const country = prepareInitOptionsValue(alreadyUsedCountries);
        const kycStatus = prepareInitOptionsValue(alreadyUsedKycStatuses);
        const riskType = prepareInitOptionsValue(alreadyUsedRiskTypes);
        setInitOptionsValues({ country, kycStatus, riskType });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (pagination?.currentPage !== undefined && hasNewRules.current) {
      fetchRulesOptions();
      hasNewRules.current = false;
    }
  }, [pagination.currentPage]);

  useEffect(() => {
    if (initOptionsValues && rules)
      setInitialValues({
        rules,
        ...initOptionsValues,
      });
  }, [rules, initOptionsValues]);

  const handleRemove = (newFormInitValues = null) => {
    afterRemoveRowFieldArray();
    if (newFormInitValues) setInitialValues(newFormInitValues);
  };

  const handleSubmit = async vals => {
    let changes = diff(initialValues, vals);

    changes.rules = changes.rules
      ? Object.entries(changes.rules).reduce((acc, [key, value]) => (value ? { ...acc, [key]: value } : { ...acc }), {})
      : undefined;

    if (!Object.keys(changes).length) return;

    setIsLoading(true);

    let changedRules = [];

    if (vals.rules?.some(rule => rule.id.startsWith('new_rule_'))) {
      hasNewRules.current = true;
    }

    if (changes.rules) {
      // changed basic info
      changedRules = Object.entries(changes.rules).reduce((acc, [index, newValues]) => {
        const changedRule = vals.rules[index];
        return [
          ...acc,
          {
            ...changedRule,
            ...newValues,
            ...(changedRule.scoringRuleType === 'country'
              ? {
                  countryTypes: getSelectedOptionsByRule(initialValues.country, changedRule.id).reduce(
                    (cAcc, countryId) => ({ ...cAcc, [countryId]: 'Existing' }),
                    {},
                  ),
                }
              : {
                  types: getSelectedOptionsByRule(initialValues[changedRule.scoringRuleType], changedRule.id),
                }),
          },
        ];
      }, []);
    }

    if (changes.country) {
      // optionValue is ruleId or undefined
      for (const [optionId, optionValue] of Object.entries(getOptionsWithRule(changes.country))) {
        const countryId = optionId.split('i_')[1];

        // remove selected option from init rule if used before
        if (initialValues.country?.[optionId]) {
          const initRuleId = initialValues.country[optionId].id;
          const isRuleChangedBefore = changedRules.some(({ id }) => id === initRuleId);

          if (isRuleChangedBefore) {
            changedRules = changedRules.map(rule => {
              if (rule.id === initRuleId)
                return {
                  ...rule,
                  countryTypes: {
                    ...rule.countryTypes,
                    [countryId]: 'Deleted',
                  },
                };
              return rule;
            });
          } else {
            const changedRule = vals.rules.find(({ id }) => id === initRuleId);
            changedRules = [
              ...changedRules,
              {
                ...changedRule,
                countryTypes: {
                  ...getSelectedOptionsByRule(initialValues.country, initRuleId).reduce(
                    (acc, country) => ({ ...acc, [country]: 'Existing' }),
                    {},
                  ),
                  [countryId]: 'Deleted',
                },
              },
            ];
          }
        }

        // add country to rule
        if (optionValue) {
          const isRuleChangedBefore = changedRules.some(({ id }) => id === optionValue);

          if (isRuleChangedBefore) {
            changedRules = changedRules.map(rule => {
              if (rule.id === optionValue)
                return {
                  ...rule,
                  countryTypes: {
                    ...rule.countryTypes,
                    [countryId]: 'New',
                  },
                };
              return rule;
            });
          } else {
            const changedRule = vals.rules.find(({ id }) => id === optionValue);
            changedRules = [
              ...changedRules,
              {
                ...changedRule,
                countryTypes: {
                  ...getSelectedOptionsByRule(initialValues.country, optionValue).reduce(
                    (objAcc, country) => ({ ...objAcc, [country]: 'Existing' }),
                    {},
                  ),
                  [countryId]: 'New',
                },
              },
            ];
          }
        }
      }
    }

    if (changes.riskType) changedRules = changeOptions(changedRules, 'riskType', changes.riskType, initialValues, vals);

    if (changes.kycStatus)
      changedRules = changeOptions(changedRules, 'kycStatus', changes.kycStatus, initialValues, vals);

    const newRulesInfo = changedRules.reduce(
      (acc, rule) => {
        if (rule.id?.startsWith('new_rule_')) {
          const isFirstNewRule = acc.count === 0;
          return {
            ...acc,
            count: acc.count + 1,
            firstNewRuleIndex: isFirstNewRule ? +rule.id.split('new_rule_')[1] : acc.firstNewRuleIndex,
          };
        }
        return { ...acc };
      },
      { count: 0, firstNewRuleIndex: 0 },
    );

    // remove temporary ids
    changedRules = changedRules.map(rule =>
      rule.id?.includes('new_rule_')
        ? {
            ...rule,
            id: '',
          }
        : rule,
    );

    // set countryTypes to null if empty
    changedRules = changedRules.map(rule => ({
      ...rule,
      countryTypes:
        rule.countryTypes && typeof rule.countryTypes === 'object' && !Array.isArray(rule.countryTypes)
          ? rule.countryTypes
          : null,
    }));

    api.administration.riskScoring
      .updateRules(changedRules)
      .then(({ data }) => {
        if (data.length) {
          // if there is a new rule
          const newIds = data.map(rule => rule.id); // ids for new rules
          const tempIds = vals.rules.filter(rule => rule.id.startsWith('new_rule_')).map(r => r.id); // temporary ids

          const mapTempToNewIds = tempIds.reduce((acc, tempId, index) => ({ ...acc, [tempId]: newIds[index] }), {});

          const newRules = vals.rules.map(rule => {
            if (rule.id.startsWith('new_rule_')) return data.find(r => r.id === mapTempToNewIds[rule.id]);
            return rule;
          });

          const options = {
            country: null,
            riskType: null,
            kycStatus: null,
          };

          const optionsNames = ['country', 'riskType', 'kycStatus'];
          optionsNames.forEach(optionName => {
            options[optionName] = Object.entries(vals[optionName]).reduce((acc, [key, value]) => {
              if (value?.id?.includes('new_rule_'))
                return {
                  ...acc,
                  [key]: value
                    ? {
                        ...value,
                        id: mapTempToNewIds[value.id],
                      }
                    : undefined,
                };

              const hasNumber = !!value?.number;
              const hasNewNumber = hasNumber && value.number >= newRulesInfo.firstNewRuleIndex;

              return {
                ...acc,
                [key]: value
                  ? {
                      ...value,
                      ...(hasNewNumber && {
                        number: value.number + newRulesInfo.sum,
                      }),
                    }
                  : undefined,
              };
            }, {});
          });

          setInitialValues({ rules: newRules, ...options });
        } else {
          setInitialValues(vals);
        }

        toastr.success('Succesfully updated Rules');
      })
      .catch(err => {
        toastr.error('Error occurred while updating Rules');
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errData.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const TableControl = () => {
    const {
      fields: { push },
    } = useFieldArray('rules');
    const { values } = useFormState();

    return (
      <Section>
        <div className="ickyc-section__subheader">
          <h2>Scoring Rules</h2>
          <PrimaryButton
            onClick={() => {
              const { currentPage, limit } = pagination;
              const elementIndex = (currentPage - 1) * limit + values.rules.length + 1;

              push({
                id: `new_rule_${elementIndex}`, // temporary id, starts with new_rule_
                scoringRuleType: 'country',
                scoringRuleOperation: 'addition',
                score: 100,
                active: false,
              });

              afterAddRowFieldArray();
            }}
          >
            + Add Scoring Rule
          </PrimaryButton>
        </div>
      </Section>
    );
  };

  return (
    <div className={classes}>
      <BareHeading title="Risk Scoring" />
      <div className="ickyc-page__content">
        <IComplyForm mutators={{ ...arrayMutators }} onSubmit={handleSubmit} initialValues={initialValues} showControls>
          <div className="ickyc-scoring-rules-table">
            <FieldArrayTable
              withPagination
              updating={isLoadingTableData || isLoading}
              pagination={pagination}
              fieldName="rules"
              rulesOptions={rulesOptionsData}
              startIndex={pagination?.skip}
              tableControls={TableControl}
              tableRow={RuleRow}
              headerRow={TableHeader}
              handleParamsChange={changeParams}
              onControlsClick={flag => setIsLoading(flag)}
              onRemove={handleRemove}
              headerData={{
                items: [
                  { type: 'index' },
                  { type: 'scoringRuleType', label: 'If' },
                  { type: 'scoringRuleOperation', label: 'Then' },
                  { type: 'score' },
                  { type: 'active', label: 'Status' },
                  { type: 'remove' },
                ],
              }}
              noResultMessage="No Rules To Show"
            />
          </div>
        </IComplyForm>
        <RiskLevelSection />
      </div>
    </div>
  );
};

RiskScoringPage.propTypes = {
  className: PropTypes.string,
};

RiskScoringPage.defaultProps = {
  className: undefined,
};
export default RiskScoringPage;
