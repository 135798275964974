import React from 'react';

export default () => (
  <svg viewBox="0 0 245.83 235.69" fill="currentColor">
    <path d="M138.67 83c2.8 1.61 5.4 3.54 7.75 5.76.96-2.37 2.14-4.65 3.52-6.79-20.18-17.53-50.75-15.38-68.28 4.8s-15.38 50.75 4.8 68.28c1.83 1.59 3.77 3.03 5.81 4.33v67.2C32.63 212.06-3.96 151.94 10.55 92.3 22.51 43.15 66.14 8.27 116.71 7.43h1.53c40.49.1 77.72 22.22 97.15 57.74 2.89-.48 5.86-.28 8.66.6C204.67 26.78 165.47 1.56 121.95.1V0l-3.71.01C52.77.18-.17 53.4 0 118.87c.15 56.38 39.98 104.85 95.25 115.93l4.43.89v-80.54l-1.86-1.07c-19.67-11.2-26.54-36.23-15.33-55.91 11.2-19.67 36.23-26.54 55.91-15.33.09.05.18.1.27.16z" />
    <path d="M224.97 168.89l-.24.08c-2.02.66-4.12 1-6.25 1.02-.67 0-1.33-.04-1.99-.11a111.002 111.002 0 01-72.12 56.67v-67.28a47.52 47.52 0 006.48-4.99c-1.46-2.08-2.73-4.3-3.78-6.61a40.95 40.95 0 01-8.26 6.32l-1.84 1.07v80.62l4.43-.89a117.764 117.764 0 0075.3-50.63c3.28-4.93 6.18-10.1 8.7-15.46-.14.07-.28.13-.43.19z" />
    <g>
      <g transform="translate(150.242 69.66)">
        <path d="M80.27 47.8l11.26-11.26c3.8-3.83 5.05-9.51 3.22-14.58A35.352 35.352 0 0073.64.83a13.972 13.972 0 00-14.58 3.22L47.79 15.32 36.53 4.05C32.7.25 27.02-1 21.95.83A35.352 35.352 0 00.83 21.95a14.005 14.005 0 003.21 14.59L15.31 47.8 4.04 59.06A13.993 13.993 0 00.83 73.64a35.3 35.3 0 0021.01 21.02c1.54.57 3.18.84 4.83.8 3.68-.01 7.22-1.45 9.86-4.02l11.26-11.16 11.26 11.26c2.63 2.59 6.17 4.03 9.86 4.02 1.64-.02 3.27-.29 4.83-.8a35.29 35.29 0 0021.02-21.02c1.83-5.08.58-10.75-3.22-14.58L80.27 47.8zM8.57 32.01a7.649 7.649 0 01-1.71-7.95 28.8 28.8 0 0117.2-17.2 7.74 7.74 0 017.95 1.71l11.26 11.26-23.43 23.44L8.57 32.01zM32 87.02a7.649 7.649 0 01-7.95 1.71 28.8 28.8 0 01-17.2-17.2 7.74 7.74 0 011.71-7.95l55.02-55a7.505 7.505 0 015.33-2.21c.9-.03 1.79.14 2.61.5a28.8 28.8 0 0117.2 17.2 7.74 7.74 0 01-1.71 7.95L32 87.02zm56.72-15.49a28.8 28.8 0 01-17.2 17.2 7.74 7.74 0 01-7.95-1.71L52.31 75.76l23.43-23.43L87 63.59a7.62 7.62 0 011.72 7.94z" />
        <circle cx="61.54" cy="43.17" r="4.12" />
        <circle cx="43.19" cy="61.6" r="4.12" />
        <path d="M55.23 49.51a4.122 4.122 0 100 5.83 4.122 4.122 0 000-5.83zM55.23 31.11a4.122 4.122 0 100 5.83 4.122 4.122 0 000-5.83z" />
        <circle cx="34.04" cy="52.43" r="4.12" />
        <circle cx="43.15" cy="43.21" r="4.12" />
      </g>
    </g>
  </svg>
);
