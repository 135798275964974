const handleApplyToAllPortals = values => {
  const { domain, applyToAll, ...rest } = values;

  if (domain === 'Apply to all Portals') {
    return { ...rest, applyToAll: true };
  }

  return { domain, applyToAll: false, ...rest };
};

export default {
  handleApplyToAllPortals,
};
