import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: 'currentColor', stroke: 'currentColor' }}
    width="120"
    height="120"
  >
    <path d="M95.625 48.75h-22.5a1.874 1.874 0 01-1.875-1.875v-22.5A24.375 24.375 0 1195.625 48.75zM75 45h20.625A20.625 20.625 0 1075 24.375z" />
    <path d="M105 35.625H86.25a3.754 3.754 0 01-3.75-3.75v-11.25a3.754 3.754 0 013.75-3.75H105a3.754 3.754 0 013.75 3.75v11.25a3.754 3.754 0 01-3.75 3.75zm0-3.75zm-18.75-11.25v11.25h18.744l.006-11.25z" />
    <path d="M91.875 20.625A1.874 1.874 0 0190 18.75v-5.625a1.875 1.875 0 013.75 0v5.625a1.874 1.874 0 01-1.875 1.875z" />
    <path d="M99.375 15h-7.5a1.875 1.875 0 010-3.75h7.5a1.875 1.875 0 010 3.75z" />
    <path d="M99.375 20.625A1.874 1.874 0 0197.5 18.75v-5.625a1.875 1.875 0 013.75 0v5.625a1.874 1.874 0 01-1.875 1.875zM52.5 15H26.25a1.875 1.875 0 010-3.75H52.5a1.875 1.875 0 010 3.75z" />
    <path d="M52.5 28.125a1.874 1.874 0 01-1.875-1.875V13.125a1.875 1.875 0 013.75 0V26.25a1.874 1.874 0 01-1.875 1.875zM26.25 28.125a1.874 1.874 0 01-1.875-1.875V13.125a1.875 1.875 0 013.75 0V26.25a1.874 1.874 0 01-1.875 1.875z" />
    <path d="M63.75 28.125H15a1.875 1.875 0 010-3.75h48.75a1.875 1.875 0 010 3.75z" />
    <path d="M63.75 120a1.873 1.873 0 01-1.875-1.875V26.25a1.875 1.875 0 013.75 0v91.875A1.873 1.873 0 0163.75 120zM15 120a1.873 1.873 0 01-1.875-1.875V26.25a1.875 1.875 0 013.75 0v91.875A1.873 1.873 0 0115 120zM39.375 15a1.874 1.874 0 01-1.875-1.875V1.875a1.875 1.875 0 013.75 0v11.25A1.874 1.874 0 0139.375 15z" />
    <path d="M76.875 120h-75a1.875 1.875 0 110-3.75h75a1.875 1.875 0 110 3.75z" />
    <path d="M5.625 120a1.873 1.873 0 01-1.875-1.875v-7.5a1.875 1.875 0 013.75 0v7.5A1.873 1.873 0 015.625 120z" />
    <path d="M5.625 112.5a5.625 5.625 0 115.625-5.625 5.632 5.632 0 01-5.625 5.625zm0-7.5a1.875 1.875 0 101.875 1.875A1.876 1.876 0 005.625 105zM73.125 120a1.873 1.873 0 01-1.875-1.875v-7.5a1.875 1.875 0 113.75 0v7.5A1.873 1.873 0 0173.125 120z" />
    <path d="M73.125 112.5a5.625 5.625 0 115.625-5.625 5.632 5.632 0 01-5.625 5.625zm0-7.5A1.875 1.875 0 1075 106.875 1.876 1.876 0 0073.125 105zM28.125 110.625a1.873 1.873 0 01-1.875-1.875v-75a1.875 1.875 0 013.75 0v75a1.873 1.873 0 01-1.875 1.875z" />
    <g>
      <path d="M39.377 105a1.873 1.873 0 01-1.876-1.875V33.75a1.875 1.875 0 013.75 0v69.375A1.873 1.873 0 0139.377 105z" />
    </g>
    <g>
      <path d="M50.629 110.625a1.873 1.873 0 01-1.877-1.875v-75a1.875 1.875 0 013.75 0v75a1.873 1.873 0 01-1.873 1.875z" />
    </g>
    <g>
      <path d="M39.375 120a1.873 1.873 0 01-1.875-1.875V112.5a1.875 1.875 0 113.75 0v5.625A1.873 1.873 0 0139.375 120z" />
    </g>
    <g>
      <path d="M39.377 28.125a1.874 1.874 0 01-1.876-1.875v-5.625a1.875 1.875 0 013.75 0v5.625a1.874 1.874 0 01-1.874 1.875z" />
    </g>
  </svg>
);
