// import CachedCollapsible from '../../scenes/Kyc/EntityManagement/components/CachedCollapsible';
// import CollapsibleSection from './components/CollapsibleSection';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import api from '../../../../../api';
import Loader from '../../../../../components/Loader';
import Spinner from '../../../../../components/Spinner';
import useSelect from '../../../../../hooks/useSelect';
import ProtectedComponent from '../../../../../router/components/ProtectedComponent';
import { selectUserAccessByLicense } from '../../../../../store/selectors/auth.selector';
import authEnums from '../../../../../utilities/enums/authEnums';
import { RCUContext } from '../../../../../utilities/services/contexts';
import AddAMLCase from './components/AddAMLCase';
import Automation from './components/Automation';
import CommentRow from './components/CommentRow';
import DataCollection from './components/DataCollection';
import GeneralSettings from './components/GeneralSettings';
import KYCRequests from './components/KYCRequests';
import './styles.scss';

// eslint-disable-next-line no-useless-escape

const KYCServicesV3 = ({ entityID, screeningProfileId, setDisableSubmitButton }) => {
  const [selectedDomainGroup, setSelectedDomainGroup] = useState();
  const [domainsConfigs, setDomainsConfigs] = useState([]);
  const [isLoadingSettings, setIsLoadingSettings] = useState(true);
  const [initialConfiguration, setInitialConfiguration] = useState({});
  const [isLoadingDomains, setIsLoadingDomains] = useState(true);
  const [isLoadingAutomation, setIsLoadingAutomations] = useState(true);
  const [errorMessage, setErrorMesassage] = useState(null);
  const [globalErrorMessage, setGlobalErrorMessage] = useState();
  const platformAccessByLicence = useSelect(selectUserAccessByLicense);

  const {
    values: {
      generalSettings: { domain, emailMessage },
      kycRequests: {
        biometricAuthentication: { isEnabled: isEnabledBiometric },
        documentAuthentication: { isEnabled: isEnabledDocumentAuth },
        identityVerification: { isEnabled: isEnabledIdentityVerif },
      },
      dataCollection: { requestSupportingDocuments: isEnabledSuporting },
    },
  } = useFormState();

  const { batch, change, submit } = useForm();

  const listItems = useMemo(() => {
    let value = '';
    if (isEnabledIdentityVerif) value += '<li>Identity Verification</li>';
    if (isEnabledDocumentAuth) value += `<li>Document Authentication</li>`;
    if (isEnabledBiometric) value += `<li>Biometric Authentication</li>`;
    if (isEnabledSuporting) value += `<li>Supporting Document</li>`;
    return value;
  }, [isEnabledIdentityVerif, isEnabledDocumentAuth, isEnabledBiometric, isEnabledSuporting]);

  useEffect(() => {
    const handleListItemsChange = () => {
      batch(() => {
        change(
          'generalSettings.emailMessage',
          JSON.parse(JSON.stringify(emailMessage)?.replace(/<ul.*<\/ul>/, `<ul>${listItems}</ul>`)),
        );
      });
    };

    if (emailMessage && listItems) handleListItemsChange();
  }, [isEnabledSuporting, isEnabledDocumentAuth, isEnabledBiometric, isEnabledIdentityVerif]);

  const {
    supportingDocumentPackagesEnabled: supportingDocsVisible,
    documentAuthenticationEnabled: docsAuthVisible,
    biometricAuthenticationEnabled: biometricAuthVisible,
    emailMessage: emailMessageInitial,
  } = initialConfiguration;

  useEffect(() => {
    if (JSON.stringify(initialConfiguration) !== '{}') {
      if (!supportingDocsVisible) {
        batch(() => {
          change('kycRequests.biometricAuthentication.isEnabled', false);
        }, []);
      }
      if (!docsAuthVisible) {
        batch(() => {
          change('kycRequests.documentAuthentication.isEnabled', false);
        }, []);
      }
      if (!biometricAuthVisible) {
        batch(() => {
          change('kycRequests.supportingDocuments.isEnabled', false);
        }, []);
      }
      if (emailMessageInitial) {
        batch(() => {
          change(
            'generalSettings.emailMessage',
            JSON.parse(JSON.stringify(emailMessageInitial).replace(/<ul.*<\/ul>/, `<ul>${listItems}</ul>`)),
          );
        });
      }
    }
  }, [initialConfiguration]);

  useEffect(() => {
    if (domain && selectedDomainGroup?.id) {
      const newDomainGroup = domainsConfigs.find(domainConfig => domainConfig.domains.some(d => d.url === domain));
      if (selectedDomainGroup.id !== newDomainGroup?.id) {
        setSelectedDomainGroup(newDomainGroup);
      }
    }
  }, [domain, domainsConfigs, selectedDomainGroup]);

  useEffect(() => {
    const reloadData = () => {
      setIsLoadingSettings(true);
      api.kyc.entityManagement
        .getNpSettingsForRCU(selectedDomainGroup.id, entityID, domain)
        .then(({ data: responseData }) => {
          setInitialConfiguration(responseData);
        })
        .catch(err => {
          console.log(err);
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              setErrorMesassage(Array.isArray(errData.message) ? errData.message.join('') : errData.message);
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoadingSettings(false);
        });
    };

    if (selectedDomainGroup?.id && domain) reloadData();
  }, [selectedDomainGroup, entityID, domain]);

  useEffect(() => {
    if (selectedDomainGroup) {
      const defaultDomain = selectedDomainGroup.domains.find(dom => dom.isDefault);
      change('generalSettings.domain', defaultDomain ? defaultDomain.url : selectedDomainGroup[0]?.url);
    }
  }, [selectedDomainGroup, change]);
  //
  const mapJurisdictionEntity = data => {
    return data.reduce(
      (accumulator, { countryId, autoAssignEntityProfileUserId }) => ({
        ...accumulator,
        [`country${countryId}`]: {
          autoAssignEntityProfileUserId,
        },
      }),
      {},
    );
  };
  const mapidentityAuthenticationEvent = data => {
    return data.reduce(
      (accumulator, { countryId, autoAssignIdentityAuthenticationUserId }) => ({
        ...accumulator,
        [`country${countryId}`]: {
          autoAssignIdentityAuthenticationUserId,
        },
      }),
      {},
    );
  };

  const mapdocumentAuthenticationEvent = data => {
    return data.reduce(
      (accumulator, { countryId, autoAssignDocumentAuthenticationUserId }) => ({
        ...accumulator,
        [`country${countryId}`]: {
          autoAssignDocumentAuthenticationUserId,
        },
      }),
      {},
    );
  };

  const mapbiometricAuthenticationEvent = data => {
    return data.reduce(
      (accumulator, { countryId, autoAssignBiometricAuthenticationUserId }) => ({
        ...accumulator,
        [`country${countryId}`]: {
          autoAssignBiometricAuthenticationUserId,
        },
      }),
      {},
    );
  };
  useEffect(() => {
    const loadDomains = () => {
      setIsLoadingDomains(true);
      api.kyc.entityManagement
        .getDomains()
        .then(({ data: responseData }) => {
          if (!responseData.length) {
            setDisableSubmitButton(true);
            setGlobalErrorMessage('Please create a portal that is active in order to process the RCU request');
          }

          let configurations = responseData;
          let defaultConfiguration = responseData.find(config => config.isDefault);
          if (!defaultConfiguration) {
            configurations[0].isDefault = true;
            defaultConfiguration = configurations[0];
          }

          let defaultDomain = defaultConfiguration?.domains.find(domain => domain.isDefault);
          if (!defaultDomain) {
            defaultConfiguration.domains[0].isDefault = true;
          }

          setSelectedDomainGroup(defaultConfiguration);
          setDomainsConfigs(configurations);
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoadingDomains(false);
        });
    };
    const loadAutomation = () => {
      setIsLoadingAutomations(true);

      api.administration.automations
        .getAutomationsConfiguration()
        .then(
          ({
            data: {
              automationsJurisdictions,
              automationsSupportingDocs,
              biometricAuthenticationAutoAcceptance,
              documentAuthenticationAutoAcceptance,
              identityAuthenticationAutoAcceptance,
              periodicReviewInterval,
              periodicReviewValue,
              setProfileStatusTo,
              setRiskLevelTo,
              autoAssignSupportingDocumentPackagesEnabled,
              autoAssignIdentityAuthenticationEnabled,
              autoAssignBiometricAuthenticationEnabled,
              autoAssignDocumentAuthenticationEnabled,
              autoAssignEntityProfileEnabled,
              changeStatusForApprovedProfile,
              approvedProfileStatus,
              enablePeriodicReview,
              autoUpdateEntityStatus,
              autoUpdateRiskLevel,
            },
          }) => {
            batch(() => {
              change('initialyFetchedData', { automationsJurisdictions, automationsSupportingDocs });
            }, []);
            const initDocsConfig = automationsSupportingDocs.reduce(
              (accumulator, { documentTypeName, autoAssignedUserId, packageId }) => ({
                ...accumulator,
                [`document${packageId}`]: {
                  documentTypeName,
                  autoAssignedUserId,
                  packageId,
                },
              }),
              {},
            );
            batch(() => {
              change('entityProfile', mapJurisdictionEntity(automationsJurisdictions));
              change('identityAuthenticationEvent', mapidentityAuthenticationEvent(automationsJurisdictions));
              change('documentAuthenticationEvent', mapdocumentAuthenticationEvent(automationsJurisdictions));
              change('biometricAuthenticationEvent', mapbiometricAuthenticationEvent(automationsJurisdictions));
              change('automationsSupportingDocsTransformed', initDocsConfig);
              change('automationsSupportingDocs', automationsSupportingDocs);
              change('biometricAuthenticationAutoAcceptance', biometricAuthenticationAutoAcceptance);
              change('documentAuthenticationAutoAcceptance', documentAuthenticationAutoAcceptance);
              change('identityAuthenticationAutoAcceptance', identityAuthenticationAutoAcceptance);
              change('periodicReviewInterval', periodicReviewInterval);
              change('periodicReviewValue', periodicReviewValue);
              change('setProfileStatusTo', setProfileStatusTo);
              change('setRiskLevelTo', setRiskLevelTo);
              change('autoAssignSupportingDocumentPackagesEnabled', autoAssignSupportingDocumentPackagesEnabled);
              change('autoAssignIdentityAuthenticationEnabled', autoAssignIdentityAuthenticationEnabled);
              change('autoAssignBiometricAuthenticationEnabled', autoAssignBiometricAuthenticationEnabled);
              change('autoAssignDocumentAuthenticationEnabled', autoAssignDocumentAuthenticationEnabled);
              change('autoAssignEntityProfileEnabled', autoAssignEntityProfileEnabled);
              change('changeStatusForApprovedProfile', changeStatusForApprovedProfile);
              change('approvedProfileStatus', approvedProfileStatus);
              change('enablePeriodicReview', enablePeriodicReview);
              change('autoUpdateEntityStatus', autoUpdateEntityStatus);
              change('autoUpdateRiskLevel', autoUpdateRiskLevel);
            }, []);

            // setIsLoading(false);
          },
        )
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoadingAutomations(false);
        });
    };
    loadDomains();
    if (!platformAccessByLicence.some(elm => elm === authEnums.ACCESS_BY_LICENCE.AUTOMATIONS)) {
      setIsLoadingAutomations(false);
      return;
    }
    loadAutomation();
  }, []);

  const mappedDomainsList = useMemo(() => {
    return domainsConfigs
      .reduce((acc, v) => [...acc, ...v.domains], [])
      .map(domainEl => ({
        id: domainEl.url,
        value: domainEl.url,
        label: domainEl.url,
      }));
  }, [domainsConfigs]);

  if (globalErrorMessage) {
    return <div className="ickyc-form-modal__submissionError">{globalErrorMessage}</div>;
  }

  return (
    <div className="ickyc-kyc-services-v3">
      {isLoadingDomains ? (
        <Spinner />
      ) : (
        <>
          {isLoadingSettings && <Loader />}
          <RCUContext.Provider value={{ initialConfiguration }}>
            <GeneralSettings domains={mappedDomainsList} errMessage={errorMessage} />
            <DataCollection />
            <KYCRequests />
            <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}>
              <AddAMLCase entityID={entityID} screeningProfileId={screeningProfileId} />
            </ProtectedComponent>
            <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.AUTOMATIONS}>
              <Automation isLoadingAutomation={isLoadingAutomation} />
            </ProtectedComponent>
            <CommentRow />
          </RCUContext.Provider>
        </>
      )}
    </div>
  );
};
KYCServicesV3.propTypes = {
  entityID: PropTypes.string,
  screeningProfileId: PropTypes.string,
};
KYCServicesV3.defaultProps = {
  entityID: null,
  screeningProfileId: null,
};
export default KYCServicesV3;
