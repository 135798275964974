import api from 'api';
import classNames from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import BareHeading from 'components/Headings/BareHeading';
import InputField from 'components/InputField';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import EmailMessage from 'scenes/Administration/Emails/components/EmailMessage';
import toastr from 'toastr';
import setClassSuffix from 'utilities/services/ClassManager';
import validators from 'utilities/services/validators';
import EmailEditorField from './components/EmailEditorField';
import './styles.scss';
import utils from './utils';

const EmailTemplateEditorPage = ({ className }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const emailEditorRef = useRef(null);
  const baseClass = 'ickyc-email-template-editor-page';
  const classes = classNames('ickyc-page', baseClass, className);
  const type = location?.state.type;
  const id = location?.state.id;
  const [initialValues, setInitialValues] = useState({});
  const [messagePlaceholders, setMessagePlaceholders] = useState();
  const [editorPlaceholders, setEditorPlaceholders] = useState();
  const [subjectPlaceholders, setSubjectPlaceholders] = useState();
  const { placeholderDescriptions } = utils;

  const saveTemplate = useCallback(async ({ template, ...rest }) => {
    const { html, design } = template;

    try {
      setIsUpdating(true);
      await api.internal.emailTemplates.updateEmailTemplate({
        jsonConfig: Buffer.from(JSON.stringify(design)).toString('base64'),
        htmlContent: html,
        ...rest,
      });

      toastr.success('Successfully saved template');
    } catch (err) {
      console.error(err);
      toastr.error('Error occurred while updating template ');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const placeholderTooltip = useCallback(
    placeholders =>
      placeholders?.reduce(
        (acc, placeholder) => `${acc}\n ${placeholderDescriptions[placeholder]}`,
        'Use following placeholders to customize:',
      ),
    [placeholderDescriptions],
  );

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const {
          data: {
            jsonConfig,
            validPlaceholders: { editor, message, subject },
            ...rest
          },
        } = await api.internal.emailTemplates.getEmailTemplate(id);
        const jsonStr = Buffer.from(jsonConfig, 'base64').toString('utf-8');
        setInitialValues({ template: { design: JSON.parse(jsonStr) }, ...rest });

        setMessagePlaceholders(message);
        setEditorPlaceholders(editor);
        setSubjectPlaceholders(subject);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  const setSuffix = setClassSuffix(baseClass);

  return (
    <div className={classes}>
      <Form
        initialValues={initialValues}
        onSubmit={saveTemplate}
        validate={
          !!messagePlaceholders?.length &&
          validators.someTypeOfLinkIsRequiredInEmailTemplate(['{{LINK}}', '{{PLAIN LINK}}', 'class="v-button"'])
        }
        render={({ handleSubmit, error, submitFailed }) => (
          <form onSubmit={handleSubmit}>
            <BareHeading title={`Email Templates / ${type}`}>
              <PrimaryButton disabled={isLoading} type="submit">
                Save Template
              </PrimaryButton>
            </BareHeading>

            <Field
              label="Email Subject"
              name="subject"
              component={InputField}
              validate={validators.composeValidators(
                validators.required(),
                validators.checkForPlaceholdersDuplicates(subjectPlaceholders),
                validators.checkForUnallowedPlaceholders(subjectPlaceholders),
              )}
              className={`${setSuffix('__input')} ${setSuffix('__subject')}`}
              hint={placeholderTooltip(subjectPlaceholders)}
            />
            {!!messagePlaceholders?.length && (
              <EmailMessage
                label="Email Message"
                className={setSuffix('__input')}
                messageName="message"
                placeholders={messagePlaceholders}
              />
            )}

            <Field
              component={EmailEditorField}
              name="template"
              label="Email Template"
              setIsLoading={setIsLoading}
              className={setSuffix('__input')}
              ref={emailEditorRef}
              validate={validators.validateEmailEditor(editorPlaceholders)}
              tooltip={placeholderTooltip(editorPlaceholders)}
            />

            {error && submitFailed && (
              <div className={setSuffix('__submissionError')}>
                <p>{error}</p>
              </div>
            )}
            {(isLoading || isUpdating) && <Loader />}
          </form>
        )}
      />
    </div>
  );
};

EmailTemplateEditorPage.propTypes = {
  className: PropTypes.string,
};

EmailTemplateEditorPage.defaultProps = {
  className: undefined,
};
export default EmailTemplateEditorPage;
