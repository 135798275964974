import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toastr from 'toastr';
import api from '../../../../api';
import Select from '../../../../components/Select';
import SelectFilterContent from '../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../components/Select/components/SelectTrigger';
import bus from '../../../../modules/bus';
import enums from '../../../../utilities/enums';
import { EntityContext } from '../../../../utilities/services/contexts';
import './index.scss';

const AssignToUserDropdown = React.memo(
  ({
    assignedUserId,
    assignEvent,
    eventId,
    onChange,
    assignCase,
    complianceLogId,
    assignUser,
    disabled,
    renderAsPortal,
    label,
    preview,
    hint,
    listingId,
    assignlisting,
    hitId,
    logId,
    useFullValue,
    className,
    ...rest
  }) => {
    const { entityId } = useContext(EntityContext) || {};
    const [isChanging, setIsChanging] = useState(false);
    const usersToAssign = useSelector(state =>
      state.global.usersToAssign.map(({ id: uId, name }) => ({
        label: name,
        value: uId,
        id: uId,
      })),
    );

    const [selectedUser, setSelectedUser] = useState(usersToAssign.find(el => el.value === assignedUserId) || {});

    useEffect(() => {
      if (assignedUserId && !selectedUser?.id) {
        setSelectedUser(usersToAssign.find(el => el.value === assignedUserId) || {});
      }
    }, [usersToAssign]);

    const callback = useCallback(
      newUser => {
        setSelectedUser(newUser);
        onChange(newUser);
      },
      [onChange],
    );

    const assingEventToUser = useCallback(
      newUser => {
        setIsChanging(true);
        api.kyc
          .assingEventToUser(entityId, eventId, newUser.value)
          .then(response => {
            bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, response.data);
            toastr.success('Event is Succesfully Assigned to Entity');
            callback(newUser);
          })
          .finally(() => {
            setIsChanging(false);
          });
      },
      [callback, entityId, eventId],
    );

    const assignUserToEntity = useCallback(
      newUser => {
        setIsChanging(true);
        api.kyc
          .assignEntityToUser(entityId, newUser.value)
          .then(response => {
            bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, response.data);
            toastr.success('User is Succesfully Assigned to Entity');
            callback(newUser);
          })
          .finally(() => {
            setIsChanging(false);
          });
      },
      [entityId, callback],
    );

    const assignCaseToUser = useCallback(
      newUser => {
        setIsChanging(true);

        api.kyc.caseManagement
          .assignCaseToUser(complianceLogId, newUser.value)
          .then(response => {
            bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, response.data);
            toastr.success('Case is Succesfully Assigned to Entity');
            callback(newUser);
          })
          .finally(() => {
            setIsChanging(false);
          });
      },
      [complianceLogId, callback],
    );

    const assingUserToListing = useCallback(
      newUser => {
        setIsChanging(true);

        api.kyc
          .assingUserToListing(logId, hitId, listingId, newUser.value)
          .then(response => {
            bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, response.data);
            toastr.success('Listing is Succesfully Assigned to User');
            callback(newUser);
          })
          .finally(() => {
            setIsChanging(false);
          });
      },
      [logId, hitId, listingId, callback],
    );

    const handleUserAssign = newUser => {
      if (selectedUser.value === newUser.value) return;
      if (assignlisting) {
        assingUserToListing(newUser);
      }
      if (assignCase) {
        assignCaseToUser(newUser);
      }
      if (assignUser) {
        assignUserToEntity(newUser);
      }
      if (assignEvent) {
        assingEventToUser(newUser);
      }
    };
    return (
      <Select
        value={selectedUser}
        onChange={handleUserAssign}
        loading={isChanging}
        options={usersToAssign}
        useFullValue={useFullValue}
        renderAsPortal={renderAsPortal}
        disabled={disabled}
        label={label}
        preview={preview}
        hint={hint}
        Trigger={<SelectTrigger placeholder="Choose Assigned To" />}
        Content={<SelectFilterContent />}
        className={className}
        {...rest}
      />
    );
  },
);
AssignToUserDropdown.propTypes = {
  assignedUserId: PropTypes.string,
  assignEvent: PropTypes.bool,
  eventId: PropTypes.string,
  onChange: PropTypes.func,
  assignCase: PropTypes.bool,
  assignUser: PropTypes.bool,
  complianceLogId: PropTypes.string,
  disabled: PropTypes.bool,
  renderAsPortal: PropTypes.bool,
  label: PropTypes.string,
  preview: PropTypes.bool,
  hint: PropTypes.string,
  listingId: PropTypes.string,
  assignlisting: PropTypes.bool,
  hitId: PropTypes.string,
  logId: PropTypes.string,
  input: PropTypes.shape(PropTypes.object),
  useFullValue: PropTypes.bool,
  className: PropTypes.string,
};
AssignToUserDropdown.defaultProps = {
  assignedUserId: undefined,
  assignCase: false,
  assignEvent: false,
  eventId: undefined,
  complianceLogId: undefined,
  assignUser: undefined,
  onChange: () => {},
  renderAsPortal: false,
  disabled: false,
  label: undefined,
  preview: false,
  hint: undefined,
  listingId: undefined,
  assignlisting: false,
  hitId: undefined,
  logId: undefined,
  input: {},
  useFullValue: true,
  className: undefined,
};

export default AssignToUserDropdown;
