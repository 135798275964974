import api from 'api';
import classNames from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import BareHeading from 'components/Headings/BareHeading';
import WizardFormModal from 'components/Modal/WizardFormModal';
import WizardFormPage from 'components/Modal/WizardFormModal/components/WizardFormPage';
import Section from 'components/Section';
import Table from 'components/Tables/Table';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import ToggleField from 'components/ToggleField';
import { FORM_ERROR } from 'final-form';
import useTable from 'hooks/useTable';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { saveDefaultScreeningProfile } from 'store/actions/global.action';
import toastr from 'toastr';
import enums from 'utilities/enums';
import TableHeaders from 'utilities/services/TableHeaders';
import { PermissionGroupContext } from 'utilities/services/contexts';
import RiskProfilesTableRow from './components/RiskProfilesTableRow';
import CaseAssignement from './components/steps/CaseAssignement';
import RiskScreeningParameters from './components/steps/RiskScreeningParameters';
import SearchCriteriaConfiguration from './components/steps/SearchCriteriaConfiguration';
import './styles.scss';
import useRiskScreeningUtilities from './useRiskScreeningUtilities';

const RiskScreeningPage = ({ className }) => {
  const [updating, setUpdating] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const {
    removeRow,
    records: riskProfiles,
    addRow,
    updateRowData,
    changeParams,
    pagination,
    params,
    isLoading,
  } = useTable(api.administration.getRiskScreening, {});
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const pageRef = useRef(null);
  const classes = classNames(['ickyc-page', 'ickyc-risk-screening', className]);

  const { defaultValues, prefillForm, parseSubmitObject } = useRiskScreeningUtilities();
  const [initialValues, setInitialValues] = useState(defaultValues);

  const handleSubmit = async data => {
    const submitObj = parseSubmitObject(data);
    try {
      if (submitObj.id) {
        const { data: responseData } = await api.administration.updateRiskScreeningProfile(submitObj);
        if (responseData.isDefault) {
          updateRowData('isDefault', true, { isDefault: false });
        }
        updateRowData('id', submitObj.id, responseData);

        toastr.success('Successfully updated Screening Profile.');
      } else {
        const { data: responseData } = await api.administration.createRiskScreeningProfile(submitObj);
        if (responseData.isDefault) {
          updateRowData('isDefault', true, { isDefault: false });
        }
        addRow(responseData);

        toastr.success('Successfully created Screening Profile.');
      }
      setIsOpen(false);
    } catch (err) {
      if (err?.response) {
        const { status, data: errorData } = err.response;
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: errorData.message };
        }
        if (status === 500) {
          return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
        }
      } else return { [FORM_ERROR]: 'Error occured while creating screening profile' };
    }
  };

  const loadProfileData = profileID => {
    setUpdating(true);
    api.administration
      .getRiskScreeningPprofile(profileID)
      .then(res => {
        setInitialValues(prefillForm(res.data));
        setIsOpen(true);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const setDefaultRiskLevel = useCallback(
    id => {
      setUpdating(true);
      api.administration
        .setDefaultRiskScreeningProfile(id)
        .then(() => {
          updateRowData('isDefault', true, { isDefault: false });
          updateRowData('id', id, { isDefault: true });

          api.kyc.caseManagement
            .getDefaultScreeningProfile()
            .then(({ data: response }) => {
              dispatch(saveDefaultScreeningProfile(response));
            })
            .catch(() => {
              dispatch(
                saveDefaultScreeningProfile({
                  sources: [],
                  fuzziness: 0,
                  searchTerms: {
                    ongoing: true,
                    countries: [],
                    name: '',
                  },
                }),
              );
            });
          toastr.success('Risk Screening was set to default');
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [updateRowData, dispatch],
  );

  const onRemoveHandler = id => {
    removeRow('id', id);
  };
  const { edit: canEdit } = useContext(PermissionGroupContext);

  const riskProfilesWithDeafaultAsFirst = useMemo(() => riskProfiles.sort((a, b) => b.isDefault - a.isDefault), [
    riskProfiles,
  ]);

  return (
    <div className={classes} ref={pageRef}>
      <BareHeading title="Risk Screening Profiles" />

      <div className="ickyc-page__content">
        <Section>
          <div className="ickyc-section__subheader">
            <h2>Screening Profiles</h2>
            <PrimaryButton
              onClick={() => {
                setInitialValues(defaultValues);
                setIsOpen(true);
              }}
              disabled={!canEdit}
            >
              + Add Screening Profile
            </PrimaryButton>
          </div>

          {isOpen && (
            <WizardFormModal
              onSubmitHandler={handleSubmit}
              initialValues={initialValues}
              hideModal={() => setIsOpen(false)}
              className="ickyc-risk-profile-modal"
              containerRef={pageRef}
              disableSubmitButton={disableNextButton}
              titles={[
                'Step 1 - Choose Screening Parameters',
                'Step 2 - Configure Search Criteria',
                'Step 3 - Assignment',
              ]}
            >
              <WizardFormPage
                validate={values => {
                  const errors = {};

                  if (values?.sources?.length < 1) {
                    errors.sources = 'At least one source must be selected.';
                  }

                  if (!values?.name) {
                    errors.name = 'Please enter a profile name.';
                  }
                  if (Object.keys(errors).length) {
                    setDisableNextButton(true);
                  } else {
                    setDisableNextButton(false);
                  }

                  return errors;
                }}
              >
                <RiskScreeningParameters />
              </WizardFormPage>
              <WizardFormPage
                validate={values => {
                  const errors = {};
                  if (
                    values?.applyByNationalityEnabled &&
                    !Object.values(values?.appliedNationalities)
                      .map(elm => elm.enabled === 1)
                      .some(elm => elm === true)
                  ) {
                    errors.applyByNationalityEnabled = 'At least one of the countries must be enabled.';
                  }

                  if (
                    values?.applyByJurisdictionEnabled &&
                    !Object.values(values?.appliedJurisdictions)
                      .map(elm => elm.enabled === 1)
                      .some(elm => elm === true)
                  ) {
                    errors.applyByJurisdictionEnabled = 'At least one of the countries must be enabled.';
                  }

                  if (
                    values?.applyByKycStatusesEnabled &&
                    (!values?.appliedKycStatuses || values?.appliedKycStatuses.length === 0)
                  ) {
                    errors.appliedKycStatuses = 'Required';
                  }

                  if (
                    values?.applyByRiskLevelsEnabled &&
                    (!values?.appliedRiskLevels || values?.appliedRiskLevels?.length === 0)
                  ) {
                    errors.appliedRiskLevels = 'Required';
                  }

                  if (Object.keys(errors).length) {
                    setDisableNextButton(true);
                  } else {
                    setDisableNextButton(false);
                  }

                  return errors;
                }}
              >
                <SearchCriteriaConfiguration />
              </WizardFormPage>
              <WizardFormPage
                validate={values => {
                  const errors = {};

                  if (
                    values.assignmentType === enums.RISK_SCREENING_ASSIGNEMENT_TYPES.AssignAllTo &&
                    !values?.assignmentUserId
                  ) {
                    errors.assignmentUserId = 'Required';
                  }

                  if (Object.keys(errors).length) {
                    setDisableNextButton(true);
                  } else {
                    setDisableNextButton(false);
                  }
                }}
                footerContent={
                  <Field component={ToggleField} name="isDefault" label="Make This The Default Screening Profile" />
                }
              >
                <CaseAssignement />
              </WizardFormPage>
            </WizardFormModal>
          )}
          <Table
            values={riskProfilesWithDeafaultAsFirst}
            onClick={loadProfileData}
            containerRef={pageRef}
            isRadio
            updating={isLoading || updating}
            onRadioClick={setDefaultRiskLevel}
            headerRow={TableHeader}
            tableRow={RiskProfilesTableRow}
            pagination={pagination}
            handleParamsChange={changeParams}
            headerData={{
              sortCriteria: params.sortCriteria,
              sortOrder: params.sortOrder,
              onClick: changeParams,
              items: TableHeaders.AdministrationRiskScreening,
              withDelete: true,
            }}
            withPagination
            withRemove
            withRadioColumn
            onRemove={onRemoveHandler}
            className="ickyc-risk-profiles-table"
          />
        </Section>
      </div>
    </div>
  );
};

RiskScreeningPage.propTypes = {
  className: PropTypes.string,
};

RiskScreeningPage.defaultProps = {
  className: undefined,
};

export default RiskScreeningPage;
