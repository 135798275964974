import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { Field } from 'react-final-form';
import toastr from 'toastr';
import api from '../../../../../api';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import FormModal from '../../../../../components/Modal/FormModal';
import RadioButtonField from '../../../../../components/RadioButtonField';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import { PermissionGroupContext } from '../../../../../utilities/services/contexts';
import './styles.scss';

const RiskProfilesTableRow = ({
  rowInfo: { id, name, createdBy, isDefault, hovered, selected },
  className,
  onClick,
  onRadioClick,
  onRemove,
}) => {
  const { edit: canEdit } = useContext(PermissionGroupContext);
  const handleOnCLick = useCallback(() => {
    if (!canEdit) {
      return;
    }
    onClick(id);
  }, [id, onClick, canEdit]);

  const [forceRequest, setForceRequest] = useState(false);

  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const deleteRiskProfile = vals => {
    const { deleteOption } = vals;
    return api.administration
      .deleteRiskScreeningProfile(id, forceRequest, deleteOption)
      .then(() => {
        setForceRequest(false);
        setConfirmModalOpen(false);
        onRemove(id);
        toastr.success(`${name} has been deleted`);
      })
      .catch(err => {
        setForceRequest(false);
        if (err?.response) {
          const { status, data } = err.response;
          if (status === 400) {
            return { [FORM_ERROR]: data.errors.join() };
          }
          if (status === 409) {
            if (data.errors.join().includes('Cannot delete Screening Profile because')) {
              setForceRequest(true);
              return { [FORM_ERROR]: data.errors.join() };
            }
          }
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  const handleRadioClick = useCallback(() => {
    onRadioClick(id);
  }, [onRadioClick, id]);

  const classes = classNames({
    [`${className}__row--hovered`]: hovered,
    [`${className}__row--selected`]: selected,
    [`${className}__row`]: true,
  });

  return (
    <tr key={id} className={classes}>
      <td className={setColumnSuffix('__radio')}>
        <label>
          <input disabled={!canEdit} type="radio" checked={isDefault} onChange={handleRadioClick} />
        </label>
      </td>
      <td className={setColumnSuffix('__name')} onClick={handleOnCLick}>
        {name}
      </td>
      <td className={setColumnSuffix('__createdBy')} onClick={handleOnCLick}>
        {createdBy}
      </td>
      <td className={setColumnSuffix('__remove')}>
        {canEdit && (
          <DeleteButton
            onClick={ev => {
              ev.stopPropagation();
              setConfirmModalOpen(true);
            }}
          />
        )}
        <div>
          {confirmModalOpen && (
            <FormModal
              hideModal={() => setConfirmModalOpen(false)}
              className="ickyc-modal-remove-screeening-profile"
              title="Remove Screening Profile"
              onSubmit={deleteRiskProfile}
              showConfirmationForm={forceRequest}
              onRejectClick={() => setConfirmModalOpen(false)}
              showErrorsInConfirmation
              initialValues={{ deleteOption: '0' }}
              confirmButtonText="Yes"
              confirmationText={
                <p className="ickyc-proceed-form">
                  <div className="ickyc-proceed-form-title">
                    This risk screening profile is connected to an active portal. How would you like to proceed?
                  </div>
                  <Field
                    component={RadioButtonField}
                    type="radio"
                    value="0"
                    label="Disable Case module in the portal and delete this risk screening profile."
                    name="deleteOption"
                  />
                  <Field
                    component={RadioButtonField}
                    name="deleteOption"
                    type="radio"
                    value="1"
                    label="Update portal to use the default screening profile and delete this risk screening profile."
                  />
                </p>
              }
              rejectButtonText="No"
            >
              <span className="ickyc-confirmation-message">
                Are you sure you want to remove Risk Screening Profile: &nbsp; <h3>{name}</h3>
              </span>
            </FormModal>
          )}
        </div>
      </td>
    </tr>
  );
};
RiskProfilesTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    name: PropTypes.string,
    createdBy: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isDefault: PropTypes.bool,
    hovered: PropTypes.bool,
    selected: PropTypes.bool,
  }),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onRadioClick: PropTypes.func,
  onRemove: PropTypes.func,
};
RiskProfilesTableRow.defaultProps = {
  rowInfo: {
    name: '',
    createdBy: '',
    isDefault: false,
    hovered: false,
    selected: false,
  },
  className: '',
  onClick: () => {},
  onRadioClick: () => {},
  onRemove: () => {},
};
export default RiskProfilesTableRow;
