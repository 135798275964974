import ArrowRightIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import './styles.scss';

/**
 * Collapsible routing section with trigger and content
 * @param {*} title - section title
 * @param {boolean} open - fleg which denotes is section open
 * @param {*} children - secition content
 */

const RouterCollapsible = ({ title, children, shouldOpen }) => {
  const collapsibleContentRef = useRef();
  const [isOpen, setIsOpen] = useState(shouldOpen || false);
  // const { isOpen, toggleView } = useHeightCollapsible(collapsibleContentRef, shouldOpen);
  const classBase = 'ickyc-router-collapsible';
  const toggleView = () => {
    setIsOpen(prev => !prev);
  };
  const contenClasses = classNames({
    [`${classBase}__content`]: true,
    [`${classBase}__content--expanded`]: isOpen,
  });
  const svgClasses = classNames({
    [`${classBase}__svg-container`]: true,
    [`${classBase}__svg-container--expanded`]: isOpen,
  });
  return (
    <div className={classBase}>
      <button onClick={toggleView}>
        <div>
          <span>{title}</span>

          <span className={svgClasses}>
            <ArrowRightIcon />
          </span>
        </div>
      </button>

      {isOpen && (
        <div className={contenClasses} ref={collapsibleContentRef}>
          {children}
        </div>
      )}
    </div>
  );
};

RouterCollapsible.propTypes = {
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  shouldOpen: PropTypes.bool,
};

RouterCollapsible.defaultProps = {
  shouldOpen: false,
};
function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps.title) === JSON.stringify(nextProps.title);
}
export default React.memo(RouterCollapsible, areEqual);
