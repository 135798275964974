import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import setClassSuffix from 'utilities/services/ClassManager';
import DashboardTile from '../DashboardTile';
import './styles.scss';

const UsersCountTile = ({ currentUsage, licenceLimits, isLoading }) => {
  const history = useHistory();
  const baseClass = 'ickyc-users-count-tile';
  const setSuffix = setClassSuffix(baseClass);

  const navigateToUserManagement = useCallback(() => {
    history.push('/administration/user-management', {
      openFromDashboard: true,
    });
  }, [history]);

  return (
    <DashboardTile
      header="Users"
      buttonText="Add User"
      className={baseClass}
      isLoading={isLoading}
      buttonAction={navigateToUserManagement}
    >
      <div className={setSuffix('__content-box')}>
        <div className={setSuffix('__count-box')}>
          <span
            className={setSuffix('__count')}
          >{`${currentUsage.administrators}/${licenceLimits.administrators}`}</span>
          <span className={setSuffix('__count-label')}>Admin Users</span>
        </div>
        <span className={setSuffix('__separator')} />
        <div className={setSuffix('__count-box')}>
          <span className={setSuffix('__count')}>{`${currentUsage.platformUsers}/${licenceLimits.platformUsers}`}</span>
          <span className={setSuffix('__count-label')}>Platform Users</span>
        </div>
      </div>
    </DashboardTile>
  );
};

UsersCountTile.propTypes = {
  currentUsage: PropTypes.shape({
    platformUsers: PropTypes.number,
    administrators: PropTypes.number,
  }),
  licenceLimits: PropTypes.shape({
    platformUsers: PropTypes.number,
    administrators: PropTypes.number,
  }),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

UsersCountTile.defaultProps = {
  className: undefined,
  currentUsage: undefined,
  licenceLimits: undefined,
  isLoading: true,
};
export default UsersCountTile;
