import React from 'react';

export default () => (
  <svg viewBox="0 0 119.87 109.47" width="20px" height="20px" fill="currentColor">
    <g transform="translate(159.444 -4.5)">
      <g transform="translate(14.852 4.5)">
        <g>
          <path d="M-60.25,59.9c0.54,0.34,0.73,0.35,0.84,0.48c0.05,0.06,0.08,0.13,0.08,0.21v39.72     c0,5.07-4.11,9.17-9.17,9.17h-91.71c-5.07,0-9.17-4.11-9.17-9.17V61.11c0-0.51,0.41-0.92,0.92-0.92c0.21,0,0.42,0.07,0.58,0.21     c2.09,1.95,4.81,3.08,7.67,3.21h34.4v4.59c0,2.32,1.74,4.28,4.05,4.55l0.54,0.03h13.76c2.53,0,4.59-2.05,4.59-4.58v-4.59h34.39     C-65.75,63.61-63,62.38-60.25,59.9z" />
          <path d="M-68.5,22.93h-18.34V9.17c0-5.06-4.11-9.17-9.17-9.17h-36.69c-5.06,0-9.17,4.11-9.17,9.17v13.76h-18.34     c-5.06,0-9.17,4.11-9.17,9.17v13.76c0,0,0,0,0,0c0,5.06,4.11,9.17,9.17,9.17h34.39v-4.59c0-2.53,2.05-4.59,4.59-4.59h13.76     l0.54,0.03c2.31,0.27,4.05,2.23,4.05,4.55v4.59h34.39c5.06,0,9.17-4.11,9.17-9.17V32.1C-59.33,27.03-63.44,22.93-68.5,22.93z      M-96.02,22.93h-36.69v-9.17h36.69V22.93z" />
        </g>
      </g>
    </g>
  </svg>
);
