import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import ToggleField from '../../../../../components/ToggleField';
import './styles.scss';

const AutoAcceptenceForm = ({ namePrefix }) => {
  const baseClass = 'ickyc-auto-acceptence-form';
  const preffix = useMemo(() => (namePrefix ? `${namePrefix}.` : ''), [namePrefix]);

  return (
    <div className={baseClass}>
      <span>If no issues are found auto accept:</span>
      <Field
        name={`${preffix}identityAuthenticationAutoAcceptance`}
        component={ToggleField}
        label="Identity Authentication event"
      />
      <Field
        name={`${preffix}documentAuthenticationAutoAcceptance`}
        component={ToggleField}
        label="Document Authentication event"
      />
      <Field
        name={`${preffix}biometricAuthenticationAutoAcceptance`}
        component={ToggleField}
        label="Biometric Authentication event"
      />
    </div>
  );
};
AutoAcceptenceForm.propTypes = {
  namePrefix: PropTypes.string,
};
AutoAcceptenceForm.defaultProps = {
  namePrefix: null,
};
export default AutoAcceptenceForm;
