import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import { FORM_ERROR } from 'final-form';
/**
 * Displays details inside the Identity Document row
 * @param {*} param0
 */
import api from 'api';
import CountrySelect from 'components/CountrySelect';
import IComplyForm from 'components/Form/IComplyForm';
import StateProvinceSelect from 'components/StateProvinceSelect';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import toastr from 'toastr';
import enums from 'utilities/enums';
import DateManager from 'utilities/services/DateManager';
import IconManager from 'utilities/services/IconManager';
import { EntityContext, HistoryRecordContext, PermissionGroupContext } from 'utilities/services/contexts';
import IdentityDetailsFields from './IdentityDetailsFields';
import './index.scss';

const IdentityDetails = ({
  details: {
    firstName = '',
    middleName = '',
    lastName = '',
    unit = '',
    street = '',
    city = '',
    stateProvinceId = 0,
    stateProvinceAbbreviation = '',
    zip = '',
    dateOfBirth,
    documentNumber = '',
    expiryDate,
    documentCountryId = 0,
    fullAddress,
  },
  handleUpdate,
}) => {
  const { type: documentType, eventHistoryId } = useContext(HistoryRecordContext);
  const initValues = {
    firstName,
    middleName,
    lastName,
    unit,
    street,
    city,
    stateProvinceId,
    stateProvinceAbbreviation,
    zip,
    documentNumber,
    documentCountryId,
    dateOfBirth: DateManager.toDate(dateOfBirth),
    expiryDate: DateManager.toDate(expiryDate),
  };
  const { entityId } = useContext(EntityContext);
  const [editMode, setEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState(initValues);
  const goToEditMode = useCallback(event => {
    event.stopPropagation();
    setEditMode(true);
  }, []);

  const onSaveChanges = async ({ dateOfBirth: dob, expiryDate: ed, ...rest }) => {
    return api.kyc.entityManagement.naturalPerson
      .updateIdentityRecordDetails(entityId, eventHistoryId, {
        ...rest,
        dateOfBirth: dob && DateManager.toBekend(dob),
        expiryDate: ed && DateManager.toBekend(ed),
      })
      .then(({ data: response }) => {
        toastr.success('Succesfully Updated Document Details');
        bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, response.comment);
        const { documentDetails } = response;
        const transformedResults = {
          ...documentDetails,
          dateOfBirth:
            documentDetails.dateOfBirth === '0001-01-01 00:00:00'
              ? null
              : DateManager.parseDate(documentDetails.dateOfBirth, 'YYYY-MM-DD HH:mm:ss'),
          expiryDate:
            documentDetails.expiryDate === '0001-01-01 00:00:00'
              ? null
              : DateManager.parseDate(documentDetails.expiryDate, 'YYYY-MM-DD HH:mm:ss'),
        };
        setInitialValues(transformedResults);
        handleUpdate(transformedResults);
        setEditMode(false);
      })
      .catch(err => {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            const { message } = data;
            return { [FORM_ERROR]: message };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };
  const onDiscardChanges = async () => {
    setEditMode(false);
    setInitialValues(initValues);
  };

  const classes = classNames({
    'ickyc-identity-details': true,
    'ickyc-identity-details--preview': !editMode,
  });

  const renderDocumentJurisdiction = () => {
    const { documentCountryId: docCId, documentJurisdictionId: docJId } = initialValues;
    if (documentType === 'Passport') {
      return <CountrySelect value={docCId} preview />;
    }
    if (docCId === 1 || docCId === 2) return <StateProvinceSelect countryId={docCId} value={docJId} preview />;
    return docCId && <CountrySelect value={docCId} preview />;
  };

  const addressPT1 = useMemo(() => {
    if (!street && !unit) {
      return <></>;
    }
    if (!street && unit) {
      return <>{unit}</>;
    }
    return (
      <>
        {street} &nbsp;{unit}
        <br />
      </>
    );
  }, [street, unit]);

  const addressPT2 = useMemo(() => {
    if (city) {
      if (stateProvinceAbbreviation || zip)
        return (
          <>
            {city}, {stateProvinceAbbreviation} {zip}
          </>
        );
      return <>{city}</>;
    }
    return (
      <>
        {stateProvinceAbbreviation} {zip}
      </>
    );
  }, [city, stateProvinceAbbreviation, zip]);
  const { edit: canEdit } = useContext(PermissionGroupContext);
  return (
    <div className={classes}>
      <h2>
        <span>Details</span>
        <Tooltip
          placement="top"
          trigger={
            <div>{canEdit && <span onClick={goToEditMode}>{IconManager.get(IconManager.names.PENCIL)}</span>}</div>
          }
          content={<span>Click to edit details.</span>}
        />
      </h2>

      {editMode ? (
        <IComplyForm
          onSubmit={onSaveChanges}
          onDiscardClick={onDiscardChanges}
          initialValues={initialValues}
          showControls={editMode}
          withCloseButton
        >
          <IdentityDetailsFields documentType={documentType} />
        </IComplyForm>
      ) : (
        <>
          <div>
            <b>Name</b>
            <span>{`${firstName || ''} ${middleName || ''} ${lastName || ''}`}</span>
          </div>

          <div>
            <b>Address</b>
            <span>
              {fullAddress ? (
                <>{fullAddress.replace('^', ' ')}</>
              ) : (
                <>
                  {street && unit && addressPT1}
                  {stateProvinceAbbreviation && zip && addressPT2}
                </>
              )}
            </span>
          </div>

          <div>
            <b>Date of Birth</b>
            <span>{dateOfBirth ? DateManager.monDayYearLocal(dateOfBirth) : ''}</span>
          </div>

          <div>
            <b>Document Number</b>
            <span>{documentNumber}</span>
          </div>

          <div>
            <b>Expiry Date</b>
            <span> {expiryDate ? DateManager.monDayYearLocal(expiryDate) : ''}</span>
          </div>
          <div>
            <b>Document Country</b>
            <CountrySelect value={documentCountryId} preview />
          </div>
        </>
      )}
    </div>
  );
};

IdentityDetails.propTypes = {
  details: PropTypes.shape({
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    unit: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    stateProvinceAbbreviation: PropTypes.string,
    stateProvinceId: PropTypes.number,
    zip: PropTypes.string,
    dateOfBirth: PropTypes.string,
    documentNumber: PropTypes.string,
    expiryDate: PropTypes.string,
    documentJurisdictionId: PropTypes.number,
    documentCountryId: PropTypes.number,
    fullAddress: PropTypes.string,
  }),
  handleUpdate: PropTypes.func.isRequired,
};

IdentityDetails.defaultProps = {
  details: { firstName: '', middleName: '', lastName: '', fullAddress: '' },
};

export default IdentityDetails;
