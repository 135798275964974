import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import SingleCountry from '../CountrySelect/components/SingleCountry';
import SelectFilterContent from '../Select/components/SelectFilterContent';
import SelectTrigger from '../Select/components/SelectTrigger';
import SelectField from '../SelectField';

const CountrySelectField = ({
  contentComponent: ContentComponent,
  placeholder,
  onClose,
  multipleSelection,
  ...rest
}) => {
  const options = useSelector(state => state.global.countries || []).map(({ id, ...rest2 }) => ({
    label: rest2.name,
    id,
    value: id,
    ...rest2,
  }));

  return (
    <SelectField
      {...rest}
      multipleSelection={multipleSelection}
      options={options}
      Trigger={
        <SelectTrigger
          placeholder={placeholder}
          TriggerComponent={SingleCountry}
          withClose={!multipleSelection && !rest?.preview}
        />
      }
      Content={<SelectFilterContent withFilter ContentComponent={SingleCountry} />}
    />
  );
};

CountrySelectField.propTypes = {
  contentComponent: PropTypes.elementType,
  // Generated by final-form's Field component
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({ invalid: PropTypes.bool, touched: PropTypes.bool, error: PropTypes.string }),
  placeholder: PropTypes.string,
};

CountrySelectField.defaultProps = {
  contentComponent: undefined,
  meta: { invalid: false, touched: false, error: '' },
  placeholder: 'Country',
};

export default CountrySelectField;
