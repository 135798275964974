import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import Select from '../../../../components/Select';
import SelectFilterContent from '../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../components/Select/components/SelectTrigger';
import './styles.scss';

const AutoAssignementControlButtons = ({ onClick, disabled, value, setValue }) => {
  const usersToAssign = useSelector(state =>
    state.global.usersToAssign.map(({ id: uId, name }) => ({
      label: name,
      value: uId,
      id: uId,
    })),
  );

  return (
    <span className="ickyc-auto-assignment-control-buttons">
      <Select
        Trigger={<SelectTrigger placeholder="Choose Assigned To" />}
        Content={<SelectFilterContent />}
        value={value}
        onChange={setValue}
        options={usersToAssign}
      />
      <PrimaryButton onClick={() => onClick({ assignedByJurisdiction: value })} disabled={!value || disabled}>
        Assign to Selected
      </PrimaryButton>
      <PrimaryButton onClick={() => onClick({ assignedByJurisdiction: null })} disabled={disabled}>
        Unassign Selected
      </PrimaryButton>
    </span>
  );
};

AutoAssignementControlButtons.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
  setValue: PropTypes.func,
};

AutoAssignementControlButtons.defaultProps = {
  onClick: () => {},
  value: undefined,
  setValue: () => {},
  disabled: true,
};

export default AutoAssignementControlButtons;
