import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17.264" height="17.264">
    <path
      d="M2.877.004A2.884 2.884 0 000 2.881v8.056a2.884 2.884 0 002.877 2.877h.575v2.877a.576.576 0 00.982.407l3.284-3.284h6.667a2.884 2.884 0 002.877-2.877V2.881A2.884 2.884 0 0014.385.004zm0 1.151h11.51a1.71 1.71 0 011.726 1.726v8.056a1.71 1.71 0 01-1.726 1.726H7.481a.576.576 0 00-.407.169l-2.471 2.471v-2.064a.575.575 0 00-.575-.575H2.877a1.71 1.71 0 01-1.726-1.726V2.882a1.71 1.71 0 011.726-1.731z"
      fill="currentColor"
    />
  </svg>
);
