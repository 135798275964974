import classNames from 'classnames';
import InfoTooltip from 'components/InfoTooltip';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { EmailEditor } from 'react-email-editor';
import setClassSuffix from 'utilities/services/ClassManager';
import './styles.scss';

const EmailEditorField = ({
  input: { value, onChange },
  meta: { error, dirty, initial },
  label,
  setIsLoading,
  className,
  tooltip,
}) => {
  const ref = useRef(null);
  const [isDesignLoaded, setIsDesignLoaded] = useState(false);

  const handleOnChange = useCallback(() => {
    ref.current.exportHtml(async ({ design, html }) => {
      onChange({ design, html });
    });
  }, [onChange, ref.current]);

  const handleOnReady = useCallback(() => {
    if (value.design && !isDesignLoaded) {
      const emailEditor = ref.current;

      emailEditor.removeEventListener('design:updated', handleOnChange);

      emailEditor.addEventListener('design:updated', () => {
        handleOnChange();
      });

      emailEditor.loadDesign(value.design);
      setIsDesignLoaded(true);
      setIsLoading(false);
      handleOnChange();
    }
  }, [value.design, handleOnChange, setIsLoading, isDesignLoaded]);

  const hasErrors = useMemo(() => !!(error && dirty), [error, dirty]);

  const baseClass = 'ickyc-email-editor-field';
  const classes = classNames('ickyc-email-editor-field', className, {
    'ickyc-email-editor-field--error': hasErrors,
  });
  const setSuffix = setClassSuffix(baseClass);

  return (
    <div className={classes}>
      <div className={setSuffix('__label')}>
        <label>{label}</label>
        <InfoTooltip tooltip={tooltip} />
      </div>
      <div className={setSuffix('__editor-container')}>
        <EmailEditor
          // IF LICENSE EXISTS
          // projectId="YOUR_UNLAYER_PROJECT_ID"
          // designConfig={{ customCSS: customCSS }}
          options={{ tools: { html: { enabled: false } } }}
          ref={ref}
          onReady={handleOnReady}
        />
        {hasErrors && <span className={setSuffix('__error')}>{error}</span>}
      </div>
    </div>
  );
};

EmailEditorField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  setIsLoading: PropTypes.func,
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
  tooltip: PropTypes.string,
};

EmailEditorField.defaultProps = {
  className: undefined,
  label: undefined,
  meta: { invalid: false, touched: false, error: '', submitError: '', dirtySinceLastSubmit: false },
  setIsLoading: () => {},
  tooltip: undefined,
};

export default EmailEditorField;
