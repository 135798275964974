import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import useSelect from '../../hooks/useSelect';
import { selectPersonalId } from '../../store/selectors/auth.selector';
import setClassSuffix from '../../utilities/services/ClassManager';
import { EntityContext } from '../../utilities/services/contexts';
import DateManager from '../../utilities/services/DateManager';
import viewRelatedEvents from '../../utilities/services/RelatedEventsService';
import './styles.scss';
/**
 * Parent comment which can display replies
 * @param {string} date - date of the comment creation
 * @param {object} comment - top-level comment fetched from the server
 */

const LogComment = ({ comment }) => {
  const [isMe] = useState(useSelect(selectPersonalId) === comment.userId);
  const classBase = 'ickyc-comment';
  const setSuffix = setClassSuffix(classBase);
  const { entityId, isNaturalPerson, entityName } = useContext(EntityContext) || {};

  const getViewRelatedEvents = useCallback(() => {
    viewRelatedEvents(entityId, isNaturalPerson, comment.eventId);
  }, [entityId, isNaturalPerson, comment]);

  const commentTextClass = classNames({
    'ickyc-comment__top__content': true,
    'ickyc-comment__top__content--clickable': !!comment.eventId,
  });

  const isIncorporatedEntityEvent = comment.body.includes('Incorporated Entity');

  const constructText = () => {
    return (
      <p>
        <span>{isMe ? 'You' : comment.userFullName}</span>{' '}
        {entityName && !isIncorporatedEntityEvent ? comment.body.replace('Entity', entityName) : comment.body}
      </p>
    );
  };
  return (
    <div className={classBase}>
      <div className={setSuffix('__top')}>
        <div>
          <div className={setSuffix('__top__left-border')}>
            {/* Dot span */}
            <span />
          </div>
          {comment.timeCreated && DateManager.toLocal_hhmm(comment.timeCreated)}
        </div>

        <div className={commentTextClass} onClick={comment.eventId ? getViewRelatedEvents : undefined}>
          {constructText()}
        </div>
      </div>
    </div>
  );
};

LogComment.propTypes = {
  comment: PropTypes.shape({
    commentId: PropTypes.number,
    body: PropTypes.string,
    editable: PropTypes.bool,
    numberOfReplies: PropTypes.number,
    parentId: PropTypes.number,
    timeCreated: PropTypes.string,
    timeModified: PropTypes.string,
    userFullName: PropTypes.string,
    eventId: PropTypes.string,
    userId: PropTypes.string,
  }),
};

LogComment.defaultProps = {
  comment: {},
};

export default LogComment;
