import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import utilities from 'utilities';
import Modal from '..';
import CloseButton from '../../Buttons/CloseButton';
import OutlineButton from '../../Buttons/OutlineButton';
import PrimaryButton from '../../Buttons/PrimaryButton';
import FormModalSubmit from './components/FormModalSubmit';
import LeaveModal from './components/LeaveModal';
import './styles.scss';

const FormModal = React.forwardRef(
  (
    {
      hideModal,
      title,
      children,
      initialValues,
      className,
      onSubmit,
      withFieldArray,
      submitButtonText,
      showErrorsInConfirmation,
      disableSubmitButton,
      disableSubmitWithValidation,
      submitButtons: SubmitButtons = FormModalSubmit,
      disableSubmitWhenErrorMessageIsDisplayed,
      renderAsPortal,
      ...rest
    },
    containerRef,
  ) => {
    const [values, setValues] = useState(initialValues || {});

    const errorContainerRef = useRef();

    const baseClass = 'ickyc-form-modal';

    const fieldArrayMutators = useMemo(() => (withFieldArray ? arrayMutators : {}), [withFieldArray, arrayMutators]);

    const modalClasses = classNames({ [className]: className, [baseClass]: true });

    const handleScrollingToSubmissionError = useCallback(
      element => element?.scrollIntoView({ behavior: 'smooth' }),
      [],
    );

    const renderErrors = useCallback(err => {
      return (
        <div className={`${baseClass}__submissionError`} ref={handleScrollingToSubmissionError}>
          {Array.isArray(err) ? (
            <ul>
              {err.map(e => (
                <li>{e}</li>
              ))}
            </ul>
          ) : (
            err
          )}
        </div>
      );
    }, []);

    useEffect(() => {
      setValues(initialValues);
    }, [initialValues]);

    return (
      <Modal hideModal={hideModal} renderAsPortal={renderAsPortal}>
        <FinalForm
          initialValues={values}
          onSubmit={onSubmit}
          keepDirtyOnReinitialize
          mutators={fieldArrayMutators}
          {...rest}
        >
          {({
            handleSubmit,
            submitting,
            submitError,
            leaveModalOpen,
            closeLeaveModal,
            openLeaveModal,
            showConfirmationForm,
            onRejectClick,
            onConfirmClick,
            confirmButtonText,
            confirmationText,
            rejectButtonText,
            modified,
            form,
            hasValidationErrors,
            touched,
            errors,
          }) => {
            if (leaveModalOpen && Object.entries(modified).every(el => !el[1])) hideModal();
            return (
              <form onSubmit={handleSubmit} className={modalClasses}>
                <h2 className={`${baseClass}__header`}>
                  {title}
                  <CloseButton
                    transparent
                    onClick={() => {
                      openLeaveModal();
                    }}
                  />
                </h2>
                <div
                  ref={containerRef}
                  className={`${baseClass}__content ${submitting ? `${baseClass}__content--loading` : ''}`}
                >
                  {children}
                  {submitError && renderErrors(submitError)}
                </div>
                <div className={`${baseClass}__footer`}>
                  <PrimaryButton type="button" variant="link" disabled={submitting} onClick={openLeaveModal}>
                    Cancel
                  </PrimaryButton>
                  <SubmitButtons
                    showSpinner={submitting}
                    text={submitButtonText}
                    disabled={
                      submitting ||
                      disableSubmitButton ||
                      (disableSubmitWhenErrorMessageIsDisplayed &&
                        utilities.getTouchedAndErrorFields(touched, errors).length) ||
                      (disableSubmitWithValidation && hasValidationErrors)
                    }
                    onSubmit={form.submit}
                  />
                </div>
                {showConfirmationForm && (
                  <div className={`${baseClass}__warning`}>
                    <div>
                      {showErrorsInConfirmation && submitError && renderErrors(submitError)}
                      <h2 className={`${baseClass}__warning--message`}>{confirmationText}</h2>
                      <div>
                        <div>
                          <OutlineButton disabled={submitting} onClick={onRejectClick}>
                            {rejectButtonText}
                          </OutlineButton>
                          <PrimaryButton disabled={submitting} type="submit" onClick={onConfirmClick}>
                            {confirmButtonText}
                            {submitting && <CircularProgress />}
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {leaveModalOpen && <LeaveModal closeLeaveModal={closeLeaveModal} hideParentModal={hideModal} />}
              </form>
            );
          }}
        </FinalForm>
      </Modal>
    );
  },
);
FormModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  initialValues: PropTypes.shape({}),
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  withFieldArray: PropTypes.bool,
  submitButtonText: PropTypes.string,
  showErrorsInConfirmation: PropTypes.bool,
  disableSubmitButton: PropTypes.bool,
  submitButtons: PropTypes.func,
  renderAsPortal: PropTypes.bool,
  disableSubmitWithValidation: PropTypes.bool,
  disableSubmitWhenErrorMessageIsDisplayed: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  rejectButtonText: PropTypes.string,
};

FormModal.defaultProps = {
  title: undefined,
  initialValues: {},
  className: undefined,
  submitButtonText: 'Submit',
  withFieldArray: false,
  showErrorsInConfirmation: false,
  disableSubmitButton: false,
  submitButtons: FormModalSubmit,
  renderAsPortal: true,
  disableSubmitWithValidation: false,
  disableSubmitWhenErrorMessageIsDisplayed: false,
  confirmButtonText: 'Yes',
  rejectButtonText: 'No',
};

export default FormModal;
