/* eslint-disable react/jsx-curly-newline */
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import FilterFormsService from 'utilities/services/FilterFormsService';
import ConfirmationPage from '../../../../../components/Modal/ConfirmationPage';
import FormModal from '../../../../../components/Modal/FormModal';
import InformationModal from '../../../../../components/Modal/InformationModal';
import RadioButtonField from '../../../../../components/RadioButtonField';
import IconManager from '../../../../../utilities/services/IconManager';
import './styles.scss';

/**
 * Modal shows options to select CSV content
 *
 * @param {Function} hideModal  = callback which closes modal
 * @param {Object} filters  = list of active fillters. Used to create CSV Export
 * @param {number} count  = number of entities that will be displayed in CSV
 * @param {Boolean} filterOn  = are filters applied
 * @param {string} parentEntityId  = are filters applied
 *
 */

const CSVDownloadModal = ({
  hideModal,
  filters,
  count,
  filterOn,
  parentEntityId,
  searchEntity,
  selectedEntityIds,
  csvDownloadRequest,
}) => {
  const [showConfirmationmModal, setShowConfirmationModal] = useState(false);
  const handleOnSubmit = values => {
    const urlParams = new URLSearchParams(window.location.search);
    const logId = urlParams.get('csvcomplianceLogId');
    const filterOptions = ['allFilteredResults', 'selectedEntities'];

    let appliedFilters = {};

    if (filterOptions.includes(values.withFilter)) {
      if (values.withFilter === 'allFilteredResults')
        appliedFilters = FilterFormsService.normalizeEntityManagementFormData(filters, filterOn);
      else
        appliedFilters = {
          applyFilters: true,
          selectedEntitiesIds: selectedEntityIds,
        };
    }

    return csvDownloadRequest(appliedFilters, parentEntityId, logId, searchEntity).then(() => {
      setShowConfirmationModal(true);
    });
  };
  if (showConfirmationmModal) {
    return (
      <InformationModal hideModal={hideModal} onClick={() => setShowConfirmationModal(false)}>
        <ConfirmationPage
          icon={IconManager.get(IconManager.names.NEWS)}
          title="Preparing CSV Download"
          onClick={hideModal}
          icons={<MailOutlineIcon />}
          text={
            <p>
              We are preparing your CSV Export. <br />
              We’ll send you an email with the link to the CSV file <br />
              when the process is complete
            </p>
          }
        />
      </InformationModal>
    );
  }
  return (
    <FormModal
      onSubmit={handleOnSubmit}
      initialValues={{
        withFilter: 'allFilteredResults',
      }}
      hideModal={hideModal}
      title="Download CSV"
      className="ickyc-csv-download-modal"
      submitButtonText="Download CSV"
    >
      <FormSpy subscription={{ values: true }}>
        {({ values: { withFilter } }) =>
          withFilter === 'allFilteredResults' && (
            <p>
              <b>
                You currently have a filter {filterOn ? 'enabled' : 'disabled'} that displays {count} entities{' '}
              </b>
            </p>
          )
        }
      </FormSpy>
      <div className="ickyc-csv-download-type">
        <Field
          type="radio"
          component={RadioButtonField}
          label="Download a CSV with all currently filtered results"
          value="allFilteredResults"
          name="withFilter"
        />
        <Field
          type="radio"
          component={RadioButtonField}
          label="Download a CSV  with all entities"
          value="allEntities"
          name="withFilter"
        />
        <Field
          type="radio"
          component={RadioButtonField}
          disabled={selectedEntityIds.length === 0}
          label="Download selected Entities"
          value="selectedEntities"
          name="withFilter"
        />
      </div>
    </FormModal>
  );
};

CSVDownloadModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  filterOn: PropTypes.bool,
  count: PropTypes.number,
  filters: PropTypes.shape({}),
  parentEntityId: PropTypes.string,
  csvDownloadRequest: PropTypes.instanceOf(Promise).isRequired,
  searchEntity: PropTypes.string,
  selectedEntityIds: PropTypes.arrayOf(PropTypes.string),
};
CSVDownloadModal.defaultProps = {
  filters: {},
  count: 0,
  filterOn: false,
  parentEntityId: null,
  searchEntity: '',
  selectedEntityIds: [],
};

export default CSVDownloadModal;
