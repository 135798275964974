import { CircularProgress } from '@material-ui/core';
import DeleteButton from 'components/Buttons/DeleteButton';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import toastr from 'toastr';
import api from '../../../../../../../api';
import OutlineButton from '../../../../../../../components/Buttons/OutlineButton';
import FormModal from '../../../../../../../components/Modal/FormModal';
import useModalHandler from '../../../../../../../hooks/useModalHandler';
import useSelect from '../../../../../../../hooks/useSelect';
import { selectUserAccessByLicense } from '../../../../../../../store/selectors/auth.selector';
import utilities from '../../../../../../../utilities';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../utilities/services/DateManager';
import IconManager from '../../../../../../../utilities/services/IconManager';

const MyWebHooksRow = ({
  rowInfo: { name, endpoint, requestUrl, types, dateCreated, status, id },
  className,
  onRemove,
  onClick,
  onUpdate,
}) => {
  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const {
    isOpen: isConfirmationModalOpen,
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useModalHandler();
  const { isOpen, open: openModal, close: closeModal } = useModalHandler();
  const [apiKeyLoader, setApiKeyLoader] = useState(false);
  const [sendTestLoader, setSendTestLoader] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [copyText, setCopyText] = useState('Copy');
  const platformAccess = useSelect(selectUserAccessByLicense);

  const userTypes = useMemo(() => {
    if (!platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.CASES)) {
      return types.replace('|Natural Person Has AML Alert', '');
    }
    return types;
  }, [platformAccess]);

  const handleSendTest = () => {
    setSendTestLoader(true);
    api.administration.webhooks
      .testEndpoint(id)
      .then(response => {
        setSendTestLoader(false);
        if (response?.data) {
          const { message } = response.data;
          toastr.success(`${message}`);
          onUpdate('id', id, { status: 'Enabled' });
        }
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  const onRemoveIconClick = useCallback(
    e => {
      e.preventDefault();
      openConfirmationModal();
    },
    [openConfirmationModal],
  );

  const onGetSecretClick = useCallback(
    e => {
      setCopyText('Copy');
      e.preventDefault();
      setApiKeyLoader(true);
      api.administration.webhooks
        .getWebHookSecret(id)
        .then(response => {
          setApiKey(response.data.value);
          setApiKeyLoader(false);
          openModal();
        })
        .catch(err => {
          if (err?.response) {
            const { status: resStatus, data } = err.response;
            if (resStatus >= 400 && resStatus < 500) {
              return { [FORM_ERROR]: data.message };
            }
            if (resStatus === 500) {
              return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        });
    },
    [id, openModal],
  );
  const onCopyConfirmation = () => {
    utilities.copyToClipboard(apiKey);
    setCopyText('Copied');
    openModal();
  };
  const onCopyIconCLick = () => {
    utilities.copyToClipboard(apiKey);
    setCopyText('Copied');
  };
  const onDeleteConfirmation = useCallback(() => {
    return api.administration.webhooks
      .deleteMyWebHook(id)
      .then(() => {
        closeConfirmationModal();
        onRemove(id);
        toastr.success(`${name} has been deleted`);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  }, [onRemove, closeConfirmationModal, id, name]);

  const handleEditWebHook = useCallback(
    event => {
      event.stopPropagation();
      onClick(id);
    },
    [id, onClick],
  );

  return (
    <tr key={id} className={`${className}__row`}>
      <td className={setColumnSuffix('__name')} onClick={handleEditWebHook}>
        <span>{name}</span>
      </td>
      <td className={setColumnSuffix('__endpoint')}>
        {endpoint && (
          <a href={endpoint} target="_blank" rel="noopener noreferrer">
            <span>{endpoint}</span>
            {IconManager.get(IconManager.names.LINK)}
          </a>
        )}
      </td>
      <td className={setColumnSuffix('__requestUrl')}>
        {requestUrl && (
          <a href={requestUrl} target="_blank" rel="noopener noreferrer">
            <span>{requestUrl}</span>
            {IconManager.get(IconManager.names.LINK)}
          </a>
        )}
      </td>
      <td className={setColumnSuffix('__types')} onClick={handleEditWebHook}>
        <span>{userTypes}</span>
      </td>
      <td className={setColumnSuffix('__dateCreated')} onClick={handleEditWebHook}>
        <span>{DateManager.monDayYearLocal(dateCreated)}</span>
      </td>
      <td className={setColumnSuffix('__status')} onClick={handleEditWebHook}>
        <span>{status}</span>
      </td>
      <td className={setColumnSuffix('__actions')}>
        <OutlineButton
          disabled={sendTestLoader}
          onClick={handleSendTest}
          right={sendTestLoader && <CircularProgress />}
        >
          Send Test
        </OutlineButton>
      </td>
      <td className={setColumnSuffix('__secret')}>
        <OutlineButton disabled={apiKeyLoader} onClick={onGetSecretClick} right={apiKeyLoader && <CircularProgress />}>
          Get Secret
        </OutlineButton>
        <div>
          {isOpen && (
            <FormModal
              hideModal={closeModal}
              title="Copy the webhook secret"
              className="ickyc-confirmation-modal"
              onSubmit={onCopyConfirmation}
              on
              submitButtonText="Copy"
            >
              <span className="ickyc-confirmation-message" onClick={onCopyIconCLick}>
                &nbsp;&nbsp; <h3>{apiKey}</h3> &nbsp;&nbsp;
                {IconManager.get(IconManager.names.COPY)}
                &nbsp; <h3 className="ickyc-confirmation-message__copy">{copyText}</h3>
              </span>
            </FormModal>
          )}
        </div>
      </td>
      <td className={setColumnSuffix('__remove')}>
        <DeleteButton onClick={onRemoveIconClick} />
        <div>
          {isConfirmationModalOpen && (
            <FormModal
              hideModal={closeConfirmationModal}
              title="Remove Web Hook"
              className="ickyc-confirmation-modal"
              onSubmit={onDeleteConfirmation}
            >
              <span className="ickyc-confirmation-message">
                Are you sure you want to delete &nbsp; <h3>{name}</h3>
              </span>
            </FormModal>
          )}
        </div>
      </td>
    </tr>
  );
};
MyWebHooksRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    endpoint: PropTypes.string,
    types: PropTypes.string,
    dateCreated: PropTypes.string,
    status: PropTypes.string,
    requestUrl: PropTypes.string,
  }),
  className: PropTypes.string,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
};
MyWebHooksRow.defaultProps = {
  className: '',
  rowInfo: {},
  onRemove: null,
  onClick: null,
};
export default MyWebHooksRow;
