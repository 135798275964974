import classNames from 'classnames';
import Modal from 'components/Modal';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ProtectedComponent from 'router/components/ProtectedComponent';
import toastr from 'toastr';
import enums from 'utilities/enums';
import authEnums from 'utilities/enums/authEnums';
import CloseButton from '../../../../../components/Buttons/CloseButton';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import OutlineButton from '../../../../../components/Buttons/OutlineButton';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import IComplyForm from '../../../../../components/Form/IComplyForm';
import ModalTabHeader from '../../../../../components/Headers/ModalTabHeader';
import NestedListHeader from '../../../../../components/Headings/NestedListHeading';
import ConfirmationPage from '../../../../../components/Modal/ConfirmationPage';
import FormModal from '../../../../../components/Modal/FormModal';
import InformationModal from '../../../../../components/Modal/InformationModal';
import Spinner from '../../../../../components/Spinner';
import useModalHandler from '../../../../../hooks/useModalHandler';
import utilities from '../../../../../utilities';
import routesEnum from '../../../../../utilities/enums/routesEnum';
import IconManager from '../../../../../utilities/services/IconManager';
import { PermissionGroupContext } from '../../../../../utilities/services/contexts';
import RestrictionsSection from '../../../RestrictionsPage/components/RestrictionsSection';
import PortalManagementService from '../../services/PortalManagement.service';
import DeleteDefaultPortalForm from '../DeleteDefaultPortalForm';
import Domains from '../NaturalPersonPortalModal/components/Domains';
import General from '../NaturalPersonPortalModal/components/General';
import IdentityVerification from '../NaturalPersonPortalModal/components/IdentityVerification';
import Modules from '../NaturalPersonPortalModal/components/Modules';
import Styling from '../NaturalPersonPortalModal/components/Styling';
import Technical from '../NaturalPersonPortalModal/components/Tehnical';
import RevertPortalSettingsButton from '../RevertPortalSettingsButton';
import SavePortalSettingsButton from '../SavePortalSettingsButton';
import './styles.scss';

const naturalPersonTabsOptions = [
  {
    label: 'General ',
    key: 'general',
  },
  {
    label: 'Domains ',
    key: 'domains',
  },
  {
    label: 'Modules ',
    key: 'modules',
  },
  {
    label: 'Restrictions ',
    key: 'restrictions',
  },
  {
    label: 'Identity Verification ',
    key: 'identityVerificaiton',
  },
  {
    label: 'Styling ',
    key: 'styling',
  },
  {
    label: 'Technical ',
    key: 'technical',
  },
];

const internalTabKeys = ['general', 'modules', 'restrictions', 'identityVerificaiton'];

const internalNaturalPersonTabsOptions = naturalPersonTabsOptions.filter(({ key }) => internalTabKeys.includes(key));

const PortalDetailsNaturalPerson = ({ className }) => {
  const [isGlobalErrorModalOpen, setIsGlobalErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { id, clientId } = useParams();
  const containerRef = useRef(null);
  const [changeStatusConfMsg, setChangeStatusConfMsg] = useState('');
  const { isOpen: isConfirmModalOpen, close: closeConfirmModal, open: openConfirmModal } = useModalHandler();
  const { close: closeStatusConfModal, isOpen: isOpenStatusConfModal, open: openStatusConfModal } = useModalHandler();
  const { createGeneralPortals, createCustomPortals } = useContext(PermissionGroupContext);
  const [initialValues, setInitialValues] = useState({});
  const [initialRestrictions, setInitialRestrictions] = useState();
  const [leaveModalOpen, setLeaveModalOpen] = useState(false);
  const [shouldForceChangeStatus, setShouldForceChangeStatus] = useState(false);
  const [forceDelete, setForceDelete] = useState(false);
  const [showDeleteDefaultPortalForm, setShowDeleteDefaultPortalForm] = useState(false);
  const formRef = useRef();

  const hasOnlyGeneralPermission = useMemo(() => createGeneralPortals && !createCustomPortals, [
    createGeneralPortals,
    createCustomPortals,
  ]);

  window.onunload = e => {
    e.stopPropagation();
    setLeaveModalOpen(true);
  };

  const [activeTab, setActiveTab] = useState(0);

  const [npScreens] = useState(
    () => [
      <General />,
      <Domains />,
      <Modules />,
      <RestrictionsSection
        disabled={hasOnlyGeneralPermission}
        messageFieldName="globalSettings.minimumAcceptableAgeMessage"
        tableFieldPreffix="acceptableAgeRestrictions"
        instructionFieldName="globalSettings.instructions"
      />,
      <IdentityVerification
        disabled={hasOnlyGeneralPermission}
        autoAcceptanceFieldName="globalSettings.identityVerificationAutoAcceptance"
      />,
      <Styling />,
      <Technical />,
    ],
    [hasOnlyGeneralPermission],
  );

  const [internalNpScreens] = useState(
    () => [
      <General />,
      <Modules />,
      <RestrictionsSection
        disabled={hasOnlyGeneralPermission}
        messageFieldName="globalSettings.minimumAcceptableAgeMessage"
        tableFieldPreffix="acceptableAgeRestrictions"
        instructionFieldName="globalSettings.instructions"
      />,
      <IdentityVerification
        disabled={hasOnlyGeneralPermission}
        autoAcceptanceFieldName="globalSettings.identityVerificationAutoAcceptance"
      />,
    ],
    [],
  );

  const {
    fetchExistingConfiguration,
    updateConfigurationLogoImage,
    updateConfigurationNP,
    deleteConfiguration,
  } = PortalManagementService.initializeApiEndpoints(clientId);

  const classes = classNames('ickyc-kyc-page', 'ickyc-natural-person-portal-details', className);

  const handlePortalDeletion = useCallback(
    async ({ newDefaultPortalId }) => {
      const isNaturalPerson = initialValues?.type === 'NaturalPerson';
      const paramsData = clientId
        ? { id, clientId, type: isNaturalPerson ? 0 : 1 }
        : { id, type: isNaturalPerson ? 0 : 1 };

      try {
        await deleteConfiguration(...Object.entries(paramsData).map(el => el[1]), forceDelete, newDefaultPortalId);
        toastr.success(`Cofiguration has been deleted`);
        if (clientId) {
          history.push(
            utilities.routeParams(routesEnum.internal.BUSINESS_PROFILE, {
              id: clientId,
            }),
          );
        }
        history.push(utilities.routeParams(routesEnum.administration.PORTAL_MANAGMENT, { id }));

        setForceDelete(false);
      } catch (err) {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus === 409) {
            setForceDelete(true);
            return;
          }
          if (resStatus === 406) {
            setForceDelete(true);
            setShowDeleteDefaultPortalForm(true);
            return;
          }
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      }
    },
    [clientId, deleteConfiguration, id, initialValues, history, forceDelete],
  );

  const handleConfirmationPageClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const goHistoryBack = useCallback(() => history.goBack(), [history]);

  const optionClick = useCallback(value => {
    setActiveTab(value);
  }, []);

  const headingListItems = useMemo(() => {
    return [
      <>
        <b>{`${initialValues?.name || `Portal Name`}`}</b>
      </>,
    ];
  }, [initialValues]);

  useEffect(() => {
    const fetchExistingConfigurationSettings = () => {
      setIsLoading(true);
      const paramsData = clientId ? { id, clientId } : { id };
      fetchExistingConfiguration(...Object.entries(paramsData).map(el => el[1]))
        .then(({ data: response }) => {
          setInitialRestrictions(
            response?.restrictions.map(el => {
              const {
                failbackToSelfieUploadMessage,
                minimumAcceptableAgeMessage,
                onboardingStatusMessage,
                ...rest
              } = el;
              return rest;
            }),
          );
          const tmpConf = PortalManagementService.parsePortalConfiguration(response);

          if (!tmpConf.domains.some(el => el.isClientUpdate)) {
            tmpConf.domains[0].isClientUpdate = true;
          }
          setInitialValues(tmpConf);
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              setIsGlobalErrorModalOpen(true);
            }
            if (status === 500) {
              toastr.error('Internal Server Error, Try Again Later');
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    fetchExistingConfigurationSettings();
  }, [clientId, fetchExistingConfiguration, id]);

  const updateConfigurationSettings = useCallback(
    async (submitObject, newInitialValues) => {
      if (submitObject.styling?.clientLogo) {
        const { imgUrl, enabled } = submitObject.styling.clientLogo;
        if (imgUrl?.file && enabled) {
          const formData = new FormData();
          formData.set('file', imgUrl.file);
          try {
            const paramsData = clientId
              ? { id: submitObject.id, clientId, formData }
              : { id: submitObject.id, formData };

            const { data: responseData } = await updateConfigurationLogoImage(
              ...Object.entries(paramsData).map(el => el[1]),
            );
            submitObject.styling.clientLogo.imgUrl = responseData.imgUrl;
          } catch (err) {
            utilities.errorHandler(err);
          }
        }
      }

      const paramsData = clientId
        ? { id: submitObject.id, clientId, submitObject, shouldForceChangeStatus }
        : { id: submitObject.id, submitObject, shouldForceChangeStatus };

      return updateConfigurationNP(...Object.entries(paramsData).map(el => el[1]))
        .then(() => {
          setInitialValues(newInitialValues);
          setInitialRestrictions(oldCountriesRestrictions =>
            oldCountriesRestrictions.map(
              oldCountryRestrictions =>
                submitObject.restrictions.find(el => el.countryId === oldCountryRestrictions.countryId) ||
                oldCountryRestrictions,
            ),
          );
          toastr.success('Portal Configuration Updated!');
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              if (status === 409) {
                if (Array.isArray(errData?.message)) {
                  if (errData.message[0]?.includes('use this portal for Identity Verification.')) {
                    setShouldForceChangeStatus(true);
                    setChangeStatusConfMsg(
                      `${errData.message[0]} Changing the portal status will disable all Legal Entity Portal modules associated with it. Would you like to proceed?`,
                    );
                    openStatusConfModal();
                    return;
                  }
                }
              }
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || errData.errors,
              };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        });
    },
    [clientId, updateConfigurationNP, updateConfigurationLogoImage, shouldForceChangeStatus],
  );
  const handleOnSubmit = async (vals, form) => {
    const updatedConfiguration = PortalManagementService.normalizePortalConfiguration(vals);
    updatedConfiguration.restrictions = Object.entries(
      diff(
        initialRestrictions.map(({ instructions, ...rest }) => rest),
        updatedConfiguration?.restrictions.map(elm => {
          const { selected, ...rest } = elm;
          return rest;
        }),
      ),
    ).map(elm => ({ countryId: initialRestrictions[Number(elm[0])].countryId, ...elm[1] }));
    closeStatusConfModal();

    form.initialize(vals);

    return updateConfigurationSettings(updatedConfiguration, vals);
  };

  const handleRejectClick = useCallback(() => {
    setForceDelete(false);
    closeConfirmModal();
  }, [closeConfirmModal, setForceDelete]);

  if (isLoading) {
    return (
      <div className={classes} ref={containerRef}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={classes} ref={containerRef}>
      {isGlobalErrorModalOpen ? (
        <InformationModal hideModal={() => setIsGlobalErrorModalOpen(false)} onClick={handleConfirmationPageClick}>
          <ConfirmationPage
            title="Oops!"
            subtitle="Something went wrong…"
            text={
              <>
                We’re working on it, and we’ll get it fixed as soon as we can.
                <br /> You may refresh the page, or try again later.
              </>
            }
            icons={<div>{IconManager.get(IconManager.names.GLOBAL_ERROR)}</div>}
          />
        </InformationModal>
      ) : (
        <>
          <IComplyForm
            initialValues={initialValues}
            ref={formRef}
            onSubmit={handleOnSubmit}
            mutators={{ ...arrayMutators }}
          >
            {leaveModalOpen && (
              <div className="ickyc-form-modal__warning">
                <div>
                  <h2 className="ickyc-form-moda__warning--message">
                    Are you sure you want to navigate away from this page and discard the data?
                  </h2>
                  <div>
                    <OutlineButton onClick={() => setLeaveModalOpen(false)}>Stay</OutlineButton>
                    <PrimaryButton onClick={() => history.goBack()}>Leave</PrimaryButton>
                  </div>
                </div>
              </div>
            )}
            <NestedListHeader
              title="Portal Settings"
              onClick={goHistoryBack}
              items={headingListItems}
              right={
                <>
                  <RevertPortalSettingsButton />
                  <SavePortalSettingsButton />
                  <div>
                    {clientId ? (
                      <ProtectedComponent allowedUserTypes={[authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN]}>
                        <DeleteButton onClick={openConfirmModal} tooltip="Delete Portal" tooltipPlacement="bottom" />
                      </ProtectedComponent>
                    ) : (
                      <DeleteButton onClick={openConfirmModal} tooltip="Delete Portal" tooltipPlacement="bottom" />
                    )}
                    {isConfirmModalOpen && (
                      <FormModal
                        hideModal={closeConfirmModal}
                        title="Delete Portal"
                        className="ickyc-confirmation-moda ickyc-portal-management-confirm-modal"
                        onSubmit={handlePortalDeletion}
                        onRejectClick={handleRejectClick}
                        showErrorsInConfirmation={!forceDelete}
                        showConfirmationForm={forceDelete && !showDeleteDefaultPortalForm}
                        confirmationText="You are about to delete your default portal. Would you like to proceed?"
                      >
                        {!showDeleteDefaultPortalForm ? (
                          <span className="ickyc-confirmation-message">
                            Are you sure you want to delete &nbsp;
                            <b>{`${initialValues?.name || `Portal Name`}`}</b>?
                          </span>
                        ) : (
                          <DeleteDefaultPortalForm
                            portalType={enums.ENTITY_TYPE_NAMES.NATURAL_PERSON}
                            clientId={clientId}
                          />
                        )}
                      </FormModal>
                    )}
                  </div>
                </>
              }
            />
            <div>
              <ModalTabHeader
                onClick={optionClick}
                options={initialValues.isInternal ? internalNaturalPersonTabsOptions : naturalPersonTabsOptions}
              />
              {initialValues.isInternal ? internalNpScreens[activeTab] : npScreens[activeTab]}
            </div>
            {isOpenStatusConfModal && (
              <Modal
                open={isOpenStatusConfModal}
                className="ickyc-confirmation-modal"
                relativeToParent={formRef.current}
                hideModal={() => {
                  setShouldForceChangeStatus(false);
                  closeStatusConfModal();
                }}
              >
                <div className="ickyc-form-modal ickyc-portal-management-modal ickyc-confirmation-risk-level-modal">
                  <h2 className="ickyc-form-modal__header">
                    Portal Status Confirmation
                    <CloseButton
                      transparent
                      onClick={() => {
                        setShouldForceChangeStatus(false);
                        closeStatusConfModal();
                      }}
                    />
                  </h2>
                  <div className="ickyc-form-modal__content">{changeStatusConfMsg}</div>
                  <div className="ickyc-form-modal__footer">
                    <PrimaryButton
                      variant="link"
                      type="button"
                      onClick={() => {
                        setShouldForceChangeStatus(false);
                        closeStatusConfModal();
                      }}
                    >
                      Decline
                    </PrimaryButton>
                    <PrimaryButton type="submit">Confirm</PrimaryButton>
                  </div>
                </div>
              </Modal>
            )}
          </IComplyForm>
        </>
      )}
    </div>
  );
};

PortalDetailsNaturalPerson.propTypes = {
  className: PropTypes.string,
};

PortalDetailsNaturalPerson.defaultProps = {
  className: undefined,
};

export default PortalDetailsNaturalPerson;
