/* eslint-disable max-len */
import classNames from 'classnames';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api';
import IComplyForm from '../../../components/Form/IComplyForm';
import BareHeading from '../../../components/Headings/BareHeading';
import Loader from '../../../components/Loader';
import Spinner from '../../../components/Spinner';
import setClassSuffix from '../../../utilities/services/ClassManager';
import { PermissionGroupContext } from '../../../utilities/services/contexts';
import DocumentTypesSettings from '../components/DocumentTypesSettings';
import PortalManagementService from '../PortalManagementPage/services/PortalManagement.service';

const DocumentVerificationPage = ({ className }) => {
  const [initialValues, setInitialValues] = useState();
  const [updatingStatus, setUpdatingStatus] = useState();
  const [isFetching, setIsFetching] = useState(true);

  const { edit: canEdit } = useContext(PermissionGroupContext); /// change this

  useEffect(() => {
    const loadInitialValues = () => {
      setIsFetching(true);
      api.administration
        .getDocumentAuthInfo()
        .then(({ data }) => {
          if (data.jurisdictions.length)
            setInitialValues({
              jurisdictions: data.jurisdictions.reduce(
                (accumulator, { countryId, driverLicenceRequired, idCardRequired, passportRequired }) => ({
                  ...accumulator,
                  [`country${countryId}`]: {
                    driverLicense: driverLicenceRequired,
                    idCard: idCardRequired,
                    passport: passportRequired,
                  },
                }),
                {},
              ),
              documentAuthAutoAcceptance: data.documentAuthAutoAcceptance,
            });
          else {
            setInitialValues({
              restrictions: PortalManagementService.setCountryTableRowValues({
                idDocumentTypes: {
                  driverLicense: true,
                  idCard: true,
                  passport: true,
                },
              }),
            });
          }
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => setIsFetching(false));
    };
    loadInitialValues();
  }, []);

  const handleSubmit = vals => {
    const changes = diff(initialValues, vals);

    const submitObj = {
      jurisdictions: Object.entries(changes.jurisdictions).map(country => ({
        [`countryId`]: Number(country[0].replace('country', '')),
        [`driverLicenceRequired`]: country[1].driverLicense,
        [`idCardRequired`]: country[1].idCard,
        [`passportRequired`]: country[1].passport,
      })),
      documentAuthAutoAcceptance: vals.documentAuthAutoAcceptance,
    };
    setUpdatingStatus(true);
    api.administration
      .updateDocumentAuthInfo(submitObj)
      .then(() => {})
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => setUpdatingStatus(false));
  };

  const baseClass = 'ickyc-page';
  const setSuffix = setClassSuffix(baseClass);

  const classes = classNames(baseClass, 'ickyc-biometric-authentication', className);

  const contentClasses = classNames({
    [setSuffix('__content')]: true,
  });
  return (
    <div className={classes}>
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          {updatingStatus && <Loader />}
          <BareHeading title="Document Verification" />
          <div className={contentClasses}>
            <IComplyForm initialValues={initialValues} showControls={canEdit} onSubmit={handleSubmit}>
              <DocumentTypesSettings tableName="jurisdictions" disabled={!canEdit} />
            </IComplyForm>
          </div>
        </>
      )}
    </div>
  );
};

DocumentVerificationPage.propTypes = {
  className: PropTypes.string,
};

DocumentVerificationPage.defaultProps = {
  className: undefined,
};
export default DocumentVerificationPage;
