import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import Item from './components/Item';
import './styles.scss';

/**
 * @name List
 *
 * @param {Array} options - Array of props applied to Item such as icon, title and text
 * @param {boolean} inline
 *
 */

const List = ({ options, inline }) => {
  const classes = classNames({
    'ickyc-list': true,
    'ickyc-list--inline': inline,
  });

  return (
    <ul className={classes}>
      {options.map(item => (
        <Item key={uuid()} title={item.title} text={item.text} icon={item.icon} />
      ))}
    </ul>
  );
};

List.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  inline: PropTypes.bool,
};

List.defaultProps = {
  inline: false,
};

export default List;
