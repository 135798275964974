import PropTypes from 'prop-types';
import React from 'react';

import './styles.scss';

const FieldDescription = ({ text }) => {
  return <span className="ickyc-field-description">{text}</span>;
};

FieldDescription.propTypes = {
  text: PropTypes.string.isRequired,
};
export default FieldDescription;
