import VerifiedUser from 'assets/VerifiedUser';
import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const UserVerificationTag = ({ verificationStatus }) => {
  if (verificationStatus === 0) return null;

  return (
    <Tooltip
      trigger={
        <span
          className={classNames('verification-tag-wrapper', {
            'verification-tag-wrapper--verified': verificationStatus === 2,
          })}
        >
          <VerifiedUser />
        </span>
      }
      content={<span>{verificationStatus === 1 ? 'Pending verification' : 'Verified'}</span>}
    />
  );
};

UserVerificationTag.propTypes = {
  // 0 - verification not required
  // 1 - not verified
  // 2 - verified
  verificationStatus: PropTypes.oneOf([0, 1, 2]).isRequired,
};

export default UserVerificationTag;
