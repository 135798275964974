import Tooltip from 'components/Tooltip';
import React from 'react';
import utilities from 'utilities';
import routesEnum from 'utilities/enums/routesEnum';
import IconManager from 'utilities/services/IconManager';

export default function useAssignedToMeRow(entityType, id, entityId) {
  const routeAndTooltipParams = {
    'Legal Entity': [
      utilities.routeParams(routesEnum.kyc.LEGAL_ENTITY, { id: entityId }),
      <Tooltip
        placement="top"
        trigger={<div>{IconManager.get(IconManager.names.LEGAL_ENTITY)}</div>}
        content="Legal Entity"
      />,
    ],
    'Natural Person': [
      utilities.routeParams(routesEnum.kyc.NATURAL_PERSON, { id: entityId }),
      <Tooltip
        placement="top"
        trigger={<div>{IconManager.get(IconManager.names.NATURAL_PERSON)}</div>}
        content="Natural Person"
      />,
    ],

    Case: [
      utilities.routeParams(routesEnum.kyc.CASE_DETAIL, { id }),
      <Tooltip placement="top" trigger={<div>{IconManager.get(IconManager.names.CASE)}</div>} content="Case" />,
    ],
  };

  const key = entityType ?? 'Case';

  return routeAndTooltipParams[key];
}
