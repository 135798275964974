import classNames from 'classnames';
import ModalTabHeader from 'components/Headers/ModalTabHeader';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import toastr from 'toastr';
import api from '../../../api';
import IComplyForm from '../../../components/Form/IComplyForm';
import BareHeading from '../../../components/Headings/BareHeading';
import Spinner from '../../../components/Spinner';
import './styles.scss';
import utils from './utils';

const {
  defaultInitialValues,
  mapJurisdictionEntity,
  mapSupportingDocs,
  mapbiometricAuthenticationEvent,
  mapdocumentAuthenticationEvent,
  mapidentityAuthenticationEvent,
  mergeJurisdictionTables,
  parseDocumentsListTable,
  tabOptions,
  screens,
} = utils;

const Automations = ({ className }) => {
  const classes = classNames('ickyc-page', 'ickyc-automation-page', className);

  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [initialFetchedData, setInitialFetchedData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    api.administration.automations
      .getAutomationsConfiguration()
      .then(
        ({
          data: {
            automationsJurisdictions,
            automationsSupportingDocs,
            automationNpRcu: {
              automation: { automationsJurisdictions: nestedAutomationsJurisdictions, ...restNestedAutomation },
              ...restAutomationNpRcu
            },
            ...rest
          },
        }) => {
          setInitialFetchedData({
            automationsJurisdictions,
            automationsSupportingDocs,
            nestedAutomationsJurisdictions,
          });

          setInitialValues(old => ({
            ...old,
            entityProfile: mapJurisdictionEntity(automationsJurisdictions),
            identityAuthenticationEvent: mapidentityAuthenticationEvent(automationsJurisdictions),
            documentAuthenticationEvent: mapdocumentAuthenticationEvent(automationsJurisdictions),
            biometricAuthenticationEvent: mapbiometricAuthenticationEvent(automationsJurisdictions),
            automationsSupportingDocsTransformed: mapSupportingDocs(automationsSupportingDocs),
            automationsSupportingDocs,
            automationNpRcu: {
              automation: {
                entityProfile: mapJurisdictionEntity(nestedAutomationsJurisdictions),
                identityAuthenticationEvent: mapidentityAuthenticationEvent(nestedAutomationsJurisdictions),
                documentAuthenticationEvent: mapdocumentAuthenticationEvent(nestedAutomationsJurisdictions),
                biometricAuthenticationEvent: mapbiometricAuthenticationEvent(nestedAutomationsJurisdictions),
                ...restNestedAutomation,
              },
              ...restAutomationNpRcu,
            },
            ...rest,
          }));

          setIsLoading(false);
        },
      )
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = async vals => {
    const {
      biometricAuthenticationAutoAcceptance,
      documentAuthenticationAutoAcceptance,
      identityAuthenticationAutoAcceptance,
      periodicReviewInterval,
      periodicReviewValue,
      setProfileStatusTo,
      setRiskLevelTo,
      autoAssignSupportingDocumentPackagesEnabled,
      autoAssignIdentityAuthenticationEnabled,
      autoAssignBiometricAuthenticationEnabled,
      autoAssignDocumentAuthenticationEnabled,
      autoAssignEntityProfileEnabled,
      enablePeriodicReview,
      autoUpdateEntityStatus,
      autoUpdateRiskLevel,
      changeStatusForApprovedProfile,
      approvedProfileStatus,
      automationLeRcu,
      automationLeRcuEnabled,
      automationNpRcu,
      automationNpRcuEnabled,
      ...rest
    } = vals;

    const mergedJurisdiction = mergeJurisdictionTables(vals);
    const mergedSuppDocs = parseDocumentsListTable(vals);

    const jurisdictionChanges = diff(initialFetchedData?.automationsJurisdictions, mergedJurisdiction);
    const suppDocsChanges = diff(initialFetchedData?.automationsSupportingDocs, mergedSuppDocs);

    //automationNpRCU
    const mergedJurisdictionRcu = mergeJurisdictionTables(automationNpRcu.automation);

    const submitObj = {
      automationsJurisdictions: Object.entries(jurisdictionChanges).map(country => ({
        ...mergedJurisdiction[Number(country[0])],
      })),
      automationsSupportingDocs: Object.entries(suppDocsChanges).map(suppDoc => ({
        ...mergedSuppDocs[Number(suppDoc[0])],
      })),
      biometricAuthenticationAutoAcceptance,
      documentAuthenticationAutoAcceptance,
      identityAuthenticationAutoAcceptance,
      periodicReviewInterval,
      periodicReviewValue,
      setProfileStatusTo,
      setRiskLevelTo,
      autoAssignSupportingDocumentPackagesEnabled,
      autoAssignIdentityAuthenticationEnabled,
      autoAssignBiometricAuthenticationEnabled,
      autoAssignDocumentAuthenticationEnabled,
      autoAssignEntityProfileEnabled,
      enablePeriodicReview,
      autoUpdateEntityStatus,
      autoUpdateRiskLevel,
      changeStatusForApprovedProfile,
      approvedProfileStatus,
      automationLeRcu,
      automationLeRcuEnabled,
      automationNpRcu: {
        ...automationNpRcu,
        dataCollection: {
          ...automationNpRcu.dataCollection,
          packagesTypes: automationNpRcu.dataCollection.packagesTypes || [],
        },
        automation: {
          ...automationNpRcu.automation,
          automationsJurisdictions: mergedJurisdictionRcu,
        },
      },
      automationNpRcuEnabled,
    };

    return api.administration.automations
      .updateAutomationsConfiguration(submitObj)
      .then(response => {
        toastr.success('Successfully updated Automations Section');
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  const optionClick = useCallback(value => {
    setActiveTab(value);
  }, []);

  return (
    <div className={classes}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <BareHeading title="Automations" />
          <ModalTabHeader onClick={optionClick} options={tabOptions} />

          <IComplyForm onSubmit={onSubmit} showControls initialValues={initialValues} mutators={arrayMutators}>
            {screens[activeTab]}
          </IComplyForm>
        </>
      )}
    </div>
  );
};
Automations.propTypes = {
  className: PropTypes.string,
};

Automations.defaultProps = {
  className: undefined,
};
export default Automations;
