import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import BareHeading from '../../../components/Headings/BareHeading';
import Reports from './compoenents/Reports';

import './styles.scss';

const ReportsManagementPage = ({ className }) => {
  const classes = classNames('ickyc-page', 'ickyc-reports-management', className);
  return (
    <div className={classes}>
      <BareHeading title="Reports" />
      <div className="ickyc-page__content">
        <Reports />
      </div>
    </div>
  );
};

ReportsManagementPage.propTypes = {
  className: PropTypes.string,
};

ReportsManagementPage.defaultProps = {
  className: undefined,
};

export default ReportsManagementPage;
