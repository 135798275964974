import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import api from '../../../../../../../../api';
import FormModal from '../../../../../../../../components/Modal/FormModal';
import SelectFilterContent from '../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../components/SelectField';
import bus from '../../../../../../../../modules/bus';
import enums from '../../../../../../../../utilities/enums';
import { EntityContext } from '../../../../../../../../utilities/services/contexts';
import validators from '../../../../../../../../utilities/services/validators';
import DocumentDropzone from '../../../../../components/DocumentDropzone';
import FaceImagePreview from '../FaceImagePreview';
import './styles.scss';

const FaceImageUpload = ({ hideModal }) => {
  const { entityId } = useContext(EntityContext);
  const [forceRequest, setForceRequest] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [faceNotDetected, setFaceNotDetected] = useState(false);

  const uploadDocument = async values => {
    setForceRequest(false);
    const { faceImage, status } = values;
    const formData = new FormData();
    formData.append('status', status);
    formData.append('selfieImage', faceImage.file);
    return api.kyc.entityManagement.naturalPerson
      .uploadFaceImage(entityId, formData, forceRequest)
      .then(({ data }) => {
        bus.broadcastEvent(enums.BUS_EVENTS.NEW_BIOMETRIC_RECORD, data);
        hideModal();
      })
      .catch(err => {
        if (err?.response) {
          const { status: stausCode, data: errorData } = err.response;
          if (stausCode >= 400 && stausCode < 500) {
            return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
          }
          if (stausCode === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to update Natural Person' };
      });
  };

  const handleOnSubmit = async values => {
    return uploadDocument(values);
  };

  const mappedStatuses = useMemo(() => {
    return Object.entries(enums.HISTORY_EVENT_STATUSES).map(status => ({
      id: status[0],
      label: status[1],
      value: status[1],
    }));
  }, []);

  return (
    <FormModal
      onSubmit={handleOnSubmit}
      className="ickyc-face-image-upload-modal"
      hideModal={hideModal}
      title="Upload Face Image"
      withConfirmationPage
      showConfirmationForm={forceRequest}
      onRejectClick={hideModal}
      showErrorsInConfirmation
      disableSubmitButton={disableSubmitButton || faceNotDetected}
      confirmButtonText="Yes"
      confirmationText="Would you like to proceed?"
      rejectButtonText="No"
    >
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          if (!values?.faceImage) {
            setDisableSubmitButton(true);
          } else if (values?.faceImage !== {}) {
            setDisableSubmitButton(false);
          }
          return <></>;
        }}
      </FormSpy>
      <Field
        name="faceImage"
        component={DocumentDropzone}
        accept="image/*"
        exifCorrection
        validate={validators.faceImageDropzoneValidator()}
        initialValue={null}
      >
        <FaceImagePreview faceDetection onFaceNotDetectedHandler={val => setFaceNotDetected(val)} />
      </Field>
      <Field
        component={SelectField}
        name="status"
        initialValue={enums.HISTORY_EVENT_STATUSES.REVIEW}
        options={mappedStatuses}
        label="Status"
        Trigger={<SelectTrigger />}
        Content={<SelectFilterContent />}
      />
    </FormModal>
  );
};

FaceImageUpload.propTypes = {
  hideModal: PropTypes.bool.isRequired,
};
export default FaceImageUpload;
