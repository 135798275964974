/* eslint-disable no-restricted-globals */
import classNames from 'classnames';
import CheckboxField from 'components/CheckboxField';
import SingleCountry from 'components/CountrySelect/components/SingleCountry';
import DebounceInput from 'components/DebounceInput';
import useSelect from 'hooks/useSelect';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import VirtualList from 'react-vlist';
import { selectCountries } from 'store/selectors/global.selector';
import utilities from 'utilities';
import './styles.scss';

const FieldCountriesList = ({
  initValue,
  TableControlsComponent,
  tableRow: TableRow,
  modulePreffix,
  idsToInclude,
  withInfiniteScroll,
  disabled,
  propertyName,
}) => {
  const [controlsValue, setControlsValue] = useState();
  const [controlsState, setControlsState] = useState(initValue);
  const [searchString, setSearchString] = useState();

  const countries = useSelect(selectCountries) || [];

  const { batch, change } = useForm();

  const { values } = useFormState();

  const { selectAllCountries, ...restrictions } =
    utilities.accessObjectNestedValuesByStringPath(values, modulePreffix) || {};

  const baseClass = 'ickyc-countries';

  const countriesToList = idsToInclude.length ? countries.filter(({ id }) => idsToInclude.includes(id)) : countries;

  const filteredCountries = useMemo(() => {
    if (!searchString) return countries;

    return countries.filter(country => country.name.toLowerCase().includes(searchString.toLowerCase()));
  }, [searchString, countries]);

  const disableControls = !Object.entries(restrictions || []).some(el => el[1]?.selected) || disabled;

  const applyToMultipleRows = useCallback(
    val => {
      batch(() => {
        Object.entries(restrictions)
          .filter(el => el[1].selected)
          .forEach(el => change(`${modulePreffix}.${el[0]}`, { ...el[1], ...val }));
      });
    },
    [restrictions, batch, change, modulePreffix],
  );

  const renderRow = useCallback(
    (country, index, style) => {
      const { id, alpha2Code, name } = country;

      const listItemClass = `${baseClass}__list__item`;

      const listItemClasses = classNames(listItemClass, {
        [`${listItemClass}--even`]: index % 2 === 0,
        [`${listItemClass}--disabled`]: disabled,
      });

      return (
        <li className={listItemClasses} key={index} style={style}>
          <div className={`${listItemClass}__checkbox`}>
            <Field
              // undefined in order to delete prop if unchecked
              parse={value => value || undefined}
              component={CheckboxField}
              type="checkbox"
              checked={`restrictions.country${id}.selected`}
              readOnly
              name={`${modulePreffix}.country${id}.selected`}
              disabled={disabled}
            />
          </div>
          <div className={`${listItemClass}__country`}>
            <SingleCountry alpha2Code={alpha2Code} name={name} />
          </div>
          <div className={`${listItemClass}__controls`}>
            <TableRow modulePreffix={`${modulePreffix}.country${id}`} disabled={disabled} propertyName={propertyName} />
          </div>
        </li>
      );
    },
    [modulePreffix, disabled, propertyName],
  );

  const removeSelectFromAllCountries = useCallback(
    searchString => {
      setSearchString(searchString);

      batch(() => {
        Object.entries(restrictions).forEach(el => {
          change(`${modulePreffix}.${el[0]}.selected`, undefined);
        });
        change(`${modulePreffix}.selectAllCountries`, false);
      });
    },
    [restrictions, change, modulePreffix],
  );

  useEffect(() => {
    batch(() => {
      filteredCountries.forEach(country => {
        // undefined in order to delete prop, since there's no 'selected' in initial values
        change(`${modulePreffix}.country${country.id}.selected`, selectAllCountries ? true : undefined);
      });
    });
  }, [batch, change, selectAllCountries, modulePreffix, filteredCountries]);

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__controls`}>
        <Field
          name={`${modulePreffix}.selectAllCountries`}
          type="checkbox"
          label="Select All"
          component={CheckboxField}
          disabled={disabled}
        />
        <DebounceInput onChange={removeSelectFromAllCountries} placeholder="Search Countries" />
        <TableControlsComponent
          disabled={disableControls}
          value={controlsValue}
          setState={setControlsState}
          initValue={controlsState}
          onClick={applyToMultipleRows}
          setValue={setControlsValue}
          propertyName={propertyName}
        />
      </div>
      {withInfiniteScroll ? (
        <VirtualList
          className={`${baseClass}__list`}
          data={filteredCountries}
          itemheight={48}
          renderItems={(item, index, style) => renderRow(item, index, style)}
        />
      ) : (
        <ul className={`${baseClass}__list`}>{countriesToList.map((item, index) => renderRow(item, index))}</ul>
      )}
    </div>
  );
};

FieldCountriesList.propTypes = {
  tableRow: PropTypes.elementType.isRequired,
  modulePreffix: PropTypes.string.isRequired,
  TableControlsComponent: PropTypes.elementType,
  idsToInclude: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  withInfiniteScroll: PropTypes.bool,
  disabled: PropTypes.bool,
  initValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.string])),
  propertyName: PropTypes.string,
};
FieldCountriesList.defaultProps = {
  withInfiniteScroll: false,
  idsToInclude: [],
  TableControlsComponent: null,
  disabled: false,
  initValue: [],
  propertyName: '',
};
export default FieldCountriesList;
