import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import CountrySelectField from '../../../../../../../components/CountrySelectField';
import InputField from '../../../../../../../components/InputField';

import DeleteButton from '../../../../../../../components/Buttons/DeleteButton';
import './styles.scss';

const JurisdictionItem = ({ preview, namePreffix, index, handleOnRemove }) => {
  const jurisdictionClasses = classNames('jurisdiction__container', {
    'jurisdiction__container--preview': preview,
  });

  return (
    <div className={jurisdictionClasses}>
      <div className="ickyc-corporate-info--responsive__block">
        <Field component={CountrySelectField} name={`${namePreffix}.countryId`} label="Country" preview={preview} />
        <Field
          component={InputField}
          name={`${namePreffix}.taxOrganization`}
          label="Tax Organization"
          type="text"
          preview={preview}
        />
        <Field component={InputField} name={`${namePreffix}.taxId`} label="Tax ID" type="text" preview={preview} />
        <Field component={InputField} name={`${namePreffix}.note`} label="Note" type="text" preview={preview} />
      </div>
      {!preview && (
        <div className="jurisdiction__close-button__container">
          <DeleteButton onClick={() => handleOnRemove(index)} />
        </div>
      )}
    </div>
  );
};

JurisdictionItem.propTypes = {
  index: PropTypes.number.isRequired,
  handleOnRemove: PropTypes.func,
  preview: PropTypes.bool,
  namePreffix: PropTypes.string,
};

JurisdictionItem.defaultProps = {
  handleOnRemove: () => {},
  preview: false,
  namePreffix: 'corporateInformation',
};

export default JurisdictionItem;
