import React from 'react';

export const ModalContext = React.createContext(null);
export const NavigationContext = React.createContext(null);
export const CountriesContext = React.createContext(null);
export const EntityContext = React.createContext(null);
export const CommentsContext = React.createContext(null);
export const CaseContext = React.createContext(null);
export const HistoryRecordContext = React.createContext(null);
export const PermissionGroupContext = React.createContext({});
export const UsersManagementContext = React.createContext({});
export const RCUContext = React.createContext({});
export const PortalManagementContext = React.createContext({});
export const UserManagementContext = React.createContext({});
export const MatchesFilterContext = React.createContext({});
export const BatchContext = React.createContext({});
export const DragContext = React.createContext({});
export const AffiliatedEntitiesErrorContext = React.createContext({});
export const DocumentDataContext = React.createContext({});
