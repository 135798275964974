import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

const activeModals = [];

/**
 * Adds active pop-up into global activeModals array with optional ability to close it on Escape.
 */

export default function({ defaultOpen = false, accessible = false, onOpen, onClose }) {
  const id = useRef(uuid());
  const [open, setOpenState] = useState(defaultOpen);

  useEffect(() => {
    if (accessible) {
      activeModals[open ? 'push' : 'pop'](id.current);
    }
  }, [accessible, open]);

  const setOpen = useCallback(
    value => {
      if (value) {
        if (onOpen?.() === false) return;
      } else if (onClose?.() === false) return;

      setOpenState(value);
    },
    [onOpen, onClose],
  );

  useEffect(() => {
    if (!accessible) return;
    if (open) {
      const onEscape = ev => {
        if (ev.key === 'Escape' && activeModals.slice(-1)[0] === id.current) {
          setOpen(false);
        }
      };

      window.addEventListener('keyup', onEscape);
      return () => {
        window.removeEventListener('keyup', onEscape);
      };
    }
  }, [open, setOpen, accessible]);

  return [open, setOpen];
}
