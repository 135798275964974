import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import KYCServices from 'components/KYCServices';
import Tooltip from 'components/Tooltip';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import enums from 'utilities/enums';
import NaturalPersonTable from '../NaturalPersonTable';
import UploadID from '../UploadID';

const baseClass = 'ickyc-natural-person-modal';

const ModalContent = ({ successIds, setHasEntities }) => {
  const [isUploadIDVisible, setIsUploadIDVisible] = useState(false);
  const [isLoadingID, setIsLoadingID] = useState(false);

  const {
    values: { persons },
  } = useFormState();
  const { mutators } = useForm();

  const addPerson = () => {
    mutators.push('persons', { data: undefined, sessionId: '' });
  };

  const hasPerson = !!persons?.length;

  useEffect(() => {
    setHasEntities(hasPerson);
  }, [hasPerson]);

  return (
    <Box className={classNames({ [`${baseClass}--loading-id`]: isLoadingID })}>
      <Grid container justifyContent="center" spacing={2} className={`${baseClass}__button-container`}>
        <Grid item>
          <Tooltip
            placement="top"
            trigger={
              <div>
                <PrimaryButton
                  onClick={() => {
                    setIsUploadIDVisible(true);
                    setTimeout(() => {
                      setTimeout(() => {
                        bus.broadcastEvent(enums.BUS_EVENTS.SCROLL_BOTTOM_MODAL);
                      });
                    });
                  }}
                >
                  Upload ID
                </PrimaryButton>
              </div>
            }
            content={<span>The system will extract the KYC information associated with the ID</span>}
          />
        </Grid>
        {!hasPerson && (
          <Grid item>
            <OutlineButton onClick={addPerson}>Manual Entry</OutlineButton>
          </Grid>
        )}
      </Grid>

      {hasPerson && (
        <Box>
          <NaturalPersonTable successIds={successIds} />
          <KYCServices complete />
        </Box>
      )}

      {isUploadIDVisible && (
        <UploadID
          isLoadingID={isLoadingID}
          setIsLoadingID={setIsLoadingID}
          hideSection={() => setIsUploadIDVisible(false)}
        />
      )}
    </Box>
  );
};

ModalContent.propTypes = {
  successIds: PropTypes.arrayOf(PropTypes.number),
  setHasEntities: PropTypes.func,
};

ModalContent.defaultProps = {
  successIds: [],
  setHasEntities: () => {},
};
export default ModalContent;
