import { useEffect, useState } from 'react';

const MEDIA_MATCHES = {
  isBelowLaptopLarge: '(max-width: 1440px)',
  isBelowLaptop: '(max-width: 1024px)',
  isBelowTablet: '(max-width: 768px)',
  isBelowMobile: '(max-width: 480px)',
};

export default function useMatchMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const handleResize = () => setMatches(window.matchMedia(MEDIA_MATCHES[query]).matches);

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [query]);

  return matches;
}
