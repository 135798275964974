import { useEffect, useRef, useState } from 'react';

const useVisible = initialValue => {
  const [isVisible, setIsVisible] = useState(initialValue);
  const ref = useRef(null);

  const handleClickOutside = event => {
    if (ref) if (ref.current && !ref.current.contains(event.target)) setIsVisible(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
};

export default useVisible;
