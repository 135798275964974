import React, { useCallback, useEffect, useRef } from 'react';
import OutlineButton from '../../../../Buttons/OutlineButton';
import PrimaryButton from '../../../../Buttons/PrimaryButton';
import './styles.scss';

const LeaveModal = ({ closeLeaveModal, hideParentModal }) => {
  const leaveModalRef = useRef(null);

  const keyDownHandler = useCallback(e => {
    if (e.key !== 'Tab') return;
    e.stopPropagation();

    const focusableModalElements = leaveModalRef.current.querySelectorAll(
      'a[href], button:not([disabled]), textarea, input:not([hidden],[disabled]),span.ickyc-toggle__circle, select',
    );
    const stayButton = focusableModalElements[0];
    const leaveButton = focusableModalElements[1];

    if (document.activeElement === leaveModalRef.current) {
      stayButton.focus();
      return e.preventDefault();
    }

    if (!e.shiftKey && document.activeElement === stayButton) {
      leaveButton.focus();
      return e.preventDefault();
    }
    if (!e.shiftKey && document.activeElement === leaveButton) {
      stayButton.focus();
      return e.preventDefault();
    }
    if (e.shiftKey && document.activeElement === stayButton) {
      leaveButton.focus();
      return e.preventDefault();
    }
    if (e.shiftKey && document.activeElement === leaveButton) {
      stayButton.focus();
      return e.preventDefault();
    }
  }, []);

  useEffect(() => {
    leaveModalRef.current.focus();
  }, []);

  return (
    <div className="ickyc-leave-modal" ref={leaveModalRef} onKeyDown={keyDownHandler} tabIndex={0}>
      <div>
        <h2 className="ickyc-leave-modal__message">
          Are you sure you want to navigate away from this window and discard the data?
        </h2>
        <div>
          <OutlineButton onClick={closeLeaveModal}>Stay</OutlineButton>
          <PrimaryButton onClick={hideParentModal}>Leave</PrimaryButton>
        </div>
      </div>
    </div>
  );
};
export default LeaveModal;
