import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const InputFieldMuiAdapter = props => {
  const { input, meta, ...rest } = props;
  const { value, onChange, ...restInput } = input;
  return (
    <TextField
      {...rest}
      variant="standard"
      helperText={meta.touched ? meta.error : undefined}
      error={meta.error && meta.touched}
      value={value}
      onChange={onChange}
      inputProps={restInput}
    />
  );
};

InputFieldMuiAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
  className: PropTypes.string,
};
InputFieldMuiAdapter.defaultProps = {
  className: undefined,
  meta: { invalid: false, touched: false, error: '', submitError: '', dirtySinceLastSubmit: false },
};

export default InputFieldMuiAdapter;
