import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from '../../../../../../../../api';
import Spinner from '../../../../../../../../components/Spinner';
import bus from '../../../../../../../../modules/bus';
import utilities from '../../../../../../../../utilities';
import enums from '../../../../../../../../utilities/enums';
import capitalSplit from '../../../../../../../../utilities/services/CapitalSplitter';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';
import { EntityContext, HistoryRecordContext } from '../../../../../../../../utilities/services/contexts';
import HistoryRecordControls from '../../../../../components/HistoryRecordControls';
import IssueList from '../../../../../components/IssueList';
import BiometricCheckList from './components/BiometricChecklist';
import BiometricDetails from './components/BiometricDetails';
import LiveFaceMatch from './components/LiveFaceCaptures';
import './index.scss';

const BiometricData = ({ data, onDataFetch }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { entityId } = useContext(EntityContext);
  const { eventHistoryId } = useContext(HistoryRecordContext);
  const [isGenerating, setIsGenerating] = useState(false);

  const generatePdfSummary = () => {
    setIsGenerating(true);
    api.kyc.entityManagement.naturalPerson
      .generateBiometricPDFSummary(entityId, eventHistoryId, data)
      .then(({ data: responseData, headers }) => {
        const fileName = Object.entries(headers)[0][1]
          .split(' ')[1]
          .replace('filename=', '')
          .replace(';', '');
        utilities.downloadFile(responseData, `application/pdf`, `${fileName}.pdf`);
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  const extractDetails = response => {
    const { type = '', biometricConfidence, livenessConfidence, duration, imagesRetained } = response;

    const splittedType = capitalSplit(type);
    let dur = duration;
    if (dur > 100) {
      dur = duration.toString().slice(0, 2);
    }
    return {
      type: splittedType,
      biometricConfidence,
      livenessConfidence,
      duration: dur,
      imagesRetained,
    };
  };

  const extractChecklist = response => {
    const { faceMatch, livenessTest, issues } = response;
    if (issues.some(el => el === 'Manual upload, no Accepted Identity Document present, verification wasn’t initiated'))
      return { faceMatch: null, livenessTest: null };
    return { faceMatch, livenessTest };
  };

  const extractImgSources = response => {
    const { neutralImage, smileImage, sadImage, angryImage, surprisedImage, livenessConfidenceDetails } = response;

    return {
      neutralImage,
      smileImage,
      sadImage,
      angryImage,
      surprisedImage,
      livenessConfidenceDetails,
    };
  };

  const extractControlInfo = response => {
    const { sourceType, sourceDetail, status, portalConfigurationId, portalDomain, portalName } = response;

    return {
      sourceType,
      sourceDetail,
      status,
      portalConfigurationId,
      portalDomain,
      portalName,
    };
  };
  useEffect(() => {
    if (JSON.stringify(data) !== '{}') {
      setIsLoading(false);
      return;
    }
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data: response } = await api.kyc.entityManagement.naturalPerson.getBiometricTableRecord(
          eventHistoryId,
          entityId,
        );
        onDataFetch(response);
      } catch (err) {
        onDataFetch({ message: `Error Loading History Record: ${eventHistoryId}` });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [data, onDataFetch, eventHistoryId, entityId]);

  const updateDetails = useCallback(
    newData => {
      onDataFetch({ ...data, ...newData });
    },
    [onDataFetch, data],
  );

  const handleStatusChange = useCallback(
    (issuesChange, issuesList, __status, allRejected) => {
      bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
        type: 'kyc',
        section: enums.ACCORDION_INDEXES.BIOMETRIC_AUTHENTICATIONS,
        issuesChange,
        allRejected,
      });
      onDataFetch({ ...data, issuesList });
    },
    [onDataFetch, data],
  );

  const classBase = 'ickyc-biometric-record';
  const setSuffix = setClassSuffix(classBase);

  if (isLoading) {
    return (
      <div className={setSuffix('__wrapper')}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={setSuffix('__wrapper')}>
      {data.message ? (
        <div className={setSuffix('__message')}>{data.message}</div>
      ) : (
        <>
          <div className={classBase}>
            <IssueList list={data.issues || []} />

            <div className={setSuffix('__content')}>
              <div className={setSuffix('__content__left')}>
                <BiometricDetails details={extractDetails(data)} handleUpdate={updateDetails} />
              </div>

              <div className={setSuffix('__content__right')}>
                <BiometricCheckList
                  checklist={extractChecklist(data)}
                  generatePdf={generatePdfSummary}
                  isGenerating={isGenerating}
                />

                <LiveFaceMatch sources={extractImgSources(data)} />
              </div>
            </div>
          </div>

          <HistoryRecordControls info={extractControlInfo(data)} onStatusChange={handleStatusChange} />
        </>
      )}
    </div>
  );
};

BiometricData.propTypes = {
  data: PropTypes.shape({
    angryImage: PropTypes.string,
    neutralImage: PropTypes.string,
    sadImage: PropTypes.string,
    smileImage: PropTypes.string,
    biometricConfidence: PropTypes.number,
    livenessConfidence: PropTypes.number,
    duration: PropTypes.number,
    eventId: PropTypes.string,
    faceMatch: PropTypes.bool,
    livenessTest: PropTypes.bool,
    imagesRetained: PropTypes.number,
    issues: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    sourceType: PropTypes.string,
    sourceDetail: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }),
  onDataFetch: PropTypes.func,
};
BiometricData.defaultProps = {
  data: { faceMatch: true, livenessTest: true },
  onDataFetch: () => {},
};

export default BiometricData;
