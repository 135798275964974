import PropTypes from 'prop-types';
import React from 'react';
import Routes from '../../../../components/Routes';
import routes from './routes';

const SettingsSubrouter = ({ className }) => {
  return <Routes routes={routes} className={className} />;
};

SettingsSubrouter.propTypes = {
  className: PropTypes.string,
};
SettingsSubrouter.defaultProps = {
  className: undefined,
};
export default SettingsSubrouter;
