import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../../../../api';
import FormModal from '../../../../../../../components/Modal/FormModal';
import SelectContentTable from '../../../../../../../components/Select/components/SelectContentTable';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import TableHeader from '../../../../../../../components/Tables/Table/components/TableHeader';
import AttachedEntitiesTableRow from './components/AttachedEntitiesTableRow';
import './styles.scss';

const AttachEntitiesModal = ({ hideModal, onSubmit, initialValues }) => {
  const [params, setParams] = useState({ limit: 10, skip: 0, sortOrder: 1, sortCriteria: 'name' });
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    const fetchUsers = () => {
      setIsLoading(true);
      api.kyc.entityManagement
        .getEntitiesTable({ ...params, shortFormat: true })
        .then(({ data: response }) => {
          const mappedList = response.data.map(el => ({ ...el, value: el.entityId, id: el.entityId, label: el.name }));
          setEntities(mappedList);
          setPagination(response.paginationData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchUsers();
  }, [params]);

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  return (
    <FormModal
      hideModal={hideModal}
      title={<h3>Attach Entities To Document</h3>}
      onSubmit={onSubmit}
      className="ickyc-entity-attachments-modal"
      initialValues={{
        entities: initialValues,
      }}
    >
      <Field
        component={SelectField}
        options={entities}
        multipleSelection
        Trigger={<SelectTrigger />}
        Content={
          <SelectContentTable
            tableProps={{
              tableRow: AttachedEntitiesTableRow,
              headerRow: TableHeader,
              handleParamsChange: changeParams,
              pagination,
              headerData: {
                sortCriteria: params.sortCriteria,
                sortOrder: params.sortOrder,
                onClick: setParams,
                items: [
                  {
                    type: 'name',
                    label: 'Name',
                    clickable: true,
                  },
                  {
                    type: 'type',
                    label: 'Type',
                    clickable: true,
                  },
                ],
              },
              updating: isLoading,
              withLoadMore: true,
              withPagination: true,
              className: 'ickyc-existing-entities-table',
            }}
          />
        }
        useFullValue
        name="entities"
      />
    </FormModal>
  );
};

AttachEntitiesModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.shape({})),
};

AttachEntitiesModal.defaultProps = {
  initialValues: [],
};

export default AttachEntitiesModal;
