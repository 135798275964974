import Tooltip from 'components/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import IconManager from 'utilities/services/IconManager';

const CustomControllWithTooltip = ({ isControlActive, eventHandler, icon }) => {
  return (
    <Tooltip
      placement="top"
      trigger={
        <span
          disabled={isControlActive}
          onClick={event => {
            event.stopPropagation();
            eventHandler();
          }}
        >
          {IconManager.get(icon)}
        </span>
      }
      content={<span>Reopen the case to make changes</span>}
    />
  );
};
CustomControllWithTooltip.propTypes = {
  isControlActive: PropTypes.bool,
  eventHandler: PropTypes.func,
  icon: PropTypes.string,
};

export default CustomControllWithTooltip;
