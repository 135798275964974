import validators from 'utilities/services/validators';

const initialAddonsList = {
  adminUsers: {
    text: 'Admin Users',
    fieldName: 'adminUsers',
    placeholder: 'Number of Users',
    isOpen: false,
  },
  platformUsers: {
    text: 'Platform Users',
    fieldName: 'platformUsers',
    placeholder: 'Number of Users',
    isOpen: false,
  },
  naturalPersons: {
    text: 'Natural Persons',
    fieldName: 'naturalPersons',
    placeholder: 'Number of Persons',
    isOpen: false,
  },
  legalEntities: {
    text: 'Legal Entities',
    fieldName: 'legalEntities',
    placeholder: 'Number of Entities',
    isOpen: false,
  },
  supportingDocuments: {
    text: 'Supporting Documents',
    fieldName: 'supportingDocuments',
    placeholder: 'Number of Documents',
    isOpen: false,
  },
  cases: {
    text: 'Cases',
    fieldName: 'cases',
    placeholder: 'Number of Cases',
    isOpen: false,
  },
  riskScreening: {
    text: 'Risk Screening Profiles',
    fieldName: 'riskScreening',
    placeholder: 'Number of Profiles',
    isOpen: false,
  },
  npPortals: {
    text: 'Natural Person Portals',
    fieldName: 'npPortals',
    placeholder: 'Number of Portals',
    isOpen: false,
  },
  npCustomUrls: {
    text: 'Custom URLs for Natural Persons Portals',
    fieldName: 'npCustomUrls',
    placeholder: 'Number of URLs',
    isOpen: false,
  },
  lePortals: {
    text: 'Legal Entities Portals',
    fieldName: 'lePortals',
    placeholder: 'Number of Portals',
    isOpen: false,
  },
  leCustomUrls: {
    text: 'Custom URLs for Legal Entity Portals',
    fieldName: 'leCustomUrls',
    placeholder: 'Number of URLs',
    isOpen: false,
  },
  apiIntegration: {
    text: 'API Integration',
    fieldName: 'apiIntegration',
    isOpen: false,
  },
  basicSupport: {
    text: 'Basic Support',
    fieldName: 'basicSupport',
    isOpen: false,
  },
  prioritySupport: {
    text: 'Priority Support',
    fieldName: 'prioritySupport',
    isOpen: false,
  },
  premiumSupport: {
    text: 'Premium Support',
    fieldName: 'premiumSupport',
    isOpen: false,
  },
};

const defaultAddonsValues = {
  adminUsers: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  platformUsers: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  naturalPersons: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  legalEntities: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  supportingDocuments: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  cases: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  riskScreening: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  npPortals: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  npCustomUrls: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  lePortals: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  leCustomUrls: {
    enabled: false,
    limit: 0,
    fee: 0,
    period: 0,
  },
  apiIntegration: {
    enabled: false,
    fee: 0,
    period: 0,
  },
  basicSupport: {
    enabled: false,
    fee: 0,
    period: 0,
  },
  prioritySupport: {
    enabled: false,
    fee: 0,
    period: 0,
  },
  premiumSupport: {
    enabled: false,
    fee: 0,
    period: 0,
  },
};

const requiredNumber = validators.composeValidators(
  validators.required(' '),
  validators.validateNumberGreaterThanZero(' '),
);

export default {
  initialAddonsList,
  defaultAddonsValues,
  requiredNumber,
};
