import React from 'react';

export default () => {
  return (
    <svg viewBox="0 0 724.000000 715.000000" aria-hidden="true">
      <g transform="translate(0.000000,724.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
        <path
          d="M915 5954 c-11 -2 -45 -9 -75 -15 -198 -40 -398 -208 -490 -412 -64
-143 -61 -38 -58 -1912 l3 -1700 23 -64 c72 -204 194 -346 376 -435 169 -83
-91 -76 2923 -76 2344 0 2696 2 2751 15 263 62 476 269 542 526 19 72 20 129
20 1765 0 1638 -1 1693 -19 1766 -19 72 -67 180 -110 242 -76 112 -230 224
-376 274 l-80 27 -2705 1 c-1488 1 -2714 0 -2725 -2z m5410 -300 c120 -31 218
-113 276 -229 l34 -70 0 -1705 0 -1705 -34 -69 c-18 -37 -46 -82 -62 -101 -42
-48 -126 -102 -193 -125 -59 -20 -76 -20 -2727 -20 -1854 0 -2683 3 -2718 11
-116 25 -245 138 -294 259 l-22 55 -3 1669 c-2 1837 -6 1737 58 1836 65 100
152 166 260 196 36 10 605 12 2705 13 2374 1 2667 -1 2720 -15z"
        />
        <path
          d="M3263 5511 c-82 -38 -111 -146 -57 -211 93 -110 256 -59 258 81 2
106 -105 175 -201 130z"
        />
        <path
          d="M1835 5230 c-242 -48 -369 -238 -310 -464 12 -45 13 -62 4 -73 -30
-35 -2 -150 41 -173 11 -6 20 -19 20 -30 0 -30 38 -124 60 -148 17 -18 20 -35
20 -111 0 -88 -1 -91 -32 -127 -24 -27 -74 -57 -176 -107 -205 -99 -276 -171
-301 -300 l-8 -47 724 0 725 0 -7 43 c-14 84 -72 164 -160 224 -16 11 -84 44
-150 74 -216 95 -235 115 -235 246 0 45 5 85 10 88 15 9 56 94 69 142 6 23 24
55 40 71 37 37 52 113 29 152 -13 23 -13 31 -1 65 10 27 13 69 11 126 -7 148
-73 244 -186 271 -46 10 -53 15 -66 50 -14 34 -20 38 -48 37 -18 -1 -51 -5
-73 -9z"
        />
        <path
          d="M3238 5065 c-38 -26 -58 -67 -58 -122 0 -115 137 -179 228 -106 23
19 42 46 51 73 12 38 12 47 -5 89 -37 88 -137 119 -216 66z"
        />
        <path
          d="M3252 4640 c-98 -60 -96 -202 5 -251 81 -39 165 -8 198 73 50 126
-89 248 -203 178z"
        />
        <path
          d="M3963 4644 c-47 -23 -73 -71 -73 -133 0 -46 4 -56 39 -94 l40 -42
983 -3 c882 -2 987 -1 1025 14 59 22 87 64 88 130 0 61 -23 99 -76 126 -32 17
-100 18 -1014 18 -878 0 -983 -2 -1012 -16z"
        />
        <path
          d="M3276 4220 c-64 -20 -101 -79 -93 -153 3 -40 11 -56 41 -85 33 -34
41 -37 94 -37 72 0 107 21 133 81 25 56 17 104 -23 150 -16 18 -46 38 -66 44
-42 11 -47 11 -86 0z"
        />
        <path
          d="M3953 4060 c-66 -40 -85 -144 -39 -205 52 -68 13 -65 847 -65 730 0
754 1 794 20 101 49 105 195 7 253 -28 16 -85 17 -804 17 -749 0 -774 -1 -805
-20z"
        />
        <path
          d="M3270 3782 c-58 -27 -90 -75 -90 -135 0 -132 161 -192 249 -92 41 47
48 91 23 146 -28 63 -53 82 -115 86 -28 1 -59 -1 -67 -5z"
        />
        <path
          d="M4000 3501 c-34 -4 -77 -37 -95 -71 -22 -43 -19 -116 6 -151 48 -67
-21 -63 1071 -63 976 0 988 0 1015 20 49 37 68 72 68 124 0 52 -19 87 -68 124
-27 20 -38 20 -1000 21 -535 0 -983 -2 -997 -4z"
        />
        <path
          d="M1455 3342 c-121 -42 -153 -88 -236 -342 l-64 -195 2 -355 c2 -424
-11 -390 143 -390 125 0 144 12 143 89 0 28 2 51 6 52 3 0 198 1 434 2 l427 2
0 -46 c0 -32 7 -55 21 -73 20 -25 24 -26 123 -26 157 0 146 -31 146 394 l0
348 -67 206 -67 207 -56 57 c-88 87 -92 88 -534 88 -326 -1 -377 -3 -421 -18z
m799 -406 c25 -73 46 -139 46 -145 0 -8 -118 -11 -425 -11 -234 0 -425 2 -425
4 0 4 72 213 91 264 l9 22 328 0 329 0 47 -134z m-748 -317 c114 -55 103 -224
-16 -260 -174 -52 -267 193 -100 265 47 20 66 20 116 -5z m846 11 c23 -7 49
-25 68 -48 57 -70 35 -179 -45 -216 -96 -46 -205 19 -205 122 0 69 34 118 95
140 39 14 43 14 87 2z"
        />
        <path
          d="M3255 3341 c-52 -31 -68 -58 -72 -114 -3 -40 1 -60 16 -84 43 -68
119 -90 189 -54 72 37 98 118 60 190 -39 75 -127 103 -193 62z"
        />
        <path
          d="M3285 2923 c-107 -27 -141 -170 -58 -247 54 -51 146 -46 199 9 74 79
40 206 -63 234 -40 11 -46 12 -78 4z"
        />
        <path
          d="M3975 2916 c-51 -23 -77 -60 -83 -117 -6 -63 15 -110 63 -139 32 -19
53 -20 799 -20 845 0 817 -2 856 62 31 51 28 128 -7 169 -52 61 -21 59 -845
59 -605 -1 -759 -3 -783 -14z"
        />
        <path
          d="M3251 2473 c-45 -22 -71 -67 -71 -123 0 -74 42 -125 121 -143 85 -21
180 74 164 163 -19 99 -121 148 -214 103z"
        />
        <path
          d="M3252 2041 c-52 -33 -74 -77 -69 -139 12 -164 233 -178 278 -18 14
51 -10 117 -53 147 -44 31 -114 36 -156 10z"
        />
      </g>
    </svg>
  );
};
