import PropTypes from 'prop-types';
import React from 'react';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import iconManager from '../../../../utilities/services/IconManager';
import './styles.scss';

const AutomaticOnDemandControlButtons = ({ onClick, disabled }) => {
  const globalValues = {
    AUTOMATIC: 'Automatic',
    ONDEMAND: 'On-Demand',
  };

  return (
    <span className="ickyc-automatic-ondemand-control-buttons">
      <PrimaryButton onClick={() => onClick({ automaticIDVerification: globalValues.AUTOMATIC })} disabled={disabled}>
        {iconManager.get(iconManager.names.GEAR_GREEN)}
        Automatic Selected
      </PrimaryButton>

      <PrimaryButton
        onClick={() => onClick({ automaticIDVerification: globalValues.ONDEMAND })}
        variant="orange"
        disabled={disabled}
      >
        {iconManager.get(iconManager.names.GEAR_ORANGE)}
        <span>On-Demand Selected</span>
      </PrimaryButton>
    </span>
  );
};

AutomaticOnDemandControlButtons.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

AutomaticOnDemandControlButtons.defaultProps = {
  onClick: () => {},
  disabled: PropTypes.bool,
};

export default AutomaticOnDemandControlButtons;
