import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import DeleteIcon from '../../../assets/DeleteIcon';
import Tooltip from '../../../components/Tooltip';
import IconButton from '../IconButton';
import './styles.scss';

const DeleteButton = ({ onClick, className, tooltip, tooltipPlacement, ...rest }) => {
  const classes = classNames('ickyc-delete-button', className);
  return (
    <Tooltip
      trigger={
        <div>
          <IconButton transparent onClick={onClick} className={classes} {...rest}>
            <DeleteIcon />
          </IconButton>
        </div>
      }
      content={<span>{tooltip}</span>}
      placement={tooltipPlacement}
    />
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string,
};

DeleteButton.defaultProps = {
  transparent: false,
  tooltip: 'Delete',
};

export default DeleteButton;
