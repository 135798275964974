import { useMemo } from 'react';

/**
 *
 * @param {Object} additionalParams
 * Used for making requests with ambigous params
 * @returns {string}
 */
export default function useAdditionalParamsDep(additionalParams) {
  return useMemo(() => JSON.stringify(additionalParams), [additionalParams]);
}
