import { PropTypes } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import RadioButtonField from '../../../../../../../../../components/RadioButtonField';
import RadioGroup from '../../../../../../../../../components/RadioGroup';
import TextEditorField from '../../../../../../../../../components/TextEditorField';
import './styles.scss';

const ConsentGatewayOptionsForm = ({ index }) => {
  const baseClass = 'ickyc-consent-gateway-option-form';
  return (
    <div className={baseClass}>
      <Field name={`modules[${index}].text`} component={TextEditorField} />
      <div className={`${baseClass}__subject`}>If Subject Declines</div>
      <RadioGroup>
        <Field
          component={RadioButtonField}
          name={`modules[${index}].declineOption`}
          type="radio"
          parse={value => Number(value)}
          value={0}
          label="Subject Returns to Previous Module"
        />
        <Field
          component={RadioButtonField}
          name={`modules[${index}].declineOption`}
          type="radio"
          value={1}
          parse={value => Number(value)}
          label="Subject is Sent to Fail Redirect URL"
        />
      </RadioGroup>
    </div>
  );
};
ConsentGatewayOptionsForm.propTypes = {
  index: PropTypes.number.isRequired,
};
export default ConsentGatewayOptionsForm;
