import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/business-manager';

const ENDPOINTS = {
  getBillingInfo: clientId => `${BASE_ROUTE}/clients/${clientId}/billing`,
  updateBillingStatus: (clientId, licenceId, status) =>
    `${BASE_ROUTE}/clients/${clientId}/billing/${licenceId}/pay/${status}`,
};

const getBillingInfo = params => {
  const { clientId, ...rest } = params;
  return axiosInstance.get(ENDPOINTS.getBillingInfo(clientId), { params: rest });
};

const updateBillingStatus = (clientId, licencesId, status) => {
  return axiosInstance.put(ENDPOINTS.updateBillingStatus(clientId, licencesId, status));
};

export default {
  getBillingInfo,
  updateBillingStatus,
};
