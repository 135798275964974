import { FORM_ERROR } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import toastr from 'toastr';
import api from '../../../../api';
import IComplyForm from '../../../../components/Form/IComplyForm';
import InputField from '../../../../components/InputField';
import Section from '../../../../components/Section';
import setClassSuffix from '../../../../utilities/services/ClassManager';
import validators from '../../../../utilities/services/validators';
import './styles.scss';

const ChangePasswordForm = () => {
  const classBase = 'ickyc-change-password-form';
  const setSuffix = setClassSuffix(classBase);
  const handleSaveChanges = async (values, form) => {
    await api.personalSettings
      .resetPassword(values.currentPassword, values.newPassword)
      .then(() => {
        toastr.success('Password successfully reset');
        form.restart();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            toastr.error(err.response.data.errors);
            return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to reset Password' };
      });
  };
  return (
    <Section title="Change Password" divider className={classBase}>
      <IComplyForm
        onSubmit={handleSaveChanges}
        initialValues={{}}
        showControls
        noDiscard
        submitButtonText="Save New Password"
      >
        <span>Existing Password</span>
        <Field
          className={setSuffix('__email')}
          component={InputField}
          name="currentPassword"
          type="password"
          validate={validators.required()}
        />

        <span>New Password</span>
        <Field
          component={InputField}
          name="newPassword"
          validate={validators.composeValidators(
            validators.required('Required'),
            validators.password(
              'Password must contain at least one uppercase letter, one lowercase letter, one digit number and special character',
            ),
          )}
          type="password"
        />

        <span>Confirm New Password</span>
        <Field
          component={InputField}
          name="confirmNewPassword"
          validate={validators.composeValidators(
            validators.required(),
            validators.passwordMatch('Passwords don`t match', 'newPassword'),
          )}
          type="password"
        />
      </IComplyForm>
    </Section>
  );
};
export default ChangePasswordForm;
