const myWorkTabs = [
  {
    label: 'Assigned to Me',
    key: 'assignedToMe',
  },
];

export default {
  myWorkTabs,
};
