import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { CommentsContext } from 'utilities/services/contexts';
import Comment from '../Comment';
import './styles.scss';

/**
 * Parent comment which can display replies
 * @param {string} date - date of the comment creation
 * @param {object} comment - top-level comment fetched from the server
 */

const TopComment = ({ date, comment, type }) => {
  const [replies, setReplies] = useState([]);
  const [wasFetch, setWasFetch] = useState(false);
  const { newReply, setNewReply, apiGetReplies } = useContext(CommentsContext);

  const fetchReplies = async (limit, id) => {
    const { data: response } = await apiGetReplies(id, limit);
    setReplies(response.data);
    setWasFetch(true);
  };

  useEffect(() => {
    const insertReplyLocally = reply => {
      if (wasFetch) {
        setReplies(prev => [...prev, reply]);
      } else {
        fetchReplies(comment.numberOfReplies + 1, comment.commentId);
        setWasFetch(true);
      }
    };

    if (newReply.parentId === comment.commentId) {
      insertReplyLocally(newReply);
      setNewReply({});
    }
  }, [newReply, comment.commentId, comment.numberOfReplies, setNewReply, wasFetch]);

  const removeReplyLocally = replyId => {
    setReplies(replies.filter(rep => rep.commentId !== replyId));
  };

  const renderReplies = () => {
    return replies.map(reply => (
      <Comment
        date={reply.timeCreated}
        comment={reply}
        key={`${reply.body}-${reply.commentId}`}
        removeReplyLocally={() => removeReplyLocally(reply.commentId)}
        type={type}
      />
    ));
  };
  return (
    <Comment
      date={date}
      comment={comment}
      key={`${comment.body}-${comment.commentId}`}
      areRepliesFetchedOutside={wasFetch}
      parent
      hasReplies={Boolean(replies.length)}
      setReplies={setReplies}
      type={type}
    >
      {renderReplies()}
    </Comment>
  );
};

TopComment.propTypes = {
  date: PropTypes.string,
  type: PropTypes.string,
  comment: PropTypes.shape({
    commentId: PropTypes.number,
    body: PropTypes.string,
    editable: PropTypes.bool,
    numberOfReplies: PropTypes.number,
    parentId: PropTypes.number,
    timeCreated: PropTypes.string,
    timeModified: PropTypes.string,
    userFullName: PropTypes.string,
    userId: PropTypes.string,
  }),
};

TopComment.defaultProps = {
  date: '',
  type: 'entity',
  comment: {},
};

export default TopComment;
