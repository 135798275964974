import PropTypes from 'prop-types';
import React from 'react';
import ImagePreview from '../../../../../../../../../../components/ImagePreview';
import setClassSuffix from '../../../../../../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../../../../../../utilities/services/IconManager';
import './index.scss';
/**
 * Displays images in the Document image Section for Identity Document
 * @param {string} frontImageUrl - source for the front of the image
 * @param {string} backImageUrl - back for the front of the image
 */

function DocumentImage({ frontImageUrl, backImageUrl }) {
  const classBase = 'ickyc-document-image';

  const setSuffix = setClassSuffix(classBase);

  return (
    <div className={classBase}>
      <h2>Document Image(s)</h2>

      <div className={setSuffix('__content')}>
        {frontImageUrl ? (
          <ImagePreview src={frontImageUrl} enlargeImage alt="front" />
        ) : (
          <span className={setSuffix('__content__no-image')}>{IconManager.get(IconManager.names.ID_CARD)}</span>
        )}
        {backImageUrl && <ImagePreview src={backImageUrl} enlargeImage alt="back" />}
      </div>
    </div>
  );
}

DocumentImage.propTypes = {
  frontImageUrl: PropTypes.string,
  backImageUrl: PropTypes.string,
};

DocumentImage.defaultProps = {
  frontImageUrl: '',
  backImageUrl: '',
};

export default DocumentImage;
