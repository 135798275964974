import React from 'react';

export default () => (
  <svg version="1.2" baseProfile="tiny" id="Layer_1" x="0px" y="0px" viewBox="0 0 256 265" overflow="visible">
    <g>
      <path
        fill="#4972D2"
        d="M158.56,15.25v52.36c0,4.84,3.94,8.76,8.65,8.76h43.74L158.56,15.25z M71.25,207.34v8.73h113.51v-8.73
		H71.25z M71.25,181.15v8.73h78.58v-8.73H71.25z M71.25,154.95v8.73h113.51v-8.73H71.25z M71.25,128.76v8.73h87.31v-8.73H71.25z
		 M71.25,102.56v8.73h113.51v-8.73H71.25z M71.25,50.17v8.73h61.12v-8.73H71.25z M71.25,76.37v8.73h43.66v-8.73H71.25z
		 M149.83,15.25v52.41c0,9.7,7.84,17.45,17.52,17.45h43.6v148.46c0,9.67-7.81,17.44-17.44,17.44H62.5
		c-9.66,0-17.44-7.85-17.44-17.52V32.78c0-9.68,7.85-17.52,17.54-17.52h87.23L149.83,15.25z"
      />
    </g>
  </svg>
);
