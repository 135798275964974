import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import enums from '../../../utilities/enums';
import IconCombinator from './components/IconCombinator';
import './styles.scss';

/**
 * Button used for sorting displaying label and appropriate arrow-like-icons.
 * @param {*} children - button content
 * @param {function} onClick - on click handler
 * @param {string} sortType - active sort type
 */

const SortingButton = ({ children, onClick, sortType }) => {
  const [activeIcon, setActiveIcon] = useState();
  const [activeSortType, setActiveSortType] = useState();
  useEffect(() => {
    setActiveSortType(sortType);
  }, [sortType]);
  useEffect(() => {
    switch (activeSortType) {
      // TODO: Rista, please fix the icons, you implemented the logic vice-versa
      case enums.SORTING.DESCENDING:
        setActiveIcon(<IconCombinator bottom={<ArrowDropDown />} />);
        break;

      case enums.SORTING.ASCENDING:
        setActiveIcon(<IconCombinator top={<ArrowDropUp />} />);
        break;

      case enums.SORTING.CHAOTIC:
        setActiveIcon(<IconCombinator top={<ArrowDropUp />} bottom={<ArrowDropDown />} />);
        break;

      default:
        break;
    }
  }, [activeSortType]);
  return (
    <button onClick={onClick} type="button" className="ickyc-sorting-button">
      {children}
      <div className="ickyc-sorting-button__icons">{activeIcon}</div>
    </button>
  );
};

SortingButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  sortType: PropTypes.number,
};

SortingButton.defaultProps = {
  onClick: () => {},
  sortType: enums.SORTING.CHAOTIC,
};

export default SortingButton;
