/* eslint-disable react/jsx-indent-props */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import useTablePagination from '../../../hooks/useTablePagination';
import Loader from '../../Loader';
import TableNavigation from '../../TableNavigation';

const FieldArrayTable = ({
  fieldName,
  onClick,
  tableRow: TableRow,
  headerRow: HeaderRow,
  tableControls: TableControls,
  updating,
  headerData,
  pagination,
  handleParamsChange,
  withPagination,
  withLimitChange,
  onRadioClick,
  onControlsClick,
  onCheckboxClick,
  withLoadMore,
  className,
  onRemove,
  noResultMessage,
  ...rest
}) => {
  const tableNavigationProps = useTablePagination(handleParamsChange, pagination);

  const baseClass = 'ickyc-table';

  const classes = classNames([[className], [baseClass]]);

  return (
    <div className={classes}>
      {updating && <Loader />}
      {TableControls && (
        <div className={`${baseClass}__controls`}>
          <TableControls />
        </div>
      )}
      <table>
        {HeaderRow && <HeaderRow className={baseClass} {...headerData} onClick={handleParamsChange} />}
        <tbody>
          <FieldArray name={fieldName}>
            {({ fields }) => {
              return fields.length
                ? fields.map((name, index) => {
                    return (
                      <TableRow
                        namePreffix={name}
                        className={baseClass}
                        onRadioClick={onRadioClick}
                        onClick={onClick}
                        onControlsClick={onControlsClick}
                        onCheckboxClick={onCheckboxClick}
                        onRemove={onRemove}
                        index={index}
                        key={`${name + index}`}
                        {...rest}
                      />
                    );
                  })
                : `${noResultMessage}`;
            }}
          </FieldArray>
        </tbody>
      </table>

      {withPagination && (
        <TableNavigation withLoadMore={withLoadMore} withLimitChange={withLimitChange} {...tableNavigationProps} />
      )}
    </div>
  );
};

FieldArrayTable.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.shape({
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
    total: PropTypes.number,
  }),
  withPagination: PropTypes.bool,
  handleParamsChange: PropTypes.func,
  headerData: PropTypes.shape({
    sortCriteria: PropTypes.string,
    sortOrder: PropTypes.number,
  }),
  tableRow: PropTypes.elementType.isRequired,
  headerRow: PropTypes.elementType.isRequired,
  tableControls: PropTypes.elementType,

  withLimitChange: PropTypes.bool,
  onRemove: PropTypes.func,
  withRadioColumn: PropTypes.bool,
  onRadioClick: PropTypes.func,
  onClick: PropTypes.func,
  onCheckboxClick: PropTypes.func,
  withLoadMore: PropTypes.bool,
  updating: PropTypes.bool,
  onControlsClick: PropTypes.func,
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  noResultMessage: PropTypes.string,
};
FieldArrayTable.defaultProps = {
  withPagination: false,
  values: [],
  pagination: {},
  handleParamsChange: () => {},
  onCheckboxClick: () => {},
  onRadioClick: () => {},
  onClick: () => {},
  onControlsClick: () => {},
  onRemove: () => {},
  headerData: {},
  withLimitChange: false,
  withLoadMore: false,
  withRadioColumn: false,
  updating: false,
  tableControls: null,
  className: undefined,
  noResultMessage: 'No Results',
};
export default FieldArrayTable;
