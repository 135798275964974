import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/client-account/automations';

const ENDPOINTS = {
  createAutomationsConfiguration: () => `${BASE_ROUTE}`,
  updateAutomationsConfiguration: () => `${BASE_ROUTE}`,
  getAutomationsConfiguration: () => `${BASE_ROUTE}`,
};

const createAutomationsConfiguration = data => axiosInstance.post(ENDPOINTS.createAutomationsConfiguration(), data);
const updateAutomationsConfiguration = data => axiosInstance.put(ENDPOINTS.updateAutomationsConfiguration(), data);
const getAutomationsConfiguration = () => axiosInstance.get(ENDPOINTS.getAutomationsConfiguration(), {});

export default {
  createAutomationsConfiguration,
  updateAutomationsConfiguration,
  getAutomationsConfiguration,
};
