import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import PrimaryButton from '../../../../../../../../../../components/Buttons/PrimaryButton';
import SelectFilterContent from '../../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../../components/SelectField';
import enums from '../../../../../../../../../../utilities/enums';
import IconManager from '../../../../../../../../../../utilities/services/IconManager';
import validators from '../../../../../../../../../../utilities/services/validators';
import { DOCUMENT_TYPES } from '../../utilities/documentUploadEnums';

const DocumentTypeSelection = ({ setIsPassport }) => {
  const {
    values: { scanDocumentType },
  } = useFormState();

  const documentTypes = [
    {
      text: 'Passport',
      icon: IconManager.get(IconManager.names.PASSPORT),
      value: DOCUMENT_TYPES.PASSPORT,
    },
    {
      text: "Driver's Licence",
      icon: IconManager.get(IconManager.names.DRIVERS_LICENCE),
      value: DOCUMENT_TYPES.DRIVERS_LICENCE,
    },
    {
      text: 'ID',
      icon: IconManager.get(IconManager.names.ID_CARD),
      value: DOCUMENT_TYPES.ID,
    },
  ];

  const { change } = useForm();

  const handleOnClick = useCallback(
    (event, value) => {
      event.stopPropagation();
      if (scanDocumentType !== value) {
        change('image', null);
        change('backImage', null);
      }
      setIsPassport(value === DOCUMENT_TYPES.PASSPORT);
    },
    [scanDocumentType, change, setIsPassport],
  );
  const mappedStatuses = useMemo(() => {
    return Object.entries(enums.HISTORY_EVENT_STATUSES).map(status => ({
      id: status[0],
      label: status[1],
      value: status[1],
    }));
  }, []);

  return (
    <div className="ickyc-document-type-selection">
      {/* <Field
        component={CountrySelectField}
        useFullValue
        label="What Country is your government issued ID from?"
        name="documentIssuanceCountry"
        validate={validators.required('Required')}
      /> */}
      <Field name="scanDocumentType" validate={validators.required('Select Document Type')}>
        {({ input, meta: { error, touched } }) => (
          <div className="ickyc-document-type-selection__buttons">
            <span>Select Document Type</span>
            {documentTypes.map(element => (
              <PrimaryButton
                type="submit"
                onClick={e => {
                  handleOnClick(e, element.value);
                  input.onChange(element.value);
                }}
                n
              >
                {element.icon}
                {element.text}
              </PrimaryButton>
            ))}
            {error && touched && <span className="ickyc-document-type-selection__buttons--error">{error}</span>}
          </div>
        )}
      </Field>
      <Field
        component={SelectField}
        name="status"
        initialValue={enums.HISTORY_EVENT_STATUSES.REVIEW}
        Trigger={<SelectTrigger />}
        Content={<SelectFilterContent />}
        autoClose
        renderAsPortal
        options={mappedStatuses}
        label="Status"
      />
    </div>
  );
};

DocumentTypeSelection.propTypes = {
  setIsPassport: PropTypes.func,
};
DocumentTypeSelection.defaultProps = {
  setIsPassport: () => {},
};
export default DocumentTypeSelection;
