import { useEffect, useState } from 'react';

export default file => {
  const [previewData, setPreviewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fileString = JSON.stringify(file);

  useEffect(() => {
    const readCSVData = async () => {
      setIsLoading(true);
      const reader = new FileReader();

      reader.onload = function() {
        const dataString = reader.result;
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const dataArray = dataStringLines
          .slice(1, dataStringLines.length)
          .filter(dsl => !dsl.split(',').every(dslRow => dslRow === '' || dslRow === ' '))
          .map(dsl =>
            dsl.split(/,(?=(?:[^\"]*\"[^\"]*\")*(?![^\"]*\"))/g).reduce(
              (acc, curr, index) => ({
                ...acc,
                [headers[index]]: curr.replace(/^"|"$/g, ''),
              }),
              {},
            ),
          );
        setPreviewData(dataArray);
      };
      reader.readAsText(file);
      setIsLoading(false);
    };
    setTimeout(() => {
      file && readCSVData();
    }, 300);
  }, [fileString]);

  return { previewData, isLoading };
};
