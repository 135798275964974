const initialValues = {
  includeImages: false,
  includeAcceptedOnly: true,
  includeIdDocsImages: false,
  includeFaceMatchImages: false,
  includeSupportingDocs: false,
  includeAmlCases: false,
  includeOnlyAcceptedMatches: false,
  format: 'pdf',
};

const initialParams = { limit: 5, skip: 0, sortOrder: 1, sortCriteria: 'name' };

export default {
  initialValues,
  initialParams,
};
