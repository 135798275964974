import axiosInstance from '../axiosInstance';

const getCountries = () => axiosInstance.get('/country');

const getStateProvincesByCountryIdApi = countryId =>
  axiosInstance.get(`/state-provinces/`, {
    params: {
      countryId,
    },
  });

const transformArrayToQueryString = (namesList, propName = 'Names') =>
  namesList.map(name => `${propName}=${encodeURIComponent(name)}`).join('&');
const getCurrencies = () => axiosInstance.get('/currency');

export default {
  getCountries,
  getStateProvincesByCountryIdApi,
  transformArrayToQueryString,
  getCurrencies,
};
