import React from 'react';

export default () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="7.5" stroke="#DE350B" stroke-width="2" />
      <path d="M6.25 10H13.75" stroke="#DE350B" stroke-width="2" />
    </svg>
  );
};
