import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const SelectFieldMuiAdapter = props => {
  const {
    className,
    options,
    input,
    meta: { touched, error },
    label,
    loading,
    ...rest
  } = props;

  const hasError = useMemo(() => !!(touched && error), [error, touched]);

  return (
    <FormControl className={className} error={hasError}>
      <InputLabel variant="standard">{label}</InputLabel>
      <Select {...input} error={hasError} variant="standard" {...rest}>
        {options.map(option => (
          <MenuItem key={`${option.key}-${option.text}`} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectFieldMuiAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  className: PropTypes.string,
  preview: PropTypes.bool,
  label: PropTypes.string,
};
SelectFieldMuiAdapter.defaultProps = {
  className: undefined,
  meta: { invalid: false, touched: false, error: '', submitError: '', dirtySinceLastSubmit: false },
  preview: false,
  loading: false,
  options: [],
  label: undefined,
};

export default SelectFieldMuiAdapter;
