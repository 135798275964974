import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import toastr from 'toastr';
import api from '../../../../../api';
import Select from '../../../../../components/Select';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import { BatchContext } from '../../../../../utilities/services/contexts';
import './styles.scss';

const BatchAssignDropdown = ({
  assignedUserId,
  assignEvent,
  eventId,
  onBatchUpdate,
  assignCase,
  complianceLogId,
  assignUser,
  disabled,
  renderAsPortal,
  label,
  preview,
  hint,
  listingId,
  assignlisting,
  hitId,
  logId,
  useFullValue,
  className,
  entityId,
  setUpdating,
  ...rest
}) => {
  const { listOfSelectedIDs } = useContext(BatchContext) || [];
  const usersToAssign = useSelector(state =>
    state.global.usersToAssign.map(({ id: uId, name }) => ({
      label: name,
      value: uId,
      id: uId,
    })),
  );

  const entityIds = useMemo(() => {
    if (!listOfSelectedIDs.length) {
      return [entityId];
    }
    if (!listOfSelectedIDs.some(eId => eId === entityId)) {
      return [...listOfSelectedIDs, entityId];
    }
    return listOfSelectedIDs;
  }, [listOfSelectedIDs]);

  const batchAssignUsers = useCallback(
    newUser => {
      setUpdating(entityIds);
      const submitObj = {
        entityIds,
        assignedUserId: newUser,
      };
      api.kyc
        .batchAssignUser(submitObj)
        .then(response => {
          onBatchUpdate(response.data);
          toastr.success('Entities successfully updated!');
        })
        .catch(err => {
          console.log(err);
          toastr.error('Error occurred during update');
        })
        .finally(() => {
          setUpdating([]);
        });
    },
    [entityId, onBatchUpdate, entityIds],
  );

  const handleUserAssign = newUser => {
    batchAssignUsers(newUser);
  };
  return (
    <Select
      value={assignedUserId}
      onChange={handleUserAssign}
      options={usersToAssign}
      useFullValue={false}
      renderAsPortal={false}
      disabled={disabled}
      label={label}
      preview={preview}
      hint={hint}
      Trigger={<SelectTrigger placeholder="N/A" withoutArrow />}
      Content={<SelectFilterContent />}
      className={className}
      {...rest}
    />
  );
};
BatchAssignDropdown.propTypes = {
  assignedUserId: PropTypes.string,
  assignEvent: PropTypes.bool,
  eventId: PropTypes.string,
  onBatchUpdate: PropTypes.func,
  assignCase: PropTypes.bool,
  assignUser: PropTypes.bool,
  complianceLogId: PropTypes.string,
  disabled: PropTypes.bool,
  renderAsPortal: PropTypes.bool,
  label: PropTypes.string,
  preview: PropTypes.bool,
  hint: PropTypes.string,
  listingId: PropTypes.string,
  assignlisting: PropTypes.bool,
  hitId: PropTypes.string,
  logId: PropTypes.string,
  input: PropTypes.shape(PropTypes.object),
  useFullValue: PropTypes.bool,
  className: PropTypes.string,
  entityId: PropTypes.string.isRequired,
  setUpdating: PropTypes.func,
};
BatchAssignDropdown.defaultProps = {
  assignedUserId: undefined,
  assignCase: false,
  assignEvent: false,
  eventId: undefined,
  complianceLogId: undefined,
  assignUser: undefined,
  onBatchUpdate: () => {},
  renderAsPortal: false,
  disabled: false,
  label: undefined,
  preview: false,
  hint: undefined,
  listingId: undefined,
  assignlisting: false,
  hitId: undefined,
  logId: undefined,
  input: {},
  useFullValue: true,
  className: undefined,
  setUpdating: () => {},
};

export default BatchAssignDropdown;
