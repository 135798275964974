import classNames from 'classnames';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api';
import IComplyForm from '../../../components/Form/IComplyForm';
import BareHeading from '../../../components/Headings/BareHeading';
import Loader from '../../../components/Loader';
import Section from '../../../components/Section';
import Spinner from '../../../components/Spinner';
import setClassSuffix from '../../../utilities/services/ClassManager';
import { PermissionGroupContext } from '../../../utilities/services/contexts';
import BiometricAuthenticationSettings from './components/FacialMatchingSettings';

import './styles.scss';

const biometricAuthenticationTypes = ['Selfie Upload', 'Live Face Match'];

const BiometricAuthenticationPage = ({ className }) => {
  const [initialValues, setInitialValues] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [updatingStatus, setUpdatingStatus] = useState(false);

  const { edit: canEdit } = useContext(PermissionGroupContext);

  useEffect(() => {
    const loadInitialValues = () => {
      setIsFetching(true);
      api.administration
        .getFacialMatchingInfo()
        .then(({ data: responseData }) => {
          const { facialMatching, biometricTypes } = responseData;

          setInitialValues({
            biometricRestrictions: biometricTypes.reduce(
              (accumulator, { countryId, biometricAuthenticationType }) => ({
                ...accumulator,
                [`country${countryId}`]: {
                  biometricAuthenticationType: biometricAuthenticationTypes[biometricAuthenticationType],
                },
              }),
              {},
            ),
            biometricConfidance: facialMatching.biometricConfidence,
            livenessConfidence: facialMatching.livenessConfidence,
            facialMatching: {
              finalTestSmile: facialMatching.finalTestHappy,
              liveness: facialMatching.failbackToSelfieUpload,
              ...facialMatching,
            },
            documentAuthAutoAcceptance: facialMatching.facialMatchingAutoAcceptance,
          });
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => setIsFetching(false));
    };
    loadInitialValues();
  }, []);

  const handleSubmit = vals => {
    const { biometricRestrictions, livenessConfidence, documentAuthAutoAcceptance, facialMatching } = diff(
      initialValues,
      vals,
    );

    const submitObject = {};
    if (biometricRestrictions) {
      submitObject.biometricTypes = Object.entries(biometricRestrictions).map(country => ({
        [`countryId`]: Number(country[0].replace('country', '')),
        [`biometricAuthenticationType`]: biometricAuthenticationTypes.indexOf(country[1].biometricAuthenticationType),
      }));
    }
    if (facialMatching) {
      submitObject.facialMatching = {
        ...facialMatching,
      };
    }
    if (livenessConfidence) {
      submitObject.facialMatching.livenessConfidence = livenessConfidence;
    }
    if (documentAuthAutoAcceptance) {
      submitObject.facialMatching.facialMatchingAutoAcceptance = documentAuthAutoAcceptance;
    }
    setUpdatingStatus(true);
    api.administration
      .updateFacialMatchingInfo(submitObject)
      .then(() => {})
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => setUpdatingStatus(false));
  };

  const baseClass = 'ickyc-page';
  const componentClass = 'ickyc-biometric-authentication';
  const setSuffix = setClassSuffix(baseClass);

  const classes = classNames(baseClass, componentClass, className);

  const contentClasses = classNames({
    [setSuffix('__content')]: true,
  });

  return (
    <div className={classes}>
      {updatingStatus && <Loader />}
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <BareHeading title="Facial Matching" />
          <Section>
            <div className="ickyc-page__info">
              <p>
                Prompt your user to provide an image of their face so their identity can be matched to their identity
                document.
                <br />
                <br />
                This Facial Match can be performed through either a <strong>Live Face Match</strong> or a &nbsp;
                <strong>Selfie Upload.</strong>
              </p>
            </div>
          </Section>
          <div className={contentClasses}>
            <IComplyForm initialValues={initialValues} showControls={canEdit} onSubmit={handleSubmit}>
              <BiometricAuthenticationSettings
                biomtricConfidenceSlider
                prefix="facialMatching"
                showRejectionMessageField
                disabled={!canEdit}
              />
            </IComplyForm>
          </div>
        </>
      )}
    </div>
  );
};

BiometricAuthenticationPage.propTypes = {
  className: PropTypes.string,
};

BiometricAuthenticationPage.defaultProps = {
  className: undefined,
};
export default BiometricAuthenticationPage;
