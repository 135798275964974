import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { selectUserAccessByLicense } from 'store/selectors/auth.selector';
import setClassSuffix from 'utilities/services/ClassManager';
import DashboardTile from '../DashboardTile';
import './styles.scss';

const PortalsCountTile = ({ currentUsage, licenceLimits, isLoading }) => {
  const baseClass = 'ickyc-portals-count-tile';
  const setSuffix = setClassSuffix(baseClass);
  const history = useHistory();
  const platformAccess = useSelector(selectUserAccessByLicense);

  const navigateToPortalManagement = useCallback(() => {
    history.push('/administration/portal-management', {
      openFromDashboard: true,
    });
  }, [history]);

  const hasAccessByLicence = useMemo(() => {
    const licenceAccessKey = 'Portal Management';
    return platformAccess.some(el => el === licenceAccessKey);
  }, []);

  return (
    <DashboardTile
      header="Portals"
      buttonText="Add Portal"
      className={baseClass}
      isLoading={isLoading}
      buttonAction={navigateToPortalManagement}
      hideButton={!hasAccessByLicence}
    >
      <div className={setSuffix('__content-box')}>
        {!!licenceLimits.npPortals && (
          <div className={setSuffix('__count-box')}>
            <span className={setSuffix('__count')}>{`${currentUsage.npPortals}/${licenceLimits.npPortals}`}</span>
            <span className={setSuffix('__count-label')}>Natural Person</span>
          </div>
        )}
        {!!(licenceLimits.npPortals && licenceLimits.lePortals) && <span className={setSuffix('__separator')} />}
        {!!licenceLimits.lePortals && (
          <div className={setSuffix('__count-box')}>
            <span className={setSuffix('__count')}>{`${currentUsage.lePortals}/${licenceLimits.lePortals}`}</span>
            <span className={setSuffix('__count-label')}>Legal Entity</span>
          </div>
        )}
      </div>
    </DashboardTile>
  );
};
PortalsCountTile.propTypes = {
  currentUsage: PropTypes.shape({
    npPortals: PropTypes.number,
    lePortals: PropTypes.number,
  }),
  licenceLimits: PropTypes.shape({
    npPortals: PropTypes.number,
    lePortals: PropTypes.number,
  }),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

PortalsCountTile.defaultProps = {
  className: undefined,
  currentUsage: undefined,
  licenseLimit: undefined,
  isLoading: true,
};
export default PortalsCountTile;
