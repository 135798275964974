import React from 'react';
import { createRoot } from 'react-dom/client';
import ErrorPage from 'router/components/ErrorPage';
import App from './App';
import * as serviceWorker from './serviceWorker';

const root = createRoot(document.getElementById('root'));
let errorEvent = null;

const errorCallback = () => root.render(<ErrorPage event={errorEvent} />);

root.render(<App errorCallback={errorCallback} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// This will catch any error
window.addEventListener('error', event => {
  errorEvent = event;
});

serviceWorker.unregister();
