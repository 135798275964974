import { useCallback, useState } from 'react';

export default (initIsOpen = false) => {
  const [isOpen, setIsOpen] = useState(initIsOpen);

  const open = useCallback(e => {
    if (e) {
      e.stopPropagation();
    }
    setIsOpen(true);
  }, []);

  const close = useCallback(e => {
    if (e) {
      e.stopPropagation();
    }
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    open,
    close,
  };
};
