import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import IconButton from '../IconButton';

const CloseButton = ({ onClick, transparent, className }) => {
  return (
    <IconButton transparent={transparent} onClick={onClick} className={className}>
      <Close />
    </IconButton>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  transparent: PropTypes.bool,
};

CloseButton.defaultProps = {
  transparent: false,
};

export default CloseButton;
