import SupportRobotIcon from 'assets/SupportRobotIcon';
import React from 'react';
import './styles.scss';

const ErrorPage = ({ event }) => {
  const url = event?.currentTarget?.location?.href;
  const description = event?.reason?.stack || event?.error?.stack;
  const trimmedDescription = `${description?.slice(0, 252)}...`;

  return (
    <div className="kyc-error-page">
      <main>
        <SupportRobotIcon />
        <p>
          Oops! Our gears are shifting right now, but something seems to have gone a little haywire. No need to worry
          though! Our trusty team is hard at work resolving the issue, and we’ll have everything up and running soon. If
          you need immediate assistance, don’t hesitate to contact our support team using the button below. Thank you
          for your patience and trust in iComply Investor Services!
        </p>
      </main>
      <footer>
        <a className="ickyc-button ickyc-button--primary" href={`${process.env.REACT_APP_HOST_URL}/kyc/home-screen`}>
          Back to Dashboard
        </a>
        <a
          className="ickyc-button ickyc-button--primary"
          href={`${process.env.REACT_APP_HOST_URL}/kyc/home-screen?showStopper=10051&description=${trimmedDescription}&url=${url}`}
        >
          Contact Support
        </a>
      </footer>
    </div>
  );
};
export default ErrorPage;
