/* eslint-disable jsx-a11y/no-autofocus */
import classNames from 'classnames';
import useVisibleInViewport from 'hooks/useVisibleInViewport';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { SelectContext } from '../..';
import IconManager from '../../../../utilities/services/IconManager';
import './styles.scss';

const SelectFilterContent = ({ ContentComponent }) => {
  const {
    hoverIndex,
    onChange,
    filterProps: { inputProps, suggestions },
  } = useContext(SelectContext);
  const inputRef = useRef();
  const isVisible = useVisibleInViewport(inputRef);

  useEffect(() => {
    isVisible && inputRef.current.focus();
  }, [isVisible]);

  return (
    <div className="ickyc-select-filter-content">
      <input ref={inputRef} {...inputProps} onClick={e => e.stopPropagation()} />

      <ul>
        {suggestions.map((suggestion, index) => {
          const optionClasses = classNames({
            hovered: hoverIndex === index,
            selected: false,
          });

          return (
            <li
              className={optionClasses}
              key={suggestion.id}
              onClick={e => {
                e.stopPropagation();
                onChange(suggestion);
              }}
            >
              {ContentComponent ? <ContentComponent {...suggestion} /> : suggestion.label}
              {false && <span>{IconManager.get(IconManager.names.CHECK_FILLED)}</span>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

SelectFilterContent.propTypes = {
  ContentComponent: PropTypes.node,
};
SelectFilterContent.defaultProps = {
  ContentComponent: undefined,
};
export default SelectFilterContent;
