import classNames from 'classnames';
import InputField from 'components/InputField';
import ToggleField from 'components/ToggleField';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import validators from 'utilities/services/validators';
import LicenceItem from '../LicenceItem';
import LicenceModule from '../LicenceModule';
import PlusComponent from '../PlusComponent';

import './styles.scss';

const modules = [
  { name: 'Core', valueName: 'core', toggled: true },
  { name: 'Onboarding', valueName: 'onboarding', toggled: false },
  { name: 'Due Diligence', valueName: 'dueDiligence', toggled: false },
  { name: 'Advanced Workflow', valueName: 'advancedWorkflow', toggled: false },
  { name: 'AML', valueName: 'aml', toggled: false },
  { name: 'Technical', valueName: 'technical', toggled: false },
];

const defaultModules = {
  dueDiligence: {
    supportingDocumentPackagesEnabled: false,
    riskLevelsEnabled: false,
  },
  aml: {
    ongoingMonitoringEnabled: false,
  },
  onboarding: {
    npCustomUrlsEnabled: false,
    leCustomUrlsEnabled: false,
    portalManagementEnabled: false,
    emailSettingsEnabled: false,
  },
  advancedWorkflow: {
    automationsEnabled: false,
    riskScoringEnabled: false,
    quickSetupSettingsEnabled: false,
  },
  technical: {
    webhooksEnabled: false,
    apiEnabled: false,
  },
};

const requiredNumber = validators.composeValidators(
  validators.required(' '),
  validators.validateNumberGreaterThanZero(' '),
);

const ModulesSection = ({ initialModules, namePreffix, divider }) => {
  const [showModules, setShowModules] = useState(modules);

  const { batch, change } = useForm();

  const fieldPreffix = useMemo(() => (namePreffix ? `${namePreffix}.modules` : 'modules'));

  const classes = classNames('module-section', {
    'module-section--with-divider': divider,
  });

  const {
    input: { value: npCustomUrlsToggle },
  } = useField(`${fieldPreffix}.onboarding.npCustomUrlsEnabled`);

  const {
    input: { value: leCustomUrlsToggle },
  } = useField(`${fieldPreffix}.onboarding.leCustomUrlsEnabled`);

  const {
    input: { value: supportingDocumentsToggle },
  } = useField(`${fieldPreffix}.dueDiligence.supportingDocumentPackagesEnabled`);

  const handleShowModule = useCallback(
    name => {
      const module = showModules.find(module => module.name === name);

      batch(() => {
        change(`${fieldPreffix}.${module.valueName}`, module.toggled ? undefined : defaultModules[module.valueName]);
      }, []);

      setShowModules(prevModules => {
        return prevModules.map(module => {
          if (module.name === name) {
            return { ...module, toggled: !module.toggled };
          } else {
            return module;
          }
        });
      });
    },
    [batch, change, showModules],
  );

  useEffectSkipFirst(() => {
    batch(() => {
      change(`${fieldPreffix}.onboarding.npCustomUrlsLimit`, npCustomUrlsToggle === false ? 0 : undefined);
    }, []);
  }, [npCustomUrlsToggle, batch, change]);

  useEffectSkipFirst(() => {
    batch(() => {
      change(`${fieldPreffix}.onboarding.leCustomUrlsLimit`, leCustomUrlsToggle === false ? 0 : undefined);
    }, []);
  }, [leCustomUrlsToggle, batch, change]);

  useEffectSkipFirst(() => {
    batch(() => {
      change(
        `${fieldPreffix}.dueDiligence.supportingDocumentsLimit`,
        supportingDocumentsToggle === false ? 0 : undefined,
      );
    }, []);
  }, [supportingDocumentsToggle, batch, change]);

  useEffect(() => {
    setShowModules(prevModules => {
      return prevModules.map(module => {
        const initialModule = initialModules?.[module.valueName];
        if (initialModule) {
          return {
            ...module,
            toggled: true,
          };
        }
        return module;
      });
    });
  }, [initialModules]);

  return (
    <section className={classes}>
      <div className="module-section__header">
        <h3>Modules</h3>
      </div>

      <div className="module-section__content">
        {showModules.map(({ name, toggled }) => (
          <PlusComponent isChecked={toggled} text={name} handleShow={handleShowModule} />
        ))}
      </div>

      {showModules.find(module => module.name === 'Core').toggled && (
        <LicenceModule
          title="Core"
          key="core-module"
          headeritems={
            <>
              <LicenceItem text="Admin Users" noButtons>
                <Field
                  component={InputField}
                  name={`${fieldPreffix}.core.adminUsersLimit`}
                  type="number"
                  placeholder="Number of Users"
                  validate={validators.required(' ')}
                />
              </LicenceItem>
              <LicenceItem text="Platform Users" noButtons>
                <Field
                  component={InputField}
                  name={`${fieldPreffix}.core.platformUsersLimit`}
                  type="number"
                  placeholder="Number of Users"
                  validate={validators.required(' ')}
                />
              </LicenceItem>
            </>
          }
        >
          <Field component={ToggleField} name={`${fieldPreffix}.core.userManagementEnabled`} label="User Management" />
          <Field
            component={ToggleField}
            name={`${fieldPreffix}.core.filesManagementEnabled`}
            label="Files Management"
          />
          <Field component={ToggleField} name={`${fieldPreffix}.core.notificationEnabled`} label="Notifications" />
        </LicenceModule>
      )}

      {showModules.find(module => module.name === 'Due Diligence').toggled && (
        <LicenceModule
          title="Due Diligence"
          handleHide={() => handleShowModule('Due Diligence')}
          headeritems={
            <>
              <LicenceItem text="Natural Persons" noButtons>
                <Field
                  component={InputField}
                  name={`${fieldPreffix}.dueDiligence.naturalPersonsLimit`}
                  type="number"
                  placeholder="Number of Persons"
                  validate={validators.required(' ')}
                />
              </LicenceItem>
              <LicenceItem text="Legal Entities" noButtons>
                <Field
                  component={InputField}
                  name={`${fieldPreffix}.dueDiligence.legalEntitiesLimit`}
                  type="number"
                  placeholder="Number of Entities"
                  validate={validators.required(' ')}
                />
              </LicenceItem>
            </>
          }
        >
          <LicenceItem
            text={
              <Field
                component={ToggleField}
                name={`${fieldPreffix}.dueDiligence.supportingDocumentPackagesEnabled`}
                label="Supporting Document Packages"
              />
            }
            noButtons
          >
            {supportingDocumentsToggle && (
              <Field
                component={InputField}
                name={`${fieldPreffix}.dueDiligence.supportingDocumentsLimit`}
                type="number"
                placeholder="Maximum number"
                validate={requiredNumber}
              />
            )}
          </LicenceItem>
          <Field component={ToggleField} name={`${fieldPreffix}.dueDiligence.riskLevelsEnabled`} label="Risk Levels" />
        </LicenceModule>
      )}

      {showModules.find(module => module.name === 'AML').toggled && (
        <LicenceModule
          title="AML"
          handleHide={() => handleShowModule('AML')}
          headeritems={
            <>
              <LicenceItem text="Cases" noButtons>
                <Field
                  component={InputField}
                  name={`${fieldPreffix}.aml.casesLimit`}
                  type="number"
                  placeholder="Maximum number"
                  validate={requiredNumber}
                />
              </LicenceItem>
              <LicenceItem text="Risk Screening" noButtons>
                <Field
                  component={InputField}
                  name={`${fieldPreffix}.aml.riskScreeningProfilesLimit`}
                  type="number"
                  placeholder="Maximum number"
                  validate={requiredNumber}
                />
              </LicenceItem>
            </>
          }
        >
          <Field
            component={ToggleField}
            name={`${fieldPreffix}.aml.ongoingMonitoringEnabled`}
            label="Ongoing Monitoring"
          />
        </LicenceModule>
      )}

      {showModules.find(module => module.name === 'Onboarding').toggled && (
        <LicenceModule
          title="Onboarding"
          handleHide={() => handleShowModule('Onboarding')}
          headeritems={
            <>
              <LicenceItem text="Natural Person Portals" noButtons>
                <Field
                  component={InputField}
                  name={`${fieldPreffix}.onboarding.npPortalsLimit`}
                  type="number"
                  placeholder="Number of Portals"
                  validate={validators.required(' ')}
                />
              </LicenceItem>
              <LicenceItem
                text={
                  <Field
                    component={ToggleField}
                    name={`${fieldPreffix}.onboarding.npCustomUrlsEnabled`}
                    label="Custom URLs"
                  />
                }
                noButtons
              >
                {npCustomUrlsToggle && (
                  <Field
                    component={InputField}
                    name={`${fieldPreffix}.onboarding.npCustomUrlsLimit`}
                    type="number"
                    placeholder="Number of URLs"
                    validate={requiredNumber}
                  />
                )}
              </LicenceItem>
              <LicenceItem text="Legal Entity Portals" noButtons>
                <Field
                  component={InputField}
                  name={`${fieldPreffix}.onboarding.lePortalsLimit`}
                  type="number"
                  placeholder="Number of Portals"
                  validate={validators.required(' ')}
                />
              </LicenceItem>
              <LicenceItem
                text={
                  <Field
                    component={ToggleField}
                    name={`${fieldPreffix}.onboarding.leCustomUrlsEnabled`}
                    label="Custom URLs"
                  />
                }
                noButtons
              >
                {leCustomUrlsToggle && (
                  <Field
                    component={InputField}
                    name={`${fieldPreffix}.onboarding.leCustomUrlsLimit`}
                    type="number"
                    placeholder="Number of URLs"
                    validate={requiredNumber}
                  />
                )}
              </LicenceItem>
            </>
          }
        >
          <Field
            component={ToggleField}
            name={`${fieldPreffix}.onboarding.portalManagementEnabled`}
            label="Portal Management"
          />
          <Field
            component={ToggleField}
            name={`${fieldPreffix}.onboarding.emailSettingsEnabled`}
            label="Email Settings"
          />
        </LicenceModule>
      )}

      {showModules.find(module => module.name === 'Advanced Workflow').toggled && (
        <LicenceModule
          title="Advanced Workflow"
          handleHide={() => handleShowModule('Advanced Workflow')}
          headeritems={
            <>
              <Field
                component={ToggleField}
                name={`${fieldPreffix}.advancedWorkflow.automationsEnabled`}
                label="Automations"
              />
              <Field
                component={ToggleField}
                name={`${fieldPreffix}.advancedWorkflow.riskScoringEnabled`}
                label="Risk Scoring"
              />
              <Field
                component={ToggleField}
                name={`${fieldPreffix}.advancedWorkflow.quickSetupSettingsEnabled`}
                label="Quick Setup Settings"
              />
            </>
          }
        />
      )}

      {showModules.find(module => module.name === 'Technical').toggled && (
        <LicenceModule
          title="Technical"
          handleHide={() => handleShowModule('Technical')}
          headeritems={
            <>
              <Field component={ToggleField} name={`${fieldPreffix}.technical.webhooksEnabled`} label="Webhooks" />
              <Field component={ToggleField} name={`${fieldPreffix}.technical.apiEnabled`} label="API Manager" />
            </>
          }
        />
      )}
    </section>
  );
};

ModulesSection.propTypes = {
  namePreffix: PropTypes.string,
  divider: PropTypes.bool,
};

ModulesSection.defaultProps = {
  namePreffix: '',
  divider: false,
};

export default ModulesSection;
