import React, { useCallback, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../components/Accordion';
import TextareaField from '../../../../../../../components/TextareaField';
import ToggleField from '../../../../../../../components/ToggleField';
import utilities from '../../../../../../../utilities';
import rcuEnums from '../../../../../../../utilities/enums/rcuEnums';
import './styles.scss';

const CommentRow = ({ namePrefix }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  const { change } = useForm();
  const { values } = useFormState();

  const comment = values[namePrefix] ? values[namePrefix].comment : values.comment;
  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  const { isEnabled } = comment || {};

  const handleCommentBodyChange = useCallback(
    ({ target: { value: commentBody } }) => {
      if (commentBody !== '' && !isEnabled) change(`${namePrefixWithDot}comment.isEnabled`, true);
      if (commentBody === '' && isEnabled) change(`${namePrefixWithDot}comment.isEnabled`, false);
    },
    [isEnabled, namePrefixWithDot],
  );

  const handleIsEnabledChange = useCallback(
    value => {
      setAccordionOpen(value);
      !value && change(`${namePrefixWithDot}comment.commentBody`, '');
    },
    [change, namePrefixWithDot],
  );

  return (
    <Accordion
      accordionindex={rcuEnums.ACCORDION_INDEXES.COMMENT}
      accordionOpen={accordionOpen}
      title={
        <>
          <h3>Comment</h3>
          <span className="ickyc-spacer" />
          <div onClick={utilities.handleOnClickEvent}>
            <Field
              preview
              name={`${namePrefixWithDot}comment.isEnabled`}
              component={ToggleField}
              showCustomText
              label="Enable"
              onChange={handleIsEnabledChange}
            />
          </div>
        </>
      }
    >
      <div className="ickyc-rcu-comment">
        <span>Add a Comment to the Activity Log of this KYC profile</span>
        <Field
          component={TextareaField}
          name={`${namePrefixWithDot}comment.commentBody`}
          placeholder="Comment"
          onChange={handleCommentBodyChange}
        />
      </div>
    </Accordion>
  );
};
export default CommentRow;
