import PropTypes from 'prop-types';
import React from 'react';
import setClassSuffix from '../../../utilities/services/ClassManager';
import './styles.scss';

const ConfirmationPage = ({ icons, title, subtitle, text }) => {
  const baseClass = 'ickyc-confirmation-page';

  const setSuffix = setClassSuffix(baseClass);
  return (
    <div className={setSuffix('__body')}>
      <div className={setSuffix('__body__icons')}>{icons}</div>

      <div className={setSuffix('__body__title')}>
        <div>{title}</div>
        <div>{subtitle}</div>
      </div>

      <div className={setSuffix('__body__description')}>{text}</div>
    </div>
  );
};

ConfirmationPage.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    ]),
  ),
  title: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    ]),
  ),
  subtitle: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    ]),
  ),
  text: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    ]),
  ),
};
ConfirmationPage.defaultProps = {
  text: undefined,
  subtitle: undefined,
  title: undefined,
  icons: undefined,
};
export default ConfirmationPage;
