import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../components/Accordion';
import Section from '../../../../../../components/Section';
import SelectContent from '../../../../../../components/Select/components/SelectContent';
import SelectInputTrigger from '../../../../../../components/Select/components/SelectInputTrigger';
import SelectField from '../../../../../../components/SelectField';
import FieldCountriesList from '../../../../../../components/Tables/FieldCountriesList';
import ToggleField from '../../../../../../components/ToggleField';
import useSelect from '../../../../../../hooks/useSelect';
import ProtectedComponent from '../../../../../../router/components/ProtectedComponent';
import { selectRiskLevelLabels } from '../../../../../../store/selectors/global.selector';
import enums from '../../../../../../utilities/enums';
import authEnums from '../../../../../../utilities/enums/authEnums';
import EnableDisableControlButtons from '../../../../components/EnableDisableControlButtons';
import EnableDisableRadioButtons from '../../../../components/EnableDisableRadioButtons';
import './index.scss';

const SearchCriteriaConfiguration = () => {
  const classsBase = 'ickyc-risk-screening-criteria-configuration';
  const toggleWrapperClasses = (val = true) =>
    classNames({
      'toggle-wrapper': true,
      'toggle-wrapper--disabled': !val,
    });
  const riskItems = useSelect(selectRiskLevelLabels).map((risk, ind) => ({
    id: ind,
    label: `${risk} (${ind + 1})`,
    value: `${ind + 1}`,
  }));
  const { change } = useForm();
  const { errors } = useFormState();
  const {
    values: {
      ongoingMonitoring,
      annualReview,
      applyByNationalityEnabled,
      applyByJurisdictionEnabled,
      applyByRiskLevelsEnabled,
      applyByKycStatusesEnabled,
    },
  } = useFormState();

  useEffect(() => {
    if (!ongoingMonitoring && annualReview) {
      change('annualReview', false);
    }
  }, [change, ongoingMonitoring]);

  const mappedStatuses = useMemo(() => {
    return Object.entries(enums.ENTITY_STATUSES).map(status => ({
      id: status[1],
      label: status[1],
      value: status[1],
    }));
  }, []);

  return (
    <div className={classsBase}>
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
        <Section divider>
          <Accordion
            title={
              <Field name="applyByNationalityEnabled" component={ToggleField} label={<h3>Apply By Nationality</h3>} />
            }
            controledOpen
            accordionOpen={applyByNationalityEnabled}
          >
            <FieldCountriesList
              modulePreffix="appliedNationalities"
              TableControlsComponent={EnableDisableControlButtons}
              tableRow={EnableDisableRadioButtons}
              withInfiniteScroll
            />
            {errors?.applyByNationalityEnabled && (
              <div className={`${classsBase}__error`}>{errors?.applyByNationalityEnabled}</div>
            )}
          </Accordion>
        </Section>
      </ProtectedComponent>
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
        <Section divider>
          <Accordion
            title={
              <Field name="applyByJurisdictionEnabled" component={ToggleField} label={<h3>Apply By Jurisdiction</h3>} />
            }
            controledOpen
            accordionOpen={applyByJurisdictionEnabled}
          >
            <FieldCountriesList
              modulePreffix="appliedJurisdictions"
              TableControlsComponent={EnableDisableControlButtons}
              tableRow={EnableDisableRadioButtons}
              withInfiniteScroll
            />
            {errors?.applyByJurisdictionEnabled && (
              <div className={`${classsBase}__error`}>{errors?.applyByJurisdictionEnabled}</div>
            )}
          </Accordion>
        </Section>
      </ProtectedComponent>
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.RISK_LEVELS}>
        <Section divider>
          <Accordion
            title={
              <Field name="applyByRiskLevelsEnabled" component={ToggleField} label={<h3>Apply By Risk Level</h3>} />
            }
            controledOpen
            accordionOpen={applyByRiskLevelsEnabled}
          >
            <Field
              component={SelectField}
              multipleSelection
              name="appliedRiskLevels"
              options={riskItems}
              Trigger={<SelectInputTrigger />}
              Content={<SelectContent />}
            />
          </Accordion>
        </Section>
      </ProtectedComponent>
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
        <Section divider>
          <Accordion
            title={
              <Field name="applyByKycStatusesEnabled" component={ToggleField} label={<h3>Apply By KYC Status</h3>} />
            }
            controledOpen
            accordionOpen={applyByKycStatusesEnabled}
          >
            <Field
              component={SelectField}
              multipleSelection
              name="appliedKycStatuses"
              options={mappedStatuses}
              Trigger={<SelectInputTrigger />}
              Content={<SelectContent />}
            />
          </Accordion>
        </Section>
      </ProtectedComponent>
      <Section title="Ongoing Monitoring" divider>
        <div className={toggleWrapperClasses()}>
          <Field component={ToggleField} name="ongoingMonitoring" />
          <p>
            <b>Enable daily ongoing monitoring.</b> Entities that are screened with this profile will be reviewed for
            new potential risk on a daily basis for one year. New potential risk will appear in the KYC Profile Page of
            every entity this search is attached to.
          </p>
        </div>
      </Section>
      <Section title="Annual Review">
        <div className={toggleWrapperClasses(ongoingMonitoring)}>
          <Field component={ToggleField} name="annualReview" />
          <p>
            <b>Enable annual automatic data refresh, review, and filtering using this profile.</b> Requires ongoing
            monitoring to be enabled.
          </p>
        </div>
      </Section>
    </div>
  );
};
export default SearchCriteriaConfiguration;
