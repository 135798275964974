import classNames from 'classnames';
import ModalTabHeader from 'components/Headers/ModalTabHeader';
import BareHeading from 'components/Headings/BareHeading';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import AssignedToMe from './components/AssignedToMe';
import './styles.scss';
import utils from './utils';

const { myWorkTabs } = utils;

const MyWork = ({ className }) => {
  const [activeTab, setActiveTab] = useState(0);

  // TODO: Expand screens list with Notifications and xyz's
  const screens = useMemo(() => [<AssignedToMe />], []);

  const classes = classNames('ickyc-page', 'ickyc-my-work', className);

  const optionClick = useCallback(value => {
    setActiveTab(value);
  }, []);

  return (
    <div className={classes}>
      <BareHeading className="ickyc-my-work__title" title="My Work" />
      <ModalTabHeader className="ickyc-my-work__tabs" onClick={optionClick} options={myWorkTabs} />
      {screens[activeTab]}
    </div>
  );
};

MyWork.propTypes = {
  className: PropTypes.string,
};

MyWork.defaultProps = {
  className: undefined,
};
export default MyWork;
