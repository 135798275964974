import HelpIcon from '@material-ui/icons/Help';
import classNames from 'classnames';
import FieldDescription from 'components/FieldDescription';
import { ContentState, EditorState } from 'draft-js';
import useOutsideClick from 'hooks/useOutsideClick';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import Tooltip from '../Tooltip';
import './styles.scss';
import utils from './utils';

const TextEditorField = ({ input, meta, disabled, preview, label, hint, className, description }) => {
  const { toHtml } = utils;
  const baseClass = 'ickyc-text-editor';

  const textEditorRef = useRef(null);

  const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromText('')));

  const { error, invalid, submitFailed } = meta;
  const touched = useOutsideClick(textEditorRef);

  const onEditorStateChange = edState => {
    setEditorState(edState);
    return input.onChange(toHtml(edState));
  };

  const hasError = useMemo(() => (submitFailed || touched) && invalid && error, [
    error,
    invalid,
    touched,
    submitFailed,
  ]);

  const classes = classNames(
    baseClass,
    { 'ickyc-text-editor--disabled': disabled },
    { 'ickyc-text-editor--preview': preview },
    { 'ickyc-text-editor--error': hasError },
    className,
  );

  const handleLinkCallback = linkObject => {
    const { target, ...rest } = linkObject;
    const protocol = /^https?:\/\//;
    return { target: protocol.test(target) ? target : `https://${target.trim()}`, ...rest };
  };

  useEffect(() => {
    if (toHtml(editorState) !== input?.value) {
      editorState.getSelection();
      const newContentState = ContentState.createFromBlockArray(htmlToDraft(input?.value).contentBlocks);
      const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');

      setEditorState(newEditorState);
    }
  }, [input?.value]);

  return (
    <div className={classes}>
      {(label || hint) && (
        <label>
          {label}
          {hint && <Tooltip trigger={<HelpIcon />} content={<span>{hint}</span>} />}
        </label>
      )}
      <div>
        <div ref={textEditorRef} className={`${baseClass}__content`}>
          <Editor
            toolbar={{
              options: ['inline', 'textAlign', 'list', 'link'],
              inline: {
                options: ['bold', 'italic', 'underline'],
              },
              link: {
                options: ['link'],
                linkCallback: handleLinkCallback,
              },
              list: {
                options: ['unordered', 'ordered', 'indent', 'outdent'],
              },
            }}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            stripPastedStyles
            handlePastedText={() => false}
          />
        </div>
        {hasError && <div className={`${baseClass}__error`}>{error}</div>}
        {description && <FieldDescription text={description} />}
      </div>
    </div>
  );
};
TextEditorField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  preview: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
};

TextEditorField.defaultProps = {
  disabled: false,
  preview: false,
  label: null,
  className: '',
  hint: null,
  description: undefined,
};

export default TextEditorField;
