import PropTypes from 'prop-types';
import React from 'react';
import DateManager from 'utilities/services/DateManager';
import LogComment from '../LogComment';
import TopComment from '../TopComment';
import './styles.scss';

/**
 * List of Comment components with date displayed at the header
 * @param {string} date - date displayed
 * @param {array} comments - list of displayed comments
 */

const CommentList = ({ date, comments, type }) => {
  return (
    <div className="ickyc-comment-list">
      <div>
        <span>{DateManager.monDayYearLocal(date)}</span>
      </div>
      {comments.map(comment => {
        return comment.systemGenerated ? (
          <LogComment comment={comment} key={`${comment.body}-${comment.commentId}`} />
        ) : (
          <TopComment date={date} comment={comment} key={`${comment.body}-${comment.commentId}`} type={type} top />
        );
      })}
    </div>
  );
};

CommentList.propTypes = {
  date: PropTypes.string,
  type: PropTypes.string,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      commentId: PropTypes.number,
      body: PropTypes.string,
      editable: PropTypes.bool,
      numberOfReplies: PropTypes.number,
      parentId: PropTypes.number,
      timeCreated: PropTypes.string,
      timeModified: PropTypes.string,
      userFullName: PropTypes.string,
      userId: PropTypes.string,
    }),
  ),
};

CommentList.defaultProps = {
  date: '',
  type: 'entity',
  comments: [],
};

export default CommentList;
