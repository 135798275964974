import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import DebounceInput from '../../../components/DebounceInput';
import BareHeading from '../../../components/Headings/BareHeading';
import ButtonHeading from '../../../components/Headings/ButtonHeading';
import Table from '../../../components/Tables/Table';
import TableHeader from '../../../components/Tables/Table/components/TableHeader';
import useModalHandler from '../../../hooks/useModalHandler';
import useTable from '../../../hooks/useTable';
import ProtectedComponent from '../../../router/components/ProtectedComponent';
import utilities from '../../../utilities';
import authEnums from '../../../utilities/enums/authEnums';
import IconManager from '../../../utilities/services/IconManager';
import { PermissionGroupContext, UserManagementContext } from '../../../utilities/services/contexts';
import FilterToggleButton from '../../Kyc/components/FilterToggleButton';
import SearchControls from '../../Kyc/components/SearchControls';
import UserManagementFilterModal from './components/UserManagementFilterModal';
import './styles.scss';

const UserManagementPage = () => {
  const [initialUserSettings, setInitialUserSettings] = useState({});
  const [initialUserInfo, setInitialUserInfo] = useState({});
  const [filterOn, setFilterOn] = useState(false);
  const [filters, setFilters] = useState({ userTypes: [], userStatuses: [], tags: [] });
  const [downloading, setDownloading] = useState(false);
  const [isGlobalTwoFactorEnabled, setIsGlobalTwoFactor] = useState();
  const { state } = useLocation();

  const separateUserData = data => {
    const {
      firstName,
      middleName,
      lastName,
      lastLogin,
      status,
      creationDate,
      email,
      tags,
      requireTwoFactorAuthentication,
      requireIdentityVerification,
      ...rest
    } = data;
    setInitialUserInfo({
      firstName,
      middleName,
      lastName,
      lastLogin,
      status,
      creationDate,
      email,
      tags,
      requireTwoFactorAuthentication,
      requireIdentityVerification,
    });
    setInitialUserSettings(rest);
  };
  const {
    getAllUsers,
    fetchUser,
    withTitle,
    clientId,
    downloadReportCSV,
    UserModal,
    groupPermissions,
    titleText,
    TableRow,
    CustomTableHeader,
    getGlobalTwoFactorAuthentication,
  } = useContext(UserManagementContext);

  const [fetchingUser, setFetchingUser] = useState(false);

  const { isOpen: isEditModalOpen, close: closeEditModal, open: openEditModal } = useModalHandler();

  const [selectedUserId] = useState();

  const {
    isLoading,
    records,
    changeParams,
    pagination,
    params,
    updateRowData,
    addRow,
    removeRow,
  } = useTable(getAllUsers, { sortCriteria: 'type', limit: 10, filters, filterOn, id: clientId });

  const classes = classNames('ickyc-user-management');

  const applySearchTerm = useCallback(
    searchString => {
      changeParams({ searchString });
    },
    [changeParams],
  );

  const applyUserChanges = changes => {
    updateRowData('id', selectedUserId, changes);
  };

  const fetchUserInfo = userId => {
    if (!groupPermissions.edit) return;
    setFetchingUser(true);
    const paramsData = clientId ? { userId, id: clientId } : { userId };
    fetchUser(...Object.entries(paramsData).map(el => el[1]))
      .then(({ data: responseData }) => {
        separateUserData(responseData);
        openEditModal();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(data.message)
                ? data.message.join('')
                : data.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => {
        setFetchingUser(false);
      });
  };

  const deleteUser = userId => {
    removeRow('userId', userId);
  };

  const setNewlyAdded = user => {
    addRow(user);
  };

  const handleUpdateUser = (userId, data) => {
    updateRowData('userId', userId, data);
  };

  const handleFilterToggle = useCallback(() => {
    setFilterOn(prev => !prev);
  }, []);

  const handleFilterSave = useCallback(values => {
    setFilters(values);
    setFilterOn(true);
  }, []);

  const handleDownload = async () => {
    setDownloading(true);
    return downloadReportCSV(params)
      .then(({ data }) => {
        utilities.downloadFile(data, 'application/csv', `user-management-report.csv`);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const pageTitle = useMemo(() => {
    if (withTitle) {
      return titleText || 'User Management';
    }
    return '';
  }, [withTitle, titleText]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { enabled },
        } = (await getGlobalTwoFactorAuthentication?.(clientId)) || {};
        setIsGlobalTwoFactor(enabled);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [clientId, getGlobalTwoFactorAuthentication, setIsGlobalTwoFactor]);

  useEffect(() => {
    state?.openFromDashboard && openEditModal();
  }, [state?.openModal]);

  return (
    <div className={classes}>
      {groupPermissions.create ? (
        <ButtonHeading
          title={pageTitle}
          buttonCopies={[<>+ Invite User</>]}
          clickHandlers={[
            () => {
              setInitialUserInfo({});
              setInitialUserSettings({});
              openEditModal();
            },
          ]}
        />
      ) : (
        <BareHeading title={pageTitle} />
      )}
      <SearchControls>
        <div className="ickyc-search-controls__info">
          <span>{pagination.total > 1 ? `${pagination.total || 0}  Results` : `${pagination.total || 0} Result`}</span>
          <ProtectedComponent
            requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.csvDownloads]}
            permissionGroup={authEnums.PERMISSION_GROUP.REPORTS}
          >
            <PrimaryButton variant="link" onClick={handleDownload} disabled={downloading}>
              {IconManager.get(IconManager.names.DOWNLOAD)} Download CSV
            </PrimaryButton>
          </ProtectedComponent>
        </div>

        <div className="ickyc-search-controls__filter-section">
          <DebounceInput placeholder="Search Users" initialValue={params.searchString} onChange={applySearchTerm} />
          <FilterToggleButton filters={filters} filterOn={filterOn} onToggleFilter={handleFilterToggle}>
            <UserManagementFilterModal onFilterSave={handleFilterSave} />
          </FilterToggleButton>
        </div>
      </SearchControls>
      <Table
        values={records}
        onClick={fetchUserInfo}
        pagination={pagination}
        tableRow={TableRow}
        headerRow={TableHeader}
        handleParamsChange={changeParams}
        className="ickyc-user-management-table"
        withPagination
        withLimitChange
        updating={isLoading || fetchingUser}
        onRemove={deleteUser}
        headerData={{
          withDelete: groupPermissions.delete,
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          onClick: changeParams,
          items: CustomTableHeader,
        }}
      />

      <PermissionGroupContext.Provider value={groupPermissions}>
        {isEditModalOpen && (
          <UserModal
            hideModal={closeEditModal}
            handleUpdateInfo={applyUserChanges}
            disableAttachTo
            initialValues={{
              userInfo: {
                initialUserInfo,
                requireTwoFactorAuthentication:
                  isGlobalTwoFactorEnabled || initialUserInfo.requireTwoFactorAuthentication,
              },
              ...initialUserSettings,
            }}
            setNewlyAdded={setNewlyAdded}
            updateUser={handleUpdateUser}
          />
        )}
      </PermissionGroupContext.Provider>
    </div>
  );
};

export default UserManagementPage;
