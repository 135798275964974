import PropTypes from 'prop-types';
import React from 'react';
import FramedRangeSlider from '../../../../../components/FramedRangeSlider';

const Fuzziness = ({ input, disabled }) => {
  const { value, onChange } = input;

  return (
    <FramedRangeSlider
      min={0}
      max={100}
      step={10}
      leftLabel="Exact Match"
      rightLabel="Broad Match"
      value={value}
      withPercentage
      callback={onChange}
      disabled={disabled}
    />
  );
};

Fuzziness.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
};
Fuzziness.defaultProps = {
  disabled: false,
};
export default Fuzziness;
