import FormModal from 'components/Modal/FormModal';
import RadioButtonField from 'components/RadioButtonField';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import './styles.scss';

const ConfirmApprovingEntityModal = ({ hideModal, onAprove, issues }) => {
  const renderSection = (sectionName, value) => {
    if (value > 0) {
      return (
        <div className="ickyc-confirm-approving-entity-modal__section__name">
          <p>{sectionName}</p>
          <p>
            {value} {value > 1 ? 'Issues' : 'Issue'}
          </p>
        </div>
      );
    }
    return <></>;
  };

  const RenderIssueslist = () => {
    const { personal, address, identity, affiliated, biometric, documents, riskScreening } = issues;

    return (
      <div className="ickyc-confirm-approving-entity-modal__section">
        {renderSection('Entity Information Section', personal?.value)}
        {renderSection('Address Information Section', address?.value)}
        {renderSection('Identity Information Section', identity?.value)}
        {renderSection('Affiliated Entities Section', affiliated?.value)}
        {renderSection('Biometric Information Section', biometric?.value)}
        {renderSection('Document Information Section', documents?.value)}
        {renderSection('Risk Screening Section', riskScreening?.value)}
      </div>
    );
  };
  return (
    <FormModal
      hideModal={hideModal}
      title={'Approve Entity'}
      initialValues={{
        acceptIssues: 1,
      }}
      onSubmit={onAprove}
      className="ickyc-confirm-approving-entity-modal"
    >
      <p>This entity still has unresolved issues.</p>
      <RenderIssueslist />
      <Field
        component={RadioButtonField}
        type="radio"
        value={1}
        name={'acceptIssues'}
        parse={val => Number(val)}
        label="Accept all issues and Approve this entity"
      />
      <Field
        component={RadioButtonField}
        name={'acceptIssues'}
        type="radio"
        parse={val => Number(val)}
        value={0}
        label="Keep issues unresolved and Approve this entity"
      />
    </FormModal>
  );
};

ConfirmApprovingEntityModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onAprove: PropTypes.func,
  issues: PropTypes.node,
};

ConfirmApprovingEntityModal.defaultProps = {
  onAprove: () => {},
  issues: null,
};

export default ConfirmApprovingEntityModal;
