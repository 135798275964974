import PropTypes from 'prop-types';
import React from 'react';
import IconManager from '../../../utilities/services/IconManager';
import Badge from '../../Badge';
import './styles.scss';

/**
 * Header component consisting of title and toggle.
 * Styles are coverd in BareHeading component.
 * @param {string} title - page title
 * @param {string} titleIcon - name of the icon that will be displayed along the title
 * @param {array[component]} buttonProps - array of button copies inside the button
 */

const TabHeader = ({ title, titleIcon, buttonProps }) => {
  const classBase = 'ickyc-tab-header';

  return (
    <div className={classBase}>
      <h2>
        {titleIcon && IconManager.get(titleIcon)}

        {title}
      </h2>

      <ul className={`${classBase}__tabs`}>
        {buttonProps.map(spec => (
          <li className={spec.selected ? `${classBase}__tabs__li--active` : ''} key={spec.text + spec.badgeValue}>
            <button onClick={spec.onClick}>
              <span>
                {spec.text}&nbsp;
                {spec.badgeValue && spec.isRed ? (
                  <Badge content={spec.badgeValue} red />
                ) : (
                  <Badge content={spec.badgeValue} gray />
                )}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

TabHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string,
  buttonProps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      badgeValue: PropTypes.number,
      onClick: PropTypes.func,
      selected: PropTypes.bool,
    }),
  ),
};

TabHeader.defaultProps = {
  titleIcon: null,
  buttonProps: [],
};

export default TabHeader;
