import classNames from 'classnames';
import React from 'react';
import './styles.scss';

const ColoredText = ({ text = 'deleted' }) => {
  const classes = classNames('ickyc-colored-text', {
    'ickyc-colored-text--current-user': text === 'You',
  });
  return <span className={classes}>{text}</span>;
};
export default ColoredText;
