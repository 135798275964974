import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from '../../../../../../../../api';
import IComplyForm from '../../../../../../../../components/Form/IComplyForm';
import Spinner from '../../../../../../../../components/Spinner';
import bus from '../../../../../../../../modules/bus';
import enums from '../../../../../../../../utilities/enums';
import DateManager from '../../../../../../../../utilities/services/DateManager';
import { EntityContext, HistoryRecordContext } from '../../../../../../../../utilities/services/contexts';
import HistoryRecordControls from '../../../../../components/HistoryRecordControls';
import IssueList from '../../../../../components/IssueList';
import NaturalPersonInformationForm from '../../../../../components/NaturalPersonformationForm';
import './styles.scss';

/**
 * Displays the natural information in immutable mode
 * @param {object} data - displayed data
 * @param {function} onDataFetch - caches once fetched data
 */

const PersonalInformationPreview = ({ data, onDataFetch }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [controlsInfo, setControlsInfo] = useState({});
  const { entityId, updateEntityInfo } = useContext(EntityContext);
  const { eventHistoryId } = useContext(HistoryRecordContext);

  useEffect(() => {
    if (JSON.stringify(data) !== '{}') {
      setIsLoading(false);
      return;
    }
    const fetchInfo = async () => {
      try {
        setIsLoading(true);
        const { data: response } = await api.kyc.entityManagement.naturalPerson.getInformationTableRecord(
          eventHistoryId,
          entityId,
        );
        const {
          status,
          sourceType,
          sourceDetail,
          eventId: evId,
          portalConfigurationId,
          portalDomain,
          portalName,
          ...rest
        } = response;
        setControlsInfo({
          status,
          sourceType,
          sourceDetail,
          eventId: evId,
          portalConfigurationId,
          portalDomain,
          portalName,
        });

        onDataFetch({ personalInformation: { ...rest, birthDate: DateManager.toDate(rest.birthDate) } });
      } catch (err) {
        onDataFetch({ message: `Error Loading History Record: ${eventHistoryId}` });
      } finally {
        setIsLoading(false);
      }
    };

    fetchInfo();
  }, [data, eventHistoryId, onDataFetch, entityId]);
  const { issues } = useContext(HistoryRecordContext);

  const handleStatusChange = useCallback(
    (issuesChange, issuesList, status, allRejected) => {
      const { personalInformation } = data;

      if (status === enums.HISTORY_EVENT_STATUSES.ACCEPTED) {
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.PERSONAL_INFORMATION,
          issuesChange: -issues,
          allRejected,
        });
      } else {
        bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
          type: 'kyc',
          section: enums.ACCORDION_INDEXES.PERSONAL_INFORMATION,
          issuesChange,
          allRejected,
        });
      }
      updateEntityInfo(personalInformation);
      onDataFetch({ ...data, personalInformation: { ...data.personalInformation, issues: issuesList } });
    },
    [onDataFetch, data, updateEntityInfo, issues],
  );

  const classBase = 'ickyc-personal-info-record';

  if (isLoading) {
    return (
      <div className={classBase}>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={classBase}>
      {data.message ? (
        <div className={`${classBase}__message`}>{data.message}</div>
      ) : (
        <>
          <div className={classBase}>
            <div className={`${classBase}__full`}>
              <IComplyForm initialValues={data} onSubmit={() => {}}>
                <IssueList list={data?.personalInformation.issues || []} />
                <NaturalPersonInformationForm preview checkList={data?.personalInformation?.checkList} />
              </IComplyForm>
            </div>
          </div>
          <HistoryRecordControls info={controlsInfo} onStatusChange={handleStatusChange} />
        </>
      )}
    </div>
  );
};

PersonalInformationPreview.propTypes = {
  data: PropTypes.shape({
    personalInformation: PropTypes.shape({ issues: PropTypes.arrayOf(PropTypes.string), checkList: PropTypes.shape() }),
    message: PropTypes.string,
  }),
  onDataFetch: PropTypes.func,
};

PersonalInformationPreview.defaultProps = {
  data: {},
  onDataFetch: () => {},
};

export default PersonalInformationPreview;
