import Section from 'components/Section';
import ToggleField from 'components/ToggleField';
import useSelect from 'hooks/useSelect';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { Field } from 'react-final-form';
import { selectUserAccessByLicense } from 'store/selectors/auth.selector';
import { PermissionGroupContext } from 'utilities/services/contexts';
import './styles.scss';

const SecurityOptions = ({ withoutIdentityVerification }) => {
  const classBase = 'ickyc-security-options';
  const { edit: canEdit = true } = useContext(PermissionGroupContext);
  const platformAccess = useSelect(selectUserAccessByLicense);

  const hasPortalManagement = useMemo(() => platformAccess?.find(platform => platform === 'Portal Management'), [
    platformAccess,
  ]);

  return (
    <Section divider title="Security Options" className={classBase}>
      <Field
        component={ToggleField}
        name="userInfo.requireTwoFactorAuthentication"
        label="Require Two Factor Authentication (2FA)"
        preview={!canEdit}
      />
      {!withoutIdentityVerification && hasPortalManagement && (
        <Field
          component={ToggleField}
          name="userInfo.requireIdentityVerification"
          label="Require Identity Verification"
          preview={!canEdit}
        />
      )}
    </Section>
  );
};
SecurityOptions.propTypes = {
  withoutIdentityVerification: PropTypes.bool,
};

SecurityOptions.defaultProps = {
  withoutIdentityVerification: false,
};
export default SecurityOptions;
