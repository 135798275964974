import React from 'react';

export default () => (
  <svg width="32px" height="32px" viewBox="0 0 208.000000 168.000000">
    <g transform="translate(0.000000,168.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
      <path d="M1056 1504 c-3 -9 -6 -23 -6 -33 0 -12 -20 -25 -64 -42 l-64 -25 -27 19 c-27 20 -27 20 -48 -2 -18 -18 -20 -24 -9 -39 23 -33 23 -41 0 -101 -20 -54 -27 -61 -55 -67 -30 -6 -33 -10 -33 -40 0 -31 2 -34 31 -34 25 0 32 -6 45 -37 8 -21 20 -49 27 -61 9 -19 8 -29 -6 -54 -17 -29 -17 -32 -1 -49 21 -23 32 -24 55 -3 16 15 23 14 81 -9 52 -21 64 -31 70 -55 14 -52 62 -52 76 0 6 24 18 34 70 55 58 23 65 24 81 9 23 -21 34 -20 55 3 16 17 16 20 -1 49 -14 25 -15 35 -6 54 7 12 19 40 27 61 13 31 20 37 45 37 29 0 31 3 31 34 0 30 -3 34 -32 40 -29 6 -36 13 -57 68 -23 59 -23 62 -6 91 17 28 17 30 -3 49 -20 21 -21 21 -47 1 l-27 -19 -63 24 c-57 23 -64 29 -70 59 -5 28 -10 33 -34 33 -17 0 -31 -7 -35 -16z m135 -124 c147 -75 170 -274 44 -378 -174 -143 -432 32 -361 245 44 132 194 195 317 133z" />
      <path d="M1014 1341 c-62 -28 -97 -80 -102 -152 -4 -49 0 -65 21 -101 70 -121 248 -119 316 2 33 58 27 142 -13 195 -33 43 -95 75 -146 75 -19 0 -54 -9 -76 -19z m147 -48 c68 -40 84 -142 32 -204 -72 -85 -216 -46 -237 66 -9 46 21 112 61 137 36 22 107 23 144 1z" />
      <path d="M1054 1260 c-56 -23 -71 -99 -29 -144 53 -58 155 -19 155 60 0 62 -70 108 -126 84z" />
      <path d="M1070 755 l0 -35 -220 0 c-207 0 -221 -1 -240 -20 -26 -26 -27 -70 -1 -70 10 0 21 9 24 20 5 19 14 20 221 20 209 0 216 -1 216 -20 0 -13 7 -20 20 -20 13 0 20 7 20 20 0 19 7 20 216 20 207 0 216 -1 221 -20 3 -11 14 -20 24 -20 26 0 25 44 -1 70 -19 19 -33 20 -240 20 l-220 0 0 35 c0 28 -4 35 -20 35 -16 0 -20 -7 -20 -35z" />
      <path d="M462 568 c-7 -7 -12 -22 -12 -35 l0 -23 160 0 160 0 0 23 c0 13 -5 28 -12 35 -17 17 -279 17 -296 0z" />
      <path d="M942 568 c-7 -7 -12 -22 -12 -35 l0 -23 160 0 160 0 0 23 c0 13 -5 28 -12 35 -17 17 -279 17 -296 0z" />
      <path d="M1422 568 c-7 -7 -12 -22 -12 -35 l0 -23 160 0 160 0 0 23 c0 13 -5 28 -12 35 -17 17 -279 17 -296 0z" />
      <path d="M450 371 c0 -134 -3 -131 160 -131 163 0 160 -3 160 131 l0 99 -160 0 -160 0 0 -99z m85 29 c0 -8 -8 -16 -17 -18 -13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z m185 0 c0 -18 -7 -20 -65 -20 -58 0 -65 2 -65 20 0 18 7 20 65 20 58 0 65 -2 65 -20z m-182 -93 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25 33 22 38 -4z m137 3 c0 -10 -13 -16 -42 -18 -38 -3 -43 -1 -43 18 0 19 5 21 43 18 29 -2 42 -8 42 -18z" />
      <path d="M930 371 c0 -134 -3 -131 160 -131 163 0 160 -3 160 131 l0 99 -160 0 -160 0 0 -99z m85 29 c0 -8 -8 -16 -17 -18 -13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z m185 0 c0 -18 -7 -20 -65 -20 -58 0 -65 2 -65 20 0 18 7 20 65 20 58 0 65 -2 65 -20z m-182 -93 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25 33 22 38 -4z m137 3 c0 -10 -13 -16 -42 -18 -38 -3 -43 -1 -43 18 0 19 5 21 43 18 29 -2 42 -8 42 -18z" />
      <path d="M1410 371 c0 -134 -3 -131 160 -131 163 0 160 -3 160 131 l0 99 -160 0 -160 0 0 -99z m85 29 c0 -8 -8 -16 -17 -18 -13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z m185 0 c0 -18 -7 -20 -65 -20 -58 0 -65 2 -65 20 0 18 7 20 65 20 58 0 65 -2 65 -20z m-182 -93 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25 33 22 38 -4z m137 3 c0 -10 -13 -16 -42 -18 -38 -3 -43 -1 -43 18 0 19 5 21 43 18 29 -2 42 -8 42 -18z" />
    </g>
  </svg>
);
