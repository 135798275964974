import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import CameraIcon from '../../assets/CameraIcon';
import PrimaryButton from '../Buttons/PrimaryButton';
import './styles.scss';

const uploadStatus = {
  idle: () => <></>,
  pending: () => <CircularProgress className="status--circular-progress" style={{ width: '18px', height: '18px' }} />,
  succeeded: (message = 'Uploaded!') => (
    <Typography component="span" className="status--succeeded">
      {message}
    </Typography>
  ),
  failed: (message = 'Image Error - Please Replace!') => (
    <Typography component="span" className="status--failed">
      {message}
    </Typography>
  ),
};

const UploadImageWrapper = props => {
  const { imageData, getInputProps, open, getRootProps, photoLabel, messages } = props;

  const isImgUpload = !!imageData?.name;
  const statusMessage = messages?.[imageData.status];

  const classes = classNames({
    'upload-document-wrapper': true,
    'upload-document-wrapper--with-image': isImgUpload,
  });

  const emptyImgContainer = useMemo(
    () => (
      <Box className="empty-document-container" {...getRootProps()}>
        <input {...getInputProps()} />
        <Typography className="empty-document-container__label">{photoLabel}</Typography>
        <Box className="empty-document-container__camera-wrapper">
          <CameraIcon />
        </Box>
        <Typography className="empty-document-container__upload-photo-label">Upload Photo</Typography>
      </Box>
    ),
    [getRootProps, getInputProps, photoLabel],
  );

  const fullImgContainer = useMemo(
    () => (
      <>
        <Box component="img" className="document-image" alt="Document Photo" src={imageData?.blobURL ?? ''} />
        <Box className="full-container" {...getRootProps()} onClick={e => e.stopPropagation()}>
          <input {...getInputProps()} />
          <Box className="full-container__label">
            {photoLabel}
            <br />
            {uploadStatus[imageData.status](statusMessage)}
          </Box>
          <Grid className="full-container__btn-container" justifyContent="center" container>
            <PrimaryButton disabled={imageData.status === 'pending'} onClick={open}>
              <Box component="span" width="22px" alignSelf="normal">
                <CameraIcon />
              </Box>
              Replace
            </PrimaryButton>
          </Grid>
        </Box>
      </>
    ),
    [imageData, getRootProps, getInputProps, photoLabel, statusMessage, open],
  );

  return <Box className={classes}>{isImgUpload ? fullImgContainer : emptyImgContainer}</Box>;
};

UploadImageWrapper.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  getRootProps: PropTypes.func.isRequired,
  imageData: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
  photoLabel: PropTypes.string,
  messages: PropTypes.object,
};
UploadImageWrapper.defaultProps = {
  photoLabel: '',
  messages: {},
};

export default UploadImageWrapper;
