import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import ProtectedComponent from 'router/components/ProtectedComponent';
import authEnums from 'utilities/enums/authEnums';
import api from '../../../../../../api';
import RadioButtonField from '../../../../../../components/RadioButtonField';
import RadioGroup from '../../../../../../components/RadioGroup';
import Section from '../../../../../../components/Section';
import ExistingEntitiesTableRow from '../../../../../../components/Select/components/ExistingEntitiesTableRow';
import SelectContentTable from '../../../../../../components/Select/components/SelectContentTable';
import SelectTrigger from '../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../components/SelectField';
import TableHeader from '../../../../../../components/Tables/Table/components/TableHeader';
import TableHeaders from '../../../../../../utilities/services/TableHeaders';
import { PermissionGroupContext } from '../../../../../../utilities/services/contexts';
import Fuzziness from '../../../../../Administration/RiskScreeningPage/components/Fuzziness';
import ScreeningSources from '../../../../../Administration/RiskScreeningPage/components/ScreeningSources';
import { SEARCH_FORM_CASE_ENTITY_TYPES } from '../../../../CaseManagement/enums';
import SearchTerms from './components/SearchTerms';
import './styles.scss';

const SearchForm = () => {
  const classBase = 'ickyc-search-form';
  const [params, setParams] = useState({ limit: 5, skip: 0, sortOrder: 1, sortCriteria: 'name' });
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    const fetchUsers = () => {
      setIsLoading(true);
      api.kyc.caseManagement
        .getCaseAttachmentSubjects(params)
        .then(({ data: response }) => {
          const mappedList = response.data.map(el => ({ ...el, value: el.id, label: el.name }));
          setEntities(mappedList);
          setPagination(response.paginationData);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    fetchUsers();
  }, [params]);

  const { customAmlSearches: canCustomAmlSearches } = useContext(PermissionGroupContext);

  const changeParams = useCallback(newParams => {
    setParams(prev => ({ ...prev, ...newParams }));
  }, []);

  return (
    <div className={classBase}>
      <SearchTerms />
      <Section title="Fuzziness" divider>
        <p>
          Level of flexibility of search. 0% <i>Exact Match</i> will check for exact matches only. 100%{' '}
          <i>Broad Match</i> will look for results with even the slightest match in spelling and phonetics.
        </p>
        <Field name="fuzziness" component={Fuzziness} disabled={!canCustomAmlSearches} />
      </Section>
      <Section title="Entity Type" divider>
        <RadioGroup>
          {SEARCH_FORM_CASE_ENTITY_TYPES.map((label, index) => (
            <Field
              name="entityType"
              type="radio"
              value={index}
              label={label}
              component={RadioButtonField}
              parse={val => Number(val)}
              disabled={!canCustomAmlSearches}
            />
          ))}
        </RadioGroup>
      </Section>
      <Section title="Sources" divider>
        <Field
          name="sources"
          defaultValue={[]}
          render={({ input }) => (
            <ScreeningSources value={input.value} onChange={input.onChange} disabled={!canCustomAmlSearches} />
          )}
        />
      </Section>
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
        <div className={`${classBase}__attachments`}>
          <h2>Case Attachment</h2>
          <p>The case will be attached to KYC subject(s):</p>

          <Field
            component={SelectField}
            options={entities}
            Trigger={<SelectTrigger />}
            multipleSelection
            Content={
              <SelectContentTable
                tableProps={{
                  tableRow: ExistingEntitiesTableRow,
                  headerRow: TableHeader,
                  handleParamsChange: changeParams,
                  pagination,
                  headerData: {
                    sortCriteria: params.sortCriteria,
                    sortOrder: params.sortOrder,
                    onClick: changeParams,
                    items: TableHeaders.ExistingEntities,
                  },
                  updating: isLoading,
                  withLoadMore: true,
                  withPagination: true,
                  className: 'ickyc-existing-entities-table',
                }}
              />
            }
            useFullValue
            name="caseAttachment"
          />
        </div>
      </ProtectedComponent>
    </div>
  );
};

export default SearchForm;
