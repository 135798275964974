import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import useSelect from '../../../hooks/useSelect';
import globalLogout from '../../../modules/globalLogout';
import {
  selectUserAccessByLicense,
  selectUserPermissions,
  selectUserRoles,
  selectUserType
} from '../../../store/selectors/auth.selector';
import authEnums from '../../../utilities/enums/authEnums';
import TokenStorage from '../../../utilities/services/local-storage/TokenStorage';
import ProtectedComponent from '../../components/ProtectedComponent';

const EnhancedRoute = props => {
  const {
    authorized = false,
    onlyPublic = false,
    component: Component,
    componentProps,
    allowedUserTypes,
    allowedUserRoles = [],
    permissionGroup = '',
    requiredPermissions = [],
    withPermissionsCheck = false,
    licenceAccessKey,
    match,
    location,
    ...rest
  } = props;

  const lsToken = TokenStorage.get();
  const isLoggedIn = Boolean(lsToken);

  const userPermissions = useSelect(selectUserPermissions);
  const selectedPermissions = userPermissions[permissionGroup] || [];
  const userType = useSelect(selectUserType);
  const userRoles = useSelect(selectUserRoles);
  const platformAccess = useSelect(selectUserAccessByLicense);

  const finalRoute = useMemo(() => {
    if ((authorized || allowedUserTypes) && !isLoggedIn) {
      return <Redirect to={{ pathname: '/auth/login', state: { from: location } }} />;
    }
    const hasAccessByLicence = !licenceAccessKey ? true : platformAccess.some(el => el === licenceAccessKey);
    const { isExact, url } = match;
    if (!hasAccessByLicence) {
      return <Redirect to="/kyc/home-screen" />;
    }
    if (
      isExact &&
      url.includes('csv') &&
      !platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.FILES_MANAGEMENT)
    ) {
      if (platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.WEBHOOKS)) {
        return <Redirect to="/administration/web-hooks" />;
      }
      if (platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.PORTAL_MANAGEMENT)) {
        return <Redirect to="/administration/portal-management" />;
      }
      if (platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.AUTOMATIONS)) {
        return <Redirect to="/administration/automation" />;
      }
      if (platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.USER_MANAGEMENT)) {
        return <Redirect to="/administration/user-management" />;
      }
      if (platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.COMPLIENCE_SETTINGS)) {
        if (platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.SUPPORTING_DOCUMENTS))
          return <Redirect to="/administration/compliance-settings/supporting-documents" />;

        if (platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.RISK_SCREENING))
          return <Redirect to="/administration/compliance-settings/risk-screening" />;

        if (platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.RISK_LEVELS))
          return <Redirect to="/administration/compliance-settings/risk-levels" />;

        if (platformAccess.some(elms => elms === authEnums.ACCESS_BY_LICENCE.RISK_SCORING))
          return <Redirect to="/administration/compliance-settings/risk-scoring" />;
      }
      return <Redirect to="/kyc/home-screen" />;
    }

    const hasPermissions =
      permissionGroup !== ''
        ? requiredPermissions.some(rp => selectedPermissions?.find(permission => permission === rp))
        : true;

    if ((onlyPublic && isLoggedIn) || !hasPermissions) {
      return <Redirect to="/kyc/home-screen" />;
    }
    if (allowedUserTypes) {
      const isAllowed = allowedUserTypes ? allowedUserTypes.some(r => r === userType) : true;
      if (!isAllowed) {
        globalLogout();
        return null;
      }
    }
    if (allowedUserRoles.length) {
      const isAllowed = allowedUserRoles ? allowedUserRoles.some(r => userRoles.some(role => role === r)) : true;

      if (!isAllowed) {
        globalLogout();
        return null;
      }
    }

    return !withPermissionsCheck ? (
      <Component {...rest} {...componentProps} />
    ) : (
      // eslint-disable-next-line jsx-a11y/no-access-key
      <ProtectedComponent
        requiredPermissions={requiredPermissions}
        permissionGroup={permissionGroup}
        licenceAccessKey={licenceAccessKey}
      >
        <Component {...rest} {...componentProps} />
      </ProtectedComponent>
    );
  }, [
    authorized,
    allowedUserTypes,
    componentProps,
    isLoggedIn,
    onlyPublic,
    rest,
    userType,
    userRoles,
    allowedUserRoles,
    permissionGroup,
    requiredPermissions,
    selectedPermissions,
    withPermissionsCheck,
    licenceAccessKey,
    location,
    match,
    platformAccess
  ]);

  return finalRoute;
};

export default EnhancedRoute;
