import classNames from 'classnames';
import PropType, { any } from 'prop-types';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import uniqid from 'uniqid';
import useSelect from '../../hooks/useSelect';
import ProtectedComponent from '../../router/components/ProtectedComponent';
import RouterCollapsible from '../../router/components/RouterCollapsible';
import { selectUserAccessByLicense } from '../../store/selectors/auth.selector';
import LinkButton from '../Buttons/LinkButton';
import './styles.scss';

const NestedMenuList = ({ items }) => {
  const isNestedList = useCallback(item => !!item.items?.length, []);
  const platformAccess = useSelect(selectUserAccessByLicense);
  const location = useLocation();
  const renderItems = useCallback(
    (arayItems, className) => {
      const nestedListId = uniqid('nested-list-component');
      const getLineHeight = () => {
        if (arayItems.filter(item => !isNestedList(item)).length === 1) return 66;
        const getTotalItemsLength = listItems =>
          listItems
            .filter(item =>
              item?.licenceAccessKey ? platformAccess.some(elms => elms === item?.licenceAccessKey) : true,
            )
            .reduce(acc => {
              return acc + 1;
            }, 0);
        const totalItems = getTotalItemsLength(arayItems);
        return totalItems > 1 ? 56 * (totalItems - 1) + 66 : 66;
      };

      const lineHeight = `${getLineHeight()}px`;
      return (
        <ul className={classNames(className, nestedListId)} key={nestedListId}>
          <style>
            {`
           .${nestedListId} {
             --line-height: ${lineHeight};
          }
          `}
          </style>
          {arayItems.map(item => {
            return (
              <ProtectedComponent
                requiredPermissions={item.requiredPermissions}
                permissionGroup={item.permissionGroup}
                allowedRoles={item.allowedRoles}
                key={item.title}
                licenceAccessKey={item?.licenceAccessKey}
              >
                {isNestedList(item) ? (
                  <RouterCollapsible title={item.title} shouldOpen={item.shouldOpen || false}>
                    {renderItems(item.items)}
                  </RouterCollapsible>
                ) : (
                  <li>
                    <LinkButton path={item.path}>{item.title}</LinkButton>
                  </li>
                )}
              </ProtectedComponent>
            );
          })}
        </ul>
      );
    },
    [isNestedList, platformAccess],
  );
  const mappedItems = useCallback(
    itemsArray => {
      if (!itemsArray) return;
      return itemsArray.map(item =>
        item?.items
          ? {
              ...item,
              items: mappedItems(item.items),
              shouldOpen: item.items
                ? item.items.some(({ path }) => {
                    return location.pathname.indexOf(path) > -1;
                  })
                : false,
            }
          : {
              ...item,
              shouldOpen: item.items
                ? item.items.some(({ path }) => {
                    return location.pathname.indexOf(path) > -1;
                  })
                : false,
            },
      );
    },
    [location.pathname],
  );
  if (!mappedItems(items)?.length) return <></>;
  return renderItems(mappedItems(items), 'nested-list-component');
};
NestedMenuList.propTypes = {
  items: PropType.arrayOf(any),
};
NestedMenuList.defaultProps = {
  items: [],
};
function areEqual(prevProps, nextProps) {
  return JSON.stringify(prevProps.items) === JSON.stringify(nextProps.items);
}
export default React.memo(NestedMenuList, areEqual);
//
