import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../../../../../../api';
import IComplyForm from '../../../../../../../../components/Form/IComplyForm';
import Spinner from '../../../../../../../../components/Spinner';
import bus from '../../../../../../../../modules/bus';
import enums from '../../../../../../../../utilities/enums';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';
import { EntityContext, HistoryRecordContext } from '../../../../../../../../utilities/services/contexts';
import HistoryRecordControls from '../../../../../components/HistoryRecordControls';
import IssueList from '../../../../../components/IssueList';
import ThirdPartyConfirmation from '../../../ThirdPartyConfirmation';
import AddressForm from '../AddressForm';
import './index.scss';

const AddressDataPreview = ({ data, onDataFetch, onRemove }) => {
  const [isLoading, setIsLoading] = useState();
  const { entityId } = useContext(EntityContext);
  const [controlsInfo, setControlsInfo] = useState({});
  const { eventId: eventHistoryId } = useContext(HistoryRecordContext);

  const showControlls = useMemo(
    () =>
      !data?.addressInformation?.thirdPartySuggestion?.editedAddressEvent?.issues?.length &&
      !data?.addressInformation?.thirdPartySuggestion?.thirdPartyReport?.issuesCount &&
      !data?.addressInformation?.thirdPartySuggestion?.isDelete,
    [
      data?.addressInformation?.thirdPartySuggestion?.editedAddressEvent?.issues?.length,
      data?.addressInformation?.thirdPartySuggestion?.thirdPartyReport?.issuesCount,
      data?.addressInformation?.thirdPartySuggestion?.isDelete,
    ],
  );

  useEffect(() => {
    if (JSON.stringify(data) !== '{}') {
      setIsLoading(false);
      return;
    }
    const fetchRecord = async () => {
      try {
        setIsLoading(true);
        const { data: resData } = await api.kyc.entityManagement.legalEntity.getAddressesTableRecord(
          entityId,
          eventHistoryId,
        );
        const {
          status,
          sourceType,
          sourceDetail,
          eventId,
          portalConfigurationId,
          portalDomain,
          portalName,
          ...rest
        } = resData;
        setControlsInfo({ status, sourceType, sourceDetail, eventId, portalConfigurationId, portalDomain, portalName });
        onDataFetch({ addressInformation: resData });
      } catch (err) {
        onDataFetch({ message: `Error Loading History Record: ${eventHistoryId}` });
      } finally {
        setIsLoading(false);
      }
    };
    fetchRecord();
  }, [data, onDataFetch, eventHistoryId, entityId]);

  const handleStatusChange = useCallback(
    (issuesChange, issuesList, __status, allRejected) => {
      bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
        type: 'kyc',
        section: enums.ACCORDION_INDEXES.ADDRESS,
        issuesChange,
        allRejected,
      });

      const newData = {
        ...data,
        addressInformation: {
          ...data.addressInformation,
          issues: issuesList,
        },
      };

      onDataFetch({ ...newData, issuesList });
    },
    [onDataFetch, data],
  );

  const classBase = 'ickyc-address-record';
  const setSuffix = setClassSuffix(classBase);

  if (isLoading) {
    return (
      <div className={setSuffix('__wrapper')}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={setSuffix('__wrapper')}>
      {data.message ? (
        <div className={setSuffix('__message')}>{data.message}</div>
      ) : (
        <>
          <div className={classBase}>
            <div className={setSuffix('__full')}>
              <IComplyForm initialValues={data} onSubmit={() => {}}>
                <IssueList list={data?.addressInformation?.issues || []} />
                <AddressForm preview />
                {data?.addressInformation?.thirdPartySuggestion && (
                  <ThirdPartyConfirmation
                    data={data?.addressInformation}
                    onDataFetch={onDataFetch}
                    onRemove={onRemove}
                  />
                )}
              </IComplyForm>
            </div>
          </div>
          {showControlls && <HistoryRecordControls info={controlsInfo} onStatusChange={handleStatusChange} />}
        </>
      )}
    </div>
  );
};

AddressDataPreview.propTypes = {
  data: PropTypes.shape({
    addressInformation: PropTypes.shape({
      addressId: PropTypes.number,
      addressTypes: PropTypes.string,
      street: PropTypes.string,
      unit: PropTypes.string,
      stateProvinceId: PropTypes.number,
      city: PropTypes.string,
      countryId: PropTypes.number,
      postalZip: PropTypes.string,
      sourceDetail: PropTypes.string,
      sourceType: PropTypes.string,
      status: PropTypes.string,
      assignedUser: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      issues: PropTypes.array,
    }),
    thirdPartySuggestion: PropTypes.shape({
      thirdPartyReport: PropTypes.shape({
        issuesCount: PropTypes.number,
      }),
      editedAddressEvent: PropTypes.shape({
        issues: PropTypes.array,
      }),
      isDelete: PropTypes.bool,
    }),
    message: PropTypes.string,
  }),
  onDataFetch: PropTypes.func,
  onRemove: PropTypes.func,
};

AddressDataPreview.defaultProps = {
  data: {},
  onDataFetch: () => {},
  onRemove: () => {},
};

export default AddressDataPreview;
