import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import Checkbox from '../../../../../components/Checkbox';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import { SCREENING_SOURCES, SCREENING_SOURCES_GROUPS } from '../../../../Kyc/CaseManagement/enums';
import './styles.scss';

const ScreeningSources = ({ value, onChange, disabled }) => {
  const baseClass = 'ickyc-risk-screening-sources';
  const setSuffix = setClassSuffix(baseClass);

  const selectMasterCheckbox = useCallback(
    (val, slaveGroup) => {
      const pickedValue = Number(val);
      let updatedValue = [];
      if (value.includes(pickedValue)) {
        updatedValue = value.filter(v => v !== pickedValue);
        slaveGroup.forEach(code => {
          updatedValue = updatedValue.filter(el => el !== code);
        });
      } else {
        updatedValue = [...value, pickedValue];
        const missingPeps = slaveGroup.filter(code => !value.includes(code));
        updatedValue = [...updatedValue, ...missingPeps];
      }
      onChange(updatedValue);
    },
    [value, onChange],
  );

  const getMasterCheckboxStatus = useCallback(
    slaveGroup => {
      return slaveGroup.every(code => value.includes(code));
    },
    [value],
  );

  const handleSlaveCheckbox = useCallback(
    val => {
      const pickedValue = Number(val);
      let updatedValeus = value.includes(pickedValue) ? value.filter(v => v !== pickedValue) : [...value, pickedValue];

      if (SCREENING_SOURCES_GROUPS.ALL_PEPS.every(code => updatedValeus.includes(code)) && !updatedValeus.includes(3)) {
        updatedValeus = [...updatedValeus, 3];
      }
      if (!SCREENING_SOURCES_GROUPS.ALL_PEPS.every(code => updatedValeus.includes(code)) && updatedValeus.includes(3)) {
        updatedValeus = updatedValeus.filter(code => code !== 3);
      }

      if (
        SCREENING_SOURCES_GROUPS.ALL_ADVERSE.every(code => updatedValeus.includes(code)) &&
        !updatedValeus.includes(8)
      ) {
        updatedValeus = [...updatedValeus, 8];
      }
      if (
        !SCREENING_SOURCES_GROUPS.ALL_ADVERSE.every(code => updatedValeus.includes(code)) &&
        updatedValeus.includes(8)
      ) {
        updatedValeus = updatedValeus.filter(code => code !== 8);
      }

      if (
        SCREENING_SOURCES_GROUPS.ALL_SANTIONS.every(code => updatedValeus.includes(code)) &&
        !updatedValeus.includes(16)
      ) {
        updatedValeus = [...updatedValeus, 16];
      }
      if (
        !SCREENING_SOURCES_GROUPS.ALL_SANTIONS.every(code => updatedValeus.includes(code)) &&
        updatedValeus.includes(16)
      ) {
        updatedValeus = updatedValeus.filter(code => code !== 16);
      }

      onChange(updatedValeus);
    },
    [value, onChange],
  );

  const renderSlaveCheckboxGroup = useCallback(
    slaveGroup =>
      slaveGroup.map(val => (
        <Checkbox
          value={val}
          label={SCREENING_SOURCES[val]}
          checked={value.includes(val)}
          onChange={() => handleSlaveCheckbox(val)}
          key={val}
          disabled={disabled}
        />
      )),
    [value, handleSlaveCheckbox, disabled],
  );

  const isAllSelected = useMemo(
    () =>
      Object.keys(SCREENING_SOURCES)
        .map(code => Number(code))
        .every(code => value.includes(code)),
    [value],
  );

  const selectAll = useCallback(() => {
    onChange(isAllSelected ? [] : Object.keys(SCREENING_SOURCES).map(code => Number(code)));
  }, [onChange, isAllSelected]);

  return (
    <div className={baseClass}>
      <Checkbox label="Select All" bolded checked={isAllSelected} onChange={selectAll} disabled={disabled} />
      <div className={setSuffix('__content')}>
        <div className={setSuffix('__content__column')}>
          <Checkbox
            value={16}
            label={<b>{SCREENING_SOURCES[16]}</b>}
            checked={getMasterCheckboxStatus(SCREENING_SOURCES_GROUPS.ALL_SANTIONS)}
            onChange={() => selectMasterCheckbox(16, SCREENING_SOURCES_GROUPS.ALL_SANTIONS)}
            disabled={disabled}
          />
          {renderSlaveCheckboxGroup(SCREENING_SOURCES_GROUPS.ALL_SANTIONS)}
        </div>
        <div className={setSuffix('__content__column')}>
          <Checkbox
            value={3}
            label={<b>{SCREENING_SOURCES[3]}</b>}
            onChange={() => selectMasterCheckbox(3, SCREENING_SOURCES_GROUPS.ALL_PEPS)}
            checked={getMasterCheckboxStatus(SCREENING_SOURCES_GROUPS.ALL_PEPS)}
            disabled={disabled}
          />
          {renderSlaveCheckboxGroup(SCREENING_SOURCES_GROUPS.ALL_PEPS)}
        </div>
        <div className={setSuffix('__content__column')}>
          <Checkbox
            value={8}
            label={<b>{SCREENING_SOURCES[8]}</b>}
            onChange={() => selectMasterCheckbox(8, SCREENING_SOURCES_GROUPS.ALL_ADVERSE)}
            checked={getMasterCheckboxStatus(SCREENING_SOURCES_GROUPS.ALL_ADVERSE)}
            disabled={disabled}
          />
          {renderSlaveCheckboxGroup(SCREENING_SOURCES_GROUPS.ALL_ADVERSE)}
        </div>
      </div>
    </div>
  );
};

ScreeningSources.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ScreeningSources.defaultProps = {
  value: [],
  onChange: () => {},
  disabled: false,
};
export default ScreeningSources;
