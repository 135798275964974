/* eslint-disable react/jsx-indent-props */
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
/**
 * Row displayed inside the search table
 * @param {object} data - data displayed in the row
 * @param {boolean = false} [hasAttachedTo] - does have an attachedTo header cell
 */
import CheckIcon from 'assets/CheckIcon';
import HourglassIcon from 'assets/HourglassIcon';
import classNames from 'classnames';
import DeleteButton from 'components/Buttons/DeleteButton';
import Tooltip from 'components/Tooltip';
import toastr from 'toastr';
import api from '../../../../../../../api';
import Badge from '../../../../../../../components/Badge';
import Loader from '../../../../../../../components/Loader';
import FormModal from '../../../../../../../components/Modal/FormModal';
import Toggle from '../../../../../../../components/Toggle';
import utilities from '../../../../../../../utilities';
import routesEnum from '../../../../../../../utilities/enums/routesEnum';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../utilities/services/DateManager';
import { PermissionGroupContext } from '../../../../../../../utilities/services/contexts';

const EntityCasesTableRow = ({ rowInfo, className, onRemove }) => {
  const { id, created, name, runBy, ongoing, potential, processing } = rowInfo;
  const [toggleState, setToggleValue] = useState(ongoing);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [updating, setUpdating] = useState(false);

  const handleToggleChange = async event => {
    event.stopPropagation();
    setUpdating(true);
    api.kyc.caseManagement
      .changeCaseOngoingStatus(id, !toggleState)
      .then(() => {
        setToggleValue(prev => !prev);
        toastr.success(`Ongoing monitoring was ${!toggleState ? 'enabled' : 'disabled'} `);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  const { amlSearchDashboard: canNavigate } = useContext(PermissionGroupContext);

  const linkToCaseProfile = useMemo(() => {
    return utilities.routeParams(routesEnum.kyc.CASE_DETAIL, {
      id,
    });
  }, [id]);

  const deleteCase = () => {
    return api.kyc.caseManagement
      .removeCase(id)
      .then(() => {
        toastr.success('Case was deleted');
        onRemove(rowInfo);
        setConfirmModalOpen(false);
      })
      .catch(() => {
        return { [FORM_ERROR]: 'Error Occured While Trying to Delete Case' };
      });
  };

  const setSuffix = setClassSuffix(`${className}__column`);
  const preventLinkIfNoPermisssion = e => {
    if (!canNavigate) e.preventDefault();
  };
  const classes = classNames(`${className}__row`, { [`${className}__row--disabled`]: processing });

  return (
    <tr key={id} className={classes}>
      {updating && <Loader />}
      <td className={setSuffix('__name')}>
        <NavLink onClick={preventLinkIfNoPermisssion} to={linkToCaseProfile}>
          {name}
        </NavLink>
      </td>
      <td className={setSuffix('__created')}>
        {processing ? (
          <Tooltip
            content={<span>Retrieving case results</span>}
            trigger={
              <div>
                <HourglassIcon className={setSuffix('__created__pending-icon')} />
              </div>
            }
          />
        ) : (
          <Tooltip
            content={<span>Ready</span>}
            trigger={
              <div>
                <CheckIcon className={setSuffix('__created__ready-icon')} />
              </div>
            }
          />
        )}
        <NavLink onClick={preventLinkIfNoPermisssion} to={linkToCaseProfile}>
          {DateManager.monDayYearLocal(created)}
        </NavLink>
      </td>
      <td className={setSuffix('__ongoing')}>
        <span>
          <Toggle onOff onChange={handleToggleChange} value={toggleState} />
        </span>
      </td>
      <td className={setSuffix('__runby')}>
        <NavLink onClick={preventLinkIfNoPermisssion} to={linkToCaseProfile}>
          {runBy}
        </NavLink>
      </td>
      <td className={setSuffix('__potential')}>
        <NavLink onClick={preventLinkIfNoPermisssion} to={linkToCaseProfile}>
          <Badge red content={potential} />
        </NavLink>
      </td>
      <td className={setSuffix('__remove')}>
        <DeleteButton
          onClick={ev => {
            ev.stopPropagation();
            setConfirmModalOpen(true);
          }}
        />
        <div>
          {confirmModalOpen && (
            <FormModal
              hideModal={() => setConfirmModalOpen(false)}
              title="Delete Case Confirmation"
              onSubmit={deleteCase}
            >
              <span className="ickyc-confirmation-message">Are you sure you want to delete this case?</span>
            </FormModal>
          )}
        </div>
      </td>
    </tr>
  );
};

EntityCasesTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    created: PropTypes.string,
    name: PropTypes.string.isRequired,
    runBy: PropTypes.string.isRequired,
    ongoing: PropTypes.bool,
    potential: PropTypes.number,
  }),
  className: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

EntityCasesTableRow.defaultProps = {
  rowInfo: {
    created: '',
    ongoing: false,
    potential: 0,
  },
  onRemove: () => {},
};

export default EntityCasesTableRow;
