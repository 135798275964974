import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Used as a prop in Table Component
 *
 * @param {Date} added - creation date
 * @param {string} type - entity type -> Natural Person or Legal Entity
 */
const AttachEntitiesTableRow = ({ rowInfo: { type, name, id, selected, hovered }, className, onClick }) => {
  const classes = classNames({
    [`${className}__row--hovered`]: hovered,
    [`${className}__row--selected`]: selected,
    [`${className}__row`]: true,
  });
  return (
    <tr key={id} className={classes} onClick={() => onClick(id)}>
      <td className={`${className}__column__name`}>
        <span>{name}</span>
      </td>
      <td className={`${className}__column__type`}>
        <span>{type}</span>
      </td>
    </tr>
  );
};

AttachEntitiesTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    hovered: PropTypes.bool,
  }),
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
AttachEntitiesTableRow.defaultProps = {
  onClick: () => {},
  rowInfo: {
    selected: false,
    name: '',
    email: '',
    added: undefined,
    hovered: false,
    countryId: 0,
  },
};

export default React.memo(AttachEntitiesTableRow);
