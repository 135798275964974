import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import toastr from 'toastr';
import api from '../../../../../../../api';
import FormModal from '../../../../../../../components/Modal/FormModal';
import ApiKeyForm from './components/ApiKeyForm';

const CreateApiKeyModal = ({ hideModal, setNewlyAdded, clientId, className }) => {
  const handleSubmit = async data => {
    const { ApiKey } = data;
    const submitObject = {
      name: ApiKey.name,
    };
    let errMessage;
    try {
      const response = await api.administration.apikeys.createNewApiKey(clientId, submitObject);
      setNewlyAdded(response.data);
      toastr.success('Successfully created Web Hook.');
      hideModal();
    } catch (err) {
      if (err?.response) {
        const { status, data: errorData } = err.response;
        if (status >= 400 && status < 500) {
          errMessage = { [FORM_ERROR]: errorData.message || errorData.errors?.[0] };
        }
        if (status === 500) {
          errMessage = { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
        }
      } else errMessage = { [FORM_ERROR]: 'Error occured while performing risk screening' };
    }
    return errMessage;
  };
  return (
    <FormModal
      title="Create A New API Key"
      initialValues={{}}
      hideModal={hideModal}
      className={className}
      onSubmit={handleSubmit}
      showErrorsInConfirmation
    >
      <ApiKeyForm />
    </FormModal>
  );
};
CreateApiKeyModal.propTypes = {
  setNewlyAdded: PropTypes.func,
  hideModal: PropTypes.func,
  className: PropTypes.string,
  clientId: PropTypes.string,
};

CreateApiKeyModal.defaultProps = {
  className: '',
  hideModal: null,
  setNewlyAdded: null,
  clientId: '',
};
export default CreateApiKeyModal;
