import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { Field, useField } from 'react-final-form';
import validators from 'utilities/services/validators';
import InputField from '../../../../../../../../../components/InputField';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import useSelect from '../../../../../../../../../hooks/useSelect';
import { selectRiskLevelLabels } from '../../../../../../../../../store/selectors/global.selector';
import enums from '../../../../../../../../../utilities/enums';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import ModuleOption from '../ModuleOption';
import './styles.scss';

const PERIODIC_REVIEW_OPTIONS = [{ id: '0', label: 'Years', value: 'years' }];

const AutomationForm = ({ index }) => {
  const baseClass = 'ickyc-automation-form';
  const setSuffix = setClassSuffix(baseClass);
  const classes = classNames([
    baseClass,
    {
      [setSuffix('--background-gray')]: index % 2 !== 0,
    },
  ]);

  const riskItems = useSelect(selectRiskLevelLabels).map((risk, ind) => ({
    id: ind + 1,
    label: `${risk} (${ind + 1})`,
    value: ind + 1,
  }));

  const mappedStatuses = useMemo(() => {
    return Object.values(enums.ENTITY_STATUSES).map((status, index) => ({
      id: status,
      label: status,
      value: index + 1,
    }));
  }, []);

  const {
    input: {
      value: { enablePeriodicReview, autoUpdateEntityStatus, autoUpdateRiskLevel },
    },
  } = useField(`modules[${index}].automation`);

  return (
    <ModuleOption className={classes}>
      <span>If All KYC Requests Have Been Auto-Accepted:</span>
      <div className={setSuffix('__controlls-container')}>
        <div className={setSuffix('__controlls-container__section-with-select')}>
          <Field
            component={ToggleField}
            label="Set Next KYC Review:"
            name={`modules[${index}].automation.enablePeriodicReview`}
          />
          <div>
            <Field
              component={InputField}
              type="number"
              name={`modules[${index}].automation.periodicReviewValue`}
              Trigger={<SelectTrigger />}
              Content={<SelectFilterContent />}
              validate={validators.validateBasedOnOtherField(
                `modules[${index}].automation.enablePeriodicReview`,
                validators.required('Required', true),
              )}
              disabled={!enablePeriodicReview}
            />
            <Field
              component={SelectField}
              name={`modules[${index}].automation.periodicReviewInterval`}
              options={PERIODIC_REVIEW_OPTIONS}
              Trigger={<SelectTrigger placeholder="Select Period" />}
              Content={<SelectFilterContent />}
              validate={validators.validateBasedOnOtherField(
                `modules[${index}].automation.enablePeriodicReview`,
                validators.required('Required', true),
              )}
              disabled={!enablePeriodicReview}
            />
          </div>
        </div>
        <div className={setSuffix('__controlls-container__section-with-select')}>
          <Field
            component={ToggleField}
            label="Set Profile Status To:"
            name={`modules[${index}].automation.autoUpdateEntityStatus`}
          />
          <Field
            component={SelectField}
            name={`modules[${index}].automation.entityStatus`}
            options={enums.ENTITY_STATUSES_OPTIONS}
            Trigger={<SelectTrigger placeholder="Entity Status" />}
            Content={<SelectFilterContent />}
            validate={validators.validateBasedOnOtherField(
              `modules[${index}].automation.autoUpdateEntityStatus`,
              validators.required('Required'),
            )}
            disabled={!autoUpdateEntityStatus}
          />
        </div>
        <div className={setSuffix('__controlls-container__pushed-in')}>
          <Field
            component={ToggleField}
            label="If Entity Data Verification is Accepted"
            name={`modules[${index}].automation.checkIfDataVerificationIsAccepted`}
          />
          <Field
            component={ToggleField}
            label="If All Attached AML Cases are Closed"
            name={`modules[${index}].automation.checkIfAllAttachedCasesAreClosed`}
          />
        </div>
        <div className={setSuffix('__controlls-container__section-with-select')}>
          <Field
            component={ToggleField}
            label="Set Risk Level To:"
            name={`modules[${index}].automation.autoUpdateRiskLevel`}
          />
          <Field
            component={SelectField}
            name={`modules[${index}].automation.riskLevel`}
            options={riskItems}
            renderAsPortal
            Trigger={<SelectTrigger placeholder="Risk Level" />}
            Content={<SelectFilterContent />}
            validate={validators.validateBasedOnOtherField(
              `modules[${index}].automation.autoUpdateRiskLevel`,
              validators.required('Required', true),
            )}
            disabled={!autoUpdateRiskLevel}
          />
        </div>
      </div>
    </ModuleOption>
  );
};
AutomationForm.propTypes = {
  index: PropTypes.number.isRequired,
};
export default AutomationForm;
