import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import SelectFilterContent from '../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../components/SelectField';
import './styles.scss';

const AutoAssignementRow = ({ modulePreffix }) => {
  const usersToAssign = useSelector(state =>
    state.global.usersToAssign.map(({ id: uId, name: uName }) => ({
      label: uName,
      value: uId,
      id: uId,
    })),
  );

  return (
    <Field
      component={SelectField}
      name={`${modulePreffix}.assignedByJurisdiction`}
      options={usersToAssign}
      Trigger={<SelectTrigger placeholder="Choose Assigned To" />}
      Content={<SelectFilterContent />}
    />
  );
};

AutoAssignementRow.propTypes = {
  modulePreffix: PropTypes.string.isRequired,
};

export default React.memo(AutoAssignementRow);
