import { Box } from '@material-ui/core';
import OutlineButton from 'components/Buttons/OutlineButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Tooltip from 'components/Tooltip';
import React, { useMemo, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import LegalEntityFields from './components/LegalEntityFields';
import OrganizationSearchForm from './components/OrganizationSearchForm';
import './styles.scss';

const AddLegalEntitiesStep = ({ entitiesToGetValidated }) => {
  const [showSearchOrgForm, setShowSearchOrgForm] = useState(false);

  const { mutators } = useForm();

  const countries = useSelector(state => state.global.countries || []);

  const {
    input: { value: entitiesValue },
  } = useField('entities');

  const selectedEntitiesBusinessNumber = useMemo(
    () => (entitiesValue || []).map(entity => entity.leiNumber || entity.dunsNumber),
    [entitiesValue],
  );

  const addPerson = () => {
    mutators.push('entities', {
      affiliatedEntities: [
        {
          type: '0',
          primaryContact: true,
        },
      ],
    });
  };

  const handleAddOrganization = organization => {
    const countryId = countries.find(countryData => countryData.alpha2Code === organization.additionalInfo.country)?.id;

    mutators.push('entities', {
      affiliatedEntities: [
        {
          type: '0',
          primaryContact: true,
        },
      ],
      name: organization.name,
      jurisdictionOfIncorporationId: countryId,
      ...(organization.source === 'lei' ? { leiNumber: organization.number } : { dunsNumber: organization.number }),
      businessNumber: organization.additionalInfo.businessNumber,
      jurisdictionOfTaxResidenceId: countryId,
      address: {
        streetAddress: organization.additionalInfo.streetAddress,
        unit: organization.additionalInfo.unit,
        city: organization.additionalInfo.city,
        postalZip: organization.additionalInfo.postalZip,
        countryId,
        stateProvince: organization.additionalInfo.region,
      },
    });
  };

  return (
    <div className="ickyc-add-legal-entities-form">
      <div className="ickyc-add-legal-entities-form__header">
        <h1>Step 1 &nbsp;</h1>
        <h2>Add Legal Entities</h2>
      </div>
      <div className="ickyc-add-legal-entities-form__control-container">
        {!showSearchOrgForm && (
          <Tooltip
            placement="top"
            trigger={
              <div>
                <PrimaryButton type="button" onClick={() => setShowSearchOrgForm(true)}>
                  Search
                </PrimaryButton>
              </div>
            }
            content={
              <span>
                Conducting a name search will result in the retrieval of the organization's headquarters address and
                either its LEI or DUNS number
              </span>
            }
          />
        )}
        <OutlineButton className="ickyc-add-legal-entities-form__manual-entry-btn" onClick={addPerson}>
          Manual Entry
        </OutlineButton>
      </div>
      {showSearchOrgForm && (
        <OrganizationSearchForm
          selectedEntitiesBusinessNumber={selectedEntitiesBusinessNumber}
          onAddOrganization={handleAddOrganization}
        />
      )}
      {!!entitiesValue.length && (
        <>
          <Box className="ickyc-add-legal-entities-form__fields">
            <FieldArray name="entities">
              {({ fields, meta: { dirtySinceLastSubmit } }) => {
                return fields.map((name, index) => (
                  <LegalEntityFields
                    className="ickyc-add-legal-entities-form__fields__row"
                    index={index}
                    namePreffix={`${name}`}
                    dirtySinceLastSubmit={dirtySinceLastSubmit}
                    entitiesToGetValidated={entitiesToGetValidated}
                  />
                ));
              }}
            </FieldArray>
          </Box>
          <OutlineButton onClick={addPerson}>+ Add Row </OutlineButton>
        </>
      )}
    </div>
  );
};

export default AddLegalEntitiesStep;
