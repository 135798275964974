import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import RadioButtonField from '../../../../../../../../../components/RadioButtonField';
import RadioGroup from '../../../../../../../../../components/RadioGroup';
import SelectFilterContent from '../../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import ColorPickerInput from '../ColorPickerInput';
import LogoUploadInput from '../LogoUploadInput';

const StylingNaturalPersonForm = ({
  logoContainerClass,
  logoContainerRef,
  fontsArray,
  fontsLoading,
  toggleView,
  baseClass,
}) => {
  const {
    values: {
      styling: {
        colors: { darkBackgroundColor },
      },
    },
  } = useFormState();

  const { batch, change } = useForm();

  const setSuffix = setClassSuffix(baseClass);

  const pickLogoColorBasedOnBgColorAdvanced = bgColor => {
    //  Mark Ransoms theory for dark/light text color picking based on backgroud
    if (!bgColor) return;
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map(col => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      // eslint-disable-next-line no-restricted-properties
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return batch(() => {
      change('styling.colors.isPoweredByWhite', L < 0.179 ? 1 : 0);
    });
  };

  useEffect(() => {
    pickLogoColorBasedOnBgColorAdvanced(darkBackgroundColor);
  }, [darkBackgroundColor]);

  return (
    <div>
      <div className={setSuffix('__content__logo')}>
        <h2>Logo</h2>
        <Field
          name="styling.clientLogo.enabled"
          component={ToggleField}
          callback={toggleView}
          showCustomText
          toggleOnText="Enabled"
          toggleOffText="Disabled"
        />
      </div>
      <div className={logoContainerClass} ref={logoContainerRef}>
        <Field name="styling.clientLogo.imgUrl" component={LogoUploadInput} />
      </div>
      <h2>Styling</h2>
      <Field
        name="styling.colors.fonts"
        label="Font"
        component={SelectField}
        options={fontsArray}
        loading={fontsLoading}
        Trigger={<SelectTrigger />}
        Content={<SelectFilterContent />}
      />
      <RadioGroup label="Powered By IComply Logo Color">
        <Field
          component={RadioButtonField}
          name="styling.colors.isPoweredByWhite"
          type="radio"
          value={1}
          parse={val => Number(val)}
          label="White"
        />
        <Field
          component={RadioButtonField}
          name="styling.colors.isPoweredByWhite"
          type="radio"
          value={0}
          parse={val => Number(val)}
          label="Blue"
        />
      </RadioGroup>
      <Field name="styling.colors.bodyTextColor" label="Body Text Color" component={ColorPickerInput} />
      <Field name="styling.colors.backgroundTextColor" label="Background Text Color" component={ColorPickerInput} />
      <Field name="styling.colors.cardBackgroundColor" label="Card Background Color" component={ColorPickerInput} />
      <Field
        name="styling.colors.lightBackgroundColor"
        label="Light Background Gradient"
        component={ColorPickerInput}
      />
      <Field name="styling.colors.darkBackgroundColor" label="Dark Background Gradient" component={ColorPickerInput} />
      <Field name="styling.colors.interactionColor" label="Interaction Color" component={ColorPickerInput} />
      <Field name="styling.colors.accentColor" label="Accent Color" component={ColorPickerInput} />
    </div>
  );
};
StylingNaturalPersonForm.propTypes = {
  logoContainerClass: PropTypes.string,
  logoContainerRef: PropTypes.shape(),
  fontsArray: PropTypes.array,
  fontsLoading: PropTypes.bool,
  toggleView: PropTypes.func,
  baseClass: PropTypes.string,
};
StylingNaturalPersonForm.defaultProps = {
  logoContainerClass: '',
  logoContainerRef: null,
  fontsArray: [],
  fontsLoading: false,
  toggleView: () => {},
  baseClass: '',
};
export default StylingNaturalPersonForm;
