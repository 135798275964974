import React from 'react';

export default () => (
  <svg viewBox="0 0 89.7 90.7" width="24" height="24px" fill="currentColor">
    <g transform="matrix(1.48475 0 0 1.48475 30.0778 987.0517)">
      <g>
        <path d="M3.4-606.8c1.8 0 3.2-1.5 3.2-3.2 0-1.8-1.4-3.2-3.2-3.2-1.8 0-3.2 1.5-3.2 3.2 0 1.7 1.5 3.2 3.2 3.2zm0-4.7c.8 0 1.5.7 1.5 1.5s-.6 1.5-1.5 1.5c-.8 0-1.5-.7-1.5-1.5.1-.8.7-1.5 1.5-1.5z" />
        <path d="M7.9-661.4H-1c-1.5 0-2.7 1.2-2.7 2.7 0 1.5 1.2 2.7 2.7 2.7h8.9c1.5 0 2.7-1.2 2.7-2.7 0-1.4-1.2-2.7-2.7-2.7zm-.2 3.6H-.9c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h8.6c.5 0 .9.4.9.9s-.4.9-.9.9z" />
        <path d="M32.6-656.9c0-.7-.3-1.4-.9-1.9l-.2-.1c-1-.9-2.6-.8-3.5.2l-3.3 3.5-.7-.6c-1-.9-2.6-.9-3.5.2-.5.5-.7 1.2-.6 1.9 0 .7.4 1.3.9 1.8l2.6 2.3c.5.4 1 .6 1.6.6.7 0 1.3-.3 1.8-.8l5.1-5.1c.4-.6.7-1.3.7-2zm-2 .6l-5.1 5.1c-.3.3-.8.3-1.1 0l-2.6-2.3c-.2-.1-.3-.3-.3-.6 0-.2.1-.4.2-.6.3-.3.8-.4 1.1-.1l.9.9c.6.5 1.4.5 1.9-.1l3.5-3.7c.3-.3.8-.3 1.1-.1l.2.1c.2.1.3.4.3.6.1.4.1.6-.1.8z" />
        <path d="M26.1-664.4c-1.7 0-3.3.4-4.8 1.3-.9-.8-2.1-1.3-3.4-1.3h-29.1c-2.8 0-5.1 2.4-5 5.2v34c0 .5.4.9.9.9s.9-.4.9-.9v-27.5h31c.5 3 2.3 5.7 4.9 7.2v29.9h-35.8v-5.4c0-.5-.4-.9-.9-.9s-.9.4-.9.9v11.7c0 2.8 2.3 5.2 5.1 5.2h29.1c2.8 0 5.1-2.3 5.1-5.2v-35.5c1 .3 2.1.5 3.1.5 5.5-.1 9.9-4.5 9.8-10-.1-5.6-4.5-10.1-10-10.1zm-4.8 55c0 1.9-1.5 3.4-3.4 3.4h-29.1c-1.9 0-3.4-1.5-3.4-3.4v-4.6h35.8zm-5-45.3h-30.8v-4.6c0-1.9 1.5-3.4 3.4-3.4H18c.7 0 1.3.2 1.9.6-2.3 1.8-3.5 4.5-3.6 7.4zm9.9 8.5c-4.5 0-8.2-3.7-8.1-8.3 0-4.5 3.6-8.2 8.1-8.3 4.5 0 8.1 3.7 8.1 8.3 0 4.6-3.6 8.3-8.1 8.3zM-7-633.4h20.9v-17.5H-7zm1.2-16.1h18.2v14.7H-5.8z" />
        <path d="M-7.7-627.8c0 1.2.9 2.1 2.1 2.1h18c1.2 0 2.1-1 2.1-2.1v-1.4c0-1.2-.9-2.1-2.1-2.1h-18c-1.2 0-2.1 1-2.1 2.1zm1.7-1.5c0-.2.1-.4.3-.4h18c.2 0 .4.2.4.4v1.5c0 .2-.1.4-.3.4h-18c-.2 0-.4-.2-.4-.4z" />
        <path d="M-7.7-620.8c0 1.2.9 2.1 2.1 2.1H7.7c1.2 0 2.1-1 2.1-2.1v-1.4c0-1.2-.9-2.1-2.1-2.1H-5.6c-1.2 0-2.1 1-2.1 2.1zm1.7-1.5c0-.2.1-.4.3-.4H7.8c.2 0 .4.2.4.4v1.5c0 .2-.1.4-.3.4H-5.6c-.2 0-.4-.2-.4-.4z" />
        <path d="M5.3-641.3c.9-.6 1.3-1.6 1.3-2.7 0-1.8-1.4-3.3-3.3-3.4-1.8 0-3.3 1.5-3.3 3.4 0 1.1.5 2.1 1.3 2.7-1.5.8-2.4 2.3-2.4 4v1.1h8.7v-1.1c.2-1.7-.8-3.2-2.3-4z" />
      </g>
    </g>
  </svg>
);
