import EmailsPage from 'scenes/Administration/Emails';
import redirect from '../../../../../../../modules/redirect';

export default [
  {
    path: '/emails',
    component: EmailsPage,
  },
  {
    path: '*',
    component: redirect('/emails'),
  },
];
