import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Field, useFormState } from 'react-final-form';
import Constants from 'utilities/constants';
import DatePickerField from '../../../../../components/DatepickerField';
import InputField from '../../../../../components/InputField';
import SelectCustomContent from '../../../../../components/Select/components/SelectCustomContent';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectInputTrigger from '../../../../../components/Select/components/SelectInputTrigger';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../components/SelectField';
import utilities from '../../../../../utilities';
import authEnums from '../../../../../utilities/enums/authEnums';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import { PermissionGroupContext, UserManagementContext } from '../../../../../utilities/services/contexts';
import validators from '../../../../../utilities/services/validators';
import './styles.scss';

/**
 * Displays the data about the natural info profile
 * @param {boolean} editable - should fields be editable
 * @param {boolean} responsive - is form responsive
 */

const UserInformationForm = ({ editable, responsive, onCancel, onUpdate }) => {
  const classBase = !responsive ? 'ickyc-user-information' : 'ickyc-user-information--responsive';
  const setSuffix = setClassSuffix(classBase);
  const inputFieldsClasses = classNames([classBase]);

  const { withPassword } = useContext(UserManagementContext);
  const { edit: canEdit } = useContext(PermissionGroupContext);
  const {
    values: { userId },
  } = useFormState();

  const isEditable = true || !userId;
  return (
    <div className={inputFieldsClasses}>
      <div className={setSuffix('__block')}>
        <Field
          component={InputField}
          name="userInfo.firstName"
          label="First Name"
          validate={
            isEditable &&
            validators.composeValidators(
              validators.required('Required'),
              validators.stringMaxLength(Constants.PERSONAL_NAME_MAX_LENGTH),
            )
          }
          required
          preview={!isEditable}
          parse={utilities.parseName}
        />
        <Field
          component={InputField}
          label="Last Name"
          name="userInfo.lastName"
          validate={
            isEditable &&
            validators.composeValidators(
              validators.required('Required'),
              validators.stringMaxLength(Constants.PERSONAL_NAME_MAX_LENGTH),
            )
          }
          required
          preview={!isEditable}
          parse={utilities.parseName}
        />
        <Field component={DatePickerField} preview name="userInfo.lastLogin" label="Last Login" />
        <Field component={DatePickerField} preview name="userInfo.creationDate" label="Creation Date" />
      </div>

      <div className={setSuffix('__block')}>
        <Field
          component={InputField}
          name="userInfo.email"
          label="Email"
          preview={userId}
          required
          validate={
            isEditable &&
            validators.composeValidators(validators.required('Required*'), validators.email('Email format invalid'))
          }
        />
        {withPassword && !userId && (
          <Field component={InputField} name="userInfo.password" label="Temp. Password" preview={userId} />
        )}
        <Field
          component={SelectField}
          Trigger={<SelectInputTrigger />}
          Content={<SelectCustomContent />}
          multipleSelection
          name="userInfo.tags"
          label="Tags"
          preview={!isEditable}
          options={authEnums.TAGS}
          withCustomOptions
        />
        <Field
          component={SelectField}
          name="userInfo.status"
          label="Status"
          preview={!isEditable || !userId}
          options={authEnums.USER_STATUS}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContent />}
        />
      </div>
    </div>
  );
};

UserInformationForm.propTypes = {
  editable: PropTypes.bool,
  responsive: PropTypes.bool,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
};

UserInformationForm.defaultProps = {
  editable: false,
  onCancel: () => {},
  onUpdate: () => {},
  responsive: false,
};

export default UserInformationForm;
