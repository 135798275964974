/* eslint-disable react/forbid-prop-types */
import PropTypes, { object } from 'prop-types';
import React from 'react';
import Checkbox from '../Checkbox';

const CheckboxField = ({ input, className, ...rest }) => {
  return <Checkbox className={className} {...rest} {...input} />;
};

CheckboxField.propTypes = {
  input: object.isRequired,
  className: PropTypes.string,
};
CheckboxField.defaultProps = {
  className: undefined,
};
export default CheckboxField;
