import React from 'react';

export default () => (
  <svg viewBox="0 0 28.684 28.776">
    <g fill="currentColor" stroke="currentColor">
      <path d="M26.164 27.019H1.755V2.574h11.112V.819H.9a.86.86 0 00-.9.9V27.88a.86.86 0 00.9.9h26.161a.86.86 0 00.9-.9V15.909H26.2v11.11z" />
      <path d="M28.426 3.782L24.878.195l-.039-.039A.95.95 0 0024.332 0a.831.831 0 00-.624.273l-11.7 11.7c-.039.039-.078.117-.117.156l-.039.039-.078.078-1.4 4.991a.9.9 0 00.117.624.861.861 0 00.546.429h.429l4.829-1.328a.456.456 0 00.429-.312L28.387 4.991a.8.8 0 00.039-1.209zm-13.96 11.932l-2.028.546.546-2.028zm12.129-11.23L16.143 14.9l-2.262-2.262 10.45-10.45z" />
    </g>
  </svg>
);
