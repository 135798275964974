import PropTypes from 'prop-types';
import React from 'react';
import utilities from '../../../../../../../../../utilities';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../../../utilities/services/DateManager';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';

const BillingInfoRow = ({
  rowInfo: { clientLicenceName, saas, kycPlan, consumption, overage, overageRate, balance, clientLicenceForStatistic },
  className,
}) => {
  const setSufix = setClassSuffix(`${className}__column`);
  return (
    <CollapsibleTableRow
      className={className}
      row={
        <>
          <td className={setSufix('__month')}>
            <span>{clientLicenceName}</span>
          </td>
          <td className={setSufix('__saas')}>
            <span>${utilities.formatDecimalByIndianLocalization(saas)} </span>
          </td>
          <td className={setSufix('__kycPlan')}>
            <span>${utilities.formatDecimalByIndianLocalization(kycPlan)} </span>
          </td>
          <td className={setSufix('__consumption')}>
            <span>${utilities.formatDecimalByIndianLocalization(consumption)} </span>
          </td>
          <td className={setSufix('__overage')}>
            <span>${utilities.formatDecimalByIndianLocalization(overage)} </span>
          </td>
          <td className={setSufix('__overageRate')}>
            <span>{overageRate}%</span>
          </td>
          <td className={setSufix('__balance')}>
            <span>${utilities.formatDecimalByIndianLocalization(balance)} </span>
          </td>
          <td className={setSufix('__status')} />
          <td className={setSufix('__downloadInvoice')} />
        </>
      }
    >
      <div className={`${className}__information`}>
        <div>
          <span className={`${className}__information__label`}>Date Created &nbsp;</span>
          <span>
            {clientLicenceForStatistic?.startDate
              ? DateManager.monDayYearLocal(clientLicenceForStatistic?.startDate)
              : ''}
          </span>
        </div>
        <div>
          <span className={`${className}__information__label`}>Invoice Date &nbsp;</span>
          <span>
            {clientLicenceForStatistic?.invoiceDate
              ? DateManager.monDayYearLocal(clientLicenceForStatistic?.invoiceDate)
              : ''}
          </span>
        </div>
        <div>
          <span className={`${className}__information__label`}>Due Date &nbsp;</span>
          <span>
            {clientLicenceForStatistic?.endDate ? DateManager.monDayYearLocal(clientLicenceForStatistic?.endDate) : ''}
          </span>
        </div>
      </div>
    </CollapsibleTableRow>
  );
};
BillingInfoRow.propTypes = {
  rowInfo: PropTypes.shape({
    clientLicenceName: PropTypes.string,
    dateCreated: PropTypes.string,
    saas: PropTypes.number,
    kycPlan: PropTypes.number,
    consumption: PropTypes.number,
    overage: PropTypes.number,
    overageRate: PropTypes.number,
    balance: PropTypes.number,
    clientLicenceForStatistic: PropTypes.shape({
      startDate: PropTypes.string,
      invoiceDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  }),
  className: PropTypes.string,
};

BillingInfoRow.defaultProps = {
  className: '',
  rowInfo: {
    clientLicenceName: '',
    dateCreated: '',
    saas: 0,
    kycPlan: 0,
    consumption: 0,
    overage: 0,
    overageRate: 0,
    balance: 0,
    clientLicenceForStatistic: {},
  },
};
export default BillingInfoRow;
