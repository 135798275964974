import classNames from 'classnames';
import Tooltip from 'components/Tooltip';
import useSelect from 'hooks/useSelect';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { selectPersonalId } from 'store/selectors/auth.selector';
import toastr from 'toastr';
import setClassSuffix from 'utilities/services/ClassManager';
import { CommentsContext } from 'utilities/services/contexts';
import DateManager from 'utilities/services/DateManager';
import PrimaryButton from '../Buttons/PrimaryButton';
import './styles.scss';

/**
 * Comment component commonly used inside CommentList component
 * @param {string} date - date when the comment was created
 * @param {object} comment - comment object fetched from the server
 * @param {*} children - list of replies
 * @param {function} fetchReplies - api call for fetching replies
 * @param {boolean} top - fleg which differentiate if comment is the top comment
 * @param {function} removeReplyLocally - removes the reply locally
 * @param {boolean} areRepliesFetchedOutside - fleg which denotes that the replies are fetched
 * @param {number} type -
 */

const Comment = ({
  date,
  comment,
  children,
  parent,
  removeReplyLocally,
  areRepliesFetchedOutside,
  hasReplies,
  type,
  setReplies,
}) => {
  const [isMe] = useState(useSelect(selectPersonalId) === comment.userId);
  const [areRepliesLoaded, setAreRepliesLoaded] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [preEditComment, setPreEditComment] = useState('');
  const [text, setText] = useState('');
  const {
    deleteCommentLocally,
    updateCommentLocally,
    openCommentModal,
    setCommentsNumber,
    apiEditComment,
    apiGetReplies,
    apiDeleteComment,
  } = useContext(CommentsContext);
  const textareaRef = useRef(null);
  const [freshModifiedTime, setFreshModifiedTime] = useState(null);

  useEffect(() => {
    setText(comment.body);
  }, [comment]);

  const calcTextareaHeight = useCallback(() => {
    const res = `${textareaRef.current.scrollHeight}px`;

    textareaRef.current.style.height = res;
    textareaRef.current.style.minHeight = '70px';
  }, [textareaRef]);

  useEffect(() => {
    if (textareaRef.current) {
      calcTextareaHeight();
    }
  }, [textareaRef, text, calcTextareaHeight]);

  useEffect(() => {
    window.addEventListener('resize', calcTextareaHeight);

    const cleanUp = () => {
      window.removeEventListener('resize', calcTextareaHeight);
    };

    return cleanUp;
  }, [calcTextareaHeight]);

  const onReplyFetch = async () => {
    try {
      const { data } = await apiGetReplies(comment.commentId);
      setAreRepliesLoaded(true);
      setReplies(data.data);
    } catch (e) {
      console.error(e);
    }
  };

  const updateComment = async () => {
    const trimmedText = text.trim();

    if (!trimmedText.length) {
      return;
    }

    try {
      setIsLoading(true);
      const { data: res } = await apiEditComment(comment.commentId, { body: text });
      setFreshModifiedTime(res.timeModified);
      updateCommentLocally(res);
      toastr.success('Comment updated successfully.');
      setIsEditable(false);
    } catch (e) {
      console.error(e);
      toastr.error('Failed to update comment.');
    } finally {
      setIsLoading(false);
    }
  };

  const deleteComment = async () => {
    try {
      setIsLoading(true);
      await apiDeleteComment(comment.commentId);
      parent && setCommentsNumber(old => old - 1);
      parent ? deleteCommentLocally(date, comment.commentId) : removeReplyLocally();
      toastr.success('Successfully removed comment.');
    } catch (e) {
      console.error(e);
      toastr.error('Failed to remove comment.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderMyButtons = () => {
    return isEditable ? (
      <>
        <PrimaryButton
          variant="link"
          disabled={isLoading}
          onClick={() => {
            setText(preEditComment);
            setIsEditable(false);
          }}
        >
          Cancel
        </PrimaryButton>
        <PrimaryButton variant="link" disabled={isLoading} onClick={updateComment}>
          Save
        </PrimaryButton>
      </>
    ) : (
      <>
        <PrimaryButton
          variant="link"
          disabled={isLoading}
          onClick={() => {
            setPreEditComment(text);
            setIsEditable(prev => !prev);
          }}
        >
          Edit
        </PrimaryButton>

        <PrimaryButton variant="link" disabled={isLoading} onClick={deleteComment}>
          Delete
        </PrimaryButton>
      </>
    );
  };

  const renderReplyButton = () => {
    return parent ? (
      <PrimaryButton
        variant="link"
        onClick={() => {
          openCommentModal(comment.commentId);
        }}
      >
        Reply
      </PrimaryButton>
    ) : (
      <span />
    );
  };

  const dateModifiedFormatted = useMemo(
    () => DateManager.getFullDateLocalModified(freshModifiedTime || comment.timeModified),
    [freshModifiedTime, comment.timeModified],
  );

  const classBase = 'ickyc-comment';
  const setSuffix = setClassSuffix(classBase);

  const classes = classNames([
    classBase,
    {
      'ickyc-comment--stranger': !isMe,
      'ickyc-comment--parent': parent,
      'ickyc-comment--parent__with-replies': hasReplies,
    },
  ]);

  const textareaClasses = classNames([
    'ickyc-comment__top__content__copy',
    {
      'ickyc-comment__top__content__copy--editable': isEditable,
    },
  ]);

  return (
    <div className={classes}>
      <div className={setSuffix('__top')}>
        <div>
          <div className={setSuffix('__top__left-border')}>
            {/* Dot span */}
            <span />
          </div>
          {freshModifiedTime && DateManager.toLocal_hhmm(freshModifiedTime)}

          {!freshModifiedTime &&
            DateManager.toLocal_hhmm(comment.timeModified ? comment.timeModified : comment.timeCreated)}
        </div>

        <div className={setSuffix('__top__content')}>
          {isMe ? (
            <p>
              {parent ? (
                <>
                  <span>You</span> have commented on this {type}.
                  {(freshModifiedTime || comment.timeModified) && (
                    <Tooltip
                      placement="top"
                      trigger={<span className={setSuffix('__edited')}> Edited</span>}
                      content={`${dateModifiedFormatted} by ${comment.userFullName}`}
                    />
                  )}
                </>
              ) : (
                <>
                  <span>You</span> have replied to <b>{comment.parentCommentUser}</b>'s comment.
                </>
              )}
            </p>
          ) : (
            <p>
              {parent ? (
                <>
                  <span>{comment.userFullName}</span> has commented on this {type}.
                  {(freshModifiedTime || comment.timeModified) && (
                    <Tooltip
                      placement="top"
                      trigger={<span className={setSuffix('__edited')}> Edited</span>}
                      content={`${dateModifiedFormatted} by ${comment.userFullName}`}
                    />
                  )}
                </>
              ) : (
                <>
                  <span>{comment.userFullName}</span> has replied to <b>{comment.parentCommentUser}</b>'s comment.
                </>
              )}
            </p>
          )}
          <textarea
            ref={textareaRef}
            disabled={!isEditable}
            className={textareaClasses}
            value={text}
            onChange={e => setText(e.target.value)}
          />

          <div className={setSuffix('__top__content__personal-controls')}>
            {comment.numberOfReplies && !areRepliesLoaded && !areRepliesFetchedOutside ? (
              <PrimaryButton variant="link" onClick={onReplyFetch}>
                {`Load replies (${comment.numberOfReplies})`}
              </PrimaryButton>
            ) : (
              <span />
            )}

            <div className={setSuffix('__top__content__personal-controls__update')}>
              {isMe ? renderMyButtons() : renderReplyButton()}
            </div>
          </div>
        </div>
      </div>

      {children && <div className={setSuffix('__replies')}>{children}</div>}
    </div>
  );
};

Comment.propTypes = {
  comment: PropTypes.shape({
    commentId: PropTypes.number,
    body: PropTypes.string,
    editable: PropTypes.bool,
    numberOfReplies: PropTypes.number,
    parentId: PropTypes.number,
    timeCreated: PropTypes.string,
    timeModified: PropTypes.string,
    userFullName: PropTypes.string,
    userId: PropTypes.string,
    parentCommentUser: PropTypes.string,
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  date: PropTypes.string,
  parent: PropTypes.bool,
  hasReplies: PropTypes.bool,
  removeReplyLocally: PropTypes.func,
  areRepliesFetchedOutside: PropTypes.bool,
  type: PropTypes.string,
  setReplies: PropTypes.func,
};

Comment.defaultProps = {
  date: '',
  comment: {},
  parent: false,
  hasReplies: false,
  children: <></>,
  removeReplyLocally: () => {},
  areRepliesFetchedOutside: false,
  type: 'entity',
  setReplies: () => {},
};

export default Comment;
