import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/clients/settings';

const ENDPOINTS = {
  emailALinkTemplates: forceRequest => `${BASE_ROUTE}/emails/email-a-link?forceRequest=${forceRequest}`,
  RCUTemplates: forceRequest => `${BASE_ROUTE}/emails/client-update?forceRequest=${forceRequest}`,
  updateEmailALinkTemplates: (templateId, forceRequest) =>
    `${BASE_ROUTE}/emails/email-a-link/${templateId}?forceRequest=${forceRequest}`,
  updateRCUTemplates: (templateId, forceRequest) =>
    `${BASE_ROUTE}/emails/client-update/${templateId}?forceRequest=${forceRequest}`,
  getDomainsForEmailALinkTemplate: () => `${BASE_ROUTE}/emails/email-a-link/domains`,
  getDomainsForRCUTemplate: () => `${BASE_ROUTE}/emails/client-update/domains`,
  deleteEmailTemplate: templateId => `${BASE_ROUTE}/emails/${templateId}`,
  getEmailTemplatePreview: () => `${BASE_ROUTE}/emails/preview`,
};

const getEmailALinkTemplates = () => axiosInstance.get(ENDPOINTS.emailALinkTemplates());
const getRCUTemplates = () => axiosInstance.get(ENDPOINTS.RCUTemplates());
const createEmailALinkTemplates = (params, forceRequest) =>
  axiosInstance.post(ENDPOINTS.emailALinkTemplates(forceRequest), params);
const createRCUTemplates = (params, forceRequest) => axiosInstance.post(ENDPOINTS.RCUTemplates(forceRequest), params);
const updateEmailALinkTemplates = ({ id, ...params }, forceRequest) =>
  axiosInstance.put(ENDPOINTS.updateEmailALinkTemplates(id, forceRequest), params);
const updateRCUTemplates = ({ id, ...params }, forceRequest) =>
  axiosInstance.put(ENDPOINTS.updateRCUTemplates(id, forceRequest), params);
const getDomainsForEmailALinkTemplate = () => axiosInstance.get(ENDPOINTS.getDomainsForEmailALinkTemplate());
const getDomainsForRCUTemplate = () => axiosInstance.get(ENDPOINTS.getDomainsForRCUTemplate());
const deleteEmailTemplate = id => axiosInstance.delete(ENDPOINTS.deleteEmailTemplate(id));
const getEmailTemplatePreview = () => axiosInstance.get(ENDPOINTS.getEmailTemplatePreview());

export default {
  getEmailALinkTemplates,
  getRCUTemplates,
  createEmailALinkTemplates,
  createRCUTemplates,
  updateEmailALinkTemplates,
  updateRCUTemplates,
  getDomainsForEmailALinkTemplate,
  getDomainsForRCUTemplate,
  deleteEmailTemplate,
  getEmailTemplatePreview,
};
