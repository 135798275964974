import { Popover } from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
/**
 * Row displayed inside the address table
 * @param {object} address - address displayed in the row
 */
import toastr from 'toastr';
import api from '../../../../../../../../api';
import Badge from '../../../../../../../../components/Badge';
import DeleteButton from '../../../../../../../../components/Buttons/DeleteButton';
import FormModal from '../../../../../../../../components/Modal/FormModal';
import setClassSuffix from '../../../../../../../../utilities/services/ClassManager';
import {
  EntityContext,
  HistoryRecordContext,
  PermissionGroupContext,
} from '../../../../../../../../utilities/services/contexts';
import AssignToUserDropdown from '../../../../../../components/AssignToUserDropdown';

const AddressHistoryRecord = ({ className, onRemove }) => {
  const {
    city,
    country,
    postalZip,
    stateProvince,
    street,
    unit,
    addressTypes,
    assignedUser,
    status,
    eventId,
    issues,
  } = useContext(HistoryRecordContext);
  const { entityId } = useContext(EntityContext);
  const setSuffix = setClassSuffix(`${className}__column`);
  const [anchorEl, setAnchorEl] = useState(false);
  const [confirmationModal, setConfirmModalOpen] = useState(false);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onHistoryRecordDelete = useCallback(() => {
    return api.kyc.entityManagement
      .deleteHistoryRecordsSoft(entityId, eventId)
      .then(() => {
        setConfirmModalOpen(false);
        onRemove(eventId);
        toastr.success(`History Record has been removed`);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  }, [entityId, onRemove, eventId]);

  const onRemoveIconClick = useCallback(e => {
    e.stopPropagation();
    setConfirmModalOpen(true);
  }, []);
  const preventBubbling = event => {
    event.stopPropagation();
  };

  const addressPT1 = useMemo(() => {
    if (!street && !unit) {
      return <></>;
    }
    return (
      <>
        {street}&nbsp;{unit}
        <br />
      </>
    );
  }, [street, unit]);

  const addressPT2 = useMemo(() => {
    if (city) {
      if (stateProvince || postalZip)
        return (
          <>
            {city}, {stateProvince} {postalZip}
          </>
        );
      return <>{city}</>;
    }
    return (
      <>
        {stateProvince} {postalZip}
      </>
    );
  }, [city, stateProvince, postalZip]);

  // TODO REMOVE MATERIAL UI
  const open = Boolean(anchorEl);
  const { edit: canEdit, delete: canDelete } = useContext(PermissionGroupContext);
  return (
    <>
      <td className={setSuffix('__addressTypes')}>
        {addressTypes.includes('headquarter') && (
          <>
            <Popover
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              className="ickyc-popover-wrapper"
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              open={open}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              Headquarter
            </Popover>
            <Badge content="HQ" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} />
          </>
        )}
      </td>
      <td className={setSuffix('__address')}>
        {addressPT1}
        {addressPT2}
      </td>
      <td className={setSuffix('__country')}> {country}</td>

      <td className={setSuffix('__assignedTo')} onClick={preventBubbling}>
        <AssignToUserDropdown
          renderAsPortal
          assignedUserId={assignedUser?.id}
          eventId={eventId}
          preview={!canEdit}
          assignEvent
        />
      </td>
      <td className={setSuffix('__status')}>{status}</td>
      <td className={setSuffix('__issues')}>
        <Badge content={issues} showOnlyNumber={status === 'Rejected'} red />
      </td>
      {canDelete && (
        <td className={setSuffix('__remove')} onClick={preventBubbling}>
          <DeleteButton onClick={onRemoveIconClick} />
          {confirmationModal && (
            <FormModal
              hideModal={() => setConfirmModalOpen(false)}
              title="Remove Address"
              className="ickyc-confirmation-modal"
              onSubmit={onHistoryRecordDelete}
            >
              <span className="ickyc-confirmation-message">Are you sure you want to delete ?</span>
            </FormModal>
          )}
        </td>
      )}
    </>
  );
};

AddressHistoryRecord.propTypes = {
  className: PropTypes.string,
  onRemove: PropTypes.func,
};
AddressHistoryRecord.defaultProps = {
  className: '',
  onRemove: () => {},
};

export default AddressHistoryRecord;
