import { Lock } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import ModuleSettings from '../ModuleSettings';
import ModuleTitleSettings from '../ModuleTitleSettings';
import './styles.scss';

const FinalStepModule = ({ index, indexToShow, isDropAllowed }) => {
  const className = 'ickyc-table';
  const classes = classNames([{ 'ickyc-final-step-white-section': indexToShow % 2 === 0 }, className]);

  return (
    <CollapsibleTableRow
      isDropAllowed={isDropAllowed}
      className={classes}
      withIndexColumn
      index={indexToShow}
      icon={<Lock />}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Final Screen</td>
          <td className={`${className}__column__enabled--empty`} />
        </>
      }
    >
      <ModuleSettings>
        <ModuleTitleSettings index={index} withDescription />
      </ModuleSettings>
    </CollapsibleTableRow>
  );
};
FinalStepModule.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
};
export default FinalStepModule;
