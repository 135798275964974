import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import toastr from 'toastr';
import api from '../../../../../api';
import Loader from '../../../../../components/Loader';
import FormModal from '../../../../../components/Modal/FormModal';
import Spinner from '../../../../../components/Spinner';
import bus from '../../../../../modules/bus';
import enums from '../../../../../utilities/enums';
import { EntityContext, RCUContext } from '../../../../../utilities/services/contexts';
import GeneralLegalEntitySettings from './components/GeneralLegalEntitySettings';
import './styles.scss';

const RequestClientUpdateLegalEntityModal = ({ hideModal, ...rest }) => {
  const [shouldForce, setShoudForce] = useState(false);
  const classBase = 'ickyc-request-client-update-legal-entity-modal';
  const [selectedDomainGroup, setSelectedDomainGroup] = useState();
  const [domainsConfigs, setDomainsConfigs] = useState([]);
  const [initialConfiguration, setInitialConfiguration] = useState({});
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const [isLoadingDomains, setIsLoadingDomains] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [errMessage, setErrMessage] = useState(null);
  const { id: entityId } = useParams();
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [globalErrorMessage, setGlobalErrorMessage] = useState();

  const entity = useContext(EntityContext);
  useEffect(() => {
    const reloadData = () => {
      setIsLoadingSettings(true);
      api.kyc.entityManagement
        .getLESettingsForRCU(selectedDomainGroup.id, entityId, selectedDomain)
        .then(({ data: responseData }) => {
          setInitialConfiguration(responseData);
          setInitialValues(prev => ({
            ...prev,
            recepientId: responseData?.affiliatedEntities.length ? responseData?.affiliatedEntities[0]?.id : null,
          }));
        })
        .catch(err => {
          console.log(err);
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              setErrMessage(Array.isArray(errData.message) ? errData.message.join('') : errData.message);
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoadingSettings(false);
        });
    };

    if (selectedDomainGroup?.id && selectedDomain) reloadData();
  }, [selectedDomainGroup, entityId, selectedDomain]);

  useEffect(() => {
    if (selectedDomainGroup) {
      const defaultDomain = selectedDomainGroup.domains.find(dom => dom.isDefault);
      setInitialValues(prev => ({ ...prev, domain: defaultDomain ? defaultDomain.url : selectedDomainGroup[0]?.url }));
    }
  }, [selectedDomainGroup]);

  useEffect(() => {
    if (selectedDomain && selectedDomainGroup?.id) {
      const newDomainGroup = domainsConfigs.find(domainConfig =>
        domainConfig.domains.some(d => d.url === selectedDomain),
      );
      if (selectedDomainGroup.id !== newDomainGroup?.id) {
        setSelectedDomainGroup(newDomainGroup);
      }
    }
  }, [selectedDomain, domainsConfigs, selectedDomainGroup]);

  useEffect(() => {
    const loadDomains = () => {
      setIsLoadingDomains(true);
      api.kyc.entityManagement
        .getLegalEntityDomains()
        .then(({ data: responseData }) => {
          if (!responseData.length) {
            setDisableSubmitButton(true);
            setGlobalErrorMessage('Please create a portal that is active in order to process the RCU request');
          }

          let configurations = responseData;
          let defaultConfiguration = responseData.find(config => config.isDefault);
          if (!defaultConfiguration) {
            configurations[0].isDefault = true;
            defaultConfiguration = configurations[0];
          }

          let defaultDomain = defaultConfiguration?.domains.find(domain => domain.isDefault);
          if (!defaultDomain) {
            defaultConfiguration.domains[0].isDefault = true;
          }

          setSelectedDomainGroup(defaultConfiguration);
          setDomainsConfigs(configurations);
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => {
          setIsLoadingDomains(false);
        });
    };
    loadDomains();
  }, []);

  const mappedDomainsList = useMemo(() => {
    return domainsConfigs
      .reduce((acc, v) => [...acc, ...v.domains], [])
      .map(domainEl => ({
        id: domainEl.url,
        value: domainEl.url,
        label: domainEl.url,
      }));
  }, [domainsConfigs]);

  const handleSubmit = async values => {
    const submitObj = values;

    submitObj.configId = selectedDomainGroup?.id;

    return api.kyc.entityManagement
      .requestClientUpdateLegalEntity(
        entity.entityId,
        {
          ...submitObj,
        },
        shouldForce,
      )
      .then(({ data }) => {
        const { newEvents, expiredEvents = [] } = data;
        toastr.success(`Request has been forwarded`);

        if (newEvents['Supporting Document']) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_DOCUMENTS_RECORD, newEvents['Supporting Document']);
          newEvents['Supporting Document'].forEach(doc =>
            bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
              type: 'kyc',
              section: enums.ACCORDION_INDEXES.DOCUMENTS,
              issuesChange: doc?.issues,
            }),
          );
        }
        if (newEvents['Corporate Verification']) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_CORPORATE_TABLE_RECORD, newEvents['Corporate Verification']);
          bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
            type: 'kyc',
            section: enums.ACCORDION_INDEXES.CORPORATE_INFORMATION,
            issuesChange: newEvents['Corporate Verification']?.issues,
          });
        }
        if (expiredEvents.length > 0) {
          expiredEvents.forEach(({ id, type }) => {
            bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
              type: 'kyc',
              section: enums.EVENT_TYPES[type],
              issuesChange: -1,
            });

            bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, {
              eventHistoryId: id,
              data: {
                status: 'Canceled',
                issues: 0,
              },
            });
          });
        }
        hideModal();
      })
      .catch(err => {
        console.log(err);
        if (err?.response) {
          console.log(err?.response);
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            if (data?.message?.length || Array.isArray(data?.message)) {
              if (data.message.includes('event in pending status')) {
                setShoudForce(true);
                return { [FORM_ERROR]: data.message };
              }
              setShoudForce(false);
              return { [FORM_ERROR]: data.message };
            }
            return {
              [FORM_ERROR]:
                (Array.isArray(data?.message) && data.message.join(' ')) ||
                data?.message ||
                data?.errors?.RecepientId ||
                'Error Occurred',
            };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        }
        return { [FORM_ERROR]: 'Error trying to Request Client Update' };
      });
  };

  return (
    <FormModal
      hideModal={hideModal}
      title={`Request Client Update - ${entity.entityName}`}
      withFieldArray
      onSubmit={handleSubmit}
      className={classBase}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      showConfirmationForm={shouldForce}
      onRejectClick={hideModal}
      showErrorsInConfirmation
      confirmButtonText="Yes"
      confirmationText="Would you like to proceed and override previous request?"
      rejectButtonText="No"
      disableSubmitButton={disableSubmitButton}
      {...rest}
    >
      {globalErrorMessage ? (
        <div className="ickyc-form-modal__submissionError">{globalErrorMessage}</div>
      ) : (
        <div className={`${classBase}__container`}>
          {isLoadingDomains ? (
            <Spinner />
          ) : (
            <>
              {isLoadingSettings && <Loader />}
              <RCUContext.Provider value={{ initialConfiguration }}>
                <GeneralLegalEntitySettings
                  domains={mappedDomainsList}
                  errMessage={errMessage}
                  setSelectedDomain={setSelectedDomain}
                  withRecipient
                />
              </RCUContext.Provider>
            </>
          )}
        </div>
      )}
    </FormModal>
  );
};
RequestClientUpdateLegalEntityModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};
RequestClientUpdateLegalEntityModal.defaultProps = {};
export default RequestClientUpdateLegalEntityModal;
