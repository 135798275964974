import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/clients/account';

const ENDPOINTS = {
  deletePaymentMethod: paymentId => `${BASE_ROUTE}/payment-methods/${paymentId}`,
  setDefaultPaymentMethod: paymentId => `${BASE_ROUTE}/payment-methods/set-default/${paymentId}`,
  paymentMethods: () => `${BASE_ROUTE}/payment-methods`,
  billingHistory: () => `${BASE_ROUTE}/billing-history`,
  companyDetails: () => `${BASE_ROUTE}/company-details`,
  companySettings: () => `${BASE_ROUTE}/company-settings`,
  usage: () => `${BASE_ROUTE}/usage`,
  updateCompanyLogo: () => `${BASE_ROUTE}/company-settings/logo`,
  deleteCompanyLogo: () => `${BASE_ROUTE}/company-settings/logo`,
  getGlobalTwoFactorAuthentication: () => `${BASE_ROUTE}/global-two-factor`,
};

const addNewPaymentMethod = data => axiosInstance.post(ENDPOINTS.paymentMethods(), data);
const getPaymentMethods = data => axiosInstance.get(ENDPOINTS.paymentMethods(), data);
const billingHistory = data => axiosInstance.get(ENDPOINTS.billingHistory(), data);

const deletePaymentMethod = (paymentId, data) => axiosInstance.delete(ENDPOINTS.deletePaymentMethod(paymentId), data);
const setDefaultPaymentMethod = (paymentId, data) =>
  axiosInstance.put(ENDPOINTS.setDefaultPaymentMethod(paymentId), data);
const companyDetails = data => axiosInstance.get(ENDPOINTS.companyDetails(), data);
const updateCompanyDetails = data => axiosInstance.put(ENDPOINTS.companyDetails(), data);
const companySettings = data => axiosInstance.get(ENDPOINTS.companySettings(), data);
const updateCompanySettings = data => axiosInstance.put(ENDPOINTS.companySettings(), data);
const updateCompanyLogo = data => axiosInstance.put(ENDPOINTS.updateCompanyLogo(), data);
const deleteCompanyLogo = () => axiosInstance.delete(ENDPOINTS.deleteCompanyLogo());

const usage = data => axiosInstance.get(ENDPOINTS.usage(), data);

const getGlobalTwoFactorAuthentication = () => axiosInstance.get(ENDPOINTS.getGlobalTwoFactorAuthentication());

export default {
  addNewPaymentMethod,
  getPaymentMethods,
  billingHistory,
  deletePaymentMethod,
  setDefaultPaymentMethod,
  companyDetails,
  updateCompanyDetails,
  companySettings,
  updateCompanySettings,
  usage,
  updateCompanyLogo,
  deleteCompanyLogo,
  getGlobalTwoFactorAuthentication,
};
