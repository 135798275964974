import PrimaryButton from 'components/Buttons/PrimaryButton';
import Checkbox from 'components/Checkbox';
import CheckboxField from 'components/CheckboxField';
import DebounceInput from 'components/DebounceInput';
import InputField from 'components/InputField';
import RadioButtonField from 'components/RadioButtonField';
import useSelect from 'hooks/useSelect';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import VirtualList from 'react-vlist';
import { selectCurrencies } from 'store/selectors/global.selector';
import validators from 'utilities/services/validators';
import './styles.scss';

const Currency = () => {
  const baseClass = 'ickyc-custom-currency';
  const [selectedAll, setSelectedAll] = useState(false);
  const { change } = useForm();
  const [searchString, setSearchString] = useState();

  const listOfAvailableCurrencies = useSelect(selectCurrencies) || [];

  const {
    values: { parameters: currenciesObject },
  } = useFormState();

  const listOfCurrencies = useMemo(() => Object.entries(currenciesObject).map(el => ({ ...el[1] })) || [], [
    currenciesObject,
  ]);

  const areSelectedAll = useMemo(
    () => selectedAll || (listOfCurrencies.every(val => val.selected) && listOfCurrencies.length > 0),
    [selectedAll, listOfCurrencies],
  );

  const numberOfSelected = useMemo(() => (listOfCurrencies.filter(el => el?.selected) || [])?.length, [
    listOfCurrencies,
  ]);

  const filteredCurrencies = useMemo(() => {
    if (!searchString) return listOfAvailableCurrencies;

    return listOfAvailableCurrencies.filter(currency =>
      currency.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [searchString, listOfAvailableCurrencies]);

  const applySearchTerm = useCallback(
    searchString => {
      setSearchString(searchString);
    },
    [setSearchString],
  );

  const onSelectAll = useCallback(() => {
    const filteredListOfCurrencies = listOfCurrencies.filter(currency =>
      filteredCurrencies.some(filteredCurrency => filteredCurrency.code === currency.code),
    );

    filteredListOfCurrencies.forEach(currency => {
      // undefined in order to delete prop, since there's no 'selected' in initial values
      change(`parameters.currency${currency?.id}.selected`, selectedAll ? undefined : true);
    });

    setSelectedAll(prev => !prev);
  }, [change, listOfCurrencies, selectedAll, filteredCurrencies]);

  const onToggleSelect = useCallback(
    toggleValue => {
      listOfCurrencies.forEach(el => {
        if (el?.selected) {
          change(`parameters.currency${el?.id}.isEnabled`, toggleValue);
        }
      });
    },
    [change, listOfCurrencies],
  );

  const renderRow = useCallback((item, index, style) => {
    const { id, code, name } = item;
    const listItemClass = `${baseClass}__list__item`;

    return (
      <li key={index} style={style}>
        <div className={`${listItemClass}__checkbox`}>
          <Field
            parse={value => value || undefined}
            component={CheckboxField}
            type="checkbox"
            readOnly
            name={`parameters.currency${id}.selected`}
          />
        </div>
        <div className={`${listItemClass}__code`}>{code}</div>
        <div className={`${listItemClass}__long-name`}>{name}</div>
        <div className={`${listItemClass}__decimal`}>
          <Field
            component={InputField}
            name={`parameters.currency${id}.decimalPlaces`}
            label="Decimal Places"
            type="number"
            min="0"
            max="10"
          />
        </div>
        <div className={`${listItemClass}__enabled`}>
          <Field
            component={RadioButtonField}
            type="radio"
            value={false}
            label="Disabled"
            inline
            iconPosition="left"
            parse={val => val === 'true'}
            name={`parameters.currency${id}.isEnabled`}
          />
        </div>
        <div className={`${listItemClass}__enabled`}>
          <Field
            component={RadioButtonField}
            type="radio"
            value={true}
            parse={val => val === 'true'}
            label="Enabled"
            inline
            iconPosition="left"
            name={`parameters.currency${id}.isEnabled`}
          />
        </div>
      </li>
    );
  }, []);

  return (
    <div className="ickyc-custom-currency">
      <div className="ickyc-custom-document-options">
        <span className="ickyc-custom-document-options--label">Question ID</span>
        <span>
          <Field name="id" component={InputField} preview></Field>
        </span>
        <span className="ickyc-custom-document-options--label">Character Limit</span>
        <span>
          <Field
            name="parameters.characterLimit"
            component={InputField}
            type="number"
            validate={validators.composeValidators(
              validators.required(),
              validators.minAllowedValue('Please note that character liimit must be a positive number', 1),
            )}
          />
        </span>
      </div>
      <div className={`ickyc-custom-currency__header`}>
        <div className="ickyc-custom-currency__header__selected">
          <Checkbox checked={areSelectedAll} onChange={onSelectAll} label="Select All" />
        </div>
        <DebounceInput onChange={applySearchTerm} placeholder="Search Currencies" />
        <span className="ickyc-custom-currency__header__number-of-selected"> {numberOfSelected} Selected</span>
        <div className="ickyc-custom-currency__header__button-container">
          <PrimaryButton onClick={() => onToggleSelect(false)}> Disable Selected</PrimaryButton>
          <PrimaryButton onClick={() => onToggleSelect(true)}> Enable Selected</PrimaryButton>
        </div>
      </div>
      <div>
        <VirtualList
          className={`ickyc-custom-currency__list`}
          data={filteredCurrencies}
          itemheight={40}
          renderItems={(item, index, style) => renderRow(item, index, style)}
        />
      </div>
    </div>
  );
};
export default Currency;
