const removeHeadquarterAndAddAdditionalAddressIfNeeded = ({ addressTypes, ...restAddress }) => {
  if (addressTypes.includes('headquarter')) {
    addressTypes = addressTypes.filter(type => type !== 'headquarter');
  }

  if (!addressTypes.includes('additional')) {
    addressTypes.push('additional');
  }

  return { addressTypes, ...restAddress };
};

export default { removeHeadquarterAndAddAdditionalAddressIfNeeded };
