import PropTypes from 'prop-types';
import React from 'react';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import './styles.scss';

const FaceMatchControlButtons = ({ onClick, disabled }) => {
  const globalValues = {
    SELFIE_UPLOAD: 'Selfie Upload',
    LIVE_FACE_MATCH: 'Live Face Match',
  };
  return (
    <span className="ickyc-face-match-control-buttons">
      <PrimaryButton
        onClick={() => onClick({ biometricAuthenticationType: globalValues.LIVE_FACE_MATCH })}
        disabled={disabled}
      >
        Live Face Match Selected
      </PrimaryButton>

      <PrimaryButton
        onClick={() => onClick({ biometricAuthenticationType: globalValues.SELFIE_UPLOAD })}
        disabled={disabled}
      >
        <span>Selfie Upload Selected</span>
      </PrimaryButton>
    </span>
  );
};

FaceMatchControlButtons.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

FaceMatchControlButtons.defaultProps = {
  onClick: () => {},
  disabled: false,
};
export default FaceMatchControlButtons;
