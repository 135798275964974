import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import api from '../../../api';
import IComplyForm from '../../../components/Form/IComplyForm';
import InputField from '../../../components/InputField';
import { setToken } from '../../../store/actions/auth.action';
import tokenHandler from '../../../utilities/services/local-storage/TokenStorage';
import './styles.scss';

const NewPasswordForm = ({ setResetTokenError, resetToken }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const email = params.get('email');
  const handleCreateNewPassword = async data => {
    try {
      const response = await api.auth.createNewPassword({ ...data, resetToken });
      const { data: dataRes } = response;
      tokenHandler.token = dataRes.token;
      tokenHandler.refreshToken = dataRes.refreshToken;
      // Saving token inside redux
      dispatch(setToken(dataRes.token));
      history.push('/');
    } catch ({ response }) {
      const { status, data: dataFromRes } = response;
      if (status === 422) {
        return { [FORM_ERROR]: dataFromRes.errors.join() };
      }
      return { [FORM_ERROR]: dataFromRes?.message || dataFromRes?.errors || 'Something Went wrong' };
    }
  };
  const baseClass = 'ickyc-form';
  return (
    <IComplyForm
      className="icompy-new-password-form"
      onSubmit={handleCreateNewPassword}
      showControls
      noDiscard
      initialValues={{ email: email?.replaceAll(' ', '+') }}
    >
      <div className={`${baseClass}__fields ${baseClass}__fields--rounded icompy-new-password-form--content`}>
        <h4>Create a New Password</h4>
        <p>Please enter a new password to replace your old one.</p>
        <Field component={InputField} name="email" label="Email" type="text" autocomplete="off" preview={email} />
        <Field component={InputField} name="password" label="New Password" type="password" autocomplete="off" />
        <Field
          component={InputField}
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          autocomplete="off"
        />
      </div>
    </IComplyForm>
  );
};
NewPasswordForm.propTypes = {
  resetToken: PropTypes.string.isRequired,
  setResetTokenError: PropTypes.func.isRequired,
};

export default NewPasswordForm;
