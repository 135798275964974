import axiosInstance from '../axiosInstance';

const BASE_ROUTE = '/client-user';

const ENDPOINTS = {
  confirmNewEmail: () => `${BASE_ROUTE}/email-confirmation`,
  recejtEmailChange: () => `${BASE_ROUTE}/email-confirmation/reject`,
};
const confirmNewEmail = code => {
  return axiosInstance.post(ENDPOINTS.confirmNewEmail(), { code });
};
const recejtEmailChange = () => {
  return axiosInstance.post(ENDPOINTS.recejtEmailChange());
};
export default {
  confirmNewEmail,
  recejtEmailChange,
};
