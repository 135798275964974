import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import PrimaryButton from '../../Buttons/PrimaryButton';
import BareHeading from '../BareHeading';

/**
 * Header component consisting of title and toggle.
 * Styles are coverd in BareHeading component.
 * @param {string} title - page title
 * @param {array[component]} buttonCopies - array of button copies inside the button
 * @param {array[function]} clickHandlers - array of click handlers
 */

const ButtonHeading = ({ title, buttonCopies, clickHandlers, h1, buttonDisabled }) => {
  return (
    <BareHeading title={title} h1={h1}>
      <>
        {buttonCopies.map((copy, index) => (
          <PrimaryButton disabled={buttonDisabled?.[index]} onClick={clickHandlers[index]} key={uuid()}>
            {copy}
          </PrimaryButton>
        ))}
      </>
    </BareHeading>
  );
};

ButtonHeading.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  buttonCopies: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  clickHandlers: PropTypes.arrayOf(PropTypes.func).isRequired,
  buttonDisabled: PropTypes.arrayOf(PropTypes.bool),
  h1: PropTypes.bool,
};

ButtonHeading.defaultProps = {
  h1: true,
  buttonDisabled: [],
};

export default ButtonHeading;
