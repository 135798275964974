import axiosInstance from '../../axiosInstance';

const BASE_ROUTE = '/internal/email-templates';

const ENDPOINTS = {
  getEmailTemplates: () => BASE_ROUTE,
  emailTemplate: id => `${BASE_ROUTE}/${id}`,
};

const getEmailTemplates = params => axiosInstance.get(ENDPOINTS.getEmailTemplates(), { params });
const getEmailTemplate = id => axiosInstance.get(ENDPOINTS.emailTemplate(id));
const updateEmailTemplate = ({ id, ...data }) => axiosInstance.put(ENDPOINTS.emailTemplate(id), data);
export default {
  getEmailTemplates,
  getEmailTemplate,
  updateEmailTemplate,
};
