import api from 'api';
import { NEW_PACKAGE_ITEM_ID } from 'components/DocumentsAndCustomQuestions/utils';
import { FORM_ERROR } from 'final-form';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDocumentPackages } from 'store/actions/global.action';
import toastr from 'toastr';

export default function useDocumentsAndCustomQuestionsHandlers(rowInfo, updateRowData, onRemove, setisUpdating, index) {
  const [documentId, setDocumentId] = useState(rowInfo?.id);
  const [forceDelete, setForceDelete] = useState(false);

  const { type } = rowInfo;

  const dispatch = useDispatch();

  const updateDocumentPackages = useCallback(async () => {
    const { data } = await api.kyc.entityManagement.getSupportingPackages();

    dispatch(setDocumentPackages(data));
  }, []);

  const handleCreatePackageDocument = useCallback(
    async values => {
      const { document, parameters, ...rest } = values;

      const formData = new FormData();
      formData.set('document', document?.[0]?.file || null);
      formData.set(
        'item',
        JSON.stringify({ ...rest, parameters: JSON.stringify(parameters), documentOriginalName: document?.name }),
      );

      const { data } = await api.administration.supportingDocumentPackages.createSupportingDocumentPackageDocument(
        rest.packageId,
        formData,
      );

      setDocumentId(data?.id);
      updateRowData('id', NEW_PACKAGE_ITEM_ID, data);
    },
    [updateRowData],
  );

  const handleCreatePackageItem = useCallback(
    async values => {
      let transformedParameters = values?.parameters;

      if (values?.type === 'CurrencyField') {
        const { characterLimit, ...restOfParams } = values?.parameters;
        transformedParameters = {
          characterLimit,
          items: Object.entries(restOfParams).map(elm => elm[1]),
          // .filter(el => el.isEnabled), for now we are sending all not just enabled
        };
      }

      const { data } = await api.administration.supportingDocumentPackages.createSupportingDocumentPackageItem({
        ...values,
        parameters: JSON.stringify(transformedParameters),
      });

      setDocumentId(data?.id);
      updateRowData('id', NEW_PACKAGE_ITEM_ID, data);
    },
    [updateRowData],
  );

  const handleCreate = useCallback(
    async values => {
      try {
        setisUpdating(true);

        if (type === 'Document') {
          await handleCreatePackageDocument(values);
        } else {
          await handleCreatePackageItem(values);
        }

        toastr.success('Successfully created package item');
      } catch (err) {
        const {
          response: { data },
        } = err;
        console.error(err);
        toastr.error('Error occured while creating package item');

        return { [FORM_ERROR]: data?.errors || err?.message };
      } finally {
        setisUpdating(false);
      }
    },
    [handleCreatePackageDocument, handleCreatePackageItem, setisUpdating, type],
  );

  const handleUpdatePackageDocument = useCallback(
    async values => {
      const { document, shouldDelete, parameters, ...rest } = values;

      const formData = new FormData();
      formData.set('document', document?.[0]?.file || null);
      formData.set(
        'item',
        JSON.stringify({ ...rest, parameters: JSON.stringify(parameters), documentOriginalName: document?.[0]?.name }),
      );

      const { data } = await api.administration.supportingDocumentPackages.updateSupportingDocumentPackageDocument(
        formData,
      );

      setDocumentId(data?.id);
    },
    [updateRowData],
  );

  const handleUpdatePackageItem = useCallback(
    async values => {
      let transformedParameters = values?.parameters;

      if (values?.type === 'CurrencyField') {
        const { characterLimit, ...restOfParams } = values?.parameters;
        transformedParameters = {
          characterLimit,
          items: Object.entries(restOfParams).map(elm => elm[1]),
          // .filter(el => el.isEnabled), for now we are sending all not just enabled
        };
      }

      const { data } = await api.administration.supportingDocumentPackages.updateSupportingDocumentPackageItem({
        ...values,
        parameters: JSON.stringify(transformedParameters),
      });

      setDocumentId(data?.id);
    },
    [updateRowData],
  );

  const handleUpdate = useCallback(
    async values => {
      try {
        setisUpdating(true);

        if (type === 'Document') {
          await handleUpdatePackageDocument(values);
        } else {
          await handleUpdatePackageItem(values);
        }

        toastr.success('Successfully updated package item');
      } catch (err) {
        const {
          response: { data },
        } = err;

        console.error(err);
        toastr.error('Error occured while updating package item');

        return { [FORM_ERROR]: data?.errors || err?.message };
      } finally {
        setisUpdating(false);
      }
    },
    [type, handleUpdatePackageDocument, handleUpdatePackageItem, setisUpdating],
  );

  const handleSubmit = useCallback(
    async values => {
      if (documentId !== NEW_PACKAGE_ITEM_ID) {
        return handleUpdate({ ...values, id: documentId });
      } else {
        return handleCreate(values);
      }
    },
    [handleCreate, handleUpdate, documentId],
  );

  const handleRemove = useCallback(
    async (event, forceDelete) => {
      event && event.stopPropagation();

      if (documentId !== NEW_PACKAGE_ITEM_ID) {
        try {
          setisUpdating(true);

          await api.administration.supportingDocumentPackages.deleteSupportingDocumentPackageItem(
            documentId,
            forceDelete,
          );

          toastr.success('Successfully removed package item');
        } catch (err) {
          const {
            response: { data, status },
          } = err;

          if (status === 409) {
            setForceDelete(true);
          } else {
            console.error(err);
            toastr.error('Error occured while removing package item');
          }

          return { [FORM_ERROR]: data?.errors || err?.message };
        } finally {
          setisUpdating(false);

          await updateDocumentPackages();
        }
      }

      onRemove('index', index);
    },
    [documentId, onRemove, setisUpdating, documentId],
  );

  return { handleSubmit, handleRemove, forceDelete, setForceDelete };
}
