import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Field } from 'react-final-form';
/**
 * Modal used for creating new comments.
 * @param {func} hideModal - function for hiding the modal
 * @param {reference} containerRef - reference of the page which contains the modal
 * @param {boolean} isReply - is modal used to create reply
 * @param {func} addCommentLocally - adds comment to the comment section
 * @param {number} parentCommentId - id of the comment which is the owner of the reply, if there is one
 * @param {func} setNewReply - sets the new reply object in the parent page
 */
import toastr from 'toastr';
import { CommentsContext } from 'utilities/services/contexts';
import FormModal from '../FormModal';
import './styles.scss';

const NewCommentModal = ({
  hideModal,
  isReply,
  addCommentLocally,
  parentCommentId,
  setNewReply,
  additionalParams,
  setCommentsNumber,
}) => {
  const { apiCreateComment, apiCreateReply } = useContext(CommentsContext);
  const isValidComment = comment => {
    if (!comment.trim().length) {
      return false;
    }
    return true;
  };

  const handleSubmit = async ({ text }) => {
    if (!isValidComment(text)) {
      return;
    }

    const trimmedComment = text.trim();

    try {
      if (!isReply) {
        const { data: res } = await apiCreateComment({
          ...additionalParams,
          body: trimmedComment,
        });

        addCommentLocally({ ...res, systemGenerated: false });
        setCommentsNumber(prev => prev + 1);
        hideModal();

        toastr.success('Successfully added comment!');
      } else {
        const { data: response } = await apiCreateReply(parentCommentId, {
          body: trimmedComment,
        });

        setNewReply(response);
        hideModal();

        toastr.success('Successfully added reply!');
      }
    } catch (err) {
      if (err?.response) {
        const { status, data } = err.response;
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: data.error };
        }
        if (status === 500) {
          return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
        }
      }
    }
  };

  return (
    <FormModal
      hideModal={hideModal}
      title={isReply ? <h3>New Reply</h3> : <h3>New Comment</h3>}
      onSubmit={handleSubmit}
      className="ickyc-comment-modal"
    >
      {isReply ? <p>You can add your reply here:</p> : <p> You can write your comment here: </p>}
      <Field name="text" autoFocus component="textarea" rows={20} />
    </FormModal>
  );
};

NewCommentModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  isReply: PropTypes.bool,
  addCommentLocally: PropTypes.func,
  parentCommentId: PropTypes.number,
  setNewReply: PropTypes.func,
  additionalParams: PropTypes.shape({}).isRequired,
  setCommentsNumber: PropTypes.func,
};

NewCommentModal.defaultProps = {
  isReply: false,
  addCommentLocally: () => {},
  parentCommentId: null,
  setNewReply: () => {},
  setCommentsNumber: () => {},
};

export default NewCommentModal;
