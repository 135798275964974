import api from 'api';
import classNames from 'classnames';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Info from 'components/Info';
import Section from 'components/Section';
import TagsContainer from 'components/TagButtonContainer';
import Toggle from 'components/Toggle';
import Tooltip from 'components/Tooltip';
import useModalHandler from 'hooks/useModalHandler';
import bus from 'modules/bus';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ProtectedComponent from 'router/components/ProtectedComponent';
import toastr from 'toastr';
import utilities from 'utilities';
import enums from 'utilities/enums';
import authEnums from 'utilities/enums/authEnums';
import routesEnum from 'utilities/enums/routesEnum';
import setClassSuffix from 'utilities/services/ClassManager';
import DateManager from 'utilities/services/DateManager';
import IconManager from 'utilities/services/IconManager';
import { CaseContext, PermissionGroupContext } from 'utilities/services/contexts';
import AssignToUserDropdown from '../../../components/AssignToUserDropdown';
import AttachUsersToCaseModal from '../../../components/AttachUsersToCaseModal';
import CasesReportsModal from '../CasesReportsModal';
import './styles.scss';

const entityTypeMap = {
  0: 'Any',
  1: 'Person',
  2: 'Company',
  3: 'Organisation',
  4: 'Vessel',
  5: 'Aircraft',
};

const CaseInformation = ({ info: initialInfo, matchDetails, disabled, onCaseStatusChange }) => {
  const history = useHistory();
  const { isOpen, open: openModal, close: closeModal } = useModalHandler();
  const { complianceLogId } = useContext(CaseContext);
  const [changing, setChanging] = useState(false);
  const [caseInfo, setCaseInfo] = useState(initialInfo);

  const {
    searchTerm,
    assignedToId,
    onGoing,
    year,
    attachedTo,
    fuzziness,
    runBy,
    entityType,
    created,
    countries,
    caseStatus,
    searchResult,
  } = caseInfo;

  const [caseStatusState, setCaseStatusState] = useState(caseStatus);
  const [toggleState, setToggleValue] = useState(onGoing);

  const changeOngoingStatus = () => {
    setChanging(true);
    api.kyc.caseManagement
      .changeCaseOngoingStatus(complianceLogId, !toggleState)
      .then(() => {
        setToggleValue(prev => !prev);
        toastr.success(`Ongoing monitoring was ${!toggleState ? 'enabled' : 'disabled'} `);
      })
      .finally(() => setChanging(false));
  };

  const navigateToEntityProfile = ({ type, id }) => {
    history.push(utilities.routeParams(type ? routesEnum.kyc.LEGAL_ENTITY : routesEnum.kyc.NATURAL_PERSON, { id }));
  };

  const baseClass = 'ickyc-case-information';
  const setSuffix = setClassSuffix(baseClass);

  const classes = classNames({
    [baseClass]: true,
    [setSuffix('--updating')]: changing,
  });

  const detachUser = user => {
    setChanging(true);
    api.kyc.caseManagement
      .removeEntityFromCaseApi(complianceLogId, user.id)
      .then(({ data }) => {
        setCaseInfo(prev => ({
          ...prev,
          attachedTo: prev.attachedTo.filter(attach => attach.id !== user.id),
        }));
        bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, data);
      })
      .finally(() => setChanging(false));
  };

  const attachUsers = newUsers => {
    setCaseInfo(prev => ({ ...prev, attachedTo: newUsers }));
  };

  const handleCaseStatusChange = () => {
    setChanging(true);
    const newStatus = caseStatusState === 'Open' ? 'Closed' : 'Open';
    api.kyc.caseManagement
      .updateCaseStatus({ complianceLogId, status: newStatus })
      .then(() => {
        setCaseStatusState(newStatus);
        onCaseStatusChange(newStatus);
        toastr.success(`Case status was set to ${newStatus}`);
      })
      .finally(() => {
        setChanging(false);
      });
  };

  const { linkEntities: canLinkEntities } = useContext(PermissionGroupContext);
  const { isOpen: isOpenReportModal, open: openCasesReportModal, close: closeCasesReportModal } = useModalHandler();
  const requestPersonalReport = () => {
    openCasesReportModal();
  };

  return (
    <Section title="Details" className={classes} isUpdating={changing}>
      <Info label="Search Term">{searchTerm || ''}</Info>
      <Info label="Entity Type"> {entityTypeMap[entityType] || ''}</Info>
      {!matchDetails ? (
        <Info label="Case ID">{complianceLogId}</Info>
      ) : (
        <Info label="Search Result">{searchResult}</Info>
      )}
      <Info label="Created"> {DateManager.monDayYearLocal(created)}</Info>
      <Info label="Date of Birth / Incorporation"> {year}</Info>
      <Info label="Fuzziness"> {`${fuzziness} %`}</Info>
      <Info label="Run By"> {runBy}</Info>
      <Info label="Countries" withLoadMore>
        {countries ? countries.replaceAll('|', ' | ') : ''}
      </Info>
      <Info label="Assigned To">
        <AssignToUserDropdown
          preview={disabled}
          assignedUserId={assignedToId}
          assignCase
          complianceLogId={complianceLogId}
        />
      </Info>
      <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
        <Info label="Attached To">
          <TagsContainer
            asTagButton
            options={attachedTo}
            onRemove={detachUser}
            onAdd={openModal}
            onClick={navigateToEntityProfile}
            preview={!canLinkEntities || disabled}
          />
        </Info>
      </ProtectedComponent>
      <ProtectedComponent
        requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.ongoingMonitoring]}
        permissionGroup={authEnums.PERMISSION_GROUP.RISK_SCREENING}
      >
        <Tooltip
          placement="top"
          trigger={
            <div>
              <Info label="Ongoing Monitoring">
                <Toggle value={toggleState} onOff onChange={changeOngoingStatus} disabled={disabled || !toggleState} />
              </Info>
            </div>
          }
          content={<span>Once you disable ongoing monitoring, you can not re-enable it again.</span>}
        />
      </ProtectedComponent>
      <ProtectedComponent
        requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.status]}
        permissionGroup={authEnums.PERMISSION_GROUP.RISK_SCREENING}
      >
        <Info label="Case Status">
          <Toggle
            onChange={handleCaseStatusChange}
            value={caseStatusState === 'Open'}
            showCustomText
            toggleOnText="Open"
            toggleOffText="Closed"
            disabled={disabled}
          />
        </Info>
      </ProtectedComponent>
      <ProtectedComponent
        requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.legalEntityReports]}
        permissionGroup={authEnums.PERMISSION_GROUP.REPORTS}
      >
        <PrimaryButton variant="link" onClick={requestPersonalReport}>
          {IconManager.get(IconManager.names.DOWNLOAD)}&nbsp;&nbsp;Request Case Report
        </PrimaryButton>
        {isOpenReportModal && (
          <CasesReportsModal hideModal={closeCasesReportModal} casesIds={[complianceLogId]} searchTerm={searchTerm} />
        )}
      </ProtectedComponent>
      {isOpen && (
        <AttachUsersToCaseModal
          hideModal={closeModal}
          initialAttachments={attachedTo.map(({ id, name }) => ({ id, label: name, value: id }))}
          setNewlyAdded={attachUsers}
          complianceLogId={complianceLogId}
          disabled={disabled}
        />
      )}
    </Section>
  );
};

CaseInformation.propTypes = {
  info: PropTypes.shape({
    attachedTo: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
      }),
    ),
    dateOfBirth: PropTypes.string,
    searchTerm: PropTypes.string,
    fuzziness: PropTypes.number,
    countries: PropTypes.string,
    created: PropTypes.string,
    runBy: PropTypes.string,
    onGoing: PropTypes.bool,
    year: PropTypes.number,
    assignedTo: PropTypes.string,
    assignedToId: PropTypes.string,
    searchResult: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  matchDetails: PropTypes.bool,
  onCaseStatusChange: PropTypes.func,
};
CaseInformation.defaultProps = {
  info: {
    isUpdating: false,
    attachedTo: [],
    dateOfBirth: undefined,
    countries: '',
    onGoing: false,
    year: undefined,
  },
  matchDetails: false,
  disabled: false,
  onCaseStatusChange: () => {},
};

export default CaseInformation;
