import CustomQuestionIcon from 'assets/CustomQuestionIcon';
import { NEW_PACKAGE_ITEM_ID } from 'components/DocumentsAndCustomQuestions/utils';
import InputField from 'components/InputField';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import TextareaField from 'components/TextareaField';
import Tooltip from 'components/Tooltip';
import React, { useCallback } from 'react';
import { Field, useForm } from 'react-final-form';
import validators from 'utilities/services/validators';
import utils from '../../utils';

export default ({ setSuffix, id }) => {
  const { change } = useForm();

  const cleanParameters = useCallback(
    e => {
      change(`parameters`, {});
    },
    [change],
  );

  return (
    <>
      <td className={setSuffix('__type')}>
        <CustomQuestionIcon />
        <span>Custom Question</span>
      </td>
      <td className={setSuffix('__name')} onClick={utils.stopPropagation}>
        <Field topLabeled label="Question" name="name" component={InputField} validate={validators.required()} />
      </td>
      <td className={setSuffix('__question')} onClick={utils.stopPropagation}>
        <Field label="Description" labelPosition="top" name="description" component={TextareaField} />
      </td>
      <td className={setSuffix('__answer-format')} onClick={utils.stopPropagation}>
        {id !== NEW_PACKAGE_ITEM_ID ? (
          <Tooltip
            placement="top"
            content={<span>To change your Answer Format create a new question</span>}
            trigger={
              <div>
                <Field
                  disabled
                  label="Answer Format"
                  name="type"
                  component={SelectField}
                  options={utils.answerFormatOptions}
                  onChange={cleanParameters}
                  Trigger={<SelectTrigger />}
                  Content={<SelectFilterContent />}
                  labelPosition="top"
                  validate={validators.required()}
                />
              </div>
            }
          />
        ) : (
          <Field
            label="Answer Format"
            name="type"
            component={SelectField}
            options={utils.answerFormatOptions}
            onChange={cleanParameters}
            Trigger={<SelectTrigger />}
            Content={<SelectFilterContent />}
            labelPosition="top"
            validate={validators.required()}
          />
        )}
      </td>
    </>
  );
};
