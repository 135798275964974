import React, { useCallback } from 'react';
import { Field } from 'react-final-form';
import validators from 'utilities/services/validators';
import api from '../../../../../../../api';
import OutlineButton from '../../../../../../../components/Buttons/OutlineButton';
import DropzoneV2 from '../../../../../../../components/DropzoneV2';
import RadioButtonField from '../../../../../../../components/RadioButtonField';
import utilities from '../../../../../../../utilities';
import IconManager from '../../../../../../../utilities/services/IconManager';
import utils from './utils';

const CSVImportForm = ({ setShiftFocusToSubmit }) => {
  const handeDownload = useCallback(entityType => {
    document.activeElement.blur();
    return api.kyc.entityManagement.getCSVTemplate(entityType).then(({ data: response }) => {
      utilities.downloadFile(
        response,
        'application/csv',
        entityType === 0 ? 'natural-persons-template.csv' : 'legal-entities-template.csv',
      );
    });
  }, []);

  return (
    <div className="ickyc-csv-upload__instructions">
      <span>
        <b>Select whether your CSV contains</b> a list of <i>Natural Persons</i> or <i>Legal Entities</i>
      </span>
      <div className="ickyc-csv-upload-type">
        <div className="ickyc-csv-upload-type__wrapper ickyc-csv-upload-type__wrapper--left">
          <Field
            type="radio"
            component={RadioButtonField}
            label="Natural Persons"
            value="true"
            name="isNaturalPerson"
          />
        </div>
        <div className="ickyc-csv-upload-type__wrapper ickyc-csv-upload-type__wrapper--left">
          <Field
            type="radio"
            name="isNaturalPerson"
            component={RadioButtonField}
            label="Legal Entities"
            value="false"
            className="ickyc-csv-upload-type__right"
          />
        </div>

        <div className="ickyc-csv-upload-type__wrapper ickyc-csv-upload-type__wrapper--right">
          <OutlineButton onClick={() => handeDownload(0)}>
            <span>
              Download <br /> Natural Persons <br /> CSV Template
            </span>
            {IconManager.get(IconManager.names.DOWNLOAD)}
          </OutlineButton>
        </div>
        <div className="ickyc-csv-upload-type__wrapper ickyc-csv-upload-type__wrapper--right">
          <OutlineButton onClick={() => handeDownload(1)}>
            <span>
              Download <br /> Legal Entities <br /> CSV Template
            </span>
            {IconManager.get(IconManager.names.DOWNLOAD)}
          </OutlineButton>
        </div>
      </div>
      <Field
        component={DropzoneV2}
        accept=".csv"
        name="csvFile"
        type="file"
        validate={validators.composeValidators(validators.required(), utils.csvUploadValidation())}
        instructionsText={
          <span>
            <b>Upload the CSV Document</b> that your users will fill in with their information
          </span>
        }
        onChangeCallback={() => {
          setShiftFocusToSubmit(true);
        }}
        showRequiredErrorOnlyOnSubmit
      />
    </div>
  );
};

export default CSVImportForm;
