import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import routesEnum from '../../../../../../../utilities/enums/routesEnum';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../utilities/services/DateManager';
import IconManager from '../../../../../../../utilities/services/IconManager';

const CSVImportsRow = ({
  rowInfo: { dateTime, entityType, requestedBy, records, id, importedExported },
  className,
}) => {
  const history = useHistory();

  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const handleClick = useCallback(() => {
    history.push(`${routesEnum.kyc.ENTITY_MANAGEMENT}?csvcomplianceLogId=${id}`);
  }, [id, history]);

  return (
    <tr key={id} className={`${className}__row`}>
      <td className={setColumnSuffix('__dateTime')}>
        <span>{dateTime ? DateManager.monDayYearLocal(dateTime) : ''}</span>
      </td>

      <td className={setColumnSuffix('__entityType')}>
        <span>{entityType}</span>
      </td>
      <td className={setColumnSuffix('__requestedBy')}>
        <span>{requestedBy}</span>
      </td>
      <td className={setColumnSuffix('__records')}>
        <span>{records}</span>
      </td>
      <td className={setColumnSuffix('__importedExported')}>
        <span onClick={handleClick}>
          {importedExported}
          <span>{IconManager.get(IconManager.names.LINK)}</span>
        </span>
      </td>
      <td className={setColumnSuffix('__download')}>
        <span />
      </td>
    </tr>
  );
};

CSVImportsRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.string,
    dateTime: PropTypes.string,
    requestedBy: PropTypes.string,
    importedExported: PropTypes.number,
    entityType: PropTypes.string.isRequired,
    records: PropTypes.number,
  }),
  className: PropTypes.string,
};

CSVImportsRow.defaultProps = {
  className: '',
  rowInfo: {
    importedExported: 0,
    records: 0,
    dateTime: null,
  },
};
export default CSVImportsRow;
