import api from 'api';
import classNames from 'classnames';
import DocumentsAndCustomQuestions from 'components/DocumentsAndCustomQuestions';
import IComplyForm from 'components/Form/IComplyForm';
import NestedListHeader from 'components/Headings/NestedListHeading';
import ConfirmationPage from 'components/Modal/ConfirmationPage';
import FormModal from 'components/Modal/FormModal';
import InformationModal from 'components/Modal/InformationModal';
import Section from 'components/Section';
import Spinner from 'components/Spinner';
import { FORM_ERROR } from 'final-form';
import useModalHandler from 'hooks/useModalHandler';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { setDocumentPackages } from 'store/actions/global.action';
import toastr from 'toastr';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import IconManager from '../../../../../utilities/services/IconManager';
import SupportingDocumentTypeForm from '../SupportingDocumentTypeForm';

const SupportingPackageDetailsPage = ({ className }) => {
  const classes = classNames(className, 'ickyc-page', 'ickyc-supporting-documents-package-details');
  const formClasses = classNames('ickyc-confirmation-modal', 'ickyc-form-modal--delete-supporting-doc');

  const [isGlobalErrorModalOpen, setIsGlobalErrorModalOpen] = useState(false);
  const [isLoadingDescription, setIsLoadingDescription] = useState(true);
  const [forceDelete, setForceDelete] = useState(false);
  const [initialValues, setInitialValues] = useState();

  const history = useHistory();
  const { id } = useParams();
  const { isOpen: isConfirmModalOpen, close: closeConfirmModal, open: openConfirmModal } = useModalHandler();

  const headingListItems = useMemo(() => {
    return [<b>{`${initialValues?.header || `Supporting Package Name`}`}</b>];
  }, [initialValues]);

  const goHistoryBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const dispatch = useDispatch();

  const updateDocumentPackages = useCallback(async () => {
    const { data } = await api.kyc.entityManagement.getSupportingPackages();

    dispatch(setDocumentPackages(data));
  }, []);

  const handleSuppPackageDeletion = useCallback(async () => {
    try {
      await api.administration.deleteSupportingDocumentPackage(id, forceDelete);
      toastr.success('Supporting Document type was deleted');

      await updateDocumentPackages();

      goHistoryBack();
      closeConfirmModal();
    } catch (err) {
      const { status: resStatus, data } = err.response;
      if (resStatus >= 400 && resStatus < 500) {
        if (resStatus === 409) {
          setForceDelete(true);
        } else {
          setForceDelete(false);
        }
        return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
      }
      return { [FORM_ERROR]: 'Error Occured While Trying to Delete Supporting Document Type' };
    }
  }, [closeConfirmModal, forceDelete, id]);

  const onSubmitDescription = useCallback(async vals => {
    try {
      await api.administration.updateSupportingDocumentPackage({
        ...vals,
        entityType: Number(vals?.entityType),
      });
      toastr.success('Successfully updated document description!');

      await updateDocumentPackages();
    } catch (err) {
      if (err?.response) {
        const { status, data: errorData } = err.response;
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: errorData.message };
        }
        if (status === 500) {
          return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
        }
      } else return { [FORM_ERROR]: 'Error occured while updating supporting document' };
    }
  }, []);

  useEffect(() => {
    const fetchDescription = async () => {
      try {
        // todo: check if those entties already exist and if not set global error setIsGlobalErrorModalOpen(true)
        setIsLoadingDescription(true);
        const response = await api.administration.getSupportingDocumentsPackage(id);
        setInitialValues({ ...response?.data, entityType: response?.data?.entityType.toString() });
      } catch (err) {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.message || errorData.errors };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      } finally {
        setIsLoadingDescription(false);
      }
    };

    fetchDescription();
  }, []);

  return (
    <div className={classes}>
      {isGlobalErrorModalOpen ? (
        <InformationModal hideModal={() => setIsGlobalErrorModalOpen(false)} onClick={goHistoryBack}>
          <ConfirmationPage
            title="Oops!"
            subtitle="Something went wrong…"
            text={
              <>
                We’re working on it, and we’ll get it fixed as soon as we can.
                <br /> You may refresh the page, or try again later.
              </>
            }
            icons={<div>{IconManager.get(IconManager.names.GLOBAL_ERROR)}</div>}
          />
        </InformationModal>
      ) : (
        <div>
          <NestedListHeader
            title="Supporting Package Details"
            onClick={goHistoryBack}
            items={headingListItems}
            right={<DeleteButton onClick={openConfirmModal} tooltip="Delete Supporting Document Package" />}
          />
          {isConfirmModalOpen && (
            <FormModal
              className={formClasses}
              hideModal={closeConfirmModal}
              title="Delete Supporting Documents Package"
              onSubmit={handleSuppPackageDeletion}
              showConfirmationForm={forceDelete}
              onRejectClick={() => {
                setForceDelete(false);
                closeConfirmModal();
              }}
              showErrorsInConfirmation
              confirmButtonText="Yes"
              confirmationText="Would you like to proceed?"
              rejectButtonText="No"
            >
              <span className="ickyc-confirmation-message">
                Are you sure you want to delete the&nbsp;
                <b>{initialValues?.header}</b>&nbsp;supporting package?
              </span>
            </FormModal>
          )}
          <Section divider>
            {isLoadingDescription ? (
              <Spinner />
            ) : (
              <IComplyForm onSubmit={onSubmitDescription} showControls initialValues={initialValues}>
                <SupportingDocumentTypeForm entityTypePreview={!!id} />
              </IComplyForm>
            )}
          </Section>
          <Section>
            <div onSubmit={onSubmitDescription} showControls initialValues={initialValues}>
              <DocumentsAndCustomQuestions packageId={id} />
            </div>
          </Section>
        </div>
      )}
    </div>
  );
};
SupportingPackageDetailsPage.propTypes = {
  className: PropTypes.string,
};

SupportingPackageDetailsPage.defaultProps = {
  className: undefined,
};

export default SupportingPackageDetailsPage;
