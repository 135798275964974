/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import CountrySelectFilterContentToggleList from '../../../../../../../../../components/CountrySelect/components/CountrySelectFilterContentToggleList';
import CountrySelectField from '../../../../../../../../../components/CountrySelectField';
import SelectFilterContentToggleList from '../../../../../../../../../components/Select/components/SelectFilterContentToggleList';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import FilterItem from '../../../../../../../components/FilterItem';
import { CASE_ENTITY_TYPES, HIT_MATCH_TYPES } from '../../../../../../enums';

const MatchesFilterForm = () => {
  const fieldNames = {
    RELEVANCE_FLAG: 'relevanceFlag',
    RELEVANCE: 'relevanceTerms',
    COUNTIES_FLAG: 'countriesFlag',
    COUNTRIES: 'countries',
    TYPES_FLAG: 'entityTypesFlag',
    TYPES: 'entityTypes',
  };

  const matchOptions = Object.values(HIT_MATCH_TYPES).map(el => ({
    id: el,
    label: el,
    value: el,
  }));

  const typeOptions = useMemo(
    () => CASE_ENTITY_TYPES.map((el, index) => ({ id: index, label: el, value: index + 1 })),
    [],
  );

  return (
    <div className="ickyc-filter-form">
      <Field component={FilterItem} label="Entity Type" name={fieldNames.TYPES_FLAG}>
        <Field
          component={SelectField}
          Content={<SelectFilterContentToggleList />}
          Trigger={<SelectTrigger />}
          options={typeOptions}
          name={fieldNames.TYPES}
          multipleSelection
        />
      </Field>
      <Field component={FilterItem} label="Relevance" name={fieldNames.RELEVANCE_FLAG}>
        <Field
          component={SelectField}
          Content={<SelectFilterContentToggleList />}
          Trigger={<SelectTrigger />}
          options={matchOptions}
          name={fieldNames.RELEVANCE}
          multipleSelection
        />
      </Field>
      <Field component={FilterItem} label="Country" name={fieldNames.COUNTIES_FLAG}>
        <Field
          component={CountrySelectField}
          placeholder="- Choose Country -"
          withFilter
          multipleSelection
          name={fieldNames.COUNTRIES}
          contentComponent={CountrySelectFilterContentToggleList}
        />
      </Field>
    </div>
  );
};

export default MatchesFilterForm;
