import api from 'api';
import FormModal from 'components/Modal/FormModal';
import { FORM_ERROR } from 'final-form';
import history from 'modules/history';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import ReportsModalFields from './components/ReportsModalFields';
import './styles.scss';
import utils from './utils';

const { initialValues } = utils;

const ReportsModal = ({ hideModal, entitiesIds }) => {
  const [submitedSuccessfully, setSubmitedSuccessfully] = useState(false);
  const [reportsIds, setReportsIds] = useState([]);

  const handleSubmit = async configData => {
    if (configData.includeImages === false) {
      configData.includeIdDocsImages = false;
      configData.includeFaceMatchImages = false;
      configData.includeSupportingDocs = false;
    }

    if (configData.includeAmlCases === false) {
      configData.cases = [];
      configData.includeOnlyAcceptedMatches = false;
    }

    configData.type = 'EntityReportConfig';

    try {
      if (entitiesIds?.length === 1) {
        await api.kyc.entityManagement.createPersonalReportConfig(entitiesIds[0], configData);
      } else {
        const {
          data: { ids },
        } = await api.kyc.entityManagement.batchReport({ entitiesIds, ...configData });
        setReportsIds(ids);
      }
      setSubmitedSuccessfully(true);
    } catch (err) {
      if (err?.response) {
        const { status, data } = err.response;
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: data.message };
        }
        if (status === 500) {
          return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
        }
      } else return { [FORM_ERROR]: 'Error occured' };
    }
  };

  const navigateToReports = useCallback(() => {
    history.push(`/administration/files-management/reports`, { ids: reportsIds });
  }, [reportsIds]);

  return (
    <FormModal
      onSubmit={handleSubmit}
      hideModal={hideModal}
      initialValues={initialValues}
      title="Request Report"
      className="ickyc-reports-modal"
      submitButtonText="Request Report"
      showErrorsInConfirmation
      onRejectClick={hideModal}
      onConfirmClick={navigateToReports}
      showConfirmationForm={submitedSuccessfully}
      confirmButtonText="Go to Reports"
      confirmationText="To download requested report please go to Reports in Administration."
      rejectButtonText="Cancel"
    >
      <ReportsModalFields entitiesIds={entitiesIds} />
    </FormModal>
  );
};
ReportsModal.propTypes = {
  hideModal: PropTypes.func,
  entityId: PropTypes.string,
};

ReportsModal.defaultProps = {
  hideModal: null,
  entityId: '',
};

export default ReportsModal;
