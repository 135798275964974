import ModalTabHeader from 'components/Headers/ModalTabHeader';
import Table from 'components/Tables/Table';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import useSelect from 'hooks/useSelect';
import React, { useCallback, useMemo, useState } from 'react';
import { selectUserAccessByLicense } from 'store/selectors/auth.selector';
import TableHeaders from 'utilities/services/TableHeaders';
import CasesAssignedToMeRow from './components/CasesAssignedToMeRow';
import EntitiesAssignedToMeRow from './components/EntitiesAssignedToMeRow';
import useMyWorkParams from './hooks/useMyWorkParams';
import './styles.scss';

const AssignedToMe = () => {
  const [activeTab, setActiveTab] = useState(0);
  const platformAccessByLicence = useSelect(selectUserAccessByLicense);
  const showEntities = useMemo(() => platformAccessByLicence.includes('Entities'), [platformAccessByLicence]);
  const showCases = useMemo(() => platformAccessByLicence.includes('Cases'), [platformAccessByLicence]);

  const assignedToMeTabs = useMemo(() => {
    let tabs = [];
    if (showEntities) {
      tabs.push({
        label: 'Entities',
        key: 'entities',
        value: 'entities',
      });
    }
    if (showCases) {
      tabs.push({
        label: 'Cases',
        key: 'cases',
        value: 'cases',
      });
    }
    return tabs;
  }, [showEntities, showCases]);

  const isEntitiesTabActive = useMemo(() => activeTab === 0 && showEntities, [activeTab, showEntities]);

  const {
    entityPagination,
    entities,
    entityParams,
    isEntityFetching,
    changeEntityParams,
    casePagination,
    caseParams,
    cases,
    isCaseFetching,
    changeCaseParams,
  } = useMyWorkParams();

  const optionClick = useCallback(value => {
    setActiveTab(value);
  }, []);

  return (
    <div className="ickyc-assigned-to-me">
      <ModalTabHeader className="ickyc-assigned-to-me__tabs" onClick={optionClick} options={assignedToMeTabs} />

      <Table
        withPagination
        withLimitChange
        className="ickyc-assigned-to-me__table"
        values={isEntitiesTabActive ? entities : cases}
        pagination={isEntitiesTabActive ? entityPagination : casePagination}
        tableRow={isEntitiesTabActive ? EntitiesAssignedToMeRow : CasesAssignedToMeRow}
        headerRow={TableHeader}
        updating={isEntitiesTabActive ? isEntityFetching : isCaseFetching}
        handleParamsChange={isEntitiesTabActive ? changeEntityParams : changeCaseParams}
        headerData={{
          sortCriteria: isEntitiesTabActive ? entityParams.sortCriteria : caseParams.sortCriteria,
          sortOrder: isEntitiesTabActive ? entityParams.sortOrder : caseParams.sortOrder,
          items: isEntitiesTabActive
            ? TableHeaders.EntitiesAssignedToMeTableHeader
            : TableHeaders.CasesAssignedToMeTableHeader,
        }}
      />
    </div>
  );
};

export default AssignedToMe;
