import api from 'api';
import React, { useEffect, useState } from 'react';

import Section from 'components/Section';
import Spinner from 'components/Spinner';
import Table from 'components/Tables/Table';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import TableHeaders from 'utilities/services/TableHeaders';
import PlatformUsageRow from './components/PlatformUsageRow';
import UsageRow from './components/UsageRow';
import './style.scss';

const UsagePage = () => {
  const [usageServices, setUsageServices] = useState([]);
  const [platformUsages, setPlatformUsages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const {
          data: { serviceUsages, platformUsages },
        } = await api.administration.account.usage();
        setUsageServices(serviceUsages);
        setPlatformUsages(platformUsages);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div className="ickyc-usage-page">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Section title="Services" divider>
            <table className="ickyc-usage-page__services-table">
              {usageServices?.length ? (
                <>
                  <thead>
                    <tr>
                      <th>Service Name</th>
                      <th>Credits Used</th>
                      <th>Limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usageServices?.map(service => (
                      <UsageRow key={service.name} {...service} />
                    ))}
                  </tbody>
                </>
              ) : (
                <p className="ickyc-usage-page__no-services-message">
                  No services are in use right now, so we don't have any data to show you
                </p>
              )}
            </table>
          </Section>
          <Section title="Platform">
            <div className="ickyc-usage-page__platform-table">
              <Table
                values={platformUsages}
                tableRow={PlatformUsageRow}
                headerRow={TableHeader}
                headerData={{
                  items: TableHeaders.PlatformUsage,
                }}
                updating={isLoading}
              />
            </div>
          </Section>
        </>
      )}
    </div>
  );
};
export default UsagePage;
