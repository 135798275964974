import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

/**
 * Button component containing only icon inside, with, or without border.
 * @param {boolean} [withBorder = false] - style with border
 * @param {boolean} [transparent = false] - transparent button
 * @param {string} [type = string] - button type
 * @param {function} onClick - onClick handle
 * @param {boolean} [disabled=false] - disability flag
 * @param {*} children - content placed inside button
 */
const IconButton = ({
  withBorder = false,
  transparent = false,
  active = false,
  type = 'button',
  onClick,
  disabled = false,
  children,
  className,
}) => {
  const classes = classNames(
    'ickyc-icon-button',
    className,
    { 'ickyc-icon-button--with-border': withBorder },
    { 'ickyc-icon-button--transparent': transparent },
    { 'ickyc-icon-button--active': active },
  );

  return (
    <button onClick={onClick} disabled={disabled} className={classes} type={type} tabIndex={0}>
      {children}
    </button>
  );
};

IconButton.propTypes = {
  withBorder: PropTypes.bool,
  transparent: PropTypes.bool,
  active: PropTypes.bool,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  withBorder: false,
  transparent: false,
  active: false,
  type: 'button',
  onClick: () => {},
  disabled: false,
  className: undefined,
};

export default IconButton;
