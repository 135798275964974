import api from 'api';
import Badge from 'components/Badge';
import CloseButton from 'components/Buttons/CloseButton';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Spinner from 'components/Spinner';
import LoadMore from 'components/Tables/Table/components/LoadMore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { setNotifications } from 'store/actions/global.action';
import enums from 'utilities/enums';
import Modal from '..';
import useSelect from '../../../hooks/useSelect';
import { selectNotifications } from '../../../store/selectors/global.selector';
import Notification from './components/Notification';
import './styles.scss';

const NotificationsModal = (modalRef, closeNotifications, areNotificationsOpen) => {
  const [markAllAsReadLoading, setMarkAllAsReadLoading] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const notificationsRef = useRef();
  const { data: notifications, unreadNotificationsCount, paginationData } = useSelect(selectNotifications) || [];
  const { limit, currentPage, ...pagination } = paginationData || {};
  const dispatch = useDispatch();

  const onLoadMore = useCallback(
    async e => {
      e.stopPropagation();
      try {
        setLoadMoreLoading(true);
        const { data } = await api.notifications.fetchNotifications({
          ...pagination,
          limit: limit,
          skip: limit * currentPage,
          sortOrder: enums.SORTING.DESCEDING,
          sortCriteria: 'dateCreated',
        });
        dispatch(
          setNotifications({
            data: [...notifications, ...data.data],
            paginationData: { ...data.paginationData, realTimeAddition: paginationData.realTimeAddition || 0 },
            unreadNotificationsCount: data.unreadNotificationsCount,
          }),
        );
      } catch (err) {
        console.error(err);
      } finally {
        setLoadMoreLoading(false);
      }
    },
    [pagination, limit, currentPage, unreadNotificationsCount, notifications, dispatch],
  );

  const markAllAsRead = useCallback(async e => {
    try {
      e.stopPropagation();
      setMarkAllAsReadLoading(true);

      await api.notifications.markAllAsRead();

      const { data } = await api.notifications.fetchNotifications({
        limit: 10,
        sortOrder: enums.SORTING.DESCEDING,
        sortCriteria: 'dateCreated',
      });
      dispatch(setNotifications({ ...data }));
    } catch (err) {
      console.error(err);
    } finally {
      setMarkAllAsReadLoading(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target) &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        closeNotifications();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef, closeNotifications]);

  return (
    <>
      {areNotificationsOpen && (
        <Modal
          open={areNotificationsOpen}
          className="ickyc-notifications-modal"
          relativeToParent={modalRef.current}
          renderAsPortal={false}
          hideModal={() => {
            closeNotifications();
          }}
          onClick={e => e.stopPropagation()}
        >
          <div className="notifications" ref={notificationsRef}>
            <div className="notifications__header">
              <title className="notifications__title">
                <h2>Notifications</h2>
                {!!unreadNotificationsCount && <Badge content={unreadNotificationsCount} red />}
              </title>
              <div className="notifications__header-actions">
                <PrimaryButton
                  className="notifications__clear-all-button"
                  type="button"
                  variant="link"
                  disabled={markAllAsReadLoading || !unreadNotificationsCount}
                  onClick={markAllAsRead}
                >
                  Clear All Notifications
                </PrimaryButton>
                <CloseButton
                  className="notifications__exit-button"
                  transparent
                  onClick={() => {
                    closeNotifications();
                  }}
                />
              </div>
            </div>

            <div className="notifications__body">
              {markAllAsReadLoading ? (
                <Spinner />
              ) : (
                <>
                  {!notifications?.length && (
                    <p className="notifications__no-notifications">There are no notifications to display</p>
                  )}
                  {notifications?.map(notification => (
                    <Notification {...notification} key={notification.id} />
                  ))}
                  <LoadMore
                    onLoadMore={onLoadMore}
                    limit={limit}
                    currentPage={currentPage}
                    {...pagination}
                    loading={loadMoreLoading}
                  />
                </>
              )}
            </div>

            <div className="notifications__footer">
              <NavLink to="/notifications">See All Notifications</NavLink>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default NotificationsModal;
