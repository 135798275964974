import { CircularProgress } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import { diff } from 'deep-object-diff';
import React, { useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import './styles.scss';

const SavePortalSettingsButton = () => {
  const { submitting, values } = useFormState();
  const [vals, setVals] = useState(values);
  const [manualPristine, setManualPristine] = useState(true);

  useMemo(() => {
    setVals(vals);
    if (Object.entries(diff(vals, values)).length !== 0) setManualPristine(false);
  }, [values, vals]);

  return (
    <div className="ickyc-save-portal-button">
      {!manualPristine && <span className="ickyc-save-portal-button__changes-label">(You have unsaved changes!)</span>}
      <PrimaryButton type="submit" disabled={submitting} onClick={() => setManualPristine(true)}>
        <Save /> &nbsp; Save {submitting && <CircularProgress />}
      </PrimaryButton>
    </div>
  );
};
export default SavePortalSettingsButton;
