import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React from 'react';
import toastr from 'toastr';
import api from '../../../../../../api';
import FormModal from '../../../../../../components/Modal/FormModal';
import './styles.scss';

const ResetAuthenticatorKeyModal = ({ hideModal, callback }) => {
  const handleSubmit = () => {
    return api.auth
      .resetAuthenticator()
      .then(() => {
        toastr.success('Recovery codes successfully reseted!');
        hideModal();
        if (callback) {
          callback(false);
        }
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.message || `${errorData.message?.slice(0, 80)}...` };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to reset codes' };
      });
  };
  return (
    <FormModal
      onSubmit={handleSubmit}
      //   initialValues={initialValues}
      hideModal={hideModal}
      title="Reset Authenticator Key"
      className="ickyc-reset-authenticator-key-modal"
      submitButtonText="Reset"
      showErrorsInConfirmation
    >
      <h3>If you reset your authenticator key your authenticator app will not work until you reconfigure it.</h3>
      <span>
        This process disables 2FA until you verify your authenticator app and will also reset your 2FA recovery codes.
        If you do not complete your authenticator app configuration you may lose access to your account.
      </span>
    </FormModal>
  );
};
ResetAuthenticatorKeyModal.propTypes = {
  hideModal: PropTypes.func,
  callback: PropTypes.func,
};
ResetAuthenticatorKeyModal.defaultProps = {
  hideModal: null,
  callback: () => {},
};
export default ResetAuthenticatorKeyModal;
