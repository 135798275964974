import FormModal from 'components/Modal/FormModal';
import React from 'react';
const ConfirmManualRiskLevelModal = ({ closeModal, handleSubmit, riskLevelCalculationMethod }) => {
  return (
    <FormModal
      hideModal={closeModal}
      title="Override Risk Level"
      className="ickyc-confirmation-modal"
      onSubmit={handleSubmit}
      submitButtonText="Confirm"
    >
      <span className="ickyc-confirmation-message">
        {riskLevelCalculationMethod === 'Automatic'
          ? 'You are about to override the risk level that was assigned by your configured automation rule. Would you like to proceed?'
          : 'You are about to override the risk level. Would you like to proceed?'}
      </span>
    </FormModal>
  );
};
export default ConfirmManualRiskLevelModal;
