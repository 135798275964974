import * as faceapi from 'face-api.js';
import { useEffect, useState } from 'react';

export const NETS = {
  MTCNN: 'mtcnn',
  SSD_MOBILENETV1: 'ssd_mobilenetv1',
  TINY_FACE_DETECTOR: 'tiny_face_detector',
};

const detectorOptionsParams = {
  inputSize: 512,
  minFaceSize: 20,
  scaleFactor: 0.709,
  scoreThreshold: 0.5,
  defaultMinimumExpressionConfidence: 0.5,
};

const detectorOptions = faceDetector => {
  if (faceDetector === NETS.SSD_MOBILENETV1) {
    return new faceapi.SsdMobilenetv1Options({
      minConfidence: detectorOptionsParams.defaultMinimumExpressionConfidence,
    });
  }
  if (faceDetector === NETS.TINY_FACE_DETECTOR) {
    return new faceapi.TinyFaceDetectorOptions({
      inputSize: detectorOptionsParams.inputSize,
      scoreThreshold: detectorOptionsParams.scoreThreshold,
    });
  }
  return new faceapi.MtcnnOptions({
    minFaceSize: detectorOptionsParams.minFaceSize,
  });
};

const getFaceAPIModelURL = () =>
  `${
    process.env.NODE_ENV === 'development' ? window.location.origin : process.env.REACT_APP_HOST_URL
  }/assets/face-api-models/`;

const detectFaceWithExpressions = async (input, options) =>
  faceapi
    .detectSingleFace(input, options)
    .withFaceLandmarks()
    .withFaceDescriptor()
    .withFaceExpressions();

const computeConfidenceLevel = (videoOverlayElement, videoElement, result, expressionName) => {
  const dimensions = faceapi.matchDimensions(videoOverlayElement, videoElement, true);
  const resizedResult = faceapi.resizeResults(result, dimensions);
  return resizedResult?.expressions[expressionName] || 0;
};

const detectFace = async (input, faceDetector = NETS.SSD_MOBILENETV1) =>
  faceapi.detectSingleFace(input, detectorOptions(faceDetector));

const detectExpression = async (emotion, inputElement, overlayElement) => {
  const result = await detectFaceWithExpressions(inputElement, detectorOptions(NETS.TINY_FACE_DETECTOR));

  if (result) {
    const confidenceLevel = computeConfidenceLevel(overlayElement, inputElement, result, emotion);

    return {
      emotion,
      confidenceLevel,
      descriptor: result.descriptor,
      image: faceapi.createCanvasFromMedia(inputElement).toDataURL(),
    };
  }

  return result;
};

export default function useFaceApi() {
  const [faceApiModelsState, setLoadFaceApiModelsState] = useState({ status: 'idle', errorMsg: '' });

  useEffect(() => {
    const loadFaceApiModels = async () => {
      setLoadFaceApiModelsState(prev => ({ ...prev, status: 'pending' }));
      const faceAPIModelURL = getFaceAPIModelURL();

      try {
        await faceapi.nets.ssdMobilenetv1.load(faceAPIModelURL);
        await faceapi.nets.tinyFaceDetector.load(faceAPIModelURL);
        await faceapi.loadFaceLandmarkModel(faceAPIModelURL);
        await faceapi.loadFaceRecognitionModel(faceAPIModelURL);
        await faceapi.loadFaceExpressionModel(faceAPIModelURL);
        setLoadFaceApiModelsState(prev => ({ ...prev, status: 'succeeded' }));
      } catch (err) {
        setLoadFaceApiModelsState(prev => ({
          ...prev,
          errorMsg: 'Loading models failed',
          status: 'failed',
        }));
        console.error({ err });
      }
    };

    if (faceApiModelsState.status === 'idle') {
      loadFaceApiModels();
    }
  }, [faceApiModelsState]);

  return {
    ...faceApiModelsState,
    detectFace,
    detectExpression,
  };
}
