const extractTabableElements = modalRef => {
  const focusableModalElements = modalRef.current.querySelectorAll(
    'a[href], button:not([disabled]):not([tabIndex="-1"]), textarea, input:not([hidden],[disabled],[tabIndex="-1"]):not([type="radio"]), input[type="radio"]:checked,span.ickyc-toggle__circle, select',
  );
  const firstElement = focusableModalElements[1];
  const lastElement = focusableModalElements[focusableModalElements.length - 1];
  const cancelElement = focusableModalElements[focusableModalElements.length - 2];
  const beforeCancelElement = focusableModalElements[focusableModalElements.length - 3];
  return { firstElement, lastElement, cancelElement, beforeCancelElement };
};

export default {
  extractTabableElements,
};
