import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="20px"
    height="20px"
    aria-labelledby="title"
    aria-describedby="desc"
    role="img"
  >
    <path
      data-name="layer2"
      fill="none"
      stroke="#4972d2"
      strokeMiterlimit="10"
      strokeWidth="5"
      d="M16 48H2V2h46v14"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
    <path
      data-name="layer1"
      fill="none"
      stroke="#4972d2"
      strokeMiterlimit="10"
      strokeWidth="5"
      d="M16 16h46v46H16z"
      strokeLinejoin="round"
      strokeLinecap="round"
    />
  </svg>
);
