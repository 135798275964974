import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import toastr from 'toastr';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import FormModal from '../../../../../components/Modal/FormModal';
import { BatchContext } from '../../../../../utilities/services/contexts';

const BatchDeleteButton = ({ entityId, onRemove, apiRequest, cases }) => {
  const { listOfSelectedIDs } = useContext(BatchContext) || [];
  const [confirmationModal, setConfirmModalOpen] = useState(false);

  const [isChanging, setIsChanging] = useState(false);

  const entityIds = useMemo(() => {
    if (!listOfSelectedIDs.length) {
      return [entityId];
    }
    if (!listOfSelectedIDs.some(eId => eId === entityId)) {
      return [...listOfSelectedIDs, entityId];
    }
    return listOfSelectedIDs;
  }, [listOfSelectedIDs, entityId]);

  const onDeleteConfirmation = useCallback(() => {
    setIsChanging(true);
    const submitObj = cases ? { complianceLogsIds: entityIds } : { entityIds };
    return apiRequest(submitObj)
      .then(() => {
        setConfirmModalOpen(false);
        onRemove(entityIds);
        toastr.success(`Entity(s) successfully deleted`);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: data.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => {
        setIsChanging(false);
      });
  }, [entityId, onRemove, entityIds, apiRequest, cases]);

  const onRemoveIconClick = useCallback(e => {
    e.preventDefault();
    setConfirmModalOpen(true);
  }, []);
  return (
    <>
      <DeleteButton onClick={onRemoveIconClick} disabled={isChanging} />
      <div>
        {confirmationModal && (
          <FormModal
            hideModal={() => setConfirmModalOpen(false)}
            title={`Remove ${cases ? 'Case' : 'Entity'}`}
            className="ickyc-confirmation-modal"
            onSubmit={onDeleteConfirmation}
          >
            <span className="ickyc-confirmation-message">
              {`Are you sure you want to delete selected ${cases ? 'cases' : 'entities'}?`}
            </span>
          </FormModal>
        )}
      </div>
    </>
  );
};
BatchDeleteButton.propTypes = {
  entityId: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  apiRequest: PropTypes.func.isRequired,
  cases: PropTypes.bool,
};
BatchDeleteButton.defaultProps = {
  onRemove: () => {},
  cases: false,
};

export default BatchDeleteButton;
