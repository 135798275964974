import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import toastr from 'toastr';
import utilities from 'utilities';
import api from '../../../../../api';
import IComplyForm from '../../../../../components/Form/IComplyForm';
import FieldArrayTable from '../../../../../components/Tables/FieldArrayTable';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import ToggleField from '../../../../../components/ToggleField';
import RiskLevelAssignmentRow from '../RiskLevelAssignmentRow';
import './styles.scss';

const ToggleBtn = () => {
  const {
    values: { enabled },
  } = useFormState();

  return (
    <span className="toggle-btn-wrapper">
      <span className={classNames('toggle-btn-wrapper__label', { 'toggle-btn-wrapper__label--enabled': enabled })}>
        {enabled ? 'Enabled' : 'Disabled'}
      </span>
      <Field component={ToggleField} name="enabled" />
    </span>
  );
};

const RiskLevelSection = () => {
  const [initialValues, setInitialValues] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = useCallback(async vals => {
    setIsLoading(true);

    const submitObj = {
      enabled: vals.enabled,
      riskLevelMaximumScores: vals.riskLevels
        .slice(0, -1)
        .map(({ maximumScore, riskLevel }) => ({ maximumScore: +maximumScore, riskLevel })),
    };

    api.administration.riskScoring
      .updateScoringRiskLevels(submitObj)
      .then(() => {
        toastr.success('Succesfully updated Scoring Rules');
        setInitialValues(vals);
      })
      .catch(err => {
        toastr.error('Error occurred while updating Scoring Rules');
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errData.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const fetchScoringRiskLevels = async () => {
      try {
        const { data } = await api.administration.riskScoring.getScoringRiskLevels();
        setInitialValues(data);
      } catch (e) {
        utilities.errorHandler(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchScoringRiskLevels();
  }, []);

  return (
    <IComplyForm mutators={{ ...arrayMutators }} onSubmit={handleSubmit} initialValues={initialValues} showControls>
      <div className="ickyc-risk-level-table">
        <div className="ickyc-section__subheader">
          <h2>Risk Level Assignment</h2>
          <ToggleBtn />
        </div>
        <FieldArrayTable
          updating={isLoading}
          fieldName="riskLevels"
          tableRow={RiskLevelAssignmentRow}
          headerRow={TableHeader}
          headerData={{
            items: [
              { type: 'label', label: 'Auto Assign to Risk Level' },
              { type: 'level-min', label: 'Minimum' },
              { type: 'level-max', label: 'Maximum' },
            ],
          }}
          noResultMessage="No Data To Show"
        />
      </div>
    </IComplyForm>
  );
};

export default RiskLevelSection;
