import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import BareHeading from '../../components/Headings/BareHeading';
import useSelect from '../../hooks/useSelect';
import { selectPersonalInfo } from '../../store/selectors/auth.selector';
import ChangePasswordForm from './components/ChangePasswordForm';
import PersonalDetailsForm from './components/PersonalDetailsForm';
import TwoFactorAuthentication from './components/TwoFactorAuthentication';

import './styles.scss';

const PersonalSettings = ({ className }) => {
  const classBase = 'ickyc-case-personal-settings';
  const classes = classNames('ickyc-kyc-page', classBase, className);
  const { firstName, lastName, email, pendingEmail, emailConfirmed, requireTwoFactorAuthentication } =
    useSelect(selectPersonalInfo) || {};

  return (
    <div className={classes}>
      <BareHeading title={`Personal Settings: ${firstName} ${lastName}`} />
      <PersonalDetailsForm
        initialValues={{
          firstName,
          lastName,
          email: emailConfirmed ? email : pendingEmail,
          emailConfirmed,
        }}
      />
      <ChangePasswordForm />
      <TwoFactorAuthentication requireTwoFactorAuthentication={requireTwoFactorAuthentication} />
    </div>
  );
};

PersonalSettings.propTypes = {
  className: PropTypes.string,
};

PersonalSettings.defaultProps = {
  className: undefined,
};

export default PersonalSettings;
