import { useEffect, useState } from 'react';

export default function useFilter(options) {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (options) {
      setSuggestions(options);
    }
  }, [options]);

  useEffect(() => {
    if (searchTerm !== '') {
      setSuggestions(options.filter(opt => opt?.label?.toLowerCase().includes(searchTerm.toLowerCase())));
    } else {
      setSuggestions(options);
    }
  }, [searchTerm, options]);

  return {
    inputProps: {
      value: searchTerm,
      onChange: ev => setSearchTerm(ev.target.value),
    },
    setSearchTerm,
    suggestions,
    setSuggestions,
  };
}
