import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import IconManager from '../../../../../utilities/services/IconManager';
import { CASE_MATCH_STATUS } from '../../enums';
import CustomControllWithTooltip from './components/CustomControllWithTooltip';
import './styles.scss';

const HitStatusRowControls = ({ onClick, isControlActive }) => {
  const classes = classNames('ickyc-hit-status-row-controls', {
    'ickyc-hit-status-row-controls--inactive': !isControlActive,
  });

  const clickHandler = useCallback(
    status => {
      if (isControlActive) onClick(status);
    },
    [isControlActive, onClick],
  );

  return (
    <div className={classes}>
      <CustomControllWithTooltip
        isControlActive={isControlActive}
        eventHandler={() => clickHandler(CASE_MATCH_STATUS.UNKNOWN)}
        icon={IconManager.names.QUESTION}
      />
      <CustomControllWithTooltip
        isControlActive={isControlActive}
        eventHandler={() => clickHandler(CASE_MATCH_STATUS.REJECTED)}
        icon={IconManager.names.CLOSE}
      />
      <CustomControllWithTooltip
        isControlActive={isControlActive}
        eventHandler={() => clickHandler(CASE_MATCH_STATUS.ACCEPTED)}
        icon={IconManager.names.CHECK_FILLED}
      />
    </div>
  );
};

HitStatusRowControls.propTypes = {
  onClick: PropTypes.func.isRequired,
  isControlActive: PropTypes.bool,
};
HitStatusRowControls.defaultProps = {
  isControlActive: true,
};

export default HitStatusRowControls;
