import { FORM_ERROR } from 'final-form';
import createDecorator from 'final-form-focus';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import toastr from 'toastr';
import { uuid } from 'uuidv4';
import api from '../../../../api';
import FormModal from '../../../../components/Modal/FormModal';
import Section from '../../../../components/Section';
import Select from '../../../../components/Select';
import SelectContentTable from '../../../../components/Select/components/SelectContentTable';
import SelectTrigger from '../../../../components/Select/components/SelectTrigger';
import TableHeader from '../../../../components/Tables/Table/components/TableHeader';
import useSelect from '../../../../hooks/useSelect';
import useTable from '../../../../hooks/useTable';
import { selectCountries } from '../../../../store/selectors/global.selector';
import { EntityContext, PermissionGroupContext } from '../../../../utilities/services/contexts';
import SearchForm from './components/SearchForm';
import RiskProfileSelectRow from './components/SearchForm/RiskProfileSelectRow';
import './styles.scss';

const SearchModal = ({ hideModal, setNewlyAdded, initialValues: initial }) => {
  const [customKey, setCustomKey] = useState(uuid());
  const [initialValues, setInitialValues] = useState(
    initial || {
      sources: [],
      fuzziness: 50,
      entityType: 0,
    },
  );
  const focusOnErrors = createDecorator();

  const entity = useContext(EntityContext);
  const countries = useSelect(selectCountries);
  const { customAmlSearches: canCustomAmlSearches } = useContext(PermissionGroupContext);

  const { records, changeParams, params, pagination, isLoading } = useTable(api.administration.getRiskScreening, {
    limit: 10,
    sortCriteria: 'name',
    sortOrder: 0,
    extended: true,
    entityId: entity?.entityId,
  });

  const mappedRiskProfiles = useMemo(
    () =>
      records.map(profile => ({
        ...profile,
        id: profile.id,
        value: profile.id,
        label: profile.name,
        disabled: !profile.isAllowed,
      })),
    [records],
  );

  const handleSubmit = async data => {
    const { caseAttachment, searchTerms, fuzziness, id: screeningProfileId, ...rest } = data;
    const submitObject = {
      caseAttachment:
        caseAttachment?.length > 0
          ? caseAttachment.map(singleCase => {
              return { id: singleCase.value, name: singleCase.name };
            })
          : [],
      fuzziness: fuzziness / 100,
      searchTerms: searchTerms.map(st => ({
        ...st,
        countries:
          st?.countries?.length > 0
            ? st.countries.map(countryId => {
                const selectedCountry = countries.find(cnt => cnt.id === countryId);
                return selectedCountry?.alpha2Code;
              })
            : [],
      })),
      screeningProfileId,
      ...rest,
    };
    if (entity) {
      submitObject.entityId = entity.entityId;
    }
    let errMessage;
    try {
      const { data: response } = await api.kyc.performRiskScreening(submitObject);
      setNewlyAdded(response.map(r => r.amlResult));
      toastr.success('Successfully created screening profle.');
      hideModal();
    } catch (err) {
      console.error(err);
      if (err?.response) {
        const { status, data: errorData } = err.response;
        if (status >= 400 && status < 500) {
          errMessage = { [FORM_ERROR]: errorData.message };
        }
        if (status === 500) {
          errMessage = { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
        }
      } else errMessage = { [FORM_ERROR]: 'Error occured while performing risk screening' };
    }
    return errMessage;
  };

  const handleRiskProfileChange = formState => {
    const { values } = formState;
    if (initialValues?.id !== values.id) {
      const selectedProfile = records.find(record => record.id === values.id);
      if (selectedProfile) {
        setInitialValues(prev => ({
          ...prev,
          ...selectedProfile,
          searchTerms: selectedProfile?.searchTerms || [{}],
          fuzziness: Math.ceil(selectedProfile.fuzziness),
        }));
      }
    }
  };

  useEffect(() => {
    if (!initialValues?.id) {
      const selectedProfile = records.find(record => record.isAllowed);
      if (selectedProfile) {
        setCustomKey(uuid());
        setInitialValues(prev => ({
          ...prev,
          ...selectedProfile,
          fuzziness: Math.ceil(selectedProfile.fuzziness),
        }));
      }
    }
  }, [records, initialValues.id]);

  const isDefaultRSPAvailable = mappedRiskProfiles.find(mrp => mrp.id === initial?.id)?.isAllowed;

  const profileSelectError = useMemo(() => {
    if (isLoading || (!records?.id && records.length)) {
      return false;
    }
    if (!mappedRiskProfiles?.length || mappedRiskProfiles.every(profile => !profile.isAllowed)) {
      return 'There is no Risk Profile that covers this Jurisdiction. You can add it in Compliance Settings';
    }
    if (!isDefaultRSPAvailable) {
      return 'Please select Risk Screening Profile';
    }
  }, [isDefaultRSPAvailable, mappedRiskProfiles, isLoading, records.id, records.length]);

  useEffect(() => {
    if (entity) {
      setCustomKey(uuid());
      setInitialValues(prev => ({
        ...prev,
        caseAttachment: [{ id: entity.entityId, label: entity.entityName, value: entity.entityId }],
      }));
    }
  }, [entity]);

  return (
    <FormModal
      key={customKey}
      title={<span>Add &nbsp;Search</span>}
      initialValues={initialValues}
      hideModal={hideModal}
      onSubmit={handleSubmit}
      showErrorsInConfirmation
      withFieldArray
      className="ickyc-search-modal"
      decorators={[focusOnErrors]}
    >
      <Section title="Screening Profile" divider>
        <Field
          name="id"
          render={({ input }) => (
            <Select
              Trigger={<SelectTrigger />}
              disabled={!canCustomAmlSearches || isLoading}
              withTableContent
              Content={
                <SelectContentTable
                  tableProps={{
                    tableRow: RiskProfileSelectRow,
                    headerRow: TableHeader,
                    handleParamsChange: changeParams,
                    pagination,
                    headerData: {
                      sortCriteria: params.sortCriteria,
                      sortOrder: params.sortOrder,
                      onClick: changeParams,
                      items: [
                        {
                          label: 'Name',
                          type: 'name',
                          clickable: true,
                        },
                      ],
                    },
                    updating: isLoading,
                    withLoadMore: true,
                    withPagination: true,
                  }}
                />
              }
              onChange={input.onChange}
              options={mappedRiskProfiles}
              value={input.value}
              hasError={profileSelectError && !input.value}
              errorMsg={profileSelectError}
            />
          )}
        />
      </Section>
      <FormSpy subscription={{ values: true }} onChange={handleRiskProfileChange} />
      <SearchForm />
    </FormModal>
  );
};

SearchModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  setNewlyAdded: PropTypes.func,
  entity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    country: PropTypes.number,
    additionalInfo: PropTypes.string,
  }),
  initialValues: PropTypes.shape({ id: PropTypes.number }),
};

SearchModal.defaultProps = {
  setNewlyAdded: () => {},
  entity: undefined,
  initialValues: {},
};

export default SearchModal;
