import ArrowRightIcon from '@material-ui/icons/ArrowDropDown';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import useHeightCollapsible from '../../../hooks/useHeightCollapsible';
import setClassSuffix from '../../../utilities/services/ClassManager';
import './styles.scss';

/**
 *  @param {func} onClick - option click
 */

// eslint-disable-next-line react/prop-types
const ModalTabHeader = ({ className, onClick, options }) => {
  const [selected, setSelected] = useState(options[0]?.key);
  const collapsibleOptionsRef = useRef();

  const { isOpen, toggleView } = useHeightCollapsible(collapsibleOptionsRef);

  const handleOnClick = useCallback(
    option => {
      const index = options.findIndex(opt => opt.key === option.key);
      onClick(index);
      setSelected(option.key);
      toggleView(false);
    },
    [onClick, toggleView, options],
  );

  const selectedOption = useMemo(() => options.find(opt => opt.key === selected), [selected, options]);

  const classBase = 'ickyc-modal-tab-header';
  const classes = classNames(classBase, className);
  const responsiveOptionsClasses = classNames({
    [`${classBase}__content--responsive`]: true,
    [`${classBase}__content--responsive--expanded`]: isOpen,
  });
  const setSufix = setClassSuffix(classBase);

  const generateItemClasses = useCallback(
    active =>
      classNames({
        [setSufix('__content__item')]: true,
        [setSufix('__content__item--active')]: active,
      }),
    [setSufix],
  );

  return (
    <div className={classes}>
      <div className={setSufix('__content')}>
        {options.map(option => (
          <div
            className={generateItemClasses(selected === option.key)}
            onClick={() => handleOnClick(option)}
            key={option.key}
          >
            {option.label}
          </div>
        ))}
      </div>
      <div className={responsiveOptionsClasses}>
        <div className={generateItemClasses(true)} key={selectedOption.key}>
          {selectedOption.label}
          <span className={`${classBase}__content--responsive__svg-container`} onClick={toggleView}>
            <ArrowRightIcon />
          </span>
        </div>
        <div ref={collapsibleOptionsRef}>
          {options
            .filter(opt => opt.key !== selected)
            .map(option => (
              <div className={generateItemClasses(false)} onClick={() => handleOnClick(option)} key={option.key}>
                {option.label}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

ModalTabHeader.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

ModalTabHeader.defaultProps = {
  className: '',
  onClick: () => {},
  options: [],
};

export default ModalTabHeader;
