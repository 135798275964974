import React, { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../../../components/Accordion';
import ToggleField from '../../../../../../../../../components/ToggleField';
import utilities from '../../../../../../../../../utilities';
import './styles.scss';

const IdentityVerificationRCU = ({ namePrefix }) => {
  const baseClass = 'ickyc-identity-verification-rcu';

  const { values } = useFormState();
  const kycRequests = values[namePrefix] ? values[namePrefix].kycRequests : values.kycRequests;

  const { batch, change } = useForm();

  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  useEffect(() => {
    change(
      `${namePrefixWithDot}kycRequests.identityVerification.runIdentityDataConfirmation`,
      kycRequests?.identityVerification?.isEnabled,
    );
  }, [kycRequests?.identityVerification?.isEnabled, batch, change]);

  useEffect(() => {
    if (!kycRequests?.identityVerification?.runIdentityDataConfirmation) {
      change(`${namePrefixWithDot}kycRequests.identityVerification.isEnabled`, false);
    }
  }, [kycRequests?.identityVerification?.runIdentityDataConfirmation]);

  return (
    <Accordion
      title={
        <>
          <div onClick={utilities.handleOnClickEvent}>
            <Field
              preview
              name={`${namePrefixWithDot}kycRequests.identityVerification.isEnabled`}
              component={ToggleField}
            />
          </div>
          <span>Identity Authentication</span>
          <span className="ickyc-spacer" />
        </>
      }
      accordionOpen={kycRequests?.identityVerification?.isEnabled}
    >
      <div className={baseClass}>
        <Field
          name={`${namePrefixWithDot}kycRequests.identityVerification.runIdentityDataConfirmation`}
          component={ToggleField}
          label="Run KYC Service: Profile Data Confirmation"
          className={`${baseClass}__run-verification-toggle`}
        />
      </div>
    </Accordion>
  );
};
export default IdentityVerificationRCU;
