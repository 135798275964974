import classNames from 'classnames';
import useVisibleInViewport from 'hooks/useVisibleInViewport';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import Modal from '..';
import setClassSuffix from '../../../utilities/services/ClassManager';
import PrimaryButton from '../../Buttons/PrimaryButton';
import './styles.scss';

/**
 * Global error modal used for showing api errors.
 * @param {func} hideModal - function for hiding modal
 * @param {reference} containerRef - container reference
 * @param {component} buttonText - text inside the button
 * @param {string} className - external className
 * @param {component} children - components rendered inside the modal
 */

const InformationModal = ({ hideModal, buttonText, className, children, onClick }) => {
  const submitButtonRef = useRef(null);
  const isSubmitButtonVisible = useVisibleInViewport(submitButtonRef);
  const baseClass = 'ickyc-information-modal';
  const setSuffix = setClassSuffix(baseClass);
  const wrapperClasses = classNames([baseClass, `${className}`]);

  const hideModalWrapper = useCallback(() => {
    if (onClick) onClick();
    hideModal();
  }, [hideModal, onClick]);

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        hideModalWrapper();
      }
      return e.preventDefault();
    },
    [hideModalWrapper],
  );

  useEffect(() => {
    isSubmitButtonVisible && submitButtonRef.current.focus();
  }, [isSubmitButtonVisible]);

  return (
    <Modal hideModal={hideModal} onKeyDown={handleKeyDown}>
      <div className={wrapperClasses}>
        {children}

        <div className={setSuffix('__footer')}>
          <PrimaryButton type="submit" onClick={hideModalWrapper} ref={submitButtonRef}>
            {buttonText}
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

InformationModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  buttonText: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

InformationModal.defaultProps = {
  buttonText: 'OK',
  className: '',
  children: null,
  onClick: undefined,
};

export default InformationModal;
