import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ImagePreview from '../../../../../../../../../../components/ImagePreview';
import setClassSuffix from '../../../../../../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../../../../../../utilities/services/IconManager';
import './index.scss';

const LiveFaceCapture = ({ sources }) => {
  const classBase = 'ickyc-live-face-capture';
  const setSuffix = setClassSuffix(classBase);
  const pageRef = useRef(null);

  return (
    <div className={classBase}>
      <h2>Live Face Captures</h2>

      <div className={setSuffix('__content')}>
        {sources.smileImage && (
          <div className={setSuffix('__content__block')}>
            <ImagePreview enlargeImage mini src={sources.smileImage} alt="smile" />
            <span>
              <>
                {sources.livenessConfidenceDetails?.smileImageConfidence !== null && (
                  <>{sources.livenessConfidenceDetails?.smileImageConfidence}%&nbsp;</>
                )}
                {sources?.livenessConfidenceDetails?.smileEmotionPassed !== null && (
                  <>
                    {sources?.livenessConfidenceDetails?.smileEmotionPassed ? (
                      <span className="ickyc-success">Pass</span>
                    ) : (
                      <span className="ickyc-fail">Fail</span>
                    )}
                    &nbsp;
                  </>
                )}
              </>
              {IconManager.get(IconManager.names.SMILE)}
              Smile
            </span>
          </div>
        )}

        {sources.neutralImage && (
          <div className={setSuffix('__content__block')}>
            <ImagePreview mini src={sources.neutralImage} alt="neutral" />

            <span>
              <>
                {sources.livenessConfidenceDetails.neutralImageConfidence !== null && (
                  <> {sources.livenessConfidenceDetails.neutralImageConfidence}%&nbsp;</>
                )}
                {sources?.livenessConfidenceDetails?.neutralEmotionPassed !== null && (
                  <>
                    {sources?.livenessConfidenceDetails?.neutralEmotionPassed ? (
                      <span className="ickyc-success">Pass</span>
                    ) : (
                      <span className="ickyc-fail">Fail</span>
                    )}
                    &nbsp;
                  </>
                )}
              </>
              {IconManager.get(IconManager.names.NEUTRAL_SMILE)}
              Neutral
            </span>
          </div>
        )}

        {sources.angryImage && (
          <div className={setSuffix('__content__block')}>
            <ImagePreview enlargeImage mini src={sources.angryImage} alt="frown" />

            <span>
              <>
                {sources.livenessConfidenceDetails.angryImageConfidence !== null && (
                  <>{sources.livenessConfidenceDetails.angryImageConfidence}%&nbsp;</>
                )}
                {sources?.livenessConfidenceDetails?.angryEmotionPassed !== null && (
                  <>
                    {sources?.livenessConfidenceDetails?.angryEmotionPassed ? (
                      <span className="ickyc-success">Pass</span>
                    ) : (
                      <span className="ickyc-fail">Fail</span>
                    )}
                    &nbsp;
                  </>
                )}
              </>
              {IconManager.get(IconManager.names.ANGRY)}
              Frown
            </span>
          </div>
        )}

        {sources.sadImage && (
          <div className={setSuffix('__content__block')}>
            <ImagePreview enlargeImage mini pageRef={pageRef} src={sources.sadImage} alt="frown" />

            <span>
              <>
                {sources.livenessConfidenceDetails.sadImageConfidence !== null && (
                  <>{sources.livenessConfidenceDetails.sadImageConfidence}%&nbsp;</>
                )}
                {sources?.livenessConfidenceDetails?.sadEmotionPassed !== null && (
                  <>
                    {sources?.livenessConfidenceDetails?.sadEmotionPassed ? (
                      <span className="ickyc-success">Pass</span>
                    ) : (
                      <span className="ickyc-fail">Fail</span>
                    )}
                    &nbsp;
                  </>
                )}
              </>
              {IconManager.get(IconManager.names.SAD)}
              Sad
            </span>
          </div>
        )}

        {sources.surprisedImage && (
          <div className={setSuffix('__content__block')}>
            <ImagePreview enlargeImage mini pageRef={pageRef} src={sources.surprisedImage} alt="frown" />

            <span>
              <>
                {sources.livenessConfidenceDetails.surprisedImageConfidence !== null && (
                  <>{sources.livenessConfidenceDetails.surprisedImageConfidence}%&nbsp;</>
                )}
                {sources?.livenessConfidenceDetails?.surprisedEmotionPassed !== null && (
                  <>
                    {sources?.livenessConfidenceDetails?.surprisedEmotionPassed ? (
                      <span className="ickyc-success">Pass</span>
                    ) : (
                      <span className="ickyc-fail">Fail</span>
                    )}
                    &nbsp;
                  </>
                )}
              </>
              {IconManager.get(IconManager.names.SURPRISE)}
              Surprised
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

LiveFaceCapture.propTypes = {
  sources: PropTypes.shape({
    neutralImage: PropTypes.string,
    smileImage: PropTypes.string,
    sadImage: PropTypes.string,
    angryImage: PropTypes.string,
    surprisedImage: PropTypes.string,
    livenessConfidenceDetails: PropTypes.shape({
      neutralImageConfidence: PropTypes.number,
      smileImageConfidence: PropTypes.number,
      sadImageConfidence: PropTypes.number,
      angryImageConfidence: PropTypes.number,
      surprisedImageConfidence: PropTypes.number,
      smileEmotionPassed: PropTypes.bool,
      neutralEmotionPassed: PropTypes.bool,
      sadEmotionPassed: PropTypes.bool,
      surprisedEmotionPassed: PropTypes.bool,
      angryEmotionPassed: PropTypes.bool,
    }).isRequired,
  }),
};

LiveFaceCapture.defaultProps = {
  sources: {},
};

export default LiveFaceCapture;
