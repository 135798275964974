import ArrowRight from '@material-ui/icons/ArrowRight';
import PropTypes from 'prop-types';
import React from 'react';
import setClassSuffix from '../../../../utilities/services/ClassManager';
import './index.scss';

const CardItem = ({ header, subheader, onClick }) => {
  const baseClass = 'ickyc-card-item';
  const setSuffix = setClassSuffix(baseClass);
  return (
    <div className={baseClass} onClick={onClick}>
      <span className={setSuffix('__info')}>
        <h3>{header}</h3>
        <p>{subheader}</p>
      </span>
      <span className={setSuffix('__icon')}>
        <ArrowRight />
      </span>
    </div>
  );
};
CardItem.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  onClick: PropTypes.func,
};
CardItem.defaultProps = {
  header: '',
  subheader: '',
  onClick: () => {},
};
export default CardItem;
