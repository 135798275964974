import { FORM_ERROR } from 'final-form';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { FormSpy } from 'react-final-form';
import toastr from 'toastr';
import api from '../../../../../api';
import FormModal from '../../../../../components/Modal/FormModal';
import SecurityOptions from '../../../../Administration/UserManagementPage/components/EditUserModal/components/SecurityOptions';
import UserInformationForm from '../../../../Administration/UserManagementPage/components/UserInformationForm';
import IComplyUsersTypePermissions from './components/IComplyUsersTypeSection';
import './styles.scss';

const IComplyEditUserModal = ({ hideModal, updateUser, initialValues, setNewlyAdded }) => {
  const [editUserInfo, setEditUserInfo] = useState(true);
  const revertInitialUserInfo = () => {
    setEditUserInfo(false);
  };
  const [canSubmit, setCanSubmit] = useState(false);

  const handleSubmit = values => {
    const { userInfo, ...rest } = values;
    const submitObject = { ...rest, ...userInfo };

    if (values.userId) {
      return api.internal.iComplyTeam
        .updateUser(values.userId, submitObject)
        .then(async res => {
          toastr.success('User was updated');
          updateUser(values.userId, res.data);

          hideModal();
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        });
    }
    return api.internal.iComplyTeam
      .createUser(submitObject)
      .then(res => {
        hideModal();
        toastr.success('User Succesfully Crated');
        setNewlyAdded(res.data);
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: Array.isArray(errData?.errors) ? errData?.errors?.join('') : errData?.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  return (
    <FormModal
      onSubmit={handleSubmit}
      initialValues={initialValues}
      hideModal={hideModal}
      title={`${initialValues?.userId ? 'Edit' : 'Create'} iComply User`}
      className="ickyc-edit-icomply-user-modal"
      disableSubmitButton={!canSubmit}
      showErrorsInConfirmation
    >
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const { iComplyUser, iComplyAdmin, iComplySuperAdmin } = values;
          setCanSubmit(iComplyUser || iComplyAdmin || iComplySuperAdmin);
          return <span />;
        }}
      </FormSpy>
      <UserInformationForm
        responsive
        editable={editUserInfo}
        onCancel={revertInitialUserInfo}
        onUpdate={() => setEditUserInfo(true)}
        // eslint-disable-next-line react/prop-types
      />
      <SecurityOptions withoutIdentityVerif />
      <IComplyUsersTypePermissions />
    </FormModal>
  );
};
IComplyEditUserModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    userId: PropTypes.string,
  }),
  setNewlyAdded: PropTypes.func.isRequired,
  updateUser: PropTypes.func,
};
IComplyEditUserModal.defaultProps = {
  initialValues: {
    userId: null,
  },
  updateUser: () => {},
};
export default IComplyEditUserModal;
