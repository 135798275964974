/* eslint-disable no-console */
import classNames from 'classnames';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api';
import IComplyForm from '../../../components/Form/IComplyForm';
import BareHeading from '../../../components/Headings/BareHeading';
import Loader from '../../../components/Loader';
import Spinner from '../../../components/Spinner';
import setClassSuffix from '../../../utilities/services/ClassManager';
import { PermissionGroupContext } from '../../../utilities/services/contexts';
import AutomaticOnDemandSettings from '../components/AutomaticOnDemandSettings';
import PortalManagementService from '../PortalManagementPage/services/PortalManagement.service';

const IdentityVerificationPage = ({ className }) => {
  const [initialValues, setInitialValues] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const { edit: canEdit } = useContext(PermissionGroupContext); /// change this

  const handleSubmit = vals => {
    const { assignRestrictions, verificationRestrictions } = diff(initialValues, vals);
    let jurisdictions = {};
    if (assignRestrictions) {
      jurisdictions = assignRestrictions;
    }
    if (verificationRestrictions) {
      for (const key in verificationRestrictions) {
        if (jurisdictions[key]) {
          jurisdictions[key] = {
            ...jurisdictions[key],
            ...verificationRestrictions[key],
          };
        } else {
          jurisdictions[key] = { ...verificationRestrictions[key] };
        }
      }
    }

    const submitObj = {
      jurisdictions: Object.entries(jurisdictions).map(country => ({
        [`countryId`]: Number(country[0].replace('country', '')),
        [`idVerification`]: country[1].automaticIDVerification,
        [`userId`]: country[1].assignToUser,
      })),
      idVerificationAutoAcceptance: vals.documentAuthAutoAcceptance,
    };

    setIsUpdating(true);
    api.administration
      .updateIdentityVerificationInfo(submitObj)
      .then(() => {})
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
          }
          if (status === 500) {
            return {
              [FORM_ERROR]: Array.isArray(errData.message)
                ? errData.message.join('')
                : errData.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      })
      .finally(() => setIsUpdating(false));
  };

  useEffect(() => {
    const loadInitialValues = () => {
      setIsFetching(true);
      api.administration
        .getIdentityVerificationInfo()
        .then(({ data }) => {
          const { jurisdictions, idVerificationAutoAcceptance } = data;
          if (jurisdictions.length)
            setInitialValues({
              verificationRestrictions: jurisdictions.reduce(
                (accumulator, { countryId, idVerification }) => ({
                  ...accumulator,
                  [`country${countryId}`]: { automaticIDVerification: idVerification, selected: false },
                }),
                {},
              ),
              assignRestrictions: jurisdictions.reduce(
                (accumulator, { countryId, userId }) => ({
                  ...accumulator,
                  [`country${countryId}`]: { assignToUser: userId, selected: false },
                }),
                {},
              ),
              documentAuthAutoAcceptance: idVerificationAutoAcceptance,
            });
          else {
            setInitialValues({
              verificationRestrictions: PortalManagementService.setCountryTableRowValues({
                automaticIDVerification: 'Automatic',
              }),
              assignRestrictions: PortalManagementService.setCountryTableRowValues({
                assignToUser: null,
              }),
              documentAuthAutoAcceptance: false,
            });
          }
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: Array.isArray(errData.message) ? errData.message.join('') : errData.message };
            }
            if (status === 500) {
              return {
                [FORM_ERROR]: Array.isArray(errData.message)
                  ? errData.message.join('')
                  : errData.message || 'Internal Server Error, Try Again Later',
              };
            }
          } else return { [FORM_ERROR]: 'Error occured' };
        })
        .finally(() => setIsFetching(false));
    };
    loadInitialValues();
  }, []);

  const baseClass = 'ickyc-page';

  const setSuffix = setClassSuffix(baseClass);

  const classes = classNames(baseClass, 'ickyc-biometric-authentication', className);

  const contentClasses = classNames({
    [setSuffix('__content')]: true,
  });

  return (
    <div className={classes}>
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          {isUpdating && <Loader />}
          <BareHeading title="Identity Verification" />
          <div className={contentClasses}>
            <IComplyForm initialValues={initialValues} showControls={canEdit} onSubmit={handleSubmit}>
              <AutomaticOnDemandSettings disabled={!canEdit} tableName="verificationRestrictions" />
            </IComplyForm>
          </div>
        </>
      )}
    </div>
  );
};

IdentityVerificationPage.propTypes = {
  className: PropTypes.string,
};

IdentityVerificationPage.defaultProps = {
  className: undefined,
};

export default IdentityVerificationPage;
