import React, { useCallback, useState } from 'react';
import ForgotPassowrdFrom from '../ForgotPasswordForm';
import LoginForm from './LoginForm';

const Signin = () => {
  const [activeScreen, setActiveScreen] = useState(0);
  const screens = [
    <LoginForm onForgotClick={() => setActiveScreen(1)} />,
    <ForgotPassowrdFrom onCancel={() => setActiveScreen(0)} />,
  ];
  const getCurrentScreen = useCallback(() => {
    return screens[activeScreen];
  }, [activeScreen, screens]);

  return getCurrentScreen();
};

export default Signin;
