import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import setClassSuffix from 'utilities/services/ClassManager';
import DateManager from 'utilities/services/DateManager';
import useAssignedToMeRow from '../../hooks/useAssignedToMeRow';

const EntitiesAssignedToMeRow = ({
  rowInfo: { id, name, caseStatus, status, potential, nextKYCReview, issues, type: entityType, entityId },
  className,
}) => {
  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const [navigateToItem, icon] = useAssignedToMeRow(entityType, id, entityId);

  return (
    <tr key={id || entityId} className={`${className}__row`}>
      <td className={setColumnSuffix('__entityType')}>{icon}</td>
      <td className={setColumnSuffix('__name')}>
        <NavLink to={navigateToItem}>{name}</NavLink>
      </td>
      <td className={setColumnSuffix('__caseStatus')}>
        <NavLink to={navigateToItem}>{caseStatus || status}</NavLink>
      </td>
      <td className={setColumnSuffix('__issues')}>
        <NavLink to={navigateToItem}>{caseStatus ? potential : issues}</NavLink>
      </td>
      <td className={setColumnSuffix('__nextKYCReview')}>
        <NavLink to={navigateToItem}>{nextKYCReview ? DateManager.monDayYearLocal(nextKYCReview) : '-'}</NavLink>
      </td>
    </tr>
  );
};

EntitiesAssignedToMeRow.propTypes = {
  rowInfo: PropTypes.shape({
    id: PropTypes.string,
  }),
  className: PropTypes.string,
};

EntitiesAssignedToMeRow.defaultProps = {
  className: '',
  entityType: 2,
};
export default EntitiesAssignedToMeRow;
