import PropTypes from 'prop-types';
import useSelect from '../../../hooks/useSelect';
import { selectUserRoles, selectUserType } from '../../../store/selectors/auth.selector';

const PrivateComponent = ({ allowedUserRoles, children, allowedUserTypes }) => {
  const userType = useSelect(selectUserType);
  const userRoles = useSelect(selectUserRoles);
  const isAllowedUserType = allowedUserTypes.some(r => r === userType);
  const hasRequiredRoles = allowedUserRoles.some(r => userRoles.some(role => role === r));

  return isAllowedUserType && hasRequiredRoles && children;
};

PrivateComponent.propTypes = {
  allowedUserRoles: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element,
  allowedUserTypes: PropTypes.arrayOf(PropTypes.string),
};

PrivateComponent.defaultProps = {
  allowedUserRoles: [],
  children: null,
  allowedUserTypes: null,
};

export default PrivateComponent;
