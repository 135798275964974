import api from '../../api';
import bus from '../../modules/bus';
import TokenStorage from './local-storage/TokenStorage';

export const REFRESH_TOKEN_SUCCESS = 'rts';
export const REFRESH_TOKEN_FAIL = 'rtf';

export default {
  refreshingAtm: false,
  checkRefreshinThreshold: 10000,
  refreshToken() {
    this.refreshingAtm = true;
    return api.auth.refresh().then(({ token, refreshToken }) => {
      this.refreshingAtm = false;
      TokenStorage.token = token;
      TokenStorage.refreshToken = refreshToken;
      bus.broadcastEvent(REFRESH_TOKEN_SUCCESS);
    });
  },
  reinit() {
    this.refreshingAtm = false;
  },
};
