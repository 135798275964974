import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Datepicker from '../Datepicker';

const DatePickerField = ({ input, meta, className, onChange, ...rest }) => {
  const errorMsg = useMemo(() => (meta ? meta.error || meta.submitError : null), [meta]);

  const hasError = useMemo(() => {
    if (meta) {
      const { touched: metaTouched, error: metaError, submitError, dirtySinceLastSubmit } = meta;
      if (submitError && !dirtySinceLastSubmit) {
        return submitError;
      }
      return metaTouched && metaError;
    }
    return false;
  }, [meta]);

  const inputProps = useMemo(
    () => ({
      ...input,
      onChange: event => {
        input.onChange(event);
        onChange && onChange(event);
      },
    }),
    [input, onChange],
  );

  return <Datepicker className={className} {...rest} {...inputProps} errorMsg={errorMsg} hasError={hasError} />;
};

DatePickerField.propTypes = {
  input: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  meta: PropTypes.shape({
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
  }),
  className: PropTypes.string,
};
DatePickerField.defaultProps = {
  className: undefined,
  onChange: undefined,
  meta: { invalid: false, touched: false, error: '', submitError: '', dirtySinceLastSubmit: false },
};
export default DatePickerField;
