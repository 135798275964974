import useTable from 'hooks/useTable';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import socketKeys from 'utilities/enums/socketKeys';
import SignalRService from 'utilities/services/SignalRService';
import api from '../../../../../api';
import Accordion from '../../../../../components/Accordion';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import Table from '../../../../../components/Tables/Table';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import bus from '../../../../../modules/bus';
import enums from '../../../../../utilities/enums';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import TableHeaders from '../../../../../utilities/services/TableHeaders';
import { EntityContext } from '../../../../../utilities/services/contexts';
import SearchModal from '../../../components/SearchModal';
import EntityCasesTableRow from './components/EntityCasesTableRow';
import './styles.scss';

const RiskScreeningSection = ({ badge, riskScreeningOptions }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { entityId } = useContext(EntityContext);

  const { records, addRows, removeRow, isLoading, params, changeParams, pagination, updateRowData } = useTable(
    api.kyc.entityManagement.getRiskScreeningSection,
    { entityId },
  );

  const baseClass = 'ickyc-accordion-additional-content';
  const setSuffix = setClassSuffix(baseClass);

  const handleCreateNewCase = useCallback(
    newCases => {
      addRows(newCases);

      bus.broadcastEvent(
        enums.BUS_EVENTS.UPDATE_ISSUES,
        {
          type: 'aml',
          section: enums.ACCORDION_INDEXES.RISK_SCREENING,
          issuesChange: newCases.reduce((acc, curr) => acc + curr?.potential || 0, 0),
        },
        [],
      );
    },
    [addRows],
  );

  const containerRef = useRef(null);

  const handleCaseRemove = useCallback(
    caseToRemove => {
      removeRow(caseToRemove);

      bus.broadcastEvent(
        enums.BUS_EVENTS.UPDATE_ISSUES,
        {
          type: 'aml',
          section: enums.ACCORDION_INDEXES.RISK_SCREENING,
          issuesChange: -caseToRemove.potential,
        },
        [],
      );
    },
    [removeRow],
  );

  const tableRowSocketUpdate = useCallback(
    ({ id, ...rest }) => {
      updateRowData('id', id, {
        ...rest,
      });
    },
    [updateRowData],
  );

  useEffect(() => {
    SignalRService.receiveMessage(socketKeys.CASE_READY, tableRowSocketUpdate);
  }, []);

  const TableControls = () => {
    return (
      <>
        <h3>Cases</h3>
        <div className={setSuffix('__header__controls')}>
          <PrimaryButton onClick={() => setIsOpen(prev => !prev)}>+ Add Case</PrimaryButton>
        </div>
      </>
    );
  };

  return (
    <Accordion
      title="Risk Screening"
      withBadge
      badge={badge}
      accordionOpen={false}
      accordionindex={enums.ACCORDION_INDEXES.RISK_SCREENING}
      accented
      className="ickyc-risk-collapsible"
    >
      <div ref={containerRef}>
        {isOpen && (
          <SearchModal
            hideModal={() => setIsOpen(false)}
            containerRef={containerRef}
            initialValues={riskScreeningOptions}
            setNewlyAdded={handleCreateNewCase}
          />
        )}
        <Table
          values={records}
          pagination={pagination}
          tableControls={TableControls}
          containerRef={containerRef}
          headerData={{
            sortCriteria: params.sortCriteria,
            sortOrder: params.sortOrder,
            items: TableHeaders.EntityRiskScreeningTable,
            withDelete: true,
          }}
          headerRow={TableHeader}
          tableRow={EntityCasesTableRow}
          withPagination
          handleParamsChange={changeParams}
          updating={isLoading}
          onRemove={handleCaseRemove}
          className="ickyc-risk-screening-section"
        />
      </div>
    </Accordion>
  );
};

RiskScreeningSection.propTypes = {
  badge: PropTypes.number,
  riskScreeningOptions: PropTypes.shape({}),
};
RiskScreeningSection.defaultProps = {
  badge: 0,
  riskScreeningOptions: {},
};

export default RiskScreeningSection;
