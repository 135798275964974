import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import toastr from 'toastr';
import api from '../../../../../api';
import DeleteButton from '../../../../../components/Buttons/DeleteButton';
import SingleCountry from '../../../../../components/CountrySelect/components/SingleCountry';
import FormModal from '../../../../../components/Modal/FormModal';
import useGroupPermissions from '../../../../../hooks/useGroupPermissions';
import useModalHandler from '../../../../../hooks/useModalHandler';
import useSelect from '../../../../../hooks/useSelect';
import history from '../../../../../modules/history';
import ProtectedComponent from '../../../../../router/components/ProtectedComponent';
import { selectCountries } from '../../../../../store/selectors/global.selector';
import utilities from '../../../../../utilities';
import authEnums from '../../../../../utilities/enums/authEnums';
import routesEnum from '../../../../../utilities/enums/routesEnum';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../utilities/services/DateManager';

const BusinessManagerTableRow = ({
  rowInfo: { organizationName, organizationId, status, dateCreated, countries: countriesList },
  className,
  onRemove,
}) => {
  const countries = useSelect(selectCountries);
  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const groupPermissions = useGroupPermissions(authEnums.PERMISSION_GROUP.BUSINESS_MANAGER);
  const { close: closeConfirmModal, open: openConfirmModal, isOpen: isConfirmModal } = useModalHandler();

  const navigateToBusinessProfile = () => {
    if (groupPermissions.edit)
      history.push(
        utilities.routeParams(routesEnum.internal.BUSINESS_PROFILE, { id: organizationId, organizationName }),
      );
  };

  const countriesToShow = useMemo(() => countriesList.map(({ id }) => countries?.find(country => country.id === id)), [
    countries,
    countriesList,
  ]);
  const statusClasses = classNames({
    [setColumnSuffix('__status--active')]: status === 'Active',
    [setColumnSuffix('__status')]: true,
  });

  const handleOrganizationDelete = () => {
    return api.businessManager
      .deleteOrganization(organizationId)
      .then(() => {
        closeConfirmModal();
        onRemove('organizationId', organizationId);
        toastr.success(`${organizationName} has been deleted`);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: Array.isArray(data.message) ? data.message.join('') : data.message };
          }
          if (resStatus === 500) {
            return {
              [FORM_ERROR]: Array.isArray(data.message)
                ? data.message.join('')
                : data.message || 'Internal Server Error, Try Again Later',
            };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };

  return (
    <tr key={organizationId} className={`${className}__row`}>
      <td className={setColumnSuffix('__name')} onClick={navigateToBusinessProfile}>
        <span>{organizationName}</span>
      </td>
      <td className={statusClasses} onClick={navigateToBusinessProfile}>
        <span>{status}</span>
      </td>
      <td className={setColumnSuffix('__country')} onClick={navigateToBusinessProfile}>
        <span>
          {countriesToShow?.map(({ alpha2Code, name }) => (
            <SingleCountry alpha2Code={alpha2Code?.toLowerCase()} name={name} />
          ))}
        </span>
      </td>
      <td className={setColumnSuffix('__dateCreated')} onClick={navigateToBusinessProfile}>
        <span>{dateCreated ? DateManager.getFullDateLocal(dateCreated) : ''}</span>
      </td>
      <ProtectedComponent
        requiredPermissions={[authEnums.PERMISSION_TAGS_MAPPING.delete]}
        permissionGroup={authEnums.PERMISSION_GROUP.BUSINESS_MANAGER}
      >
        <td className={setColumnSuffix('__remove')}>
          <DeleteButton onClick={openConfirmModal} />
          <div>
            {isConfirmModal && (
              <FormModal
                hideModal={closeConfirmModal}
                title="Remove Organization"
                className="ickyc-confirmation-modal"
                onSubmit={handleOrganizationDelete}
                showErrorsInConfirmation
              >
                <span className="ickyc-confirmation-message">
                  Are you sure you want to delete &nbsp; <h3>{organizationName}</h3> &nbsp;organization?
                </span>
              </FormModal>
            )}
          </div>
        </td>
      </ProtectedComponent>
    </tr>
  );
};
BusinessManagerTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    organizationId: PropTypes.string.isRequired,
    organizationName: PropTypes.string.isRequired,
    dateCreated: PropTypes.string,
    status: PropTypes.string,
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
      }),
    ),
  }),
  className: PropTypes.string,
  onRemove: PropTypes.func,
};

BusinessManagerTableRow.defaultProps = {
  className: '',
  rowInfo: {
    countries: [],
  },
  onRemove: () => {},
};
export default BusinessManagerTableRow;
