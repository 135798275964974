/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */

import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowRight';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import validators from 'utilities/services/validators';
import DeleteButton from '../../../../../../../../../components/Buttons/DeleteButton';
import InputField from '../../../../../../../../../components/InputField';
import SelectCustomContent from '../../../../../../../../../components/Select/components/SelectCustomContent';
import SelectInputTrigger from '../../../../../../../../../components/Select/components/SelectInputTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import utilities from '../../../../../../../../../utilities';
import authEnums from '../../../../../../../../../utilities/enums/authEnums';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../../../../../utilities/services/IconManager';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';

const htmlImportTag = `<script src="https://verify.icomplykyc.com/icomply-min.js"></script>`;

const styleImportTag = `<link rel="stylesheet" href="https://verify.icomplykyc.com/main.css" />`;
// eslint-disable-next-line max-len
const moduleImporTag = `window.iComply.init({containerElement,verificationIdCallback:function(verificationId) {},callback: function(serverResponse){},data}`;

const DomainsTableRow = ({ index, namePreffix, className, onRemove, onRadioClick, disableRadio }) => {
  const [copyStatus, setCopyStatus] = useState({
    html: 'copy',
    style: 'copy',
    module: 'copy',
  });
  const [shouldExpand, setShouldExpand] = useState(false);
  const setColumnSuffix = setClassSuffix(`${className}__column`);
  const {
    values: { domains },
  } = useFormState();

  const { change } = useForm();

  const handleOnRemove = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      const targetDomain = domains[index];
      if (targetDomain.isClientUpdate) {
        const newDomains = domains.filter(domain => !domain.isClientUpdate);
        newDomains[0].isClientUpdate = true;
        change('change', newDomains);
      }
      onRemove(index);
    },
    [onRemove, index, domains],
  );

  const handleRadioClick = useCallback(() => {
    onRadioClick(index);
  }, [index, onRadioClick]);

  const isAutoGenerated = useMemo(() => {
    return domains[index]?.isAutoGenerated;
  }, [domains, index]);

  const onTextCopy = (text, name) => {
    utilities.copyToClipboard(text);
    setCopyStatus({
      html: 'copy',
      style: 'copy',
      module: 'copy',
      [name]: 'copied',
    });
  };

  const stopBubling = e => e.stopPropagation();

  return (
    <CollapsibleTableRow
      className={className}
      rowClassName={domains[index]?.hidden ? `${className}__row--hidden` : undefined}
      shouldExpand={shouldExpand}
      externalArrow
      row={
        <>
          <td className={setColumnSuffix('__radio')}>
            <label>
              <Field
                component="input"
                type="radio"
                checked={domains[index]?.isClientUpdate}
                name={`${namePreffix}.isClientUpdate`}
                onChange={handleRadioClick}
                disabled={disableRadio}
              />
            </label>
          </td>
          <td
            className={setColumnSuffix('__codeSnippet')}
            onClick={() => {
              if (!isAutoGenerated) {
                setShouldExpand(prev => !prev);
              }
            }}
          >
            {!isAutoGenerated ? (
              <>
                <b>{shouldExpand ? <ArrowDownIcon /> : <ArrowUpIcon />} Custom &nbsp;</b>
                View Code
              </>
            ) : (
              <a
                name={`${namePreffix}.url`}
                onClick={() => {
                  window.open(`${domains[index]?.url}`, '_blank').focus();
                }}
              >
                {isAutoGenerated ? (
                  <>
                    <b>Autogenerated &nbsp;</b> View Domain
                  </>
                ) : (
                  <>View Portal</>
                )}
              </a>
            )}
          </td>
          <td className={setColumnSuffix('__domains')} onClick={stopBubling}>
            <Field
              name={`${namePreffix}.url`}
              component={InputField}
              disabled={isAutoGenerated}
              validate={validators.required(
                'You have an empty domain field. Complete it and click Save to prevent automatic removal when you exit the Portal Manager.',
              )}
            />
          </td>
          <td className={setColumnSuffix('__tags')} onClick={stopBubling}>
            <Field
              component={SelectField}
              name={`${namePreffix}.tags`}
              multipleSelection
              withCustomOptions
              options={authEnums.TAGS}
              renderAsPortal
              Trigger={<SelectInputTrigger placeholder="Tags" />}
              Content={<SelectCustomContent />}
            />
          </td>
          <td className={setColumnSuffix('__remove')}>
            {!isAutoGenerated && <DeleteButton transparent onClick={handleOnRemove} />}
          </td>
        </>
      }
    >
      <div>
        <h3>
          <i>Include HTML script tag on your page</i>
        </h3>
        <b>
          <span className="ickyc-confirmation-message">
            <pre>{'<script src="https://verify.icomplykyc.com/icomply-min.js"></script>'}</pre>
            <h3 className="ickyc-confirmation-message__copy" onClick={() => onTextCopy(htmlImportTag, 'html')}>
              {IconManager.get(IconManager.names.COPY)}
              {copyStatus.html}
            </h3>
          </span>
        </b>
        <h3>
          <i>Include CSS styles</i>
        </h3>
        <b>
          <span className="ickyc-confirmation-message">
            <pre>{'<link rel="stylesheet" href="https://verify.icomplykyc.com/main.css" />'}</pre>
            <h3
              className="ickyc-confirmation-message__copy"
              onClick={() => onTextCopy(JSON.stringify(styleImportTag), 'style')}
            >
              {IconManager.get(IconManager.names.COPY)}
              &nbsp;
              {copyStatus.style}
            </h3>
          </span>
        </b>
        <h3>
          <i>Initialize module</i>
        </h3>
        <b>
          <span className="ickyc-confirmation-message">
            <pre>
              window.iComply.init( <br /> containerElement, <em>// HTML Element within whom module will be loaded,</em>
              <span style={{ marginLeft: '20px' }}>
                <br />
                {'{'}
                <br />
                &nbsp;&nbsp; verificationIdCallback: function(verificationId) {'{'} <br />
                &nbsp;&nbsp;&nbsp;<em>// your code goes here</em>
                <br />
                &nbsp;&nbsp;<em>{'}'},</em>
                <br />
                &nbsp;&nbsp;callback: function(serverResponse) {'{'} <br />
                &nbsp;&nbsp;&nbsp;<em>// your code goes here</em>
                <br />
                &nbsp;&nbsp;<em>{'}'},</em>
                <br />
                &nbsp;&nbsp;data <br />
              </span>
              {'})'}
            </pre>
            <h3
              className="ickyc-confirmation-message__copy"
              onClick={() => onTextCopy(JSON.stringify(moduleImporTag), 'module')}
            >
              {IconManager.get(IconManager.names.COPY)}
              &nbsp;
              {copyStatus.module}
            </h3>
          </span>
        </b>
      </div>
    </CollapsibleTableRow>
  );
};

DomainsTableRow.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
  namePreffix: PropTypes.string.isRequired,
  onRadioClick: PropTypes.func,
  disableRadio: PropTypes.bool,
};
DomainsTableRow.defaultProps = {
  className: '',
  onRemove: () => {},
  onRadioClick: () => {},
  disableRadio: false,
};
export default DomainsTableRow;
