import authEnums from 'utilities/enums/authEnums';
import FilesManagement from '../../../../../../../../scenes/Administration/FilesManagementPage';
import ReportsManagementPage from '../../../../../../../../scenes/Administration/ReportsManagementPage';
import redirect from '../../../../../../../modules/redirect';

export default [
  {
    path: '/csv',
    component: FilesManagement,
    licenceAccessKey: authEnums.ACCESS_BY_LICENCE.ENTITIES,
  },
  {
    path: '/reports',
    component: ReportsManagementPage,
  },
  {
    path: '*',
    component: redirect('/csv'),
  },
];
