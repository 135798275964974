import FormModal from 'components/Modal/FormModal';
import RadioButtonField from 'components/RadioButtonField';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

const ApproveEntitiesModal = ({ hideModal, onApprove }) => {
  return (
    <FormModal
      hideModal={hideModal}
      title={'Approve Entitie(s)'}
      initialValues={{
        acceptIssues: 1,
      }}
      onSubmit={onApprove}
      className="ickyc-approve-entities-modal"
    >
      <p>These entities may still have unresolved issues</p>

      <Field
        component={RadioButtonField}
        type="radio"
        value={1}
        name={'acceptIssues'}
        parse={val => Number(val)}
        label="Accept all issues and Approve these entities"
      />
      <Field
        component={RadioButtonField}
        name={'acceptIssues'}
        type="radio"
        parse={val => Number(val)}
        value={0}
        label="Keep issues unresolved and Approve these entities"
      />
    </FormModal>
  );
};

ApproveEntitiesModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

ApproveEntitiesModal.defaultProps = {};

export default ApproveEntitiesModal;
