import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import setClassSuffix from 'utilities/services/ClassManager';
import DashboardTile from '../DashboardTile';
import './styles.scss';

const CasesCountTile = ({ currentUsage, isLoading }) => {
  const baseClass = 'ickyc-cases-count-tile';
  const setSuffix = setClassSuffix(baseClass);
  const history = useHistory();

  const navigateToCaseManagement = useCallback(() => {
    history.push('/kyc/case-management', {
      openFromDashboard: true,
    });
  }, [history]);

  return (
    <DashboardTile
      header="Cases"
      buttonText="Add Case"
      className={baseClass}
      isLoading={isLoading}
      buttonAction={navigateToCaseManagement}
    >
      <span className={setSuffix('__count')}>{currentUsage}</span>
    </DashboardTile>
  );
};

CasesCountTile.propTypes = {
  className: PropTypes.string,
  currentUsage: PropTypes.number,
  isLoading: PropTypes.bool,
};

CasesCountTile.defaultProps = {
  className: undefined,
  currentUsage: 0,
  isLoading: true,
};
export default CasesCountTile;
