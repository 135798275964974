import api from 'api';
import InputField from 'components/InputField';
import FormModal from 'components/Modal/FormModal';
import SelectContent from 'components/Select/components/SelectContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import Spinner from 'components/Spinner';
import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import validators from 'utilities/services/validators';
import EmailMessage from '../EmailMessage';
import './styles.scss';

const EmailTemplateModal = ({
  hideModal,
  title = 'Email Template',
  handleSubmit,
  initialValues: initialValuesProp,
  type,
}) => {
  const baseClass = 'ickyc-email-template-modal';
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    const fetchTemplatePreview = async () => {
      try {
        const { data } = await api.administration.settings.getEmailTemplatePreview();
        setInitialValues(data);
      } catch (err) {
        console.error(err);
      }
    };

    if (initialValuesProp) {
      const {
        domain: { domain },
        ...restInitialValues
      } = initialValuesProp;

      const tempInitialValues =
        domain === 'All Portals'
          ? { domain: { domain: 'Apply to all Portals' }, ...restInitialValues }
          : initialValuesProp;

      setInitialValues(tempInitialValues);
    } else {
      fetchTemplatePreview();
    }
  }, [initialValuesProp]);

  const [domains, setDomains] = useState(
    initialValues && initialValues?.domain?.domain !== 'All Portals' ? [initialValues.domain] : [],
  );
  const [isLoadingDomains, setIsLoadingDomains] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingDomains(true);
        if (type === 'RCU') {
          const { data } = await api.administration.settings.getDomainsForRCUTemplate();
          setDomains(old => [...old, ...data]);
        } else {
          const { data } = await api.administration.settings.getDomainsForEmailALinkTemplate();
          setDomains(old => [...old, ...data]);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingDomains(false);
      }
    })();
  }, []);

  const domainOptions = useMemo(() => {
    const generatedOptions = domains
      ? domains?.map(({ domain, portalId }) => ({ id: domain, label: domain, value: { domain, portalId } }))
      : [];

    return [
      { id: 'applyToAll', value: { domain: 'Apply to all Portals' }, label: 'Apply to all Portals' },
      ...generatedOptions,
    ];
  }, [domains]);

  return (
    <div>
      <FormModal
        hideModal={hideModal}
        title={title}
        withFieldArray
        onSubmit={({ domain, ...rest }) => handleSubmit({ ...domain, ...rest })}
        className={baseClass}
        initialValues={initialValues}
        onRejectClick={hideModal}
        confirmButtonText="Yes"
        confirmationText="Would you like to proceed and override previous request?"
        rejectButtonText="No"
        submitButtonText="Save"
        showErrorsInConfirmation
      >
        <div className={`${baseClass}__container`}>
          {isLoadingDomains ? (
            <Spinner />
          ) : (
            <>
              <div className={`${baseClass}__field-box`}>
                <span className={`${baseClass}__label`}>Portal</span>
                <Field
                  className={`${baseClass}__input`}
                  name="domain"
                  component={SelectField}
                  options={domainOptions}
                  validate={validators.required()}
                  Trigger={<SelectTrigger />}
                  Content={<SelectContent />}
                />
              </div>
              <div className={`${baseClass}__field-box`}>
                <span className={`${baseClass}__label`}>Email Subject</span>
                <Field
                  className={`${baseClass}__input`}
                  name="emailSubject"
                  placeholder="Subject"
                  component={InputField}
                  validate={validators.required()}
                />
              </div>
              <EmailMessage label={'Email Message'} isLinkRequired />
            </>
          )}
        </div>
      </FormModal>
    </div>
  );
};
export default EmailTemplateModal;
