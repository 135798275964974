import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../../api';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import Card from '../../../../components/Card';
import Spinner from '../../../../components/Spinner';
import ProtectedComponent from '../../../../router/components/ProtectedComponent';
import authEnums from '../../../../utilities/enums/authEnums';
import routesEnum from '../../../../utilities/enums/routesEnum';

const Summary = ({ row, column }) => {
  const [summary, setSummary] = useState({});
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchSummaryData = () => {
      setIsLoading(true);
      api.homescreen
        .getSummaryInfo()
        .then(({ data }) => {
          setSummary(data);
        })
        .finally(() => setIsLoading(false));
    };

    fetchSummaryData();
  }, []);

  const navigateToEntityManagement = () => {
    history.push(routesEnum.kyc.ENTITY_MANAGEMENT);
  };
  const naviagateToCaseManagement = () => {
    history.push(`${routesEnum.kyc.CASE_MANAGEMENT}`, { openFromHome: true });
  };
  return (
    <Card text="Summary" row={row} column={column} className="ickyc-card-summary">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <h2 className="ickyc-card-summary__header">{summary.organizationName}</h2>
          <div className="ickyc-card-summary__info">
            <div>
              <span className="ickyc-card-summary__info__text">Assigned to Me</span>
              <span className="ickyc-card-summary__info__number">{summary.assignedToMeCount}</span>
            </div>
            <div>
              <span className="ickyc-card-summary__info__text">Unassigned</span>
              <span className="ickyc-card-summary__info__number">{summary.unassigned}</span>
            </div>
          </div>
          <div className="ickyc-card-summary__buttons">
            <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.ENTITIES}>
              <PrimaryButton onClick={navigateToEntityManagement}> Add Entity</PrimaryButton>
            </ProtectedComponent>
            <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}>
              <PrimaryButton onClick={naviagateToCaseManagement}> AML Search </PrimaryButton>
            </ProtectedComponent>
          </div>
        </>
      )}
    </Card>
  );
};
Summary.propTypes = {
  row: PropTypes.number.isRequired,
  column: PropTypes.number.isRequired,
};
export default Summary;
