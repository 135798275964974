import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import './styles.scss';

const ModuleOption = ({ children, title, error: errorProp }) => {
  const baseClass = 'ickyc-module-options';

  const error = useMemo(() => {
    if (errorProp) {
      //FieldArray can also hold an array of error objects if there's no error on FieldArray level but there's an error on Field level
      if (typeof errorProp === 'string') {
        return <span className={`${baseClass}__error`}>{errorProp}</span>;
      }
    }
  }, [errorProp]);

  const classes = classNames(baseClass, { [`${baseClass}--error`]: error });

  return (
    <div className={classes}>
      <div className={`${baseClass}__label`}>{title}</div>
      <div className={`${baseClass}__content`}>{children}</div>
      {error}
    </div>
  );
};

ModuleOption.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string,
  error: PropTypes.string,
};
ModuleOption.defaultProps = {
  title: 'Module Options',
  error: '',
};

export default ModuleOption;
