import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../components/InputField';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../components/SelectField';
import ToggleField from '../../../../../components/ToggleField';
import useSelect from '../../../../../hooks/useSelect';
import { selectRiskLevelLabels } from '../../../../../store/selectors/global.selector';
import enums from '../../../../../utilities/enums';
import kycServicesEnums from '../../../../Kyc/EntityManagement/components/KYCServicesV3/components/kycServicesEnums';
import './styles.scss';

const EntityProfileForm = ({ namePreffix }) => {
  const baseClass = 'ickyc-entity-profile-form';
  const preffix = useMemo(() => (namePreffix ? `${namePreffix}.` : ''), [namePreffix]);
  const mappedStatuses = useMemo(() => {
    return Object.values(enums.ENTITY_STATUSES).map(status => ({
      id: status,
      label: status,
      value: status,
    }));
  }, []);

  const mappedStatusesAutomation = useMemo(() => {
    return Object.values(enums.ENTITY_STATUSES)
      .filter(status => status !== 'Approved')
      .map(status => ({
        id: status,
        label: status,
        value: status,
      }));
  }, []);

  const riskItems = useSelect(selectRiskLevelLabels).map((risk, ind) => ({
    id: ind,
    label: `${risk} (${ind + 1})`,
    value: ind + 1,
  }));

  return (
    <div className={baseClass}>
      <div className={`${baseClass}__aml-case-closed`}>
        <span>If all Pending KYC requests are in Accepted status and at least one AML case is closed:</span>
        <Field name={`${preffix}enablePeriodicReview`} component={ToggleField} label="Next Periodic Review" />

        <Field name={`${preffix}periodicReviewValue`} positiveNumbersOnly component={InputField} type="number" />
        <Field
          name={`${preffix}periodicReviewInterval`}
          component={SelectField}
          options={kycServicesEnums.PERIODIC_REVIEW_OPTIONS}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContent />}
        />

        <Field name={`${preffix}autoUpdateEntityStatus`} component={ToggleField} label="Set Profile Status To" />
        <Field
          name={`${preffix}setProfileStatusTo`}
          component={SelectField}
          options={mappedStatuses}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContent />}
        />

        <Field name={`${preffix}autoUpdateRiskLevel`} component={ToggleField} label="Set Risk Level To" />
        <Field
          name={`${preffix}setRiskLevelTo`}
          component={SelectField}
          options={riskItems}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContent />}
        />
      </div>
      <div className={`${baseClass}__rest-of-entity-profile`}>
        <Field
          name={`${preffix}changeStatusForApprovedProfile`}
          component={ToggleField}
          label="If Approved profile has new pending requests or case with issues set status to"
        />
        <Field
          name={`${preffix}approvedProfileStatus`}
          component={SelectField}
          options={mappedStatusesAutomation}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContent />}
        />
      </div>
    </div>
  );
};
EntityProfileForm.propTypes = {
  namePreffix: PropTypes.string,
};
EntityProfileForm.defaultProps = {
  namePreffix: null,
};
export default EntityProfileForm;
