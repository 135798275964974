import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../components/Accordion';
import Section from '../../../../../../../components/Section';
import Spinner from '../../../../../../../components/Spinner';
import ToggleField from '../../../../../../../components/ToggleField';
import ProtectedComponent from '../../../../../../../router/components/ProtectedComponent';
import utilities from '../../../../../../../utilities';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import rcuEnums from '../../../../../../../utilities/enums/rcuEnums';
import AutoAcceptenceForm from '../../../../../../Administration/Automations/components/AutoAcceptenceForm';
import AutoAssignmentForm from '../../../../../../Administration/Automations/components/AutoAssignmentForm';
import EntityProfileForm from '../../../../../../Administration/Automations/components/EntityProfileForm';
import './styles.scss';

const Automation = ({ isLoadingAutomation, namePrefix }) => {
  const baseClass = 'ickyc-automation';
  const { batch, change } = useForm();
  const { values } = useFormState();
  const [accordionOpen, setAccordionOpen] = useState(false);

  const automation = namePrefix ? utilities.accessObjectNestedValuesByStringPath(values, namePrefix) : values;
  const {
    biometricAuthenticationAutoAcceptance,
    documentAuthenticationAutoAcceptance,
    identityAuthenticationAutoAcceptance,
    autoAssignEntityProfileEnabled,
    autoAssignIdentityAuthenticationEnabled,
    autoAssignDocumentAuthenticationEnabled,
    autoAssignBiometricAuthenticationEnabled,
    autoAssignSupportingDocumentPackagesEnabled,
    enablePeriodicReview,
    autoUpdateEntityStatus,
    autoUpdateRiskLevel,
    changeStatusForApprovedProfile,
  } = automation || {};

  const namePrefixWithDot = namePrefix ? `${namePrefix}.` : '';

  const handleEnableChange = useCallback(value => {
    batch(() => {
      change(`${namePrefixWithDot}biometricAuthenticationAutoAcceptance`, value);
      change(`${namePrefixWithDot}documentAuthenticationAutoAcceptance`, value);
      change(`${namePrefixWithDot}identityAuthenticationAutoAcceptance`, value);
      change(`${namePrefixWithDot}autoAssignEntityProfileEnabled`, value);
      change(`${namePrefixWithDot}autoAssignIdentityAuthenticationEnabled`, value);
      change(`${namePrefixWithDot}autoAssignDocumentAuthenticationEnabled`, value);
      change(`${namePrefixWithDot}autoAssignBiometricAuthenticationEnabled`, value);
      change(`${namePrefixWithDot}autoAssignSupportingDocumentPackagesEnabled`, value);
      change(`${namePrefixWithDot}enablePeriodicReview`, value);
      change(`${namePrefixWithDot}autoUpdateEntityStatus`, value);
      change(`${namePrefixWithDot}autoUpdateRiskLevel`, value);
      change(`${namePrefixWithDot}changeStatusForApprovedProfile`, value);
    }, []);

    setAccordionOpen(value);
  }, []);

  useEffect(() => {
    if (
      !biometricAuthenticationAutoAcceptance &&
      !documentAuthenticationAutoAcceptance &&
      !identityAuthenticationAutoAcceptance &&
      !autoAssignEntityProfileEnabled &&
      !autoAssignIdentityAuthenticationEnabled &&
      !autoAssignDocumentAuthenticationEnabled &&
      !autoAssignBiometricAuthenticationEnabled &&
      !autoAssignSupportingDocumentPackagesEnabled &&
      !enablePeriodicReview &&
      !autoUpdateEntityStatus &&
      !autoUpdateRiskLevel &&
      !changeStatusForApprovedProfile
    )
      change(`${namePrefixWithDot}isEnabled`, false);
  }, [
    biometricAuthenticationAutoAcceptance,
    documentAuthenticationAutoAcceptance,
    identityAuthenticationAutoAcceptance,
    autoAssignEntityProfileEnabled,
    autoAssignIdentityAuthenticationEnabled,
    autoAssignDocumentAuthenticationEnabled,
    autoAssignBiometricAuthenticationEnabled,
    autoAssignSupportingDocumentPackagesEnabled,
    enablePeriodicReview,
    autoUpdateEntityStatus,
    autoUpdateRiskLevel,
    changeStatusForApprovedProfile,
  ]);

  const classes = classNames({
    'ickyc-automation--fade': false,
  });
  return (
    <Accordion
      accordionindex={rcuEnums.ACCORDION_INDEXES.AUTOMATION}
      accordionOpen={accordionOpen}
      title={
        <>
          <h3 className={classes}>Automation</h3>
          <span className="ickyc-spacer" />
          <div onClick={utilities.handleOnClickEvent}>
            <Field
              preview
              name={`${namePrefixWithDot}isEnabled`}
              component={ToggleField}
              showCustomText
              label="Enable"
              onChange={handleEnableChange}
            />
          </div>
        </>
      }
    >
      <div className={baseClass}>
        {isLoadingAutomation ? (
          <Spinner />
        ) : (
          <>
            <Section title="Auto Acceptence">
              <AutoAcceptenceForm namePrefix={namePrefix} />
            </Section>
            <Section title="Auto Assignment">
              <AutoAssignmentForm namePrefix={namePrefix} displaySupportingDocumentsSection={false} />
            </Section>
            <ProtectedComponent licenceAccessKey={authEnums.ACCESS_BY_LICENCE.CASES}>
              <Section title="Entity Profile">
                <EntityProfileForm />
              </Section>
            </ProtectedComponent>
          </>
        )}
      </div>
    </Accordion>
  );
};
Automation.propTypes = {
  isLoadingAutomation: PropTypes.string,
};
Automation.defaultProps = {
  isLoadingAutomation: false,
};
export default Automation;
