const defaultValues = {
  message:
    "<p>Hello,</p>\n<p>Please click {{LINK}}</p>\n<p>If link doesn't work, please copy this link to a new tab:</p>\n<p>{{PLAIN LINK}}</p>\n",
  linkText: 'here',
};

const defaultPlaceholders = [
  '{{ENTITY NAME}}',
  '{{LINK}}',
  '{{PLAIN LINK}}',
  '{{LIST OF REQUESTS}}',
  '{{EMAIL}}',
  '{{COMPANY NAME}}',
];
export default { defaultValues, defaultPlaceholders };
