import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import bus from '../../../../../../../modules/bus';
import enums from '../../../../../../../utilities/enums';
import { HistoryRecordContext } from '../../../../../../../utilities/services/contexts';
import CollapsibleTableRow from '../../../../../components/CollapsibleTableRow';
import CachedCollapsible from '../../../CachedCollapsible';
import DocumentsData from '../DocumentsData';
import DocumentsRecord from '../DocumentsRecord';

const DocumentsTableRow = ({ rowInfo, className, onRemove, hasServicesColumn }) => {
  const { issues, status } = rowInfo || {};

  /**
   * When record is being removed, KYC Issues have to be updated
   */
  const handleRemove = useCallback(
    eventId => {
      bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
        type: 'kyc',
        section: enums.ACCORDION_INDEXES.DOCUMENTS,
        issuesChange: -issues,
      });
      onRemove(eventId);
    },
    [onRemove, issues],
  );

  return (
    <HistoryRecordContext.Provider value={rowInfo}>
      <CachedCollapsible
        collapsible={
          <CollapsibleTableRow
            className={className}
            row={<DocumentsRecord className={className} onRemove={handleRemove} hasServices={hasServicesColumn} />}
          />
        }
        initialData={
          status === enums.HISTORY_EVENT_STATUSES.PENDING
            ? { message: `This request is still in progress, please come back later.` }
            : {}
        }
      >
        <DocumentsData onRemove={onRemove} />
      </CachedCollapsible>
    </HistoryRecordContext.Provider>
  );
};
DocumentsTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    assignedUserId: PropTypes.string,
    date: PropTypes.string.isRequired,
    eventHistoryId: PropTypes.string.isRequired,
    issues: PropTypes.number,
    source: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
  className: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  hasServicesColumn: PropTypes.bool,
};
DocumentsTableRow.defaultProps = {
  onRemove: () => {},
  rowInfo: {
    issues: 0,
    assignedUserId: null,
    assignedUserName: null,
  },
  hasServicesColumn: false,
};
export default DocumentsTableRow;
