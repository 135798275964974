import React, { useContext, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import api from '../../../../../api';
import Accordion from '../../../../../components/Accordion';
import Table from '../../../../../components/Tables/Table';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import useTable from '../../../../../hooks/useTable';
import enums from '../../../../../utilities/enums/index';
import { EntityContext } from '../../../../../utilities/services/contexts';
import TableHeaders from '../../../../../utilities/services/TableHeaders';
import AffiliationsTableRow from './components/AffiliationsTableRow';
import './styles.scss';

const AffiliationSection = () => {
  const { entityId } = useContext(EntityContext);
  const containerRef = useRef(null);
  const { isLoading, records, changeParams, pagination, params } = useTable(api.kyc.getAffiliations, { entityId });
  return (
    <Accordion
      title="Affiliations"
      accordionOpen={false}
      accordionindex={enums.ACCORDION_INDEXES.AFFILIATIONS}
      accented
    >
      <Table
        values={records.map(rec => ({ id: uuid(), ...rec }))}
        pagination={pagination}
        tableRow={AffiliationsTableRow}
        headerRow={TableHeader}
        className="ickyc-entities-table"
        withPagination
        updating={isLoading}
        handleParamsChange={changeParams}
        headerData={{
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          items: TableHeaders.AffiliationsTableHeader,
        }}
        containerRef={containerRef}
      />
    </Accordion>
  );
};

export default AffiliationSection;
