import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Field } from 'react-final-form';
import api from '../../../../../api';
import FormModal from '../../../../../components/Modal/FormModal';
import SelectFilterContent from '../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../components/SelectField';
import ToggleField from '../../../../../components/ToggleField';
import history from '../../../../../modules/history';
import enums from '../../../../../utilities/enums';
import './styles.scss';

const CasesReportsModal = ({ hideModal, casesIds, searchTerm }) => {
  const [values] = useState({
    includeAcceptedMatchesForCaseTypeReport: false,
    includeOnlyAcceptedListigs: false,
    format: 'pdf',
  });
  const [submitedSuccessfully, setSubmitedSuccessfully] = useState(false);
  const [reportsIds, setReportsIds] = useState([]);

  const handleSubmit = async configData => {
    configData.type = 'CaseReportConfig';
    configData.searchTerm = searchTerm;

    try {
      if (casesIds?.length === 1) {
        await api.kyc.caseManagement.createCaseReportConfig(casesIds[0], configData);
      } else {
        const {
          data: { ids },
        } = await api.kyc.caseManagement.createBatchCaseReport({ casesIds, ...configData });
        setReportsIds(ids);
      }
      setSubmitedSuccessfully(true);
    } catch (err) {
      if (err?.response) {
        const { status, data } = err.response;
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: data.message };
        }
        if (status === 500) {
          return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
        }
      } else return { [FORM_ERROR]: 'Error occured' };
    }
  };

  const navigateToReports = useCallback(() => {
    history.push(`/administration/files-management/reports`, { ids: reportsIds });
  }, [reportsIds]);

  return (
    <FormModal
      onSubmit={handleSubmit}
      hideModal={hideModal}
      initialValues={values}
      title="Request Report"
      className="ickyc-cases-reports-modal"
      submitButtonText="Request Report"
      showErrorsInConfirmation
      onRejectClick={hideModal}
      showConfirmationForm={submitedSuccessfully}
      onConfirmClick={navigateToReports}
      confirmButtonText="Go to Reports"
      confirmationText="To download requested report please go to Reports in Administration."
      rejectButtonText="Cancel"
    >
      <Field
        component={SelectField}
        name="format"
        label="Format"
        options={enums.EXPORT_REPORT_TYPES}
        Trigger={<SelectTrigger />}
        Content={<SelectFilterContent />}
      />
      <Field
        component={ToggleField}
        name="includeAcceptedMatchesForCaseTypeReport"
        label="Include Only Accepted Matches"
      />
      <Field component={ToggleField} name="includeOnlyAcceptedListigs" label="Include Only Accepted Listings" />
    </FormModal>
  );
};
CasesReportsModal.propTypes = {
  hideModal: PropTypes.func,
  caseId: PropTypes.number,
  searchTerm: PropTypes.string,
};

CasesReportsModal.defaultProps = {
  hideModal: null,
  caseId: 0,
  searchTerm: '',
};

export default CasesReportsModal;
