/* eslint-disable no-shadow */
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import OutlineButton from '../../../../../components/Buttons/OutlineButton';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import FormModal from '../../../../../components/Modal/FormModal';
import General from './components/General';

const NaturalPersonPortalModal = ({ hideModal, initialValues, onSubmit }) => {
  const [isQuickSetup, setIsQuickSetup] = useState(false);

  useEffect(() => {
    localStorage.setItem('icomplyPreview', true);
    return () => localStorage.removeItem('icomplyPreview');
  }, []);

  // eslint-disable-next-line react/prop-types
  const NewPortalSubmitButtons = ({ disabled, onSubmit, showSpinner }) => {
    const setQuickSetup = () => {
      setIsQuickSetup(false);
      setTimeout(() => {
        onSubmit();
      }, 100);
    };
    const setAdvancedSetup = () => {
      setIsQuickSetup(true);
      setTimeout(() => {
        onSubmit();
      }, 100);
    };

    return (
      <div className="ickyc-new-portal-submit-buttons">
        <OutlineButton disabled={disabled} type="submit" onClick={setQuickSetup}>
          Advanced Setup
          {!isQuickSetup && showSpinner && <CircularProgress />}
        </OutlineButton>
        <PrimaryButton disabled={disabled} type="submit" onClick={setAdvancedSetup}>
          Quick Setup (Recommended)
          {isQuickSetup && showSpinner && <CircularProgress />}
        </PrimaryButton>
      </div>
    );
  };

  const CreateNewPortalSubmitButtons = ({ disabled, onSubmit, showSpinner }) => {
    return NewPortalSubmitButtons({
      disabled,
      onSubmit,
      showSpinner,
    });
  };

  const classes = classNames({
    'ickyc-portal-management-modal': true,
    'ickyc-portal-management-modal--new-config': !initialValues.id,
  });

  const handleOnSubmit = async vals => {
    return onSubmit(vals, isQuickSetup);
  };
  return (
    <FormModal
      onSubmit={handleOnSubmit}
      hideModal={hideModal}
      className={classes}
      withFieldArray
      showErrorsInConfirmation
      initialValues={initialValues}
      submitButtons={CreateNewPortalSubmitButtons}
      title={
        <div>
          <h2>New Portal</h2>
        </div>
      }
    >
      <General domainProp={initialValues?.domain} />
    </FormModal>
  );
};
NaturalPersonPortalModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, type: PropTypes.string }),
  onSubmit: PropTypes.func.isRequired,
};
NaturalPersonPortalModal.defaultProps = {
  initialValues: {},
};

export default NaturalPersonPortalModal;
