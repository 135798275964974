import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      d="M20.152 14.638v4.461a1.015 1.015 0 01-.032.247.958.958 0 01-.933.768H.958a.955.955 0 01-.945-.85.751.751 0 01-.013-.171v-4.461a.952.952 0 011.9 0v3.572h16.355v-3.572a.951.951 0 011.624-.673 1 1 0 01.273.679z"
      fill="currentColor"
    />
    <path
      d="M15.564 10.171l-4.676 4.676c-.006.013-.019.019-.025.025a1.1 1.1 0 01-.615.311c-.019 0-.038.006-.057.006a.69.69 0 01-.114.006l-.108-.006c-.019 0-.038-.006-.057-.006a1.082 1.082 0 01-.615-.311l-.025-.025-4.676-4.676A1.113 1.113 0 016.17 8.6l2.811 2.811V1.11A1.116 1.116 0 0110.091 0a1.135 1.135 0 01.787.324 1.1 1.1 0 01.324.787v10.3L14.01 8.6a1.118 1.118 0 011.574 0 1.136 1.136 0 01-.02 1.571z"
      fill="currentColor"
    />
  </svg>
);
