import classNames from 'classnames';
import RadioButton from 'components/RadioButton';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import './styles.scss';

const RadioGroup = ({ label, inline, children, options, name, value: initialValue, onChange, preview }) => {
  const baseClass = 'ickyc-radio-group';
  const classses = classNames({
    [baseClass]: true,
    [`${baseClass}--inline`]: inline,
  });

  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback(
    changeEvent => {
      setValue(changeEvent.target.value);
      onChange && onChange(changeEvent.target.value);
    },
    [onChange],
  );

  return (
    <div className={classses}>
      {label && <label>{label}</label>}
      <div className={`${baseClass}__content`}>
        {children ||
          options.map(option => (
            <RadioButton
              name={name}
              {...option}
              onChange={handleChange}
              checked={value === option.value}
              disabled={preview && value !== option.value}
            />
          ))}
      </div>
    </div>
  );
};

RadioGroup.propTypes = {
  label: PropTypes.string,
  inline: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
RadioGroup.defaultProps = {
  label: undefined,
  inline: false,
};
export default RadioGroup;
