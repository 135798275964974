import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import RangeSlider from '../../../../../components/RangeSlider';
import Section from '../../../../../components/Section';
import FieldCountriesList from '../../../../../components/Tables/FieldCountriesList';
import FaceMatchControlButtons from '../../../components/FaceMatchControlButtons';
import FaceMatchRadioButtons from '../../../components/FaceMatchRadioButtons';
import FaceExpressionsSection from '../FaceExpressionsSection';
import './styles.scss';

const BiometricAuthenticationSettings = ({
  biomtricConfidenceSlider,
  componentClass,
  prefix,
  index,
  showRejectionMessageField,
  disabled,
}) => {
  return (
    <>
      <h2>Live Face Match or Selfie Upload by Jurisdiction</h2>
      <FieldCountriesList
        TableControlsComponent={FaceMatchControlButtons}
        modulePreffix="biometricRestrictions"
        tableRow={FaceMatchRadioButtons}
        withInfiniteScroll
        disabled={disabled}
      />
      {biomtricConfidenceSlider && (
        <Section className="ickyc-biometric-authentication-settings__liveness-confidence">
          <h2>Biometric Confidence</h2>
          <Field name={`${prefix}.biometricConfidenceThreshold`}>
            {({ input }) => (
              <RangeSlider
                label="Pass when facial match score is above:"
                initialValue={input.value}
                callback={input.onChange}
                disabled={disabled}
              />
            )}
          </Field>
        </Section>
      )}

      <Section>
        <FaceExpressionsSection
          index={index}
          baseClass={componentClass}
          showRejectionMessageField={showRejectionMessageField}
          facialExpressionFieldsPrefixName={prefix}
          disabled={disabled}
        />
      </Section>
    </>
  );
};
BiometricAuthenticationSettings.propTypes = {
  biomtricConfidenceSlider: PropTypes.bool,
  componentClass: PropTypes.string,
  prefix: PropTypes.string,
  index: PropTypes.number,
  showRejectionMessageField: PropTypes.bool,
  disabled: PropTypes.bool,
};
BiometricAuthenticationSettings.defaultProps = {
  biomtricConfidenceSlider: false,
  componentClass: 'ickyc-biometric-authentication',
  prefix: '',
  index: 4,
  showRejectionMessageField: false,
  disabled: false,
};
export default BiometricAuthenticationSettings;
