import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Field, useFormState } from 'react-final-form';
import Accordion from '../../../../../../../../../../components/Accordion';
import CustomContentRadio from '../../../../../../../../../../components/CustomContentRadio';
import RadioButtonField from '../../../../../../../../../../components/RadioButtonField';
import ExistingEntitiesTableRow from '../../../../../../../../../../components/Select/components/ExistingEntitiesTableRow';
import SelectContentTable from '../../../../../../../../../../components/Select/components/SelectContentTable';
import SelectTrigger from '../../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../../components/SelectField';
import TableHeader from '../../../../../../../../../../components/Tables/Table/components/TableHeader';
import enums from '../../../../../../../../../../utilities/enums';
import TableHeaders from '../../../../../../../../../../utilities/services/TableHeaders';
import validators from '../../../../../../../../../../utilities/services/validators';
import LegalEntityInformationForm from '../../../../../../../components/LegalEntityInformationForm';
import NaturalPersonInformationForm from '../../../../../../../components/NaturalPersonformationForm';
import './index.scss';

const StepOne = ({
  npEntities,
  changeNPParams,
  npParams,
  npLoading,
  leEntities,
  changeLEParams,
  leParams,
  leLoading,
  lePagination,
  npPagination,
}) => {
  const {
    values: { entityType, existingEntityType },
  } = useFormState();
  const isExistingChecked = entityType === enums.AFFILIATED_MODAL_ENTITY_OPTIONS.EXISTING;
  const isLegalChecked = entityType === enums.AFFILIATED_MODAL_ENTITY_OPTIONS.NEW_LE;
  const isNaturalChecked = entityType === enums.AFFILIATED_MODAL_ENTITY_OPTIONS.NEW_NP;
  const convertToNumber = useCallback(value => Number(value), []);

  return (
    <div className="ickyc-important-personnel-step-one">
      <Accordion
        title={
          <Field
            name="entityType"
            type="radio"
            parse={convertToNumber}
            component={RadioButtonField}
            label={<h2>Choose Existing Entity</h2>}
            value={enums.AFFILIATED_MODAL_ENTITY_OPTIONS.EXISTING}
          />
        }
        controledOpen
        withComponentUnmount
        accordionOpen={isExistingChecked}
      >
        <div className="existing-entity-types">
          <Field
            component={CustomContentRadio}
            type="radio"
            name="existingEntityType"
            parse={convertToNumber}
            value={0}
          >
            <Field
              component={SelectField}
              Trigger={<SelectTrigger placeholder="Choose Natural Person" />}
              Content={
                <SelectContentTable
                  tableProps={{
                    tableRow: ExistingEntitiesTableRow,
                    headerRow: TableHeader,
                    handleParamsChange: changeNPParams,
                    pagination: npPagination,
                    headerData: {
                      sortCriteria: npParams.sortCriteria,
                      sortOrder: npParams.sortOrder,
                      onClick: changeNPParams,
                      items: TableHeaders.ExistingEntities,
                    },
                    updating: npLoading,
                    withLoadMore: true,
                    withPagination: true,
                    className: 'ickyc-existing-entities-table',
                  }}
                />
              }
              renderAsPortal
              name="existingNPId"
              options={npEntities}
              validate={validators.validateCustomRadioComponent('Required', 'existingEntityType', 0)}
            />
          </Field>
          <Field
            component={CustomContentRadio}
            type="radio"
            name="existingEntityType"
            value={1}
            parse={convertToNumber}
          >
            <Field
              Trigger={<SelectTrigger placeholder="Choose Legal Entity" />}
              component={SelectField}
              Content={
                <SelectContentTable
                  tableProps={{
                    tableRow: ExistingEntitiesTableRow,
                    headerRow: TableHeader,
                    handleParamsChange: changeLEParams,
                    pagination: lePagination,
                    headerData: {
                      sortCriteria: leParams.sortCriteria,
                      sortOrder: leParams.sortOrder,
                      onClick: changeLEParams,
                      items: TableHeaders.ExistingEntities,
                    },
                    updating: leLoading,
                    withLoadMore: true,
                    withPagination: true,
                    className: 'ickyc-existing-entities-table',
                  }}
                />
              }
              name="existingLEId"
              options={leEntities}
              disabled={!existingEntityType}
              renderAsPortal
              validate={validators.validateCustomRadioComponent('Required', 'existingEntityType', 1)}
            />
          </Field>
        </div>
      </Accordion>
      <Accordion
        title={
          <Field
            name="entityType"
            type="radio"
            parse={convertToNumber}
            component={RadioButtonField}
            label={<h2>Create New Natural Person</h2>}
            value={enums.AFFILIATED_MODAL_ENTITY_OPTIONS.NEW_NP}
          />
        }
        controledOpen
        withComponentUnmount
        accordionOpen={isNaturalChecked}
      >
        <NaturalPersonInformationForm preview={!isNaturalChecked} />
      </Accordion>
      <Accordion
        title={
          <Field
            type="radio"
            name="entityType"
            label={<h2>Create New Legal Entity</h2>}
            value={enums.AFFILIATED_MODAL_ENTITY_OPTIONS.NEW_LE}
            parse={convertToNumber}
            component={RadioButtonField}
          />
        }
        controledOpen
        withComponentUnmount
        accordionOpen={isLegalChecked}
      >
        <LegalEntityInformationForm preview={!isLegalChecked} />
      </Accordion>
    </div>
  );
};

StepOne.propTypes = {
  npEntities: PropTypes.arrayOf(PropTypes.shape({})),
  changeNPParams: PropTypes.bool,
  npLoading: PropTypes.bool,
  npParams: PropTypes.shape({
    sortCriteria: PropTypes.string,
    sortOrder: PropTypes.number,
  }),
  leEntities: PropTypes.arrayOf(PropTypes.shape({})),
  changeLEParams: PropTypes.bool,
  leLoading: PropTypes.bool,
  leParams: PropTypes.shape({
    sortCriteria: PropTypes.string,
    sortOrder: PropTypes.number,
  }),
  lePagination: PropTypes.shape({
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
    total: PropTypes.number,
  }),
  npPagination: PropTypes.shape({
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    limit: PropTypes.number,
    skip: PropTypes.number,
    total: PropTypes.number,
  }),
};
StepOne.defaultProps = {
  npEntities: [],
  changeNPParams: () => {},
  npLoading: false,
  npParams: {},
  leEntities: [],
  changeLEParams: () => {},
  leLoading: false,
  leParams: {},
  lePagination: {},
  npPagination: {},
};
export default StepOne;
