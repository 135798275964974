import PropTypes from 'prop-types';
import React from 'react';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';

const UsageTableRow = ({ rowInfo: { kycService, count }, className }) => {
  const setColumnSuffix = setClassSuffix(`${className}__column`);
  return (
    <tr key={kycService} className={`${className}__row`}>
      <td className={setColumnSuffix('__kycService')}>
        <span>{kycService}</span>
      </td>
      <td className={setColumnSuffix('__usage')}>
        <span>{count}</span>
      </td>
    </tr>
  );
};
UsageTableRow.propTypes = {
  rowInfo: PropTypes.shape({
    kycService: PropTypes.string,
    count: PropTypes.number,
  }),
  className: PropTypes.string,
};

UsageTableRow.defaultProps = {
  className: '',
  rowInfo: {},
};
export default UsageTableRow;
