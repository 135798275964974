import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import api from '../../../api';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import InputField from '../../../components/InputField';
import Spinner from '../../../components/Spinner';
import history from '../../../modules/history';
import TokenStorage from '../../../utilities/services/local-storage/TokenStorage';
import RecoveryCodes from './components/RecoveryCodes';
import './styles.scss';

const EnableAuthenticatorTFA = () => {
  const baseClass = 'ickyc-form';
  const [code, setCode] = useState('');
  const [authenticatorUri, setAuthenticatorUri] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [submitButtonText, setSubmitButtonText] = useState('Verify');
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  useEffect(() => {
    if (token) {
      TokenStorage.token = token;
    }
  }, [token]);

  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      return api.auth
        .getEnableTwoFactorAuthenticationAnonymus()
        .then(res => {
          setCode(res.data.sharedKey);
          setAuthenticatorUri(res.data.authenticatorUri);
        })
        .catch(err => {
          if (err?.response) {
            const { status, data: errorData } = err.response;
            if (status >= 400 && status < 500) {
              return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
            }
            if (status === 500) {
              return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
            }
          } else return { [FORM_ERROR]: 'Error occured while trying to get Authentication Info' };
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (TokenStorage.token) {
      getData();
    }
  }, []);

  const handleSaveChanges = async values => {
    if (submitButtonText === 'Continue') {
      return setTimeout(() => {
        history.push('/auth/login');
      }, 1000);
    }
    return api.auth
      .enableTwoFactorAuthenticationAnonymus({ ...values, token: TokenStorage.token })
      .then(({ data: responseDAta }) => {
        const { recoveryCodes: recCodes } = responseDAta;
        setRecoveryCodes(recCodes);
        setSubmitButtonText('Continue');
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to get Authentication Info' };
      });
  };
  const renderErrors = err => {
    return (
      <div className="ickyc-enable-two-factor-authentication-page__error">
        {Array.isArray(err) ? (
          <ul>
            {err.map(e => (
              <li>{e}</li>
            ))}
          </ul>
        ) : (
          err
        )}
      </div>
    );
  };
  return !token ? (
    <h3 className="ickyc-widget__global-message">No Token Present!</h3>
  ) : (
    <FinalForm
      onSubmit={handleSaveChanges}
      validate={values => {
        const errors = {};
        if (!values.twoFactorCode) {
          errors.twoFactorCode = 'Required';
        }
        return errors;
      }}
    >
      {({ submitting, handleSubmit, submitError }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className={classNames(baseClass, 'ickyc-enable-two-factor-authentication-page')}
          >
            {isLoading ? (
              <div className="ickyc-enable-two-factor-authentication-page__spinner-container">
                <Spinner />
              </div>
            ) : (
              <>
                <div className={`${baseClass}__fields ${baseClass}__fields--tfa ${baseClass}__fields--rounded`}>
                  <h2>Enable Authenticator</h2>
                  <h3>To authenticate go through following steps:</h3>
                  <ol>
                    <li>
                      Download a two factor authenticator app like Microsoft Authenticator for Windows Phone, Android
                      and iOS or Google Authenticator for Android and iOS.
                    </li>
                    <li>
                      Scan the QR code or enter this key
                      <br /> <b>{code}</b>
                      <br /> into your two factor authenticator app
                      <div className="ickyc-enable-two-factor-authentication-page__qr">
                        <QRCode value={authenticatorUri} />
                      </div>
                    </li>

                    <li>
                      Once you have scanned the QR code or input the key above, your two factor authenticator app will
                      provide you with a unique code. Enter the code in the confirmation box below.
                      <Field component={InputField} name="twoFactorCode" label="Verification Code" autocomplete="off" />
                    </li>
                  </ol>

                  {submitError && renderErrors(submitError)}
                  {recoveryCodes.length !== 0 && (
                    <RecoveryCodes
                      successMessage="You successfully configured your authenticator app!"
                      recoveryCodes={recoveryCodes}
                    />
                  )}
                </div>

                <div className={`${baseClass}__actions`}>
                  <PrimaryButton type="submit" disabled={submitting}>
                    {submitButtonText}
                    {submitting && <CircularProgress />}
                  </PrimaryButton>
                </div>
              </>
            )}
          </form>
        );
      }}
    </FinalForm>
  );
};

EnableAuthenticatorTFA.propTypes = {};

EnableAuthenticatorTFA.defaultProps = {};

export default EnableAuthenticatorTFA;
