import { useEffect } from 'react';

const useResizeResposition = ({ dropdownRef, renderAsPortal }) => {
  useEffect(() => {
    const setCoords = () => {
      if (!!renderAsPortal || !dropdownRef.current) return;
      const { top: dropdownTopOffset, left: dropdownLeftOffset } = dropdownRef.current.getBoundingClientRect();

      const topOffset = dropdownTopOffset + window.scrollY;
      let leftOffset = dropdownLeftOffset + window.scrollX;

      const trigger = dropdownRef.current.querySelector('div');
      const content = document.body.querySelector('.ickyc-portal > div');
      const { width: triggerWidth } = trigger.getBoundingClientRect();

      leftOffset += triggerWidth;

      content.style.top = `${topOffset}px`;
      content.style.left = `${leftOffset}px`;
    };

    const el = document.body.querySelector('.ickyc-page');
    if (el) {
      el.addEventListener('resize', setCoords);

      const cleanUp = () => {
        el.removeEventListener('resize', setCoords);
      };

      return cleanUp;
    }
  }, [dropdownRef, renderAsPortal]);
};

export default useResizeResposition;
