export const TOKEN_SET = 'TOKEN_SET';
export const PERSONAL_INFO_SET = 'PERSONAL_INFO_SET';
export const PERSONAL_INFO_UPDATE = 'PERSONAL_INFO_UPDATE';
export const USER_PERMISSIONS_SET = 'USER_PERMISSIONS_SET';
export const USER_TYPE = 'USER_TYPE';

export const setToken = payload => ({
  type: TOKEN_SET,
  payload,
});

export const setPersonalInfo = payload => ({
  type: PERSONAL_INFO_SET,
  payload,
});

export const updatePersonalInfo = payload => ({
  type: PERSONAL_INFO_UPDATE,
  payload,
});

export const setUserPermissions = payload => ({
  type: USER_PERMISSIONS_SET,
  payload,
});

export const setUserType = payload => ({
  type: USER_TYPE,
  payload,
});
