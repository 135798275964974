export default function errorMessage(err) {
  if (err?.response) {
    const { status, data: errorData } = err.response;
    if (status >= 400 && status < 500) {
      return errorData.error || `${errorData?.message?.slice(0, 80)}...`;
    }
    if (status === 500) {
      return 'Internal Server Error, Try Again Later';
    }
  } else return 'Error occured';
}
