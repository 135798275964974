import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import OutlineButton from '../../../../components/Buttons/OutlineButton';
import Toggle from '../../../../components/Toggle';
import useModalHandler from '../../../../hooks/useModalHandler';
import IconManager from '../../../../utilities/services/IconManager';

const FilterToggleButton = ({ filters, filterOn, onToggleFilter, children }) => {
  const { isOpen, close, open } = useModalHandler();
  const handleToggle = useCallback(() => {
    onToggleFilter();
    if (filterOn) {
      close();
    } else {
      open();
    }
  }, [filterOn, onToggleFilter, close, open]);

  return (
    <OutlineButton onClick={open} active={filterOn}>
      <Toggle value={filterOn} checked={!!filterOn} onChange={handleToggle} />
      {IconManager.get(IconManager.names.FUNNEL)}
      Filter
      {isOpen && <span className="ickyc-modal-arrow" />}
      {isOpen &&
        React.cloneElement(children, {
          hideModal: close,
          initialFilters: filters,
        })}
    </OutlineButton>
  );
};
FilterToggleButton.propTypes = {
  filters: PropTypes.shape({}),
  filterOn: PropTypes.bool,
  onToggleFilter: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
FilterToggleButton.defaultProps = {
  filterOn: false,
  onToggleFilter: () => {},
  filters: {},
};
export default FilterToggleButton;
