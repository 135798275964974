import { FORM_ERROR } from 'final-form';
import React, { useState } from 'react';
import toastr from 'toastr';
import api from '../../../../../api';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import FormModal from '../../../../../components/Modal/FormModal';
import Table from '../../../../../components/Tables/Table';
import TableHeader from '../../../../../components/Tables/Table/components/TableHeader';
import useModalHandler from '../../../../../hooks/useModalHandler';
import useTable from '../../../../../hooks/useTable';
import TableHeaders from '../../../../../utilities/services/TableHeaders';
import { WEB_HOOK_EVENT_TYPES } from '../../enums';
import MyWebHooksRow from './components/MyWebHooksRow';
import WebHookForm from './components/WebHookForm';
import './styles.scss';

const MyWebHooks = () => {
  const { isLoading, removeRow, records, addRow, changeParams, pagination, updateRowData, params } = useTable(
    api.administration.webhooks.getWebHooks,
    { sortCriteria: 'types', limit: 5, skip: 0 },
  );

  const { isOpen: isModalOpen, open: openModal, close: closeModal } = useModalHandler();
  const [initialValues, setInitialValues] = useState({});

  const onRemoveHandler = id => {
    removeRow('id', id);
  };

  const onRowClick = id => {
    const webHook = records.find(webhook => webhook.id === id);
    const { types, ...rest } = webHook;
    setInitialValues({
      ...rest,
      types: types?.split('|').map(el => WEB_HOOK_EVENT_TYPES.find(elm => elm.label === el)?.value),
    });
    openModal();
  };

  const handleOpenModal = () => {
    setInitialValues({});
    openModal();
  };

  const handleSubmit = async values => {
    const { id, name, endpoint, types, requestUrl } = values;

    try {
      if (id) {
        const { data: responseData } = await api.administration.webhooks.updateWebHook({
          id,
          name,
          endpoint,
          types,
          requestUrl,
        });
        updateRowData('id', id, responseData);

        toastr.success('Successfully updated Web Hook.');
      } else {
        const { data: responseData } = await api.administration.webhooks.createNewWebHook({
          name,
          endpoint,
          types,
          requestUrl,
        });
        addRow(responseData);

        toastr.success('Successfully created Web Hook.');
      }
      closeModal();
    } catch (err) {
      if (err?.response) {
        const { status, data: errorData } = err.response;
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: errorData.message };
        }
        if (status === 500) {
          return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
        }
      } else return { [FORM_ERROR]: 'Error occured while creating web hook' };
    }
  };

  const TableControls = () => {
    return (
      <>
        <h3>My Web Hooks</h3>
        <PrimaryButton onClick={handleOpenModal}>Create New Web Hook</PrimaryButton>
      </>
    );
  };
  return (
    <div className="ickyc-web-hooks">
      <Table
        values={records}
        tableControls={TableControls}
        pagination={pagination}
        tableRow={MyWebHooksRow}
        headerRow={TableHeader}
        onClick={onRowClick}
        onRemove={onRemoveHandler}
        onUpdate={updateRowData}
        className="ickyc-web-hooks-table"
        withPagination
        updating={isLoading}
        handleParamsChange={changeParams}
        headerData={{
          withDelete: true,
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          onClick: changeParams,
          items: TableHeaders.MyWebHooksTableHeader,
        }}
      />

      {isModalOpen && (
        <FormModal
          initialValues={initialValues}
          title="Create A New Web Hook"
          hideModal={closeModal}
          showErrorsInConfirmation
          className="ickyc-web-hooks-modal"
          onSubmit={handleSubmit}
        >
          <WebHookForm />
        </FormModal>
      )}
    </div>
  );
};
export default MyWebHooks;
