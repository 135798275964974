import { Cancel } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

/**
 * Name and the Close button with typical tag styles
 * @param {function} onClick - whole button click handle
 * @param {string} text - name placed inside the tag
 * @param {string} className - class name of root div
 * @param {function} onIconClick - icon click handler
 * @param {object} input - DO NOT pass prop manually, this is generated by Final-Form's Field component
 */

const TagButton = ({
  onClick,
  text,
  onIconClick,
  icon,
  className,
  disabled,
  preview,
  red,
  outline,
  gray,
  filledRed,
}) => {
  const classes = classNames({
    'ickyc-tag-button': true,
    [className]: className,
    'ickyc-tag-button--disabled': disabled,
    'ickyc-tag-button--red': red,
    'ickyc-tag-button--outline': outline,
    'ickyc-tag-button--gray': gray,
    'ickyc-tag-button--red-filled': filledRed,
  });
  const handleClick = e => {
    if (!preview) {
      onClick(e);
    }
  };
  return (
    <div className={classes} onClick={handleClick}>
      {text && <div>{text}</div>}
      {!preview && (
        <button onClick={onIconClick} type="button">
          {icon}
        </button>
      )}
    </div>
  );
};

TagButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),

  onIconClick: PropTypes.func,
  icon: PropTypes.element,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  preview: PropTypes.bool,
  red: PropTypes.bool,
  gray: PropTypes.bool,
  outline: PropTypes.bool,
  filledRed: PropTypes.bool,
};

TagButton.defaultProps = {
  onClick: () => {},
  onIconClick: () => {},
  text: '',
  icon: <Cancel />,
  className: '',
  preview: false,
  disabled: false,
  red: false,
  gray: false,
  outline: false,
  filledRed: false,
};

export default TagButton;
