import { Lock } from '@material-ui/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { useParams } from 'react-router';
import { DragContext } from 'utilities/services/contexts';
import validators from 'utilities/services/validators';
import api from '../../../../../../../../../api';
import InputField from '../../../../../../../../../components/InputField';
import SelectContent from '../../../../../../../../../components/Select/components/SelectContent';
import SelectTrigger from '../../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../../components/SelectField';
import ToggleField from '../../../../../../../../../components/ToggleField';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';

const NaturalPersonFlowModule = ({ index, indexToShow, isDropAllowed, hasModuleError }) => {
  const [domainOptions, setDomainOptions] = useState(null);
  const { setDropCallback, setDragList } = useContext(DragContext);
  const { clientId } = useParams();

  const className = 'ickyc-table';

  const { change } = useForm();

  const {
    input: { value: toggleValue },
  } = useField(`modules[${index}].isEnabled`);

  const {
    input: { onChange: onChangeNpConfigId },
  } = useField(`modules[${index}].npConfigId`);

  const {
    input: { value: isEnabled },
  } = useField(`modules[${index}].isEnabled`);

  const classes = classNames([{ 'ickyc-final-step-white-section': indexToShow % 2 === 0 }, className]);

  useEffect(() => {
    (async () => {
      try {
        let response = null;

        if (clientId) {
          response = await api.businessManager.getDomainsForNaturalPerson(clientId);
        } else {
          response = await api.administration.portalManagement.getDomainsForNaturalPerson();
        }

        setDomainOptions(response.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const mappedDomainsList = useMemo(
    () =>
      (domainOptions || [])
        ?.reduce((acc, domain) => [...acc, { url: domain?.domains[0]?.url, id: domain?.id }], [])
        .map(domain => ({
          id: domain.id,
          value: domain.url,
          label: domain.url,
        })),
    [domainOptions],
  );

  const dropCallback = useCallback(
    reorderedList => {
      const isNPFLast = reorderedList[reorderedList.length - 1]?.isNaturalPersonFlowModule;
      change(`modules[${index}].nestedNpFlowLast`, !!isNPFLast);
      setDragList(reorderedList);
    },
    [index, setDragList],
  );

  useEffect(() => {
    setDropCallback(() => dropCallback);
  }, [dropCallback]);

  return (
    <CollapsibleTableRow
      isDropAllowed={isDropAllowed}
      className={classes}
      rowClassName={hasModuleError ? 'ickyc-table__row--error' : ''}
      withIndexColumn
      index={indexToShow}
      icon={!isEnabled && <Lock />}
      isDraggable={isEnabled}
      isToggled={toggleValue}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Natural Person Flow</td>
          <td className={`${className}__column__enabled`} onClick={e => e.stopPropagation()}>
            <Field
              preview
              name={`modules[${index}].isEnabled`}
              component={ToggleField}
              showCustomText
              toggleOnText="Enabled"
            />
          </td>
        </>
      }
    >
      <td className={`${className}__column__title`}>
        <Field
          component={SelectField}
          name={`modules[${index}].npDomain`}
          Trigger={
            <SelectTrigger placeholder={`${domainOptions ? 'Choose Natural Person Portal' : 'Loading domains...'}`} />
          }
          onChange={e => {
            const configId = mappedDomainsList.find(domain => domain?.value === e)?.id;
            onChangeNpConfigId(configId || null);
          }}
          Content={<SelectContent />}
          options={[{ id: 'something', value: null, label: 'Unassign Portal' }, ...mappedDomainsList]}
          validate={validators.required()}
        />
      </td>
    </CollapsibleTableRow>
  );
};
NaturalPersonFlowModule.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
};
export default NaturalPersonFlowModule;
