import React from 'react';
import routesEnum from '../../../../utilities/enums/routesEnum';
import IconManager from '../../../../utilities/services/IconManager';
import ColoredText from '../components/ColoredText';

const NOTIFICATION_TYPES = {
  ENTITY_ASSIGNED: 0,
  CASE_ASSIGNED: 1,
};

const NOTIFICATION_TITLE_PLACEHOLDER_BASED_ON_TYPE = {
  [NOTIFICATION_TYPES.ENTITY_ASSIGNED]: 'Entity assigned:',
  [NOTIFICATION_TYPES.CASE_ASSIGNED]: 'Case assigned:',
};

const PATH_BASED_ON_TYPE = {
  [NOTIFICATION_TYPES.ENTITY_ASSIGNED]: routesEnum.kyc.ENTITY_MANAGEMENT,
  [NOTIFICATION_TYPES.CASE_ASSIGNED]: routesEnum.kyc.CASE_MANAGEMENT,
};

const getPathBasedOnType = (type, headerItemForwardPath) =>
  headerItemForwardPath ? `${PATH_BASED_ON_TYPE[type]}/${headerItemForwardPath}` : PATH_BASED_ON_TYPE[type];

const ICON_BASED_ON_TYPE = {
  [NOTIFICATION_TYPES.ENTITY_ASSIGNED]: IconManager.get(IconManager.names.NATURAL_PERSON),
  [NOTIFICATION_TYPES.CASE_ASSIGNED]: IconManager.get(IconManager.names.CASE),
};

const NOTIFICATION_STATUSES = {
  UNREAD: 0,
  UNREAD_MANUALLY: 1,
  READ: 2,
  ARCHIVE: 3,
  DELETE: 4,
};

const replacePlaceholders = (contentPlaceholder, values) => {
  const parts = contentPlaceholder.split(/(\{\{.*?\}\})/);

  return parts.map((part, index) => {
    if (part.startsWith('{{') && part.endsWith('}}')) {
      const key = part.replace(/\{\{|\}\}/g, '');
      const replacementText = key === 'you' ? 'You' : values[key];
      return <ColoredText key={index} text={replacementText} />;
    } else {
      // Render regular text parts
      return part;
    }
  });
};

export default {
  NOTIFICATION_TYPES,
  NOTIFICATION_STATUSES,
  NOTIFICATION_TITLE_PLACEHOLDER_BASED_ON_TYPE,
  ICON_BASED_ON_TYPE,
  getPathBasedOnType,
  replacePlaceholders,
};
