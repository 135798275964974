import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import toastr from 'toastr';
import api from '../../../../../../api';
import FormModal from '../../../../../../components/Modal/FormModal';
import RecoveryCodes from '../../../../../Auth/EnableAuthenticatorTFA/components/RecoveryCodes';
import './styles.scss';

const ResetRecoveryCodesModal = ({ hideModal }) => {
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [submitButtonText, setSubmitButtonText] = useState('Reset');
  const handleSubmit = () => {
    if (submitButtonText === 'Close') {
      return hideModal();
    }
    return api.auth
      .resetRecoveryCodes()
      .then(res => {
        setRecoveryCodes(res.data);
        toastr.success('Recovery codes successfully reseted!');
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.message || `${errorData.message?.slice(0, 80)}...` };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to reset codes' };
      })
      .finally(() => {
        setSubmitButtonText('Close');
      });
  };
  return (
    <FormModal
      onSubmit={handleSubmit}
      //   initialValues={initialValues}
      hideModal={hideModal}
      title="Reset Recovery Codes"
      className="ickyc-reset-recovery-codes-modal"
      submitButtonText={submitButtonText}
      showErrorsInConfirmation
    >
      <h3> If you lose your device and don't have the recovery codes you will lose access to your account.</h3>
      <span>
        Generating new recovery codes does not change the keys used in authenticator apps. If you wish to change the key
        used in an authenticator app you should reset your authenticator keys.
      </span>

      {recoveryCodes.length !== 0 && (
        <RecoveryCodes successMessage=" You successfully reseted recovery codes!" recoveryCodes={recoveryCodes} />
      )}
    </FormModal>
  );
};
ResetRecoveryCodesModal.propTypes = {
  hideModal: PropTypes.func,
};
ResetRecoveryCodesModal.defaultProps = {
  hideModal: null,
};
export default ResetRecoveryCodesModal;
