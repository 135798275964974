import classNames from 'classnames';
import ModalTabHeader from 'components/Headers/ModalTabHeader';
import BareHeading from 'components/Headings/BareHeading';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import EmailALink from './components/EmailALink';
import RCU from './components/RCU';

const EmailsPage = ({ className }) => {
  const [activeTab, setActiveTab] = useState(0);
  const classes = classNames('ickyc-page', 'ickyc-emails', className);
  const tabOptions = [
    { label: 'RCU: Natural Person', key: 'rcu' },
    { label: 'Email A Link', key: 'eal' },
  ];
  const screens = [<RCU />, <EmailALink />];

  const handleOptionClick = useCallback(
    index => {
      setActiveTab(index);
    },
    [setActiveTab],
  );

  return (
    <div className={classes}>
      <BareHeading title="Emails" />
      <div className="ickyc-page__content">
        <ModalTabHeader onClick={handleOptionClick} options={tabOptions} />
        <div className="ickyc-emails-page__active-screen">{screens[activeTab]}</div>
      </div>
    </div>
  );
};

EmailsPage.propTypes = {
  className: PropTypes.string,
};

EmailsPage.defaultProps = {
  className: undefined,
};

export default EmailsPage;
