import PrimaryButton from 'components/Buttons/PrimaryButton';
import PropTypes from 'prop-types';
import React from 'react';

import './styles.scss';

const headingCards = [
  {
    title: 'Core',
    items: ['User Management', 'Files Management', 'Notifications'],
  },
  {
    title: 'Due Diligence',
    items: ['Entities', 'Supporting Documents', 'Risk Levels'],
  },
  {
    title: 'AML',
    items: ['Cases', 'Risk Screening', 'Ongoing Monitoring'],
  },
  {
    title: 'Onboarding',
    items: ['Portals', 'Portal Management', 'Email Settings'],
  },
  {
    title: 'Advanced Workflow',
    items: ['Automations', 'Risk Scoring', 'Quick Setup Settings'],
  },
  {
    title: 'Technical',
    items: ['Webhooks', 'API'],
  },
];

const CardsHeading = ({ onButtonClick }) => {
  return (
    <div className="ickyc-cards-heading">
      <h1>Licenses</h1>

      <div className="ickyc-cards-heading__cards">
        {headingCards.map(({ title, items }, index) => (
          <div key={index}>
            <h2>{title}</h2>
            <ul>
              {items.map((item, ind) => (
                <li key={ind}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <hr />

      <PrimaryButton onClick={onButtonClick}>{<>+ Add License</>}</PrimaryButton>
    </div>
  );
};

CardsHeading.propTypes = {
  onButtonClick: PropTypes.func,
};

export default CardsHeading;
