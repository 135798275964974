import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setNotifications } from 'store/actions/global.action';
import { selectNotifications } from 'store/selectors/global.selector';
import socketKeys from 'utilities/enums/socketKeys';
import SignalRService from 'utilities/services/SignalRService';
import useSelect from './useSelect';

const listenToAllNotificationsEvents = keyCallbackPairs => {
  Object.entries(keyCallbackPairs).forEach(([key, callback]) => {
    SignalRService.receiveMessage(key, callback);
  });
};

const useNotifications = () => {
  const notifications = useSelect(selectNotifications);
  const dispatch = useDispatch();

  const updateNotifications = useCallback(
    newNotification => {
      let tempNotifications = { ...notifications };
      const existingNotificationIndex = notifications.data.findIndex(
        notification => notification.id === newNotification.id,
      );
      if (existingNotificationIndex !== -1) {
        // Update the activityLogs of the existing notification
        tempNotifications.data[existingNotificationIndex].items.activityLogs = newNotification.items.activityLogs;
      } else {
        // Add the new notification
        tempNotifications.data = [newNotification, ...tempNotifications.data];
        tempNotifications.unreadNotificationsCount += 1;
        tempNotifications.paginationData = {
          ...tempNotifications.paginationData,
          realTimeAddition: (tempNotifications.paginationData.realTimeAddition || 0) + 1,
          total: tempNotifications.paginationData.total + 1,
        };
      }
      dispatch(setNotifications(tempNotifications));
    },
    [notifications],
  );

  // Add more keyCallbackPairs here to add more events
  const keyCallbackPairs = useMemo(
    () => ({
      [socketKeys.ENTITY_ASSIGNED]: updateNotifications,
      [socketKeys.CASE_ASSIGNED]: updateNotifications,
    }),
    [updateNotifications],
  );

  useEffect(() => {
    listenToAllNotificationsEvents(keyCallbackPairs);
  }, [keyCallbackPairs]);
};
export default useNotifications;
