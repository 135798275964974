import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import api from '../../../../api';
import Card from '../../../../components/Card';
import Spinner from '../../../../components/Spinner';
import Table from '../../../../components/Tables/Table';
import TableHeader from '../../../../components/Tables/Table/components/TableHeader';
import TilesTableRow from '../components/TilesTableRow';

const MyOpenCases = ({ row, column }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({ sortOrder: 0, limit: 5 });
  const [pagination, setPagination] = useState({});

  const handlePaginationChange = values => {
    setParams(prev => ({ ...prev, ...values }));
  };

  useEffect(() => {
    const fetchSummaryData = () => {
      setIsLoading(true);
      api.homescreen
        .getMyOpenCases(params)
        .then(({ data: response }) => {
          setData(response.data);
          setPagination(response.paginationData);
        })
        .finally(() => setIsLoading(false));
    };
    fetchSummaryData();
  }, [params]);

  return (
    <Card text="My Open Cases" row={row} column={column}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          values={data}
          headerRow={TableHeader}
          pagination={pagination}
          tableRow={TilesTableRow}
          headerData={{
            items: [
              {
                type: 'name',
              },
              {
                label: 'Date',
                clickable: true,
                type: 'date',
              },
            ],
            sortOrder: params.sortOrder,
            sortCriteria: params.sortCriteria,
          }}
          withPagination
          handleParamsChange={handlePaginationChange}
        />
      )}
    </Card>
  );
};

MyOpenCases.propTypes = {
  row: PropTypes.number.isRequired,
  column: PropTypes.number.isRequired,
};
export default MyOpenCases;
