import React from 'react';

export default () => (
  <svg viewBox="0 0 89.7 90.7" fill="currentColor">
    <g transform="matrix(1.34134 0 0 1.34134 1851.3748 26913.772)">
      <path d="M-1319.2-20036l-5.2-.7c-.6-2.1-1.4-4.1-2.5-6l3.2-4.1c.8-.9.8-2.2 0-3l-1-1-3.1-3.1-1-1c-.9-.8-2.2-.8-3 0l-4.1 3.2c-1.9-1.1-3.9-1.9-6-2.5l-.7-5.2c-.1-1.2-1-2.1-2.1-2.1h-7.3-.1-.2-.1v.5l-.7 5.2c-2.1.6-4.1 1.4-6 2.5l-4.1-3.2c-.9-.8-2.2-.8-3 0l-1 1-3.1 3.1-1 1c-.8.9-.8 2.2 0 3l3.2 4.1c-1.1 1.9-1.9 3.9-2.5 6l-5.2.7c-1.2.1-2.1 1-2.1 2.1v7.2c.1 1.2 1 2.1 2.1 2.1l5.2.7c.6 2.1 1.4 4.1 2.5 6l-3.2 4.1c-.8.9-.8 2.2 0 3l1 1 3.1 3.1 1 1c.9.8 2.2.8 3 0l4.1-3.2c1.9 1.1 3.9 1.9 6 2.5l.7 5.2c.1 1.2 1 2.1 2.1 2.1h7.2c1.2-.1 2.1-1 2.1-2.1l.7-5.2c2.1-.6 4.1-1.4 6-2.5l4.1 3.2c.9.8 2.2.8 3 0l1-1 3.1-3.1 1-1c.8-.9.8-2.2 0-3l-3.2-4.1c1.1-1.9 1.9-3.9 2.5-6l5.2-.7c1.2-.1 2.1-1 2.1-2.1v-7.2c-1.6.5-2.5-.5-3.7-.5zm-29.1 24c-10.1 0-18.3-8.2-18.3-18.3 0-10.1 8.2-18.3 18.3-18.3 10.1 0 18.3 8.2 18.3 18.3.1 10.1-8.1 18.3-18.3 18.3z" />
      <path d="M-1352.4-20034.9c-.1-.1-.2-.1-.3 0-.4.3.1 2.4.5 2.7.1.1.2.1.3 0 0 .2.1.3.2.5.3.6.6 1.2 1 1.7.6.8 1.5 1.3 2.5 1.4 1.1 0 2.2-.6 2.7-1.5l.2-.3.6-1.2c.1-.1.1-.3.1-.4.1 0 .2 0 .2-.1.4-.3.9-2.4.5-2.7-.1-.1-.2-.1-.3-.1.1-.7.2-1 .2-1 .6-2.9-2.8-5.5-5.4-4.9-.9.1-1.7.7-2.1 1.5-2 .2-1.4 2.7-1.4 2.7z" />
      <path d="M-1339.6-20026.6l-5-1.9c0-.1-1.3 2.4-2.4 4.3 0-1.1-.3-2.2-.8-3.2h-1c-.5 1-.8 2.1-.8 3.2-1.1-2-2.4-4.3-2.4-4.3l-4.9 1.9s-.8 0-1 1.3c-.1.7-.1 1.5-.1 2 0 .4.3.8.7 1 1.1.4 3.7 1 9 1 5.2.1 7.8-.6 9-1 .4-.1.7-.5.7-1 0-.7-.1-1.3-.1-2-.1-1.4-.9-1.3-.9-1.3z" />
    </g>
  </svg>
);
