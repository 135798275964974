import Badge from 'components/Badge';
import React from 'react';
import IconManager from 'utilities/services/IconManager';
import './styles.scss';

const ProcessingBadge = () => {
  return (
    <Badge
      red
      content={
        <div className="ickyc-processing-badge">
          {IconManager.get(IconManager.names.BATCH_ACTIONS)}
          <div>Processing</div>
        </div>
      }
    ></Badge>
  );
};
export default ProcessingBadge;
