import api from 'api';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import Section from '../../../../components/Section';
import useModalHandler from '../../../../hooks/useModalHandler';
import ConfigureAuthenticatorAppModal from './components/ConfigureAuthenticatorAppModal';
import ResetAuthenticatorKeyModal from './components/ResetAuthenticatorKeyModal';
import ResetRecoveryCodesModal from './components/ResetRecoveryCodesModal';
import './styles.scss';

const TwoFactorAuthentication = ({ requireTwoFactorAuthentication }) => {
  const classBase = 'ickyc-two-factor-authentication';
  const [isGlobalTwoFactorEnabled, setIsGlobalTwoFactorEnabled] = useState(false);
  const [, setTwoFactorAuthentication] = useState(requireTwoFactorAuthentication);
  const { isOpen: resetModalOpen, open: openResetModal, close: closeResetModal } = useModalHandler();
  const {
    isOpen: reconfigureAuthenticatorOpen,
    open: openReconfigureAuthenticatorModal,
    close: closeReconfigureAuthenticatorModal,
  } = useModalHandler();
  const {
    isOpen: isresetAuthenticatorOpen,
    open: openResetAuthenticatorModal,
    close: closeResetAuthenticatorModal,
  } = useModalHandler();

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { enabled },
        } = await api.administration.account.getGlobalTwoFactorAuthentication();
        setIsGlobalTwoFactorEnabled(enabled);
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  return (
    <Section title="Two Factor Authentication" className={classBase}>
      <PrimaryButton onClick={openResetModal}> Reset Recovery Codes</PrimaryButton>
      {!requireTwoFactorAuthentication && (
        <PrimaryButton onClick={openReconfigureAuthenticatorModal}> Configure Authenticator App</PrimaryButton>
      )}
      {(isGlobalTwoFactorEnabled || requireTwoFactorAuthentication) && (
        <PrimaryButton onClick={openResetAuthenticatorModal}> Clear Authenticator Key</PrimaryButton>
      )}

      {resetModalOpen && <ResetRecoveryCodesModal hideModal={closeResetModal} />}
      {reconfigureAuthenticatorOpen && (
        <ConfigureAuthenticatorAppModal hideModal={closeReconfigureAuthenticatorModal} />
      )}
      {isresetAuthenticatorOpen && (
        <ResetAuthenticatorKeyModal hideModal={closeResetAuthenticatorModal} callback={setTwoFactorAuthentication} />
      )}
    </Section>
  );
};

TwoFactorAuthentication.propTypes = {
  requireTwoFactorAuthentication: PropTypes.bool,
};
TwoFactorAuthentication.defaultProps = {
  requireTwoFactorAuthentication: false,
};
export default TwoFactorAuthentication;
