import utils from '../utils/index';

const { globalInitialState } = utils;

export const COUNTRIES_SET = 'COUNTRIES_SET';
export const RISK_LEVELS_SAVE = 'RISK_LEVELS_SAVE';
export const CREDENTIALS_SET = 'CREDENTIALS_SET';
export const USERS_TO_ASSIGN_SET = 'USERS_TO_ASSIGN_SET';
export const DEFAULT_SCREENING_PROFILE_SET = 'DEFAULT_SCREENING_PROFILE_SET';
export const DOCUMENT_TYPES_SET = 'DOCUMENT_TYPES_SET';
export const CURRENCIES_SET = 'CURRENCIES_SET';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const RESET_STORE = 'RESET_STORE';

export const setCountries = payload => ({
  type: COUNTRIES_SET,
  payload,
});

export const saveRiskLevels = payload => ({
  type: RISK_LEVELS_SAVE,
  payload,
});

export const saveUsersToAssign = users => ({
  type: USERS_TO_ASSIGN_SET,
  payload: users,
});

export const saveDefaultScreeningProfile = profile => ({
  type: DEFAULT_SCREENING_PROFILE_SET,
  payload: profile,
});

export const setDocumentPackages = payload => ({ type: DOCUMENT_TYPES_SET, payload });

export const setCurrencies = payload => ({
  type: CURRENCIES_SET,
  payload,
});

export const setNotifications = notifications => ({
  type: NOTIFICATIONS,
  payload: notifications,
});

export const resetStore = () => ({
  type: RESET_STORE,
  payload: globalInitialState,
});
