import axiosInstance from 'api/axiosInstance';

const BASE_ROUTE = '/client-account/supporting-document-packages';

const ENDPOINTS = {
  supportingDocumentPackages: () => BASE_ROUTE,
  supportingDocumentPackagesItems: packageId => `${BASE_ROUTE}/${packageId}/items`,
  supportingDocumentPackage: packageId => `${BASE_ROUTE}/${packageId}`,
  supportingDocumentPackageItem: () => `${BASE_ROUTE}/items`,
  deleteSupportingDocumentPackageItem: id => `${BASE_ROUTE}/items/${id}`,
  supportingDocumentPackageDocument: packageId => `${BASE_ROUTE}/${packageId}/document`,
  updateSupportingDocumentPackageDocument: () => `${BASE_ROUTE}/document`,
  reorderDocumentPackageItems: () => `${BASE_ROUTE}/items/order`,
  remainingSupportingDocumentsCount: () => `${BASE_ROUTE}/items/remaining`,
};

const createSupportingDocumentPackage = () => axiosInstance.post(ENDPOINTS.supportingDocumentPackages());
const createSupportingDocumentPackageItem = data =>
  axiosInstance.post(ENDPOINTS.supportingDocumentPackagesItems(data.packageId), data);
const updateSupportingDocumentPackage = ({ packageId, ...restData }) =>
  axiosInstance.put(ENDPOINTS.supportingDocumentPackage(packageId), restData);
const updateSupportingDocumentPackageItem = data => axiosInstance.put(ENDPOINTS.supportingDocumentPackageItem(), data);
const getSupportingDocumentPackage = ({ packageId, ...restData }) =>
  axiosInstance.get(ENDPOINTS.supportingDocumentPackage(packageId), restData);
const deleteSupportingDocumentPackage = packageId =>
  axiosInstance.delete(ENDPOINTS.supportingDocumentPackage(packageId));
const getSupportingDocumentPackageItem = ({ id, ...restData }) =>
  axiosInstance.get(ENDPOINTS.supportingDocumentPackageItem(), restData);
const deleteSupportingDocumentPackageItem = (id, forceRequest) =>
  axiosInstance.delete(ENDPOINTS.deleteSupportingDocumentPackageItem(id), { params: { forceRequest } });
const getSupportingDocumentPackages = () => axiosInstance.get(ENDPOINTS.supportingDocumentPackages());
const getSupportingDocumentPackagesItems = ({ packageId, ...restData }) =>
  axiosInstance.get(ENDPOINTS.supportingDocumentPackagesItems(packageId), restData);
const createSupportingDocumentPackageDocument = (packageId, data) => {
  return axiosInstance.post(ENDPOINTS.supportingDocumentPackageDocument(packageId), data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};
const updateSupportingDocumentPackageDocument = data => {
  return axiosInstance.put(ENDPOINTS.updateSupportingDocumentPackageDocument(), data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

const reorderDocumentPackageItems = data => {
  return axiosInstance.put(ENDPOINTS.reorderDocumentPackageItems(), data);
};

const getRemainingSupportingDocumentsCount = () => {
  return axiosInstance.get(ENDPOINTS.remainingSupportingDocumentsCount());
};

export default {
  updateSupportingDocumentPackage,
  updateSupportingDocumentPackageItem,
  createSupportingDocumentPackage,
  createSupportingDocumentPackageItem,
  getSupportingDocumentPackage,
  deleteSupportingDocumentPackage,
  getSupportingDocumentPackageItem,
  deleteSupportingDocumentPackageItem,
  getSupportingDocumentPackages,
  getSupportingDocumentPackagesItems,
  createSupportingDocumentPackageDocument,
  updateSupportingDocumentPackageDocument,
  reorderDocumentPackageItems,
  getRemainingSupportingDocumentsCount,
};
