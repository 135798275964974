import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import { FormSpy } from 'react-final-form';
import toastr from 'toastr';
import api from '../../../../../api';
import FormModal from '../../../../../components/Modal/FormModal';
import useSelect from '../../../../../hooks/useSelect';
import bus from '../../../../../modules/bus';
import { selectUserAccessByLicense } from '../../../../../store/selectors/auth.selector';
import { selectDefaultScreeningProfile } from '../../../../../store/selectors/global.selector';
import enums from '../../../../../utilities/enums';
import authEnums from '../../../../../utilities/enums/authEnums';
import { EntityContext } from '../../../../../utilities/services/contexts';
import KYCServicesV3 from '../KYCServicesV3';
import './index.scss';
import { RCUDefaultValues } from './utils';

const RequestClientUpdateNaturalPersonModal = ({
  hideModal,
  identityVerificationEnabled,
  supportingDocumentPackagesEnabled,
  biometricAuthenticationEnabled,
  documentAuthenticationEnabled,
  ...rest
}) => {
  const classBase = 'ickyc-request-client-update-modal';
  const [shouldForce, setShoudForce] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const defaultScreeningProfile = useSelect(selectDefaultScreeningProfile);
  const entity = useContext(EntityContext);
  const platformAccessByLicence = useSelect(selectUserAccessByLicense);

  const initialValues = useMemo(() => {
    RCUDefaultValues.generalSettings.clientEmail = entity.email || '';
    RCUDefaultValues.kycRequests.identityVerification.isEnabled = identityVerificationEnabled;
    RCUDefaultValues.dataCollection.requestSupportingDocuments = supportingDocumentPackagesEnabled;
    RCUDefaultValues.kycRequests.documentAuthentication.isEnabled = documentAuthenticationEnabled;
    RCUDefaultValues.kycRequests.biometricAuthentication.isEnabled = biometricAuthenticationEnabled;

    return RCUDefaultValues;
  }, [
    entity.email,
    identityVerificationEnabled,
    supportingDocumentPackagesEnabled,
    biometricAuthenticationEnabled,
    documentAuthenticationEnabled,
  ]);

  const mergeJurisdictionTables = restOfConfig => {
    const {
      biometricAuthenticationEvent,
      documentAuthenticationEvent,
      entityProfile,
      identityAuthenticationEvent,
    } = restOfConfig;

    const restrictions = [];

    Object.entries(entityProfile).forEach(({ 0: key, 1: value }) => {
      const countryId = Number(key.replace('country', ''));
      const { selected, ...restOfValue } = value;
      restrictions.push({
        [`countryId`]: countryId,
        ...restOfValue,
        ...biometricAuthenticationEvent[key],
        ...documentAuthenticationEvent[key],
        ...identityAuthenticationEvent[key],
      });
    });
    return restrictions;
  };

  const parseDocumentsListTable = restOfConfig => {
    const { automationsSupportingDocsTransformed } = restOfConfig;

    const restrictions = [];

    Object.entries(automationsSupportingDocsTransformed).forEach(({ 0: key, 1: value }) => {
      const documentId = Number(key.replace('document', ''));
      const { selected, ...restOfValue } = value;
      restrictions.push({
        [`packageId`]: documentId,
        ...restOfValue,
      });
    });
    return restrictions;
  };

  const handleSubmit = async values => {
    const {
      generalSettings,
      kycRequests: { runVerification, ...restOfKYCRequests },
      initialyFetchedData,
      biometricAuthenticationAutoAcceptance,
      documentAuthenticationAutoAcceptance,
      identityAuthenticationAutoAcceptance,
      periodicReviewInterval,
      periodicReviewValue,
      setProfileStatusTo,
      setRiskLevelTo,
      autoAssignSupportingDocumentPackagesEnabled,
      autoAssignIdentityAuthenticationEnabled,
      autoAssignBiometricAuthenticationEnabled,
      autoAssignDocumentAuthenticationEnabled,
      autoAssignEntityProfileEnabled,
      enablePeriodicReview,
      autoUpdateEntityStatus,
      autoUpdateRiskLevel,
      automation: { isEnabled },
      changeStatusForApprovedProfile,
      approvedProfileStatus,
      dataCollection,
      ...rest
    } = values;
    let automation = { isEnabled: false };
    if (platformAccessByLicence.some(elm => elm === authEnums.ACCESS_BY_LICENCE.AUTOMATIONS)) {
      const mergedJurisdiction = mergeJurisdictionTables(values);
      const mergedSuppDocs = parseDocumentsListTable(values);

      const jurisdictionChanges = diff(initialyFetchedData?.automationsJurisdictions, mergedJurisdiction);
      const suppDocsChanges = diff(initialyFetchedData?.automationsSupportingDocs, mergedSuppDocs);
      automation = {
        isEnabled,
        automationsJurisdictions: Object.entries(jurisdictionChanges).map(country => ({
          ...mergedJurisdiction[Number(country[0])],
        })),
        automationsSupportingDocs: Object.entries(suppDocsChanges).map(suppDoc => ({
          ...mergedSuppDocs[Number(suppDoc[0])],
        })),
        biometricAuthenticationAutoAcceptance,
        documentAuthenticationAutoAcceptance,
        identityAuthenticationAutoAcceptance,
        periodicReviewInterval,
        periodicReviewValue,
        setProfileStatusTo,
        setRiskLevelTo,
        autoAssignSupportingDocumentPackagesEnabled,
        autoAssignIdentityAuthenticationEnabled,
        autoAssignBiometricAuthenticationEnabled,
        autoAssignDocumentAuthenticationEnabled,
        autoAssignEntityProfileEnabled,
        enablePeriodicReview,
        autoUpdateEntityStatus,
        autoUpdateRiskLevel,
        changeStatusForApprovedProfile,
        approvedProfileStatus,
      };
    }
    restOfKYCRequests.identityVerification.runIdentityDataValidation = runVerification;
    restOfKYCRequests.documentAuthentication.runDocumentVerification = runVerification;
    dataCollection.packagesTypes = dataCollection.packagesTypes.map(({ packageId, assignedUserId, required }) => ({
      packageId,
      assignedUserId,
      required,
    }));

    return api.kyc.entityManagement
      .requestClientUpdate(
        entity.entityId,
        {
          ...rest,
          kycRequests: restOfKYCRequests,
          generalSettings,
          automation,
          dataCollection,
        },
        shouldForce,
      )
      .then(({ data }) => {
        const { newEvents, expiredEvents = [] } = data;
        toastr.success(`Request has been forwarded to ${entity.entityName}`);
        if (newEvents['Identity Verification']) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_PERSONAL_RECORD, newEvents['Identity Verification']);
          bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
            type: 'kyc',
            section: enums.ACCORDION_INDEXES.PERSONAL_INFORMATION,
            issuesChange: newEvents['Identity Verification']?.issues,
          });
        }

        if (newEvents['Document Authentication']) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_IDENTITY_RECORD, newEvents['Document Authentication']);
          bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
            type: 'kyc',
            section: enums.ACCORDION_INDEXES.IDENTITY_DOCUMENTS,
            issuesChange: newEvents['Document Authentication']?.issues,
          });
        }
        if (newEvents['Facial Matching']) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_BIOMETRIC_RECORD, newEvents['Facial Matching']);
          bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
            type: 'kyc',
            section: enums.ACCORDION_INDEXES.BIOMETRIC_AUTHENTICATIONS,
            issuesChange: newEvents['Facial Matching']?.issues,
          });
        }
        if (newEvents['Supporting Document']) {
          bus.broadcastEvent(
            enums.BUS_EVENTS.NEW_DOCUMENTS_RECORD,
            newEvents['Supporting Document'].map(({ attachedEntities, ...restInfo }) => ({
              attachedTo: attachedEntities,
              ...restInfo,
            })),
          );
          newEvents['Supporting Document'].forEach(doc =>
            bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
              type: 'kyc',
              section: enums.ACCORDION_INDEXES.DOCUMENTS,
              issuesChange: doc?.issues,
            }),
          );
        }
        if (expiredEvents.length > 0) {
          expiredEvents.forEach(({ id, type }) => {
            bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
              type: 'kyc',
              section: enums.EVENT_TYPES[type],
              issuesChange: -1,
            });

            bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_HISTORY_TABLE_RECORD, {
              eventHistoryId: id,
              data: {
                status: 'Canceled',
                issues: 0,
              },
            });
          });
        }
        hideModal();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data } = err.response;
          if (status >= 400 && status < 500) {
            if (data?.message?.length && Array.isArray(data?.message)) {
              if (data.message.join('').includes('event in pending status')) {
                setShoudForce(true);
                return { [FORM_ERROR]: data.message };
              }
              setShoudForce(false);
              return { [FORM_ERROR]: data.message };
            }
            return {
              [FORM_ERROR]:
                (Array.isArray(data?.message) && data.message.join(' ')) || data?.message || 'Error Occurred',
            };
          }
          if (status === 500) {
            return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
          }
        }
        return { [FORM_ERROR]: 'Error trying to Request Client Update' };
      });
  };

  return (
    <FormModal
      hideModal={hideModal}
      title={`Request Client Update`}
      withFieldArray
      onSubmit={handleSubmit}
      className={classBase}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      showConfirmationForm={shouldForce}
      onRejectClick={hideModal}
      showErrorsInConfirmation
      confirmButtonText="Yes"
      confirmationText="Would you like to proceed and override previous request?"
      rejectButtonText="No"
      disableSubmitButton={disableSubmitButton}
      {...rest}
    >
      <FormSpy subscription={{ values: true }} onChange={() => {}} />
      <KYCServicesV3
        entityID={entity?.entityId}
        screeningProfileId={defaultScreeningProfile?.id}
        setDisableSubmitButton={setDisableSubmitButton}
      />
    </FormModal>
  );
};
RequestClientUpdateNaturalPersonModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  identityVerificationEnabled: PropTypes.bool,
  supportingDocumentPackagesEnabled: PropTypes.bool,
  biometricAuthenticationEnabled: PropTypes.bool,
  documentAuthenticationEnabled: PropTypes.bool,
};

RequestClientUpdateNaturalPersonModal.defaultProps = {
  identityVerificationEnabled: false,
  supportingDocumentPackagesEnabled: false,
  biometricAuthenticationEnabled: false,
  documentAuthenticationEnabled: false,
};

export default RequestClientUpdateNaturalPersonModal;
