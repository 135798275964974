/* eslint-disable no-restricted-globals */
/* eslint-disable no-useless-escape */
import dayjs from 'dayjs';
import { FORM_ERROR } from 'final-form';
import utilities from 'utilities';
import licenceEnums from 'utilities/enums/licenceEnums';
import DateManager from './DateManager';

const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>[\].,;:\s@"]+\.)+[^<>()[\].,;:\s!@#$%^&*()_+=|"'{}/?]{2,})$/i,
);
const PASSWORD_REGEX = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);

const URL_REGEX = new RegExp(/(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/);

const HTTPS_REGEX = new RegExp(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
);

const LETTERS_REGEX = new RegExp(/[^\p{Letter}\p{Mark}]/gu);
const PERSONAL_NAME_REGEX = new RegExp(/[^\p{Letter}\p{Mark} ,.'`-]/gu);
const NUMBER_REGEX = new RegExp(/[^\d]/g);
const LEI_REGEX = new RegExp(/^.{4}0{2}.{14}$/);

const NOT_TO_INCLUDE_REGEX = new RegExp(/[A-Z\.,_&@#\$%\+=]/);

const GLEI_CUSTOM_REGEX = new RegExp(/^[a-zA-Z0-9_]*$/);
const DUNS_CUSTOM_REGEX = new RegExp(/^[0-9_]*$/);

// Final Form expects an undefined value for a successful validation
const VALID = undefined;

const valueExists = (value, checkAllFalseyValues = false) => {
  return !(
    value === null ||
    value === undefined ||
    value === '' ||
    JSON.stringify(value) === '[]' ||
    JSON.stringify(value) === '{}' ||
    (checkAllFalseyValues && !value)
  );
};

const required = (msg = 'Required', checkAllFalseyValues = false) => value => {
  if (!valueExists(value, checkAllFalseyValues)) return msg;

  return VALID;
};

const validateBasedOnOtherField = (otherFieldName, validator) => (value, allValues) => {
  if (valueExists(utilities.accessObjectNestedValuesByStringPath(allValues, otherFieldName), true)) {
    return validator(value, allValues);
  }
  return VALID;
};

const maxLength = (msg, limit) => value => (value?.length > limit ? msg : VALID);

const compareDates = msg => (value, values) => {
  if (!values.dateUntil?.value) return VALID;
  if (!value.dateFrom || !value.dateUntil) return VALID;
  return DateManager.compareDates(value.dateFrom, value.dateUntil) ? VALID : msg;
};
const email = errorMsg => value => {
  if (!EMAIL_REGEX.test(value) && value) {
    const defaultMessage = 'Invalid email address';
    return errorMsg || defaultMessage;
  }
  return VALID;
};
const password = errorMsg => value => {
  if (!PASSWORD_REGEX.test(value)) {
    return errorMsg;
  }
  return VALID;
};
const url = errorMsg => value => {
  if (!URL_REGEX.test(value)) {
    return errorMsg || 'Invalid URL';
  }
  return VALID;
};
const validateCustomRadioComponent = (errorMessage, radioName, radioTargetValue) => (val, values) => {
  const radioValue = values[radioName];
  if (!val && radioValue === radioTargetValue) return errorMessage;
  return undefined;
};

const validateDate = (errorMessage = 'Invalid Date') => value => {
  if (!DateManager.toDate(value)) {
    return errorMessage;
  }
};

const validateDuns = errorMessage => value => {
  if (!value || value === '') return VALID;
  if (value?.length !== 9 || !DUNS_CUSTOM_REGEX.test(value)) {
    return errorMessage;
  }
};

const validateLei = errorMessage => value => {
  if (!value || value === '') return VALID;
  if (value?.length !== 20 || !GLEI_CUSTOM_REGEX.test(value)) return errorMessage;
};

const dropzoneLimit = (errorMessage, limit) => value => {
  if (value?.length === limit) {
    return errorMessage;
  }
};

const validateDocumentDetection = errorMessage => value => {
  if (value?.data) {
    const {
      data: { message },
    } = value;
    if (message) {
      return errorMessage;
    }
  }
  return VALID;
};
const passwordMatch = (errorMessage, destField) => (value, values) => {
  if (value !== values[destField]) {
    return errorMessage;
  }
  return undefined;
};

const composeValidators = (...validators) => (value, allvalues) =>
  validators.reduce((error, validator) => error || (validator && validator(value, allvalues)), VALID);

const maxAllowedValue = (errorMessage, maxValue) => value => {
  if (value > maxValue || isNaN(value)) {
    return errorMessage;
  }
  return VALID;
};

const minAllowedValue = (errorMessage, minValue) => value => {
  if (value < minValue || isNaN(value)) {
    return errorMessage;
  }
  return VALID;
};

const customValidationForCasesDropdown = (msg = 'Required') => (value, values) => {
  if (values.includeAmlCases) {
    if (!value || value === '' || JSON.stringify(value) === '[]' || JSON.stringify(value) === '{}') {
      return msg;
    }
    return VALID;
  }
  return VALID;
};

const faceImageDropzoneValidator = () => value => {
  if (value?.data?.errorMessage) {
    return value?.data?.errorMessage;
  }
};

const validateUrlHTTPs = () => value => {
  if (value && !HTTPS_REGEX.test(value)) {
    return 'Url must contain https://';
  }
  return VALID;
};

const customValidatorForSupportingDocumentsRow = (msg = ' ') => (value, values) => {
  if (values?.dataCollection?.requestSupportingDocuments) {
    if (!value || value === '' || JSON.stringify(value) === '[]' || JSON.stringify(value) === '{}') {
      return msg;
    }
    return VALID;
  }
  return VALID;
};

const customValidatorRiskScreeningByStatusSelect = (msg = ' ') => (value, values) => {
  if (values.toggleKYCStatuses && (!value || value.length === 0)) {
    return msg;
  }
  return VALID;
};

const customValidatorRiskScreeningByLevelsSelect = (msg = ' ') => (value, values) => {
  if (values.toggleRiskLevels && (!value || value.length === 0)) {
    return msg;
  }
  return VALID;
};
const customResidenceValidator = (msg = ' ') => (value, values) => {
  if (
    (values.personalInformation?.unit ||
      values.personalInformation?.streetAddress ||
      values.personalInformation?.city ||
      values.personalInformation?.stateProvince ||
      values.personalInformation?.postalZip) &&
    (!value || value.length === 0)
  ) {
    return msg;
  }
  return VALID;
};

const compareLicenceDates = (index, msg) => (value, values) => {
  if (!values?.licences[index]?.endDate) return VALID;
  return !DateManager.compareDates(values?.licences[index]?.startDate, values?.licences[index]?.endDate) ? VALID : msg;
};

const checkActiveStartDate = (index, msg) => (value, values) => {
  const licence = values?.licences[index];

  if (!licence || licence.licenceStatus !== licenceEnums.LICENCE_STATUS_TYPES.ACTIVE) return VALID;

  const todayDate = DateManager.toBekend(dayjs());
  const isStartDateValid = DateManager.compareDates(todayDate, licence.startDate);

  return isStartDateValid ? VALID : msg;
};

const checkUpcomingStartDate = (index, msg) => (value, values) => {
  const licence = values?.licences[index];

  if (!licence || licence.licenceStatus !== licenceEnums.LICENCE_STATUS_TYPES.UPCOMING) return VALID;

  const todayDate = DateManager.toBekend(dayjs());
  const isStartDateValid = DateManager.compareDates(licence.startDate, todayDate);

  return isStartDateValid ? VALID : msg;
};

const customLicenceStatusValidator = (licenceIndex, msg) => (value, values) => {
  if (
    values?.licences
      .filter((licc, index) => index !== licenceIndex)
      .some(licc => licc?.licenceStatus === licenceEnums.LICENCE_STATUS_TYPES.ACTIVE) &&
    value === licenceEnums.LICENCE_STATUS_TYPES.ACTIVE
  ) {
    return msg;
  }
  return VALID;
};

const checkActiveEndDate = (licenceIndex, msg) => (value, values) => {
  const licence = values?.licences?.[licenceIndex];
  const isActiveStatus = licence.licenceStatus === licenceEnums.LICENCE_STATUS_TYPES.ACTIVE;

  if (!licence || !licence?.endDate || !isActiveStatus) return VALID;

  const todayDate = DateManager.toBekend(dayjs());
  const isEndDateValid = !DateManager.compareDates(todayDate, licence?.endDate);

  return isEndDateValid ? VALID : msg;
};

const domain = errorMsg => value => {
  if (NOT_TO_INCLUDE_REGEX.test(value)) {
    return errorMsg;
  }
  return VALID;
};

const customRequiredSupportingDocument = (index, propName, msg = 'Required') => (value, values) => {
  if (!values?.modules[index]?.kycRequests[propName]) return VALID;
  if (!value || value === '' || JSON.stringify(value) === '[]' || JSON.stringify(value) === '{}') {
    return msg;
  }
  return VALID;
};

const fileSize = (maxSizeInMB, errorMessage) => value => {
  const byteMaxSize = maxSizeInMB * 1024 * 1024;
  let fileSizeSum = 0;

  if (Array.isArray(value)) {
    fileSizeSum = value.reduce((prev, curr) => prev + curr.file.size, 0);
  }

  if ((fileSizeSum || value?.file?.size) > byteMaxSize) return errorMessage;
  return VALID;
};

const dateIsBefore = (limitDate, msg) => value => {
  if (value > limitDate) return msg;
  return VALID;
};

const dateIsAfter = (limitDate, msg) => value => {
  if (value < limitDate) return msg;
  return VALID;
};

const validateNumberGreaterThanZero = (errorMessage = ' ') => value => {
  if (value <= 0) return errorMessage;
  return VALID;
};

const containsLetter = (errorMessage = 'Must contain at least one letter') => value => {
  if (value?.length && !/\p{Letter}/u.test(value)) return errorMessage;
  return VALID;
};

const stringMaxLength = (maxLength = 250, errorMessage = `Maximum length is ${maxLength} characters.`) => value => {
  if (value?.length > maxLength) return errorMessage;
  return VALID;
};

const checkForUnallowedPlaceholders = placeholders => value => {
  const regex = /\{\{([^}]+)\}\}/g;
  const matches = value?.match(regex);

  if (!matches) return undefined;

  const unallowedPlaceholders = matches.reduce((acc, match) => {
    if (!placeholders?.includes(match)) return [...acc, match];
    return acc;
  }, []);

  const unallowedPlaceholdersUnique = [...new Set(unallowedPlaceholders)];

  return unallowedPlaceholdersUnique.length
    ? 'The following placeholder/s are not allowed: ' + unallowedPlaceholdersUnique.join(', ')
    : undefined;
};

const checkForPlaceholdersDuplicates = placeholders => value => {
  const duplicatePlaceholder = placeholders?.find(placeholder => {
    const regex = new RegExp(`\\${placeholder}`, 'g');
    return (value?.match(regex) || []).length > 1;
  });

  if (duplicatePlaceholder) {
    return `There should only be one "${duplicatePlaceholder}"  placeholder`;
  }

  return undefined;
};

const validateEmailEditor = placeholders => value => {
  const hasMultipleButtons = html => {
    const buttonRegex = new RegExp('class="v-button"', 'g');
    const elements = html ? html.match(buttonRegex) : document.getElementsByClassName('v-button');
    return elements?.length > 1;
  };

  const html = value?.html;
  if (html === undefined) {
    return 'Required';
  } else if (hasMultipleButtons(html)) {
    return 'Please note that email templates should not contain more than one button';
  } else if (placeholders?.includes('{{CUSTOM EMAIL MESSAGE}}') && !html.includes('{{CUSTOM EMAIL MESSAGE}}')) {
    return 'Please note that "{{CUSTOM EMAIL MESSAGE}}" is required';
  }

  const unallowedPlaceholderError = checkForUnallowedPlaceholders(placeholders)(html);
  const duplicatePlaceholderError = checkForPlaceholdersDuplicates(placeholders)(html);

  return unallowedPlaceholderError || duplicatePlaceholderError;
};

const checkEmailMessage = (placeholders, isLinkRequired) => (value, values) => {
  const unallowedPlaceholderError = checkForUnallowedPlaceholders(placeholders)(value);

  if (unallowedPlaceholderError) {
    return unallowedPlaceholderError;
  }

  if (isLinkRequired && !value.includes('{{PLAIN LINK}}') && !value.includes('{{LINK}}')) {
    return `Please include either "{{PLAIN LINK}}" or "{{LINK}}"`;
  }

  return VALID;
};

const someTypeOfLinkIsRequiredInEmailTemplate = links => ({ message = '', template }) => {
  const html = template?.html || '';

  const includedLink = links.find(link => message.includes(link) || html.includes(link));

  if (!includedLink) {
    return {
      [FORM_ERROR]: `Please include "{{PLAIN LINK}}" or "{{LINK}}" placeholder in "Email Message" or button component in "Email Template" since there should be at least on type of link`,
    };
  }

  return VALID;
};

export default {
  compareDates,
  required,
  maxLength,
  password,
  url,
  email,
  passwordMatch,
  composeValidators,
  validateCustomRadioComponent,
  validateDate,
  EMAIL_REGEX,
  NUMBER_REGEX,
  PERSONAL_NAME_REGEX,
  LEI_REGEX,
  LETTERS_REGEX,
  validateDuns,
  validateLei,
  validateDocumentDetection,
  dropzoneLimit,
  maxAllowedValue,
  minAllowedValue,
  faceImageDropzoneValidator,
  customValidationForCasesDropdown,
  validateUrlHTTPs,
  customValidatorForSupportingDocumentsRow,
  customValidatorRiskScreeningByStatusSelect,
  customValidatorRiskScreeningByLevelsSelect,
  customResidenceValidator,
  compareLicenceDates,
  checkActiveStartDate,
  checkActiveEndDate,
  checkUpcomingStartDate,
  customLicenceStatusValidator,
  domain,
  customRequiredSupportingDocument,
  fileSize,
  validateBasedOnOtherField,
  checkEmailMessage,
  dateIsBefore,
  dateIsAfter,
  validateNumberGreaterThanZero,
  containsLetter,
  checkEmailMessage,
  stringMaxLength,
  validateNumberGreaterThanZero,
  validateEmailEditor,
  someTypeOfLinkIsRequiredInEmailTemplate,
  checkForPlaceholdersDuplicates,
  checkForUnallowedPlaceholders,
};
