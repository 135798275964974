import api from 'api';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import FormModal from 'components/Modal/FormModal';
import Table from 'components/Tables/Table';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import { FORM_ERROR } from 'final-form';
import useModalHandler from 'hooks/useModalHandler';
import useTable from 'hooks/useTable';
import React, { useCallback, useState } from 'react';
import toastr from 'toastr';
import TableHeaders from 'utilities/services/TableHeaders';
import EmailTemplateModal from '../EmailTemplateModal';
import EmailTemplateRow from '../EmailTemplateRow';
import './styles.scss';
import utils from './utils';

const EmailTemplates = ({ apiRequest, type }) => {
  const {
    isLoading,
    removeRow,
    records,
    addRow,
    changeParams,
    pagination,
    updateRowData,
    params,
    replaceAllRowsWithOneRecord,
  } = useTable(apiRequest, { sortCriteria: 'types', limit: 5, skip: 0 });

  const { handleApplyToAllPortals } = utils;
  const { isOpen: isModalOpen, open: openModal, close: closeModal } = useModalHandler();
  const { isOpen: isConfirmModalOpen, close: closeConfirmModal, open: openConfirmModal } = useModalHandler();
  const [isUpdating, setIsUpdating] = useState(false);
  const [globalTemplateAlreadyExists, setGlobalTemplateAlreadyExists] = useState(false);
  const [forceTemplateValues, setForceTemplateValues] = useState();

  const onRemoveHandler = useCallback(async id => {
    try {
      setIsUpdating(true);
      await api.administration.settings.deleteEmailTemplate(id);
      removeRow('id', id);
      toastr.success('Successfully deleted email template');
    } catch (err) {
      console.error(err);
      toastr.error('Error ocured while deleting email template');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const TableControls = () => (
    <>
      <h3>My Custom Email Templates</h3>
      <PrimaryButton onClick={() => openModal()}>Add Custom Email Template</PrimaryButton>
    </>
  );

  const handleCreate = useCallback(async values => {
    const { forceRequest, ...newValues } = handleApplyToAllPortals(values);

    try {
      setIsUpdating(true);
      const { data } =
        type === 'RCU'
          ? await api.administration.settings.createRCUTemplates(newValues, !!forceRequest)
          : await api.administration.settings.createEmailALinkTemplates(newValues, !!forceRequest);

      forceRequest ? replaceAllRowsWithOneRecord(data) : addRow(data);
      toastr.success('Successfully created email template');
      closeModal();
      closeConfirmModal();
    } catch (err) {
      if (err?.response) {
        const {
          status,
          data: { globalTemplateAlreadyExists: flag, errors },
        } = err.response;
        if (status === 409) {
          setGlobalTemplateAlreadyExists(flag);
          openConfirmModal();
          setForceTemplateValues({ ...values, forceRequest: true });
        }

        if (errors) {
          return {
            [FORM_ERROR]: Array.isArray(errors) ? errors.join('') : errors || 'Internal Server Error, Try Again Later',
          };
        }
      }
      console.error(err);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const handleUpdate = useCallback(
    updateRowData => async (values, closeModal) => {
      const { forceRequest, ...newValues } = handleApplyToAllPortals(values);
      try {
        setIsUpdating(true);
        const { data } =
          type === 'RCU'
            ? await api.administration.settings.updateRCUTemplates(newValues, !!forceRequest)
            : await api.administration.settings.updateEmailALinkTemplates(newValues, !!forceRequest);

        forceRequest ? replaceAllRowsWithOneRecord(data) : updateRowData('id', values.id, data);
        toastr.success('Successfully updated email template');
        closeModal();
        closeConfirmModal();
      } catch (err) {
        if (err?.response) {
          const {
            status,
            data: { globalTemplateAlreadyExists: flag, errors },
          } = err.response;
          if (status === 409) {
            setGlobalTemplateAlreadyExists(flag);
            openConfirmModal();
            setForceTemplateValues({ ...values, forceRequest: true });
          }
          console.error(err);

          if (errors) {
            return {
              [FORM_ERROR]: Array.isArray(errors)
                ? errors.join('')
                : errors || 'Internal Server Error, Try Again Later',
            };
          }
        }
      } finally {
        setIsUpdating(false);
      }
    },
    [openConfirmModal],
  );

  const handleConfirmModalSubmit = useCallback(
    () =>
      forceTemplateValues.id
        ? handleUpdate(updateRowData)(forceTemplateValues, closeModal)
        : handleCreate(forceTemplateValues),
    [forceTemplateValues, updateRowData, closeModal],
  );

  return (
    <div className="ickyc-email-template">
      <Table
        values={records}
        tableControls={TableControls}
        pagination={pagination}
        tableRow={EmailTemplateRow}
        headerRow={TableHeader}
        onClick={() => {}}
        onRemove={onRemoveHandler}
        withPagination
        updating={isLoading || isUpdating}
        handleParamsChange={changeParams}
        handleSubmit={handleUpdate(updateRowData)}
        headerData={{
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          onClick: changeParams,
          items: TableHeaders.EmailTemplateTableHeader,
        }}
        type={type}
      />
      {isModalOpen && <EmailTemplateModal hideModal={closeModal} handleSubmit={handleCreate} type={type} />}
      {isConfirmModalOpen && (
        <FormModal
          hideModal={closeConfirmModal}
          title='Confirm creation of "Apply to All" Template'
          className="ickyc-confirmation-modal"
          onSubmit={handleConfirmModalSubmit}
          submitButtonText="Save"
        >
          <span className="ickyc-confirmation-message">
            {globalTemplateAlreadyExists
              ? 'Previously created template that was applied to all portals will be deleted. Would you like to proceed?'
              : 'Applying this template to all portals will delete other templates. Would you like to proceed?'}
          </span>
        </FormModal>
      )}
    </div>
  );
};

export default EmailTemplates;
