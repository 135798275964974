import { CircularProgress } from '@material-ui/core';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { diff } from 'deep-object-diff';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';

import './styles.scss';

const FormActions = ({ submitButtonText }) => {
  const { values, initialValues, submitting } = useFormState();
  const form = useForm();
  return (
    <div className="ickyc-form-actions">
      <PrimaryButton
        variant="red"
        disabled={submitting || !Object.entries(diff(values, initialValues))?.length}
        onClick={() => {
          form.reset(initialValues);
        }}
      >
        Discard
      </PrimaryButton>
      <PrimaryButton type="submit" disabled={submitting || !Object.entries(diff(values, initialValues))?.length}>
        {submitButtonText} {submitting && <CircularProgress />}
      </PrimaryButton>
    </div>
  );
};
export default FormActions;

FormActions.propTypes = {
  submitButtonText: PropTypes.string,
  className: PropTypes.string,
};

FormActions.defaultProps = {
  submitButtonText: 'Save',
  className: undefined,
};
