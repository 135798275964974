import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import './styles.scss';

const BUTTON_VARIANTS = ['primary', 'red', 'orange', 'link'];

/**
 * Button component used across the application.
 */

const PrimaryButton = forwardRef(({ onClick, children, variant, type, disabled, className, ...rest }, ref) => {
  const classes = classNames({
    'ickyc-button': true,
    [`ickyc-button--${variant}`]: true,
    [className]: className,
  });

  return (
    <button type={type} className={classes} onClick={onClick} disabled={disabled} {...rest} ref={ref}>
      {children}
    </button>
  );
});

PrimaryButton.propTypes = {
  /**
   * button content
   */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  /**
   * onClick handler
   */
  onClick: PropTypes.func,
  /**
   *  flag which notes which style is applied to button
   */
  variant: PropTypes.oneOf(BUTTON_VARIANTS),
  /**
   * button type
   */
  type: PropTypes.string,
  /**
   *  disability flag
   */
  disabled: PropTypes.bool,
};

PrimaryButton.defaultProps = {
  onClick: () => {},
  variant: 'primary',
  type: 'button',
  disabled: false,
};

export default PrimaryButton;
