import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import React, { useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { useDispatch } from 'react-redux';
import api from '../../../api';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import InputField from '../../../components/InputField';
import history from '../../../modules/history';
import { setToken } from '../../../store/actions/auth.action';
import authEnums from '../../../utilities/enums/authEnums';
import TokenStorage from '../../../utilities/services/local-storage/TokenStorage';
import './styles.scss';

const RecoveryCodeConfirmationTFA = () => {
  const [wrondCode, setWrongCode] = useState(false);
  const dispatch = useDispatch();
  const baseClass = 'ickyc-form';
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  useEffect(() => {
    if (token) {
      TokenStorage.token = token;
    }
  }, [token]);
  const handleSaveChanges = async values => {
    setWrongCode(false);
    const data = {
      recoveryCode: values.code,
      token,
    };
    return api.auth
      .confirmRecoveryCodeTwoFactor(data)
      .then(res => {
        setToken(res.data.token);
        TokenStorage.token = res.data.token;
        TokenStorage.refreshToken = res.data.refreshToken;
        dispatch(setToken(res.data.token));
        const { roles } = res.data || [];
        if (
          roles?.some(role => {
            return (
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_SUPER_ADMIN ||
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_ADMIN ||
              role === authEnums.ICOMPLY_USERS_ROLES.ICOMPLY_USER
            );
          })
        ) {
          history.push('/internal/business-manager');
        } else {
          history.push('/kyc/home-screen');
        }
      })
      .catch(err => {
        setWrongCode(true);
        if (err?.response) {
          const { status, data: errorData } = err.response;
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errorData.error || `${errorData.message?.slice(0, 80)}...` };
          }
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured while trying to get Authentication Info' };
      });
  };
  return !token ? (
    <h3 className="ickyc-widget__global-message">No Token Present!</h3>
  ) : (
    <FinalForm
      onSubmit={handleSaveChanges}
      validate={values => {
        const errors = {};
        if (!values.code) {
          errors.code = 'Required';
        }
        return errors;
      }}
    >
      {({ submitting, handleSubmit, submitError }) => {
        return (
          <form
            onSubmit={handleSubmit}
            className={classNames(baseClass, 'ickyc-recovery-code-two-factor-authentication-page')}
          >
            <>
              <div className={`${baseClass}__fields ${baseClass}__fields--rounded`}>
                <h2>Recovery Code Confirmation</h2>
                <span>
                  You have requested to login with a recovery code. This login will not be remembered until you provide
                  an authenticator app code at login or disable 2FA and login again.
                </span>
                <Field component={InputField} name="code" label="Recovery Code" autocomplete="off" />

                {wrondCode && (
                  <div className="ickyc-recovery-code-two-factor-authentication-page__error">{submitError}</div>
                )}
              </div>
              <div className={`${baseClass}__actions`}>
                <PrimaryButton type="submit" disabled={submitting}>
                  Authenticate
                  {submitting && <CircularProgress />}
                </PrimaryButton>
              </div>
            </>
          </form>
        );
      }}
    </FinalForm>
  );
};
export default RecoveryCodeConfirmationTFA;
