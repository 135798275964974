import api from 'api';
import classNames from 'classnames';
import BareHeading from 'components/Headings/BareHeading';
import Table from 'components/Tables/Table';
import TableHeader from 'components/Tables/Table/components/TableHeader';
import useTable from 'hooks/useTable';
import PropTypes from 'prop-types';
import React from 'react';
import TableHeaders from 'utilities/services/TableHeaders';
import EmailTemplateTableRow from './components/EmailTemplateTableRow';
import './styles.scss';

const EmailTemplates = ({ className }) => {
  const classes = classNames('ickyc-page', 'ickyc-email-templates', className);

  const { isLoading, records, changeParams, pagination, params } = useTable(
    api.internal.emailTemplates.getEmailTemplates,
    {
      sortCriteria: 'dateCreated',
      limit: 10,
      sortOrder: 1,
    },
    '',
  );

  return (
    <div className={classes}>
      <BareHeading title="Email Templates" />
      <Table
        values={records}
        pagination={pagination}
        tableRow={EmailTemplateTableRow}
        headerRow={TableHeader}
        handleParamsChange={changeParams}
        className="ickyc-email-templates__table"
        updating={isLoading}
        headerData={{
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          onClick: changeParams,
          items: TableHeaders.EmailTemplatesTableHeader,
        }}
      />
    </div>
  );
};

EmailTemplates.propTypes = {
  className: PropTypes.string,
};

EmailTemplates.defaultProps = {
  className: undefined,
};
export default EmailTemplates;
