import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';
/**
 * Search controls
 * @param {function} onSearch - search button click handler
 * @param {reference} pageRef - reference of the page containing the component
 * @param {number} count - number of fetched entities
 * @param {string} tableName - table name in redux
 */

const SearchControls = ({ renderAsPortal, children }) => {
  const classBase = 'ickyc-search-controls';
  const classes = classNames({
    [classBase]: true,
    [`${classBase}--portal`]: renderAsPortal,
  });

  return <div className={classes}>{children}</div>;
};

SearchControls.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  renderAsPortal: PropTypes.bool,
};

SearchControls.defaultProps = {
  renderAsPortal: false,
};

export default SearchControls;
