import { Lock } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import InputField from '../../../../../../../../../components/InputField';
import RadioButtonField from '../../../../../../../../../components/RadioButtonField';
import RadioGroup from '../../../../../../../../../components/RadioGroup';
import CollapsibleTableRow from '../../../../../../../../Kyc/components/CollapsibleTableRow';
import ModuleOption from '../ModuleOption';
import ModuleOptions from '../ModuleOptions';
import ModuleSettings from '../ModuleSettings';
import ModuleTitleSettings from '../ModuleTitleSettings';
import PersonalDetailsFormFields from './components/PersonalDetailsFormFields';

const PersonalDetailsModule = ({ index, indexToShow, isDropAllowed }) => {
  const className = 'ickyc-table';

  return (
    <CollapsibleTableRow
      isDropAllowed={isDropAllowed}
      className={className}
      withIndexColumn
      index={indexToShow}
      icon={<Lock />}
      row={
        <>
          <td className={`${className}__column__title`}>
            <Field name={`modules[${index}].title`} component={InputField} />
          </td>
          <td className={`${className}__column__type`}>Personal Details</td>
          <td className={`${className}__column__enabled--empty`} />
        </>
      }
    >
      <ModuleSettings>
        <ModuleTitleSettings index={index} withDescription AdditionalFieldsComponent={PersonalDetailsFormFields} />
        <ModuleOptions>
          <ModuleOption>
            <RadioGroup label="Form / API">
              <Field
                component={RadioButtonField}
                name={`modules[${index}].source`}
                type="radio"
                parse={value => Number(value)}
                value={0}
                label="Subject Enters In Form"
              />
              <Field
                component={RadioButtonField}
                name={`modules[${index}].source`}
                type="radio"
                value={1}
                parse={value => Number(value)}
                label="API Pass In / Subject Enters In Form"
              />
              <Field
                component={RadioButtonField}
                name={`modules[${index}].source`}
                parse={value => Number(value)}
                type="radio"
                value={2}
                label="API Pass In / Skip Screen"
              />
            </RadioGroup>
          </ModuleOption>
        </ModuleOptions>
      </ModuleSettings>
    </CollapsibleTableRow>
  );
};
PersonalDetailsModule.propTypes = {
  index: PropTypes.number.isRequired,
  indexToShow: PropTypes.number.isRequired,
};
export default PersonalDetailsModule;
