import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import SelectFilterContentToggleList from '../../../../../../../components/Select/components/SelectFilterContentToggleList';
import SelectTrigger from '../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../components/SelectField';
import authEnums from '../../../../../../../utilities/enums/authEnums';
import DateFromUntilPicker from '../../../../../../Kyc/components/DateFromUntilPicker';
import FilterItem from '../../../../../../Kyc/components/FilterItem';

const ReportsFilterForm = ({ users }) => {
  return (
    <div className="ickyc-filter-form">
      <Field component={FilterItem} label="Date" name="dateFlag">
        <DateFromUntilPicker dateFromName="dateFrom" dateUntilName="dateUntil" />
      </Field>
      <Field component={FilterItem} label="Type" name="userTypesFlag">
        <Field
          component={SelectField}
          placeholder="- Choose User Types -"
          withFilter
          name="userTypes"
          options={authEnums.ENTITY_TYPE_FILTER_OPTIONS}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
      <Field component={FilterItem} label="Requested By" name="requestedByFlag">
        <Field
          component={SelectField}
          placeholder="- Choose Requested By -"
          withFilter
          name="requestedBy"
          options={users}
          Trigger={<SelectTrigger />}
          Content={<SelectFilterContentToggleList />}
        />
      </Field>
    </div>
  );
};
ReportsFilterForm.propTypes = {
  users: PropTypes.arrayOf(PropTypes.node),
};
ReportsFilterForm.defaultProps = {
  users: [],
};
export default ReportsFilterForm;
