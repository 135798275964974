/**
 * Data displayed in the Identity Section
 * @param {object} data - data fetched from the server
 * @param {function} onDataFetch - function which caches fetched data
 * @param {documentId} documentId -  id of the opened document
 */
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import api from '../../../../../../../../api';
import Spinner from '../../../../../../../../components/Spinner';
import bus from '../../../../../../../../modules/bus';
import utilities from '../../../../../../../../utilities';
import enums from '../../../../../../../../utilities/enums';
import DateManager from '../../../../../../../../utilities/services/DateManager';
import { EntityContext, HistoryRecordContext } from '../../../../../../../../utilities/services/contexts';
import HistoryRecordControls from '../../../../../components/HistoryRecordControls';
import IssueList from '../../../../../components/IssueList';
import DocumentImage from './components/DocumentImage';
import FaceMatch from './components/FaceMatch';
import IdentityCheckList from './components/IdentityCheckList';
import IdentityDetails from './components/IdentityDetails';
import './index.scss';

const IdentityData = ({ data, onDataFetch }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { entityId } = useContext(EntityContext);
  const { eventHistoryId } = useContext(HistoryRecordContext);
  const [pending, setPending] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const generatePdfSummary = () => {
    setIsGenerating(true);
    api.kyc.entityManagement.naturalPerson
      .generatePdfSummary(entityId, eventHistoryId, data)
      .then(({ data: responseData, headers }) => {
        const fileName = Object.entries(headers)[0][1]
          .split(' ')[1]
          .replace('filename=', '')
          .replace(';', '');
        utilities.downloadFile(responseData, `application/pdf`, `${fileName}.pdf`);
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data: response } = await api.kyc.entityManagement.naturalPerson.getIdentityDocumentsTableRecord(
          entityId,
          eventHistoryId,
        );
        const { issues, checklist } = response;
        let updatedIssues = [...issues];
        if (issues.some(issue => issue === 'No Identity document received')) {
          updatedIssues = updatedIssues.filter(issue => issue !== 'No Identity document received');
        }

        const isManualUpload = issues.some(
          issue => issue === 'Manual upload, no Accepted Biometric present, verification wasn’t initiated',
        );
        if (isManualUpload) {
          setPending(true);
        }
        const formattedResponse = {
          ...response,
          checklist: isManualUpload
            ? { authentication: null, dataMatch: null, faceMatch: null, templateMatch: null }
            : checklist,
          issues: updatedIssues,
          details: {
            ...response.details,
            dateOfBirth:
              response.details.dateOfBirth === '0001-01-01 00:00:00'
                ? null
                : DateManager.parseDate(response.details.dateOfBirth, 'YYYY-MM-DD HH:mm:ss'),
            expiryDate:
              response.details.expiryDate === '0001-01-01 00:00:00'
                ? null
                : DateManager.parseDate(response.details.expiryDate, 'YYYY-MM-DD HH:mm:ss'),
          },
        };

        if (response.cropFrontImageUrl === null) {
          const imageRes = await api.kyc.entityManagement.naturalPerson.getIdentityRecordCroppedImage(
            entityId,
            eventHistoryId,
          );
          onDataFetch({
            ...formattedResponse,
            cropFrontImageUrl: imageRes.data ? `data:image/jpeg;base64,${imageRes.data}` : null,
          });
        } else {
          onDataFetch(formattedResponse);
        }
      } catch (err) {
        onDataFetch({ message: `Error Loading History Record: ${eventHistoryId}` });
      } finally {
        setIsLoading(false);
      }
    };

    if (JSON.stringify(data) !== '{}') {
      setIsLoading(false);
      return;
    }

    fetchData();
  }, [data, onDataFetch, entityId, eventHistoryId]);

  const updateDetails = useCallback(
    newData => {
      onDataFetch({
        ...data,
        details: newData,
      });
    },
    [onDataFetch, data],
  );
  const extractControlInfo = response => {
    const { sourceType, sourceDetail, status, portalConfigurationId, portalDomain, portalName } = response;

    return {
      sourceType,
      sourceDetail,
      status,
      portalConfigurationId,
      portalDomain,
      portalName,
    };
  };

  const handleStatusChange = useCallback(
    (issuesChange, issuesList, __status, allRejected) => {
      bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
        type: 'kyc',
        section: enums.ACCORDION_INDEXES.IDENTITY_DOCUMENTS,
        issuesChange,
        allRejected,
      });
      onDataFetch({ ...data, issues: issuesList });
    },
    [onDataFetch, data],
  );

  if (isLoading) {
    return (
      <div className="ickyc-identity-record__wrapper">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="ickyc-identity-record__wrapper">
      {data.message ? (
        <div className="ickyc-identity-record__message">{data.message}</div>
      ) : (
        <>
          <div className="ickyc-identity-record">
            <IssueList list={data.issues} />

            <div className="ickyc-identity-record__content">
              <div className="ickyc-identity-record__content__left">
                <IdentityDetails
                  details={data.details}
                  handleUpdate={updateDetails}
                  fullAddress={data?.details?.fullAddress}
                />
              </div>
              <div className="ickyc-identity-record__content__right">
                <IdentityCheckList
                  checklist={data.checklist}
                  generatePdf={generatePdfSummary}
                  isGenerating={isGenerating}
                  isFaceMatchAvailable={data.faceMatchStatus !== 'Unavailable'}
                />

                <DocumentImage frontImageUrl={data.frontImageImageUrl} backImageUrl={data.backImageUrl} />
                <FaceMatch
                  cropped={data.cropFrontImageUrl}
                  face={data.faceImageUrl}
                  success={data.checklist?.faceMatch}
                  isFaceMatchAvailable={data.faceMatchStatus !== 'Unavailable'}
                  pending={pending}
                  procentage={data?.details?.biometricConfidence}
                />
              </div>
            </div>
          </div>

          <HistoryRecordControls info={extractControlInfo(data)} onStatusChange={handleStatusChange} />
        </>
      )}
    </div>
  );
};

IdentityData.propTypes = {
  data: PropTypes.shape({
    backImageUrl: PropTypes.string,
    checklist: PropTypes.shape({
      templateMatch: PropTypes.bool,
      faceMatch: PropTypes.bool,
      authentication: PropTypes.bool,
      dataMatch: PropTypes.bool,
    }),
    cropFrontImageUrl: PropTypes.string,
    details: PropTypes.shape({
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
      unit: PropTypes.string,
      street: PropTypes.string,
      city: PropTypes.string,
      stateProvinceAbbreavation: PropTypes.string,
      zip: PropTypes.string,
      dateOfBirth: PropTypes.string,
      documentNumber: PropTypes.string,
      expiryDate: PropTypes.string,
      documentCountryAlpha: PropTypes.string,
      documentJurisdictionId: PropTypes.number,
      documentCountry: PropTypes.string,
      documentJurisdiction: PropTypes.string,
      fullAddress: PropTypes.string,
      biometricConfidence: PropTypes.number,
    }),
    faceImageUrl: PropTypes.string,
    frontImageImageUrl: PropTypes.string,
    issues: PropTypes.arrayOf(PropTypes.string),
    oldIssues: PropTypes.arrayOf(PropTypes.string),
    sessionId: PropTypes.string,
    sourceType: PropTypes.string,
    sourceDetail: PropTypes.string,
    status: PropTypes.string,
    message: PropTypes.string,
  }),
  onDataFetch: PropTypes.func,
};

IdentityData.defaultProps = {
  data: {},
  onDataFetch: () => {},
};
export default IdentityData;
