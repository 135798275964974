/* eslint-disable no-alert */
/**
 * Modal for adding data about Natural Person
 * @param {reference} containerRef - reference of the page containing the modal
 * @param {func} hideModal - display flag setter
 * @param {func} setNewlyAdded - setting locally new entities
 * @param {boolean} multipleEntities - enables creating multiple entities
 * @param {boolean} withRoles - should list roles be attached to entity
 */
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import toastr from 'toastr';
import api from '../../../../../../../../api';
import FormModal from '../../../../../../../../components/Modal/FormModal';
import SelectFilterContent from '../../../../../../../../components/Select/components/SelectFilterContent';
import SelectTrigger from '../../../../../../../../components/Select/components/SelectTrigger';
import SelectField from '../../../../../../../../components/SelectField';
import bus from '../../../../../../../../modules/bus';
import enums from '../../../../../../../../utilities/enums';
import validators from '../../../../../../../../utilities/services/validators';
import './styles.scss';

const AttachRolesModal = ({ hideModal, setNewlyAdded, parentEntityId, affEntityId, entityType, initialRoles }) => {
  const [loading] = useState(false);

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (entityType === 0) {
      setRoles(enums.ATTACH_NP_ENTITY_ROLES);
    } else {
      setRoles(enums.OWNERSHIP_TAGS);
    }
  }, [parentEntityId, entityType]);

  const handleSubmit = async values => {
    const { roles: r } = values;
    return api.kyc.entityManagement.legalEntity
      .updateAffiliatedEntityRoles(parentEntityId, affEntityId, { roles: r, entityType })
      .then(({ data }) => {
        if (data.comment) {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_LOG_COMMENT, data?.comment);
        }
        setNewlyAdded(data?.entity);
        toastr.success('Roles were added');
        hideModal();
      })
      .catch(err => {
        if (err?.response) {
          const { status, data: errData } = err.response;
          if (status === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
          if (status >= 400 && status < 500) {
            return { [FORM_ERROR]: errData?.message || 'Error Occured' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };
  return (
    <FormModal
      onSubmit={handleSubmit}
      hideModal={hideModal}
      className="ickyc-attach-roles-modal"
      initialValues={{ roles: initialRoles }}
      title={
        <h3>
          <b>Add {entityType ? 'Roles' : 'Relationships'} </b>
        </h3>
      }
    >
      {entityType ? (
        <span className="ickyc-attach-roles-modal__header">
          Please &nbsp; <b>select the roles</b> &nbsp; that this entity serves within the organization.
        </span>
      ) : (
        <span className="ickyc-attach-roles-modal__header">
          Please &nbsp; <b>select the relationships</b>.
        </span>
      )}
      <Field
        component={SelectField}
        Trigger={<SelectTrigger />}
        Content={<SelectFilterContent />}
        loading={loading}
        options={roles}
        name="roles"
        validate={validators.required()}
        renderAsPortal
        multipleSelection
      />
    </FormModal>
  );
};

AttachRolesModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  setNewlyAdded: PropTypes.func.isRequired,
  parentEntityId: PropTypes.string.isRequired,
  affEntityId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  initialRoles: PropTypes.arrayOf(PropTypes.string),
};
AttachRolesModal.defaultProps = {
  initialRoles: [],
};

export default AttachRolesModal;
