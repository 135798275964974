import { capitalize } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import RangeSlider from '../../../../../components/RangeSlider';
import TextareaField from '../../../../../components/TextareaField';
import ToggleField from '../../../../../components/ToggleField';
import setClassSuffix from '../../../../../utilities/services/ClassManager';
import IconManager from '../../../../../utilities/services/IconManager';
import './styles.scss';

const FaceExpressionsSection = ({ index, facialExpressionFieldsPrefixName, showRejectionMessageField, disabled }) => {
  const [prefix, setPrefix] = useState(`modules[${index}]`);

  const emotionEnum = {
    HAPPY: 'happy',
    SAD: 'sad',
    SURPRISED: 'surprised',
    ANGRY: 'angry',
    NEUTRAL: 'neutral',
  };

  const iconMapFragment = new Map([
    [emotionEnum.HAPPY, IconManager.get(IconManager.names.SMILE)],
    [emotionEnum.SAD, IconManager.get(IconManager.names.SAD)],
    [emotionEnum.SURPRISED, IconManager.get(IconManager.names.SURPRISE)],
    [emotionEnum.ANGRY, IconManager.get(IconManager.names.ANGRY)],
    [emotionEnum.NEUTRAL, IconManager.get(IconManager.names.NEUTRAL_SMILE)],
  ]);

  const emotionFieldNamesMapFragment = new Map([
    [emotionEnum.HAPPY, `${facialExpressionFieldsPrefixName}.happyRequired`],
    [emotionEnum.SAD, `${facialExpressionFieldsPrefixName}.sadRequired`],
    [emotionEnum.SURPRISED, `${facialExpressionFieldsPrefixName}.surprisedRequired`],
    [emotionEnum.ANGRY, `${facialExpressionFieldsPrefixName}.angryRequired`],
    [emotionEnum.NEUTRAL, `${facialExpressionFieldsPrefixName}.neutralRequired`],
  ]);
  const emotionFieldValuesMapFragment = new Map([
    [emotionEnum.HAPPY, `${facialExpressionFieldsPrefixName}.happyConfidenceThreshold`],
    [emotionEnum.SAD, `${facialExpressionFieldsPrefixName}.sadConfidenceThreshold`],
    [emotionEnum.SURPRISED, `${facialExpressionFieldsPrefixName}.surprisedConfidenceThreshold`],
    [emotionEnum.ANGRY, `${facialExpressionFieldsPrefixName}.angryConfidenceThreshold`],
    [emotionEnum.NEUTRAL, `${facialExpressionFieldsPrefixName}.neutralConfidenceThreshold`],
  ]);

  const toggleKeyGenerator = emotion =>
    facialExpressionFieldsPrefixName === 'facialMatching'
      ? emotionFieldNamesMapFragment.get(emotion)
      : `modules[${index}].emotions.${emotion}.enabled`;

  const thresholdKeyGenerator = emotion =>
    facialExpressionFieldsPrefixName === 'facialMatching'
      ? emotionFieldValuesMapFragment.get(emotion)
      : `modules[${index}].emotions.${emotion}.value`;

  const emotions = [
    emotionEnum.NEUTRAL,
    emotionEnum.HAPPY,
    emotionEnum.SAD,
    emotionEnum.SURPRISED,
    emotionEnum.ANGRY,
  ].map(emotion => {
    const toggleKey = toggleKeyGenerator(emotion);
    const thresholdKey = thresholdKeyGenerator(emotion);

    return {
      name: emotion,
      icon: iconMapFragment.get(emotion),
      label: capitalize(emotion),
      toggleKey,
      thresholdKey,
    };
  });

  useEffect(() => {
    if (facialExpressionFieldsPrefixName !== '') {
      setPrefix(facialExpressionFieldsPrefixName);
    }
  }, [facialExpressionFieldsPrefixName]);

  const baseClass = 'ickyc-face-expression';
  const setSuffix = setClassSuffix(baseClass);
  return (
    <>
      <h2>Facial Expressions</h2>
      <div className={setSuffix('--liveness-emotions')}>
        {emotions.map(emotion => (
          <div className={setSuffix('--liveness-emotion')} key={emotion.name}>
            {emotion.icon}

            {emotion.name === 'neutral' ? (
              <div className={setSuffix('--liveness-emotion--lock')}>
                <Lock />
                <span>{emotion.label}</span>
              </div>
            ) : (
              <Field component={ToggleField} disabled={disabled} name={emotion.toggleKey} label={emotion.label} />
            )}

            <Field name={emotion.thresholdKey}>
              {({ input }) => (
                <RangeSlider
                  label="Pass when confidence is above:"
                  initialValue={input.value}
                  callback={input.onChange}
                  disabled={disabled}
                />
              )}
            </Field>
          </div>
        ))}
      </div>
      <div className={setSuffix('--liveness-option')}>
        <Field
          component={ToggleField}
          name={`${prefix}.finalTestSmile`}
          disabled={disabled}
          label={
            <>
              {IconManager.get(IconManager.names.SMILE)}
              <p>
                <strong>End on a high note! </strong>
                Make the final Liveness Test be a check for Happy emotion.
              </p>
            </>
          }
        />
      </div>
      {showRejectionMessageField && (
        <div className={setSuffix('--liveness-option')}>
          <Field
            name={`${prefix}.failbackToSelfieUpload`}
            component={ToggleField}
            disabled={disabled}
            label={
              <p>
                <strong>Fallback to Selfie Upload </strong>
                If the Liveness Test is not supported by the user's device, prompt the user to do a Selfie Upload
                instead.
              </p>
            }
          />
        </div>
      )}
      <div className={setSuffix('--liveness-option')}>
        <Field
          name="globalSettings.failbackToSelfieUploadMessage"
          component={TextareaField}
          disabled={disabled}
          label="
          If the Liveness Test is not supported by the user's device and fallback to Selfie Upload is disabled,
          display message to user.
        "
        />
      </div>
    </>
  );
};
FaceExpressionsSection.propTypes = {
  index: PropTypes.number.isRequired,
  facialExpressionFieldsPrefixName: PropTypes.string,
  showRejectionMessageField: PropTypes.bool,
  disabled: PropTypes.bool,
};
FaceExpressionsSection.defaultProps = {
  facialExpressionFieldsPrefixName: undefined,
  showRejectionMessageField: false,
  disabled: false,
};
export default FaceExpressionsSection;
