import { useEffect } from 'react';

/**
 * Window listener used to hide responsive navigation on resizing to wider screen
 * @param {func} hide - function which hides navigation
 */

const useWindowResizeListener = hide => {
  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 1024) {
        hide();
      }
    };

    window.addEventListener('resize', hideMenu);

    const cleanUp = () => {
      window.removeEventListener('resize', hideMenu);
    };

    return cleanUp;
  }, [hide]);
};

export default useWindowResizeListener;
