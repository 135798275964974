import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field, useField, useForm, useFormState } from 'react-final-form';
import api from '../../../../../../../../../../api';
import validators from '../../../../../../../../../../utilities/services/validators';
import DocumentDropzone from '../../../../../../../components/DocumentDropzone';
import { DOCUMENT_TYPES, DOCUMENT_UPLOAD_TITLES } from '../../utilities/documentUploadEnums';
import DocumentBoundsDetectionPreview from '../DocumentBoundsDetectionPreview';

const BackImageStep = ({ changeTitle, index }) => {
  const [uploadStarted, setUploadStarted] = useState(false);
  const [errorOnSubmit, setErrorOnSubmit] = useState(null);

  const {
    input: { value: docImage },
  } = useField('backImage');
  const {
    values: { scanDocumentType, image },
  } = useFormState();

  const { change } = useForm();

  useEffect(() => {
    if (scanDocumentType === DOCUMENT_TYPES.DRIVERS_LICENCE) {
      changeTitle(index, DOCUMENT_UPLOAD_TITLES.BACK_DRIVERS_LICENCE);
    }
    if (scanDocumentType === DOCUMENT_TYPES.ID) {
      changeTitle(index, DOCUMENT_UPLOAD_TITLES.BACK_ID);
    }
  }, [changeTitle, index, scanDocumentType]);

  useEffect(() => {
    const uploadImage = img => {
      setUploadStarted(true);
      change('canSubmit', false);
      setErrorOnSubmit(null);
      const formData = new FormData();
      formData.append('file', img.file);
      api.kyc.entityManagement.naturalPerson
        .uploadFile(formData)
        .then(response => {
          console.log(response);
          change('backImageName', response?.data?.uploadedName);
          change('canSubmit', true);
        })
        .catch(error => {
          setErrorOnSubmit(
            error?.response?.data?.message || 'Uploading image resulted with error, please try reaploading new photo',
          );
          change('canSubmit', false);
        })
        .finally(() => {
          setUploadStarted(false);
        });
    };

    if (docImage) {
      if (!uploadStarted) uploadImage(docImage);
    }
  }, [docImage]);
  return (
    <Field
      name="backImage"
      validate={validators.required()}
      component={DocumentDropzone}
      initialValue={undefined}
      title="Back Of Your Photo ID"
      accept="image/*"
      exifCorrection
    >
      <DocumentBoundsDetectionPreview
        initialRotation={image.data.RotationAngle}
        errorOnSubmit={errorOnSubmit}
        uploadingImage={uploadStarted}
      />
    </Field>
  );
};
BackImageStep.propTypes = {
  changeTitle: PropTypes.func,
  index: PropTypes.number,
};
BackImageStep.defaultProps = {
  changeTitle: () => {},
  index: 0,
};
export default BackImageStep;
