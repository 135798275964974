import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../../../../../api';
import Accordion from '../../../../../../../components/Accordion';
import CheckboxTable from '../../../../../../../components/Tables/CheckboxTable';
import TableHeader from '../../../../../../../components/Tables/Table/components/TableHeader';
import enums from '../../../../../../../utilities/enums';
import { CaseContext } from '../../../../../../../utilities/services/contexts';
import DocumentListingRow from './components/DocumentListingRow';
import './styles.scss';

const DocumenstListings = ({ complianceLogId, hitId, selectedStatus, shouldOpen, count }) => {
  const [params] = useState({ sortCriteria: 'name', sortOrder: 0 });
  const [pagination, setPagination] = useState({});

  const [documents, setDocuments] = useState([]);

  const [loading, setLoading] = useState(false);
  const { isCaseClosed } = useContext(CaseContext);

  useEffect(() => {
    const fetchHitListings = () => {
      setLoading(true);
      api.kyc.caseManagement
        .getHitDocuments({ hitId, complianceLogId, params: { status: selectedStatus, ...params } })
        .then(({ data: response }) => {
          setDocuments(response.data.map(url => ({ id: Math.random(), url })));
          setPagination(response.paginationData);
        })
        .finally(setLoading(false));
    };
    fetchHitListings();
  }, [complianceLogId, hitId, params, selectedStatus]);

  return (
    <Accordion
      title={<h3>Documents</h3>}
      accented
      badgeCount={count}
      className="ickyc-hit-documents-accordion"
      accordionindex={enums.ACCORDION_INDEXES.DOCUMENTS_LISTINGS}
      accordionOpen={shouldOpen}
      withBadge
    >
      <CheckboxTable
        disabled={isCaseClosed}
        values={documents}
        pagination={pagination}
        tableRow={DocumentListingRow}
        withPagination
        headerRow={TableHeader}
        // TableControlsComponent={MatchesControls}
        updating={loading}
        headerData={{
          sortCriteria: params.sortCriteria,
          sortOrder: params.sortOrder,
          items: [
            {
              type: 'checkbox',
            },
            {
              type: 'ongoingAlert',
            },
            {
              type: 'icon',
            },
            {
              type: 'source',
              label: 'URL',
            },
          ],
        }}
      />
    </Accordion>
  );
};

DocumenstListings.propTypes = {
  complianceLogId: PropTypes.string.isRequired,
  hitId: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  shouldOpen: PropTypes.bool,
  count: PropTypes.number,
};
DocumenstListings.defaultProps = {
  shouldOpen: false,
  count: 0,
};
export default DocumenstListings;
