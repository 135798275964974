import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';

const RiskProfileSelectRow = ({ rowInfo, className, onClick }) => {
  const { id, name, hovered, profileName, selected, disabled } = rowInfo;
  const handleOnCLick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const setColumnSuffix = setClassSuffix(`${className}__column`);

  const classes = classNames({
    [`${className}__row--allowed`]: !disabled,
    [`${className}__row--selected`]: selected,
    [`${className}__row--hovered`]: hovered && !disabled,
    [`${className}__row--disabled`]: disabled,
    [`${className}__row`]: true,
  });

  return (
    <tr key={id} className={classes} onClick={handleOnCLick}>
      <td className={setColumnSuffix('__name')}>{profileName || name}</td>
    </tr>
  );
};
RiskProfileSelectRow.propTypes = {
  rowInfo: PropTypes.shape({
    name: PropTypes.string,
    profileName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    hovered: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
  className: PropTypes.string,
  onClick: PropTypes.func,
};
RiskProfileSelectRow.defaultProps = {
  rowInfo: {
    name: '',
    hovered: false,
    selected: false,
    disabled: false,
  },
  className: '',
  onClick: () => {},
};
export default RiskProfileSelectRow;
