import classNames from 'classnames';
import DeleteButton from 'components/Buttons/DeleteButton';
import Tooltip from 'components/Tooltip';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import toastr from 'toastr';
import api from '../../../../../../../api';
import Badge from '../../../../../../../components/Badge';
import FormModal from '../../../../../../../components/Modal/FormModal';
import TagsContainer from '../../../../../../../components/TagButtonContainer';
import useModalHandler from '../../../../../../../hooks/useModalHandler';
import utilities from '../../../../../../../utilities';
import routesEnum from '../../../../../../../utilities/enums/routesEnum';
import capitalSplit from '../../../../../../../utilities/services/CapitalSplitter';
import setClassSuffix from '../../../../../../../utilities/services/ClassManager';
import DateManager from '../../../../../../../utilities/services/DateManager';
import {
  EntityContext,
  HistoryRecordContext,
  PermissionGroupContext,
} from '../../../../../../../utilities/services/contexts';
import AssignToUserDropdown from '../../../../../components/AssignToUserDropdown';
import AttachEntitiesModal from '../AttachEntitiesModal';
import './styles.scss';

const DocumentsRecord = ({ className, onRemove }) => {
  const rowInfo = useContext(HistoryRecordContext);
  const history = useHistory();
  const {
    assignedUserId,
    date,
    attachedEntities: attachedTo,
    deletedPackageName,
    packageName,
    _documentName,
    status,
    issues,
    eventHistoryId,
    _services,
  } = rowInfo;
  const setSuffix = setClassSuffix(`${className}__column`);
  const { entityId } = useContext(EntityContext);
  const [confirmationModal, setConfirmModalOpen] = useState(false);

  const onHistoryRecordDelete = useCallback(() => {
    return api.kyc.entityManagement
      .deleteDocumentHistoryRecordsSoft(entityId, eventHistoryId)
      .then(() => {
        setConfirmModalOpen(false);
        onRemove(eventHistoryId);
        toastr.success(`History Record has been removed`);
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data: resData } = err.response;
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: rowInfo.message };
          }
          if (resStatus === 500) {
            return { [FORM_ERROR]: resData.message || 'Internal Server Error, Try Again Later' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  }, [entityId, onRemove, eventHistoryId, rowInfo.message]);

  const onRemoveIconClick = useCallback(e => {
    e.stopPropagation();
    setConfirmModalOpen(true);
  }, []);
  const preventBubbling = event => {
    event.stopPropagation();
  };

  // TODO FIX UPDATING
  const [, setUpdating] = useState(false);

  const [attachedEntities, setAttachedEntities] = useState(
    attachedTo?.map(({ entityId: eId, ...r }) => ({ id: eId, ...r })),
  );
  const { isOpen: isAttachModalOpen, close: closeAttachmentModal, open: openAttachmentModal } = useModalHandler();

  const detachUser = entity => {
    setUpdating(true);
    const entitiesToKeep = attachedEntities.filter(ae => ae.id !== entity.id).map(({ id }) => id);
    api.kyc.entityManagement
      .attachEntitiesToDocuments(entityId, eventHistoryId, entitiesToKeep)
      .then(() => {
        toastr.success(`Entity: ${entity.name}is remove from case`);
        setAttachedEntities(prev => prev.filter(en => en.id !== entity.id));
      })
      .finally(() => {
        setUpdating(false);
      });
  };
  const packageNameClasses = classNames(setSuffix('__type'), {
    [setSuffix('__type--undefined')]: packageName === 'Undefined Document Type',
  });
  const attachEntitites = values => {
    const { entities } = values;
    const dataSubmit = entities.map(({ id }) => id);
    return api.kyc.entityManagement
      .attachEntitiesToDocuments(entityId, eventHistoryId, dataSubmit)
      .then(() => {
        setAttachedEntities(entities.map(({ id, label }) => ({ id, name: label })));

        toastr.success('Entities are Successfully attached to Document');
        closeAttachmentModal();
      })
      .catch(err => {
        if (err?.response) {
          const { status: resStatus, data: errData } = err.response;
          if (resStatus === 500) {
            return { [FORM_ERROR]: 'Internal Server Error, Try Again Later' };
          }
          if (resStatus >= 400 && resStatus < 500) {
            return { [FORM_ERROR]: errData?.message || 'Error Occured' };
          }
        } else return { [FORM_ERROR]: 'Error occured' };
      });
  };
  const navigateToEntityProfile = ({ type, id: eId }) => {
    history.push(
      utilities.routeParams(type === 'Natural Person' ? routesEnum.kyc.NATURAL_PERSON : routesEnum.kyc.LEGAL_ENTITY, {
        id: eId,
      }),
    );
  };
  const { edit: canEdit, delete: canDelete } = useContext(PermissionGroupContext);
  return (
    <>
      <td className={setSuffix('__date')}>
        <span>{DateManager.getFullDateLocal(date)}</span>
      </td>
      <td className={packageNameClasses}>
        {deletedPackageName ? (
          <Tooltip trigger={<b>{capitalSplit(deletedPackageName)}</b>} content="Undefined document type" />
        ) : (
          <b>{capitalSplit(packageName)}</b>
        )}
      </td>
      <td className={setSuffix('__attached')} onClick={preventBubbling}>
        {isAttachModalOpen && (
          <AttachEntitiesModal
            initialValues={attachedEntities.map(({ name, ...r }) => ({ label: name, ...r }))}
            hideModal={closeAttachmentModal}
            onSubmit={attachEntitites}
            renderAsPortal
          />
        )}
        <TagsContainer
          asTagButton={canEdit}
          options={attachedEntities}
          onRemove={detachUser}
          onAdd={openAttachmentModal}
          onClick={navigateToEntityProfile}
        />
      </td>
      <td className={setSuffix('__assignedTo')}>
        <AssignToUserDropdown
          renderAsPortal
          eventId={eventHistoryId}
          assignEvent
          assignedUserId={assignedUserId}
          preview={!canEdit}
        />
      </td>
      <td className={setSuffix('__status')}>
        <span>{capitalSplit(status)}</span>
      </td>
      <td className={setSuffix('__issues')}>
        <Badge content={issues} red />
      </td>
      <td className={setSuffix('__remove')} onClick={preventBubbling}>
        {canDelete && <DeleteButton onClick={onRemoveIconClick} />}
        {confirmationModal && (
          <FormModal
            hideModal={() => setConfirmModalOpen(false)}
            title="Remove Record"
            className="ickyc-confirmation-modal"
            onSubmit={onHistoryRecordDelete}
          >
            <span className="ickyc-confirmation-message">Are you sure you want to delete record ?</span>
          </FormModal>
        )}
      </td>
    </>
  );
};

DocumentsRecord.propTypes = {
  className: PropTypes.string,
  onRemove: PropTypes.func,
  hasServices: PropTypes.bool,
};
DocumentsRecord.defaultProps = {
  className: undefined,
  onRemove: () => {},
  hasServices: false,
};
export default DocumentsRecord;
