export const SCREENING_SOURCES_GROUPS = {
  ALL_SANTIONS: [0, 1, 2],
  ALL_PEPS: [4, 5, 6, 7],
  ALL_ADVERSE: [9, 13, 10, 14, 11, 15, 12],
};

export const SCREENING_SOURCES = {
  16: 'All Sanctions, Warnings and Fitness & Probity',
  0: 'Sanctions e.g. OFAC SDN List, HM Treasury Sanctions List',
  1: 'Warnings e.g. US Immigration and Customs Wanted',
  2: 'Fitness & Probity e.g. US System for Award Management Exclusions',
  3: 'All PEPs',
  4: 'PEP CLASS 1 Heads of State, National Parliaments, National Governments',
  5: 'PEP CLASS 2 Regional Governments, Regional Parliaments',
  6: 'PEP CLASS 3 Senior Management & Boards of SOEs',
  7: 'PEP CLASS 4 Mayors and Local City Councils',
  8: 'All Adverse Media',
  9: 'Financial Crime',
  10: 'Violent Crime',
  11: 'Sexual Crime',
  12: 'Terrorism',
  13: 'Fraud',
  14: 'Narcotics',
  15: 'Other Crime',
};

export const AML_FILTERS = {
  16: 'All Sanctions, Warnings, Fitness + Probity',
  0: 'Sanctions',
  1: 'Warnings',
  2: 'Fitness + Probity',
  3: 'All PEPs',
  4: 'PEP CLASS 1',
  5: 'PEP CLASS 2',
  6: 'PEP CLASS 3',
  7: 'PEP CLASS 4',
  8: 'All Adverse Media',
  9: 'Financial Crime',
  10: 'Violent Crime',
  11: 'Sexual Crime',
  12: 'Terrorism',
  13: 'Fraud',
  14: 'Narcotics',
  15: 'Other Crime',
};

export const AML_FILTER_TYPES = {
  DISABLED: 0,
  SELECTED: 1,
  MATCHED: 2,
  REQUESTED: 3,
};

export const CASE_MATCH_STATUS = {
  POTENTIAL: 'potential',
  UNKNOWN: 'unknown',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
};

export const HIT_MATCH_TYPES = {
  NAME_EXACLY: 'Name matched exactly',
  AKA_EXACTLY: 'Aka matched exactly',
  NAME_CLOSELY: 'Name matched closely',
  AKA_CLOSELY: 'Aka matched closely',
  NAME_PHONETICALLY: 'Name matched phonetically',
  AKA_PHONETICALLY: 'Aka matched phonetically',
  NAME_SYNONYM: 'Name matched with a synonym',
  AKA_SYNONYM: 'Aka matched with a synonym',
  UNKWNOWN: 'Unknown',
};

export const AML_FILTER_DROPDOWN_OPTIONS = [
  {
    value: 'all-sanction-warning-fitness',
    id: 'All Sanctions, Warnings, Fitness + Probity',
    label: 'All Sanctions, Warnings, Fitness + Probity',
  },
  { value: 'sanction', id: 'Sanctions', label: 'Sanctions' },
  { value: 'warning', id: 'Warnings', label: 'Warnings' },
  { value: 'fitness-probity', id: 'Fitness + Probity', label: 'Fitness + Probity' },
  { value: 'pep', id: 'All PEPs', label: 'All PEPs' },
  { value: 'pep-class-1', id: 'PEP CLASS 1', label: 'PEP CLASS 1' },
  { value: 'pep-class-2', id: 'PEP CLASS 2', label: 'PEP CLASS 2' },
  { value: 'pep-class-3', id: 'PEP CLASS 3', label: 'PEP CLASS 3' },
  { value: 'pep-class-4', id: 'PEP CLASS 4', label: 'PEP CLASS 4' },
  { value: 'adverse-media', id: 'All Adverse Media', label: 'All Adverse Media' },
  { value: 'adverse-media-financial-crime', id: 'Financial Crime', label: 'Financial Crime' },
  { value: 'adverse-media-violent-crime', id: 'Violent Crime', label: 'Violent Crime' },
  { value: 'adverse-media-sexual-crime', id: 'Sexual Crime', label: 'Sexual Crime' },
  { value: 'adverse-media-terrorism', id: 'Terrorism', label: 'Terrorism' },
  { value: 'adverse-media-fraud', id: 'Fraud', label: 'Fraud' },
  { value: 'adverse-media-narcotics', id: 'Narcotics', label: 'Narcotics' },
  { value: 'adverse-media-general', id: 'Other Crime', label: 'Other Crime' },
];

export const CASE_ENTITY_TYPES = ['Person', 'Company', 'Organization', 'Vessel', 'Aircraft'];

export const SEARCH_FORM_CASE_ENTITY_TYPES = ['Any', 'Person', 'Company', 'Organization', 'Vessel', 'Aircraft'];
