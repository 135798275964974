import api from 'api';
import OutlineButton from 'components/Buttons/OutlineButton';
import IComplyForm from 'components/Form/IComplyForm';
import SelectFilterContent from 'components/Select/components/SelectFilterContent';
import SelectTrigger from 'components/Select/components/SelectTrigger';
import SelectField from 'components/SelectField';
import TextareaField from 'components/TextareaField';
import useModalHandler from 'hooks/useModalHandler';
import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import RequestClientUpdateLegalEntityModal from 'scenes/Kyc/EntityManagement/components/RequestClientUpdateLegalEntityModal';
import RequestClientUpdateNaturalPersonModal from 'scenes/Kyc/EntityManagement/components/RequestClientUpdateNaturalPersonModal';
import toastr from 'toastr';
import enums from 'utilities/enums';
import IconManager from 'utilities/services/IconManager';
import { DocumentDataContext, EntityContext, PermissionGroupContext } from 'utilities/services/contexts';
import './styles.scss';
const EditDocumentDetails = ({ details, setEditMode }) => {
  const { entityId, isNaturalPerson } = useContext(EntityContext);
  const { isOpen: rcuOpen, open: openRCU, close: closeRCU } = useModalHandler();
  const { isOpen: rcuLegalEntityOpen, open: openLegalEntityRCU, close: closeLegalEntityRCU } = useModalHandler();
  const { requestClientUpdate: canRCU } = useContext(PermissionGroupContext);
  const containerRef = useRef(null);
  const {
    documentRecord: { eventHistoryId },
  } = useContext(DocumentDataContext);

  const usersToAssign = useSelector(state =>
    state.global.usersToAssign.map(({ id: uId, name }) => ({
      label: name,
      value: uId,
      id: uId,
    })),
  );

  const mappedStatuses = useMemo(() => {
    return Object.entries(enums.HISTORY_EVENT_STATUSES).map(status => ({
      id: status[0],
      label: status[1],
      value: status[1],
    }));
  }, []);

  const { packageName, sourceType, uploadedBy, packageId, ...rest } = details;

  const handleSubmit = useCallback(
    async values => {
      try {
        await api.kyc.entityManagement.updateSupportingPackageDetails({
          entityId,
          eventHistoryId,
          ...values,
        });
        toastr.success('Supporting Package details updated successfully.');
      } catch (err) {
        console.error(err);
      }
    },
    [entityId, eventHistoryId],
  );

  const handleDiscard = useCallback(() => {
    setEditMode(false);
  }, []);

  return (
    <IComplyForm
      initialValues={rest}
      onSubmit={handleSubmit}
      showControls
      onDiscardClick={handleDiscard}
      div
      ref={containerRef}
    >
      {({ form: { change } }) => (
        <>
          <div className="ickyc-document-details__preview-data-box">
            <b>Document Package</b>
            <span>{packageName}</span>
          </div>
          <Field
            label="Assigned To"
            name="assignedUserId"
            component={SelectField}
            options={usersToAssign}
            Trigger={<SelectTrigger placeholder="Choose Assigned To" />}
            Content={<SelectFilterContent />}
            labelPosition="top"
          />
          <div className="ickyc-document-details__status-box">
            <Field
              label="Status"
              name="status"
              component={SelectField}
              options={mappedStatuses}
              Trigger={<SelectTrigger />}
              Content={<SelectFilterContent />}
              labelPosition="top"
            />
            <div className="ickyc-document-details__status-hotkey-box">
              <OutlineButton
                variant="red"
                onClick={() => change('status', enums.HISTORY_EVENT_STATUSES.REJECTED)}
                left={IconManager.get(IconManager.names.CLOSE)}
              >
                Reject
              </OutlineButton>
              <OutlineButton
                onClick={() => change('status', enums.HISTORY_EVENT_STATUSES.ACCEPTED)}
                left={IconManager.get(IconManager.names.CHECK_FILLED)}
              >
                Accept
              </OutlineButton>
            </div>
          </div>

          <Field label="Comment" name="comment" component={TextareaField} labelPosition="top" />

          {canRCU && (
            <OutlineButton onClick={isNaturalPerson ? openRCU : openLegalEntityRCU}>
              Request Client Update
            </OutlineButton>
          )}

          <div className="ickyc-document-details__preview-data-box">
            <b>Source Type</b>
            <span>{sourceType}</span>
          </div>

          <div className="ickyc-document-details__preview-data-box">
            <b>Uploaded By</b>
            <span>{uploadedBy}</span>
          </div>
          {rcuOpen && (
            <RequestClientUpdateNaturalPersonModal
              initialSupportingPackage={packageId}
              supportingDocumentPackagesEnabled
              hideModal={closeRCU}
            />
          )}
          {rcuLegalEntityOpen && <RequestClientUpdateLegalEntityModal hideModal={closeLegalEntityRCU} />}
        </>
      )}
    </IComplyForm>
  );
};
export default EditDocumentDetails;
