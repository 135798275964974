import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import api from '../../../api';
import useSelect from '../../../hooks/useSelect';
import { selectUserAccessByLicense } from '../../../store/selectors/auth.selector';
import { PortalManagementContext } from '../../../utilities/services/contexts';
import PortalManagement from '../PortalManagementPage';

const PortalManagementWrapper = ({ className }) => {
  const classes = classNames('ickyc-page', className);
  const platformAccess = useSelect(selectUserAccessByLicense);
  return (
    <div className={classes}>
      <PortalManagementContext.Provider
        value={{
          getAllNaturalPortalsRoute: api.administration.portalManagement.getPortalsConfigurationsNaturalPerson,
          getAllLegalEntityPortalsRoute: api.administration.portalManagement.getPortalsConfigurationsLegalEntity,
          updateConfigurationLogoImage: api.administration.portalManagement.updateConfigurationLogoImage,
          createPortalsConfiguration: api.administration.portalManagement.createPortalsConfiguration,
          updateConfigurationNP: api.administration.portalManagement.updatePortalsConfigurationNaturalPerson,
          updateConfigurationLE: api.administration.portalManagement.updatePortalsConfigurationLegalEntity,
          fetchExistingConfiguration: api.administration.portalManagement.getPortalsConfigurationById,
          updateDefault: api.administration.portalManagement.updateDefault,
          deleteConfiguration: api.administration.portalManagement.deleteConfiguration,
          getRiskScreeningProfiles: api.administration.portalManagement.getRiskScreeningProfiles,
          withTitle: true,
          accessByLicence: platformAccess,
          clientId: null,
        }}
      >
        <PortalManagement />
      </PortalManagementContext.Provider>
    </div>
  );
};
PortalManagementWrapper.propTypes = {
  className: PropTypes.string,
};

PortalManagementWrapper.defaultProps = {
  className: undefined,
};
export default PortalManagementWrapper;
