import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import api from '../../../../../../../../api';
import WizardFormModal from '../../../../../../../../components/Modal/WizardFormModal';
import WizardFormPage from '../../../../../../../../components/Modal/WizardFormModal/components/WizardFormPage';
import bus from '../../../../../../../../modules/bus';
import enums from '../../../../../../../../utilities/enums';
import { EntityContext } from '../../../../../../../../utilities/services/contexts';
import BackImageStep from './components/BackImagePage';
import DocumentTypeSelection from './components/DocumentTypeSelection';
import FrontImageStep from './components/FrontImagePage';
import './styles.scss';

const AdditionalComponentForChecking = ({ callFunckToSetVals }) => {
  const {
    values: { canSubmit },
  } = useFormState();
  useEffect(() => {
    callFunckToSetVals(canSubmit);
  }, [canSubmit]);
  return <></>;
};
AdditionalComponentForChecking.propTypes = {
  callFunckToSetVals: PropTypes.func.isRequired,
};

const ManualDocumentUpload = ({ hideModal }) => {
  const baseClass = 'ickyc-manual-document-upload';
  const [titles, setTitles] = useState(['Document Types', '', '']);
  const [isPassport, setIsPassport] = useState(false);
  const baseTitlePart = 'Document Upload';
  const { entityId } = useContext(EntityContext);
  const titlesToShow = useMemo(() => titles.map(el => `${baseTitlePart}: ${el}`), [titles, baseTitlePart]);
  const [shouldForce, setShoudForce] = useState(false);
  const [canSubmitForm, setCanSubmitForm] = useState(true);
  const [faceNotDetected, setFaceNotDetected] = useState(false);
  const changeTitleAtPosition = useCallback((index, newTitle) => {
    setTitles(prev => prev.map((oldtitle, ind) => (ind === index ? newTitle : oldtitle)));
  }, []);

  const steps = useMemo(() => {
    let s = [
      <DocumentTypeSelection setIsPassport={setIsPassport} />,
      <FrontImageStep
        index={1}
        changeTitle={changeTitleAtPosition}
        onFaceNotDetected={val => setFaceNotDetected(val)}
      />,
    ];
    if (!isPassport) {
      s = [...s, <BackImageStep index={2} changeTitle={changeTitleAtPosition} />];
    }
    return s;
  }, [isPassport, changeTitleAtPosition]);

  const onSubmit = async values => {
    const { frontImageName, backImageName, status: docStatus, scanDocumentType } = values;
    const formData = {
      documentFrontFileName: frontImageName,
      documentType: scanDocumentType,
      status: docStatus,
    };
    if (!isPassport) formData.documentBackFileName = backImageName;

    try {
      await api.kyc.entityManagement.naturalPerson
        .createIdentityRecord(entityId, formData)
        .then(({ data: resData }) => {
          bus.broadcastEvent(enums.BUS_EVENTS.NEW_IDENTITY_RECORD, resData);
          bus.broadcastEvent(enums.BUS_EVENTS.UPDATE_ISSUES, {
            type: 'kyc',
            section: enums.ACCORDION_INDEXES.IDENTITY_DOCUMENTS,
            issuesChange: resData.issues,
          });
          hideModal();
        });
    } catch (err) {
      if (err?.response) {
        const { status, data } = err.response;
        if (status >= 400 && status < 500) {
          if (
            data?.message?.length &&
            data.message.join('').includes('no accepted Biometric Event to perform verification')
          ) {
            setShoudForce(true);
          } else {
            setShoudForce(false);
          }
          return { [FORM_ERROR]: data.message };
        }
        if (status === 500) {
          return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
        }
      }
      return { [FORM_ERROR]: 'Error trying to validate document verification' };
    }
  };

  return (
    <div className={baseClass}>
      <WizardFormModal
        onSubmitHandler={onSubmit}
        hideModal={hideModal}
        initialValues={{ canSubmit: true }}
        titles={titlesToShow}
        className="ickyc-manual-document-upload-modal"
        keepDirtyOnReinitialize
        onRejectClick={hideModal}
        showErrorsInConfirmation
        disableSubmitButton={!canSubmitForm}
      >
        {steps.map(step => (
          <div>
            <AdditionalComponentForChecking callFunckToSetVals={setCanSubmitForm} />
            <WizardFormPage>{step}</WizardFormPage>
          </div>
        ))}
      </WizardFormModal>
    </div>
  );
};

ManualDocumentUpload.propTypes = {
  hideModal: PropTypes.func.isRequired,
};

export default ManualDocumentUpload;
