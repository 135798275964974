import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="13.61 18.55 66.1 52">
    <path
      d="M69.006 19.047h-2l-27.7 27.7-13-13h-2l-10.2 10.1v2l24.2 24.2h2l38.9-38.9v-2l-10.2-10.1z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);
