import { Lock } from '@material-ui/icons';
import React from 'react';
import { Field } from 'react-final-form';
import ToggleField from '../../../../../../../../../../../components/ToggleField';
import ModuleOption from '../../../ModuleOptions';
import './styles.scss';

const AddressDetailsFormFields = () => {
  const baseClass = 'ickyc-additional-details-form-fields';
  return (
    <ModuleOption title="Form Fields">
      <div className={baseClass}>
        <div className={`${baseClass}__controll`}>Show Field</div>
        <div className={`${baseClass}__controll`}>Require Field</div>
        <div>Country</div>
        <div className={`${baseClass}__lock-icon-container`}>
          {/* <Field component={ToggleField} name="modules[3].details.country.shown" /> */}
          <Lock />
        </div>
        <div className={`${baseClass}__lock-icon-container`}>
          {/* <Field component={ToggleField} name="modules[3].details.country.required" /> */}
          <Lock />
        </div>
        <div>Address</div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[3].details.address.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[3].details.address.required" />
        </div>
        <div>Unit</div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[3].details.unit.shown" />
        </div>
        <div className={`${baseClass}__controll`} />
        <div>City</div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[3].details.city.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[3].details.city.required" />
        </div>
        <div>State / Province</div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[3].details.stateProvince.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[3].details.stateProvince.required" />
        </div>

        <div>Postal / Zip</div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[3].details.postalZip.shown" />
        </div>
        <div className={`${baseClass}__controll`}>
          <Field component={ToggleField} name="modules[3].details.postalZip.required" />
        </div>
      </div>
    </ModuleOption>
  );
};
export default AddressDetailsFormFields;
