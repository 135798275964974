import PropTypes from 'prop-types';
import React from 'react';
import { SketchPicker } from 'react-color';
import useVisible from '../../../../../../../../../hooks/useVisible';
import setClassSuffix from '../../../../../../../../../utilities/services/ClassManager';
import './styles.scss';

const ColorPickerInput = ({ label, input: { value: color, onChange } }) => {
  const baseClass = 'ickyc-color-picker';
  const setSuffix = setClassSuffix(baseClass);
  const { ref, isVisible, setIsVisible } = useVisible(false);

  return (
    <div className={setSuffix('')}>
      <label>{label}</label>
      <div>
        <label>
          <b>{color.toUpperCase()}</b>
        </label>
        <div
          className={setSuffix('__color-cube')}
          onClick={() => setIsVisible(prev => !prev)}
          style={{ backgroundColor: color }}
        >
          {isVisible && (
            <div
              className={setSuffix('__color-match')}
              ref={ref}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <SketchPicker onChange={colorValue => onChange(colorValue.hex.toUpperCase())} color={color} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ColorPickerInput.propTypes = {
  label: PropTypes.string,
  input: PropTypes.shape({ value: PropTypes.string, onChange: PropTypes.func }),
};

ColorPickerInput.defaultProps = {
  label: '',
  input: undefined,
};

export default ColorPickerInput;
