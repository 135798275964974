import PropTypes from 'prop-types';
import React from 'react';
import Routes from '../../../../components/Routes';
import routes from './routes';

const KycSubrouter = ({ className }) => {
  return <Routes routes={routes} className={className} />;
};

KycSubrouter.propTypes = {
  className: PropTypes.string,
};
KycSubrouter.defaultProps = {
  className: undefined,
};
export default KycSubrouter;
