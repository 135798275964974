/* eslint-disable no-alert */
/**
 * Modal for adding data about Natural Person
 * @param {reference} containerRef - reference of the page containing the modal
 * @param {func} hideModal - display flag setter
 * @param {func} setNewlyAdded - setting locally new entities
 * @param {boolean} multipleEntities - enables creating multiple entities
 * @param {boolean} withRoles - should list roles be attached to entity
 */
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import toastr from 'toastr';
import api from '../../../../../../../../api';
import WizardFormModal from '../../../../../../../../components/Modal/WizardFormModal';
import WizardFormPage from '../../../../../../../../components/Modal/WizardFormModal/components/WizardFormPage';
import enums from '../../../../../../../../utilities/enums';
import { EntityContext } from '../../../../../../../../utilities/services/contexts';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import './styles.scss';

const AffiliatedEntityModal = ({ hideModal, setNewlyAdded }) => {
  const { entityId } = useContext(EntityContext);
  const [npSuggestions, setNPSuggestios] = useState([]);
  const [npLoading, setNPLoading] = useState(false);
  const [leLoading, setLELoading] = useState(false);

  const [leSuggestions, setLESuggestions] = useState([]);
  const [npParams, setNPParams] = useState({ sortOrder: 1, searchCriteria: 'date', limit: 5 });
  const [leParams, setLEParams] = useState({ sortOrder: 1, searchCriteria: 'date', limit: 5 });

  const [npPagination, setNPPagination] = useState({});
  const [lePagination, setLEPagination] = useState({});

  const [npRoles, setNpRoles] = useState([]);
  const [leRoles, setLeRoles] = useState([]);

  useEffect(() => {
    setNpRoles(enums.ATTACH_NP_ENTITY_ROLES);
    setLeRoles(enums.OWNERSHIP_TAGS);
  }, [entityId]);

  useEffect(() => {
    setNPLoading(true);
    api.kyc.entityManagement.legalEntity
      .getAffiliatedEntitiesSuggestios(entityId, {
        ...leParams,
        entityType: 1,
      })
      .then(({ data: response }) => {
        setLESuggestions(
          response.data.map(({ id, name, ...rest }) => ({
            id,
            label: name,
            name,
            value: id,
            ...rest,
          })),
        );
        setLEPagination(response.paginationData);
      })
      .finally(() => {
        setNPLoading(false);
      });
  }, [leParams, entityId]);

  const changeNPParams = useCallback(newParams => {
    setNPParams(prev => ({ ...prev, ...newParams }));
  }, []);

  const changeLEParams = useCallback(newParams => {
    setLEParams(prev => ({ ...prev, ...newParams }));
  }, []);

  useEffect(() => {
    setLELoading(true);
    api.kyc.entityManagement.legalEntity
      .getAffiliatedEntitiesSuggestios(entityId, {
        ...npParams,
        entityType: 0,
      })
      .then(({ data: response }) => {
        setNPSuggestios(
          response.data.map(({ id, name, ...rest }) => ({
            id,
            label: name,
            name,
            value: id,
            ...rest,
          })),
        );
        setNPPagination(response.paginationData);
      })
      .finally(() => {
        setLELoading(false);
      });
  }, [npParams, entityId]);

  const handleSubmit = async values => {
    const {
      existingEntityType,
      entityType,
      existingNPId,
      existingLEId,
      corporateInformation,
      personalInformation,
    } = values;

    const isExistingLE = entityType === enums.AFFILIATED_MODAL_ENTITY_OPTIONS.EXISTING && existingEntityType === 1;
    const isExistingNP = entityType === enums.AFFILIATED_MODAL_ENTITY_OPTIONS.EXISTING && existingEntityType === 0;
    const isNewLE = entityType === enums.AFFILIATED_MODAL_ENTITY_OPTIONS.NEW_LE;
    const isNewNP = entityType === enums.AFFILIATED_MODAL_ENTITY_OPTIONS.NEW_NP;

    try {
      if (isExistingNP) {
        const submitObj = {
          entityType: existingEntityType,
          roles: personalInformation.roles,
          entityId: existingNPId,
        };
        const response = await api.kyc.entityManagement.legalEntity.addExistingEntityAsAffiliatedEntity(
          entityId,
          submitObj,
        );
        toastr.success(`${response.data.name} has been linked`);
        setNewlyAdded(response.data);
        hideModal();
      }
      if (isExistingLE) {
        const submitObj = {
          entityType: existingEntityType,
          roles: corporateInformation.roles,
          entityId: existingLEId,
        };
        const response = await api.kyc.entityManagement.legalEntity.addExistingEntityAsAffiliatedEntity(
          entityId,
          submitObj,
        );
        toastr.success(`${response.data.name} has been linked`);
        setNewlyAdded(response.data);
        hideModal();
      }
      if (isNewLE) {
        const response = await api.kyc.entityManagement.legalEntity.addNewLEasAffiliatedEntity(entityId, {
          ...corporateInformation,
        });
        toastr.success(`${response.data.name} has been linked`);
        setNewlyAdded(response.data);
        hideModal();
      }
      if (isNewNP) {
        const response = await api.kyc.entityManagement.legalEntity.addNewNPasAffiliatedEntity(entityId, {
          ...personalInformation,
        });
        toastr.success(`${response.data.name} has been linked`);
        setNewlyAdded(response.data);
        hideModal();
      }
    } catch (err) {
      if (err?.response) {
        const { status, data } = err.response;
        if (status >= 400 && status < 500) {
          return { [FORM_ERROR]: data.message || data.errors };
        }
        if (status === 500) {
          return { [FORM_ERROR]: data.message || 'Internal Server Error, Try Again Later' };
        }
      } else return { [FORM_ERROR]: 'Error occured' };
    }
  };
  // fetching Existing Entities suggestions
  return (
    <WizardFormModal
      onSubmitHandler={handleSubmit}
      initialValues={{
        entityType: enums.AFFILIATED_MODAL_ENTITY_OPTIONS.EXISTING,
        existingEntityType: 0,
      }}
      className="ickyc-important-personnel-modal"
      hideModal={hideModal}
      titles={['Add Affiliated Entity', 'Add Affiliated Entity']}
      mutators={arrayMutators}
    >
      <WizardFormPage>
        <StepOne
          npEntities={npSuggestions}
          npParams={npParams}
          changeNPParams={changeNPParams}
          npLoading={npLoading}
          leEntities={leSuggestions}
          leParams={leParams}
          changeLEParams={changeLEParams}
          leLoading={leLoading}
          lePagination={lePagination}
          npPagination={npPagination}
        />
      </WizardFormPage>
      <WizardFormPage>
        <StepTwo npRoles={npRoles} leRoles={leRoles} />
      </WizardFormPage>
    </WizardFormModal>
  );
};

AffiliatedEntityModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  setNewlyAdded: PropTypes.func.isRequired,
};

export default AffiliatedEntityModal;
