import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import RadioButtonField from '../../../../components/RadioButtonField';
import IconManager from '../../../../utilities/services/IconManager';
import './styles.scss';

const AutomaticOnDemandControlButtons = ({ modulePreffix }) => {
  return (
    <span className="ickyc-automatic-on-demand-radio-buttons">
      <Field
        component={RadioButtonField}
        classNameSuffix="--automatic"
        name={`${modulePreffix}.automaticIDVerification`}
        type="radio"
        icon={IconManager.names.GEAR_GREEN}
        value="Automatic"
        label="Automatic"
      />
      <Field
        component={RadioButtonField}
        name={`${modulePreffix}.automaticIDVerification`}
        icon={IconManager.names.GEAR_ORANGE}
        type="radio"
        classNameSuffix="--on-demand"
        value="On-Demand"
        label="On-Demand"
      />
    </span>
  );
};

AutomaticOnDemandControlButtons.propTypes = {
  modulePreffix: PropTypes.string.isRequired,
};

export default React.memo(AutomaticOnDemandControlButtons);
