import PropTypes from 'prop-types';
import React from 'react';
import Section from '../../../../components/Section';
import FieldCountriesList from '../../../../components/Tables/FieldCountriesList';
import DocumentTypesCheckboxes from '../DocumentTypesCheckboxes';
import DocumentTypesControls from '../DocumentTypesControls';

const DocumentTypesSettings = ({ tableName, disabled, divider }) => {
  return (
    <Section divider={divider} title="Accepted Document Types by Jurisdiction">
      <p>
        Prompt your user to validate their identification by submitting an identity document.
        <br />
        <br />
        You have the option of allowing your user to submit their <strong>Driver's Licence</strong>,
        <strong> ID Card</strong> or <strong>Passport</strong>
      </p>
      <FieldCountriesList
        modulePreffix={tableName}
        initValue={[
          { label: "Driver's Licence", name: 'driverLicense', value: true },
          { label: 'Identity Card', name: 'idCard', value: true },
          { label: 'Passport', name: 'passport', value: true },
        ]}
        TableControlsComponent={DocumentTypesControls}
        tableRow={DocumentTypesCheckboxes}
        withInfiniteScroll
        disabled={disabled}
      />
    </Section>
  );
};
DocumentTypesSettings.propTypes = {
  disabled: PropTypes.bool,
  tableName: PropTypes.string.isRequired,
  divider: PropTypes.bool,
};
DocumentTypesSettings.defaultProps = {
  disabled: false,
  divider: false,
};
export default DocumentTypesSettings;
